/**
 * Returns a Promise of the provided param as a base64 string.
 * 
 * @async
 * @param file
 * @returns `new Promise()`
 * 
 * @see https://stackoverflow.com/a/62192730/8296677
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Base64
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
 */
const getBase64 = async (file: Blob): Promise<string | undefined> => {
  const reader = new FileReader();
  reader.readAsDataURL(file as Blob);
  
  // Data-URL declaration preceding the Base64-encoded data is removed via substr
  return new Promise((reslove, reject) => {
    reader.onload = () => reslove((reader.result as string).substr((reader.result as string).indexOf(',') + 1) as string);
    reader.onerror = error => reject(error);
  });
};

export default getBase64;
