import CONFIG from '../../config';

const {
  APP: { USE_AUTH_FLOW },
  SESSION: { ACCESS_TOKEN }
} = CONFIG;

export default function getInitialState(useAuthFlow = false, secretKey = null) {
  if (useAuthFlow || USE_AUTH_FLOW) {
    return window?.sessionStorage.getItem(secretKey || ACCESS_TOKEN)
      ? window?.sessionStorage.getItem(secretKey || ACCESS_TOKEN)
      : null;
  }

  return null;
}
