import styled from 'styled-components';

export const ActionCard = styled.div`
  font-size: 14px;
  color: var(--color-black);
  font-family: 'Open Sans', sans-serif;

  .pixie-h3 {
    font-weight: 400;
    font-size: 21px;
  }
  .pixie-body {
    font-weight: 400;
    font-size: 14px;
  }
  .instructions-text {
    margin-top: 17px;
  }
  .instructions-text:marker {
    height: 6px;
    width: 6px;
  }
  h3 {
    font-size: 1.25em;
    line-height: 1.15;
  }

  p {
    color: var(--color-secondary--100);
    font-size: 1em;
    line-height: 1.15;
  }

  p strong {
    color: black;
    text-transform: uppercase;
  }

  h3 .respond-by {
    display: block;
    color: black;
    font-size: 0.75em;
    font-style: italic;
    margin: 4px 0 0 0;
  }

  button {
    white-space: nowrap;
  }

  [class*='uk-align'] {
    margin-bottom: 0;
  }

  .actions-available {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  .actions-available.flex-start {
    align-items: flex-start;
  }

  .uk-grid {
    position: relative;
  }

  .is-loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
`;
