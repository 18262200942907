/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-return-assign */
import React, { ReactElement, useRef } from 'react';
import { ReadonlyFormText } from '@pixie/components';
import { IEbvPlanInformation, IEbvBenefitDetails, IEbvEncounterInformation } from '@pixie/interfaces';
import WarningIcon from '@mui/icons-material/Warning';
import { red  } from '@mui/material/colors';
import * as Styled from './EbvRTBCCard.styled';


interface IEbvRTBCCardProps {
  eligibilityPlan: IEbvPlanInformation;
  eligibilityEncounter: IEbvEncounterInformation;
  bodyWidth: number;
  isLoading?: boolean;
}

const EbvRTBCCard = (props: IEbvRTBCCardProps): ReactElement => {
  const {
    eligibilityPlan,
    eligibilityEncounter,
    bodyWidth,
    isLoading
  } = props;

  const myRef = useRef([]);

  const handleIsPriorAuthorizationRequired = (isPriorAuthorizationRequired: boolean) => {
    if (isPriorAuthorizationRequired == null){
      return '--';
    }
    return isPriorAuthorizationRequired ? 'Yes' : 'No';
  };

  const handleCoverageAlertClick = (index: any) => {
    myRef.current[index]?.scrollIntoView({ block: 'center' });
  };

  return (
    <Styled.EbvRTBCCard>
      {isLoading ? (
        <>
          <div className="is-loading">
            <div className="uk-spinner" data-uk-spinner />
          </div>
        </>
      ) : (
        <div>
          <h3 className="rtbc-header">Real Time Benefits Check Summary</h3>
          <div
            className='rtbc-card-box'
          >
            <div className="rtbc-drugDescription">
              <ReadonlyFormText
                dataTestId="ebv-rtbc-drug-description"
                labelClassName="rtbc_drug_description_label"
                textClassName="rtbc_drug_description_text"
                label='Drug Description'
                textContent={eligibilityEncounter?.drugDescription ? eligibilityEncounter.drugDescription : '--'}
              />
            </div>
            <div
              className={
              bodyWidth <= 959
                ? 'rtbc-cards uk-grid uk-child-width-1-2@m'
                : 'rtbc-cards uk-grid-small uk-child-width-1-4'
              }
            >
              {eligibilityPlan?.benefitsDetails?.map((obj: IEbvBenefitDetails, index) => {
                return (
                  <div key={obj.pharmacyType} data-value={obj.pharmacyType} >
                    <div className="RTBC-pharmacy" >
                      <div
                        data-testId={`ebv-rtbc-card-pharmacy-type-${index}`}
                        className="rtbc-card-header uk-grid "
                      >
                        <div className="rtbc-card-logos uk-child-width-1-4@s">
                          {obj.pharmacyType === 'Specialty' &&
                          <span className="material-symbols-outlined">
                            local_pharmacy
                          </span>
                        }
                          {obj.pharmacyType === '90DayAtRetail' &&
                          <span className="material-symbols-outlined">
                            add_business
                          </span>
                        }
                          {obj.pharmacyType === 'Mail' &&
                          <span className="material-symbols-outlined">
                            markunread_mailbox
                          </span>
                        }
                          {obj.pharmacyType === 'Retail' &&
                          <span className="material-symbols-outlined">
                            store
                          </span>
                        }
                        </div>
                        <div className="rtbc-pharmacy-type-header">
                          <h3 className="rtbc-card-pharmacy-type">
                            {obj.pharmacyType ? obj.pharmacyType : '--'}
                          </h3>
                          {obj.coverageAlertList.length > 0 &&
                          <div >
                            <WarningIcon fontSize="small" sx={{ color: red[500] }} />
                            <a
                              aria-hidden="true"
                              data-testId={`rtbc-coverage-alert-link-${index}`}
                              onClick={() => handleCoverageAlertClick(index)}
                              onKeyDown={() => handleCoverageAlertClick(index)}
                              className="rtbc-coverage-alert-link"
                            >
                              Coverage Alert
                            </a>
                          </div>
                          }
                        </div>

                      </div>
                      <hr />
                      <div className="rtbc-card-body">
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-estimated-pay-amount-${index}`}
                          labelClassName="rtbc_pay_amount_label"
                          textClassName="rtbc_pay_amount_text"
                          label='Estimated Patient Pay Amount'
                          textContent={obj.estimatedPatientPayAmount ? obj.estimatedPatientPayAmount : '--'}
                        />
                      </div>
                      <hr />
                      <div className="rtbc-card-body">
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-days-supply-applied-${index}`}
                          labelClassName="rtbc__section_header_label"
                          textClassName="rtbc__section_header_text"
                          label='Drug Information'
                          textContent=''
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-days-supply-applied-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Days Supply Priced'
                          textContent={obj.daysSupplyApplied ? obj.daysSupplyApplied : '--'}
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card--quantity-priced-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Quantity Priced'
                          textContent={obj.quantityPriced ? obj.quantityPriced : '--'}
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-formulary-status-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Formulary Status'
                          textContent={obj.formularyStatus ? obj.formularyStatus : '--'}
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-drug-status-code-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Drug Status Code'
                          textContent={obj.drugStatusCode ? obj.drugStatusCode : '--'}
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card--prior-authorization-required-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Prior Authorization Required'
                          textContent={handleIsPriorAuthorizationRequired(obj?.isPriorAuthorizationRequired)}
                        />
                      </div>
                      <hr />
                      <div className="rtbc-card-body">
                        <ReadonlyFormText
                          dataTestId="ebv-rtbc-card-pricing-information"
                          labelClassName="rtbc__section_header_label"
                          textClassName="rtbc__section_header_text"
                          label='Pricing Information'
                          textContent=''
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-plan-paid-amount-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Plan Paid Amount'
                          textContent={obj.planPaidAmount ? obj.planPaidAmount : '--'}
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-deductible-applied-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Deductible Applied '
                          textContent={obj.deductibleApplied ? obj.deductibleApplied : '--'}
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-out-of-pocket-applied-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Out of Pocket Applied'
                          textContent={obj.outOfPocketApplied ? obj.outOfPocketApplied : '--'}
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-deductible-remaining-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Deductible Remaining'
                          textContent={obj.deductibleRemaining ? obj.deductibleRemaining : '--'}
                        />
                        <ReadonlyFormText
                          dataTestId={`ebv-rtbc-card-out-of-pocket-remaining-${index}`}
                          labelClassName="rtbc_label"
                          textClassName="rtbc_text"
                          label='Out of Pocket Remaining'
                          textContent={obj.outOfPocketRemaining ? obj.outOfPocketRemaining : '--'}
                        />
                      </div>
                      <hr />
                      <div className="coverage-alert-section" ref={el => myRef.current[index] = el}>
                        {obj.coverageAlertList.length > 0 &&
                        <div>
                          <p className="coverage-alert-header">
                            <WarningIcon fontSize="small" sx={{ color: red[500] }} /> Coverage Alert
                          </p>
                          {obj.coverageAlertList.map((coverageAlert, coverageIndex) =>
                            <p
                              data-testId={`ebv-rtbc-card-coverages-${index}-${coverageIndex}`}
                              className="coverage-alert"
                            >
                              {coverageAlert}
                            </p>
                          )}
                        </div>
                    }
                        {obj.coverageAlertList.length === 0 &&
                        <div className="coverage-alert-header">
                          <ReadonlyFormText
                            dataTestId="ebv-rtbc-card-coverage-alert-header"
                            labelClassName="rtbc__section_header_label"
                            textClassName="rtbc__section_header_text"
                            label='No Coverage Alerts'
                            textContent=''
                          />
                        </div>
                    }
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Styled.EbvRTBCCard>
  );
};

EbvRTBCCard.defaultProps = {
  isLoading: true
};

export default EbvRTBCCard;
