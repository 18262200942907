const questionsConfig = {
  QUESTION_TYPES: {
    CHECKBOX: 'CHECKBOX',
    DATE: 'DATE',
    END: 'END',
    FILE_UPLOAD: 'FILE_UPLOAD',
    FREE_TEXT: 'FREE_TEXT',
    NUMERIC: 'NUMERIC',
    RADIO: 'RADIO'
  }
};

export default questionsConfig;
