import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRoutingType } from '@pixie/features';
import { RootState as Root } from '@pixie/store';
import { IRequestDetails } from '@pixie/api';
import { IEbvQuery } from 'interfaces/ebv-query';
import EpaDetailView from './EpaDetailView';
import EbvDetailView from './EbvDetailView';

interface IRequestDetailViewProps {
  appIsLoading: boolean;
  data: IRequestDetails;
  ebvData: IEbvQuery;
  handleRefreshRequest: any;
  responseTimestamp: string;
  appError: string;
}

const RequestDetailView = (props: IRequestDetailViewProps): ReactElement => {
  const {
    appIsLoading,
    data,
    ebvData,
    handleRefreshRequest,
    responseTimestamp,
    appError
  } = props;

  const dispatch = useDispatch();
  const routingType = useSelector((state: Root) => state.routingType);

  useEffect(() => {
    if (data?.attributes?.requestTypeEnum !== 'EBV_AND_EPA' ||
    (data?.attributes?.ebvQueryId != null && data?.attributes?.eligibilityPlanPixieMessageId != null)) {
      dispatch(setRoutingType('EPA'));
    } else {
      dispatch(setRoutingType('EBV'));
    }
    return () => {};
  }, [data, dispatch]);

  const onClickPriorAuthButton = () => {
    dispatch(setRoutingType('EPA'));
  };

  return (
    <>
      {(data != null ) ? (
        <>
          {routingType?.routingType === 'EPA' && (
          <EpaDetailView
            appIsLoading={appIsLoading}
            appError={appError}
            data={data}
            handleRefreshRequest={handleRefreshRequest}
            responseTimestamp={responseTimestamp}
          />
          )}
          {routingType?.routingType === 'EBV' && (
          <EbvDetailView
            isLoading={appIsLoading}
            ebvData={ebvData}
            modalData={data}
            onClickPriorAuthButton={onClickPriorAuthButton}
          />
          )
        }
        </>
      ) : (
        <>
        </>
      )
    }

    </>
  );
};

export default RequestDetailView;
