/* eslint-disable @typescript-eslint/indent */
import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import debugCases from '../../json/debug-cases';
import debugCertCases from '../../json/debug-cert-cases';
import debugMultipleCases from '../../json/debug-multiple-cases';

const DebugMenu = (): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation() || null;
  const caseCode = location?.pathname.replace(/(\/Requests?\/)/g, '');

  return (
    <StyledDebugMenu id="DebugMenu" isOpen={isOpen}>
      <div className="button-container">
        <button
          aria-label="Open Debug Menu"
          className="uk-icon uk-icon-button"
          data-uk-icon={isOpen === true ? 'close' : 'menu'}
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        />
      </div>

      {isOpen && (
        <>
          <div className="nav-container uk-box-shadow-xlarge">
            <div className="uk-width-auto">
              <ul className="uk-nav uk-nav-default">
                <li className="uk-parent">
                  <span>Cert Cases</span>
                  <ul className="uk-nav-sub">
                    {debugCertCases.map(c => (
                      <li
                        className={caseCode === c.value ? 'uk-active' : ''}
                        key={`cert_${c.label}`}
                      >
                        <a aria-label={c.label} href={`/Request/${c.value}`}>
                          {c.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="uk-parent">
                  <span>Specific Cases</span>
                  <ul className="uk-nav-sub">
                    {debugCases.map(sc => (
                      <li
                        className={caseCode === sc.value ? 'uk-active' : ''}
                        key={`spec_${sc.label}`}
                      >
                        <a aria-label={sc.label} href={`/Request/${sc.value}`}>
                          {sc.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="uk-parent">
                  <span>Multiple Cases</span>
                  <ul className="uk-nav-sub">
                    {debugMultipleCases.map(m => (
                      <li
                        className={caseCode === m.value ? 'uk-active' : ''}
                        key={`epa_${m.label}`}
                      >
                        <a aria-label={m.label} href={`/Requests/${m.value}`}>
                          {m.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </StyledDebugMenu>
  );
};

type Props = {
  isOpen: boolean;
};

const StyledDebugMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${(p: Props) => (p.isOpen ? 1 : 0.25)};
  z-index: ${(p: Props) => (p.isOpen ? 9000 : 'auto')};

  &:hover {
    opacity: 1;

    .button-container {
      transform: translate(0, 0);
    }
  }

  .button-container {
    transform: ${(p: Props) => {
      return p.isOpen ? 'translate(0, 0)' : 'translate(-25px, -25px)';
    }};
    transition: 200ms ease-out;
    transition-property: opacity, transform;
    will-change: opacity, transform;
  }

  .button-container,
  .nav-container {
    padding: 10px;
  }

  .uk-icon-button {
    background-color: var(--color-primary);
    color: var(--color-black--50);
  }

  .nav-container {
    background-color: var(--color-white--100);
  }
`;

export default DebugMenu;
