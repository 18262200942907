import React, { ReactElement, useState, useEffect } from 'react';
import { IRequestDetails } from '@pixie/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState as Root } from '@pixie/store';
import { classesArray, formatICD10, formatTasksWithLinks, formatWeight, formatHeight, formatAddress, formatPhoneNumber } from '@pixie/utils';
import CONFIG from '@pixie/config';
import {
  Alert,
  InfoCard,
  ReadonlyFormText,
  TaskHistoryTable,
  TheMedicalRequestHeader
} from '@pixie/components';
import {
  AppealToggleButton,
  QuestionSetToggleButton,
  TheCancelPaRequestModalButton,
  TheQuestionSetBar,
  TheAppealBar,
  TheCancelPaRequestModal
} from '@pixie/features';
import { IAncillaryServicesModel } from 'api/request-details.model';
import {
  closeAlert,
  handleAppealBar,
  handleCancelModal,
  handleExternalLinkClick,
  handleQuestionSetBar
} from './methods';
import PlacesOfService from '../../assets/placeOfService.json';

const {
  ACTIONS: { ACTION_STATES }
} = CONFIG;

interface IMedicalDetailViewProps {
  appIsLoading: boolean;
  data: IRequestDetails;
  handleRefreshRequest: any;
  responseTimestamp: string;
  appError: string;
  showBuyAndBill: boolean;
}

const placeOfServiceData = [];
for ( const k in PlacesOfService){
  if (PlacesOfService[k])
    placeOfServiceData.push({ value : k, label: PlacesOfService[k] });
}

const MedicalDetailView = (props: IMedicalDetailViewProps): ReactElement => {
  const {
    appIsLoading,
    data,
    handleRefreshRequest,
    responseTimestamp,
    appError,
    showBuyAndBill
  } = props;

  const dispatch = useDispatch();
  const actionCard = useSelector((state: Root) => state.actionCard);
  const alerts = useSelector((state: Root) => state.alerts);
  const appeal = useSelector((state: Root) => state.appeal);
  const app = useSelector((state: Root) => state.app);
  const benefitsInfo = useSelector((state: Root) => state.benefitsInfo);
  const cancelReq = useSelector((state: Root) => state.cancelPaRequest);
  const caseInfo = useSelector((state: Root) => state.caseInfo);
  const latestTask = useSelector((state: Root) => state.latestTask);
  const patientInfo = useSelector((state: Root) => state.patientInfo);
  const prescriberInfo = useSelector((state: Root) => state.prescriberInfo);
  const prescriptionInfo = useSelector((state: Root) => state.prescriptionInfo);
  const treatmentInfo = useSelector((state: Root) => state.treatmentInfo);
  // const diagnosis = useSelector((state: Root) => st)
  const questionSet = useSelector((state: Root) => state.questionSet);
  const [displayBuyAndBill, setDisplayBuyAndBill] = useState<boolean>(false);

  useEffect(() => {
    setDisplayBuyAndBill(questionSet?.showBuyAndBill);
  }, [questionSet.showBuyAndBill]);
  const formatAncillaryServices = (obj: IAncillaryServicesModel) => {
    return `${obj?.serviceCode || ''} ${obj?.codeType || ''} \n`;
  };

  if (showBuyAndBill) {
    return <></>;
  }

  const formatAddressForProvider = (requestDetails: IRequestDetails) => {
    return formatAddress(
      requestDetails?.buyAndBill?.providerAddress,
      requestDetails?.buyAndBill?.providerUnit || '',
      requestDetails?.buyAndBill?.providerCity,
      requestDetails?.buyAndBill?.providerState,
      requestDetails?.buyAndBill?.providerZip
    );
  };

  const formatPlaceOfService = (placeOfService: string) => {
    return placeOfServiceData[(placeOfServiceData.findIndex(p => p.value === placeOfService))]?.label;
  };

  const mapPriorAuthTiming = (priorAuthRequestTiming: string) => {
    if (priorAuthRequestTiming?.toLowerCase().includes('pre')) return 'Pre-Service';
    return 'Post-Service';
  };

  return (
    <>
      <TheMedicalRequestHeader
        actionCard={actionCard}
        appError={appError}
        authorizationNumber={latestTask?.authorizationNumber}
        authorizationPeriod={latestTask?.authorizationPeriod}
        bodyWidth={app.bodyWidth}
        cancelAvailable={cancelReq?.canCancel}
        cancelButton={
          <TheCancelPaRequestModalButton
            cancelAvailable={cancelReq?.canCancel}
            isCancelled={cancelReq?.isCancelled}
            onClick={e =>
              dispatch(handleCancelModal(e, questionSet?.questionSetOpen))
            }
          />
        }
        caseIsPriority={caseInfo.caseIsPriority}
        caseNumber={caseInfo.caseNumber}
        caseStatus={caseInfo.caseStatus}
        expectedResponseDate={latestTask?.expectedResponseDate}
        isLoading={appIsLoading}
        isReviewerApp={false}
        notes={latestTask?.paNote}
        externalLinkClick={(urlStr) => {
          dispatch(handleExternalLinkClick(urlStr));
        }}
        paAuthorizationDetails={latestTask?.paAuthorizationDetails}
        patientName={patientInfo.patientFormattedName}
        payerCaseId={caseInfo?.payerCaseId}
        payerAttachmentFileName={data?.integrationSubmittedFileName}
        payerName={benefitsInfo?.payerName}
        prescription={prescriptionInfo?.drugDescription}
        refreshRequest={handleRefreshRequest}
        timestamp={responseTimestamp}
        patientDOB={patientInfo?.patientDateOfBirth}
        patientGender={patientInfo?.patientGender}
        prescriberName={prescriberInfo?.prescriberFormattedName}
      >
        <div className="toggle-button-positioning">
          {actionCard.currentAction === ACTION_STATES.APPEAL ? (
            <AppealToggleButton
              actionsAvailable={actionCard.actionsAvailable}
              availableAction={actionCard.currentAction}
              onClick={e => dispatch(handleAppealBar(e, appeal.appealBarOpen))}
              open={appeal?.appealBarOpen}
            />
          ) : (
            <QuestionSetToggleButton
              actionsAvailable={actionCard.actionsAvailable}
              availableAction={actionCard.currentAction}
              onClick={e =>
                dispatch(handleQuestionSetBar(e, questionSet?.questionSetOpen))
            }
              open={questionSet?.questionSetOpen}
            />
          )}
        </div>
      </TheMedicalRequestHeader>
      {!appError && (
        <div
          className={classesArray([
            'request-data',
            'uk-container',
            'uk-container-large',
            'uk-section',
            'uk-section-small'
          ])}
        >
          <ReadonlyFormText textClassName="pixie-h2-prior-auth-info" textContent='Prior Authorization Information' />
          <div
            className={
              app.bodyWidth <= 959
                ? 'uk-grid uk-child-width-1-2@l '
                : 'uk-grid uk-child-width-1-2@m '
            }
            data-uk-grid
          />
          {!appError && (
          <div
            className={classesArray([
              'request-data',
              'uk-container',
              'uk-container-large',
              'uk-section',
              'uk-section-small'
            ])}
          >
            <div
              className={
              app.bodyWidth <= 959
                ? 'uk-grid uk-child-width-1-2@l '
                : 'uk-grid uk-child-width-1-2@m '
            }
              data-uk-grid
            >
              <div>
                <InfoCard
                  cardTitle="Patient Medication & Diagnosis"
                  cardStyle="Gray--10"
                  isLoading={patientInfo?.isLoading}
                  gridClasses={classesArray([
                    'uk-child-width-1-2@s',
                    'uk-child-width-1-3@m',
                    'uk-grid-small'
                  ])}
                  data={[
                    {
                      dataTestId:'request-detail-view-patient-first-name',
                      label: 'Diagnosis Code',
                      value: prescriptionInfo.primaryDiagnosisCode
                        ? formatICD10(prescriptionInfo.primaryDiagnosisCode)
                        : null,
                      gridClass: 'uk-width-1-2'
                    },
                    {
                      dataTestId:'request-detail-view-diagnosis-description',
                      label: 'Diagnosis',
                      value: prescriptionInfo.drugDescription,
                      gridClass: 'uk-width-1-2'
                    },
                    {
                      dataTestId:'request-detail-view-patient-middle-name',
                      label: 'Days Supply',
                      value: prescriptionInfo.daysSupply && Math.round(prescriptionInfo.daysSupply),
                      gridClass: 'uk-width-1-2'
                    },
                    {
                      dataTestId:'request-detail-view-patient-last-name',
                      label: 'Quantity',
                      value: prescriptionInfo.quantityRequestValue,
                      gridClass: 'uk-width-1-2'
                    },
                    {
                      dataTestId:'request-detail-view-patient-last-name',
                      label: 'Medication Name',
                      value: prescriptionInfo.drugDescription,
                      gridClass: 'uk-width-1-1'
                    },
                    {
                      dataTestId:'request-detail-view-patient-date-of-birth',
                      label: 'Will your organization buy and bill this medication?',
                      value: 'yes',
                      gridClass: 'uk-width-1-1'
                    }
                  ]}
                />
              </div>
              <div>
                <InfoCard
                  cardTitle="Additional Treatment Information"
                  cardStyle="Gray--10"
                  isLoading={treatmentInfo?.isLoading}
                  gridClasses="uk-child-width-1-2@s uk-grid-small"
                  data={[
                    {
                      dataTestId:'request-detail-view-pharmacy-name',
                      label: 'Patient Weight',
                      value: formatWeight(treatmentInfo?.patientWeight),
                      gridClass: 'uk-width-1-3'

                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-contact-number',
                      label: 'Patient Height',
                      value: formatHeight(treatmentInfo?.patientHeight),
                      gridClass: 'uk-width-2-3'

                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-address',
                      label: 'Place of Service',
                      value: formatPlaceOfService(treatmentInfo?.placeOfService),
                      gridClass: 'uk-width-2-3'
                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-address',
                      label: 'Billing Code (HCPCS)',
                      value: treatmentInfo?.billingCode,
                      gridClass: 'uk-width-1-3'
                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-address',
                      label: 'Number of Visits',
                      value: treatmentInfo?.numberOfVisits,
                      gridClass: 'uk-width-1-3'
                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-address',
                      label: 'Units Billed',
                      value: treatmentInfo?.unitsBilled,
                      gridClass: 'uk-width-1-3'
                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-address',
                      label: 'Number of Dosages',
                      value: treatmentInfo?.numberOfDosages,
                      gridClass: 'uk-width-1-3'
                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-address',
                      label: 'Prior Auth Request Timing',
                      value: mapPriorAuthTiming(treatmentInfo?.priorAuthRequestTiming),
                      gridClass: 'uk-width-1-1'
                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-address',
                      label: 'Start Date of Service',
                      value: treatmentInfo?.startDate,
                      gridClass: 'uk-width-1-3'
                    },
                    {
                      dataTestId:'request-detail-view-pharmacy-address',
                      label: 'End Date of Service',
                      value: treatmentInfo?.endDate,
                      gridClass: 'uk-width-1-3'
                    }
                  ]}
                />
              </div>
              <div>
                <InfoCard
                  cardTitle="Rendering Service Provider"
                  cardStyle="Gray--10"
                  isLoading={prescriberInfo?.isLoading}
                  gridClasses="uk-child-width-1-2@m uk-grid-small"
                  data={[
                    {
                      dataTestId:'request-detail-view-prescription-drug-description',
                      label: 'Facility  Name',
                      gridClass: 'uk-width-1-1',
                      value: data?.buyAndBill?.facilityName
                    },
                    {
                      dataTestId:'request-detail-view-prescription-view-direction',
                      label: 'Provider First Name',
                      value: data?.buyAndBill?.providerFirstName,
                      gridClass: 'uk-width-1-3'
                    },
                    {
                      dataTestId:'request-detail-view-prescription-quantity',
                      label: 'Provider Last Name',
                      gridClass: 'uk-width-1-3',
                      value: data?.buyAndBill?.providerLastName
                    },
                    {
                      dataTestId:'request-detail-view-prescription-days-supply',
                      label: 'Address',
                      gridClass: 'uk-width-1-1',
                      value: formatAddressForProvider(data)
                    },
                    {
                      dataTestId:'request-detail-view-prescription-primary-diagnosis',
                      label: 'Primary Phone Number',
                      gridClass: 'uk-width-1-3',
                      value: formatPhoneNumber(data?.buyAndBill?.providerPhoneNumber)
                    },
                    {
                      dataTestId:'request-detail-view-prescription-secondary-diagnosis',
                      label: 'Fax Number',
                      gridClass: 'uk-width-1-3',
                      value: formatPhoneNumber(data?.buyAndBill?.providerFaxNumber),
                    },
                    {
                      dataTestId:'request-detail-view-prescription-primary-diagnosis-code',
                      label: 'Provider NPI',
                      gridClass: 'uk-width-1-3',
                      value: data?.buyAndBill?.providerNpi
                    }
                  ]}
                />
              </div>
              <div>
                <InfoCard
                  cardTitle="Ancillary Services"
                  cardStyle="Gray--10"
                  isLoading={prescriberInfo?.isLoading}
                  gridClasses={classesArray([
                    'uk-child-width-1-2@s',
                    'uk-child-width-1-3@m',
                    'uk-grid-small'
                  ])}
                  data={[
                    {
                      dataTestId:'request-detail-view-prescriber-prescribed-by',
                      label: 'Ancillary Service Code',
                      value: treatmentInfo?.ancillaryServices?.map((obj: IAncillaryServicesModel) => {
                        return (
                          formatAncillaryServices(obj)
                        );
                      }),
                      gridClass: 'uk-width-1-1'
                    }
                  ]}
                />
              </div>
            </div>
          </div>
          )}
          {data && data.tasks && !appIsLoading && (
          <div
            className={classesArray([
              'task-history',
              'uk-container',
              'uk-container-large',
              'uk-section',
              'uk-section-small'
            ])}
          >
            <ReadonlyFormText textClassName="pixie-h2-prior-auth-info" textContent='Request History' />
            <TaskHistoryTable
              data={formatTasksWithLinks( data.tasks, (urlStr) => {
                dispatch(handleExternalLinkClick(urlStr));
              })}
            />
          </div>
          )}
        </div>
      )}
      {alerts.type !== '' && alerts.message !== '' && (
      <>
        <Alert
          onClose={(e: any) => dispatch(closeAlert(e))}
          type={alerts.type}
          text={alerts.message}
        />
      </>
      )}
      <TheQuestionSetBar
        onClose={e =>
          dispatch(handleQuestionSetBar(e, questionSet?.questionSetOpen))
        }
        handleRefreshRequest={handleRefreshRequest}
        renderQuestions={!!(data && latestTask && latestTask.questionSet)}
      />

      <TheAppealBar
        onClose={e => dispatch(handleAppealBar(e, appeal.appealBarOpen))}
        handleRefreshRequest={handleRefreshRequest}
        renderAppeal={!!(data && latestTask && latestTask.eappealSupported)}
      />
      <TheCancelPaRequestModal
        handleRefreshRequest={handleRefreshRequest}
      />
    </>
  );
};

export default MedicalDetailView;
