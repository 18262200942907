import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
  ebvQueryId: null,
  eligibilityPlanPixieMessageId: null
};

const initialState = DEFAULT_STATE;

const selectedEligiblityPlanSlice = createSlice({
  name: 'selectedEligibilityPlan',
  initialState,
  reducers: {
    setEbvQueryId(state, action) {
      state.ebvQueryId = action.payload;
    },
    setEligibilityPlanPixieMessageId(state, action) {
      state.eligibilityPlanPixieMessageId = action.payload;
    },
    clearEbvQueryId(state) {
      state.ebvQueryId = null;
    },
    clearEligibilityPlanPixieMessageId(state) {
      state.eligibilityPlanPixieMessageId = null;
    }
  }
});

export const {
  clearEbvQueryId,
  clearEligibilityPlanPixieMessageId,
  setEbvQueryId,
  setEligibilityPlanPixieMessageId
} = selectedEligiblityPlanSlice.actions;
export default selectedEligiblityPlanSlice.reducer;
