import { ReactElement } from 'react';
import * as React from 'react';
import { IAuthorizationDetails, IAuthorizationDetailsDates } from '@pixie/api';
import AuthDetails from './AuthDetails';
import Notes from './Notes';
import PayerAttachment from './PayerAttachment';
import StatusAndActions from './StatusAndActions';
import TitleBar from './TitleBar';
import * as Styled from './TheRequestHeader.styled';

interface ITheRequestHeaderProps {
  actionCard: {
    actionsAvailable: boolean;
    currentAction: string;
    headline: string;
    instructions: string[];
    isLoading: boolean;
    phoneNumber?: string;
    respondBy?: string;
  };
  appError?: string;
  authorizationNumber?: string;
  authorizationPeriod?: IAuthorizationDetailsDates;
  bodyWidth: number;
  cancelAvailable: boolean;
  cancelButton: any;
  caseNumber: string;
  caseStatus: string;
  children: any;
  expectedResponseDate?: string;
  isLoading?: boolean;
  isReviewerApp?: boolean;
  notes?: string;
  paAuthorizationDetails?: IAuthorizationDetails[];
  patientName: string | undefined;
  patientGender: string | undefined;
  patientDOB: string | undefined;
  payerAttachmentFileName?: string;
  payerName: string;
  payerCaseId: string;
  prescription: string | undefined;
  refreshRequest: () => void;
  externalLinkClick: (e) => void;
  timestamp?: string;
}

const TheRequestHeader = (props: ITheRequestHeaderProps): ReactElement => {
  const {
    actionCard,
    appError,
    authorizationNumber,
    authorizationPeriod,
    bodyWidth,
    cancelAvailable,
    cancelButton,
    caseNumber,
    caseStatus,
    children,
    expectedResponseDate,
    isLoading,
    isReviewerApp,
    notes,
    externalLinkClick,
    paAuthorizationDetails,
    patientName,
    patientGender,
    patientDOB,
    payerAttachmentFileName,
    payerName,
    payerCaseId,
    prescription,
    refreshRequest,
    timestamp
  } = props;

  return (
    <Styled.RequestHeader>
      <TitleBar
        dataTestId='the-request-header-title-bar'
        patientName={patientName}
        patientGender={patientGender}
        patientDOB={patientDOB}
        isLoading={isLoading}
        isMedicalView={false}
        appError={appError}
        caseNumber={caseNumber}
        prescription={prescription}
        prescriberName=''
        cancelAvailable={cancelAvailable}
        cancelButton={cancelButton}
        payerName={payerName}
        payerCaseId={payerCaseId}
      />

      <div className="pixie__request-header-details">
        <div className="uk-container uk-container-large">
          <section className="uk-section uk-section-xsmall">
            <StatusAndActions
              actionCard={actionCard}
              appError={appError}
              bodyWidth={bodyWidth}
              caseStatus={appError ? 'ERROR' : caseStatus}
              expectedResponseDate={expectedResponseDate}
              isLoading={isLoading}
              isReviewerApp={isReviewerApp}
              refreshRequest={refreshRequest}
              timestamp={timestamp}
            >
              {children}
            </StatusAndActions>
          </section>
        </div>

        <AuthDetails
          authorizationPeriod={authorizationPeriod}
          authorizationNumber={authorizationNumber}
          isLoading={isLoading}
          paAuthorizationDetails={paAuthorizationDetails}
        />

        {notes && !isLoading && (
          <>
            <Notes
              dataTestId='the-request-header-notes'
              notes={notes}
              onLinkClick={externalLinkClick}
            />
          </>
        )}

        {payerAttachmentFileName && !isLoading && (
          <>
            <PayerAttachment
              dataTestId='the-request-header-payer-attachment'
              fileName={payerAttachmentFileName}
              fileType={payerAttachmentFileName
                .match(/\.(gif|jpe?g|tiff?|png|webp|bmp|pdf)$/i)[0]
                .replace('.', '')}
            />
          </>
        )}
      </div>
    </Styled.RequestHeader>
  );
};

TheRequestHeader.defaultProps = {
  appError: '',
  authorizationNumber: '',
  authorizationPeriod: {
    authorizationEffectiveDateTime: '',
    authorizationExpirationDateTime: ''
  },
  expectedResponseDate: '',
  isLoading: false,
  isReviewerApp: false,
  notes: '',
  paAuthorizationDetails: [],
  payerAttachmentFileName: '',
  timestamp: ''
};

export default TheRequestHeader;
