import React from 'react';
import { Question, FileUploader } from '@pixie/components';
import CONFIG from '@pixie/config';

interface IQuestionFileUpload {
  attachmentNotes?: string;
  handleFileChange: any;
}

const QuestionFileUpload = (props: IQuestionFileUpload) => {
  const { attachmentNotes, handleFileChange } = props;

  return (
    <Question
      questionText={
        attachmentNotes !== '' || attachmentNotes !== null
          ? attachmentNotes
          : CONFIG.MESSAGES.FILE_UPLOAD_QUESTION_TEXT
      }
    >
      <FileUploader
        sizeMbLimit={CONFIG.API.ATTACHMENT_UPLOAD_MB_LIMIT}
        supportedFileTypes={[
          '.tiff',
          '.png',
          '.jpeg',
          '.jpg',
          '.pdf',
          '.cda',
          '.ccr'
        ]}
        onFileChange={handleFileChange}
        acceptMultipleFiles={false}
      />
      <div className="uk-margin-small uk-text-center uk-text-meta">
        <p className="uk-margin-remove">
          <em>
            Accepted file formats: .tiff, .png, .jpeg, .jpg, .pdf, .cda, .ccr
          </em>
        </p>
        <p className="uk-margin-remove">
          <em>
            Documents must be no larger than{' '}
            {CONFIG.API.ATTACHMENT_UPLOAD_MB_LIMIT}
            mb
          </em>
        </p>
      </div>
    </Question>
  );
};

QuestionFileUpload.defaultProps = {
  // handleFileChange: (files?: File[]) => {}
  attachmentNotes:''
};

export default QuestionFileUpload;
