import React, { ReactElement } from 'react';
import {  ReadonlyFormText } from '@pixie/components';
import { IEbvEncounterInformation } from '@pixie/interfaces';
import * as Styled from './EbvBenefitsInfo.styled';


interface IEbvMedicationCardProps {
  ebvEncounterInformation: IEbvEncounterInformation;
  isLoading?: boolean;
}

const EbvMedicationCard = (props: IEbvMedicationCardProps): ReactElement => {
  const {
    ebvEncounterInformation,
    isLoading
  } = props;

  return (
    <Styled.EbvBenefitsInfo>
      {!isLoading && (
        <div className="uk-card uk-card-xsmall uk-card-body">
          <div className="medication-box">
            <div className="card-right">
              <div data-uk-grid>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-drug-name' label='Drug Name' textContent={ebvEncounterInformation.drugDescription} />
                </div>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-diagnosis-code' label='Diagnosis Code' textContent={ebvEncounterInformation.diagnosisCode} />
                </div>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-diagnosis-description' label='Diagnosis Description' textContent={ebvEncounterInformation.diagnosisDescription} />
                </div>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-jq-code' label='JCode/QCode' textContent={`${ebvEncounterInformation.jcode}/${ebvEncounterInformation.qcode}`} />
                </div>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-cpt-code' label='CPT Code' textContent={ebvEncounterInformation.cptCode} />
                </div>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-days-supply' label='Days Supply' textContent={ebvEncounterInformation.daysSupply.toString()} />
                </div>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-quantity-requested' label='Quantity Requested' textContent={`${ebvEncounterInformation.quantityRequested} ${ebvEncounterInformation.quantityUnitOfMeasureDescription}`} />
                </div>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-dates-of-service' label='Dates of Service' textContent={ebvEncounterInformation.datesOfService} />
                </div>
                <div className='uk-width-1-3'>
                  <ReadonlyFormText dataTestId='ebv-medication-place-of-service' label='Place of Service' textContent={ebvEncounterInformation.placeOfService} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Styled.EbvBenefitsInfo>
  );
};


EbvMedicationCard.defaultProps = {
  isLoading: true
};

export default EbvMedicationCard;
