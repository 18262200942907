import React, { ReactElement } from 'react';
import { uiKitOptions, classesArray } from '@pixie/utils';
import { Button } from '@pixie/components';
import * as Styled from './ExternalLinkModal.styled';

interface IExternalLinkModalProps {
  children?: any;
  id?: string;
  title?: string;
  onClick?: any;
}

const ExternalLinkModal = (props: IExternalLinkModalProps): ReactElement => {
  const { children, id, title, onClick } = props;

  return (
    <Styled.ExternalLinkModal>
      <div
        id={id}
        className="uk-modal"
        data-uk-modal={uiKitOptions({
          'bg-close': true,
          'esc-close': true,
          container: false
        })}
      >
        <div className={classesArray([
          'pixie__cancel-modal-dialog',
          'uk-modal-dialog'])}
        >
          <button
            aria-label="Close"
            className="uk-modal-close-default"
            data-uk-close
            type="button"
          />
          <div className="uk-modal-header">
            {title && <h1 className="uk-h3">{title}</h1>}
          </div>
          <div className="uk-modal-body">
            {children &&
            <div className="pixie__modal-content">
              <p>{children}</p>
            </div>}
          </div>
          <div className="uk-modal-footer uk-text-right">
            <div
              className={classesArray([
                'uk-grid',
                'uk-grid-small',
                'uk-child-width-auto',
                'uk-flex-right'
              ])}
              data-uk-grid
            >
              <div>
                <Button
                  disabled={false}
                  buttonStyle="outline"
                  id="CloseCancelModal"
                  text="Cancel"
                  type="button"
                  ukModalClose
                />
              </div>
              <div>
                <Button
                  buttonStyle="warning"
                  text="Ok"
                  ukModalClose
                  onClick={onClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled.ExternalLinkModal>);
};

ExternalLinkModal.defaultProps = {
  children: null,
  id : null,
  title: '',
  onClick: () => {}
};

export default ExternalLinkModal;
