import CONFIG from '@pixie/config';
import { usePixieRefreshToken } from './use-pixie-refresh-token';
import { useApigeeRefreshToken } from './use-apigee-refresh-token';

const { SESSION } = CONFIG;

const useRefreshTokens = () => {
  // hooks
  const pixieRefreshToken = usePixieRefreshToken();
  const apigeeRefreshToken = useApigeeRefreshToken();

  return async () => {
    // IMPORTANT: order here matters... apigee refresh token depends on valid pixie token

    // refresh the apigee token FIRST
    const apigeeRefreshTokenResponse = await apigeeRefreshToken();
    if (apigeeRefreshTokenResponse == null) {
      // invalid response... bail
      return;
    }

    const pixieRefreshTokenResponse = await pixieRefreshToken();
    if (pixieRefreshTokenResponse == null) {
      // invalid response... bail
      return;
    }

    // store the next token refresh timestamp
    const refreshFrequencyInMinutes = 15;
    const nextTokenRefreshAt = new Date(new Date().getTime() + refreshFrequencyInMinutes * 60000);
    window?.sessionStorage.setItem(SESSION.NEXT_TOKEN_REFRESH_AT, nextTokenRefreshAt.getTime().toString());
  };
};

export { useRefreshTokens };

export default useRefreshTokens;
