import React, { ReactElement } from 'react';
import { Card, ReadonlyFormText } from '@pixie/components';
import * as Styled from './InfoCard.styled';

interface IInfoCardProps {
  cardStyle: string;
  cardTitle: string;
  data: any;
  gridClasses: string;
  isLoading?: boolean;
}

const InfoCard = (props: IInfoCardProps): ReactElement => {
  const {
    cardStyle,
    cardTitle,
    data,
    gridClasses,
    isLoading } = props;

  return (
    <Styled.InfoCard>
      <span className="uk-animation-fade">
        <div className='card-title'>{cardTitle}</div>
        {isLoading && (
          <span className="uk-spinner" data-uk-spinner="ratio: 0.5;" />
        )}
      </span>

      {!isLoading && (
        <Card style={cardStyle}>
          <div className={gridClasses} data-uk-grid>
            {data.map(
              (item: {
                dataTestId: string;
                label: string;
                value: string;
                gridClass: string | undefined;
              }) => {
                const { label, value, gridClass } = item;
                return (
                  value !== null &&
                  value !== '' && (
                    <div className={gridClass || ''} key={label}>
                      {label === 'Primary Phone Number' ?
                        <>
                          <div className='pixie-h6'>{label}</div>
                          <span className="material-symbols-outlined phone-symbol">
                            call
                          </span>
                          <span className="pixie-h4 fax-and-phone">{value}</span>
                        </>
                        : <></>
                     }
                      {label === 'Fax Number' ?
                        <>
                          <div className='pixie-h6'>{label}</div>
                          <span className="material-symbols-outlined fax-symbol">
                            fax
                          </span>
                          <span className="pixie-h4 fax-and-phone">{value}</span>
                        </>
                        : <></>
                       }
                      {label !== 'Fax Number' && label !== 'Primary Phone Number' ?
                        <ReadonlyFormText
                          dataTestId={item.dataTestId}
                          labelClassName='pixie-h6'
                          textClassName='pixie-h4'
                          label={label}
                          textContent={value}
                        />
                        : <></>}

                    </div>
                  )
                );
              }
            )}
          </div>
        </Card>
      )}
    </Styled.InfoCard>
  );
};

InfoCard.defaultProps = {
  isLoading: true
};

export default InfoCard;
