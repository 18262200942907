import { createSlice } from '@reduxjs/toolkit';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  caseCode: null,
  caseIsPriority: false,
  caseNumber: null,
  caseStatus: null,
  caseStatusReason: null,
  payerCaseId: null,
  responseMessageId: null,
  userFriendlyMessage: null
};

const initialState = DEFAULT_STATE;

const caseInfoSlice = createSlice({
  name: 'caseInfo',
  initialState,
  reducers: {
    clearCaseError: state => clearError(state),
    clearCaseSuccess: clearSuccess,
    resetCaseInfo: () => DEFAULT_STATE,
    setCaseCode(state, { payload }) {
      state.caseCode = payload || null;
    },
    setCaseError: loadingFailed,
    setCaseInfo(state, { payload }) {
      const {
        caseCode,
        caseStatus,
        caseStatusReason,
        initialSystemTrackingId,
        payerCaseId,
        priority,
        responseMessageId,
        userFriendlyMessage
      } = payload;

      state.caseCode = caseCode || null;
      state.caseIsPriority = priority || false;
      state.caseNumber = initialSystemTrackingId || null;
      state.caseStatus = caseStatus || null;
      state.caseStatusReason = caseStatusReason || null;
      state.responseMessageId = responseMessageId || null;
      state.userFriendlyMessage = userFriendlyMessage || null;
      state.payerCaseId = payerCaseId || null;
    },
    setCaseNumber(state, { payload }) {
      state.caseNumber = payload || null;
    },
    setCasePriority(state, { payload }) {
      state.caseIsPriority = payload || null;
    },
    setCaseStatus(state, { payload }) {
      state.caseStatus = payload || null;
    },
    setCaseStatusReason(state, { payload }) {
      state.caseStatusReason = payload || null;
    },
    setCaseSuccess: loadingSuccess,
    startCaseLoading: startLoading,
    stopCaseLoading: stopLoading
  }
});

export const {
  clearCaseError,
  clearCaseSuccess,
  resetCaseInfo,
  setCaseCode,
  setCaseError,
  setCaseInfo,
  setCaseNumber,
  setCasePriority,
  setCaseStatus,
  setCaseStatusReason,
  setCaseSuccess,
  startCaseLoading,
  stopCaseLoading
} = caseInfoSlice.actions;
export default caseInfoSlice.reducer;

/**
 * Sequence for loading and setting case information.
 * @param {Object} data
 */
export const fetchCaseInfo = data => async dispatch => {
  try {
    dispatch(startCaseLoading());
    dispatch(setCaseInfo(data));
    dispatch(setCaseSuccess());
  } catch (err) {
    dispatch(setCaseError(String(err)));
  }
};
