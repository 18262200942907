import {
  IDate,
  IFreeText,
  INumeric,
  IQuestion,
  IQuestionType,
  ISelect
} from '@pixie/api';
import CONFIG from '@pixie/config';
import { freeTextValidation } from '@pixie/regex';
import getQuestionType from './get-question-type';

const {
  QUESTIONS: {
    QUESTION_TYPES: {
      CHECKBOX,
      DATE,
      END,
      FILE_UPLOAD,
      FREE_TEXT,
      NUMERIC,
      RADIO
    }
  }
} = CONFIG;

/**
 * Logic to handle `questionType.date`
 * @memberof handleQuestionSequence
 */
const handleDateQuestion = (obj: IDate) => {
  if (
    obj &&
    obj !== null &&
    obj?.answer &&
    obj?.answer !== null &&
    obj?.answer?.prescriberProvidedDateAnswer &&
    obj?.answer?.prescriberProvidedDateAnswer !== null
  ) {
    return false;
  }

  return true;
};

/**
 * Logic to handle `questionType.freeText`
 * prevent user from progressing with only space/new line entered
 * @memberof handleQuestionSequence
 */
const handleFreeTextQuestion = (obj: IFreeText) => {
  if (obj && obj !== null && obj?.answer && obj?.answer !== null) {
    if (
      obj?.answer?.prescriberProvidedFreeTextAnswer === null ||
      obj?.answer?.prescriberProvidedFreeTextAnswer === '' ||
      !(/[\x21-\x7E]/g.test(obj?.answer?.prescriberProvidedFreeTextAnswer))
    ) {
      return true;
    }

    return false;
  }

  return true;
};

/**
 * Logic to handle `questionType.select.selectMultiple === true`
 * @memberof handleQuestionSequence
 */
const handleMultiSelectQuestion = (obj: ISelect) => {
  if (
    obj &&
    obj !== null &&
    obj?.answer &&
    obj?.answer !== null &&
    obj?.answer?.prescriberProvidedSelectAnswer &&
    obj?.answer?.prescriberProvidedSelectAnswer !== null &&
    obj?.answer?.prescriberProvidedSelectAnswer?.length !== 0
  ) {
    return false;
  }

  return true;
};

/**
 * Logic to handle `questionType.numeric`
 * @memberof handleQuestionSequence
 */
const handleNumericQuestion = (obj: INumeric) => {
  if (obj !== null && obj?.answer && obj?.answer !== null) {
    if (
      obj?.answer?.prescriberProvidedNumericAnswer === null ||
      obj?.answer?.prescriberProvidedNumericAnswer === ''
    ) {
      return true;
    }

    return false;
  }

  return true;
};

/**
 * Logic to handle `questionType.select.selectMultiple === false`
 * @memberof handleQuestionSequence
 */
const handleSelectQuestion = (obj: ISelect) => {
  if (
    obj &&
    obj !== null &&
    obj?.answer &&
    obj?.answer !== null &&
    obj?.answer?.prescriberProvidedSelectAnswer &&
    obj?.answer?.prescriberProvidedSelectAnswer !== null &&
    obj?.answer?.prescriberProvidedSelectAnswer?.length === 1
  ) {
    const answerObj = obj?.answer?.prescriberProvidedSelectAnswer[0];

    if (answerObj?.additionalFreeTextIndicator === 'M') {
      if (answerObj?.additionalFreeText === null) return true;
      if (freeTextValidation(answerObj?.additionalFreeText?.trim()) === '') return true;
      return false;
    }

    return false;
  }

  return true;
};

/**
 * Logic regarding which type of question function to run.
 * @member handleQuestionSequence
 * @memberof handleNextStepIsDisabled
 */
const handleQuestionSequence = (questionType: IQuestionType) => {
  const { date, freeText, numeric, select } = questionType;

  switch (getQuestionType(questionType)) {
    case CHECKBOX:
      return handleMultiSelectQuestion(select);
    case DATE:
      return handleDateQuestion(date);
    case FREE_TEXT:
      return handleFreeTextQuestion(freeText);
    case NUMERIC:
      return handleNumericQuestion(numeric);
    case RADIO:
      return handleSelectQuestion(select);
    default:
      return true;
  }
};

/**
 * Handles the file upload sequence of the member.
 * @memberof handleNextStepIsDisabled
 */
const handleFileUploadSequence = (arrayLength: number) => {
  if (arrayLength !== 0) return false;
  return true;
};

/**
 * Returns boolean value to determine if the
 * 'Next Step' button is disabled or not.
 * @name handleNextStepIsDisabled
 * @requires handleQuestionSequence
 * @requires handleFileUploadSequence
 */
const handleNextStepIsDisabled = (
  currentQuestion: IQuestion,
  currentQuestionId: string = '',
  fileUploadsLength: number = 0,
  nextQuestionId: string = ''
) => {
  if (currentQuestion !== undefined) {
    if (
      !currentQuestion.questionType ||
      currentQuestion.questionType === null
    ) {
      return true;
    }

    return handleQuestionSequence(currentQuestion.questionType);
  }

  if (currentQuestionId === FILE_UPLOAD) {
    return handleFileUploadSequence(fileUploadsLength);
  }

  if (currentQuestionId === END) {
    return true;
  }

  if (nextQuestionId === '') {
    return true;
  }

  return false;
};

export default handleNextStepIsDisabled;
