import React, { ReactElement, useState } from 'react';
import { Modal } from '@pixie/components';
import CONFIG from '@pixie/config';
import { BuyAndBillForm } from '@pixie/features';
import {  useSelector } from 'react-redux';
import { RootState } from '@pixie/store';
import { IRequestDetails, IInitiationConfirmationModel } from '@pixie/api';
import InitiationConfirmationForm from './InitiationConfirmationForm';
import * as Styled from './InitiationConfirmationForm.styled';

const {
  TASKS: { TASK_NAMES }
} = CONFIG;

interface IIntiationConfirmationModalProps {
  data: IRequestDetails;
  handleRefreshRequest: any;
  useSignOut: any;
  showBuyAndBill: boolean;
  setShowBuyAndBill: any;
}
const InitiationConfirmationModal = (props: IIntiationConfirmationModalProps): ReactElement => {

  const {
    data,
    handleRefreshRequest,
    useSignOut,
    showBuyAndBill,
    setShowBuyAndBill
  } = props;

  const date = new Date().toLocaleString('en-us', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const selectedEligiblityPlan = useSelector((state: RootState) => state.selectedEligiblityPlan);
  const [initiationConfirmationModel, setInitiationConfirmationModel] = useState<IInitiationConfirmationModel>({
    drugPrescribed: null, // do not select by default
    productCodeValue: null, // do not select by default
    daysSupply: data?.medicationPrescribed[0]?.daysSupply,
    quantityRequestValue: data?.medicationPrescribed[0]?.quantityRequestValue,
    quantityUnitOfMeasureCode: null,
    diagnosisCode: null, // do not select by default
    diagnosisDesc: null, // do not select by default
    prescriberEmail: null,
    isPriority: false,
    isDiagnosisOtherSelected: false,
    ebvQueryId: selectedEligiblityPlan?.ebvQueryId,
    eligibilityPlanPixieMessageId: selectedEligiblityPlan?.eligibilityPlanPixieMessageId,
    isBuyAndBill:  false,
    patientWeight: null, // do not select by default
    patientHeight: null,
    placeOfService: null,
    billingCode: null,
    numberOfVisits: null,
    unitsBilled: null,
    numberOfDosages: null,
    priorAuthRequestTiming: 'pre',
    startDate: date, // current date
    endDate: null,
    facilityName: data?.prescriber?.prescriberClinicName,
    providerFirstName: data?.prescriber?.prescriberFirstName,
    providerLastName: data?.prescriber?.prescriberLastName,
    providerAddress: data?.prescriber?.prescriberAddressLine1,
    providerUnit: null,
    providerCity: data?.prescriber?.prescriberCity,
    providerState: data?.prescriber?.prescriberStateProvince,
    providerZip: data?.prescriber?.prescriberPostalCode,
    providerPhoneNumber: data?.prescriber?.prescriberPrimaryTelephoneNumber,
    providerFaxNumber: data?.prescriber?.prescriberFaxNumber,
    providerNpi: data?.prescriber?.prescriberNpi,
    ancillaryServices: [{
      serviceCode: null,
      codeType: null,
      index: 0
    }],
  });
  if (data == null ||
    data.tasks == null ||
    !(data.tasks.length === 1 && data.tasks[0].name === TASK_NAMES.INITIATE_PA)){
    return <></>;
  }

  return (
    <Styled.InitiationConfirmationModal>
      { !showBuyAndBill ? (
        <Modal
          id="InitiationConfirmationModal"
          dataTestId='initiation-confirmation-modal'
          isOpen
          shouldCloseOnEscKey={false}
          shouldCloseOnOverlayClick={false}
        >
          <button
            aria-label="Close"
            className="uk-modal-close-default uk-close-large close-modal-button"
            data-testId="initiation-awaiting-response-close-button"
            data-uk-close
            type="button"
          />
          <InitiationConfirmationForm
            data={data}
            setShowBuyAndBill={setShowBuyAndBill}
            initiationConfirmationModel={initiationConfirmationModel}
            setInitiationConfirmationModal={setInitiationConfirmationModel}
          />
        </Modal>
      ) : (
        <BuyAndBillForm
          data={data}
          handleRefreshRequest={handleRefreshRequest}
          setShowBuyAndBill={setShowBuyAndBill}
          initiationConfirmationModel={initiationConfirmationModel}
          setInitiationConfirmationModal={setInitiationConfirmationModel}
          useSignOut={useSignOut}
        />
      )

    }

    </Styled.InitiationConfirmationModal>
  );
};
export default InitiationConfirmationModal;
