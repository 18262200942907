import React, { ReactElement } from 'react';
import { classesArray } from '@pixie/utils';
import * as Styled from './TextInput.styled';

export interface Props {
  className?: string;
  errorMessage?: string;
  dataTestId?: string;
  disabled?: boolean;
  forwardRef?: any;
  hint?: string;
  id?: string;
  label?: string;
  min?:string;
  onBlur?: any;
  onChange?: any;
  onKeyPress?:any;
  onKeyUp?:any;
  onPaste?: any;
  isErrorSymbol?: boolean;
  pattern?:any;
  placeholder?: string;
  required?: boolean;
  type?: string;
  unit?: string;
  unitLabel?: string;
  value?: string;
  maxLength?: number;
}

const TextInput = ({
  className,
  dataTestId,
  disabled,
  errorMessage,
  forwardRef,
  hint,
  id,
  label,
  unit,
  unitLabel,
  min,
  onBlur,
  onChange,
  onKeyPress,
  onKeyUp,
  onPaste,
  isErrorSymbol,
  pattern,
  placeholder,
  required,
  type,
  value,
  maxLength
}: Props): ReactElement => {
  switch (label) {
    case label:
      return (
        <Styled.Label
          className={classesArray([
            errorMessage ? 'has-error' : '',
            required ? 'is-required' : ''
          ])}
          htmlFor={id}
        >
          {label !== null ? (
            <div className="label-container">
              <span className="label">{label}</span>
              {hint ? <span className="hint">{hint}</span> : null}
            </div>
          ) : null}
          <input
            data-testid={dataTestId}
            autoComplete="off"
            disabled={!!disabled}
            id={id}
            min={min}
            onBlur={onBlur}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            onPaste={onPaste}
            pattern={pattern}
            placeholder={placeholder}
            readOnly={!!disabled}
            ref={forwardRef}
            type={type}
            value={value}
            className={className}
            maxLength={maxLength}
          />
          <span className={unitLabel}> {unit}</span>
          {errorMessage ? (
            <div
              data-testid={`${dataTestId}-error-message`}
              className="error-container"
            >
              {isErrorSymbol ? (
                <span className="material-symbols-outlined error-symbol">
                  error
                </span>
              ) : null}
              <span className="error-message">{errorMessage}</span>
            </div>
          ) : null}
        </Styled.Label>
      );

    default:
      return (
        <input
          data-testid={dataTestId}
          autoComplete="off"
          disabled={!!disabled}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={!!disabled}
          ref={forwardRef}
          type="text"
          value={value}
          maxLength={maxLength}
        />
      );
  }
};

TextInput.defaultProps = {
  className:'pixie-input-box',
  errorMessage: null,
  disabled: false,
  hint: null,
  id: null,
  label: null,
  isErrorSymbol: false,
  unit: null,
  onBlur: () => {},
  onChange: () => {},
  placeholder: null,
  required: false,
  type:'text',
  maxLength : 9999999
};

export default TextInput;
