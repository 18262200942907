import styled from 'styled-components';

export const LoadingBar = styled.span`
  .initiationAwaitingResponseModalContainer {
    height: 20px;
    background-color: #e1e1e1;
    border-radius: 25px;
    margin: 20px;
  }

  .initiationAwaitingResponseModalFiller {
    height: 100%;
    /* background-color: #1a4677; */
    background-color: var(--color-button);
    /* background-color: var(--color-primary--50); */
    border-radius: inherit;
  }

  .initiationAwaitingResponseModalLabel {
    background-color: #90d3e1;
  }
`;
