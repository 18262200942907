import styled from 'styled-components';

export const AppealInput = styled.div`
  .uk-h2,
  .uk-h3 {
    font-weight: bold;
  }

  .uk-text-meta {
    font-size: 0.75rem;
  }

  div[data-is-active] {
    position: relative;
  }

  div[data-is-active='true'] {
    display: block;
    z-index: 1;
  }

  div[data-is-active='false'] {
    display: none;
  }

  /* @todo figure out a textarea resizer that
   * works with the <AnimateHeight /> component
   */
  textarea {
    /* resize: vertical; */
    resize: none;
  }

  .buttons-container {
    padding: 40px 0;
    margin: 40px 0;
    border-top: 1px solid var(--color-tertiary--100);
  }

  .buttons-container button {
    width: 100%;
  }

  .rah-static {
    overflow: visible !important;
  }
`;
