import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
  type: '',
  message: ''
};

const initialState = DEFAULT_STATE;

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    clearAlertMessage() {
      return DEFAULT_STATE;
    },
    setAlertMessage(state, { payload }) {
      const { message, type } = payload;

      state.message = message;
      state.type = type;
    }
  }
});

export const { clearAlertMessage, setAlertMessage } = alertsSlice.actions;
export default alertsSlice.reducer;
