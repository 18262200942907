import { Select, TextInput,  Button, Radio } from '@pixie/components';
import React, { ReactElement, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { IRequestDetails, IInitiationConfirmationModel, IMedication,
  IDiagnosis, sourceProgramRequest, submitInitiatePaRequest } from '@pixie/api';
import {   setShowSubmitModalTrue  } from '@pixie/features';
import { matchesEmailRegEx, matchesRegexICD10 } from '@pixie/regex';
import {  useSelector,  useDispatch } from 'react-redux';
import { RootState } from '@pixie/store';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { formatDate, isBuyAndBillPayer } from '@pixie/utils';
import * as Styled from './InitiationConfirmationForm.styled';



interface InitiationConfirmationFormProps {
  data: IRequestDetails;
  setShowBuyAndBill: any;
  setInitiationConfirmationModal: any;
  initiationConfirmationModel: IInitiationConfirmationModel;
}
const InitiationConfirmationForm = (props: InitiationConfirmationFormProps): ReactElement => {

  const {
    data,
    setShowBuyAndBill,
    setInitiationConfirmationModal,
    initiationConfirmationModel
  } = props;

  const dispatch = useDispatch();

  const selectedEligiblityPlan = useSelector((state: RootState) => state.selectedEligiblityPlan);
  const { trackEvent } = useMatomo();

  const [allDiagnosisOptions, setAllDiagnosisOptions] = useState<IDiagnosis[]>([]);
  const [allMedicationOptions, setAllMedicationOptions] = useState<IMedication[]>([]);
  const [medicationSelected, setMedicationSelected] = useState<string>('');
  const [diagnosisSelected, setDiagnosisSelected] = useState<string>('');
  const [medicationUnit, setMedicationUnit] = useState<string>('');
  const [inputEmailErrorMessage, setInputEmailErrorMessage] = useState<string>();
  const [inputDiagnosisDescErrorMessage, setInputDiagnosisCodeErrorMessage] = useState<string>();
  const [inputQuantityErrorMessage, setInputQuantityErrorMessage] = useState<string>();
  const [inputDaySupplyErrorMessage, setInputDaysSupplyErrorMessage] = useState<string>();
  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState<boolean>(false);

  useEffect(() => {
    // set defaults in initial render
    sourceProgramRequest(data.faxTemplateCode)
      .then((res: AxiosResponse) => handleResponse('success', res))
      .catch((err: AxiosError) => handleResponse('alert', err));
  }, [data.faxTemplateCode]);

  const handleResponse = (type: string, obj: any) => {
    switch (type) {
      case 'success':
        if (obj.result !== null && obj.result.detailJson !== null){
          setAllMedicationOptions(obj.result.detailJson.medications);
          setAllDiagnosisOptions(obj.result.detailJson.diagnoses);
        }
        break;
      case 'alert':
      default:

    }
  };

  const getMedicationNameOptions = () =>{
    return allMedicationOptions.map((item: { unit: string; name: string, ndc: string, quom:string }) => {
      return {
        value: item.name,
        label: item.name,
        ndc: item.ndc,
        quom: item.quom,
        unit: item.unit
      };
    });
  };

  const getDiagnosisCodeOptions = () =>{
    const diagnosisOptions =  allDiagnosisOptions.map((item: { id: string; description: string }) => {
      return {
        value: item.id,
        label: `${ item.id } - ${ item.description }`,
        description: item.description
      };
    });
    diagnosisOptions.push({ value:'Other', label:'Other', description:'Other' });
    return diagnosisOptions;
  };

  // TODO: Add check for if Buy and Bill is selected then travel to medical UI instead
  const onClickSubmit = () => {

    if (validateInputFields()) {

      if (isBuyAndBillEpa() && initiationConfirmationModel.isBuyAndBill) {
        trackEvent({
          category: 'Submit PA',
          action: 'Submit Prior Authorization',
          name: 'Medical ePA with Buy and Bill'
        });
        setShowBuyAndBill(true);
      } else {
        trackEvent({
          category: 'Submit PA',
          action: 'Submit Prior Authorization',
          name: 'Pharma ePA submission'
        });
        setIsLoadingSubmitButton(true);
        setInitiationConfirmationModal({ ...initiationConfirmationModel, ebvQueryId: selectedEligiblityPlan?.ebvQueryId, eligibilityPlanPixieMessageId: selectedEligiblityPlan?.eligibilityPlanPixieMessageId });
        submitInitiatePaRequest(data.caseCode, initiationConfirmationModel
        ).then(() => {
          setIsLoadingSubmitButton(false);

          dispatch(setShowSubmitModalTrue(''));

        }).catch((err) => {
          setIsLoadingSubmitButton(false);
        });
      }
    }

  };

  const validateInputFields = () => {
    let isSuccess = true;
    setInputQuantityErrorMessage(null);
    setInputDaysSupplyErrorMessage(null);
    setInputEmailErrorMessage(null);
    if (initiationConfirmationModel?.quantityRequestValue?.toString() === '0'
        || initiationConfirmationModel?.quantityRequestValue?.toString().includes('-')){
      isSuccess = false;
      setInputQuantityErrorMessage('Quantity must be at greater than 0');
    }

    if (initiationConfirmationModel?.daysSupply?.toString() === '0'){
      isSuccess = false;
      setInputDaysSupplyErrorMessage('Day Supply must be at least 1');
    }

    if (!validateDiagnosis()){
      isSuccess = false;
    }
    if (initiationConfirmationModel?.prescriberEmail !== null && !matchesEmailRegEx(initiationConfirmationModel.prescriberEmail)){
      setInputEmailErrorMessage('Invalid email format');
      isSuccess = false;
    }
    return isSuccess;
  };

  const validateDiagnosis = () => {
    setInputDiagnosisCodeErrorMessage(null);
    if (initiationConfirmationModel.diagnosisCode === null ||
        !matchesRegexICD10(initiationConfirmationModel.diagnosisCode)){
      setInputDiagnosisCodeErrorMessage('Invalid ICD-10 code format');
      return false;
    }
    return true;
  };


  const onChangeDiagnosisCodeDescription = (args) => {
    setDiagnosisSelected(args);
    if (args.value === 'Other'){
      setInitiationConfirmationModal({ ...initiationConfirmationModel, isDiagnosisOtherSelected : true, diagnosisCode: null, diagnosisDesc: null });
      setInputDiagnosisCodeErrorMessage(null);
      return;
    }
    setInitiationConfirmationModal({ ...initiationConfirmationModel, isDiagnosisOtherSelected : false, diagnosisCode : args.value, diagnosisDesc : args.description });

  };

  const onChangeFormField = prop => event => {
    setInitiationConfirmationModal({ ...initiationConfirmationModel, [prop]: event.target.value || event.value });
  };

  const onChangeBuyAndBillForm = prop => event => {
    if (event?.target?.value === 'Yes' || event?.value === 'Yes') {
      setInitiationConfirmationModal({ ...initiationConfirmationModel, isBuyAndBill: true });

    } else {
      setInitiationConfirmationModal({ ...initiationConfirmationModel, isBuyAndBill: false });

    }
  };

  const onChangeCaptureIsPriority = (e: React.TouchEvent | React.MouseEvent) => {
    setInitiationConfirmationModal({ ...initiationConfirmationModel, isPriority: !initiationConfirmationModel.isPriority });
  };

  const onChangeMedicationName = (args) =>{
    setMedicationSelected(args);
    setMedicationUnit(args.unit);
    setInitiationConfirmationModal({ ...initiationConfirmationModel,
      quantityUnitOfMeasureCode : args.quom,
      drugPrescribed: args.label,
      productCodeValue: args.ndc });
  };


  const getPatientDateOfBirth = () => {
    return formatDate(data?.patient?.patientDateOfBirth);
  };
  const getPatientName = () =>{
    return `${data.patient.patientFirstName  } ${  getPatientMiddleInitial()  } ${  data.patient.patientLastName}`;
  };
  const getPatientMiddleInitial = () => {
    if (data.patient.patientMiddleName != null){
      return `${data.patient.patientMiddleName.substring(0, 1)}.`;
    }
    return '';
  };

  const isSubmitButtonDisabled = () => {
    return (initiationConfirmationModel?.daysSupply != null && initiationConfirmationModel?.diagnosisCode != null
      && initiationConfirmationModel?.diagnosisDesc != null && initiationConfirmationModel?.quantityRequestValue != null
      && initiationConfirmationModel?.drugPrescribed != null);
  };

  const onQuantityKeyUp = (event: any) =>{
    return (event < 0 ? event * -1 : event);
  };

  const onNumberFieldKeyPress = (event: any)=>{
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onNumberFieldPaste = (event: any) => {
    // Need to prevent pasting of the 'e' character, as number fields allow e but not other letters
    if (event?.clipboardData?.getData('Text')?.toUpperCase()?.indexOf('E') > -1) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  if (data == null || data.patient == null || data.prescriber == null || data.medicationPrescribed == null) {
    return null;
  }

  function isBuyAndBillEpa() {
    return isBuyAndBillPayer(data, true);
  }

  return (
    <Styled.InitiationConfirmationForm data-testid="test">
      <form className="pixie-form">
        <div className="pixie-body-header">
          <span >
            <img
              className="pixie-cah-medical-cross-img"
              src="../../../Medical-Cross.svg"
              alt='Cardinal Health'
              style={{ height: 'auto', width: 'auto' }}
            />
            <span className='confirmation-header'> Confirm Patient Prior Authorization Details</span>
          </span>
        </div>
        <div className="pixie-outline">
          <div className="pixie-header">
            <div>
              <img
                className="pixie-cah-medical-cross-img"
                src="../../../Patient.png"
                alt='Cardinal Health'
                style={{ height: 'auto', width: 'auto' }}
              />
              <span className="patient-information-header">Patient Information</span>

            </div>
            <div className="pixie-patient-name" data-testId='initiation-confirmation-modal-patient-name'>
              <b>{ getPatientName() }</b>
            </div>
            <div className="pixie-header-text">
              <div>
                <span
                  className="pixie-header-text-one"
                  data-testId='initiation-confirmation-modal-date-of-birth'
                ><b className="header-font-text">Date of Birth</b><br />
                  <span className="pixie-patient-info-text">
                    {getPatientDateOfBirth()}
                  </span>
                </span>

                <span
                  className="pixie-header-text-one"
                  data-testId='initiation-confirmation-modal-prescribing-physcician'
                ><b className="header-font-text">Prescribing Physician:</b><br />
                  <span className="pixie-patient-info-text">
                    {`${data.prescriber.prescriberFirstName  } ${  data.prescriber.prescriberLastName}`}
                  </span>
                </span>
                <span
                  className="pixie-header-text-two"
                  data-testId='initiation-confirmation-modal-medication-prior-authorization'
                ><b className="header-font-text">Medication For Prior Authorization:</b><br />
                  <span className="pixie-patient-info-text">
                    {`${data.medicationPrescribed[0].drugDescription}`}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <hr className="divider-line" />
          <div className="pixie-body">
            <div className="pixie-modal-header">
              <span className="material-symbols-outlined prescription-symbol">
                prescriptions
              </span>
              <span className='pixie-modal-medication-header'>Confirm Patient Medication & Diagnosis</span>
            </div>
            <div className="pixie-body-inputs">
              <div className="pixie-medication" data-testid='initiation-confirmation-medication-name'>
                <legend className="pixie-legend-required">Medication Name </legend>
                <Select
                  classNamePrefix="pixie__select__modal"
                  placeholder=''
                  dataTestId='initiation-confirmation-modal-medication-select'
                  value={medicationSelected}
                  options={getMedicationNameOptions()}
                  onChange={onChangeMedicationName}
                />
              </div>
            </div>
            <div className="pixie-lower-inputs" >
              <div className="pixie-icd-code">
                <legend className="pixie-legend-required"> ICD-10 Diagnosis Code/Description </legend>
                <Select
                  classNamePrefix="pixie__select__modal"
                  placeholder=''
                  dataTestId='initiation-confirmation-modal-diagnosis-select'
                  options={getDiagnosisCodeOptions()}
                  value={diagnosisSelected}
                  onChange={onChangeDiagnosisCodeDescription}
                />
              </div>
              <div className="pixie-body-margin">
                <div className="pixie-body-quantity-legend">
                  <legend className="pixie-legend-required">Quantity </legend>
                  {/* <legend className="pixie-legend-unit-of-measure"> {medicationUnit?.toString()}</legend> */}
                </div>
                <TextInput
                  errorMessage={inputQuantityErrorMessage}
                  type="number"
                  unit={medicationUnit?.toString().length > 8 ? medicationUnit?.toString().substring(0, 8) : medicationUnit?.toString()}
                  unitLabel='quantity-unit'
                  min='0'
                  onKeyPress={(event:any) => onNumberFieldKeyPress(event)}
                  onKeyUp={(event: any) => onQuantityKeyUp(event.target.value)}
                  onPaste={(event: any) => onNumberFieldPaste(event)}
                  dataTestId='initiation-confirmation-quantity'
                  value={initiationConfirmationModel?.quantityRequestValue?.toString()}
                  className="confirmation-modal"
                  onChange={onChangeFormField('quantityRequestValue')}
                />
              </div>
              <div className="pixie-body-margin">
                <legend className="pixie-legend-required">Days Supply</legend>
                <TextInput
                  errorMessage={inputDaySupplyErrorMessage}
                  onKeyPress={(event:any) => onNumberFieldKeyPress(event)}
                  onPaste={(event: any) => onNumberFieldPaste(event)}
                  dataTestId='initiation-confirmation-modal-days-supply'
                  type="number"
                  value={initiationConfirmationModel?.daysSupply?.toString()}
                  className="confirmation-modal"
                  onChange={onChangeFormField('daysSupply')}
                />
              </div>
            </div>
            { initiationConfirmationModel?.isDiagnosisOtherSelected ?
              <div className="pixie-icd-other-section">
                <div className="pixie-icd-other-code">
                  <legend className="pixie-legend-required">ICD-10 Diagnosis Code </legend>
                  <TextInput
                    errorMessage={inputDiagnosisDescErrorMessage}
                    dataTestId='initiation-confirmation-modal-diagnosis-code'
                    className="confirmation-modal"
                    value={initiationConfirmationModel?.diagnosisCode}
                    onChange={onChangeFormField('diagnosisCode')}
                  />
                </div>
                <div className="pixie-icd-other-description">
                  <legend className="pixie-legend-required">Description</legend>
                  <TextInput
                    dataTestId='initiation-confirmation-diagnosis-description'
                    className="confirmation-modal"
                    value={initiationConfirmationModel?.diagnosisDesc}
                    onChange={onChangeFormField('diagnosisDesc')}
                  />
                </div>
              </div> : null
      }
            <div className="pixie-body-inputs-email">
              <legend className="pixie-legend-optional">Physician Email (optional)</legend>
              <TextInput
                dataTestId='initiation-confirmation-modal-email'
                className="confirmation-modal"
                errorMessage={inputEmailErrorMessage}
                value={initiationConfirmationModel?.prescriberEmail}
                onChange={onChangeFormField('prescriberEmail')}
              />
            </div>
            { isBuyAndBillEpa() && (
            <div className="pixie-buy-and-bill">
              <span className="pixie-buy-and-bill-text">Will your organization buy and bill this medication?<br /></span>
              <span className='pixie-buy-and-bill-radio'>
                <Radio
                  data-testId='pixie-buy-and-bill-yes'
                  id='pixie-buy-and-bill-yes'
                  name='pixie-buy-and-bill'
                  onClick={onChangeBuyAndBillForm('isBuyAndBill')}
                  label='Yes'
                />
              </span>
              <span className='pixie-buy-and-bill-radio'>
                <Radio
                  data-testId='pixie-buy-and-bill-no'
                  id='pixie-buy-and-bill-no'
                  name='pixie-buy-and-bill'
                  checked
                  onClick={onChangeBuyAndBillForm('isBuyAndBill')}
                  label='No'
                />
              </span>

            </div>
            )}
            <div className="pixie-checkbox-background">
              <div className="pixie-checkbox">
                {/* TODO - consider using our own custom checkbox component */}
                <input
                  data-testid='initiation-modal-checkbox'
                  name="isPriority"
                  onChangeCapture={(e: any) => onChangeCaptureIsPriority(e)}
                  type="checkbox"
                  value={initiationConfirmationModel?.isPriority?.toString()}
                />
                <span className="pixie-checkbox-text">This prior authorization is a <b>PRIORITY</b></span>
              </div>
            </div>

            <div className="pixie-priority-text">
              <span> This prior authorization can be marked as a priority if waiting for a decision under
                the standard time frame could jeopardize the enrollee’s life, health, or ability to regain
                maximum function.
              </span>
            </div>
            <hr className="divider-line" />
          </div>
        </div>
        <div className="pixie-modal-button-test">
          <div>
            <Button
              buttonStyle="epa-modal"
              dataTestId='initiation-confirmation-modal-button'
              isLoading={isLoadingSubmitButton}
              disabled={!isSubmitButtonDisabled()}
              onClick={onClickSubmit}
              text="Submit Prior Authorization"
              type="button"
            />
          </div>
        </div>
      </form>
    </Styled.InitiationConfirmationForm>
  );
};
export default InitiationConfirmationForm;
