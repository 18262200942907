/**
 * Sets patient name grid item class.
 * @param str
 * @returns `uk-width-1-1` or `''`
 */
const handlePatientNameGrid = (str: string): string => {
  if (str?.length >= 20) return 'uk-width-1-1';
  return '';
};

export default handlePatientNameGrid;
