import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 32.3px;
  line-height: 1.2;

  @media (min-width: 960px) {
    font-size: 38px;
  }
`;

export const H2 = styled.h2`
  font-size: 25.5px;
  line-height: 1.3;

  @media (min-width: 960px) {
    font-size: 30px;
  }
`;

export const H3 = styled.h3`
  font-size: 24px;
  line-height: 1.4;
`;

export const H4 = styled.h4`
  font-size: 20px;
  line-height: 1.4;
`;

export const H5 = styled.h5`
  font-size: 15px;
  line-height: 1.4;
`;

export const H6 = styled.h6`
  font-size: 14px;
  line-height: 1.4;
`;
