import { closeAppealBar, openAppealBar } from '@pixie/features';

/**
 * Dispatches opening or closing of the Appeal bar.
 * @param event
 */
const handleAppealBar = (
  e: React.TouchEvent | React.MouseEvent,
  appealBarOpen: boolean
) => dispatch => {
  const target = e.target as HTMLTextAreaElement;
  e.preventDefault();
  target.blur();
  !appealBarOpen ? dispatch(openAppealBar()) : dispatch(closeAppealBar());
};

export default handleAppealBar;
