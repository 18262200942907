import React, { ReactElement } from 'react';
import * as Styled from './LoadingBar.styled';

interface ILoadingBar {
  containerStyle: string;
  dataTestId: string;
  fillerStyle: string;
  labelStyle: string;
  completedPercentage: number;
}

const LoadingBar = (props: ILoadingBar): ReactElement => {
  const { containerStyle,
    dataTestId,
    fillerStyle,
    labelStyle,
    completedPercentage
  } = props;

  return (
    <Styled.LoadingBar >
      <div data-testid={dataTestId} className={containerStyle}>
        <div style={{ width: `${completedPercentage}%` }} className={fillerStyle}>
          <span className={labelStyle} />
        </div>
      </div>
    </Styled.LoadingBar>
  );
};


export default LoadingBar;
