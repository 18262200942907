import { TextInput,  Button } from '@pixie/components';
import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getEligibilityRequest, IRequestDetails  } from '@pixie/api';
import { IEbvQuery } from '@pixie/interfaces';
import {
  fetchEbvBenefits,
  startEbvBenefitsLoading,
  stopEbvBenefitsLoading
} from '@pixie/features';
import { matchesEmailRegEx } from '@pixie/regex';
import * as Styled from './ebvConfirmationModal.styled';

interface InitiationConfirmationFormProps {
  data: IRequestDetails;
}
const EbvConfirmationForm = (props: InitiationConfirmationFormProps): ReactElement => {

  const {
    data
  } = props;

  const [prescriberEmail, setPrescriberEmail] = useState<string>('');
  const [inputEmailErrorMessage, setInputEmailErrorMessage] = useState<string>();
  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState<boolean>(false);
  const [appError, setAppError] = useState<String>('');
  const dispatch = useDispatch();

  const onClickSubmit = () => {
    // TODO: in future story will add email as value to include in passing back to eligibilityHistory
    if (prescriberEmail !== '' && !matchesEmailRegEx(prescriberEmail)){
      setInputEmailErrorMessage('Invalid email format');
    } else {
      setInputEmailErrorMessage('');
      setIsLoadingSubmitButton(true);
      getEligibilityRequest(data.caseCode).then((res: IEbvQuery) =>{
        dispatch(startEbvBenefitsLoading());
        dispatch(fetchEbvBenefits([res]));
        dispatch(stopEbvBenefitsLoading());
        setIsLoadingSubmitButton(false);
      }).catch(err =>{
        const errData = err?.response?.data;
        let errorMsg = ' ';

        if (typeof errData === 'object') {
          if (errData?.status && errData?.message) {
            errorMsg = `[${errData?.status}] ${errData?.message}`;
          } else if (errData?.status && errData?.error) {
            errorMsg = `[${errData?.status}] ${errData?.error}`;
          }
        }

        if (errorMsg.includes('undefined')) errorMsg = ' ';

        setAppError(errorMsg);
        setIsLoadingSubmitButton(false);
      });
    }
  };

  const getPatientDateOfBirth = () => {
    return data.patient.patientDateOfBirth?.substring(0, data.patient.patientDateOfBirth.indexOf('T'));
  };

  const getPatientName = () =>{
    return `${data.patient.patientFirstName  } ${  getPatientMiddleInitial()  } ${  data.patient.patientLastName}`;
  };

  const getPatientMiddleInitial = () => {
    if (data.patient.patientMiddleName != null && data.patient.patientMiddleName !== ''){
      return `${data?.patient?.patientMiddleName?.substring(0, 1)}.`;
    }
    return '';
  };

  const onChangeFormField = prop => event => {
    setPrescriberEmail( event.target.value || event.value );
  };

  return (
    <Styled.InitiationConfirmationForm data-testid="test">
      <form className="pixie-form">
        <div className="pixie-body-header">
          <h2 className="uk-h3" >
            <b> Benefits Verification</b>
          </h2>
        </div>
        <div className="pixie-outline">
          <div className="pixie-header">
            <h4 className="uk-h3" data-testId='ebv-confirmation-modal-patient-name'>
              <b>{ getPatientName() }</b>
            </h4>
            <div className="pixie-header-text">
              <span
                className="pixie-header-text-one"
                data-testId='ebv-confirmation-modal-date-of-birth'
              ><b>Date of Birth:&nbsp;</b>{getPatientDateOfBirth()}
              </span>
              <span
                className="pixie-header-text-one"
                data-testId='ebv-confirmation-modal-prescribing-physcician'
              ><b>Prescribing Physician:&nbsp;</b>{`${data.prescriber.prescriberFirstName  } ${  data.prescriber.prescriberLastName}`}
              </span>
            </div>
            <div>
              <span
                className="pixie-header-text-one"
                data-testId='ebv-confirmation-modal-benefits-verification'
              ><b>Medication For Benefits Verification:&nbsp;</b>{`${data.medicationPrescribed[0].drugDescription}`}
              </span>
            </div>
          </div>
          <hr className="horizontal-line" />
          <div className="pixie-body">
            <div className="pixie-modal-header">
              <b>Benefits Verification Details</b>
            </div>
            <div className="pixie-body-inputs">
              <div className="pixie-modal-text" data-testid='ebv-confirmation-medication-name'>
                <div className="pixie-text-header">Medication Name </div>
                <div className="pixie-text-field"><b>{`${data.medicationPrescribed[0].drugDescription}`}</b></div>
              </div>
              <div className="pixie-modal-text">
                <div className="pixie-body-medication">
                  <div className="pixie-text-header">Quantity</div>
                  <div className="pixie-text-field"><b>{`${data.medicationPrescribed[0].quantityRequestValue}`}</b></div>
                </div>
              </div>
              <div className="pixie-modal-text">
                <div className="pixie-text-header">Days Supply </div>
                <div className="pixie-text-field"><b>{`${data.medicationPrescribed[0].daysSupply}`}</b></div>
              </div>
            </div>
            <div className="pixie-lower-inputs">
              <legend><b>Physician Email (optional)</b></legend>
              <TextInput
                dataTestId='ebv-confirmation-modal-email'
                errorMessage={inputEmailErrorMessage}
                value={prescriberEmail}
                onChange={onChangeFormField('prescriberEmail')}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="pixie-modal-button-test">
          <div>
            <Button
              buttonStyle="ebvSubmitButton"
              dataTestId='ebv-confirmation-modal-button'
              isLoading={isLoadingSubmitButton}
              onClick={onClickSubmit}
              text="Submit Benefits Investigation"
              type="button"
            />
          </div>
        </div>
      </form>
    </Styled.InitiationConfirmationForm>
  );
};
export default EbvConfirmationForm;
