import { IRequestDetails } from '@pixie/api';
import CONFIG from '@pixie/config';
import { formatUserFriendlyMessages, formatDate } from '@pixie/utils';
import {
  IActionCard,
  generateActionCard,
  generateNoActionCard
} from './generate-action-card';

const {
  CASE: { CASE_STATUS },
  MESSAGES: {
    NO_ACTIONS_AVAILABLE_INSTRUCTIONS
  },
  TASKS: { TASK_STATUS },
} = CONFIG;

/**
 * Determines the next course of actionable instructions
 * to be displayed in the UI's ActionCard.tsx component.
 */
const determineActionCard = (data: IRequestDetails): IActionCard => {
  /**
   * If there is no data provided.
   */
  if (!data) {
    return generateNoActionCard();
  }

  /**
   * If the data provided doesn't have any `tasks` object property.
   */
  if (data && !data.tasks) {
    return generateNoActionCard();
  }

  /**
   * If the data provided has an empty tasks array.
   */
  if (data && data?.tasks && data.tasks.length === 0) {
    return generateNoActionCard();
  }

  const caseStatus = data?.caseStatus;
  const tasks = data && data?.tasks;
  const latestTask = tasks[tasks?.length - 1];
  const latestTaskStatus = latestTask?.status;
  const latestTaskQuestionSet = latestTask?.questionSet;
  const latestTaskQuestionHeader =
    latestTaskQuestionSet && latestTask?.questionSet?.header;

  const replacementTokens = { 'respondBy' : formatDate(latestTaskQuestionHeader?.openResponseDeadlineForReplyInDateTime) };
  const userFriendlyMessage = formatUserFriendlyMessages(data?.userFriendlyMessage, {
    replacementTokens
  })?.split('|') ?? [''];
  const actionHeader = userFriendlyMessage[0];
  userFriendlyMessage.shift();

  /**
   * IF actionHeader contains [{"respondBy",date}, {}]
   */
  /**
   * If the data provided has only 1 or less task(s).
   */
  if (tasks && tasks?.length === 1) {
    return generateNoActionCard(
      actionHeader, ['']
    );
  }

  /**
   * Determine further logic based on the top-level case status.
   * ---
   *  [APPROVED] ............ Case status is APPROVED
   *  [CANCELLED] ........... Case status is CANCELLED
   *  [CLOSED] .............. Case status is CLOSED
   *  [DENIED] .............. Case status is DENIED
   *  [ERROR] ............... Case status is ERROR
   *  [IN_PROGRESS] ......... Case status is IN_PROGRESS
   *  [PARTIALLY_DENIED] .... Case status is PARTIALLY_DENIED
   *  [null] ................ Case status is null
   */
  switch (caseStatus) {
    /**
     * ===================================================================
     * [APPROVED] Case status is APPROVED
     * ---
     *  [APPROVED-1] latestTaskStatus === APPROVED
     *    [APPROVED-1a] latestTask?.eappealSupported=true
     *      [APPROVED-1a.1] if (latestTask.name = 'PA_APPEAL')
     *      [APPROVED-1a.2] otherwise
     *    [APPROVED-1b] latestTask?.eappealSupported=false
     */
    case CASE_STATUS.APPROVED:
      switch (latestTaskStatus) {
        // --- [APPROVED-1]
        case TASK_STATUS.APPROVED:
          return generateNoActionCard(
            actionHeader, userFriendlyMessage
          );
        default:
          break;
      }
      break;

    /**
     * ===================================================================
     * [CANCELLED] Case status is CANCELLED
     */
    case CASE_STATUS.CANCELLED:
      return generateNoActionCard( actionHeader, userFriendlyMessage );

    /**
     * ===================================================================
     * [CLOSED] Case status is CLOSED
     * ---
     *  [CLOSED-1] latestTaskStatus === null
     *  [CLOSED-2] latestTask.statusReason
     *    [CLOSED-2a] statusReason is a code lookup (2 character string)
     *    [CLOSED-2b] statusReason message defined from the server
     */
    case CASE_STATUS.CLOSED:
      return generateNoActionCard( actionHeader, userFriendlyMessage);


    /**
     * ===================================================================
     * [DENIED] Case status is DENIED
     * ---
     *  [DENIED-1] latestTaskStatus === DENIED
     *    [DENIED-1a] latestTask?.eappealSupported=true
     *    [DENIED-1b] latestTask?.eappealSupported=false
     *  [DENIED-2] latestTaskStatus !== DENIED
     */
    case CASE_STATUS.DENIED:
      return generateNoActionCard(
        actionHeader, userFriendlyMessage
      );

    /**
     * ===================================================================
     * [ERROR] Case status is ERROR
     * ---
     * Despite there being an array of ERROR_MESSAGES, we can't distinguish
     * the difference between them as they need to be handled on the backend
     * before being pushed to the data.userFriendlyMessage value.
     */
    case CASE_STATUS.ERROR:
      return generateNoActionCard(
        actionHeader, userFriendlyMessage
      );

    /**
     * ===================================================================
     * [IN_PROGRESS] Case status is IN_PROGRESS
     * ---
     *  [IN_PROGRESS-1] latestTaskStatus === CLOSED
     *  [IN_PROGRESS-2] latestTaskStatus === IN_PROGRESS
     *  [IN_PROGRESS-3] latestTaskStatus === OPEN
     *    [IN_PROGRESS-3a] latestTask?.eappealSupported=true
     *    [IN_PROGRESS-3b] latestTask?.eappealSupported=false
     *  [IN_PROGRESS-4] latestTaskStatus === IN_PROCESS
     *  [IN_PROGRESS-5] latestTaskStatus === SUBMITTED
     *    [IN_PROGRESS-5a] latestTask?.name === APPEAL_PA
     *    [IN_PROGRESS-5b] latestTask?.name === ANSWER_QUESTIONS
     *    [IN_PROGRESS-5c] latestTask?.name === CANCEL_PA
     */
    case CASE_STATUS.IN_PROGRESS:
      switch (latestTaskStatus) {
        // --- [IN_PROGRESS-1]
        case TASK_STATUS.CLOSED: // --- [IN_PROGRESS-1]
        case TASK_STATUS.IN_PROGRESS: // --- [IN_PROGRESS-2]
        case TASK_STATUS.IN_PROCESS:  // --- [IN_PROGRESS-4]
        case TASK_STATUS.SUBMITTED: // --- [IN_PROGRESS-5]
          return generateNoActionCard(actionHeader, userFriendlyMessage);

        // --- [IN_PROGRESS-3]
        case TASK_STATUS.OPEN:
          switch (latestTask?.eappealSupported) {
            case true: // --- [IN_PROGRESS-3a]
            case false: // --- [IN_PROGRESS-3b]
            default:
              return generateActionCard(
                actionHeader,
                userFriendlyMessage,
                latestTaskQuestionHeader?.questionSetPrimaryContactNumber,
                latestTaskQuestionHeader?.openResponseDeadlineForReplyInDateTime
              );
          }

        default:
          break;
      }
      break;

    /**
     * ===================================================================
     * [PARTIALLY_DENIED] Case status is PARTIALLY_DENIED
     */
    case CASE_STATUS.PARTIALLY_DENIED:
      switch (latestTaskStatus) {
        case TASK_STATUS.PARTIALLY_DENIED:
          return generateNoActionCard(
            actionHeader, userFriendlyMessage
          );

        default:
          break;
      }
      break;

    /**
     * ===================================================================
     * [null] Case status is null
     */
    case null:
      break;

    default:
      break;
  }

  /**
   * Generate a no action card if none of the above criteria are met.
   */
  return generateNoActionCard(
    actionHeader,
    [NO_ACTIONS_AVAILABLE_INSTRUCTIONS]
  );
};

export default determineActionCard;
