import CONFIG from '@pixie/config';

const {
  QUESTIONS: { QUESTION_TYPES }
} = CONFIG;

/**
 * Handles setting the progress bar value.
 */
const handleProgressBarValue = (
  direction: string | 'FILE_UPLOAD' | 'END' | 'START' | 'PREV' | 'NEXT',
  questionProgress: number,
  questionSetArrayLength: number
) => {
  const { END, FILE_UPLOAD } = QUESTION_TYPES;
  const min = 0;
  const max = 100;
  const inc = Math.round(max / questionSetArrayLength);
  let result: number;

  switch (direction) {
    case FILE_UPLOAD:
      result = 90;
      break;

    case 'START':
      result = min;
      break;

    case END:
      result = max;
      break;

    case 'PREV':
      if (questionProgress > min)
        result = Math.round(questionProgress - inc);
      break;

    case 'NEXT':
    default:
      if (questionProgress < max)
        result = Math.round(questionProgress + inc);
      break;
  }

  return result;
};

export default handleProgressBarValue;
