import { closeQuestionSet, openQuestionSet } from '@pixie/features';


/**
 * Dispatches opening or closing of the QuestionSet bar.
 * @param event
 */
const handleQuestionSetBar = (
  e: React.TouchEvent | React.MouseEvent,
  questionSetOpen: boolean
) => dispatch => {

  const target = e.target as HTMLTextAreaElement;
  e.preventDefault();
  target.blur();
  !questionSetOpen
    ? dispatch(openQuestionSet(''))
    : dispatch(closeQuestionSet(''));
};

export default handleQuestionSetBar;
