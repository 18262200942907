/* eslint-disable no-case-declarations */
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import CONFIG from '@pixie/config';
import { IQuestion } from '@pixie/api';
import { RootState } from '@pixie/store';
import { classesArray } from '@pixie/utils';
import { useAnimateQuestionHeight } from '@pixie/hooks';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  Button,
  QuestionDate,
  QuestionNumeric,
  QuestionRadio,
  QuestionSummary,
  QuestionTextArea,
  QuestionFileUpload,
  QuestionSelect
} from '@pixie/components';

import { freeTextValidation } from '@pixie/regex';
import {
  getQuestionType,
  handleDateChange,
  handleFreeText,
  handleNextStepIsDisabled,
  handleNumericChange,
  handleProgressBarValue,
  handleRadioClick,
  handleRadioFreeText,
  handleSelectMultiple,
  submitQuestionSet
} from './logic';

import {
  handleNextQuestionId,
  handleNumericNextQuestionId,
  getUseResetDataFlow
} from './utils';

import {
  resetDateQuestion,
  resetFreeTextQuestion,
  resetNumericQuestion,
  resetSelectQuestion,
  setSubmitIsDisabled
} from './questionSet.slice';

import * as Styled from './TheQuestionSet.styled';

const {
  QUESTIONS: { QUESTION_TYPES }
} = CONFIG;

interface ITheQuestionSet {
  handleRefreshRequest: any;
}
const TheQuestionSet = (props: ITheQuestionSet): ReactElement => {

  const {
    handleRefreshRequest
  } = props;

  const useResetDataFlow = getUseResetDataFlow();

  const { trackEvent } = useMatomo();

  // Redux hook for sending data to the store.
  const dispatch = useDispatch();

  /**
   * Local state variable to define which question object from
   * the questionSet.question array to display on the UI.
   */
  const [currentQuestionObj, setCurrentQuestionObj] = useState<IQuestion>(
    undefined
  );

  /**
   * Local state variable to determine which question object from
   * the questionSet.question array to display on the UI. This is basically
   * a copy of `currentQuestionObj` above, but for only the questionId prop.
   */
  const [currentQuestionId, setCurrentQuestionId] = useState<string>('');

  /**
   * Local state variable used to set the currentQuestionId
   * when clicking invoking the nextStep() function.
   */
  const [nextQuestionId, setNextQuestionId] = useState<string>('');

  /**
   * Local state boolean to determine if the NEXT button is enabled.
   */
  const [nextStepIsDisabled, setNextStepIsDisabled] = useState<boolean>(true);

  /**
   * Local state array to fill with questionId strings. This is used to
   * determine which relevant questions were answered in relation to the
   * entire questionSet.question array as a whole.
   */
  const [questionHistory, setQuestionHistory] = useState<string>('');

  /**
   * Local state var for displaying the progress bar value.
   */
  const [questionProgress, setQuestionProgress] = useState<number>(0);

  /**
   * Local state for files uploaded by the user.
   */
  const [fileUploads, setFileUploads] = useState<File[]>([]);

  /**
   * Local state toggle for rendering & resetting date questions.
   * This is only used when the `USE_RESET_DATA_FLOW` bool is true.
   * @requires USE_RESET_DATA_FLOW=true
   */
  const [renderDateComponent, setRenderDateComponent] = useState(true);

  /**
   * Local state toggle for rendering & resetting numeric questions.
   * This is only used when the `USE_RESET_DATA_FLOW` bool is true.
   * @requires USE_RESET_DATA_FLOW=true
   */
  const [renderNumericComponent, setRenderNumericComponent] = useState(true);

  // redux lookups
  const caseInfo = useSelector((state: RootState) => state.caseInfo);
  const latestTask = useSelector((state: RootState) => state.latestTask);
  const questionSet = useSelector((state: RootState) => state.questionSet);

  /**
   * Listener to reset the local state of the component back to default.
   * This listens for `questionSet.isLoading` boolean and triggers the
   * resets when that value returns true.
   */
  useEffect(() => {
    if (questionSet?.isLoading === true) {
      setCurrentQuestionObj(undefined);
      setCurrentQuestionId('');
      setNextQuestionId('');
      setQuestionProgress(0);
      setNextStepIsDisabled(true);
      setQuestionHistory('');
      setFileUploads([]);
    }
  }, [questionSet.isLoading]);

  /**
   * Listener for determining whether to set the initial currentQuestionId
   * when the component mounts, or if it should just right to the summary.
   */
  useEffect(() => {
    if (latestTask && latestTask.status === 'SUBMITTED') {
      setCurrentQuestionId('END');
      dispatch(setSubmitIsDisabled(true));

      const answeredArray = questionSet?.question.map((question: IQuestion) => {
        return question.questionId;
      });

      setQuestionHistory(answeredArray.join(','));
    } else if (
      questionSet?.question &&
      questionSet?.question.length &&
      questionSet?.question[0].questionId &&
      currentQuestionId === ''
    ) {
      setCurrentQuestionId(questionSet?.question[0].questionId);
    }
  }, [currentQuestionId, dispatch, latestTask, questionSet.question]);

  /**
   * Listener for determining and setting the currentQuestionObj,
   * based on the local currentQuestionId state variable.
   */
  useEffect(() => {
    if (questionSet?.question && currentQuestionId === '') {
      setCurrentQuestionObj(questionSet?.question[0]);
    } else if (currentQuestionId !== QUESTION_TYPES.END) {
      setCurrentQuestionObj(
        questionSet?.question.find(
          (q: IQuestion) => q.questionId === currentQuestionId
        )
      );
    }
  }, [currentQuestionId, questionSet.question]);

  /**
   * Determines what the `nextQuestionId` variable is
   * based on the logic from `handleNextQuestionId()`.
   */
  useEffect(() => {
    const result = handleNextQuestionId(currentQuestionObj, currentQuestionId);
    result !== null && setNextQuestionId(result);
  }, [currentQuestionObj, currentQuestionId, questionSet]);

  /**
   * Logic executed when clicking the "NEXT" button on the UI.
   */
  const nextStep = () => {
    const questionHistoryArray = questionHistory.split(',');
    if (currentQuestionId !== questionHistoryArray[questionHistoryArray.length - 1]) {
      setCurrentQuestionId(nextQuestionId);
      setQuestionHistory([...questionHistoryArray, currentQuestionId].join(','));

      // get and set any map placeholder heights
      const questionHeight = document?.querySelector(
        `#QuestionSet div[data-question-id="${currentQuestionId}"]`
        // @ts-ignore
      )?.offsetHeight;

      const placeholderItem = document?.querySelector(
        `#QuestionSet div[data-question-id="${currentQuestionId}"] .question-placeholder`
      );

      if (placeholderItem !== null && typeof placeholderItem !== 'undefined') {
        placeholderItem.setAttribute('style', `height: ${questionHeight}px`);
      }

      // -- intercept document upload
      if (
        nextQuestionId === QUESTION_TYPES.END &&
        questionSet?.header.attachmentRequired === true
      ) {
        if (currentQuestionId === QUESTION_TYPES.FILE_UPLOAD) {
          setCurrentQuestionId(QUESTION_TYPES.END);
        } else {
          setCurrentQuestionId(QUESTION_TYPES.FILE_UPLOAD);
        }
      } else if (
        nextQuestionId === QUESTION_TYPES.END &&
        questionSet?.header.attachmentRequired === false
      ) {
        setCurrentQuestionId(QUESTION_TYPES.END);
      }

      // -- handle progress value
      if (currentQuestionId === QUESTION_TYPES.FILE_UPLOAD) {
        setQuestionProgress(
          handleProgressBarValue(
            QUESTION_TYPES.END,
            questionProgress,
            questionSet?.question.length
          )
        );
      } else if (nextQuestionId === QUESTION_TYPES.END) {
        if (questionSet?.header.attachmentRequired === true) {
          setQuestionProgress(
            handleProgressBarValue(
              QUESTION_TYPES.FILE_UPLOAD,
              questionProgress,
              questionSet?.question.length
            )
          );
        } else {
          setQuestionProgress(
            handleProgressBarValue(
              QUESTION_TYPES.END,
              questionProgress,
              questionSet?.question.length
            )
          );
        }
      } else {
        setQuestionProgress(
          handleProgressBarValue(
            'NEXT',
            questionProgress,
            questionSet?.question.length
          )
        );
      }
    }
  };

  /**
   * Logic executed when clicking the "PREV" button on the UI.
   */
  const prevStep = () => {
    // @see https://stackoverflow.com/a/58203994/8296677
    const temp = questionHistory.split(','); // assigning the list to temp variable
    temp.pop(); // removing the element using splice
    setQuestionHistory(temp.join(',')); // updating the list
    setCurrentQuestionId(questionHistory.split(',')[questionHistory.split(',').length - 1]);

    if (useResetDataFlow) {
      // clear the answer of the current question
      switch (getQuestionType(currentQuestionObj?.questionType)) {
        case QUESTION_TYPES.DATE:
          dispatch(resetDateQuestion(currentQuestionObj?.questionId));
          setRenderDateComponent(false);
          setTimeout(() => setRenderDateComponent(true), 1);
          break;

        case QUESTION_TYPES.NUMERIC:
          dispatch(resetNumericQuestion(currentQuestionObj?.questionId));
          setRenderNumericComponent(false);
          setTimeout(() => setRenderNumericComponent(true), 1);
          break;

        case QUESTION_TYPES.FREE_TEXT:
          dispatch(resetFreeTextQuestion(currentQuestionObj?.questionId));
          break;

        default:
          break;
      }

      // clear last question answer on prev
      const previousQuestionObj = questionSet?.question.find((q: IQuestion) => {
        return q.questionId === questionHistory.split(',')[questionHistory.split(',').length - 1];
      });

      switch (getQuestionType(previousQuestionObj?.questionType)) {
        case QUESTION_TYPES.RADIO:
          dispatch(resetSelectQuestion(previousQuestionObj?.questionId));
          break;

        case QUESTION_TYPES.DATE:
          dispatch(resetDateQuestion(previousQuestionObj?.questionId));
          setRenderDateComponent(false);
          setTimeout(() => setRenderDateComponent(true), 1);
          break;

        case QUESTION_TYPES.NUMERIC:
          dispatch(resetNumericQuestion(previousQuestionObj?.questionId));
          setRenderNumericComponent(false);
          setTimeout(() => setRenderNumericComponent(true), 1);
          break;

        case QUESTION_TYPES.FREE_TEXT:
          dispatch(resetFreeTextQuestion(previousQuestionObj?.questionId));
          break;

        default:
          break;
      }
    }

    // -- handle progress value
    if (
      currentQuestionId === QUESTION_TYPES.END &&
      questionSet?.header.attachmentRequired
    ) {
      setQuestionProgress(
        handleProgressBarValue(
          QUESTION_TYPES.FILE_UPLOAD,
          questionProgress,
          questionSet?.question.length
        )
      );
    } else if (questionHistory.split(',').length === 1) {
      setQuestionProgress(
        handleProgressBarValue(
          'START',
          questionProgress,
          questionSet?.question.length
        )
      );
    } else if (currentQuestionId === QUESTION_TYPES.FILE_UPLOAD) {
      if (questionHistory.split(',').length === 1) {
        setQuestionProgress(
          handleProgressBarValue(
            'START',
            questionProgress,
            questionSet?.question.length
          )
        );
      } else {
        setQuestionProgress(
          handleProgressBarValue(
            'PREV',
            questionProgress,
            questionSet?.question.length
          )
        );
      }
    } else if (
      questionSet.question.find((obj: IQuestion) => {
        return questionHistory.split(',')[questionHistory.split(',').length - 1] === obj.questionId;
      })?.sequenceNumber === '1'
    ) {
      setQuestionProgress(
        handleProgressBarValue(
          'START',
          questionProgress,
          questionSet?.question.length
        )
      );
    } else {
      setQuestionProgress(
        handleProgressBarValue(
          'PREV',
          questionProgress,
          questionSet?.question.length
        )
      );
    }
  };

  /**
   * Submits questionSet + attachments to the API
   * @requires dispatch
   * @requires submitPaRequest
   */
  const submitResponses = () => {
    trackEvent({
      category: 'PA Question Submission',
      action: 'Question Set Submit',
      name: 'Pharma ePA Question Set Submit'
    });
    dispatch(
      submitQuestionSet(
        caseInfo.caseCode,
        fileUploads,
        handleRefreshRequest,
        latestTask.questionSet,
        // @todo fix this TS error
        // @ts-ignore
        questionSet,
        questionHistory.split(','),
        latestTask.name === 'ANSWER_APPEAL_QUESTIONS'
      )
    );
  };

  /**
   * Listener the determine if the "NEXT" button
   * on the UI is disabled or not.
   * @requires handleNextStepIsDisabled
   * @requires setNextStepIsDisabled
   */
  useEffect(() => {
    setNextStepIsDisabled(
      handleNextStepIsDisabled(
        currentQuestionObj,
        currentQuestionId,
        fileUploads.length,
        nextQuestionId
      )
    );
  }, [currentQuestionObj, currentQuestionId, fileUploads.length, nextQuestionId]);

  return (

    <Styled.QuestionSet>
      <section className="question-set">
        <div className="uk-margin">
          <h1 className="uk-h2 uk-margin-remove">
            {questionSet?.header?.questionSetTitle}
          </h1>
          <p className="uk-text-meta uk-margin-remove">
            <span>{questionSet?.header?.questionSetDescription}</span>
          </p>
        </div>
        <form>
          <div
            className={classesArray([
              'uk-margin',
              questionSet?.submitIsDisabled ? 'uk-hidden' : ''
            ])}
          >
            <progress
              className="uk-progress"
              value={questionProgress}
              max={100}
            />
          </div>
          <AnimateHeight
            animateOpacity
            delay={0}
            duration={300}
            height={useAnimateQuestionHeight(
              currentQuestionId,
              currentQuestionObj
            )}
          >
            {questionSet?.question.map((q: IQuestion) => {
              switch (getQuestionType(q.questionType)) {
                case QUESTION_TYPES.DATE:
                  return (
                    <div
                      key={`${q.questionId}_${q.sequenceNumber}`}
                      data-question-id={q.questionId}
                      data-sequence-number={q.sequenceNumber}
                      data-is-active={q.questionId === currentQuestionId}
                      className="uk-animation-slide-right-small"
                    >
                      <div
                        className="question-placeholder"
                        data-show={!renderDateComponent}
                      />
                      {renderDateComponent && (
                        <>
                          <QuestionDate
                            dataTestId='the-question-set-date-picker'
                            questionText={q.questionText}
                            showTimeSelect={
                              q.questionType?.date?.dateTimeRequired
                            }
                            onClick={(date: Date) => {
                              dispatch(
                                handleDateChange(
                                  q.questionId,
                                  date,
                                  q.questionType?.date?.dateTimeRequired
                                )
                              );
                            }}
                          />
                        </>
                      )}
                    </div>
                  );

                case QUESTION_TYPES.NUMERIC:
                  return (
                    <div
                      key={`${q.questionId}_${q.sequenceNumber}`}
                      data-is-active={q.questionId === currentQuestionId}
                      data-question-id={q.questionId}
                      data-sequence-number={q.sequenceNumber}
                      className="uk-animation-slide-right-small"
                    >
                      <div
                        className="question-placeholder"
                        data-show={!renderNumericComponent}
                      />
                      {renderNumericComponent && (
                        <>
                          <QuestionNumeric
                            dataTestId='the-questions-set-numeric'
                            numberStep={10}
                            questionText={q.questionText}
                            onBlur={(
                              event: any
                            ) => {
                              setNextQuestionId(handleNumericNextQuestionId(event?.currentTarget?.value, q));
                            }}
                            onChange={(number: number) => {
                              dispatch(
                                handleNumericChange(q.questionId, number)
                              );
                            }}
                          />
                        </>
                      )}
                    </div>
                  );

                case QUESTION_TYPES.RADIO:
                  return (
                    <div
                      key={`${q.questionId}_${q.sequenceNumber}`}
                      data-is-active={q.questionId === currentQuestionId}
                      data-question-id={q.questionId}
                      data-sequence-number={q.sequenceNumber}
                      className="uk-animation-slide-right-small"
                    >
                      <QuestionRadio
                        dataTestId='the-question-set-radio'
                        questionId={q.questionId}
                        questionText={q.questionText}
                        questionType={q.questionType}
                        defaultNextQuestionId={q?.defaultNextQuestionId}
                        onClick={(
                          qId: string,
                          cId: string,
                          addFt: string,
                          nextId: string
                        ) => {
                          dispatch(handleRadioClick(qId, cId, addFt));
                          if (nextId !== null) setNextQuestionId(nextId);
                        }}
                        maxLength={2000}
                        onAdditionalTextChange={(e: any) => {
                          dispatch(
                            handleRadioFreeText(q.questionId, freeTextValidation(e?.target?.value))
                          );
                        }}
                      />
                    </div>
                  );

                case QUESTION_TYPES.FREE_TEXT:
                  return (
                    <div
                      key={`${q.questionId}_${q.sequenceNumber}`}
                      data-is-active={q.questionId === currentQuestionId}
                      data-question-id={q.questionId}
                      data-sequence-number={q.sequenceNumber}
                      className="uk-animation-slide-right-small"
                    >
                      <QuestionTextArea
                        dataTestId='the-questions-set-text-input'
                        questionId={q.questionId}
                        questionText={q.questionText}
                        onChange={(freeText: string) => {
                          dispatch(handleFreeText(q.questionId, freeText));
                        }}
                        maxLength={2000}
                      />
                    </div>
                  );

                case QUESTION_TYPES.CHECKBOX:
                  return (
                    <div
                      key={`${q.questionId}_${q.sequenceNumber}`}
                      data-is-active={q.questionId === currentQuestionId}
                      data-question-id={q.questionId}
                      data-sequence-number={q.sequenceNumber}
                      className="uk-animation-slide-right-small"
                    >
                      <QuestionSelect
                        dataTestId='the-question-set-select-dropdown'
                        isSelectMultiple
                        questionText={q.questionText}
                        questionType={q.questionType}
                        onChange={(choices: Array<String>) => {
                          dispatch(handleSelectMultiple(q.questionId, choices));
                        }}
                      />
                    </div>
                  );

                case QUESTION_TYPES.END:
                case null:
                default:
                  return null;
              }
            })}

            <div
              id={QUESTION_TYPES.FILE_UPLOAD}
              data-is-active={currentQuestionId === QUESTION_TYPES.FILE_UPLOAD}
              className="uk-animation-slide-right-small"
            >
              <QuestionFileUpload
                attachmentNotes={questionSet?.header?.attachmentNotes || ''}
                handleFileChange={(files: []) => setFileUploads(files)}
              />
            </div>

            <div
              id="Summary"
              data-is-active={currentQuestionId === QUESTION_TYPES.END}
              className="uk-animation-slide-right-small"
            >
              <QuestionSummary
                dataTestId="the-question-set-summary"
                fileUploads={fileUploads}
                questionHistory={questionHistory.split(',')}
                questionSetArray={questionSet?.question}
                questionTitle={
                  questionSet?.submitIsDisabled
                    ? ''
                    : 'Review & Submit Responses'
                }
              />
            </div>
          </AnimateHeight>

          <div
            className={classesArray([
              'buttons-container',
              questionSet?.submitIsDisabled ||
              (currentQuestionObj?.sequenceNumber === '1' && nextStepIsDisabled)
                ? 'uk-hidden'
                : 'uk-visible'
            ])}
          >
            <div className="uk-grid uk-grid-small" data-uk-grid>
              <div
                className={classesArray([
                  'uk-animation-slide-bottom-small',
                  useResetDataFlow && questionHistory.split(',').length === 0
                    ? 'uk-hidden'
                    : 'uk-visible', 'uk-width-1-3'
                ])}
              >
                <Button
                  dataTestId="the-question-set-prev"
                  disabled={questionHistory.split(',').length === 0}
                  buttonStyle={useResetDataFlow ? 'danger' : 'outline'}
                  text="Prev"
                  type="button"
                  onClick={prevStep}
                />
              </div>

              <div
                className={classesArray([
                  'uk-animation-slide-left-small',
                  useResetDataFlow && questionHistory.split(',').length === 0
                    ? 'uk-width-1-1'
                    : 'uk-width-2-3',
                  currentQuestionId === QUESTION_TYPES.END
                    ? 'uk-hidden'
                    : 'uk-visible'
                ])}
              >
                <Button
                  dataTestId="the-question-set-next"
                  disabled={nextStepIsDisabled}
                  buttonStyle="question-secondary"
                  text="Next"
                  type="button"
                  onClick={nextStep}
                />
              </div>

              <div
                className={classesArray([
                  'uk-width-2-3',
                  'uk-animation-slide-right-small',
                  currentQuestionId === QUESTION_TYPES.END
                    ? 'uk-visible'
                    : 'uk-hidden'
                ])}
              >
                <Button
                  buttonStyle="alert"
                  dataTestId='the-question-set-submit-button'
                  disabled={questionSet?.submitIsDisabled}
                  text="Submit"
                  type="button"
                  onClick={submitResponses}
                  isLoading={questionSet.submitIsLoading}
                  isError={questionSet.submitIsError}
                  isSuccess={questionSet.submitIsSuccess}
                />
              </div>

              <div
                className={classesArray([
                  'uk-width-1-1',
                  'uk-grid-margin',
                  'uk-first-column',
                  'uk-text-center',
                  'uk-animation-fade',
                  currentQuestionId === QUESTION_TYPES.END
                    ? 'uk-visible'
                    : 'uk-hidden'
                ])}
              >
                <p className="uk-text-meta">
                  <em>
                    Answers <strong>cannot</strong> be changed after submission.
                  </em>
                </p>
              </div>
            </div>
          </div>
        </form>
      </section>
    </Styled.QuestionSet>
  );
};

export default TheQuestionSet;
