import React, { ReactElement, useEffect, useRef } from 'react';
import { classesArray, uiKitOptions } from '@pixie/utils';
import UIkit from 'uikit';
import * as Styled from './Modal.styled';

interface IModalProps {
  children?: any;
  dataTestId: string;
  id?: string;
  isOpen?: boolean;
  shouldCloseOnEscKey?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  dialogClassName?: string;
}

const Modal = (props: IModalProps): ReactElement => {
  const {
    children,
    dataTestId,
    id,
    isOpen,
    shouldCloseOnEscKey,
    shouldCloseOnOverlayClick,
    dialogClassName
  } = props;

  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      UIkit.modal(modalRef.current).show();
    } else {
      UIkit.modal(modalRef.current).hide();
    }
  }, [ isOpen ]);

  const modalUiKitOptions = {
    'container': false,
    'esc-close': shouldCloseOnEscKey,
    'bg-close': shouldCloseOnOverlayClick
  };

  if (!shouldCloseOnEscKey) {
    modalUiKitOptions['esc-close'] = false;
  }

  return (
    <Styled.Modal
      className={classesArray([
        'pixie-modal',
        'uk-modal'
      ])}
      data-uk-modal={uiKitOptions(modalUiKitOptions)}
      ref={modalRef}
      id={id}
      data-testid={`${dataTestId}`}
    >
      <div
        className={classesArray([
          dialogClassName,
          'uk-modal-dialog'
        ])}
      >
        {children}
      </div>
    </Styled.Modal>
  );
};

Modal.defaultProps = {
  children: null,
  id: null,
  isOpen: true,
  shouldCloseOnEscKey: true,
  shouldCloseOnOverlayClick: true,
  dialogClassName: 'pixie-modal-dialog'
};

export default Modal;
