import styled from 'styled-components';

export const Button = styled.button`
  appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: 14px;
  font-weight: bold;
  line-height: 38px;
  margin: 0;
  overflow: visible;
  padding: 0 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  // text-transform: uppercase;
  transition-property: color, background-color, box-shadow, border-color;
  transition: 0.1s ease-in-out;
  vertical-align: middle;

  span {
    pointer-events: none;
    user-select: none;
  }

  .icon-refresh {
    margin-right: 10px;
  }
  .uk-icon {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    pointer-events: none;
  }

  &.primary {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background-color: var(--color-newBrandingPrimary);
    float: center;
    margin: 15px 0px 28px 0px;

    &:hover {
      background-color: #1a4677;
    }

    &:active {
      background-color: var(--color-secondary);
      border-color: #052e5d;
    }
  }

  &.question-secondary {
    color: white;
    background-color: var(--color-secondary);

    &:hover {
      background-color: #1a4677;
    }

    &:active {
      background-color: var(--color-secondary);
      border-color: #052e5d;
    }
  }
  &.secondary {
    color: #3f7d98;
    border-color: #3f7d98;
    background-color: white;
    float: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin: 15px 0px 28px 0px;

    &:hover {
      color: var(--color-primaryHover);
      border-color: var(--color-primaryHover);
    }

    &:active {
      background-color: var(--color-tertiary--10);
      border-color: var(--color-tertiary--50);
    }
  }

  &.refresh {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background-color: var(--color-newBrandingPrimary);
    float: center;
    margin: 15px 0px 28px 0px;
    &:hover {
      background-color: #1a4677;
    }

    &:active {
      background-color: var(--color-secondary);
      border-color: #052e5d;
    }
  }

  &.tertiary {
    color: #0a0808;
    background-color: var(--color-tertiary);

    &:hover {
      color: black;
      background-color: #b9b1ad;
    }

    &:active {
      background-color: var(--color-tertiary);
      border-color: #655e5a;
    }
  }

  &.outline {
    color: var(--color-black--50);
    background-color: transparent;
    border-color: var(--color-tertiary);

    &:hover {
      color: var(--color-black);
      border-color: var(--color-black--50);
    }

    &:active {
      background-color: var(--color-tertiary--10);
      border-color: var(--color-tertiary--50);
    }
    &:modal {
      color: #162427;
      background-color: var(--color-primary);

      &:hover {
        color: black;
        background-color: var(--color-primary--50);
      }

      &:active {
        background-color: var(--color-primary);
        border-color: #1a4677;
      }
    }

    &:epa-modal {
      color: #162427;
      background-color: var(--color-newBrandingPrimary);

      &:hover {
        color: black;
        background-color: var(--color-primary--50);
      }

      &:active {
        background-color: var(--color-newBrandingPrimary);
        border-color: #1a4677;
      }
    }

    &.ebvSubmitButton {
      color: var(--color-ebvButton);

      &:hover {
        color: black;
        background-color: var(--color-ebvButton);
      }

      &:active {
        background-color: var(--color-ebvButton);
        border-color: #1a4677;
      }
    }
  }

  &.outline.disabled,
  &.outline[disabled] {
    &:hover,
    &:active {
      border-color: var(--color-tertiary);
    }
  }

  &.error,
  &.danger {
    color: #2b1514;
    background-color: var(--color-error);

    &:hover {
      color: black;
      background-color: #e0a1a0;
    }

    &:active {
      background-color: var(--color-error);
      border-color: #b95857;
    }
  }

  &.error.disabled,
  &.error[disabled],
  &.danger.disabled,
  &.danger[disabled] {
    color: #2b1514;
    background-color: var(--color-error);

    &:hover {
      color: #2b1514;
      background-color: var(--color-error);
    }

    &:active {
      color: #2b1514;
      background-color: var(--color-error);
    }
  }

  &.alert,
  &.warning {
    color: #563f0a;
    background-color: var(--color-warning);

    &:hover {
      color: var(--color-black);
      background-color: #ffe19e;
    }

    &:active {
      background-color: var(--color-warning);
      border-color: #a2750e;
    }
  }

  &.modal {
    color: white;
    background-color: var(--color-button);
  }

  &.epa-modal {
    color: white;
    background-color: var(--color-newBrandingPrimary);
    &:hover {
      color: white;
      background-color: var(--color-primaryHover);
    }
  }

  &.ebvSubmitButton {
    color: white;
    background-color: var(--color-ebvButton);
  }

  &.success {
    color: #23482e;
    background-color: var(--color-success);

    &:hover {
      color: var(--color-black);
      background-color: #c0eacd;
    }

    &:active {
      background-color: var(--color-success);
      border-color: #58bf77;
    }
  }

  &.success.disabled,
  &.success[disabled] {
    color: #23482e;
    background-color: var(--color-success);

    &:hover {
      color: #23482e;
      background-color: var(--color-success);
    }

    &:active {
      color: #23482e;
      background-color: var(--color-success);
    }
  }

  &:hover {
    box-shadow: 0 14px 25px rgb(0 0 0 / 16%);
  }

  &:active {
    box-shadow: 0 1px 4px rgb(0 0 0 / 8%);
  }

  &.disabled,
  &[disabled] {
    color: #615955;
    background-color: var(--color-tertiary--50);
    box-shadow: none;
    cursor: not-allowed;

    &:hover,
    &:active {
      color: #615955;
      background-color: var(--color-tertiary--50);
    }
  }
  &.link {
    background: none;
    border: none;
    padding: 0 0px;
    margin: 0px;
    border-radius: none;
    box-sizing: none;
    box-shadow: none;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: none;
    /*optional*/

    /*input has OS specific font-family*/
    color: #069;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    overflow: visible;
    position: relative;
    text-align: left;
    vertical-align: text-top;
    &:hover {
      text-decoration: underline;
    }
  }

  &.loading,
  &.loading.disabled,
  &.loading[disabled] {
    color: #615955;
    background-color: var(--color-tertiary);
    border-color: var(--color-tertiary);
    box-shadow: none;
    cursor: progress;

    &:hover,
    &:active {
      color: #615955;
      background-color: var(--color-tertiary);
      border-color: var(--color-tertiary);
    }

    &.primary {
      color: var(--color-black);
      background-color: var(--color-primary--50);
      border-color: var(--color-primary--50);
    }

    &.secondary {
      color: white;
      background-color: #1a4677;
      border-color: #1a4677;
    }
    &.question-secondary {
      color: white;
      background-color: #1a4677;
      border-color: #1a4677;
    }

    &.tertiary {
      color: var(--color-black);
      background-color: #b9b1ad;
      border-color: #b9b1ad;
    }

    &.outline {
      color: var(--color-black);
      background-color: transparent;
      border-color: var(--color-black--50);
    }

    &.error,
    &.danger {
      color: var(--color-black);
      background-color: #e0a1a0;
      border-color: #e0a1a0;
    }

    &.alert,
    &.warning {
      color: var(--color-black);
      background-color: #ffe19e;
      border-color: #ffe19e;
    }

    &.success {
      color: var(--color-black);
      background-color: #c0eacd;
      border-color: #c0eacd;
    }
  }
  &.buy-and-bill-outline {
    color: var(--color-newBrandingPrimary);
    background-color: transparent;
    border-color: var(--color-newBrandingPrimary);

    &:hover {
      color: var(--color-primaryHover);
      border-color: var(--color-primaryHover);
    }

    &:active {
      background-color: var(--color-tertiary--10);
      border-color: var(--color-tertiary--50);
    }
    &:modal {
      color: #162427;
      background-color: var(--color-primary);

      &:hover {
        color: black;
        background-color: var(--color-primary--50);
      }

      &:active {
        background-color: var(--color-primary);
        border-color: #1a4677;
      }
    }

    &:epa-modal {
      color: #162427;
      background-color: var(--color-newBrandingPrimary);

      &:hover {
        color: black;
        background-color: var(--color-primaryHover);
      }

      &:active {
        background-color: var(--color-newBrandingPrimary);
        border-color: #1a4677;
      }
    }

    &.ebvSubmitButton {
      color: var(--color-ebvButton);

      &:hover {
        color: black;
        background-color: var(--color-ebvButton);
      }

      &:active {
        background-color: var(--color-ebvButton);
        border-color: #1a4677;
      }
    }
  }
`;
