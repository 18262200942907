import React, { useEffect } from 'react';
import { Question } from '@pixie/components';
import { freeTextValidation } from '@pixie/regex';


interface IQuestionTextAreaProps {
  dataTestId:string;
  questionId: string;
  questionText: string;
  questionTitle?: string;
  onChange: any;
  maxLength: number;
}

const QuestionTextArea = (props: IQuestionTextAreaProps) => {
  const { dataTestId,
    questionId,
    questionText,
    questionTitle,
    onChange,
    maxLength
  } = props;

  return (
    <Question questionText={questionText} questionTitle={questionTitle}>
      <label htmlFor="characterLimit">
        Character Limit: 2000
        <textarea
          className="uk-textarea"
          data-testId={dataTestId}
          name={questionId}
          id={questionId}
          rows={5}
          maxLength={maxLength}
          onPaste={(event: any) => onChange(String(freeTextValidation(event.clipboardData?.getData('text').replace(/\n/g, ' '))))}
          onChange={(event: any) => onChange(String(freeTextValidation(event.target.value)).replace(/\n/g, ' '))}
        />
      </label>
    </Question>
  );
};

QuestionTextArea.defaultProps = {
  questionTitle: ''
};

export default QuestionTextArea;
