/**
 * Checks user input against numeric questions conditions
 * and returns the appropriate next Question Id
 */
const handleNumericNextQuestionId = (value: string, question: any) => {
  const numericQuestionConditions = question?.questionType?.numeric?.nextQuestionCondition;
  let nextQID = question?.defaultNextQuestionId;
  if (numericQuestionConditions) {
    numericQuestionConditions.forEach((questionCondition) => {
      if (questionCondition.singleComparison && checkCondition(value, questionCondition.singleComparison.comparisonValue, questionCondition.singleComparison.comparisonOperator)) {
        nextQID = questionCondition.singleComparison.nextQuestionId;
      }
      if (questionCondition.rangeComparison &&
        checkRange(value, questionCondition.rangeComparison)) {
        nextQID = questionCondition.rangeComparison.nextQuestionId;
      }
    });
  }
  return nextQID;
};

const checkRange = (value: string, range: any) => {
  // If Lower is less than we are looking for non-inclusive range
  if (range.lowerBoundComparisonOperator === 'LT' || range.lowerBoundComparisonOperator === 'LE') {
    if (checkCondition(value, range.lowerBoundComparisonValue, range.lowerBoundComparisonOperator)
  || checkCondition(value, range.upperBoundComparisonValue, range.upperBoundComparisonOperator)) {
      return true;
    }
  }
  if (checkCondition(value, range.lowerBoundComparisonValue, range.lowerBoundComparisonOperator)
  && checkCondition(value, range.upperBoundComparisonValue, range.upperBoundComparisonOperator)) {
    return true;
  }
  return false;
};

const checkCondition = ( value: string, comparisonValue: string, comparisonOperator: string) => {
  switch (comparisonOperator) {
    case 'GT':
      return parseFloat(value) > parseFloat(comparisonValue);
    case 'LT' :
      return parseFloat(value) < parseFloat(comparisonValue);
    case 'GE' :
      return parseFloat(value) >= parseFloat(comparisonValue);
    case 'LE' :
      return parseFloat(value) <= parseFloat(comparisonValue);
    case 'EQ' :
      return parseFloat(value) === parseFloat(comparisonValue);
    case 'NE' :
      return parseFloat(value) !== parseFloat(comparisonValue);
  }
  return false;
};

export default handleNumericNextQuestionId;
