/**
 * Returns a ICD-10 code with a decimal point.
 *
 * ICD-10-CM codes have between 3 and 7 characters and follow the format:
 *
 * - **1st character:** always alpha (all letters except "U")
 * - **2nd character:** numeric (0-9)
 * - **3rd-7th character:** alpha or numeric
 * - **Decimal:** placed after the 3rd character
 *
 * @example
 * ```js
 * "M082"           => "M08.2"
 * "M08222"         => "M08.222"
 * "M082225132131"  => "M08.M082225132131"
 * ```
 */
const formatICD10 = (value: string): any => {
  const trimmedValue = value.trim();
  if (trimmedValue.length > 3 && !value.includes('.')){
    const beforeDecimal = trimmedValue.substring(0, 3);
    const afterDecimal = trimmedValue.substring(3);
    const formattedValue = `${beforeDecimal}.${afterDecimal}`;
    return formattedValue;
  }

  return trimmedValue;
};

export default formatICD10;
