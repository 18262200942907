import React from 'react';
import * as Styled from './Question.styled';

interface IQuestionProps {
  children: any;
  questionText?: string;
  questionTitle?: string;
}

const Question = (props: IQuestionProps) => {
  const { children, questionText, questionTitle } = props;

  return (
    <Styled.Question>
      {questionTitle && <h2 className="uk-h3">{questionTitle}</h2>}
      {questionText && <p>{questionText}</p>}
      {children}
    </Styled.Question>
  );
};

Question.defaultProps = {
  questionText: '',
  questionTitle: ''
};

export default Question;
