import React, { ReactElement } from 'react';
import { Button } from '@pixie/components';

interface IToggleButton {
  cancelAvailable: boolean;
  isCancelled: boolean;
  onClick: (event: React.TouchEvent | React.MouseEvent) => void;
}

const TheCancelPaRequestModalButton = (props: IToggleButton): ReactElement => {
  const { cancelAvailable, isCancelled, onClick } = props;

  return (
    <div className={cancelAvailable && 'uk-animation-slide-right-small'}>
      <Button
        buttonStyle="primary"
        dataTestId="the-cancel-pa-request-modal-button-cancel-button"
        disabled={!cancelAvailable || isCancelled}
        onClick={onClick}
        text={isCancelled ? 'Cancellation Submitted' : 'Request Cancel'}
        type="button"
        ukToggle="target: #CancelPaRequestModal;"
      />
    </div>
  );
};

export default TheCancelPaRequestModalButton;
