import { IQuestionSet, submitPaRequest } from '@pixie/api';
import { AxiosError, AxiosResponse } from 'axios';
import { getBase64 } from '@pixie/utils';
import {
  clearAlertMessage,
  resetSubmitLoadingStates,
  setAlertMessage,
  setSubmitError,
  setSubmitSuccess,
  startSubmitLoading
} from '@pixie/features';
import { formatQuestionSubmission } from '../utils';

/**
 * Sequence for submitting questionSet data
 */
const submitQuestionSet = (
  caseCode: string,
  attachments: File[],
  handleRefreshRequest: any,
  originalQuestionSet: IQuestionSet,
  editedQuestionSet: IQuestionSet,
  questionHistory: string[],
  isAppealQuestionSet
) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  /**
   * Object body for the submission
   */
  const apiDataObject = {
    questionSet: {
      header: originalQuestionSet.header,
      question: formatQuestionSubmission(
        originalQuestionSet.question,
        editedQuestionSet.question,
        questionHistory
      )
    },
    attachment: null
  };

  /**
   * Switches between `success` and `error` for response handling.
   */
  const handleResponse = (type: string, obj: any) => {
    const defaultErrMsg = 'An error has occured, please retry your request.';
    const defaultSucMsg = 'Request submitted successfully!';

    switch (type) {
      case 'success':
        return setTimeout(() => {
          dispatch(setSubmitSuccess(''));
          dispatch(
            setAlertMessage({
              type: 'success',
              message: obj?.statusText || obj?.responseText || defaultSucMsg
            })
          );

          setTimeout(() => {
            // -- stop loading
            dispatch(resetSubmitLoadingStates(''));

            // -- refresh data
            document?.querySelector<HTMLButtonElement>('#RefreshData')?.click();
          }, 2000);
        }, 2000);

      case 'alert':
      default:
        return setTimeout(() => {
          dispatch(
            setSubmitError(
              obj?.responseText || obj?.statusText || defaultErrMsg
            )
          );

          dispatch(
            setAlertMessage({
              type: 'error',
              message: obj?.responseText || obj?.statusText || defaultErrMsg
            })
          );
          setTimeout(() => dispatch(resetSubmitLoadingStates('')), 2000);
        }, 2000);
    }
  };

  dispatch(clearAlertMessage()); // -- clear any active failed alerts
  dispatch(startSubmitLoading('')); // -- start question loading

  // -- if there's attachments, convert to base64 then push
  if (attachments?.length !== 0) {
    const result = await getBase64(attachments[0]);
    return submitPaRequest(caseCode, {
      ...apiDataObject,
      attachment: {
        messageSource: 'PA_REQUEST',
        attachmentInBase64: result,
        fileType: attachments[0].type
      }
    }, isAppealQuestionSet)
      .then((res: AxiosResponse) => handleResponse('success', res))
      .catch((err: AxiosError) => handleResponse('alert', err));
  }

  // -- otherwise, just push
  return submitPaRequest(caseCode, apiDataObject, isAppealQuestionSet)
    .then((res: AxiosResponse) => handleResponse('success', res))
    .catch((err: AxiosError) => handleResponse('alert', err));
};

export default submitQuestionSet;
