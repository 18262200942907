import CONFIG from '../../config';

const {
  APP: { USE_AUTH_FLOW },
  SESSION: { CASE_CODE }
} = CONFIG;

export default function getInitialState(useAuthFlow = false, caseCode = null) {
  if (useAuthFlow || USE_AUTH_FLOW) {
    return window?.sessionStorage.getItem(caseCode || CASE_CODE)
      ? window?.sessionStorage.getItem(caseCode || CASE_CODE)
      : null;
  }

  return null;
}
