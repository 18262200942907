// @ts-nocheck // <-- delete this line if you solve the `declare` bug below.

/**
 * Uncommenting this block solves the errors below (inside the API_CONFIG)
 * object stating that __env isn't a property of window. However, when
 * react compiles, it produces the following error:
 *
 * ```node
 *    Module parse failed: Unexpected token (2:8)
 *    You may need an appropriate loader to handle this file type,
 *    currently no loaders are configured to process this file.
 *    See https://webpack.js.org/concepts#loaders
 *
 *    | // @-nocheck
 *    > declare global {
 *    |   interface Window {
 *    |     __env: {
 * ```
 *
 * @bug
 */
// declare global {
//   interface Window {
//     __env: {
//       API_APPEAL_INIT_REQUEST_URL: string;
//       API_BASE_LOCAL_URL: string;
//       API_BASE_URL: string;
//       API_CANCEL_REQUEST_URL: string;
//       API_CASE_DETAIL_URL: string;
//       API_CASE_DETAIL_LIST_URL: string;
//       API_PA_REQUEST_URL: string;
//       enableDebug: boolean;
//     };
//   }
// }

const env = window && window.__env;

const apiConfig = {
  TOKEN_REFRESH_REQUEST_URL: env.API_TOKEN_REFRESH_REQUEST_URL,
  AUTHN_REQUEST_URL: env.API_AUTHN_REQUEST_URL,
  REGEN_AUTH_CODE_URL: env.API_REGEN_AUTHCODE_URL,
  APPEAL_INIT_REQUEST_URL: env.API_APPEAL_INIT_REQUEST_URL,
  ATTACHMENT_UPLOAD_MB_LIMIT: 18,
  AUTHN_BASE_URL: env.AUTHN_BASE_URL,
  APIGEE_TOKEN_REFRESH_REQUEST_URL: env.API_APIGEE_TOKEN_REFRESH_REQUEST_URL,
  BASE_URL: env.API_BASE_URL,
  CANCEL_REQUEST_URL: env.API_CANCEL_REQUEST_URL,
  CASE_DETAIL_URL: env.API_CASE_DETAIL_URL,
  CASE_DETAIL_LIST_URL: env.API_CASE_DETAIL_LIST_URL,
  FILENAME_URL: env.API_FILENAME_URL,
  PA_REQUEST_URL: env.API_PA_REQUEST_URL,
  APPEAL_REQUEST_URL: env.API_APPEAL_REQUEST_URL,
  SOURCE_PROGRAM_REQUEST_URL: env.API_SOURCE_PROGRAM_REQUEST_URL,
  EBV_ELIGIBILITY_URL: env.API_EBV_ELIGIBILITY_URL,
  SUBMIT_INITIATE_URL: env.API_SUBMIT_INITIATE_URL,
  USE_FAKE_DATA: env.enableDebug,
  LOGOUT_URL: env.LOGOUT_URL
};

export default apiConfig;
