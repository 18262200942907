import styled from 'styled-components';

export const Alert = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: auto;
  z-index: 1200;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
  transition: 0.1s ease-in-out;
  color: var(--color-secondary--100);
  background-color: var(--color-white--50);
  /* border: 1px solid var(--color-white--10); */

  &:hover {
    box-shadow: 0 14px 25px rgb(0 0 0 / 16%);
  }

  .uk-close {
    padding: 14px;
    top: 0;
    right: 0;
  }

  .pixie__alert-text,
  .pixie__alert-type {
    color: inherit;
    margin: 0;
  }

  &.pixie__alert--error,
  &.pixie__alert--danger {
    background-color: var(--color-error);

    .pixie__alert-type,
    .pixie__alert-text {
      color: var(--color-black);
    }
  }

  &.pixie__alert--alert,
  &.pixie__alert--warning {
    background-color: var(--color-alert);

    .pixie__alert-type,
    .pixie__alert-text {
      color: var(--color-black);
    }
  }

  &.pixie__alert--success {
    background-color: var(--color-success);

    .pixie__alert-type,
    .pixie__alert-text {
      color: var(--color-black);
    }
  }
`;
