import React, { ReactElement } from 'react';
import { uiKitOptions } from '@pixie/utils';
import TheQuestionSet from './TheQuestionSet';

interface ITheQuestionSetBar {
  onClose: (event: React.TouchEvent | React.MouseEvent) => void;
  handleRefreshRequest: any;
  renderQuestions: boolean;

}

const TheQuestionSetBar = (props: ITheQuestionSetBar): ReactElement => {
  const {
    onClose,
    handleRefreshRequest,
    renderQuestions
  } = props;

  return (
    <div
      id="QuestionSet"
      data-uk-offcanvas={uiKitOptions({
        'bg-close': false,
        'esc-close': false,
        flip: true,
        mode: 'push',
        overlay: false
      })}
    >
      <div className="uk-offcanvas-bar uk-box-shadow-xlarge">
        <button
          className="uk-offcanvas-close"
          data-uk-close
          onClick={onClose}
          type="button"
          aria-label="Close Questions"
        />
        {renderQuestions &&
        <TheQuestionSet
          handleRefreshRequest={handleRefreshRequest}
        />}
      </div>
    </div>
  );
};

export default TheQuestionSetBar;
