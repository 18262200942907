const payerDropdown = [
  {
    cHCPayerID: '1003',
    payerID: 'VAPRM',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'VIRGINIA PREMIER HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1030',
    payerID: '30360',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'INDIVIDUAL ASSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1039',
    payerID: '81600',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'SOUTH COUNTRY HEALTH ALLIANCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1044',
    payerID: 'UC001',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'BRAND NEW DAY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1099',
    payerID: 'FLCCR',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'FLORIDA COMMUNITY CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1111',
    payerID: '14966',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'PARTNERS HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1114',
    payerID: '86083',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'SUMMIT ADMINISTRATION SERVICES, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1139',
    payerID: '31052',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'BANKERS FIDELITY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1184',
    payerID: 'CX100',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'SOUTHWEST SERVICE ADMINISTRATORS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1205',
    payerID: 'LWA01',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'LIFEWORKS ADVANTAGE (ISNP PLAN)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1252',
    payerID: 'WVS01',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'WEST VIRGINIA SENIOR ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1280',
    payerID: 'HCH01',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HEALTHCARE HIGHWAYS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1570',
    payerID: '86062',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'ARIZONA FOUNDATION FOR MEDICAL CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1595',
    payerID: '37279',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'INSURANCE ADMINISTRATORS OF AMERICA (IAA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1604',
    payerID: '14315',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'COMMONWEALTH CARE ALLIANCE',
    claimType: 'Institutional',
    State: 'MA',
    connectivityAvailable: 'P, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1631',
    payerID: '95327',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'ALAMEDA ALLIANCE HEALTH',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1723',
    payerID: '11328',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HEALTHCARE PARTNERS IPA HERITAGE NY MEDICAL GROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1735',
    payerID: '24735',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'WESTERN GROWERS / PINNACLE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1768',
    payerID: '75234',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HEALTH FIRST TPA (TYLER, TEXAS)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1786',
    payerID: '46045',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'AVERA HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1806',
    payerID: '62061',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'FiSERV HEALTH (KANSAS and TENNESSEE)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1827',
    payerID: '88019',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'TEACHERS HEALTH TRUST',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1834',
    payerID: '35112',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'EMPLOYEE PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: 'WV',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: 'KY',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: 'MI',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: 'ID',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1902',
    payerID: '48330',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HMAA - HAWAII MANAGEMENT ALLIANCE ASSOCIATION',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1907',
    payerID: '11695',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'ICARE, INDEPENDENT CARE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1914',
    payerID: '35186',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'DUNN and ASSOCIATES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1917',
    payerID: '77038',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'SANTE HEALTH SYSTEM',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '1957',
    payerID: '71404',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'CONTINENTAL GENERAL INSURANCE - non-MEDICARE SUPPL',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2004',
    payerID: 'DRHCP',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'DOCTORS HEALTHCARE PLANS ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2093',
    payerID: 'TCC93',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'TCC BENEFITS ADMINISTRATOR',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2100',
    payerID: '41212',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'FREEDOM HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2103',
    payerID: '82056',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'CUSTOM DESIGN BENEFITS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2105',
    payerID: 'HESUN',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HEALTHSUN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2112',
    payerID: 'SX173',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'BEACON HEALTH OPTIONS fka VALUE OPTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2143',
    payerID: '41178',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HEALTHEZ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2173',
    payerID: '95327',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'ALAMEDA ALLIANCE HEALTH',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2183',
    payerID: 'IP097',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'MERITAGE MEDICAL NETWORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2280',
    payerID: '48330',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HMAA - HAWAII MANAGEMENT ALLIANCE ASSOCIATION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2281',
    payerID: '77038',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'SANTE HEALTH SYSTEM',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2285',
    payerID: '11695',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'ICARE, INDEPENDENT CARE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2293',
    payerID: '35186',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'DUNN and ASSOCIATES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2486',
    payerID: 'DHS01',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'DELTA HEALTH SYSTEMS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2518',
    payerID: '31441',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'S&S HEALTHCARE STRATEGIES',
    claimType: 'Institutional',
    State: 'NC',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2518',
    payerID: '31441',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'S&S HEALTHCARE STRATEGIES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2555',
    payerID: 'CX100',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'SOUTHWEST SERVICE ADMINISTRATORS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2557',
    payerID: '7205',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'GILSBAR INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2578',
    payerID: '34136',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'JP FARLEY CORPORATION',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2680',
    payerID: '27004',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'CARE WISCONSIN HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2740',
    payerID: '',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'UNITEDHEALTHCARE WEST - PACIFICARE of CALIFORNIA',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2802',
    payerID: '7205',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'GILSBAR, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2810',
    payerID: '68011',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'CAPITOL ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2846',
    payerID: '46',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HILL PHYSICIANS MEDICAL GROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2910',
    payerID: '990263440',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'UNIVERSITY HEALTH ALLIANCE - HAWAII',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2920',
    payerID: '11328',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'HEALTHCARE PARTNERS IPA HERITAGE NY MEDICAL GROUP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2940',
    payerID: 'OCN01',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'OPTUM CARE NETWORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2946',
    payerID: '40585',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'INDECS CORPORATION',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2951',
    payerID: '64088',
    eligibilityID: 'null',
    claimStatusID: 'null',
    payerName: 'MISSISSIPPI SELECT HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2953',
    payerID: '59140',
    eligibilityID: '',
    claimStatusID: 'HHZCF',
    payerName: 'HEALTH PLAN SERVICES (TAMPA only)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2963',
    payerID: '55489',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PATIENT ADVOCATES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2967',
    payerID: '47076',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'REGIONAL CARE INC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2981',
    payerID: '65085',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'NORTH AMERICAN ADMINISTRATORS, LP (NAA) (NASHVILLE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2984',
    payerID: '36149',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BENEFIT ADMINISTRATIVE SYSTEMS, LLC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2986',
    payerID: '31074',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EMPLOYEE BENEFIT MANAGEMENT CORPORATION (EBMC)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '2989',
    payerID: '68011',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CAPITOL ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3065',
    payerID: 'KCMD1',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'KEYCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3207',
    payerID: '31074',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EMPLOYEE BENEFIT MANAGEMENT CORPORATION (EBMC)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3210',
    payerID: '990263440',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNIVERSITY HEALTH ALLIANCE - HAWAII',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3237',
    payerID: '37279',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'INSURANCE ADMINISTRATORS OF AMERICA (IAA)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3239',
    payerID: '59266',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'VOLUSIA HEALTH NETWORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3242',
    payerID: '37230',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TR PAUL INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3247',
    payerID: '55489',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PATIENT ADVOCATES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3253',
    payerID: '33033',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FCE BENEFIT ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3266',
    payerID: '47076',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'REGIONAL CARE, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3287',
    payerID: 'PROSP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PROSPECT MEDICAL SYSTEMS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3292',
    payerID: 'REGAL',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'REGAL MEDICAL GROUP',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3296',
    payerID: '93044',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'A and I BENEFIT PLAN ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3402',
    payerID: 'Block Vision',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BLOCK VISION, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3436',
    payerID: '1256',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'POPULYTICS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3437',
    payerID: '14315',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'COMMONWEALTH CARE ALLIANCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3438',
    payerID: 'AZ FOUNDATION',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ARIZONA FOUNDATION for MEDICAL CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3473',
    payerID: '94302',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CHINESE COMMUNITY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3475',
    payerID: 'MHHNP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEMORIAL HERMANN HEALTH SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3554',
    payerID: 'LWA01',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'LIFEWORKS ADVANTAGE (ISNP PLAN)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3610',
    payerID: '31144',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TRIHEALTH PHYSICIANS SOLUTIONS',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3629',
    payerID: 'GHOKC',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GLOBAL HEALTH OKC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C',
    Claims: 'Yes-Agreement',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3651',
    payerID: '35605',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EVERENCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3656',
    payerID: '41205',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BENEFIT ADMINISTRATION SERVICES, LTD (BAS LTD)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3658',
    payerID: 'CCIH',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CORRECTCARE INTEGRATED HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3672',
    payerID: '42149',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EMPLOYEE BENEFIT SYSTEMS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3740',
    payerID: '31441',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'S&S HEALTHCARE STRATEGIES',
    claimType: 'Professional',
    State: 'NC',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3740',
    payerID: '31441',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'S&S HEALTHCARE STRATEGIES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3742',
    payerID: '37135',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CONSOCIATE GROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3771',
    payerID: '34136',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'JP FARLEY CORPORATION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3773',
    payerID: '48145',
    eligibilityID: '',
    claimStatusID: 'COMHC',
    payerName: 'COMMUNITY HEALTH CHOICE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3785',
    payerID: '64088',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MISSISSIPPI SELECT HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3803',
    payerID: '77039',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'KERN HEALTH SYSTEMS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3812',
    payerID: '65085',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'NORTH AMERICAN ADMINISTRATORS, LP (NAA) (NASHVILLE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3838',
    payerID: '35198',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNIFIED GROUP SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3865',
    payerID: '71404',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CONTINENTAL GENERAL INSURANCE - non-MEDICARE SUPPL',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3891',
    payerID: '36373',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PRAIRIE STATES ENTERPRISES, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3896',
    payerID: '40585',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'INDECS CORPORATION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3931',
    payerID: 'DHS01',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'DELTA HEALTH SYSTEMS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3941',
    payerID: '33033',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FCE BENEFIT ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3954',
    payerID: '37230',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TR PAUL INC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3970',
    payerID: '59266',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'VOLUSIA HEALTH NETWORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '3984',
    payerID: '36373',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PRAIRIE STATES ENTERPRISES, INC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4003',
    payerID: '68037',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEDCORE OMNI IPA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4227',
    payerID: '20133',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'OPTIMUM HEALTHCARE SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4269',
    payerID: '53085',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ANCHOR BENEFIT CONSULTING',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4287',
    payerID: '36149',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BENEFIT ADMINISTRATIVE SYSTEMS, LLC',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4465',
    payerID: '88067',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TALL TREE ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4468',
    payerID: '20029',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AMERICAS CHOICE HEALTHPLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4542',
    payerID: '35198',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNIFIED GROUP SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4552',
    payerID: '64071',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ACCLAIM',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4583',
    payerID: 'WVS01',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'WEST VIRGINIA SENIOR ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4591',
    payerID: '13550',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AMALGAMATED LIFE INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4616',
    payerID: '77160',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GOLD COAST HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4650',
    payerID: '412MP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEDPARTNERS ADMINISTRATIVE SERVICES 412MP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4666',
    payerID: 'CHCPI',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CENTRAL HEALTH MEDICAL PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4680',
    payerID: '20133',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'OPTIMUM HEALTHCARE SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4730',
    payerID: 'OCN01',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'OPTUM CARE NETWORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4743',
    payerID: '43324',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BEACON HEALTH STRATEGIES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4743',
    payerID: '43324',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BEACON HEALTH STRATEGIES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4773',
    payerID: '10207',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CITRUS HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4787',
    payerID: '74289',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MENTAL HEALTH NETWORK - MHNET COVENTRY PSYCH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4792',
    payerID: 'BCMHODH',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'OHIO BCMH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4799',
    payerID: 'ATRIO',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ATRIO HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4882',
    payerID: '77052',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'COASTAL TPA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4905',
    payerID: '41178',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HEALTHEZ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4926',
    payerID: '12X56',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BEACON HEALTH OPTIONS fka VALUE OPTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4935',
    payerID: '88067',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TALL TREE ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '4953',
    payerID: '34108',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ES BEVERIDGE and ASSOCIATES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5131',
    payerID: '35605',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EVERENCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5149',
    payerID: '6607',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CARDINAL INNOVATIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5150',
    payerID: '31144',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TRIHEALTH PHYSICIANS SOLUTIONS',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5194',
    payerID: '37086',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PEKIN INSURANCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5242',
    payerID: '5003',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PRESBYTERIAN HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5246',
    payerID: '52461',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MARCH VISION CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5296',
    payerID: 'SCUFW',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNITED FOOD AND COMMERCIAL WORKERS UNION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5403',
    payerID: '13550',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AMALGAMATED LIFE INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5422',
    payerID: '34145',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'KLAIS and COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5430',
    payerID: '64071',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ACCLAIM',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5433',
    payerID: '64069',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FOX EVERETT, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5664',
    payerID: '56213',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CRESCENT HEALTH SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5666',
    payerID: '13285',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CLOVER HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5668',
    payerID: '22823',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MID-AMERICAN BENEFITS (ELITE CHOICE)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5683',
    payerID: '94302',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CHINESE COMMUNITY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5702',
    payerID: '-',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'DAVIS VISION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5809',
    payerID: '27004',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CARE WISCONSIN HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5814',
    payerID: 'GHOKC',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GLOBAL HEALTH OKC',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C',
    Claims: 'Yes-Agreement',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5817',
    payerID: '31165',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EYEMED VISION CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C',
    Claims: 'Yes-Agreement',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5849',
    payerID: '41205',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BENEFIT ADMINISTRATION SERVICES, LTD (BAS LTD)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5862',
    payerID: 'CCIH',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CORRECTCARE INTEGRATED HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5868',
    payerID: 'MPM03',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CENTINELA VALLEY IPA MEDICAL',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5870',
    payerID: 'MPM05',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GLOBAL CARE MEDICAL GROUP IPA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5877',
    payerID: '42149',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EMPLOYEE BENEFIT SYSTEMS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5891',
    payerID: '773',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE VISION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5909',
    payerID: '43324',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BEACON HEALTH STRATEGIES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5928',
    payerID: 'BCMHODH',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'OHIO BCMH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5934',
    payerID: 'ATRIO',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ATRIO HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5936',
    payerID: '82056',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CUSTOM DESIGN BENEFITS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5960',
    payerID: '74289',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MENTAL HEALTH NETWORK - MHNET COVENTRY PSYCH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5970',
    payerID: '95192',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GROUP HEALTH COOPERATIVE of EAU CLAIRE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '5981',
    payerID: '41212',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FREEDOM HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6040',
    payerID: '52133',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE DENTAL BENEFIT PROVIDERS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6128',
    payerID: '77160',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GOLD COAST HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6148',
    payerID: '412MP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEDPARTNERS ADMINISTRATIVE SERVICES 412MP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6180',
    payerID: '25184',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UPMC VISION ADVANTAGE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6190',
    payerID: 'MSO11',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SAN DIEGO COUNTY MEDICAL SERVICES (CMS)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6195',
    payerID: '133741352',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SUPERIOR VISION SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6198',
    payerID: 'CHCPI',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CENTRAL HEALTH MEDICAL PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6229',
    payerID: '965',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'US VIRGIN ISLANDS BLUE SHIELD',
    claimType: 'Professional',
    State: 'VI',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C',
    Claims: 'Yes-Agreement',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6289',
    payerID: '56213',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CRESCENT HEALTH SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6424',
    payerID: '22823',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MID-AMERICAN BENEFITS (ELITE CHOICE)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6433',
    payerID: '13285',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CLOVER HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6526',
    payerID: 'HCH01',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HEALTHCARE HIGHWAYS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6555',
    payerID: '34145',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'KLAIS and COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6568',
    payerID: '75234',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HEALTH FIRST TPA (TYLER, TEXAS)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6576',
    payerID: '64069',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FOX EVERETT, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6587',
    payerID: '24735',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'WESTERN GROWERS ASSURANCE TRUST',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6609',
    payerID: 'IP097',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MERITAGE MEDICAL NETWORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6611',
    payerID: 'L0230',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TRUSTED HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6645',
    payerID: '2700U',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEDOVA HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6647',
    payerID: '45281',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CAREFIRST BCBS COMMUNITY HEALTH PLAN MARYLAND',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6671',
    payerID: 'HESUN',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HEALTHSUN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6704',
    payerID: 'CAPMN',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CONIFER HEALTH SOLUTIONS',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6704',
    payerID: 'CAPMN',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CONIFER HEALTH SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6720',
    payerID: 'L0230',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TRUSTED HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6746',
    payerID: '27005',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEDOVA HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6749',
    payerID: '45281',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CAREFIRST BCBS COMMUNITY HEALTH PLAN MARYLAND',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6817',
    payerID: '92805',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MAXOR ADMINISTRATIVE SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6820',
    payerID: '13360',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CENTERLIGHT HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6834',
    payerID: '26545',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'VILLAGECAREMAX',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '6851',
    payerID: 'H0657',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FRIDAY HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7101',
    payerID: '34108',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ES BEVERIDGE and ASSOCIATES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7105',
    payerID: '4245',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HARVARD COMMUNITY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7136',
    payerID: '99320',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BENEFIT and RISK MANAGEMENT SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7144',
    payerID: '79966',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AMIDA CARE MEDICARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7178',
    payerID: 'ARGUS',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ARGUS DENTAL and VISION INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7189',
    payerID: '72091',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'INSURANCE MANAGEMENT ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7215',
    payerID: '30360',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'INDIVIDUAL ASSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7229',
    payerID: 'GHP660537624',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FIRST MEDICAL HEALTH PLAN (REFORM)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7235',
    payerID: 'CCHPC',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ALIGNMENT HEALTH CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7246',
    payerID: '441MP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEDPARTNERS ADMINISTRATIVE SERVICES 441MP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7281',
    payerID: 'PH001',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PRUITTHEALTH PREMIER',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7421',
    payerID: '56000522',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'RHODE ISLAND MEDICAID',
    claimType: 'Professional',
    State: 'RI',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7453',
    payerID: '95192',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GROUP HEALTH COOPERATIVE of EAU CLAIRE',
    claimType: 'Professional',
    State: 'WI',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7453',
    payerID: '95192',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GROUP HEALTH COOPERATIVE of EAU CLAIRE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7487',
    payerID: '45282',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CAREFIRST BCBS MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7524',
    payerID: '81312',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'APOSTROPHE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7555',
    payerID: '65418',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FIRSTNATION HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7557',
    payerID: '37135',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CONSOCIATE GROUP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7602',
    payerID: '92805',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MAXOR ADMINISTRATIVE SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7613',
    payerID: '13360',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CENTERLIGHT HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7619',
    payerID: '4245',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HARVARD COMMUNITY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7634',
    payerID: 'H0657',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FRIDAY HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7681',
    payerID: '99320',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BENEFIT and RISK MANAGEMENT SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7689',
    payerID: '79966',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AMIDA CARE MEDICARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7702',
    payerID: 'AWNY6',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AGEWELL NEW YORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7718',
    payerID: 'VCHCP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'VENTURA COUNTY HEALTH CARE PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7753',
    payerID: '81312',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'APOSTROPHE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7759',
    payerID: '65418',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FIRSTNATION HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7815',
    payerID: 'SIM01',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SIMPRA ADVANTAGE INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7828',
    payerID: 'VAPRM',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'VIRGINIA PREMIER HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7868',
    payerID: '81600',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SOUTH COUNTRY HEALTH ALLIANCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7871',
    payerID: 'UC001',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BRAND NEW DAY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7943',
    payerID: '6607',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CARDINAL INNOVATIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '7963',
    payerID: '37086',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PEKIN INSURANCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8129',
    payerID: 'FLCCR',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FLORIDA COMMUNITY CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8135',
    payerID: 'DRHCP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'DOCTORS HEALTHCARE PLANS ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8241',
    payerID: 'TCC93',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TCC BENEFITS ADMINISTRATOR',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8431',
    payerID: '59140',
    eligibilityID: '',
    claimStatusID: 'HHZCF',
    payerName: 'HEALTH PLAN SERVICES (TAMPA only)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8467',
    payerID: 'KCMD1',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'KEYCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8482',
    payerID: '37118',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BENEFIT PLAN ADMINISTRATORS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8521',
    payerID: '62061',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FiSERV HEALTH (KANSAS and TENNESSEE)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: 'KY',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: 'WV',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8548',
    payerID: '35112',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EMPLOYEE PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8561',
    payerID: '46045',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AVERA HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8603',
    payerID: '36878',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ENTRUST',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8618',
    payerID: 'ARGUS',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ARGUS DENTAL and VISION INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8646',
    payerID: 'GHP660537624',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'FIRST MEDICAL HEALTH PLAN (REFORM)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8653',
    payerID: 'CCHPC',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ALIGNMENT HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8665',
    payerID: '441MP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEDPARTNERS ADMINISTRATIVE SERVICES 441MP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8684',
    payerID: 'PROSP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PROSPECT MEDICAL SYSTEMS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8689',
    payerID: 'MPM03',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CENTINELA VALLEY IPA MEDICAL',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8726',
    payerID: '68037',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEDCORE OMNI IPA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8785',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'DELTA DENTAL of MINNESOTA',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8908',
    payerID: '5003',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PRESBYTERIAN HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8915',
    payerID: 'MHHNP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MEMORIAL HERMANN HEALTH SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8918',
    payerID: 'IMSMS',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'INSURANCE MANAGEMENT SERVICES TX',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8926',
    payerID: '10207',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CITRUS HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8932',
    payerID: '20029',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AMERICAS CHOICE HEALTHPLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8933',
    payerID: 'AMAIA',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AMA INSURANCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8934',
    payerID: '93044',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'A and I BENEFIT PLAN ADMINISTRATORS ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '8948',
    payerID: '53085',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ANCHOR BENEFIT CONSULTING',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9180',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GUARANTEE TRUST LIFE ',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9194',
    payerID: '52133',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE DENTAL BENEFIT PROVIDERS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9500',
    payerID: '77039',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'KERN HEALTH SYSTEMS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9502',
    payerID: 'SIM01',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SIMPRA ADVANTAGE INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9521',
    payerID: '14966',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PARTNERS HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9524',
    payerID: '86083',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SUMMIT ADMINISTRATION SERVICES, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9563',
    payerID: 'PH001',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PRUITTHEALTH PREMIER',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9576',
    payerID: 'CAPMN',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CONIFER HEALTH SOLUTIONS',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9576',
    payerID: 'CAPMN',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CONIFER HEALTH SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9622',
    payerID: '77052',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'COASTAL TPA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9643',
    payerID: '45282',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CAREFIRST BCBS MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9645',
    payerID: 'AWNY6',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AGEWELL NEW YORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: '9659',
    payerID: 'VCHCP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'VENTURA COUNTY HEALTH CARE PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: 'ALTR',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AULTRA ADMINISTRATIVE GROUP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: 'ATRI',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ATRIO HEALTH PLANS',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: 'BCIH',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BELL COUNTY INDIGENT HEALTH CARE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: 'BENE',
    payerID: '37248',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'BENESYS',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'null'
  },
  {
    cHCPayerID: 'CCQU',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CARE COORDINATORS BY QUANTUM HEALTH',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: 'CHPD',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CAREFIRST COMMUNITY HEALTH PLAN DISTRICT COLUMBIA',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: 'CNSL',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CONSTITUTION LIFE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'CNTY',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SAN DIEGO COUNTY MEDICAL SERVICES (CMS)',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'COMH',
    payerID: '38325',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'COMMUNITY HEALTH PLAN of MICHIGHAN',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'CTRY',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'COUNTRY LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'DDEN',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'DELTA DENTAL',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'DMND',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'DOMINION DENTAL',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'DNAD',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'DEAN HEALTH PLAN ADMINISTRATIVE SERVICES (ASO)',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'DQES',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'DENTAQUEST GOVERNMENT SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'ELDO',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'ELDORADO COMPUTION, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'EQUI',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SILAC INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'EVER',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'EVEREST REINSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'GEH1',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GEHA DENTAL CONNECTION FEDERAL',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'GRPR',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GROUP RESOURCES',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'GSLI',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GREAT SOUTHERN LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: 'GULF',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'GULF SOUTH RISK SERVICES',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'HLS1',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HEALTH SHARE of OREGON',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'HLTC',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MONTANA HEALTH CO-OP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: 'HLTH',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HEALTH SHARE of OREGON',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'HLTM',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HEALTHNET MEDICARE ADVANTAGE - OREGON',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'HMA1',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'HMA HAWAII',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'IDEA',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'IDEAL LIFE INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'IDLC',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'IDEALCARE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'KSKJ',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'KSKJ LIFE - AMERICAN SLOVENIAN CATHOLIC UNION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'LMCO',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'LUMICO',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'LSUH',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'LSU HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MADN',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'MASSHEALTH DENTAL',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MDKD',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'KIDNEY DISEASE PROGRAM of MARYLAND',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'NATG',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'NATIONAL GENERAL BENEFITS SOLUTION',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'OXLI',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'OXFORD LIFE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'PAMA',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'AMERIHEALTH PA MEDICAL ASSISTANCE PLAN',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'PRKC',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PARKCARE INSURANCE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'PRTN',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'PURITAN LIFE INSURANCE COMPANY of AMERICA',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'RENA',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'CHRONIC RENAL PROGRAM',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'SEFI',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SEAFARERS INTERNATIONAL UNION',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'SHMA',
    payerID: 'SHMAP',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SETON MAP PROGRAM',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'SHND',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'SHENANDOAH LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'STER',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'STERLING INSURANCE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'STMT',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'STATE MUTUAL INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'TMLB',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TML INTERGOVERNMENTAL EMPLOYEE BENEFITS POOL',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'TRST',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'TRISTAR',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'UFHC',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNIVERSITY of FLORIDA HEALTH DIRECT CARE NETWORK',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'UHC1',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE COMMUNITY PLAN - DENTAL',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'UNTH',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'UNITE HERE HEALTH',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'WASH',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'WASHINGTON STATE HEALTH INSURANCE POOL (WSHIP)',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'WELC',
    payerID: '',
    eligibilityID: '',
    claimStatusID: '',
    payerName: 'WELLCARE HEALTH PLANS OF KENTUCKY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, P',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4551',
    payerID: '13162',
    eligibilityID: '1199NB',
    claimStatusID: '',
    payerName: '1199 NATIONAL BENEFIT FUND',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5405',
    payerID: '13162',
    eligibilityID: '1199NB',
    claimStatusID: '',
    payerName: '1199 NATIONAL BENEFIT FUND',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1402',
    payerID: '720',
    eligibilityID: '1402',
    claimStatusID: 'MNBCBS',
    payerName: 'MINNESOTA BLUE SHIELD',
    claimType: 'Professional',
    State: 'MN',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1402',
    payerID: '720',
    eligibilityID: '1402',
    claimStatusID: 'MNBCBS',
    payerName: 'MINNESOTA BLUE SHIELD',
    claimType: 'Professional',
    State: 'MN',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1410',
    payerID: '720',
    eligibilityID: '1402',
    claimStatusID: '',
    payerName: 'MINNESOTA CC SYSTEMS',
    claimType: 'Professional',
    State: 'MN',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1429',
    payerID: '720',
    eligibilityID: '1402',
    claimStatusID: '',
    payerName: 'MINNESOTA BLUE SHIELD HMO',
    claimType: 'Professional',
    State: 'MN',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1513',
    payerID: '220',
    eligibilityID: '1402',
    claimStatusID: 'MNBCBS',
    payerName: 'MINNESOTA BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1468',
    payerID: '411674742',
    eligibilityID: '1468',
    claimStatusID: '1468',
    payerName: 'MINNESOTA MEDICAID',
    claimType: 'Professional',
    State: 'MN',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1568',
    payerID: '411674742',
    eligibilityID: '1468',
    claimStatusID: '1568',
    payerName: 'MINNESOTA MEDICAID',
    claimType: 'Institutional',
    State: 'MN',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1487',
    payerID: 'NDDHSMED',
    eligibilityID: '1487',
    claimStatusID: 'NDCAID',
    payerName: 'NORTH DAKOTA MEDICAID',
    claimType: 'Professional',
    State: 'ND',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5271',
    payerID: '36273',
    eligibilityID: 'AARP',
    claimStatusID: 'AARP',
    payerName: 'AARP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8940',
    payerID: '36273',
    eligibilityID: 'AARP',
    claimStatusID: 'AARP',
    payerName: 'AARP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1015',
    payerID: '128CA',
    eligibilityID: 'ABHCA',
    claimStatusID: 'ABHCA',
    payerName: 'AETNA BETTER HEALTH of CALIFORNIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '7838',
    payerID: '128CA',
    eligibilityID: 'ABHCA',
    claimStatusID: 'ABHCA',
    payerName: 'AETNA BETTER HEALTH of CALIFORNIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '7265',
    payerID: '128FL',
    eligibilityID: 'ABHFL',
    claimStatusID: 'CHCVST',
    payerName: 'AETNA BETTER HEALTH of FLORIDA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8678',
    payerID: '128FL',
    eligibilityID: 'ABHFL',
    claimStatusID: 'CHCVST',
    payerName: 'AETNA BETTER HEALTH of FLORIDA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4623',
    payerID: '26337',
    eligibilityID: 'ABHILL',
    claimStatusID: 'ABHILL',
    payerName: 'AETNA BETTER HEALTH of ILLINOIS - MMAI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6140',
    payerID: '26337',
    eligibilityID: 'ABHILL',
    claimStatusID: 'ABHILL',
    payerName: 'AETNA BETTER HEALTH of ILLINOIS - MMAI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1084',
    payerID: '128KS',
    eligibilityID: 'ABHKS',
    claimStatusID: 'ABHKS',
    payerName: 'AETNA BETTER HEALTH OF KANSAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8111',
    payerID: '128KS',
    eligibilityID: 'ABHKS',
    claimStatusID: 'ABHKS',
    payerName: 'AETNA BETTER HEALTH OF KANSAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7183',
    payerID: '128LA',
    eligibilityID: 'ABHLA',
    claimStatusID: 'ABHLA',
    payerName: 'AETNA BETTER HEALTH of LOUISIANA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8621',
    payerID: '128LA',
    eligibilityID: 'ABHLA',
    claimStatusID: 'ABHLA',
    payerName: 'AETNA BETTER HEALTH of LOUISIANA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7554',
    payerID: '128MD',
    eligibilityID: 'ABHMD',
    claimStatusID: 'ABHMD',
    payerName: 'AETNA BETTER HEALTH of MARYLAND',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7758',
    payerID: '128MD',
    eligibilityID: 'ABHMD',
    claimStatusID: 'ABHMD',
    payerName: 'AETNA BETTER HEALTH of MARYLAND',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7220',
    payerID: '128MI',
    eligibilityID: 'ABHMI',
    claimStatusID: 'ABHMI',
    payerName: 'AETNA BETTER HEALTH of MIGHIGAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8637',
    payerID: '128MI',
    eligibilityID: 'ABHMI',
    claimStatusID: 'ABHMI',
    payerName: 'AETNA BETTER HEALTH of MIGHIGAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '7190',
    payerID: '46320',
    eligibilityID: 'ABHNJ',
    claimStatusID: 'ABHNJ',
    payerName: 'AETNA BETTER HEALTH of NEW JERSEY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8629',
    payerID: '46320',
    eligibilityID: 'ABHNJ',
    claimStatusID: 'ABHNJ',
    payerName: 'AETNA BETTER HEALTH of NEW JERSEY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6832',
    payerID: '34734',
    eligibilityID: 'ABHNY',
    claimStatusID: 'ABHNY',
    payerName: 'AETNA BETTER HEALTH of NEW YORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7612',
    payerID: '34734',
    eligibilityID: 'ABHNY',
    claimStatusID: 'ABHNY',
    payerName: 'AETNA BETTER HEALTH of NEW YORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6876',
    payerID: '50023',
    eligibilityID: 'ABHOH',
    claimStatusID: 'ABHOH',
    payerName: 'AETNA BETTER HEALTH of OHIO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7652',
    payerID: '50023',
    eligibilityID: 'ABHOH',
    claimStatusID: 'ABHOH',
    payerName: 'AETNA BETTER HEALTH of OHIO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3617',
    payerID: '23228',
    eligibilityID: 'ABHPA',
    claimStatusID: 'ABHPA',
    payerName: 'AETNA BETTER HEALTH of PENNSYLVANIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5823',
    payerID: '23228',
    eligibilityID: 'ABHPA',
    claimStatusID: 'ABHPA',
    payerName: 'AETNA BETTER HEALTH of PENNSYLVANIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5823',
    payerID: '23228',
    eligibilityID: 'ABHPA',
    claimStatusID: 'ABHPA',
    payerName: 'AETNA BETTER HEALTH of PENNSYLVANIA',
    claimType: 'Professional',
    State: 'PA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2142',
    payerID: '38692',
    eligibilityID: 'ABHTX',
    claimStatusID: 'ABHTX',
    payerName: 'AETNA BETTER HEALTH of TEXAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2142',
    payerID: '38692',
    eligibilityID: 'ABHTX',
    claimStatusID: 'ABHTX',
    payerName: 'AETNA BETTER HEALTH of TEXAS',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2933',
    payerID: '38692',
    eligibilityID: 'ABHTX',
    claimStatusID: 'ABHTX',
    payerName: 'AETNA BETTER HEALTH of TEXAS',
    claimType: 'Institutional',
    State: 'TX',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2933',
    payerID: '38692',
    eligibilityID: 'ABHTX',
    claimStatusID: 'ABHTX',
    payerName: 'AETNA BETTER HEALTH of TEXAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5051',
    payerID: '61108',
    eligibilityID: 'ABHUM',
    claimStatusID: '',
    payerName: 'AUTHOR BY HUMANA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8895',
    payerID: '61108',
    eligibilityID: 'ABHUM',
    claimStatusID: '',
    payerName: 'AUTHOR BY HUMANA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '1147',
    payerID: '128WV',
    eligibilityID: 'ABHWV',
    claimStatusID: 'ABHWV',
    payerName: 'AETNA BETTER HEALTH of WEST VIRGINIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1577',
    payerID: '128WV',
    eligibilityID: 'ABHWV',
    claimStatusID: 'ABHWV',
    payerName: 'AETNA BETTER HEALTH of WEST VIRGINIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3230',
    payerID: '38259',
    eligibilityID: 'ABSERV',
    claimStatusID: '',
    payerName: 'AUTOMATED BENEFIT SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8947',
    payerID: '38259',
    eligibilityID: 'ABSERV',
    claimStatusID: '',
    payerName: 'AUTOMATED BENEFIT SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'ACOR',
    payerID: '',
    eligibilityID: 'ACORDN',
    claimStatusID: '',
    payerName: 'HEALTHSMART BENEFIT SOLUTIONS, INC. (OKLAHOMA CITY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'ACRD',
    payerID: '',
    eligibilityID: 'ACORDN',
    claimStatusID: '',
    payerName: 'HEALTHSMART BENEFIT SOLUTIONS WV fka WELLS FARGO',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1843',
    payerID: '72467',
    eligibilityID: 'ACSBE',
    claimStatusID: '',
    payerName: 'ACS BENEFIT SERVICES, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3908',
    payerID: '72467',
    eligibilityID: 'ACSBE',
    claimStatusID: '',
    payerName: 'ACS BENEFIT SERVICES, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7212',
    payerID: '77013',
    eligibilityID: 'ACVMI',
    claimStatusID: 'ACVMI',
    payerName: 'AMERIHEALTH CARITAS VIP CARE PLUS MICHIGAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8656',
    payerID: '77013',
    eligibilityID: 'ACVMI',
    claimStatusID: 'ACVMI',
    payerName: 'AMERIHEALTH CARITAS VIP CARE PLUS MICHIGAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3228',
    payerID: '22384',
    eligibilityID: 'ADMCON',
    claimStatusID: 'ADMCON',
    payerName: 'ADMINISTRATIVE CONCEPTS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3952',
    payerID: 'E2384',
    eligibilityID: 'ADMCON',
    claimStatusID: 'ADMCON',
    payerName: 'ADMINISTRATIVE CONCEPTS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4295',
    payerID: '59141',
    eligibilityID: 'ADMINS',
    claimStatusID: '',
    payerName: 'ADMINISTRATIVE SERVICES INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9565',
    payerID: '59141',
    eligibilityID: 'ADMINS',
    claimStatusID: '',
    payerName: 'ADMINISTRATIVE SERVICES INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'AETL',
    payerID: '',
    eligibilityID: 'AETLTC',
    claimStatusID: '',
    payerName: 'AETNA LONG TERM CARE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4500',
    payerID: '60054',
    eligibilityID: 'AETNA',
    claimStatusID: 'AETNA',
    payerName: 'AETNA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6400',
    payerID: '60054',
    eligibilityID: 'AETNA',
    claimStatusID: 'AETNA',
    payerName: 'AETNA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1933',
    payerID: '13334',
    eligibilityID: 'AFFIN',
    claimStatusID: '',
    payerName: 'AFFINITY HEALTH PLAN DOS before 11/01/2021',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2247',
    payerID: '13334',
    eligibilityID: 'AFFIN',
    claimStatusID: '',
    payerName: 'AFFINITY HEALTH PLAN DOS before 11/01/2021',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'AFLA',
    payerID: '',
    eligibilityID: 'AFLAC',
    claimStatusID: '',
    payerName: 'AFLAC - DENTAL',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'AFLM',
    payerID: '',
    eligibilityID: 'AFLCMS',
    claimStatusID: '',
    payerName: 'AFLAC MEDICARE SUPPLEMENT',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3056',
    payerID: 'AFLAC',
    eligibilityID: 'AFLCOM',
    claimStatusID: '',
    payerName: 'AFLAC COMMERCIAL',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5670',
    payerID: '77002',
    eligibilityID: 'AHCDC',
    claimStatusID: 'AHCDC',
    payerName: 'AMERIHEALTH CARITAS DISTRICT of COLUMBIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6441',
    payerID: '77002',
    eligibilityID: 'AHCDC',
    claimStatusID: 'AHCDC',
    payerName: 'AMERIHEALTH CARITAS DISTRICT of COLUMBIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7507',
    payerID: '77799',
    eligibilityID: 'AHCDE',
    claimStatusID: 'AHCDE',
    payerName: 'AMERIHEALTH CARITAS DELAWARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7746',
    payerID: '77799',
    eligibilityID: 'AHCDE',
    claimStatusID: 'AHCDE',
    payerName: 'AMERIHEALTH CARITAS DELAWARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5660',
    payerID: '77001',
    eligibilityID: 'AHCNE',
    claimStatusID: '',
    payerName: 'AMERIHEALTH CARITAS NORTHEAST DOS BEFORE 01/01/21',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6421',
    payerID: '77001',
    eligibilityID: 'AHCNE',
    claimStatusID: '',
    payerName: 'AMERIHEALTH CARITAS NORTHEAST DOS BEFORE 01/01/21',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'AHPM',
    payerID: '',
    eligibilityID: 'AHPMSP',
    claimStatusID: '',
    payerName: 'ARISE HEALTH PLAN MEDICARE SELECT POLICY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4489',
    payerID: 'AKMEDICAID FHSC',
    eligibilityID: 'AKMCD',
    claimStatusID: '',
    payerName: 'ALASKA MEDICAID',
    claimType: 'Professional',
    State: 'AK',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5524',
    payerID: 'AKMEDICAID FHSC',
    eligibilityID: 'AKMCD',
    claimStatusID: '',
    payerName: 'ALASKA MEDICAID',
    claimType: 'Institutional',
    State: 'AK',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5558',
    payerID: '00010BC',
    eligibilityID: 'ALBCSI',
    claimStatusID: 'ALBCSI',
    payerName: 'ALABAMA BLUE CROSS',
    claimType: 'Institutional',
    State: 'AL',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2423',
    payerID: '510',
    eligibilityID: 'ALBCSP',
    claimStatusID: 'ALBCSP',
    payerName: 'ALABAMA BLUE SHIELD',
    claimType: 'Professional',
    State: 'AL',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1759',
    payerID: '37308',
    eligibilityID: 'ALBENS',
    claimStatusID: '',
    payerName: 'ALLIED BENEFIT SYSTEM',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8573',
    payerID: '37308',
    eligibilityID: 'ALBENS',
    claimStatusID: '',
    payerName: 'ALLIED BENEFIT SYSTEM',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4479',
    payerID: '752548221',
    eligibilityID: 'ALCAID',
    claimStatusID: 'ALCAID',
    payerName: 'ALABAMA MEDICAID',
    claimType: 'Professional',
    State: 'AL',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5560',
    payerID: '752548221',
    eligibilityID: 'ALCAID',
    claimStatusID: 'ALCAID',
    payerName: 'ALABAMA MEDICAID - INPATIENT',
    claimType: 'Institutional',
    State: 'AL',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5587',
    payerID: '752548221',
    eligibilityID: 'ALCAID',
    claimStatusID: 'ALCAID',
    payerName: 'ALABAMA MEDICAID',
    claimType: 'Institutional',
    State: 'AL',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1651',
    payerID: '81040',
    eligibilityID: 'ALLBE',
    claimStatusID: '',
    payerName: 'ALLEGIANCE BENEFIT PLAN MANAGEMENT INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2295',
    payerID: '81040',
    eligibilityID: 'ALLBE',
    claimStatusID: '',
    payerName: 'ALLEGIANCE BENEFIT PLAN MANAGEMENT INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2589',
    payerID: '58234',
    eligibilityID: 'ALLIHP',
    claimStatusID: '',
    payerName: 'ALLIANT HEALTH PLANS of GEORGIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3793',
    payerID: '58234',
    eligibilityID: 'ALLIHP',
    claimStatusID: '',
    payerName: 'ALLIANT HEALTH PLANS of GEORGIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'ALLA',
    payerID: '',
    eligibilityID: 'ALLSA',
    claimStatusID: '',
    payerName: 'ALL SAVERS HEALTH PLAN',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2909',
    payerID: '990309519',
    eligibilityID: 'ALOHA',
    claimStatusID: '',
    payerName: 'ALOHACARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5231',
    payerID: '990309519',
    eligibilityID: 'ALOHA',
    claimStatusID: '',
    payerName: 'ALOHACARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1220',
    payerID: '85600',
    eligibilityID: 'ALPBS',
    claimStatusID: '',
    payerName: 'ALBUQUERQUE PUBLIC SCHOOLS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4518',
    payerID: '85600',
    eligibilityID: 'ALPBS',
    claimStatusID: '',
    payerName: 'ALBUQUERQUE PUBLIC SCHOOLS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1268',
    payerID: '77062',
    eligibilityID: 'AMCVC',
    claimStatusID: 'AMCVC',
    payerName: 'AMERIHEALTH CARITAS VIP CARE PA COMMHEALTHCHOICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6501',
    payerID: '77062',
    eligibilityID: 'AMCVC',
    claimStatusID: 'AMCVC',
    payerName: 'AMERIHEALTH CARITAS VIP CARE PA COMMHEALTHCHOICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2999',
    payerID: '75137',
    eligibilityID: 'AMERBN',
    claimStatusID: '',
    payerName: 'AMERIBEN SOLUTIONS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3755',
    payerID: '75137',
    eligibilityID: 'AMERBN',
    claimStatusID: '',
    payerName: 'AMERIBEN SOLUTIONS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7562',
    payerID: '661',
    eligibilityID: 'AMGRLA',
    claimStatusID: '',
    payerName: 'HEALTHY BLUE LOUISIANA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7760',
    payerID: '661',
    eligibilityID: 'AMGRLA',
    claimStatusID: '',
    payerName: 'HEALTHY BLUE LOUISIANA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2893',
    payerID: '54763',
    eligibilityID: 'AMHADM',
    claimStatusID: '',
    payerName: 'AMERIHEALTH ADMINISTRATORS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4555',
    payerID: '54763',
    eligibilityID: 'AMHADM',
    claimStatusID: '',
    payerName: 'AMERIHEALTH ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1710',
    payerID: '22248',
    eligibilityID: 'AMHLTH',
    claimStatusID: 'AMHLTH',
    payerName: 'AMERIHEALTH CARITAS PENNSYLVANIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4547',
    payerID: '22248',
    eligibilityID: 'AMHLTH',
    claimStatusID: 'AMHLTH',
    payerName: 'AMERIHEALTH CARITAS PENNSYLVANIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1941',
    payerID: '81400',
    eligibilityID: 'AMMEDS',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE LIFE INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7481',
    payerID: '81400',
    eligibilityID: 'AMMEDS',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE LIFE INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4083',
    payerID: '81671',
    eligibilityID: 'AMNCN',
    claimStatusID: 'AMNCN',
    payerName: 'AMERIHEALTH CARITAS NORTH CAROLINA ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8859',
    payerID: '81671',
    eligibilityID: 'AMNCN',
    claimStatusID: 'AMNCN',
    payerName: 'AMERIHEALTH CARITAS NORTH CAROLINA ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '2090',
    payerID: '87716',
    eligibilityID: 'AMNHP',
    claimStatusID: 'AMNHP',
    payerName: 'AMERIHEALTH CARITAS NEW HAMPSHIRE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8238',
    payerID: '87716',
    eligibilityID: 'AMNHP',
    claimStatusID: 'AMNHP',
    payerName: 'AMERIHEALTH CARITAS NEW HAMPSHIRE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3869',
    payerID: '74048',
    eligibilityID: 'AMNIC',
    claimStatusID: '',
    payerName: 'AMERICAN NATIONAL INSURANCE COMPANY (ANICO)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4974',
    payerID: '74048',
    eligibilityID: 'AMNIC',
    claimStatusID: '',
    payerName: 'AMERICAN NATIONAL INSURANCE COMPANY (ANICO)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4503',
    payerID: '44444',
    eligibilityID: 'AMPWU',
    claimStatusID: 'AMPWU',
    payerName: 'AMERICAN POSTAL WORKERS UNION',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6436',
    payerID: '44444',
    eligibilityID: 'AMPWU',
    claimStatusID: 'AMPWU',
    payerName: 'AMERICAN POSTAL WORKERS UNION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'AMRC',
    payerID: '',
    eligibilityID: 'AMRCO',
    claimStatusID: '',
    payerName: 'AMERICO',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1569',
    payerID: '26375',
    eligibilityID: 'AMRGRP',
    claimStatusID: 'AMRGRP',
    payerName: 'AMERIGROUP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1741',
    payerID: '26375',
    eligibilityID: 'AMRGRP',
    claimStatusID: 'AMRGRP',
    payerName: 'AMERIGROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1741',
    payerID: '26375',
    eligibilityID: 'AMRGRP',
    claimStatusID: 'AMRGRP',
    payerName: 'AMERIGROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1553',
    payerID: '42011',
    eligibilityID: 'AMRREP',
    claimStatusID: '',
    payerName: 'AMERICAN REPUBLIC INSURANCE (MAP)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2895',
    payerID: '42011',
    eligibilityID: 'AMRREP',
    claimStatusID: '',
    payerName: 'AMERICAN REPUBLIC INSURANCE (MAP)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'AMRT',
    payerID: '',
    eligibilityID: 'AMRTAS',
    claimStatusID: '',
    payerName: 'AMERITAS LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'AMTS',
    payerID: '',
    eligibilityID: 'AMTSNY',
    claimStatusID: '',
    payerName: 'FIRST AMERITAS of NEW YORK',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6032',
    payerID: '39856',
    eligibilityID: 'ANGLE',
    claimStatusID: '',
    payerName: 'ANGLE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9184',
    payerID: '39856',
    eligibilityID: 'ANGLE',
    claimStatusID: '',
    payerName: 'ANGLE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: 'ANLI',
    payerID: '',
    eligibilityID: 'ANLITX',
    claimStatusID: '',
    payerName: 'AMERICAN NATIONAL LIFE INSURANCE COMPANY of TEXAS',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2290',
    payerID: '34192',
    eligibilityID: 'ANTAR',
    claimStatusID: 'ANTAR',
    payerName: 'MUTUAL HEALTH SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2935',
    payerID: '34192',
    eligibilityID: 'ANTAR',
    claimStatusID: 'ANTAR',
    payerName: 'MUTUAL HEALTH SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '4020',
    payerID: '958',
    eligibilityID: 'ANTHME',
    claimStatusID: 'ANTHME',
    payerName: 'ANTHEM MAINEHEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8749',
    payerID: '958',
    eligibilityID: 'ANTHME',
    claimStatusID: 'ANTHME',
    payerName: 'ANTHEM MAINEHEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6066',
    payerID: '83112',
    eligibilityID: 'APXHL',
    claimStatusID: '',
    payerName: 'APEXHEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9223',
    payerID: '83112',
    eligibilityID: 'APXHL',
    claimStatusID: '',
    payerName: 'APEXHEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '2416',
    payerID: '520',
    eligibilityID: 'ARBCBS',
    claimStatusID: '',
    payerName: 'ARKANSAS BLUE SHIELD',
    claimType: 'Professional',
    State: 'AR',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2416',
    payerID: '520',
    eligibilityID: 'ARBCBS',
    claimStatusID: '',
    payerName: 'ARKANSAS BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5596',
    payerID: '520',
    eligibilityID: 'ARBCBS',
    claimStatusID: '',
    payerName: 'ARKANSAS BLUE CROSS',
    claimType: 'Institutional',
    State: 'AR',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5596',
    payerID: '520',
    eligibilityID: 'ARBCBS',
    claimStatusID: '',
    payerName: 'ARKANSAS BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1489',
    payerID: '716007869',
    eligibilityID: 'ARCAID',
    claimStatusID: '1489',
    payerName: 'ARKANSAS MEDICAID',
    claimType: 'Professional',
    State: 'AR',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5597',
    payerID: '716007869',
    eligibilityID: 'ARCAID',
    claimStatusID: '5597',
    payerName: 'ARKANSAS MEDICAID',
    claimType: 'Institutional',
    State: 'AR',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1680',
    payerID: '99999',
    eligibilityID: 'ARCHB',
    claimStatusID: '',
    payerName: 'ARCHBOLD MEDICAL CENTER HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7150',
    payerID: '99999',
    eligibilityID: 'ARCHB',
    claimStatusID: '',
    payerName: 'ARCHBOLD MEDICAL CENTER HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8126',
    payerID: 'AMC5001',
    eligibilityID: 'ARCHB',
    claimStatusID: '',
    payerName: 'ARCHBOLD ED UNINSURED',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'ARLI',
    payerID: '',
    eligibilityID: 'ARLIMS',
    claimStatusID: '',
    payerName: 'AMMERICAN RETIREMENT LIFE INSURANCE CO MEDICARE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1163',
    payerID: '46156',
    eligibilityID: 'ASPHPL',
    claimStatusID: '',
    payerName: 'ASPIRE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2507',
    payerID: '46156',
    eligibilityID: 'ASPHPL',
    claimStatusID: '',
    payerName: 'ASPIRE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4097',
    payerID: '36483',
    eligibilityID: 'ASPRS',
    claimStatusID: '',
    payerName: 'ASPIRUS MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8840',
    payerID: '36483',
    eligibilityID: 'ASPRS',
    claimStatusID: '',
    payerName: 'ASPIRUS MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'ASRH',
    payerID: '',
    eligibilityID: 'ASRHLT',
    claimStatusID: '',
    payerName: 'ASR HEALTH BENEFITS',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4881',
    payerID: '93221',
    eligibilityID: 'ASURNH',
    claimStatusID: 'ASURNH',
    payerName: 'ASURIS NORTHWEST REGENCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5942',
    payerID: '93221',
    eligibilityID: 'ASURNH',
    claimStatusID: 'ASURNH',
    payerName: 'ASURIS NORTHWEST REGENCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'ATIR',
    payerID: '',
    eligibilityID: 'ATIRS',
    claimStatusID: '',
    payerName: 'ALTERNATIVE INSURANCE RESOURCES INC',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6870',
    payerID: '62118',
    eligibilityID: 'ATNSSI',
    claimStatusID: 'ATNSSI',
    payerName: 'AETNA SSI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7649',
    payerID: '62118',
    eligibilityID: 'ATNSSI',
    claimStatusID: 'ATNSSI',
    payerName: 'AETNA SSI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4791',
    payerID: '341488123',
    eligibilityID: 'AULTCR',
    claimStatusID: 'AULTCR',
    payerName: 'AULTCARE of OHIO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5927',
    payerID: '341488123',
    eligibilityID: 'AULTCR',
    claimStatusID: 'AULTCR',
    payerName: 'AULTCARE of OHIO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1121',
    payerID: '',
    eligibilityID: 'AUXANT',
    claimStatusID: '',
    payerName: 'AUXIANT HEALTH',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1573',
    payerID: '59274',
    eligibilityID: 'AVMED',
    claimStatusID: 'AVMED',
    payerName: 'AVMED, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3802',
    payerID: '59274',
    eligibilityID: 'AVMED',
    claimStatusID: 'AVMED',
    payerName: 'AVMED, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4426',
    payerID: '53589',
    eligibilityID: 'AZBCBS',
    claimStatusID: 'AZBCBS',
    payerName: 'ARIZONA BLUE SHIELD',
    claimType: 'Professional',
    State: 'AZ',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5547',
    payerID: '53589',
    eligibilityID: 'AZBCBS',
    claimStatusID: 'AZBCBS',
    payerName: 'ARIZONA BLUE CROSS',
    claimType: 'Institutional',
    State: 'AZ',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4405',
    payerID: '866004791',
    eligibilityID: 'AZCAID',
    claimStatusID: '',
    payerName: 'ARIZONA MEDICAID',
    claimType: 'Professional',
    State: 'AZ',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5548',
    payerID: '866004791',
    eligibilityID: 'AZCAID',
    claimStatusID: '',
    payerName: 'ARIZONA MEDICAID',
    claimType: 'Institutional',
    State: 'AZ',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2930',
    payerID: '62179',
    eligibilityID: 'AZHLCH',
    claimStatusID: '',
    payerName: 'HEALTH CHOICE ARIZONA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3232',
    payerID: '62179',
    eligibilityID: 'AZHLCH',
    claimStatusID: '',
    payerName: 'HEALTH CHOICE ARIZONA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3433',
    payerID: '86052',
    eligibilityID: 'AZMCP',
    claimStatusID: 'AZMCP',
    payerName: 'MERCY CARE PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3530',
    payerID: '86052',
    eligibilityID: 'AZMCP',
    claimStatusID: 'AZMCP',
    payerName: 'MERCY CARE PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1585',
    payerID: '3432',
    eligibilityID: 'AZPIPA',
    claimStatusID: 'AZPIPA',
    payerName: 'UNITEDHEALTHCARE ARIZONA PHYSICIANS IPA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3432',
    payerID: '3432',
    eligibilityID: 'AZPIPA',
    claimStatusID: 'AZPIPA',
    payerName: 'UNITEDHEALTHCARE ARIZONA PHYSICIANS IPA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2016',
    payerID: '81079',
    eligibilityID: 'BAYCR',
    claimStatusID: '',
    payerName: 'BAYCARE PLUS MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8150',
    payerID: '81079',
    eligibilityID: 'BAYCR',
    claimStatusID: '',
    payerName: 'BAYCARE PLUS MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5096',
    payerID: '32002',
    eligibilityID: 'BCCMI',
    claimStatusID: 'BCCMI',
    payerName: 'BLUE CROSS COMPLETE of MICHIGAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '7409',
    payerID: '32002',
    eligibilityID: 'BCCMI',
    claimStatusID: 'BCCMI',
    payerName: 'BLUE CROSS COMPLETE of MICHIGAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7165',
    payerID: '66005',
    eligibilityID: 'BCCOM',
    claimStatusID: '',
    payerName: 'BLUE CROSS COMMUNITY HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8606',
    payerID: '66005',
    eligibilityID: 'BCCOM',
    claimStatusID: '',
    payerName: 'BLUE CROSS COMMUNITY HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'BCLA',
    payerID: '84555',
    eligibilityID: 'BCLAM',
    claimStatusID: '',
    payerName: 'HMO LOUISIANA BLUE ADVANTAGE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6675',
    payerID: 'BRIDG',
    eligibilityID: 'BDSPH',
    claimStatusID: '',
    payerName: 'BRIDGESPAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6779',
    payerID: 'BRIDG',
    eligibilityID: 'BDSPH',
    claimStatusID: '',
    payerName: 'BRIDGESPAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1012',
    payerID: '63100',
    eligibilityID: 'BHRHS',
    claimStatusID: '',
    payerName: 'BEHAVIORAL HEALTH SYSTEMS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6106',
    payerID: '63100',
    eligibilityID: 'BHRHS',
    claimStatusID: '',
    payerName: 'BEHAVIORAL HEALTH SYSTEMS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8139',
    payerID: '25463',
    eligibilityID: 'BIND',
    claimStatusID: '',
    payerName: 'BIND',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2007',
    payerID: '25463',
    eligibilityID: 'BIND ',
    claimStatusID: '',
    payerName: 'BIND',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7428',
    payerID: '922',
    eligibilityID: 'BLCHHP',
    claimStatusID: '',
    payerName: 'SOUTH CAROLINA BLUECHOICE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1167',
    payerID: '66006',
    eligibilityID: 'BLMADV',
    claimStatusID: '',
    payerName: 'BLUE CROSS MEDICARE ADVANTAGE PPO/HMO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2513',
    payerID: '66006',
    eligibilityID: 'BLMADV',
    claimStatusID: '',
    payerName: 'BLUE CROSS MEDICARE ADVANTAGE PPO/HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6013',
    payerID: '772',
    eligibilityID: 'BMADV',
    claimStatusID: 'BMADV',
    payerName: 'BLUE MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9162',
    payerID: '772',
    eligibilityID: 'BMADV',
    claimStatusID: 'BMADV',
    payerName: 'BLUE MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '5047',
    payerID: '84323',
    eligibilityID: 'BMAHMO ',
    claimStatusID: 'BMAHMO',
    payerName: 'BANNER MEDICARE ADVANTAGE PRIME HMO (BMAH)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8893',
    payerID: '84323',
    eligibilityID: 'BMAHMO ',
    claimStatusID: 'BMAHMO',
    payerName: 'BANNER MEDICARE ADVANTAGE PRIME HMO (BMAH)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '5046',
    payerID: '84324',
    eligibilityID: 'BMAPP',
    claimStatusID: 'BMAPP',
    payerName: 'BANNER MEDICARE ADVANTAGE PLUS PPO (BMAP)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8892',
    payerID: '84324',
    eligibilityID: 'BMAPP',
    claimStatusID: 'BMAPP',
    payerName: 'BANNER MEDICARE ADVANTAGE PLUS PPO (BMAP)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '2921',
    payerID: 'S3337',
    eligibilityID: 'BMCHLT',
    claimStatusID: '',
    payerName: 'BOSTON MEDICAL CENTER HEALTHNET PLAN',
    claimType: 'Institutional',
    State: 'NH',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2921',
    payerID: 'S3337',
    eligibilityID: 'BMCHLT',
    claimStatusID: '',
    payerName: 'BOSTON MEDICAL CENTER HEALTHNET PLAN',
    claimType: 'Institutional',
    State: 'MA',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3818',
    payerID: '13337',
    eligibilityID: 'BMCHLT',
    claimStatusID: '',
    payerName: 'BOSTON MEDICAL CENTER HEALTHNET PLAN',
    claimType: 'Professional',
    State: 'NH',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3818',
    payerID: '13337',
    eligibilityID: 'BMCHLT',
    claimStatusID: '',
    payerName: 'BOSTON MEDICAL CENTER HEALTHNET PLAN',
    claimType: 'Professional',
    State: 'MA',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1777',
    payerID: '74238',
    eligibilityID: 'BNCHAP',
    claimStatusID: '',
    payerName: 'BOON CHAPMAN ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8500',
    payerID: '74238',
    eligibilityID: 'BNCHAP',
    claimStatusID: '',
    payerName: 'BOON CHAPMAN ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1240',
    payerID: '36066',
    eligibilityID: 'BNKLIF',
    claimStatusID: '',
    payerName: 'BANKERS LIFE and CASUALTY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1632',
    payerID: '48611',
    eligibilityID: 'BNMGT',
    claimStatusID: '',
    payerName: 'BENEFIT MANAGEMENT of KANSAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6118',
    payerID: '48611',
    eligibilityID: 'BNMGT',
    claimStatusID: '',
    payerName: 'BENEFIT MANAGEMENT of KANSAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3930',
    payerID: 'BOONG',
    eligibilityID: 'BOONGR',
    claimStatusID: '',
    payerName: 'BOON GROUP (THE)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4278',
    payerID: 'BOONG',
    eligibilityID: 'BOONGR',
    claimStatusID: '',
    payerName: 'BOON GROUP (THE)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5000',
    payerID: '84367',
    eligibilityID: 'BRAVEN',
    claimStatusID: 'BRAVEN',
    payerName: 'BRAVEN HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8844',
    payerID: '84367',
    eligibilityID: 'BRAVEN',
    claimStatusID: 'BRAVEN',
    payerName: 'BRAVEN HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6091',
    payerID: 'BRGHT',
    eligibilityID: 'BRIGHT',
    claimStatusID: '',
    payerName: 'BRIGHT HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9269',
    payerID: 'BRGHT',
    eligibilityID: 'BRIGHT',
    claimStatusID: '',
    payerName: 'BRIGHT HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '1830',
    payerID: '51037',
    eligibilityID: 'BRKRG',
    claimStatusID: '',
    payerName: 'BROKERAGE CONCEPTS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8562',
    payerID: '51037',
    eligibilityID: 'BRKRG',
    claimStatusID: '',
    payerName: 'BROKERAGE CONCEPTS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1290',
    payerID: '66901',
    eligibilityID: 'BUFCR',
    claimStatusID: 'BUFCR',
    payerName: 'BANNER UNIVERSITY FAMILY CARE (UNIVER of AZ HLTH)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6548',
    payerID: '66901',
    eligibilityID: 'BUFCR',
    claimStatusID: 'BUFCR',
    payerName: 'BANNER UNIVERSITY FAMILY CARE (UNIVER of AZ HLTH)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1169',
    payerID: '246',
    eligibilityID: 'BWNYMC',
    claimStatusID: 'BWNYMC',
    payerName: 'BCBS of WESTERN NEW YORK MEDICAID / CHP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2516',
    payerID: '246',
    eligibilityID: 'BWNYMC',
    claimStatusID: 'BWNYMC',
    payerName: 'BCBS of WESTERN NEW YORK MEDICAID / CHP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3511',
    payerID: '47198',
    eligibilityID: 'CABC',
    claimStatusID: 'CABC',
    payerName: 'ANTHEM BLUE CROSS CALIFORNIA',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3511',
    payerID: '47198',
    eligibilityID: 'CABC',
    claimStatusID: 'CABC',
    payerName: 'ANTHEM BLUE CROSS CALIFORNIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4417',
    payerID: '47198',
    eligibilityID: 'CABC',
    claimStatusID: 'CABC',
    payerName: 'ANTHEM BLUE CROSS CALIFORNIA',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4417',
    payerID: '47198',
    eligibilityID: 'CABC',
    claimStatusID: 'CABC',
    payerName: 'ANTHEM BLUE CROSS CALIFORNIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1409',
    payerID: 'BS001',
    eligibilityID: 'CABS',
    claimStatusID: 'CABS',
    payerName: 'CALIFORNIA BLUE SHIELD',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1627',
    payerID: '57115',
    eligibilityID: 'CABS',
    claimStatusID: 'CABS',
    payerName: 'BLUE SHIELD of CALIFORNIA PROMISE HEALTH PLAN',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3050',
    payerID: '94032',
    eligibilityID: 'CABS',
    claimStatusID: '',
    payerName: 'CALIFORNIA BLUE SHIELD - HMO ENCOUNTERS',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4463',
    payerID: '94032',
    eligibilityID: 'CABS',
    claimStatusID: '',
    payerName: 'CALIFORNIA BLUE SHIELD - HMO ENCOUNTERS',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6114',
    payerID: '57115',
    eligibilityID: 'CABS',
    claimStatusID: 'CABS',
    payerName: 'BLUE SHIELD of CALIFORNIA PROMISE HEALTH PLAN',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6569',
    payerID: '94036',
    eligibilityID: 'CABS',
    claimStatusID: 'CABS',
    payerName: 'CALIFORNIA BLUE SHIELD',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1473',
    payerID: '610442',
    eligibilityID: 'CACAID',
    claimStatusID: '',
    payerName: 'CALIFORNIA MEDI-CAL',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3447',
    payerID: '953865941',
    eligibilityID: 'CACAID',
    claimStatusID: '',
    payerName: 'CENCAL HEALTH (formerly SBRHA)',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3510',
    payerID: '610442',
    eligibilityID: 'CACAID',
    claimStatusID: '',
    payerName: 'CALIFORNIA MEDI-CAL',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3568',
    payerID: '953865941',
    eligibilityID: 'CACAID',
    claimStatusID: '',
    payerName: 'CENCAL HEALTH (formerly SBRHA)',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2907',
    payerID: 'NVMED',
    eligibilityID: 'CAIDNV',
    claimStatusID: '',
    payerName: 'NEVADA MEDICAID',
    claimType: 'Institutional',
    State: 'NV',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7495',
    payerID: 'NVMED',
    eligibilityID: 'CAIDNV',
    claimStatusID: '',
    payerName: 'NEVADA MEDICAID',
    claimType: 'Professional',
    State: 'NV',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'NVCH',
    payerID: '',
    eligibilityID: 'CAIDNV',
    claimStatusID: '',
    payerName: 'NEVADA CHECKUP - CHIP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'E',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes-Agreement',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4221',
    payerID: 'CALOP',
    eligibilityID: 'CALOP',
    claimStatusID: '',
    payerName: 'CAL OPTIMA DIRECT',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5914',
    payerID: 'CALOP',
    eligibilityID: 'CALOP',
    claimStatusID: '',
    payerName: 'CAL OPTIMA DIRECT',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1423',
    payerID: '38333',
    eligibilityID: 'CAMLNA',
    claimStatusID: 'CAMLNA',
    payerName: 'MOLINA HEALTHCARE of CALIFORNIA',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3539',
    payerID: '38333',
    eligibilityID: 'CAMLNA',
    claimStatusID: 'CAMLNA',
    payerName: 'MOLINA HEALTHCARE OF CALIFORNIA',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3654',
    payerID: 'CAPHP',
    eligibilityID: 'CAPROK',
    claimStatusID: '',
    payerName: 'CAPROCK HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5848',
    payerID: 'CAPHP',
    eligibilityID: 'CAPROK',
    claimStatusID: '',
    payerName: 'CAPROCK HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1985',
    payerID: '95112',
    eligibilityID: 'CAPTAL',
    claimStatusID: '',
    payerName: 'CAPITAL HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4246',
    payerID: '95112',
    eligibilityID: 'CAPTAL',
    claimStatusID: '',
    payerName: 'CAPITAL HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2013',
    payerID: '66010',
    eligibilityID: 'CAREN',
    claimStatusID: 'CAREN',
    payerName: 'CARE N CARE INSURANCE CO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8146',
    payerID: '66010',
    eligibilityID: 'CAREN',
    claimStatusID: 'CAREN',
    payerName: 'CARE N CARE INSURANCE CO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'CARF',
    payerID: '',
    eligibilityID: 'CARFEP',
    claimStatusID: '',
    payerName: 'CAREFIRST FEDERAL EMPLOYEE PROGRAM',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5001',
    payerID: 'CWSTL',
    eligibilityID: 'CARHLT',
    claimStatusID: '',
    payerName: 'CARPENTERS HEALTH and WELFARE TRUST of ST LOUIS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8846',
    payerID: 'CWSTL',
    eligibilityID: 'CARHLT',
    claimStatusID: '',
    payerName: 'CARPENTERS HEALTH and WELFARE TRUST of ST LOUIS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3737',
    payerID: '3036',
    eligibilityID: 'CBABLU',
    claimStatusID: '',
    payerName: 'CBA BLUE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3737',
    payerID: '3036',
    eligibilityID: 'CBABLU',
    claimStatusID: '',
    payerName: 'CBA BLUE',
    claimType: 'Professional',
    State: 'MA',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7540',
    payerID: '3036',
    eligibilityID: 'CBABLU',
    claimStatusID: '',
    payerName: 'CBA BLUE',
    claimType: 'Institutional',
    State: 'MA',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7540',
    payerID: '3036',
    eligibilityID: 'CBABLU',
    claimStatusID: '',
    payerName: 'CBA BLUE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4674',
    payerID: '38308',
    eligibilityID: 'CBSER',
    claimStatusID: '',
    payerName: 'CHRISTIAN BROTHERS SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6202',
    payerID: '38308',
    eligibilityID: 'CBSER',
    claimStatusID: '',
    payerName: 'CHRISTIAN BROTHERS SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5043',
    payerID: '85468',
    eligibilityID: 'CCAIL',
    claimStatusID: 'CCAIL',
    payerName: 'CLEAR SPRING HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8889',
    payerID: '85468',
    eligibilityID: 'CCAIL',
    claimStatusID: 'CCAIL',
    payerName: 'CLEAR SPRING HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '2149',
    payerID: 'CCA01',
    eligibilityID: 'CCALLH',
    claimStatusID: '',
    payerName: 'CENTRAL CALIFORNIA ALLIANCE for HEALTH (CCAH)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2997',
    payerID: 'CCA01',
    eligibilityID: 'CCALLH',
    claimStatusID: '',
    payerName: 'CENTRAL CALIFORNIA ALLIANCE for HEALTH (CCAH)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7154',
    payerID: '59355',
    eligibilityID: 'CCMINS',
    claimStatusID: 'CCMINS',
    payerName: 'CHRISTIAN CARE MINISTRIES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8638',
    payerID: '59355',
    eligibilityID: 'CCMINS',
    claimStatusID: 'CCMINS',
    payerName: 'CHRISTIAN CARE MINISTRIES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2987',
    payerID: '95491',
    eligibilityID: 'CDPHP',
    claimStatusID: '',
    payerName: 'CAPITAL DISTRICT PHYSICIANS HEALTH PLAN (CDPHP)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3786',
    payerID: '95491',
    eligibilityID: 'CDPHP',
    claimStatusID: '',
    payerName: 'CAPITAL DISTRICT PHYSICIANS HEALTH PLAN (CDPHP)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2518',
    payerID: '31441',
    eligibilityID: 'CDSGP',
    claimStatusID: '',
    payerName: 'S&S HEALTHCARE STRATEGIES',
    claimType: 'Institutional',
    State: 'NV',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2979',
    payerID: '88022',
    eligibilityID: 'CDSGP',
    claimStatusID: '',
    payerName: 'PROMINENCE ADMINISTRATIVE SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '3740',
    payerID: '31441',
    eligibilityID: 'CDSGP',
    claimStatusID: '',
    payerName: 'S&S HEALTHCARE STRATEGIES',
    claimType: 'Professional',
    State: 'NV',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4253',
    payerID: '88022',
    eligibilityID: 'CDSGP',
    claimStatusID: '',
    payerName: 'PROMINENCE ADMINISTRATIVE SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '1630',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - FLORIDA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1660',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - OHIO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2175',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - OHIO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2190',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - FLORIDA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2985',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - INDIANA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3220',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - WISCONSIN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3236',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - INDIANA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3663',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - KANSAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3912',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - GEORGIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3962',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - TEXAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4239',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - GEORGIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4663',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - ILLINOIS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4676',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - SOUTH CAROLINA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4692',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - MISSOURI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4746',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - TEXAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4909',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - AZ DOS before 10/18',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4927',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - WISCONSIN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5207',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - KANSAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5638',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - MISSISSIPPI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6136',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - AZ DOS before 10/18',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6205',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - SOUTH CAROLINA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6212',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - ILLINOIS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6215',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - MISSOURI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6268',
    payerID: '68068',
    eligibilityID: 'CENPAT',
    claimStatusID: 'CENPAT',
    payerName: 'CENPATICO BEHAVIORAL HEALTH - MISSISSIPPI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1051',
    payerID: '45564',
    eligibilityID: 'CENTV',
    claimStatusID: '',
    payerName: 'CENTIVO ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8148',
    payerID: '45564',
    eligibilityID: 'CENTV',
    claimStatusID: '',
    payerName: 'CENTIVO ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4493',
    payerID: '90551',
    eligibilityID: 'CFMHP',
    claimStatusID: 'CFMHP',
    payerName: 'CHRISTUS HEALTH - USFHP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4952',
    payerID: '90551',
    eligibilityID: 'CFMHP',
    claimStatusID: 'CFMHP',
    payerName: 'CHRISTUS HEALTH - USFHP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1730',
    payerID: '75190',
    eligibilityID: 'CFSAD',
    claimStatusID: '',
    payerName: 'CAREFIRST ADMINISTRATORS / NCAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2914',
    payerID: '75190',
    eligibilityID: 'CFSAD',
    claimStatusID: '',
    payerName: 'CAREFIRST ADMINISTRATORS / NCAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'CGIC',
    payerID: '',
    eligibilityID: 'CGICMS',
    claimStatusID: '',
    payerName: 'CONTINENTAL GENERAL INSUR CO MEDICARE SUPPLEMENT',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'CHCADV',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'CHCADV',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7737',
    payerID: '128VA',
    eligibilityID: 'CHCCN',
    claimStatusID: 'CHCCN',
    payerName: 'AETNA BETTER HEALTH of VIRGINIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9686',
    payerID: '128VA',
    eligibilityID: 'CHCCN',
    claimStatusID: 'CHCCN',
    payerName: 'AETNA BETTER HEALTH of VIRGINIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'CHCCVT',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'CHCCVT',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'CHCFED',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'CHCFED',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'CHCSHS',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'CHCSHS',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'CHCUOM',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'CHCUOM',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'CHFM',
    payerID: '',
    eligibilityID: 'CHFMAP',
    claimStatusID: '',
    payerName: 'COMMUNITY HEALTH FIRST MEDICARE ADVANTAGE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7805',
    payerID: '10629',
    eligibilityID: 'CHPMA',
    claimStatusID: '',
    payerName: 'CHRISTUS HEALTH MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8579',
    payerID: '10629',
    eligibilityID: 'CHPMA',
    claimStatusID: '',
    payerName: 'CHRISTUS HEALTH MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7716',
    payerID: '52106',
    eligibilityID: 'CHPTX',
    claimStatusID: '',
    payerName: 'CHRISTUS HEALTH PLAN TEXAS HIX',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9657',
    payerID: '52106',
    eligibilityID: 'CHPTX',
    claimStatusID: '',
    payerName: 'CHRISTUS HEALTH PLAN TEXAS HIX',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3999',
    payerID: 'PA041',
    eligibilityID: 'CHSTR',
    claimStatusID: '',
    payerName: 'THE CHESTERFIELD COMPANIES ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4245',
    payerID: 'PA041',
    eligibilityID: 'CHSTR',
    claimStatusID: '',
    payerName: 'THE CHESTERFIELD COMPANIES ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1841',
    payerID: 'CBH',
    eligibilityID: 'CIGNA',
    claimStatusID: '',
    payerName: 'EVERNORTH BEHAVIORAL HEALTH, INC',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4509',
    payerID: '62308',
    eligibilityID: 'CIGNA',
    claimStatusID: 'CIGNA',
    payerName: 'CIGNA HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4509',
    payerID: '62308',
    eligibilityID: 'CIGNA',
    claimStatusID: 'CIGNA',
    payerName: 'CIGNA HEALTH PLANS',
    claimType: 'Institutional',
    State: 'AR',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4509',
    payerID: '62308',
    eligibilityID: 'CIGNA',
    claimStatusID: 'CIGNA',
    payerName: 'CIGNA HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5943',
    payerID: 'CBH',
    eligibilityID: 'CIGNA',
    claimStatusID: '',
    payerName: 'EVERNORTH BEHAVIORAL HEALTH, INC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6405',
    payerID: '62308',
    eligibilityID: 'CIGNA',
    claimStatusID: 'CIGNA',
    payerName: 'CIGNA HEALTH PLANS',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6405',
    payerID: '62308',
    eligibilityID: 'CIGNA',
    claimStatusID: 'CIGNA',
    payerName: 'CIGNA HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6405',
    payerID: '62308',
    eligibilityID: 'CIGNA',
    claimStatusID: 'CIGNA',
    payerName: 'CIGNA HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1095',
    payerID: 'CLEAR',
    eligibilityID: 'CLEAR',
    claimStatusID: 'CLEAR',
    payerName: 'CLEAR HEALTH ALLIANCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8123',
    payerID: 'CLEAR',
    eligibilityID: 'CLEAR',
    claimStatusID: 'CLEAR',
    payerName: 'CLEAR HEALTH ALLIANCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'CLMS',
    payerID: '',
    eligibilityID: 'CLMSMS',
    claimStatusID: '',
    payerName: 'CLAIMS MANAGEMENT SERVICE INCORPORATED',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6206',
    payerID: '37363',
    eligibilityID: 'CMPSY',
    claimStatusID: '',
    payerName: 'COMPSYCH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6644',
    payerID: '37363',
    eligibilityID: 'CMPSY',
    claimStatusID: '',
    payerName: 'COMPSYCH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3544',
    payerID: '9201',
    eligibilityID: 'CMSHSP',
    claimStatusID: '',
    payerName: 'PUERTO RICO MEDICARE',
    claimType: 'Institutional',
    State: 'PR',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5530',
    payerID: '11003',
    eligibilityID: 'CMSHSP',
    claimStatusID: '5530',
    payerName: 'WEST VIRGINIA MEDICARE',
    claimType: 'Institutional',
    State: 'WV',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5584',
    payerID: '3201',
    eligibilityID: 'CMSHSP',
    claimStatusID: '5584',
    payerName: 'MONTANA MEDICARE',
    claimType: 'Institutional',
    State: 'MT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5907',
    payerID: '1311',
    eligibilityID: 'CMSHSP',
    claimStatusID: '5907',
    payerName: 'NEVADA MEDICARE',
    claimType: 'Institutional',
    State: 'NV',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5912',
    payerID: '12101',
    eligibilityID: 'CMSHSP',
    claimStatusID: '5912',
    payerName: 'DELAWARE MEDICARE',
    claimType: 'Institutional',
    State: 'DE',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5954',
    payerID: '14013',
    eligibilityID: 'CMSHSP',
    claimStatusID: '5954',
    payerName: 'VERMONT MEDICARE',
    claimType: 'Institutional',
    State: 'VT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'HSP',
    payerID: '',
    eligibilityID: 'CMSHSP',
    claimStatusID: '',
    payerName: 'CMS MEDICARE ELIGIBILITY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'E',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes-Agreement',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1434',
    payerID: '6302',
    eligibilityID: 'CMSMED',
    claimStatusID: '1434',
    payerName: 'WISCONSIN MEDICARE',
    claimType: 'Professional',
    State: 'WI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1435',
    payerID: '6202',
    eligibilityID: 'CMSMED',
    claimStatusID: '1435',
    payerName: 'MINNESOTA MEDICARE',
    claimType: 'Professional',
    State: 'MN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1436',
    payerID: '1112',
    eligibilityID: 'CMSMED',
    claimStatusID: '1436',
    payerName: 'NORTHERN CALIFORNIA MEDICARE',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1437',
    payerID: '6102',
    eligibilityID: 'CMSMED',
    claimStatusID: '1437',
    payerName: 'ILLINOIS MEDICARE',
    claimType: 'Professional',
    State: 'IL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1438',
    payerID: '5102',
    eligibilityID: 'CMSMED',
    claimStatusID: '1438',
    payerName: 'IOWA MEDICARE',
    claimType: 'Professional',
    State: 'IA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1440',
    payerID: '4412',
    eligibilityID: 'CMSMED',
    claimStatusID: '1440',
    payerName: 'TEXAS MEDICARE',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1441',
    payerID: '5302',
    eligibilityID: 'CMSMED',
    claimStatusID: '1441',
    payerName: 'MISSOURI MEDICARE EASTERN',
    claimType: 'Professional',
    State: 'MO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1442',
    payerID: '11302',
    eligibilityID: 'CMSMED',
    claimStatusID: '1442',
    payerName: 'VIRGINIA MEDICARE',
    claimType: 'Professional',
    State: 'VA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1443',
    payerID: 'C00882',
    eligibilityID: 'CMSMED',
    claimStatusID: '1443',
    payerName: 'RETIRED RAILROAD MEDICARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1444',
    payerID: '1182',
    eligibilityID: 'CMSMED',
    claimStatusID: '1444',
    payerName: 'SOUTHERN CALIFORNIA MEDICARE',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1445',
    payerID: '8102',
    eligibilityID: 'CMSMED',
    claimStatusID: '1445',
    payerName: 'INDIANA MEDICARE',
    claimType: 'Professional',
    State: 'IN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1446',
    payerID: '1312',
    eligibilityID: 'CMSMED',
    claimStatusID: '1446',
    payerName: 'NEVADA MEDICARE',
    claimType: 'Professional',
    State: 'NV',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1447',
    payerID: '15202',
    eligibilityID: 'CMSMED',
    claimStatusID: '1447',
    payerName: 'OHIO MEDICARE',
    claimType: 'Professional',
    State: 'OH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1448',
    payerID: '9102',
    eligibilityID: 'CMSMED',
    claimStatusID: '1448',
    payerName: 'FLORIDA MEDICARE',
    claimType: 'Professional',
    State: 'FL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1449',
    payerID: '4112',
    eligibilityID: 'CMSMED',
    claimStatusID: '1449',
    payerName: 'COLORADO MEDICARE',
    claimType: 'Professional',
    State: 'CO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1450',
    payerID: '11402',
    eligibilityID: 'CMSMED',
    claimStatusID: '1450',
    payerName: 'WEST VIRGINIA MEDICARE',
    claimType: 'Professional',
    State: 'WV',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1451',
    payerID: '5402',
    eligibilityID: 'CMSMED',
    claimStatusID: '1451',
    payerName: 'NEBRASKA MEDICARE',
    claimType: 'Professional',
    State: 'NE',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1452',
    payerID: '13102',
    eligibilityID: 'CMSMED',
    claimStatusID: '1452',
    payerName: 'CONNECTICUT MEDICARE',
    claimType: 'Professional',
    State: 'CT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1453',
    payerID: '10312',
    eligibilityID: 'CMSMED',
    claimStatusID: '1453',
    payerName: 'TENNESSEE MEDICARE',
    claimType: 'Professional',
    State: 'TN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1455',
    payerID: '2102',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'ALASKA MEDICARE',
    claimType: 'Professional',
    State: 'AK',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1456',
    payerID: '3102',
    eligibilityID: 'CMSMED',
    claimStatusID: '1456',
    payerName: 'ARIZONA MEDICARE',
    claimType: 'Professional',
    State: 'AZ',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1457',
    payerID: '4212',
    eligibilityID: 'CMSMED',
    claimStatusID: '1457',
    payerName: 'NEW MEXICO MEDICARE',
    claimType: 'Professional',
    State: 'NM',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1458',
    payerID: '4312',
    eligibilityID: 'CMSMED',
    claimStatusID: '1458',
    payerName: 'OKLAHOMA MEDICARE',
    claimType: 'Professional',
    State: 'OK',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1459',
    payerID: '2302',
    eligibilityID: 'CMSMED',
    claimStatusID: '1459',
    payerName: 'OREGON MEDICARE',
    claimType: 'Professional',
    State: 'OR',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1460',
    payerID: '7202',
    eligibilityID: 'CMSMED',
    claimStatusID: '1460',
    payerName: 'LOUISIANA MEDICARE',
    claimType: 'Professional',
    State: 'LA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1461',
    payerID: '953',
    eligibilityID: 'CMSMED',
    claimStatusID: '1461',
    payerName: 'MICHIGAN MEDICARE',
    claimType: 'Professional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1462',
    payerID: '2402',
    eligibilityID: 'CMSMED',
    claimStatusID: '1462',
    payerName: 'WASHINGTON MEDICARE',
    claimType: 'Professional',
    State: 'WA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1463',
    payerID: '13282',
    eligibilityID: 'CMSMED',
    claimStatusID: '1463',
    payerName: 'NEW YORK MEDICARE (UPSTATE)',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1464',
    payerID: '11502',
    eligibilityID: 'CMSMED',
    claimStatusID: '1464',
    payerName: 'NORTH CAROLINA MEDICARE ',
    claimType: 'Professional',
    State: 'NC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1465',
    payerID: '12402',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'NEW JERSEY MEDICARE',
    claimType: 'Professional',
    State: 'NJ',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1466',
    payerID: '10212',
    eligibilityID: 'CMSMED',
    claimStatusID: '1466',
    payerName: 'GEORGIA MEDICARE',
    claimType: 'Professional',
    State: 'GA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1506',
    payerID: '10311',
    eligibilityID: 'CMSMED',
    claimStatusID: '1506',
    payerName: 'RURAL HEALTH MEDICARE',
    claimType: 'Institutional',
    State: 'TN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1519',
    payerID: '5401',
    eligibilityID: 'CMSMED',
    claimStatusID: '1519',
    payerName: 'NEBRASKA MEDICARE',
    claimType: 'Institutional',
    State: 'NE',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1522',
    payerID: '12201',
    eligibilityID: 'CMSMED',
    claimStatusID: '1522',
    payerName: 'DISTRICT of COLUMBIA MEDICARE',
    claimType: 'Institutional',
    State: 'DC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1523',
    payerID: '3301',
    eligibilityID: 'CMSMED',
    claimStatusID: '1523',
    payerName: 'NORTH DAKOTA MEDICARE',
    claimType: 'Institutional',
    State: 'ND',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1526',
    payerID: '7101',
    eligibilityID: 'CMSMED',
    claimStatusID: '1526',
    payerName: 'ARKANSAS MEDICARE',
    claimType: 'Institutional',
    State: 'AR',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1527',
    payerID: '3501',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'UTAH MEDICARE',
    claimType: 'Institutional',
    State: 'UT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1547',
    payerID: '4411',
    eligibilityID: 'CMSMED',
    claimStatusID: '1547',
    payerName: 'COLORADO MEDICARE',
    claimType: 'Institutional',
    State: 'CO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1558',
    payerID: '4311',
    eligibilityID: 'CMSMED',
    claimStatusID: '1558',
    payerName: 'OKLAHOMA MEDICARE',
    claimType: 'Institutional',
    State: 'OK',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1560',
    payerID: '11001',
    eligibilityID: 'CMSMED',
    claimStatusID: '1560',
    payerName: 'SOUTH CAROLINA MEDICARE',
    claimType: 'Institutional',
    State: 'SC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2451',
    payerID: '7302',
    eligibilityID: 'CMSMED',
    claimStatusID: '2451',
    payerName: 'MISSISSIPPI MEDICARE',
    claimType: 'Professional',
    State: 'MS',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2452',
    payerID: '11202',
    eligibilityID: 'CMSMED',
    claimStatusID: '2452',
    payerName: 'SOUTH CAROLINA MEDICARE',
    claimType: 'Professional',
    State: 'SC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2453',
    payerID: '3302',
    eligibilityID: 'CMSMED',
    claimStatusID: '2453',
    payerName: 'NORTH DAKOTA MEDICARE',
    claimType: 'Professional',
    State: 'ND',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2454',
    payerID: '3402',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'SOUTH DAKOTA MEDICARE',
    claimType: 'Professional',
    State: 'SD',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2455',
    payerID: '7102',
    eligibilityID: 'CMSMED',
    claimStatusID: '2455',
    payerName: 'ARKANSAS MEDICARE',
    claimType: 'Professional',
    State: 'AR',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2456',
    payerID: '12102',
    eligibilityID: 'CMSMED',
    claimStatusID: '2456',
    payerName: 'DELAWARE MEDICARE',
    claimType: 'Professional',
    State: 'DE',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2457',
    payerID: '12502',
    eligibilityID: 'CMSMED',
    claimStatusID: '2457',
    payerName: 'PENNSYLVANIA MEDICARE',
    claimType: 'Professional',
    State: 'PA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2458',
    payerID: '3502',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'UTAH MEDICARE',
    claimType: 'Professional',
    State: 'UT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2459',
    payerID: '12202',
    eligibilityID: 'CMSMED',
    claimStatusID: '2459',
    payerName: 'DISTRICT of COLUMBIA METROPOLITAN AREA MEDICARE',
    claimType: 'Professional',
    State: 'DC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2461',
    payerID: '12202',
    eligibilityID: 'CMSMED',
    claimStatusID: '2461',
    payerName: 'VIRGINIA MEDICARE (ALEX, ARLGTN, FAIRFAX',
    claimType: 'Professional',
    State: 'VA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2462',
    payerID: '15102',
    eligibilityID: 'CMSMED',
    claimStatusID: '2462',
    payerName: 'KENTUCKY MEDICARE',
    claimType: 'Professional',
    State: 'KY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2463',
    payerID: '5202',
    eligibilityID: 'CMSMED',
    claimStatusID: '2463',
    payerName: 'KANSAS MEDICARE',
    claimType: 'Professional',
    State: 'KS',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2464',
    payerID: '12202',
    eligibilityID: 'CMSMED',
    claimStatusID: '2464',
    payerName: 'MARYLAND MEDICARE (MONTG, PRINCE GEORGE)',
    claimType: 'Professional',
    State: 'MD',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2465',
    payerID: '2202',
    eligibilityID: 'CMSMED',
    claimStatusID: '2465',
    payerName: 'IDAHO MEDICARE',
    claimType: 'Professional',
    State: 'ID',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2466',
    payerID: '3602',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'WYOMING MEDICARE',
    claimType: 'Professional',
    State: 'WY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2467',
    payerID: '1212',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'HAWAII MEDICARE',
    claimType: 'Professional',
    State: 'HI',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2468',
    payerID: '10112',
    eligibilityID: 'CMSMED',
    claimStatusID: '2468',
    payerName: 'ALABAMA MEDICARE',
    claimType: 'Professional',
    State: 'AL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2469',
    payerID: '14212',
    eligibilityID: 'CMSMED',
    claimStatusID: '2469',
    payerName: 'MASSACHUSETTS MEDICARE',
    claimType: 'Professional',
    State: 'MA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2470',
    payerID: '14312',
    eligibilityID: 'CMSMED',
    claimStatusID: '2470',
    payerName: 'NEW HAMPSHIRE MEDICARE',
    claimType: 'Professional',
    State: 'NH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2471',
    payerID: '14512',
    eligibilityID: 'CMSMED',
    claimStatusID: '2471',
    payerName: 'VERMONT MEDICARE',
    claimType: 'Professional',
    State: 'VT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2472',
    payerID: '14112',
    eligibilityID: 'CMSMED',
    claimStatusID: '2472',
    payerName: 'MAINE MEDICARE',
    claimType: 'Professional',
    State: 'ME',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2528',
    payerID: '14013',
    eligibilityID: 'CMSMED',
    claimStatusID: '2528',
    payerName: 'NEW HAMPSHIRE MEDICARE',
    claimType: 'Institutional',
    State: 'NH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2535',
    payerID: '5901',
    eligibilityID: 'CMSMED',
    claimStatusID: '2535',
    payerName: 'J5 NATIONAL PART A',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2537',
    payerID: '5101',
    eligibilityID: 'CMSMED',
    claimStatusID: '2537',
    payerName: 'IOWA MEDICARE',
    claimType: 'Institutional',
    State: 'IA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2785',
    payerID: '9202',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'PUERTO RICO MEDICARE',
    claimType: 'Professional',
    State: 'PR',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3500',
    payerID: '130',
    eligibilityID: 'CMSMED',
    claimStatusID: '3500',
    payerName: 'INDIANA MEDICARE',
    claimType: 'Institutional',
    State: 'IN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3507',
    payerID: '15201',
    eligibilityID: 'CMSMED',
    claimStatusID: '3507',
    payerName: 'OHIO MEDICARE',
    claimType: 'Institutional',
    State: 'OH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3508',
    payerID: '1111',
    eligibilityID: 'CMSMED',
    claimStatusID: '3508',
    payerName: 'CALIFORNIA MEDICARE',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3515',
    payerID: '452',
    eligibilityID: 'CMSMED',
    claimStatusID: '3515',
    payerName: 'MICHIGAN MEDICARE',
    claimType: 'Institutional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3518',
    payerID: '9101',
    eligibilityID: 'CMSMED',
    claimStatusID: '3518',
    payerName: 'FLORIDA MEDICARE',
    claimType: 'Institutional',
    State: 'FL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3519',
    payerID: '13201',
    eligibilityID: 'CMSMED',
    claimStatusID: '3519',
    payerName: 'NEW YORK EMPIRE MEDICARE',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3521',
    payerID: '6201',
    eligibilityID: 'CMSMED',
    claimStatusID: '3521',
    payerName: 'MINNESOTA MEDICARE',
    claimType: 'Institutional',
    State: 'MN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3533',
    payerID: '13101',
    eligibilityID: 'CMSMED',
    claimStatusID: '3533',
    payerName: 'CONNECTICUT MEDICARE',
    claimType: 'Institutional',
    State: 'CT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3536',
    payerID: '10211',
    eligibilityID: 'CMSMED',
    claimStatusID: '3536',
    payerName: 'GEORGIA MEDICARE',
    claimType: 'Institutional',
    State: 'GA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3547',
    payerID: '14011',
    eligibilityID: 'CMSMED',
    claimStatusID: '3547',
    payerName: 'MAINE MEDICARE',
    claimType: 'Institutional',
    State: 'ME',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3563',
    payerID: '11501',
    eligibilityID: 'CMSMED',
    claimStatusID: '3563',
    payerName: 'NORTH CAROLINA MEDICARE',
    claimType: 'Institutional',
    State: 'NC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3579',
    payerID: '7201',
    eligibilityID: 'CMSMED',
    claimStatusID: '3579',
    payerName: 'LOUISIANA MEDICARE',
    claimType: 'Institutional',
    State: 'LA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3583',
    payerID: '3601',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'WYOMING MEDICARE',
    claimType: 'Institutional',
    State: 'WY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4442',
    payerID: '13202',
    eligibilityID: 'CMSMED',
    claimStatusID: '4442',
    payerName: 'NEW YORK EMPIRE MEDICARE',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4451',
    payerID: '5302',
    eligibilityID: 'CMSMED',
    claimStatusID: '4451',
    payerName: 'MISSOURI NORTHWEST MEDICARE - KANSAS CITY, MO',
    claimType: 'Professional',
    State: 'MO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '4487',
    payerID: '9302',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'VIRGIN ISLANDS MEDICARE',
    claimType: 'Professional',
    State: 'VI',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5502',
    payerID: '4411',
    eligibilityID: 'CMSMED',
    claimStatusID: '5502',
    payerName: 'TEXAS MEDICARE',
    claimType: 'Institutional',
    State: 'TX',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5503',
    payerID: '12401',
    eligibilityID: 'CMSMED',
    claimStatusID: '5503',
    payerName: 'NEW JERSEY MEDICARE',
    claimType: 'Institutional',
    State: 'NJ',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5506',
    payerID: '6101',
    eligibilityID: 'CMSMED',
    claimStatusID: '5506',
    payerName: 'ILLINOIS MEDICARE',
    claimType: 'Institutional',
    State: 'IL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5512',
    payerID: '6001',
    eligibilityID: 'CMSMED',
    claimStatusID: '5512',
    payerName: 'WISCONSIN MEDICARE',
    claimType: 'Institutional',
    State: 'WI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5515',
    payerID: '2001',
    eligibilityID: 'CMSMED',
    claimStatusID: '5515',
    payerName: 'OREGON MEDICARE',
    claimType: 'Institutional',
    State: 'OR',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5518',
    payerID: '5201',
    eligibilityID: 'CMSMED',
    claimStatusID: '5518',
    payerName: 'KANSAS MEDICARE',
    claimType: 'Institutional',
    State: 'KS',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5521',
    payerID: '2001',
    eligibilityID: 'CMSMED',
    claimStatusID: '5521',
    payerName: 'WASHINGTON AND ALASKA MEDICARE',
    claimType: 'Institutional',
    State: 'AK',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5521',
    payerID: '2001',
    eligibilityID: 'CMSMED',
    claimStatusID: '5521',
    payerName: 'WASHINGTON AND ALASKA MEDICARE',
    claimType: 'Institutional',
    State: 'WA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5525',
    payerID: '10311',
    eligibilityID: 'CMSMED',
    claimStatusID: '5525',
    payerName: 'TENNESSEE MEDICARE',
    claimType: 'Institutional',
    State: 'TN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5527',
    payerID: '14211',
    eligibilityID: 'CMSMED',
    claimStatusID: '5527',
    payerName: 'MASSACHUSETTS MEDICARE',
    claimType: 'Institutional',
    State: 'MA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5533',
    payerID: '15101',
    eligibilityID: 'CMSMED',
    claimStatusID: '5533',
    payerName: 'KENTUCKY MEDICARE',
    claimType: 'Institutional',
    State: 'KY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5536',
    payerID: '11003',
    eligibilityID: 'CMSMED',
    claimStatusID: '5536',
    payerName: 'VIRGINIA MEDICARE',
    claimType: 'Institutional',
    State: 'VA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5546',
    payerID: '3101',
    eligibilityID: 'CMSMED',
    claimStatusID: '5546',
    payerName: 'ARIZONA MEDICARE',
    claimType: 'Institutional',
    State: 'AZ',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5554',
    payerID: '12301',
    eligibilityID: 'CMSMED',
    claimStatusID: '5554',
    payerName: 'MARYLAND MEDICARE',
    claimType: 'Institutional',
    State: 'MD',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5556',
    payerID: '7301',
    eligibilityID: 'CMSMED',
    claimStatusID: '5556',
    payerName: 'MISSISSIPPI MEDICARE',
    claimType: 'Institutional',
    State: 'MS',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5559',
    payerID: '10111',
    eligibilityID: 'CMSMED',
    claimStatusID: '5559',
    payerName: 'ALABAMA MEDICARE',
    claimType: 'Institutional',
    State: 'AL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5566',
    payerID: '4211',
    eligibilityID: 'CMSMED',
    claimStatusID: '5566',
    payerName: 'NEW MEXICO MEDICARE',
    claimType: 'Institutional',
    State: 'NM',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5567',
    payerID: '1211',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'HAWAII MEDICARE',
    claimType: 'Institutional',
    State: 'HI',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5572',
    payerID: '5301',
    eligibilityID: 'CMSMED',
    claimStatusID: '5572',
    payerName: 'MISSOURI MEDICARE EASTERN',
    claimType: 'Institutional',
    State: 'MO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5578',
    payerID: '14411',
    eligibilityID: 'CMSMED',
    claimStatusID: '5578',
    payerName: 'RHODE ISLAND MEDICARE',
    claimType: 'Institutional',
    State: 'RI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5581',
    payerID: '2001',
    eligibilityID: 'CMSMED',
    claimStatusID: '5581',
    payerName: 'IDAHO MEDICARE',
    claimType: 'Institutional',
    State: 'ID',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5589',
    payerID: '3401',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'SOUTH DAKOTA MEDICARE',
    claimType: 'Institutional',
    State: 'SD',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5598',
    payerID: '12501',
    eligibilityID: 'CMSMED',
    claimStatusID: '5598',
    payerName: 'PENNSYLVANIA MEDICARE',
    claimType: 'Institutional',
    State: 'PA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7400',
    payerID: '3202',
    eligibilityID: 'CMSMED',
    claimStatusID: '7400',
    payerName: 'MONTANA MEDICARE',
    claimType: 'Professional',
    State: 'MT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7401',
    payerID: '13292',
    eligibilityID: 'CMSMED',
    claimStatusID: '7401',
    payerName: 'NEW YORK MEDICARE GHI',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7402',
    payerID: '12302',
    eligibilityID: 'CMSMED',
    claimStatusID: '7402',
    payerName: 'MARYLAND MEDICARE',
    claimType: 'Professional',
    State: 'MD',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7419',
    payerID: '14412',
    eligibilityID: 'CMSMED',
    claimStatusID: '7419',
    payerName: 'RHODE ISLAND MEDICARE',
    claimType: 'Professional',
    State: 'RI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7475',
    payerID: '16013',
    eligibilityID: 'CMSMED',
    claimStatusID: '7475',
    payerName: 'MEDICARE DME MAC JURISDICTION A',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7476',
    payerID: '17013',
    eligibilityID: 'CMSMED',
    claimStatusID: '7476',
    payerName: 'MEDICARE DME MAC JURISDICTION B',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7477',
    payerID: '18003',
    eligibilityID: 'CMSMED',
    claimStatusID: '7477',
    payerName: 'MEDICARE DME MAC JURISDICTION C',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7478',
    payerID: '19003',
    eligibilityID: 'CMSMED',
    claimStatusID: '7478',
    payerName: 'MEDICARE DME MAC JURISDICTION D',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MED',
    payerID: '',
    eligibilityID: 'CMSMED',
    claimStatusID: '',
    payerName: 'CMS MEDICARE ELIGIBILITY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'E',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes-Agreement',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2233',
    payerID: '75284',
    eligibilityID: 'CNSRR',
    claimStatusID: '',
    payerName: 'CONSOLIDATED ASSOCIATES RAILROAD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1045',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ARIZONA COMPLETE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1075',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL FROM WESTERN SKY COMMUNITY CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1076',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'WESTERN SKY COMMUNITY CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1164',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'NEBRASKA TOTAL CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1263',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'SILVER SUMMIT HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1562',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'MANAGED HEALTH SERVICES - INDIANA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1611',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'MAGNOLIA HEALTH PLAN - MISSISSIPPI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1921',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'PEACH STATE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1962',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'BUCKEYE COMMUNITY HEALTH - OHIO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2003',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ARKANSAS TOTAL CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2026',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'IOWA TOTAL CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2029',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ASCENSION COMPLETE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2111',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'SUNSHINE HEALTH ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2417',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'PEACH STATE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2508',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'NEBRASKA TOTAL CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2701',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'SUPERIOR HEALTH PLAN - TEXAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2702',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'MHS HEALTH WISCONSIN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2703',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'MANAGED HEALTH SERVICES - INDIANA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2855',
    payerID: '95567',
    eligibilityID: 'CNTENE',
    claimStatusID: 'HLTHNT',
    payerName: 'HEALTH NET of CALIFORNIA - CLAIMS',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3504',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'MHS HEALTH WISCONSIN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3522',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'SUPERIOR HEALTH PLAN - TEXAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4013',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER OF TENNESSEE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4052',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER FROM PENNSYLVANIA HEALTH AND WELLNESS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4272',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ABSOLUTE TOTAL CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4272',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ABSOLUTE TOTAL CARE',
    claimType: 'Professional',
    State: 'SC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4639',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'LOUISIANA HEALTH CONNECTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4652',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'HOMESTATE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4665',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'COORDINATED CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4717',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'BUCKEYE COMMUNITY HEALTH - OHIO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4793',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ADVANTAGE by BUCKEYE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5286',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'MAGNOLIA HEALTH PLAN - MISSISSIPPI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5542',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'SILVER SUMMIT HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5613',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'SUNFLOWER STATE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5628',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'NEW HAMPSHIRE HEALTHY FAMILIES',
    claimType: 'Institutional',
    State: 'NH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5947',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'SUNSHINE HEALTH ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6027',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'CAROLINA COMPLETE HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '6030',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER FROM MERIDIAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '6157',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'LOUISIANA HEALTH CONNECTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6196',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'HOMESTATE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6197',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'COORDINATED CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6249',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'SUNFLOWER STATE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6262',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'NEW HAMPSHIRE HEALTHY FAMILIES',
    claimType: 'Professional',
    State: 'NH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6514',
    payerID: '95567',
    eligibilityID: 'CNTENE',
    claimStatusID: 'HLTHNT',
    payerName: 'HEALTH NET of CALIFORNIA - CLAIMS',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6582',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'PA HEALTH and WELLNESS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6646',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'CALIFORNIA HEALTH and WELLNESS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6658',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER from MAGNOLIA HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6660',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER from BUCKEYE COMMUNITY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6661',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER from SUPERIOR HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6748',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'CALIFORNIA HEALTH and WELLNESS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6753',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER of ARKANSAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6755',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER from SUNSHINE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6756',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER from PEACH STATE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6757',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER from MANAGED HEALTH SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6759',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER from MAGNOLIA HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6761',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER from BUCKEYE COMMUNITY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6762',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER from SUPERIOR HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7286',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER ILLINICARE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7287',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER NEW HAMPSHIRE HEALTHY FAMILIES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7295',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'TRILLIUM COMMUNITY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7515',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'HEALTH NET of CA, OR',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7515',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'HEALTH NET of CA, OR',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7570',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from HEALTH NET',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7572',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from ARKANSAS HEALTH and WELLNESS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7578',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from SUNSHINE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7581',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from PEACH STATE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7582',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from MANAGED HEALTH SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7586',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from SUNFLOWER HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7588',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from LOUISIANA HEALTHCARE CONNECTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7589',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from MAGNOLIA HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7591',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from HOME STATE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7592',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from BUCKEYE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7740',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'PA HEALTH and WELLNESS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7751',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'HEALTH NET of CA, OR',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7766',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from HEALTH NET',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7767',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from ARKANSAS HEALTH and WELLNESS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7768',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from SUNSHINE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7769',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from PEACH STATE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7770',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from MANAGED HEALTH SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7772',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from SUNFLOWER HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7773',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from LOUISIANA HEALTHCARE CONNECTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7774',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from MAGNOLIA HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7775',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from HOME STATE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7776',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from BUCKEYE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7783',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from PA HEALTH and WELLNESS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7784',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from ABSOLUTE TOTAL CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7785',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from SUPERIOR HEALTHPLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7786',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from MANAGED HEALTH SERVICES HEALTH WI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7808',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER from HEALTH NET',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7809',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER from HOMESTATE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7874',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ARIZONA COMPLETE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8102',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL FROM WESTERN SKY COMMUNITY CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8103',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'WESTERN SKY COMMUNITY CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8136',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ARKANSAS TOTAL CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8162',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'IOWA TOTAL CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8299',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ASCENSION COMPLETE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8524',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from PA HEALTH and WELLNESS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8526',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from ABSOLUTE TOTAL CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8527',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from SUPERIOR HEALTHPLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8528',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ALLWELL from MANAGED HEALTH SERVICES HEALTH WI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8549',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ABSOLUTE TOTAL CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8549',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ABSOLUTE TOTAL CARE',
    claimType: 'Institutional',
    State: 'SC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8583',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER from HEALTH NET',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8584',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER from HOMESTATE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8741',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'AMBETTER OF TENNESSEE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8793',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER FROM PENNSYLVANIA HEALTH AND WELLNESS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9175',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'CAROLINA COMPLETE HEALTH CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9181',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER FROM MERIDIAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9564',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'ADVANTAGE BY BUCKEYE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9587',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER ILLINICARE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9588',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER NEW HAMPSHIRE HEALTHY FAMILIES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9617',
    payerID: '68069',
    eligibilityID: 'CNTENE',
    claimStatusID: '',
    payerName: 'TRILLIUM COMMUNITY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6652',
    payerID: '68069',
    eligibilityID: 'CNTENE ',
    claimStatusID: '',
    payerName: 'AMBETTER of ARKANSAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6654',
    payerID: '68069',
    eligibilityID: 'CNTENE ',
    claimStatusID: '',
    payerName: 'AMBETTER from SUNSHINE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6655',
    payerID: '68069',
    eligibilityID: 'CNTENE ',
    claimStatusID: 'CNTENE',
    payerName: 'AMBETTER from PEACH STATE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6656',
    payerID: '68069',
    eligibilityID: 'CNTENE ',
    claimStatusID: '',
    payerName: 'AMBETTER from MANAGED HEALTH SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1643',
    payerID: 'COACC',
    eligibilityID: 'COACCS',
    claimStatusID: '',
    payerName: 'COLORADO ACCESS',
    claimType: 'Institutional',
    State: 'CO',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2431',
    payerID: '84129',
    eligibilityID: 'COACCS',
    claimStatusID: '',
    payerName: 'COLORADO ACCESS',
    claimType: 'Professional',
    State: 'CO',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1415',
    payerID: '50',
    eligibilityID: 'COBCBS',
    claimStatusID: 'COBCBS',
    payerName: 'COLORADO BLUE SHIELD',
    claimType: 'Professional',
    State: 'CO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1549',
    payerID: '50',
    eligibilityID: 'COBCBS',
    claimStatusID: 'COBCBS',
    payerName: 'COLORADO BLUE CROSS',
    claimType: 'Institutional',
    State: 'CO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1496',
    payerID: '77016',
    eligibilityID: 'COCAID',
    claimStatusID: 'COCAID',
    payerName: 'HEALTH FIRST COLORADO fka COLORADO MEDICAID',
    claimType: 'Professional',
    State: 'CO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1548',
    payerID: '77016',
    eligibilityID: 'COCAID',
    claimStatusID: 'COCAID',
    payerName: 'HEALTH FIRST COLORADO fka COLORADO MEDICAID',
    claimType: 'Institutional',
    State: 'CO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1931',
    payerID: 'RH003',
    eligibilityID: 'COKAIS',
    claimStatusID: 'COKAIS',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of COLORADO',
    claimType: 'Institutional',
    State: 'CO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2439',
    payerID: 'RH003',
    eligibilityID: 'COKAIS',
    claimStatusID: 'COKAIS',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of COLORADO',
    claimType: 'Professional',
    State: 'CO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5036',
    payerID: '36479',
    eligibilityID: 'COLLH',
    claimStatusID: 'COLLH',
    payerName: 'COLLECTIVE HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8883',
    payerID: '36479',
    eligibilityID: 'COLLH',
    claimStatusID: 'COLLH',
    payerName: 'COLLECTIVE HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2538',
    payerID: '48145',
    eligibilityID: 'COMHC',
    claimStatusID: 'COMHC',
    payerName: 'COMMUNITY HEALTH CHOICE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6687',
    payerID: '60495',
    eligibilityID: 'COMHC',
    claimStatusID: 'COMHC',
    payerName: 'COMMUNITY HEALTH CHOICE TEXAS - HIM',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6791',
    payerID: '60495',
    eligibilityID: 'COMHC',
    claimStatusID: 'COMHC',
    payerName: 'COMMUNITY HEALTH CHOICE TEXAS - HIM',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7110',
    payerID: '953766170',
    eligibilityID: 'COMHGR',
    claimStatusID: '',
    payerName: 'COMMUNITY HEALTH GROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7668',
    payerID: '953766170',
    eligibilityID: 'COMHGR',
    claimStatusID: '',
    payerName: 'COMMUNITY HEALTH GROUP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3809',
    payerID: 'COMMF',
    eligibilityID: 'COMMF',
    claimStatusID: '',
    payerName: 'COMMUNITY FIRST HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8925',
    payerID: 'COMMF',
    eligibilityID: 'COMMF',
    claimStatusID: '',
    payerName: 'COMMUNITY FIRST HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4471',
    payerID: '78375',
    eligibilityID: 'CONNCR',
    claimStatusID: 'EMBLEM',
    payerName: 'CONNECTICARE VIP MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4969',
    payerID: '78375',
    eligibilityID: 'CONNCR',
    claimStatusID: 'EMBLEM',
    payerName: 'CONNECTICARE VIP MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6440',
    payerID: '6105',
    eligibilityID: 'CONNCR',
    claimStatusID: 'CONNCR',
    payerName: 'CONNECTICARE, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6500',
    payerID: '6105',
    eligibilityID: 'CONNCR',
    claimStatusID: 'CONNCR',
    payerName: 'CONNECTICARE, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4751',
    payerID: 'CCHP1',
    eligibilityID: 'COOKCH',
    claimStatusID: '',
    payerName: 'COOK CHILDRENS HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8924',
    payerID: 'CCHP1',
    eligibilityID: 'COOKCH',
    claimStatusID: '',
    payerName: 'COOK CHILDRENS HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4044',
    payerID: '60065',
    eligibilityID: 'COOKG',
    claimStatusID: '',
    payerName: 'COOK MEDICAL GROUP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8773',
    payerID: '60065',
    eligibilityID: 'COOKG',
    claimStatusID: '',
    payerName: 'COOK MEDICAL GROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7410',
    payerID: '128KY',
    eligibilityID: 'COVCKY',
    claimStatusID: 'COVCKY',
    payerName: 'AETNA BETTER HEALTH of KENTUCKY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9626',
    payerID: '128KY',
    eligibilityID: 'COVCKY',
    claimStatusID: 'COVCKY',
    payerName: 'AETNA BETTER HEALTH of KENTUCKY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1575',
    payerID: '58102',
    eligibilityID: 'COVENT',
    claimStatusID: '',
    payerName: 'COVENANT ADMINISTRATORS, INC. (ATLANTA, GA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3828',
    payerID: '58102',
    eligibilityID: 'COVENT',
    claimStatusID: '',
    payerName: 'COVENANT ADMINISTRATORS, INC. (ATLANTA, GA)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2482',
    payerID: '19',
    eligibilityID: 'COXHP',
    claimStatusID: '',
    payerName: 'COX HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3832',
    payerID: '56116',
    eligibilityID: 'CPBNSV',
    claimStatusID: '',
    payerName: 'CORPORATE BENEFITS SERVICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3939',
    payerID: '56116',
    eligibilityID: 'CPBNSV',
    claimStatusID: '',
    payerName: 'CORPORATE BENEFITS SERVICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1180',
    payerID: '37077',
    eligibilityID: 'CPLIC',
    claimStatusID: '',
    payerName: 'COLONIAL PENN LIFE INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2550',
    payerID: '37077',
    eligibilityID: 'CPLIC',
    claimStatusID: '',
    payerName: 'COLONIAL PENN LIFE INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1279',
    payerID: 'CREA8',
    eligibilityID: 'CREATE',
    claimStatusID: '',
    payerName: 'CREATE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6525',
    payerID: 'CREA8',
    eligibilityID: 'CREATE',
    claimStatusID: '',
    payerName: 'CREATE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2289',
    payerID: '14163',
    eligibilityID: 'CRFRAZ',
    claimStatusID: '',
    payerName: 'CARE 1ST HEALTH PLAN OF ARIZONA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2949',
    payerID: '14163',
    eligibilityID: 'CRFRAZ',
    claimStatusID: '',
    payerName: 'CARE 1ST HEALTH PLAN OF ARIZONA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3293',
    payerID: 'CARMO',
    eligibilityID: 'CRMRE',
    claimStatusID: '',
    payerName: 'CAREMORE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5635',
    payerID: 'CARMO',
    eligibilityID: 'CRMRE',
    claimStatusID: '',
    payerName: 'CAREMORE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1682',
    payerID: '95092',
    eligibilityID: 'CRPLUS',
    claimStatusID: '',
    payerName: 'CAREPLUS HEALTH PLANS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2830',
    payerID: '95092',
    eligibilityID: 'CRPLUS',
    claimStatusID: '',
    payerName: 'CAREPLUS HEALTH PLANS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2526',
    payerID: '31114',
    eligibilityID: 'CRSROH',
    claimStatusID: 'CRSROH',
    payerName: 'CARESOURCE of OHIO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2526',
    payerID: '31114',
    eligibilityID: 'CRSROH',
    claimStatusID: 'CRSROH',
    payerName: 'CARESOURCE of OHIO',
    claimType: 'Institutional',
    State: 'OH',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3826',
    payerID: '31114',
    eligibilityID: 'CRSROH',
    claimStatusID: 'CRSROH',
    payerName: 'CARESOURCE of OHIO',
    claimType: 'Professional',
    State: 'OH',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3826',
    payerID: '31114',
    eligibilityID: 'CRSROH',
    claimStatusID: 'CRSROH',
    payerName: 'CARESOURCE of OHIO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7593',
    payerID: '46430',
    eligibilityID: 'CRYSTL',
    claimStatusID: '',
    payerName: 'CRYSTAL RUN HEALTH PLANS ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7777',
    payerID: '46430',
    eligibilityID: 'CRYSTL',
    claimStatusID: '',
    payerName: 'CRYSTAL RUN HEALTH PLANS ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1773',
    payerID: '38225',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE DETROIT)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1983',
    payerID: '75136',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE LITTLE ROCK)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2587',
    payerID: '35182',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH (CORESOURCE AZ IL IN MD MN NC PA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3254',
    payerID: '35183',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE OH)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3791',
    payerID: '35182',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH (CORESOURCE AZ IL IN MD MN NC PA)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3806',
    payerID: '75136',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE LITTLE ROCK)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3983',
    payerID: '35187',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE INTERNAL)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4568',
    payerID: '48117',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE KC)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4771',
    payerID: '35187',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE INTERNAL)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4947',
    payerID: '35183',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE OH)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5492',
    payerID: '48117',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE KC)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8522',
    payerID: '38225',
    eligibilityID: 'CSFMH',
    claimStatusID: 'CSFMH',
    payerName: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE DETROIT)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6099',
    payerID: 'ARCS1',
    eligibilityID: 'CSPAR',
    claimStatusID: 'CSPAR',
    payerName: 'CARESOURCE PASSE OF ARKANSAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9226',
    payerID: 'ARCS1',
    eligibilityID: 'CSPAR',
    claimStatusID: 'CSPAR',
    payerName: 'CARESOURCE PASSE OF ARKANSAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '7143',
    payerID: 'INCS1',
    eligibilityID: 'CSRCIN',
    claimStatusID: 'CSRCIN',
    payerName: 'CARESOURCE of INDIANA',
    claimType: 'Professional',
    State: 'IN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7688',
    payerID: 'INCS1',
    eligibilityID: 'CSRCIN',
    claimStatusID: 'CSRCIN',
    payerName: 'CARESOURCE of INDIANA',
    claimType: 'Institutional',
    State: 'IN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5624',
    payerID: 'KYCS1',
    eligibilityID: 'CSRCKY',
    claimStatusID: 'CSRCKY',
    payerName: 'HUMANA - CARESOURCE of KENTUCKY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '6259',
    payerID: 'KYCS1',
    eligibilityID: 'CSRCKY',
    claimStatusID: 'CSRCKY',
    payerName: 'HUMANA - CARESOURCE of KENTUCKY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7263',
    payerID: 'WVCS1',
    eligibilityID: 'CSRCWV',
    claimStatusID: 'CSRCWV',
    payerName: 'CARESOURCE of WEST VIRGINIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '8676',
    payerID: 'WVCS1',
    eligibilityID: 'CSRCWV',
    claimStatusID: 'CSRCWV',
    payerName: 'CARESOURCE of WEST VIRGINIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '4507',
    payerID: '36215',
    eligibilityID: 'CSTFND',
    claimStatusID: '',
    payerName: 'CENTRAL STATES HEALTH and WELFARE FUNDS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6492',
    payerID: '36215',
    eligibilityID: 'CSTFND',
    claimStatusID: '',
    payerName: 'CENTRAL STATES HEALTH and WELFARE FUNDS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1420',
    payerID: '60',
    eligibilityID: 'CTBCBS',
    claimStatusID: 'CTBCBS',
    payerName: 'CONNECTICUT BLUE SHIELD',
    claimType: 'Professional',
    State: 'CT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3534',
    payerID: '60',
    eligibilityID: 'CTBCBS',
    claimStatusID: 'CTBCBS',
    payerName: 'CONNECTICUT BLUE CROSS',
    claimType: 'Institutional',
    State: 'CT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3535',
    payerID: '61274678',
    eligibilityID: 'CTCAID',
    claimStatusID: '3535',
    payerName: 'CONNECTICUT MEDICAID - INPATIENT/OUTPATIENT',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3535',
    payerID: '61274678',
    eligibilityID: 'CTCAID',
    claimStatusID: '3535',
    payerName: 'CONNECTICUT MEDICAID - INPATIENT/OUTPATIENT',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4476',
    payerID: '61274678',
    eligibilityID: 'CTCAID',
    claimStatusID: '4476',
    payerName: 'CONNECTICUT MEDICAID',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4476',
    payerID: '61274678',
    eligibilityID: 'CTCAID',
    claimStatusID: '4476',
    payerName: 'CONNECTICUT MEDICAID',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'CTLS',
    payerID: '',
    eligibilityID: 'CTLSTI',
    claimStatusID: '',
    payerName: 'CENTRAL STATES INDEMNITY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6088',
    payerID: '86220',
    eligibilityID: 'CTMASS',
    claimStatusID: 'CTMASS',
    payerName: 'MASS ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9262',
    payerID: '86220',
    eligibilityID: 'CTMASS',
    claimStatusID: 'CTMASS',
    payerName: 'MASS ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '7738',
    payerID: '6541',
    eligibilityID: 'CTYCHP',
    claimStatusID: 'CTYCHP',
    payerName: 'COUNTYCARE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9688',
    payerID: '6541',
    eligibilityID: 'CTYCHP',
    claimStatusID: 'CTYCHP',
    payerName: 'COUNTYCARE HEALTH PLAN ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'CVHL',
    payerID: '',
    eligibilityID: 'CVHLOK',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH and LIFE OKLAHOMA',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'CVHLTN',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'CVHLTN',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6693',
    payerID: '88056',
    eligibilityID: 'CYPRSS',
    claimStatusID: '',
    payerName: 'LUCENT HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6797',
    payerID: '88056',
    eligibilityID: 'CYPRSS',
    claimStatusID: '',
    payerName: 'LUCENT HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1984',
    payerID: 'DAKOTACARE',
    eligibilityID: 'DAKOTA',
    claimStatusID: '',
    payerName: 'DAKOTACARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2492',
    payerID: 'DAKOTACARE',
    eligibilityID: 'DAKOTA',
    claimStatusID: '',
    payerName: 'DAKOTACARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1509',
    payerID: '80',
    eligibilityID: 'DCBCBS',
    claimStatusID: 'DCBCBS',
    payerName: 'BLUE CROSS OF NATIONAL CAPITAL AREA - CAREFIRST',
    claimType: 'Institutional',
    State: 'DC',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2425',
    payerID: '580',
    eligibilityID: 'DCBCBS',
    claimStatusID: 'DCBCBS',
    payerName: 'BLUE SHIELD OF NATIONAL CAPITOL AREA - CAREFIRST',
    claimType: 'Professional',
    State: 'DC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4425',
    payerID: '77033',
    eligibilityID: 'DCCAID',
    claimStatusID: '4425',
    payerName: 'DISTRICT OF COLUMBIA MEDICAID',
    claimType: 'Professional',
    State: 'DC',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5550',
    payerID: '77033',
    eligibilityID: 'DCCAID',
    claimStatusID: '5550',
    payerName: 'DISTRICT OF COLUMBIA MEDICAID',
    claimType: 'Institutional',
    State: 'DC',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '4076',
    payerID: 'DCMED',
    eligibilityID: 'DCMED',
    claimStatusID: '',
    payerName: 'MEDSTAR DC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8819',
    payerID: 'DCMED',
    eligibilityID: 'DCMED',
    claimStatusID: '',
    payerName: 'MEDSTAR DC',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1497',
    payerID: '39113',
    eligibilityID: 'DEANHP',
    claimStatusID: 'DEANHP',
    payerName: 'DEAN HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3581',
    payerID: '39113',
    eligibilityID: 'DEANHP',
    claimStatusID: 'DEANHP',
    payerName: 'DEAN HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1546',
    payerID: '70',
    eligibilityID: 'DEBCBS',
    claimStatusID: 'DEBCSI',
    payerName: 'DELAWARE BLUE CROSS - HIGHMARK',
    claimType: 'Institutional',
    State: 'DE',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7456',
    payerID: '570',
    eligibilityID: 'DEBCBS',
    claimStatusID: 'DEBCSP',
    payerName: 'DELAWARE BLUE SHIELD - HIGHMARK',
    claimType: 'Professional',
    State: 'DE',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1545',
    payerID: '75-2548221',
    eligibilityID: 'DECAID',
    claimStatusID: '1545',
    payerName: 'DELAWARE MEDICAID',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2726',
    payerID: '345724166',
    eligibilityID: 'DECAID',
    claimStatusID: '2726',
    payerName: 'DELAWARE MEDICAID',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'DECHC',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'DECHC',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1175',
    payerID: 'DCHPMCAID',
    eligibilityID: 'DELLC',
    claimStatusID: '',
    payerName: "DELL CHILDREN'S HEALTH PLAN",
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2539',
    payerID: 'DCHPMCAID',
    eligibilityID: 'DELLC',
    claimStatusID: '',
    payerName: "DELL CHILDREN'S HEALTH PLAN",
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2061',
    payerID: 'DEVOT',
    eligibilityID: 'DEVOT',
    claimStatusID: '',
    payerName: 'DEVOTED HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8196',
    payerID: 'DEVOT',
    eligibilityID: 'DEVOT',
    claimStatusID: '',
    payerName: 'DEVOTED HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'DIAM',
    payerID: '',
    eligibilityID: 'DIAMPL',
    claimStatusID: '',
    payerName: 'DIAMOND PLAN (MARYLAND MEDICAID)',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4728',
    payerID: 'HT000006-001',
    eligibilityID: 'DMBA',
    claimStatusID: '',
    payerName: 'DESERET MUTUAL BENEFIT ASSOCIATES (DMBA)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8995',
    payerID: '12X35',
    eligibilityID: 'DMBA',
    claimStatusID: '',
    payerName: 'DESERET MUTUAL BENEFIT ASSOCIATES (DMBA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3936',
    payerID: '84135',
    eligibilityID: 'DNVHMP',
    claimStatusID: '',
    payerName: 'DENVER HEALTH MEDICAL PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4285',
    payerID: '84135',
    eligibilityID: 'DNVHMP',
    claimStatusID: '',
    payerName: 'DENVER HEALTH MEDICAL PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3234',
    payerID: '74284',
    eligibilityID: 'DRSCHP',
    claimStatusID: '',
    payerName: 'DRISCOLL CHILDRENS HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3925',
    payerID: '74284',
    eligibilityID: 'DRSCHP',
    claimStatusID: '',
    payerName: 'DRISCOLL CHILDRENS HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3934',
    payerID: '6102',
    eligibilityID: 'DVRGB',
    claimStatusID: '',
    payerName: 'DIVERSIFIED GROUP BROKERAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6481',
    payerID: '6102',
    eligibilityID: 'DVRGB',
    claimStatusID: '',
    payerName: 'DIVERSIFIED GROUP BROKERAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2947',
    payerID: '81039',
    eligibilityID: 'EBMS',
    claimStatusID: 'EBMS',
    payerName: 'EMPLOYEE BENEFIT MANAGEMENT SERV (EBMS)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5483',
    payerID: '81039',
    eligibilityID: 'EBMS',
    claimStatusID: 'EBMS',
    payerName: 'EMPLOYEE BENEFIT MANAGEMENT SERV (EBMS)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2059',
    payerID: '37257',
    eligibilityID: 'EBSO',
    claimStatusID: '',
    payerName: 'EBSO, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4414',
    payerID: '37257',
    eligibilityID: 'EBSO',
    claimStatusID: '',
    payerName: 'EBSO, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1853',
    payerID: '31625',
    eligibilityID: 'ELDER',
    claimStatusID: '',
    payerName: 'ELDERPLAN, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1999',
    payerID: '31625',
    eligibilityID: 'ELDER',
    claimStatusID: '',
    payerName: 'ELDERPLAN, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1978',
    payerID: '52192',
    eligibilityID: 'ELDRHL',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA HEALTHSPRING MARYLAND/PENNSYLNAVIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3839',
    payerID: '52192',
    eligibilityID: 'ELDRHL',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA HEALTHSPRING MARYLAND/PENNSYLNAVIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4289',
    payerID: 'EPF03',
    eligibilityID: 'ELPASO',
    claimStatusID: '',
    payerName: 'EL PASO HEALTH-CHIP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8923',
    payerID: 'EPF03',
    eligibilityID: 'ELPASO',
    claimStatusID: '',
    payerName: 'EL PASO HEALTH-CHIP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1578',
    payerID: 'U5531',
    eligibilityID: 'EMBLEM',
    claimStatusID: 'EMBLEM',
    payerName: 'EMBLEMHEALTH PLAN INC HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1784',
    payerID: '25531',
    eligibilityID: 'EMBLEM',
    claimStatusID: 'EMBLEM',
    payerName: 'EMBLEMHEALTH PLAN INC HMO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1896',
    payerID: '55247',
    eligibilityID: 'EMBLEM',
    claimStatusID: 'EMBLEM',
    payerName: 'EMBLEMHEALTH INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2744',
    payerID: '13551',
    eligibilityID: 'EMBLEM',
    claimStatusID: 'EMBLEM',
    payerName: 'EMBLEMHEALTH PLAN INC MCR',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6509',
    payerID: 'A3551',
    eligibilityID: 'EMBLEM',
    claimStatusID: 'EMBLEM',
    payerName: 'EMBLEMHEALTH PLAN INC MCR',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6562',
    payerID: '22264',
    eligibilityID: 'EMBLEM',
    claimStatusID: 'EMBLEM',
    payerName: 'VYTRA HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8440',
    payerID: '22264',
    eligibilityID: 'EMBLEM',
    claimStatusID: 'EMBLEM',
    payerName: 'VYTRA HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8599',
    payerID: 'E5247',
    eligibilityID: 'EMBLEM',
    claimStatusID: 'EMBLEM',
    payerName: 'EMBLEMHEALTH INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3520',
    payerID: '303',
    eligibilityID: 'EMHECH',
    claimStatusID: 'EMHECH',
    payerName: 'NEW YORK EMPIRE BLUE CROSS',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3520',
    payerID: '303',
    eligibilityID: 'EMHECH',
    claimStatusID: 'EMHECH',
    payerName: 'NEW YORK EMPIRE BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4437',
    payerID: '803',
    eligibilityID: 'EMHECH',
    claimStatusID: 'EMHECH',
    payerName: 'NEW YORK EMPIRE BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4437',
    payerID: '803',
    eligibilityID: 'EMHECH',
    claimStatusID: 'EMHECH',
    payerName: 'NEW YORK EMPIRE BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4437',
    payerID: '803',
    eligibilityID: 'EMHECH',
    claimStatusID: 'EMHECH',
    payerName: 'NEW YORK EMPIRE BLUE SHIELD',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6584',
    payerID: '16565',
    eligibilityID: 'EMHSP',
    claimStatusID: '',
    payerName: 'EMHS EMPLOYEE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7741',
    payerID: '16565',
    eligibilityID: 'EMHSP',
    claimStatusID: '',
    payerName: 'EMHS EMPLOYEE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4410',
    payerID: 'HT000214-001',
    eligibilityID: 'EMINS',
    claimStatusID: '',
    payerName: 'EMI HEALTH ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8654',
    payerID: 'HT000214-001',
    eligibilityID: 'EMINS',
    claimStatusID: '',
    payerName: 'EMI HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2005',
    payerID: '12956',
    eligibilityID: 'EMPOW',
    claimStatusID: 'EMPOW',
    payerName: 'EMPOWER HEALTHCARE SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8137',
    payerID: '12956',
    eligibilityID: 'EMPOW',
    claimStatusID: 'EMPOW',
    payerName: 'EMPOWER HEALTHCARE SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'ENHD',
    payerID: '',
    eligibilityID: 'ENHDC',
    claimStatusID: '',
    payerName: 'ENHANCEDCAREMD',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5469',
    payerID: '23250',
    eligibilityID: 'ERINGP',
    claimStatusID: 'ERINGP',
    payerName: 'SIGNIFICA BENEFIT SERVICES, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7532',
    payerID: '23250',
    eligibilityID: 'ERINGP',
    claimStatusID: 'ERINGP',
    payerName: 'SIGNIFICA BENEFIT SERVICES, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2274',
    payerID: '20818',
    eligibilityID: 'ESSNCE',
    claimStatusID: '',
    payerName: 'ESSENCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8993',
    payerID: '20818',
    eligibilityID: 'ESSNCE',
    claimStatusID: '',
    payerName: 'ESSENCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1576',
    payerID: '22254',
    eligibilityID: 'FALLON',
    claimStatusID: 'FALLON',
    payerName: 'FALLON COMMUNITY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3801',
    payerID: '22254',
    eligibilityID: 'FALLON',
    claimStatusID: 'FALLON',
    payerName: 'FALLON COMMUNITY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1876',
    payerID: '39167',
    eligibilityID: 'FAMHS',
    claimStatusID: '',
    payerName: 'GROUP HEALTH COOPERATIVE SOUTH CENTRAL WISCONSIN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4077',
    payerID: 'FCC01',
    eligibilityID: 'FCACAR',
    claimStatusID: '',
    payerName: 'FIRSTCAROLINACARE - NEW',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8821',
    payerID: 'FCC01',
    eligibilityID: 'FCACAR',
    claimStatusID: '',
    payerName: 'FIRSTCAROLINACARE - NEW',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1804',
    payerID: '56196',
    eligibilityID: 'FCCARE',
    claimStatusID: '',
    payerName: 'FIRSTCAROLINACARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8510',
    payerID: '56196',
    eligibilityID: 'FCCARE',
    claimStatusID: '',
    payerName: 'FIRSTCAROLINACARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6087',
    payerID: '32456',
    eligibilityID: 'FCVPC',
    claimStatusID: '',
    payerName: 'FIRST CHOICE VIP CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9248',
    payerID: '32456',
    eligibilityID: 'FCVPC',
    claimStatusID: '',
    payerName: 'FIRST CHOICE VIP CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '7193',
    payerID: '77009',
    eligibilityID: 'FCVSC',
    claimStatusID: 'FCVSC',
    payerName: 'FIRST CHOICE VIP CARE PLUS BY SELECT HEALTH OF SC',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8631',
    payerID: '77009',
    eligibilityID: 'FCVSC',
    claimStatusID: 'FCVSC',
    payerName: 'FIRST CHOICE VIP CARE PLUS BY SELECT HEALTH OF SC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3792',
    payerID: '11315',
    eligibilityID: 'FDCRNY',
    claimStatusID: '',
    payerName: 'FIDELIS CARE',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3792',
    payerID: '11315',
    eligibilityID: 'FDCRNY',
    claimStatusID: '',
    payerName: 'FIDELIS CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6536',
    payerID: '11315',
    eligibilityID: 'FDCRNY',
    claimStatusID: '',
    payerName: 'FIDELIS CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6536',
    payerID: '11315',
    eligibilityID: 'FDCRNY',
    claimStatusID: '',
    payerName: 'FIDELIS CARE',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1414',
    payerID: '590',
    eligibilityID: 'FLBCBS',
    claimStatusID: '1414',
    payerName: 'FLORIDA BLUE SHIELD',
    claimType: 'Professional',
    State: 'FL',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3517',
    payerID: '590',
    eligibilityID: 'FLBCBS',
    claimStatusID: '3517',
    payerName: 'FLORIDA BLUE CROSS',
    claimType: 'Institutional',
    State: 'FL',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1478',
    payerID: '77027',
    eligibilityID: 'FLCAID',
    claimStatusID: 'FLCAID',
    payerName: 'FLORIDA MEDICAID',
    claimType: 'Professional',
    State: 'FL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3516',
    payerID: '77027',
    eligibilityID: 'FLCAID',
    claimStatusID: 'FLCAID',
    payerName: 'FLORIDA MEDICAID',
    claimType: 'Institutional',
    State: 'FL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2150',
    payerID: '59322',
    eligibilityID: 'FLHLTH',
    claimStatusID: 'FLHLTH',
    payerName: 'FLORIDA HEALTH CARE PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3693',
    payerID: '59322',
    eligibilityID: 'FLHLTH',
    claimStatusID: 'FLHLTH',
    payerName: 'FLORIDA HEALTH CARE PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4879',
    payerID: '51062',
    eligibilityID: 'FLMLNA',
    claimStatusID: 'FLMLNA',
    payerName: 'MOLINA HEALTHCARE of FLORIDA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8913',
    payerID: '51062',
    eligibilityID: 'FLMLNA',
    claimStatusID: 'FLMLNA',
    payerName: 'MOLINA HEALTHCARE of FLORIDA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1151',
    payerID: '62045',
    eligibilityID: 'FRMBHP',
    claimStatusID: '',
    payerName: 'FARM BUREAU HEALTH PLANS',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3096',
    payerID: '62045',
    eligibilityID: 'FRMBHP',
    claimStatusID: '',
    payerName: 'FARM BUREAU HEALTH PLANS',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7462',
    payerID: '43197',
    eligibilityID: 'FRSNHP',
    claimStatusID: '',
    payerName: 'FRESENIUS TOTAL HEALTH (PPO SNP)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9633',
    payerID: '43197',
    eligibilityID: 'FRSNHP',
    claimStatusID: '',
    payerName: 'FRESENIUS TOTAL HEALTH (PPO SNP)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6453',
    payerID: '91131',
    eligibilityID: 'FRSTC',
    claimStatusID: '',
    payerName: 'FIRST CHOICE HEALTH NETWORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6554',
    payerID: '91131',
    eligibilityID: 'FRSTC',
    claimStatusID: '',
    payerName: 'FIRST CHOICE HEALTH NETWORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1995',
    payerID: '94999',
    eligibilityID: 'FRTCR',
    claimStatusID: '',
    payerName: 'FIRSTCARE HEALTH PLANS',
    claimType: 'Institutional',
    State: 'TX',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7435',
    payerID: '94999',
    eligibilityID: 'FRTCR',
    claimStatusID: '',
    payerName: 'FIRSTCARE HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7435',
    payerID: '94999',
    eligibilityID: 'FRTCR',
    claimStatusID: '',
    payerName: 'FIRSTCARE HEALTH PLANS',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'FSTR',
    payerID: '',
    eligibilityID: 'FSTREL',
    claimStatusID: '',
    payerName: 'FIRST RELIANCE STANDARD LIFE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'FUNA',
    payerID: '',
    eligibilityID: 'FUNAI',
    claimStatusID: '',
    payerName: 'FIRST UNITED AMERICAN INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '6853',
    payerID: '27048',
    eligibilityID: 'FUSION',
    claimStatusID: '',
    payerName: 'PAYER FUSION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7636',
    payerID: '27048',
    eligibilityID: 'FUSION',
    claimStatusID: '',
    payerName: 'PAYER FUSION',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1407',
    payerID: '601',
    eligibilityID: 'GABCBS',
    claimStatusID: 'GABCBS',
    payerName: 'GEORGIA BLUE SHIELD',
    claimType: 'Professional',
    State: 'GA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3537',
    payerID: '101',
    eligibilityID: 'GABCBS',
    claimStatusID: 'GABCBS',
    payerName: 'GEORGIA BLUE CROSS',
    claimType: 'Institutional',
    State: 'GA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1494',
    payerID: '77034',
    eligibilityID: 'GACAID',
    claimStatusID: '1494',
    payerName: 'GEORGIA MEDICAID',
    claimType: 'Professional',
    State: 'GA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1499',
    payerID: '77034',
    eligibilityID: 'GACAID',
    claimStatusID: '',
    payerName: 'GEORGIA MEDICAID ANESTHESIA',
    claimType: 'Professional',
    State: 'GA',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3538',
    payerID: '77034',
    eligibilityID: 'GACAID',
    claimStatusID: '3538',
    payerName: 'GEORGIA MEDICAID',
    claimType: 'Institutional',
    State: 'GA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'GACHC',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'GACHC',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1846',
    payerID: 'RH008',
    eligibilityID: 'GAKAIS',
    claimStatusID: '',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of GEORGIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8546',
    payerID: 'RH008',
    eligibilityID: 'GAKAIS',
    claimStatusID: '',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of GEORGIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1579',
    payerID: '44054',
    eligibilityID: 'GEHA',
    claimStatusID: 'GEHA',
    payerName: 'GOVERNMENT EMPLOYEE HEALTH ASSOCIATION (GEHA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1579',
    payerID: '44054',
    eligibilityID: 'GEHA',
    claimStatusID: 'GEHA',
    payerName: 'GOVERNMENT EMPLOYEE HEALTH ASSOCIATION (GEHA)',
    claimType: 'Institutional',
    State: 'TX',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6467',
    payerID: '44054',
    eligibilityID: 'GEHA',
    claimStatusID: 'GEHA',
    payerName: 'GOVERNMENT EMPLOYEE HEALTH ASSOCIATION (GEHA)',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2559',
    payerID: '75273',
    eligibilityID: 'GEISHP',
    claimStatusID: '',
    payerName: 'GEISINGER HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5473',
    payerID: '75273',
    eligibilityID: 'GEISHP',
    claimStatusID: '',
    payerName: 'GEISINGER HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'GHPG',
    payerID: '106125',
    eligibilityID: 'GHPG',
    claimStatusID: '',
    payerName: 'GEISINGER HEALTH PLAN GOLD',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7148',
    payerID: '47181',
    eligibilityID: 'GHPHHO',
    claimStatusID: 'GHPHHO',
    payerName: 'HIGHMARK BCBS DELAWARE HEALTH OPTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7693',
    payerID: '47181',
    eligibilityID: 'GHPHHO',
    claimStatusID: 'GHPHHO',
    payerName: 'HIGHMARK BCBS DELAWARE HEALTH OPTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4569',
    payerID: '25169',
    eligibilityID: 'GHPMCD',
    claimStatusID: 'GHPMCD',
    payerName: 'GATEWAY HEALTH PLAN - MEDICAID PENNSYLVANIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8472',
    payerID: '25169',
    eligibilityID: 'GHPMCD',
    claimStatusID: 'GHPMCD',
    payerName: 'GATEWAY HEALTH PLAN - MEDICAID PENNSYLVANIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2298',
    payerID: '60550',
    eligibilityID: 'GHPMCR',
    claimStatusID: 'GHPMCR',
    payerName: 'GATEWAY HEALTH PLAN - MEDICARE ASSURED PENNSYLVANI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2912',
    payerID: '60550',
    eligibilityID: 'GHPMCR',
    claimStatusID: 'GHPMCR',
    payerName: 'GATEWAY HEALTH PLAN - MEDICARE ASSURED PENNSYLVANI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4515',
    payerID: '37602',
    eligibilityID: 'GLDRUL',
    claimStatusID: 'GLDRUL',
    payerName: 'GOLDEN RULE INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6483',
    payerID: '37602',
    eligibilityID: 'GLDRUL',
    claimStatusID: 'GLDRUL',
    payerName: 'GOLDEN RULE INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4064',
    payerID: '91472',
    eligibilityID: 'GLOBE',
    claimStatusID: '',
    payerName: 'GLOBE LIFE & ACCIDENT INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4607',
    payerID: 'MCS03',
    eligibilityID: 'GMCARE',
    claimStatusID: '',
    payerName: 'GEMCARE - KERN COUNTY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6100',
    payerID: 'MCS03',
    eligibilityID: 'GMCARE',
    claimStatusID: '',
    payerName: 'GEMCARE - KERN COUNTY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4159',
    payerID: '23641',
    eligibilityID: 'GMPER',
    claimStatusID: '',
    payerName: 'GMP EMPLOYERS RETIREE TRUST',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2936',
    payerID: '48143',
    eligibilityID: 'GPADM',
    claimStatusID: '',
    payerName: 'GROUP and PENSION ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3757',
    payerID: '48143',
    eligibilityID: 'GPADM',
    claimStatusID: '',
    payerName: 'GROUP and PENSION ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'GRPA',
    payerID: '68046',
    eligibilityID: 'GRPAFL',
    claimStatusID: '',
    payerName: 'GROUP PRACTICE AFFILIATES',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6511',
    payerID: '91051',
    eligibilityID: 'GRPNW',
    claimStatusID: '',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of WASHINGTON',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6511',
    payerID: '91051',
    eligibilityID: 'GRPNW',
    claimStatusID: '',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of WASHINGTON',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8405',
    payerID: '91051',
    eligibilityID: 'GRPNW',
    claimStatusID: '',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of WASHINGTON',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3833',
    payerID: '',
    eligibilityID: 'GUNDER',
    claimStatusID: '',
    payerName: 'GUNDERSEN LUTHERAN HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: '',
    Remit: '',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5948',
    payerID: '',
    eligibilityID: 'GUNDER',
    claimStatusID: '',
    payerName: 'GUNDERSEN LUTHERAN HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: '',
    Remit: '',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4516',
    payerID: '62308',
    eligibilityID: 'GWSTHC',
    claimStatusID: '',
    payerName: 'CIGNA SELECT (formerly GREAT WEST HEALTHCARE)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6408',
    payerID: '62308',
    eligibilityID: 'GWSTHC',
    claimStatusID: '',
    payerName: 'CIGNA SELECT (formerly GREAT WEST HEALTHCARE)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6466',
    payerID: '382242827',
    eligibilityID: 'HAPMI',
    claimStatusID: '',
    payerName: 'HEALTH ALLIANCE PLAN of MICHIGAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6513',
    payerID: '382242827',
    eligibilityID: 'HAPMI',
    claimStatusID: '',
    payerName: 'HEALTH ALLIANCE PLAN of MICHIGAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4021',
    payerID: '551',
    eligibilityID: 'HBDALA',
    claimStatusID: 'HBDALA',
    payerName: 'HEALTHY BLUE DUAL ADVANTAGE LOUISIANA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8750',
    payerID: '551',
    eligibilityID: 'HBDALA',
    claimStatusID: 'HBDALA',
    payerName: 'HEALTHY BLUE DUAL ADVANTAGE LOUISIANA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5019',
    payerID: '541',
    eligibilityID: 'HBLUMO',
    claimStatusID: 'HBLUMO',
    payerName: 'HEALTHY BLUE MISSOURI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8867',
    payerID: '541',
    eligibilityID: 'HBLUMO',
    claimStatusID: 'HBLUMO',
    payerName: 'HEALTHY BLUE MISSOURI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5086',
    payerID: '602',
    eligibilityID: 'HBLUNC',
    claimStatusID: 'HBLUNC',
    payerName: 'HEALTHY BLUE NORTH CAROLINA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9133',
    payerID: '602',
    eligibilityID: 'HBLUNC',
    claimStatusID: 'HBLUNC',
    payerName: 'HEALTHY BLUE NORTH CAROLINA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '5022',
    payerID: '544',
    eligibilityID: 'HBLUNE',
    claimStatusID: 'HBLUNE',
    payerName: 'HEALTHY BLUE NEBRASKA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8870',
    payerID: '544',
    eligibilityID: 'HBLUNE',
    claimStatusID: 'HBLUNE',
    payerName: 'HEALTHY BLUE NEBRASKA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4878',
    payerID: '62180',
    eligibilityID: 'HCHGN',
    claimStatusID: '',
    payerName: 'HEALTH CHOICE PATHWAY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8985',
    payerID: '62180',
    eligibilityID: 'HCHGN',
    claimStatusID: '',
    payerName: 'HEALTH CHOICE PATHWAY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'HCINC',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'HCIN',
    payerID: '',
    eligibilityID: 'HCINC',
    claimStatusID: '',
    payerName: 'HEALTHCARE INC.',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3805',
    payerID: '62111',
    eligibilityID: 'HCOSTS',
    claimStatusID: '',
    payerName: 'HEALTH COST SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3917',
    payerID: '62111',
    eligibilityID: 'HCOSTS',
    claimStatusID: '',
    payerName: 'HEALTH COST SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'HFST',
    payerID: '',
    eligibilityID: 'HFSTNJ',
    claimStatusID: '',
    payerName: 'HEALTHFIRST of NEW JERSEY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1757',
    payerID: '56144',
    eligibilityID: 'HGRAM',
    claimStatusID: '',
    payerName: 'HEALTHGRAM',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6595',
    payerID: '56144',
    eligibilityID: 'HGRAM',
    claimStatusID: '',
    payerName: 'HEALTHGRAM',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7812',
    payerID: '86602',
    eligibilityID: 'HHZOE',
    claimStatusID: '',
    payerName: 'OPERATING ENGINEERS LOCAL 428',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8595',
    payerID: '86602',
    eligibilityID: 'HHZOE',
    claimStatusID: '',
    payerName: 'OPERATING ENGINEERS LOCAL 428',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2579',
    payerID: '996001089',
    eligibilityID: 'HICAID',
    claimStatusID: '',
    payerName: 'HAWAII MEDICAID',
    claimType: 'Institutional',
    State: 'HI',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2715',
    payerID: '996001089',
    eligibilityID: 'HICAID',
    claimStatusID: '',
    payerName: 'HAWAII MEDICAID',
    claimType: 'Professional',
    State: 'HI',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5925',
    payerID: 'RH0011',
    eligibilityID: 'HIKAIS',
    claimStatusID: '',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of HAWAII',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6772',
    payerID: 'RH0011',
    eligibilityID: 'HIKAIS',
    claimStatusID: '',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of HAWAII',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2934',
    payerID: '85729',
    eligibilityID: 'HLCOMP',
    claimStatusID: '',
    payerName: 'HEALTHCOMP, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3206',
    payerID: '85729',
    eligibilityID: 'HLCOMP',
    claimStatusID: '',
    payerName: 'HEALTHCOMP INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3205',
    payerID: '95019',
    eligibilityID: 'HLFRT',
    claimStatusID: '',
    payerName: 'HEALTH FIRST HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6685',
    payerID: '95019',
    eligibilityID: 'HLFRT',
    claimStatusID: '',
    payerName: 'HEALTH FIRST HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1889',
    payerID: '44273',
    eligibilityID: 'HLPLNS',
    claimStatusID: '',
    payerName: 'HEALTH PLANS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1909',
    payerID: '44273',
    eligibilityID: 'HLPLNS',
    claimStatusID: '',
    payerName: 'HEALTH PLANS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1525',
    payerID: 'HEALTHPARTNERS',
    eligibilityID: 'HLPTMN',
    claimStatusID: '',
    payerName: 'HEALTHPARTNERS of MINNESOTA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3411',
    payerID: 'HEALTHPARTNERS',
    eligibilityID: 'HLPTMN',
    claimStatusID: '',
    payerName: 'HEALTHPARTNERS of MINNESOTA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2494',
    payerID: '77950',
    eligibilityID: 'HLTAIL',
    claimStatusID: '',
    payerName: 'HEALTH ALLIANCE MEDICAL PLAN of ILLINOIS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2509',
    payerID: '77950',
    eligibilityID: 'HLTAIL',
    claimStatusID: '',
    payerName: 'HEALTH ALLIANCE MEDICAL PLAN of ILLINOIS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4046',
    payerID: '74853',
    eligibilityID: 'HLTBR',
    claimStatusID: '',
    payerName: 'HEALTHBRIDGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8775',
    payerID: '74853',
    eligibilityID: 'HLTBR',
    claimStatusID: '',
    payerName: 'HEALTHBRIDGE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7511',
    payerID: '71064',
    eligibilityID: 'HLTCH',
    claimStatusID: '',
    payerName: 'HEALTHCHOICE OKLAHOMA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7513',
    payerID: '71065',
    eligibilityID: 'HLTCH',
    claimStatusID: '',
    payerName: 'OKLAHOMA DEPARTMENT of CORRECTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7748',
    payerID: '71064',
    eligibilityID: 'HLTCH',
    claimStatusID: '',
    payerName: 'HEALTHCHOICE OKLAHOMA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7750',
    payerID: '71065',
    eligibilityID: 'HLTCH',
    claimStatusID: '',
    payerName: 'OKLAHOMA DEPARTMENT of CORRECTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2898',
    payerID: '34158',
    eligibilityID: 'HLTDS1',
    claimStatusID: '',
    payerName: 'CONTIGO HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7552',
    payerID: '34158',
    eligibilityID: 'HLTDS1',
    claimStatusID: '',
    payerName: 'CONTIGO HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2408',
    payerID: '20356',
    eligibilityID: 'HLTHEX',
    claimStatusID: '',
    payerName: 'HEALTH EXCHANGE (THE) (CERNER CORPORATION)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2964',
    payerID: '20356',
    eligibilityID: 'HLTHEX',
    claimStatusID: '',
    payerName: 'HEALTH EXCHANGE (THE) (CERNER CORPORATION)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3628',
    payerID: '4286',
    eligibilityID: 'HLTNE',
    claimStatusID: '',
    payerName: 'HEALTH NEW ENGLAND',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6432',
    payerID: '4286',
    eligibilityID: 'HLTNE',
    claimStatusID: '',
    payerName: 'HEALTH NEW ENGLAND',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7485',
    payerID: '55204',
    eligibilityID: 'HLTNOW',
    claimStatusID: '',
    payerName: 'HEALTHNOW NEW YORK BLUE SHIELD',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2824',
    payerID: '95677',
    eligibilityID: 'HLTPLN',
    claimStatusID: 'HLTPLN',
    payerName: 'THE HEALTH PLAN OF WEST VIRGINIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7551',
    payerID: '95677',
    eligibilityID: 'HLTPLN',
    claimStatusID: 'HLTPLN',
    payerName: 'THE HEALTH PLAN OF WEST VIRGINIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4598',
    payerID: '71063',
    eligibilityID: 'HLTSCP',
    claimStatusID: '',
    payerName: 'HEALTHSCOPE BENEFITS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8413',
    payerID: '71063',
    eligibilityID: 'HLTSCP',
    claimStatusID: '',
    payerName: 'HEALTHSCOPE BENEFITS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1981',
    payerID: '86066',
    eligibilityID: 'HMADMN',
    claimStatusID: '',
    payerName: 'HMA HAWAII',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4753',
    payerID: '86066',
    eligibilityID: 'HMADMN',
    claimStatusID: '',
    payerName: 'HMA HAWAII',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5243',
    payerID: 'HMA01',
    eligibilityID: 'HMAWR',
    claimStatusID: '',
    payerName: 'HEALTHCARE MGMT ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8920',
    payerID: 'HMA01',
    eligibilityID: 'HMAWR',
    claimStatusID: '',
    payerName: 'HEALTHCARE MGMT ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2714',
    payerID: '990040115',
    eligibilityID: 'HMSA',
    claimStatusID: '2714',
    payerName: 'HAWAII BLUE CROSS BLUE SHIELD HMSA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2714',
    payerID: '990040115',
    eligibilityID: 'HMSA',
    claimStatusID: '2714',
    payerName: 'HAWAII BLUE CROSS BLUE SHIELD HMSA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1495',
    payerID: '22771',
    eligibilityID: 'HNMHN',
    claimStatusID: '',
    payerName: 'MANAGED HEALTH NETWORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1955',
    payerID: '22771',
    eligibilityID: 'HNMHN',
    claimStatusID: '',
    payerName: 'MANAGED HEALTH NETWORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2839',
    payerID: '88023',
    eligibilityID: 'HOMTWN',
    claimStatusID: '',
    payerName: 'HOMETOWN HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2960',
    payerID: '88023',
    eligibilityID: 'HOMTWN',
    claimStatusID: '',
    payerName: 'HOMETOWN HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4292',
    payerID: 'HPSM1',
    eligibilityID: 'HPOSM',
    claimStatusID: '',
    payerName: 'HEALTH PLAN of SAN MATEO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5678',
    payerID: '12X74',
    eligibilityID: 'HPOSM',
    claimStatusID: '',
    payerName: 'HEALTH PLAN of SAN MATEO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2822',
    payerID: '80142',
    eligibilityID: 'HPPHIL',
    claimStatusID: 'HPPHIL',
    payerName: 'HEALTH PARTNERS PLANS of PENNSYLVANIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4572',
    payerID: '80142',
    eligibilityID: 'HPPHIL',
    claimStatusID: 'HPPHIL',
    payerName: 'HEALTH PARTNERS PLANS of PENNSYLVANIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1666',
    payerID: '94320',
    eligibilityID: 'HRGTN',
    claimStatusID: '',
    payerName: 'KAISER SELF FUNDED PLAN - HARRINGTON/FISERV',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4293',
    payerID: '94320',
    eligibilityID: 'HRGTN',
    claimStatusID: '',
    payerName: 'KAISER SELF FUNDED PLAN - HARRINGTON/FISERV',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4656',
    payerID: '59143',
    eligibilityID: 'HRGTN',
    claimStatusID: '',
    payerName: 'HARRINGTON HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6172',
    payerID: '59143',
    eligibilityID: 'HRGTN',
    claimStatusID: '',
    payerName: 'HARRINGTON HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2742',
    payerID: 'HPHC0001',
    eligibilityID: 'HRVPGM',
    claimStatusID: 'HRVPGM',
    payerName: 'HARVARD PILGRIM HEALTH CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,CS',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6512',
    payerID: 'HPHC0001',
    eligibilityID: 'HRVPGM',
    claimStatusID: 'HRVPGM',
    payerName: 'HARVARD PILGRIM HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,CS',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'HRVR',
    payerID: '',
    eligibilityID: 'HRVRDU',
    claimStatusID: 'HRVRDU',
    payerName: 'HARVARD UNIVERSITY HEALTH SERVICES',
    claimType: '',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1920',
    payerID: '37290',
    eligibilityID: 'HSCSN',
    claimStatusID: 'HSCSN',
    payerName: 'HEALTH SERVICES for CHILDREN with SPECIAL NEEDS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2297',
    payerID: '37290',
    eligibilityID: 'HSCSN',
    claimStatusID: 'HSCSN',
    payerName: 'HEALTH SERVICES for CHILDREN with SPECIAL NEEDS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2722',
    payerID: '37283',
    eligibilityID: 'HSMRT',
    claimStatusID: 'ACORDN',
    payerName: 'HEALTHSMART BENEFIT SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3926',
    payerID: '37283',
    eligibilityID: 'HSMRT',
    claimStatusID: 'ACORDN',
    payerName: 'HEALTHSMART BENEFIT SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1556',
    payerID: 'HSTN',
    eligibilityID: 'HSPRNG',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA HEALTHSPRING TENNESSEE ALABAMA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1556',
    payerID: 'HSTN',
    eligibilityID: 'HSPRNG',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA HEALTHSPRING TENNESSEE ALABAMA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2795',
    payerID: 'HSTN',
    eligibilityID: 'HSPRNG',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA HEALTHSPRING TENNESSEE ALABAMA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2795',
    payerID: 'HSTN',
    eligibilityID: 'HSPRNG',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA HEALTHSPRING TENNESSEE ALABAMA',
    claimType: 'Professional',
    State: 'TN',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2795',
    payerID: 'HSTN',
    eligibilityID: 'HSPRNG',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA HEALTHSPRING TENNESSEE ALABAMA',
    claimType: 'Professional',
    State: 'AL',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2795',
    payerID: 'HSTN',
    eligibilityID: 'HSPRNG',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA HEALTHSPRING TENNESSEE ALABAMA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4742',
    payerID: '52192',
    eligibilityID: 'HSPRNG',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA MEDICARE',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5694',
    payerID: '52192',
    eligibilityID: 'HSPRNG',
    claimStatusID: 'ELDRHL',
    payerName: 'CIGNA MEDICARE',
    claimType: 'Institutional',
    State: 'TX',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2449',
    payerID: '61101',
    eligibilityID: 'HUMANA',
    claimStatusID: 'HUMANA',
    payerName: 'HUMANA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4544',
    payerID: '61101',
    eligibilityID: 'HUMANA',
    claimStatusID: 'HUMANA',
    payerName: 'HUMANA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1709',
    payerID: '22326',
    eligibilityID: 'HZMRCY',
    claimStatusID: 'HZMRCY',
    payerName: 'HORIZON NJ HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6534',
    payerID: '22326',
    eligibilityID: 'HZMRCY',
    claimStatusID: 'HZMRCY',
    payerName: 'HORIZON NJ HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1404',
    payerID: '88848',
    eligibilityID: 'IABCBS',
    claimStatusID: 'IABCBS',
    payerName: 'IOWA BLUE SHIELD',
    claimType: 'Professional',
    State: 'IA',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1510',
    payerID: '88848',
    eligibilityID: 'IABCBS',
    claimStatusID: 'IABCBS',
    payerName: 'IOWA BLUE CROSS',
    claimType: 'Institutional',
    State: 'IA',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1510',
    payerID: '88848',
    eligibilityID: 'IABCBS',
    claimStatusID: 'IABCBS',
    payerName: 'IOWA BLUE CROSS',
    claimType: 'Institutional',
    State: 'SD',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2490',
    payerID: '88848',
    eligibilityID: 'IABCBS',
    claimStatusID: 'IABCBS',
    payerName: 'SOUTH DAKOTA BLUE SHIELD',
    claimType: 'Professional',
    State: 'SD',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1469',
    payerID: '18049',
    eligibilityID: 'IACAID',
    claimStatusID: '1469',
    payerName: 'IOWA MEDICAID',
    claimType: 'Professional',
    State: 'IA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2571',
    payerID: '18049',
    eligibilityID: 'IACAID',
    claimStatusID: '2571',
    payerName: 'IOWA MEDICAID',
    claimType: 'Institutional',
    State: 'IA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3540',
    payerID: '611',
    eligibilityID: 'IDBCS',
    claimStatusID: 'IDBCS',
    payerName: 'IDAHO BLUE SHIELD REGENCE',
    claimType: 'Institutional',
    State: 'ID',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7426',
    payerID: '611',
    eligibilityID: 'IDBCS',
    claimStatusID: 'IDBCS',
    payerName: 'IDAHO BLUE SHIELD REGENCE',
    claimType: 'Professional',
    State: 'ID',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5582',
    payerID: 'BLUEC',
    eligibilityID: 'IDBLCB',
    claimStatusID: '',
    payerName: 'IDAHO BLUE CROSS BOISE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5582',
    payerID: 'BLUEC',
    eligibilityID: 'IDBLCB',
    claimStatusID: '',
    payerName: 'IDAHO BLUE CROSS BOISE',
    claimType: 'Institutional',
    State: 'ID',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7430',
    payerID: 'BLUEC',
    eligibilityID: 'IDBLCB',
    claimStatusID: '',
    payerName: 'IDAHO BLUE CROSS BOISE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7430',
    payerID: 'BLUEC',
    eligibilityID: 'IDBLCB',
    claimStatusID: '',
    payerName: 'IDAHO BLUE CROSS BOISE',
    claimType: 'Professional',
    State: 'ID',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5583',
    payerID: 'ID_MMIS_4_DXCMS',
    eligibilityID: 'IDCAID',
    claimStatusID: '',
    payerName: 'IDAHO MEDICAID',
    claimType: 'Institutional',
    State: 'ID',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7427',
    payerID: 'ID_MMIS_4_DXCMS',
    eligibilityID: 'IDCAID',
    claimStatusID: '',
    payerName: 'IDAHO MEDICAID',
    claimType: 'Professional',
    State: 'ID',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2671',
    payerID: 'IEHP1',
    eligibilityID: 'IEHP',
    claimStatusID: '',
    payerName: 'INLAND EMPIRE HEALTH PLAN - DOS before 4/1/18',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4244',
    payerID: 'IEHP1',
    eligibilityID: 'IEHP',
    claimStatusID: '',
    payerName: 'INLAND EMPIRE HEALTH PLAN - DOS before 4/1/18',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6589',
    payerID: 'IEHP1',
    eligibilityID: 'IEHP',
    claimStatusID: '',
    payerName: 'INLAND EMPIRE HEALTH PLAN - DOS after 3/31/18',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7743',
    payerID: 'IEHP1',
    eligibilityID: 'IEHP',
    claimStatusID: '',
    payerName: 'INLAND EMPIRE HEALTH PLAN - DOS after 3/31/18',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1405',
    payerID: '621',
    eligibilityID: 'ILBCBS',
    claimStatusID: 'ILBCBS',
    payerName: 'ILLINOIS BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1405',
    payerID: '621',
    eligibilityID: 'ILBCBS',
    claimStatusID: 'ILBCBS',
    payerName: 'ILLINOIS BLUE SHIELD',
    claimType: 'Professional',
    State: 'IL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5508',
    payerID: '621',
    eligibilityID: 'ILBCBS',
    claimStatusID: 'ILBCBS',
    payerName: 'ILLINOIS BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5508',
    payerID: '621',
    eligibilityID: 'ILBCBS',
    claimStatusID: 'ILBCBS',
    payerName: 'ILLINOIS BLUE CROSS',
    claimType: 'Institutional',
    State: 'IL',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1563',
    payerID: '37-1320188',
    eligibilityID: 'ILCAID',
    claimStatusID: 'ILMCDI',
    payerName: 'ILLINOIS MEDICAID',
    claimType: 'Institutional',
    State: 'IL',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2488',
    payerID: '37-1320188',
    eligibilityID: 'ILCAID',
    claimStatusID: 'ILMCDP',
    payerName: 'ILLINOIS MEDICAID',
    claimType: 'Professional',
    State: 'IL',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2807',
    payerID: '36331',
    eligibilityID: 'ILPBA',
    claimStatusID: '',
    payerName: 'PROFESSIONAL BENEFIT ADMINISTRATORS (OAK BROOK,IL)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2950',
    payerID: '36331',
    eligibilityID: 'ILPBA',
    claimStatusID: '',
    payerName: 'PROFESSIONAL BENEFIT ADMINISTRATORS (OAKBROOK, IL)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1942',
    payerID: '41600',
    eligibilityID: 'IMCARE',
    claimStatusID: 'IMCARE',
    payerName: 'IMCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3764',
    payerID: '41600',
    eligibilityID: 'IMCARE',
    claimStatusID: 'IMCARE',
    payerName: 'IMCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1412',
    payerID: '630',
    eligibilityID: 'INBCBS',
    claimStatusID: 'INBCBS',
    payerName: 'INDIANA BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3502',
    payerID: '130',
    eligibilityID: 'INBCBS',
    claimStatusID: 'INBCBS',
    payerName: 'INDIANA BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3226',
    payerID: '56139',
    eligibilityID: 'INBMS',
    claimStatusID: '',
    payerName: 'MAESTRO HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4918',
    payerID: '56139',
    eligibilityID: 'INBMS',
    claimStatusID: '',
    payerName: 'MAESTRO HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1085',
    payerID: '3519M',
    eligibilityID: 'INCAID',
    claimStatusID: '',
    payerName: 'MDWISE HOOSIER HEALTHWISE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1086',
    payerID: '3135M',
    eligibilityID: 'INCAID',
    claimStatusID: '',
    payerName: 'MDWISE HEALTHY INDIANA PLAN ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1474',
    payerID: 'IHCP',
    eligibilityID: 'INCAID',
    claimStatusID: '1474',
    payerName: 'INDIANA MEDICAID',
    claimType: 'Professional',
    State: 'IN',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3501',
    payerID: 'IHCP',
    eligibilityID: 'INCAID',
    claimStatusID: '3501',
    payerName: 'INDIANA MEDICAID',
    claimType: 'Institutional',
    State: 'IN',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8112',
    payerID: '3519M',
    eligibilityID: 'INCAID',
    claimStatusID: '',
    payerName: 'MDWISE HOOSIER HEALTHWISE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8113',
    payerID: '3135M',
    eligibilityID: 'INCAID',
    claimStatusID: '',
    payerName: 'MDWISE HEALTHY INDIANA PLAN ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5113',
    payerID: 'TA720',
    eligibilityID: 'INDADM',
    claimStatusID: '',
    payerName: 'INDEPENDENCE ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'INTM',
    payerID: '',
    eligibilityID: 'INTMHS',
    claimStatusID: '',
    payerName: 'INTEGRATED MENTAL HEALTH SERVICES',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2502',
    payerID: '95444',
    eligibilityID: 'IUHLTH',
    claimStatusID: '',
    payerName: 'IU HEALTH PLAN MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5479',
    payerID: '95444',
    eligibilityID: 'IUHLTH',
    claimStatusID: '',
    payerName: 'IU HEALTH PLAN MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1236',
    payerID: 'IUHPLNS',
    eligibilityID: 'IUHLTP',
    claimStatusID: '',
    payerName: 'IU HEALTH PLANS (Payer ID IUHPLNS)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4553',
    payerID: 'IUHPLNS',
    eligibilityID: 'IUHLTP',
    claimStatusID: '',
    payerName: 'IU HEALTH PLANS (Payer ID IUHPLNS)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7261',
    payerID: '334',
    eligibilityID: 'IVHP',
    claimStatusID: '',
    payerName: 'INTER VALLEY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8675',
    payerID: 'IVHPA',
    eligibilityID: 'IVHP',
    claimStatusID: '',
    payerName: 'INTER VALLEY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6291',
    payerID: 'JAI01',
    eligibilityID: 'JAIMED',
    claimStatusID: '',
    payerName: 'JAI MEDICAL SYSTEMS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8936',
    payerID: 'JAI01',
    eligibilityID: 'JAIMED',
    claimStatusID: '',
    payerName: 'JAI MEDICAL SYSTEMS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4523',
    payerID: '87726',
    eligibilityID: 'JDHS',
    claimStatusID: '',
    payerName: 'UNITED HEALTHCARE COMMUNITY PLAN - TENNCARE only',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6442',
    payerID: '87726',
    eligibilityID: 'JDHS',
    claimStatusID: '',
    payerName: 'UNITED HEALTHCARE COMMUNITY PLAN - TENNCARE only',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1592',
    payerID: '72189',
    eligibilityID: 'JHHP',
    claimStatusID: '',
    payerName: 'JOHNS HOPKINS HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5432',
    payerID: '72189',
    eligibilityID: 'JHHP',
    claimStatusID: '',
    payerName: 'JOHNS HOPKINS HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4750',
    payerID: '52123',
    eligibilityID: 'JHUSFP',
    claimStatusID: '',
    payerName: 'JOHNS HOPKINS USFHP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4959',
    payerID: '52123',
    eligibilityID: 'JHUSFP',
    claimStatusID: '',
    payerName: 'JOHNS HOPKINS USFHP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'JNHP',
    payerID: 'JNHPS',
    eligibilityID: 'JNHPS',
    claimStatusID: '',
    payerName: 'JOHNS HOPKINS ADVANTAGE MD',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1544',
    payerID: 'RH002',
    eligibilityID: 'KAISNW',
    claimStatusID: 'KAISNW',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of the NORTHWEST',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2266',
    payerID: 'RH002',
    eligibilityID: 'KAISNW',
    claimStatusID: 'KAISNW',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of the NORTHWEST',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4419',
    payerID: '47171',
    eligibilityID: 'KCBCBS',
    claimStatusID: 'KCBCBS',
    payerName: 'MISSOURI BLUE SHIELD of KANSAS CITY',
    claimType: 'Professional',
    State: 'MO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5569',
    payerID: '47171',
    eligibilityID: 'KCBCBS',
    claimStatusID: 'KCBCBS',
    payerName: 'MISSOURI BLUE CROSS of KANSAS CITY',
    claimType: 'Institutional',
    State: 'MO',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4577',
    payerID: '73100',
    eligibilityID: 'KEMCO',
    claimStatusID: '',
    payerName: 'KEMPTON GROUP ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8435',
    payerID: '73100',
    eligibilityID: 'KEMCO',
    claimStatusID: '',
    payerName: 'KEMPTON GROUP ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2821',
    payerID: '37217',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'KEY BENEFIT ADMINISTRATORS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2836',
    payerID: '37216',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'KEY BENEFIT ADMINISTRATORS - FT MILL, SC',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3249',
    payerID: '37284',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'TRANSCHOICE KEY BENEFIT ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3273',
    payerID: '37322',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN',
    payerName: 'COMPANION LIFE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3989',
    payerID: '37322',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN',
    payerName: 'COMPANION LIFE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4677',
    payerID: '37284',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'TRANSCHOICE KEY BENEFIT ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4933',
    payerID: '37321',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'KEY SELECT',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5672',
    payerID: '61453',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN',
    payerName: 'KEMPER BENEFITS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5880',
    payerID: '37321',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'KEY SELECT',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7216',
    payerID: '61453',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN',
    payerName: 'KEMPER BENEFITS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7256',
    payerID: '37323',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'KEY SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7505',
    payerID: '37217',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'KEY BENEFIT ADMINISTRATORS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7528',
    payerID: '37216',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'KEY BENEFIT ADMINISTRATORS - FT MILL, SC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8673',
    payerID: '37323',
    eligibilityID: 'KEYBEN',
    claimStatusID: 'KEYBEN ',
    payerName: 'KEY SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6531',
    payerID: '23284',
    eligibilityID: 'KMHPLN',
    claimStatusID: 'KMHPLN',
    payerName: 'KEYSTONE FIRST',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8475',
    payerID: '23284',
    eligibilityID: 'KMHPLN',
    claimStatusID: 'KMHPLN',
    payerName: 'KEYSTONE FIRST',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4039',
    payerID: 'KPIC1',
    eligibilityID: 'KPIC',
    claimStatusID: '',
    payerName: 'KAISER PERMANENTE INSURANCE CO SELF FUNDED (DELL)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8767',
    payerID: 'KPIC1',
    eligibilityID: 'KPIC',
    claimStatusID: '',
    payerName: 'KAISER PERMANENTE INSURANCE CO SELF FUNDED (DELL)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2422',
    payerID: '47163',
    eligibilityID: 'KSBCBS',
    claimStatusID: 'KSBCBS',
    payerName: 'KANSAS BLUE SHIELD - TOPEKA',
    claimType: 'Professional',
    State: 'KS',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5519',
    payerID: '47163',
    eligibilityID: 'KSBCBS',
    claimStatusID: 'KSBCBS',
    payerName: 'KANSAS BLUE CROSS - TOPEKA',
    claimType: 'Institutional',
    State: 'KS',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1485',
    payerID: '481124839',
    eligibilityID: 'KSCAID',
    claimStatusID: '',
    payerName: 'KANSAS MEDICAID',
    claimType: 'Professional',
    State: 'KS',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5520',
    payerID: '481124839',
    eligibilityID: 'KSCAID',
    claimStatusID: '',
    payerName: 'KANSAS MEDICAID',
    claimType: 'Institutional',
    State: 'KS',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1093',
    payerID: '42344',
    eligibilityID: 'KSFCHC',
    claimStatusID: 'KSFCHC',
    payerName: 'KEYSTONE FIRST COMMUNITY HEALTHCHOICES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8121',
    payerID: '42344',
    eligibilityID: 'KSFCHC',
    claimStatusID: 'KSFCHC',
    payerName: 'KEYSTONE FIRST COMMUNITY HEALTHCHOICES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2819',
    payerID: 'RH010',
    eligibilityID: 'KSRMDA',
    claimStatusID: 'KAISMA',
    payerName: 'KAISER FOUNDATION HEALTH PLAN MID-ATLANTIC STATES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7506',
    payerID: 'RH010',
    eligibilityID: 'KSRMDA',
    claimStatusID: 'KAISMA',
    payerName: 'KAISER FOUNDATION HEALTH PLAN MID-ATLANTIC STATES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2582',
    payerID: '94135',
    eligibilityID: 'KSRNCA',
    claimStatusID: 'KSRNCA',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of N CA REGION',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3781',
    payerID: 'RH009',
    eligibilityID: 'KSRNCA',
    claimStatusID: 'KSRNCA',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of N CA REGION',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1703',
    payerID: '94134',
    eligibilityID: 'KSRSCA',
    claimStatusID: 'KSRSCA',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of S CA REGION',
    claimType: 'Professional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4576',
    payerID: '94134',
    eligibilityID: 'KSRSCA',
    claimStatusID: 'KSRSCA',
    payerName: 'KAISER FOUNDATION HEALTH PLAN of S CA REGION',
    claimType: 'Institutional',
    State: 'CA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6649',
    payerID: '77741',
    eligibilityID: 'KVIPC',
    claimStatusID: 'KVIPC',
    payerName: 'KEYSTONE FIRST VIP CHOICE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6751',
    payerID: '77741',
    eligibilityID: 'KVIPC',
    claimStatusID: 'KVIPC',
    payerName: 'KEYSTONE FIRST VIP CHOICE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2421',
    payerID: '660',
    eligibilityID: 'KYBCBS',
    claimStatusID: 'KYBCBS',
    payerName: 'KENTUCKY BLUE SHIELD',
    claimType: 'Professional',
    State: 'KY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2421',
    payerID: '660',
    eligibilityID: 'KYBCBS',
    claimStatusID: 'KYBCBS',
    payerName: 'KENTUCKY BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5534',
    payerID: '160',
    eligibilityID: 'KYBCBS',
    claimStatusID: 'KYBCBS',
    payerName: 'KENTUCKY BLUE CROSS',
    claimType: 'Institutional',
    State: 'KY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5534',
    payerID: '160',
    eligibilityID: 'KYBCBS',
    claimStatusID: 'KYBCBS',
    payerName: 'KENTUCKY BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1493',
    payerID: 'KYMEDICAID',
    eligibilityID: 'KYCAID',
    claimStatusID: 'KYCAID',
    payerName: 'KENTUCKY MEDICAID',
    claimType: 'Professional',
    State: 'KY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5535',
    payerID: 'KYMEDICAID',
    eligibilityID: 'KYCAID',
    claimStatusID: 'KYCAID',
    payerName: 'KENTUCKY MEDICAID',
    claimType: 'Institutional',
    State: 'KY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5008',
    payerID: '72107',
    eligibilityID: 'LABADV',
    claimStatusID: 'LABADV',
    payerName: 'LA BLUE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8853',
    payerID: '72107',
    eligibilityID: 'LABADV',
    claimStatusID: 'LABADV',
    payerName: 'LA BLUE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2758',
    payerID: '53120',
    eligibilityID: 'LABCBS',
    claimStatusID: '2758',
    payerName: 'LOUISIANA BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3580',
    payerID: '53120',
    eligibilityID: 'LABCBS',
    claimStatusID: '3580',
    payerName: 'LOUISIANA BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3580',
    payerID: '53120',
    eligibilityID: 'LABCBS',
    claimStatusID: '3580',
    payerName: 'LOUISIANA BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1475',
    payerID: 'LA-DHH-MEDICAID',
    eligibilityID: 'LACAID',
    claimStatusID: '',
    payerName: 'LOUISIANA MEDICAID',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1475',
    payerID: 'LA-DHH-MEDICAID',
    eligibilityID: 'LACAID',
    claimStatusID: '',
    payerName: 'LOUISIANA MEDICAID',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2236',
    payerID: 'LA-DHH-MEDICAID',
    eligibilityID: 'LACAID',
    claimStatusID: '',
    payerName: 'LOUISIANA MEDICAID - AMBULANCE',
    claimType: 'Professional',
    State: 'LA',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3578',
    payerID: 'LA-DHH-MEDICAID',
    eligibilityID: 'LACAID',
    claimStatusID: '',
    payerName: 'LOUISIANA MEDICAID',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3578',
    payerID: 'LA-DHH-MEDICAID',
    eligibilityID: 'LACAID',
    claimStatusID: '',
    payerName: 'LOUISIANA MEDICAID',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4638',
    payerID: '27357',
    eligibilityID: 'LACAR',
    claimStatusID: 'LACAR',
    payerName: 'AMERIHEALTH CARITAS LOUISIANA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6156',
    payerID: '27357',
    eligibilityID: 'LACAR',
    claimStatusID: 'LACAR',
    payerName: 'AMERIHEALTH CARITAS LOUISIANA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5988',
    payerID: 'LACAR',
    eligibilityID: 'LACRE',
    claimStatusID: 'LACRE',
    payerName: 'LA CARE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6484',
    payerID: 'LACAR',
    eligibilityID: 'LACRE',
    claimStatusID: 'LACRE',
    payerName: 'LA CARE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6437',
    payerID: '13193',
    eligibilityID: 'LALIMS',
    claimStatusID: '',
    payerName: 'LOYAL AMERICAN LIFE INSURANCE/MEDICARE SUPPLEMENT',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7664',
    payerID: '13193',
    eligibilityID: 'LALIMS',
    claimStatusID: '',
    payerName: 'LOYAL AMERICAN LIFE INSUR CO MEDICARE SUPPLEMENT',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'LCNH',
    payerID: '',
    eligibilityID: 'LCNHG',
    claimStatusID: '',
    payerName: 'LINCOLN HERITAGE LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4608',
    payerID: 'LIFE1',
    eligibilityID: 'LFPRT',
    claimStatusID: '',
    payerName: 'OPTUM MEDICAL NETWORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6101',
    payerID: 'LIFE1',
    eligibilityID: 'LFPRT',
    claimStatusID: '',
    payerName: 'OPTUM MEDICAL NETWORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'LIBR',
    payerID: '',
    eligibilityID: 'LIBRTY',
    claimStatusID: '',
    payerName: 'LIBERTY NATIONAL LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2034',
    payerID: '31828',
    eligibilityID: 'LIGHT',
    claimStatusID: 'LIGHT',
    payerName: 'LIGHTHOUSE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8171',
    payerID: '31828',
    eligibilityID: 'LIGHT',
    claimStatusID: 'LIGHT',
    payerName: 'LIGHTHOUSE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'LINF',
    payerID: '',
    eligibilityID: 'LINFG',
    claimStatusID: '',
    payerName: 'LINCOLN FINANCIAL GROUP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5444',
    payerID: '23223',
    eligibilityID: 'LOOMCO',
    claimStatusID: '',
    payerName: 'LOOMIS COMPANY (THE)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5541',
    payerID: '23223',
    eligibilityID: 'LOOMCO',
    claimStatusID: '',
    payerName: 'LOOMIS COMPANY (THE)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2424',
    payerID: '200',
    eligibilityID: 'MABCBS',
    claimStatusID: 'MABCBS',
    payerName: 'BLUE CROSS BLUE SHIELD of MASSACHUSETTS',
    claimType: 'Professional',
    State: 'MA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5528',
    payerID: '200',
    eligibilityID: 'MABCBS',
    claimStatusID: 'MABCBS',
    payerName: 'BLUE CROSS BLUE SHIELD of MASSACHUSETTS',
    claimType: 'Institutional',
    State: 'MA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4491',
    payerID: 'DMA7384',
    eligibilityID: 'MACAID',
    claimStatusID: '4491',
    payerName: 'MASSACHUSETTS MEDICAID (FORMAT 9)',
    claimType: 'Professional',
    State: 'MA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4492',
    payerID: 'DMA7384',
    eligibilityID: 'MACAID',
    claimStatusID: '4492',
    payerName: 'MASSACHUSETTS MEDICAID (FORMAT 5)',
    claimType: 'Professional',
    State: 'MA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5529',
    payerID: 'DMA7384',
    eligibilityID: 'MACAID',
    claimStatusID: '5529',
    payerName: 'MASSACHUSETTS MEDICAID',
    claimType: 'Institutional',
    State: 'MA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1419',
    payerID: '1260',
    eligibilityID: 'MAGLAN',
    claimStatusID: '',
    payerName: 'MAGELLAN HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5539',
    payerID: '1260',
    eligibilityID: 'MAGLAN',
    claimStatusID: '',
    payerName: 'MAGELLAN HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1704',
    payerID: '11303',
    eligibilityID: 'MAGNA',
    claimStatusID: '',
    payerName: 'MAGNACARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1989',
    payerID: '11303',
    eligibilityID: 'MAGNA',
    claimStatusID: '',
    payerName: 'MAGNACARE',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2772',
    payerID: 'MEDICAL ASSOCIATES HEALTH',
    eligibilityID: 'MAHP',
    claimStatusID: '',
    payerName: 'MEDICAL ASSOCIATES HEALTH PLANS - FEE for SERVICE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5251',
    payerID: '4600012',
    eligibilityID: 'MAPFRE',
    claimStatusID: '',
    payerName: 'MAPFRE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7626',
    payerID: '4600012',
    eligibilityID: 'MAPFRE',
    claimStatusID: '',
    payerName: 'MAPFRE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MARA',
    payerID: '',
    eligibilityID: 'MARIAD',
    claimStatusID: '',
    payerName: 'MARICOPA CARE ADVANTAGE - ARIZONA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2719',
    payerID: '9908',
    eligibilityID: 'MARIHP',
    claimStatusID: '',
    payerName: 'MARICOPA HEALTH PLAN - MARICOPA CARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8954',
    payerID: '9908',
    eligibilityID: 'MARIHP',
    claimStatusID: '',
    payerName: 'MARICOPA HEALTH PLAN - MARICOPA CARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MCA',
    payerID: '',
    eligibilityID: 'MCA',
    claimStatusID: '',
    payerName: 'MANAGED CARE of AMERICA',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1098',
    payerID: 'MCC01',
    eligibilityID: 'MCCAZ',
    claimStatusID: '',
    payerName: 'MOLINA COMPLETE CARE OF ARIZONA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8127',
    payerID: 'MCC01',
    eligibilityID: 'MCCAZ',
    claimStatusID: '',
    payerName: 'MOLINA COMPLETE CARE OF ARIZONA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5058',
    payerID: 'MHPIL',
    eligibilityID: 'MCCIL',
    claimStatusID: '',
    payerName: 'MERIDIAN MEDICARE MEDICAID PLAN ',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9102',
    payerID: 'MHPIL',
    eligibilityID: 'MCCIL',
    claimStatusID: '',
    payerName: 'MERIDIAN MEDICARE MEDICAID PLAN ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '3042',
    payerID: 'MCC02',
    eligibilityID: 'MCCVM',
    claimStatusID: 'MCCVM',
    payerName: 'MOLINA COMPLETE CARE of VIRGINIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8291',
    payerID: 'MCC02',
    eligibilityID: 'MCCVM',
    claimStatusID: 'MCCVM',
    payerName: 'MOLINA COMPLETE CARE of VIRGINIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '2592',
    payerID: '38338',
    eligibilityID: 'MCLREN',
    claimStatusID: '',
    payerName: 'MCLAREN HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3794',
    payerID: '38338',
    eligibilityID: 'MCLREN',
    claimStatusID: '',
    payerName: 'MCLAREN HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1543',
    payerID: '660396197P',
    eligibilityID: 'MCSPR',
    claimStatusID: '',
    payerName: 'MEDICAL CARD SYSTEMS (MCS) REFORMA',
    claimType: 'Institutional',
    State: 'PR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6712',
    payerID: '660396197P',
    eligibilityID: 'MCSPR',
    claimStatusID: '',
    payerName: 'MEDICAL CARD SYSTEMS (MCS) REFORMA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2576',
    payerID: '930989307',
    eligibilityID: 'MDAHLT',
    claimStatusID: '',
    payerName: 'MODA HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7498',
    payerID: '930989307',
    eligibilityID: 'MDAHLT',
    claimStatusID: '',
    payerName: 'MODA HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2419',
    payerID: '690',
    eligibilityID: 'MDBCBS',
    claimStatusID: 'MDBCBS',
    payerName: 'MARYLAND BLUE SHIELD - CAREFIRST',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7518',
    payerID: '190',
    eligibilityID: 'MDBCBS',
    claimStatusID: 'MDBCBS',
    payerName: 'MARYLAND BLUE CROSS - CAREFIRST',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1728',
    payerID: '74323',
    eligibilityID: 'MDBEN',
    claimStatusID: '',
    payerName: 'MEDICAL BENEFITS ADMINISTRATORS (NEWARK, OH)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8513',
    payerID: '74323',
    eligibilityID: 'MDBEN',
    claimStatusID: '',
    payerName: 'MEDICAL BENEFITS ADMINISTRATORS (NEWARK, OH)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8513',
    payerID: '74323',
    eligibilityID: 'MDBEN',
    claimStatusID: '',
    payerName: 'MEDICAL BENEFITS ADMINISTRATORS (NEWARK, OH)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MDBE',
    payerID: '',
    eligibilityID: 'MDBEN',
    claimStatusID: '',
    payerName: 'MEDBEN ACCESS',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1488',
    payerID: '526002033MCP',
    eligibilityID: 'MDCAID',
    claimStatusID: '',
    payerName: 'MARYLAND MEDICAID',
    claimType: 'Professional',
    State: 'MD',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5552',
    payerID: '526002033MCP',
    eligibilityID: 'MDCAID',
    claimStatusID: '',
    payerName: 'MARYLAND MEDICAID',
    claimType: 'Institutional',
    State: 'MD',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6600',
    payerID: '23160',
    eligibilityID: 'MDICO',
    claimStatusID: 'MDICO',
    payerName: 'MEDICO INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6885',
    payerID: '23160',
    eligibilityID: 'MDICO',
    claimStatusID: 'MDICO',
    payerName: 'MEDICO INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4068',
    payerID: '76498',
    eligibilityID: 'MDPHCR',
    claimStatusID: 'MDPHCR',
    payerName: 'MARYLAND PHYSICIANS CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8812',
    payerID: '76498',
    eligibilityID: 'MDPHCR',
    claimStatusID: 'MDPHCR',
    payerName: 'MARYLAND PHYSICIANS CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3548',
    payerID: '680',
    eligibilityID: 'MEBCBS',
    claimStatusID: 'MEBCBS',
    payerName: 'MAINE BLUE CROSS',
    claimType: 'Institutional',
    State: 'ME',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7446',
    payerID: '680',
    eligibilityID: 'MEBCBS',
    claimStatusID: 'MEBCBS',
    payerName: 'MAINE BLUE SHIELD',
    claimType: 'Professional',
    State: 'ME',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5259',
    payerID: 'ME_MMIS_4_DXCMS',
    eligibilityID: 'MECAID',
    claimStatusID: 'MECAID',
    payerName: 'MAINE MEDICAID',
    claimType: 'Professional',
    State: 'ME',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9582',
    payerID: 'ME_MMIS_4_DXCMS',
    eligibilityID: 'MECAID',
    claimStatusID: 'MECAID',
    payerName: 'MAINE MEDICAID',
    claimType: 'Institutional',
    State: 'ME',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6827',
    payerID: '45341',
    eligibilityID: 'MECOH',
    claimStatusID: '',
    payerName: 'MAINE COMMUNITY HEALTH OPTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7608',
    payerID: '45341',
    eligibilityID: 'MECOH',
    claimStatusID: '',
    payerName: 'MAINE COMMUNITY HEALTH OPTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1880',
    payerID: '56205',
    eligibilityID: 'MEDCST',
    claimStatusID: '',
    payerName: 'MEDCOST BENEFIT SERVICES (MBS)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8974',
    payerID: '56205',
    eligibilityID: 'MEDCST',
    claimStatusID: '',
    payerName: 'MEDCOST BENEFIT SERVICES (MBS)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1584',
    payerID: '95655',
    eligibilityID: 'MEDGLD',
    claimStatusID: '',
    payerName: 'MEDIGOLD HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4714',
    payerID: '95655',
    eligibilityID: 'MEDGLD',
    claimStatusID: '',
    payerName: 'MEDIGOLD HMO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5930',
    payerID: '13123',
    eligibilityID: 'MEDGLD',
    claimStatusID: '',
    payerName: 'MEDIGOLD PPO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7118',
    payerID: '13123',
    eligibilityID: 'MEDGLD',
    claimStatusID: '',
    payerName: 'MEDIGOLD PPO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5012',
    payerID: 'MEDM1',
    eligibilityID: 'MEDGP',
    claimStatusID: 'MEDGP',
    payerName: 'MEDICA GOVERNMENT PROGRAMS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8860',
    payerID: 'MEDM1',
    eligibilityID: 'MEDGP',
    claimStatusID: 'MEDGP',
    payerName: 'MEDICA GOVERNMENT PROGRAMS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6694',
    payerID: '12422',
    eligibilityID: 'MEDIFB',
    claimStatusID: '',
    payerName: 'MEDICA INDIVIDUAL and FAMILY (IFB)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6694',
    payerID: '12422',
    eligibilityID: 'MEDIFB',
    claimStatusID: '',
    payerName: 'MEDICA INDIVIDUAL and FAMILY (IFB)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6799',
    payerID: '12422',
    eligibilityID: 'MEDIFB',
    claimStatusID: '',
    payerName: 'MEDICA INDIVIDUAL and FAMILY (IFB)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7480',
    payerID: '431318969',
    eligibilityID: 'MEDPY',
    claimStatusID: '',
    payerName: 'MED-PAY, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MEDR',
    payerID: '',
    eligibilityID: 'MEDRX',
    claimStatusID: '',
    payerName: 'MEDRX',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'E',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5614',
    payerID: '251MS',
    eligibilityID: 'MEDSTR',
    claimStatusID: '',
    payerName: 'MEDSTAR SELECT',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5615',
    payerID: '251MS',
    eligibilityID: 'MEDSTR',
    claimStatusID: '',
    payerName: 'MEDSTAR MEDICARE CHOICE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6250',
    payerID: '251MS',
    eligibilityID: 'MEDSTR',
    claimStatusID: '',
    payerName: 'MEDSTAR SELECT',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6251',
    payerID: '251MS',
    eligibilityID: 'MEDSTR',
    claimStatusID: '',
    payerName: 'MEDSTAR MEDICARE CHOICE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4777',
    payerID: '13189',
    eligibilityID: 'MERIL',
    claimStatusID: '',
    payerName: 'MERIDIAN HEALTH PLAN of ILLINOIS DOS < 7/1/21',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5916',
    payerID: '13189',
    eligibilityID: 'MERIL',
    claimStatusID: '',
    payerName: 'MERIDIAN HEALTH PLAN of ILLINOIS DOS < 7/1/21',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1708',
    payerID: '41124',
    eligibilityID: 'MERITN',
    claimStatusID: '',
    payerName: 'MERITAIN HEALTH MINNEAPOLIS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4561',
    payerID: '41124',
    eligibilityID: 'MERITN',
    claimStatusID: '',
    payerName: 'MERITAIN HEALTH MINNEAPOLIS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1566',
    payerID: '',
    eligibilityID: 'METHPL',
    claimStatusID: 'METHPL',
    payerName: 'HENNEPIN HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2787',
    payerID: '',
    eligibilityID: 'METHPL',
    claimStatusID: 'METHPL',
    payerName: 'HENNEPIN HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2296',
    payerID: '13265',
    eligibilityID: 'METPL',
    claimStatusID: 'METPL',
    payerName: 'METRO PLUS HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8972',
    payerID: '13265',
    eligibilityID: 'METPL',
    claimStatusID: 'METPL',
    payerName: 'METRO PLUS HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1032',
    payerID: '71890',
    eligibilityID: 'MHSRT',
    claimStatusID: '',
    payerName: 'MEDICA HEALTH PLAN SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7859',
    payerID: '71890',
    eligibilityID: 'MHSRT',
    claimStatusID: '',
    payerName: 'MEDICA HEALTH PLAN SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2008',
    payerID: '82832',
    eligibilityID: 'MIAMI',
    claimStatusID: 'MIAMI',
    payerName: 'MIAMI CHILDRENS HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8140',
    payerID: '82832',
    eligibilityID: 'MIAMI',
    claimStatusID: 'MIAMI',
    payerName: 'MIAMI CHILDRENS HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3531',
    payerID: '210',
    eligibilityID: 'MIBCBI',
    claimStatusID: 'MIBCSI',
    payerName: 'MICHIGAN BLUE CROSS (FEP)',
    claimType: 'Institutional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1913',
    payerID: '210',
    eligibilityID: 'MIBCSI',
    claimStatusID: 'MIBCSI',
    payerName: 'MEDICARE PLUS BLUE - MEDICARE ADVANTAGE PLAN',
    claimType: 'Institutional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3514',
    payerID: '210',
    eligibilityID: 'MIBCSI',
    claimStatusID: 'MIBCSI',
    payerName: 'MICHIGAN BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3532',
    payerID: '210',
    eligibilityID: 'MIBCSI',
    claimStatusID: 'MIBCSI',
    payerName: 'BLUE CARE NETWORK HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3532',
    payerID: '210',
    eligibilityID: 'MIBCSI',
    claimStatusID: 'MIBCSI',
    payerName: 'BLUE CARE NETWORK HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3532',
    payerID: '210',
    eligibilityID: 'MIBCSI',
    claimStatusID: 'MIBCSI',
    payerName: 'BLUE CARE NETWORK HMO',
    claimType: 'Institutional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1421',
    payerID: '710',
    eligibilityID: 'MIBCSP',
    claimStatusID: 'MIBCSP',
    payerName: 'MICHIGAN BLUE SHIELD',
    claimType: 'Professional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1421',
    payerID: '710',
    eligibilityID: 'MIBCSP',
    claimStatusID: 'MIBCSP',
    payerName: 'MICHIGAN BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2287',
    payerID: '710',
    eligibilityID: 'MIBCSP',
    claimStatusID: 'MIBCSP',
    payerName: 'MEDICARE PLUS BLUE - MEDICARE ADVANTAGE PLAN',
    claimType: 'Professional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2426',
    payerID: '710',
    eligibilityID: 'MIBCSP',
    claimStatusID: 'MIBCSP',
    payerName: 'BLUE CARE NETWORK HMO',
    claimType: 'Professional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2480',
    payerID: 'D00111',
    eligibilityID: 'MICAID',
    claimStatusID: '2480',
    payerName: 'MICHIGAN MEDICAID',
    claimType: 'Professional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2532',
    payerID: '38337',
    eligibilityID: 'MICAID',
    claimStatusID: '',
    payerName: 'UPPER PENINSULA HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3512',
    payerID: 'D00111',
    eligibilityID: 'MICAID',
    claimStatusID: '3512',
    payerName: 'MICHIGAN MEDICAID',
    claimType: 'Institutional',
    State: 'MI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3741',
    payerID: '38337',
    eligibilityID: 'MICAID',
    claimStatusID: '',
    payerName: 'UPPER PENINSULA HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2272',
    payerID: 'JV',
    eligibilityID: 'MIMLNA',
    claimStatusID: '',
    payerName: 'MOLINA HEALTHCARE OF MICHIGAN NON PAR - JVHL',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3551',
    payerID: '38334',
    eligibilityID: 'MIMLNA',
    claimStatusID: 'MIMLNA',
    payerName: 'MOLINA HEALTHCARE OF MICHIGAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5443',
    payerID: '38334',
    eligibilityID: 'MIMLNA',
    claimStatusID: 'MIMLNA',
    payerName: 'MOLINA HEALTHCARE OF MICHIGAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8789',
    payerID: '',
    eligibilityID: 'MLDNTL',
    claimStatusID: '',
    payerName: 'METLIFE DENTAL FAMILY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2119',
    payerID: 'ABRI1',
    eligibilityID: 'MLNAWI',
    claimStatusID: 'MLNAWI',
    payerName: 'MOLINA HEALTHCARE of WISCONSIN',
    claimType: 'Professional',
    State: 'WI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5951',
    payerID: 'ABRI1',
    eligibilityID: 'MLNAWI',
    claimStatusID: 'MLNAWI',
    payerName: 'MOLINA HEALTHCARE of WISCONSIN',
    claimType: 'Institutional',
    State: 'WI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1098',
    payerID: 'MCC01',
    eligibilityID: 'MLNAZ',
    claimStatusID: '',
    payerName: 'MOLINA COMPLETE CARE OF ARIZONA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8127',
    payerID: 'MCC01',
    eligibilityID: 'MLNAZ',
    claimStatusID: '',
    payerName: 'MOLINA COMPLETE CARE OF ARIZONA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1018',
    payerID: '61799',
    eligibilityID: 'MLNID',
    claimStatusID: 'MLNID',
    payerName: 'MOLINA HEALTHCARE of  IDAHO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7842',
    payerID: '61799',
    eligibilityID: 'MLNID',
    claimStatusID: 'MLNID',
    payerName: 'MOLINA HEALTHCARE of  IDAHO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5015',
    payerID: '61325',
    eligibilityID: 'MLNKY',
    claimStatusID: 'MLNKY',
    payerName: 'PASSPORT HEALTH PLAN BY MOLINA HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8863',
    payerID: '61325',
    eligibilityID: 'MLNKY',
    claimStatusID: 'MLNKY',
    payerName: 'PASSPORT HEALTH PLAN BY MOLINA HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6086',
    payerID: 'MLNNV',
    eligibilityID: 'MLNNV',
    claimStatusID: '',
    payerName: 'MOLINA HEALTHCARE OF NEVADA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9247',
    payerID: 'MLNNV',
    eligibilityID: 'MLNNV',
    claimStatusID: '',
    payerName: 'MOLINA HEALTHCARE OF NEVADA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '3042',
    payerID: 'MCC02',
    eligibilityID: 'MLNVA',
    claimStatusID: 'MCCVM',
    payerName: 'MOLINA COMPLETE CARE of VIRGINIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8291',
    payerID: 'MCC02',
    eligibilityID: 'MLNVA',
    claimStatusID: 'MCCVM',
    payerName: 'MOLINA COMPLETE CARE of VIRGINIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '5985',
    payerID: '33628',
    eligibilityID: 'MMICR',
    claimStatusID: 'MMICR',
    payerName: 'MERCY CARE RBHA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6717',
    payerID: '33628',
    eligibilityID: 'MMICR',
    claimStatusID: 'MMICR',
    payerName: 'MERCY CARE RBHA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1090',
    payerID: 'MMMFL',
    eligibilityID: 'MMMFL',
    claimStatusID: '',
    payerName: 'MMM OF FLORIDA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8118',
    payerID: 'MMMFL',
    eligibilityID: 'MMMFL',
    claimStatusID: '',
    payerName: 'MMM OF FLORIDA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1591',
    payerID: '660588600',
    eligibilityID: 'MMMHC',
    claimStatusID: '',
    payerName: 'MMM HEALTHCARE',
    claimType: 'Institutional',
    State: 'PR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5819',
    payerID: '660588600',
    eligibilityID: 'MMMHC',
    claimStatusID: '',
    payerName: 'MMM HEALTHCARE',
    claimType: 'Professional',
    State: 'PR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7230',
    payerID: '660653763',
    eligibilityID: 'MMMHRF',
    claimStatusID: '',
    payerName: 'MMM MULTI HEALTH (REFORM)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8647',
    payerID: '660653763',
    eligibilityID: 'MMMHRF',
    claimStatusID: '',
    payerName: 'MMM MULTI HEALTH (REFORM)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3506',
    payerID: '29076',
    eligibilityID: 'MMUTOH',
    claimStatusID: 'MMUTOH',
    payerName: 'MEDICAL MUTUAL of OHIO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '4423',
    payerID: '29076',
    eligibilityID: 'MMUTOH',
    claimStatusID: 'MMUTOH',
    payerName: 'MEDICAL MUTUAL of OHIO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2167',
    payerID: '81883',
    eligibilityID: 'MNHBF',
    claimStatusID: '',
    payerName: 'MUNICIPAL HEALTH BENEFIT FUND',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5956',
    payerID: '81883',
    eligibilityID: 'MNHBF',
    claimStatusID: '',
    payerName: 'MUNICIPAL HEALTH BENEFIT FUND',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1064',
    payerID: '562',
    eligibilityID: 'MNHCP',
    claimStatusID: '',
    payerName: 'MINNESOTA BLUE CROSS BLUE SHIELD HEALTH CARE PROGR',
    claimType: 'Institutional',
    State: 'MN',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7892',
    payerID: '562',
    eligibilityID: 'MNHCP',
    claimStatusID: '',
    payerName: 'MINNESOTA BLUE CROSS BLUE SHIELD HEALTH CARE PROGR',
    claimType: 'Professional',
    State: 'MN',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MNHT',
    payerID: '',
    eligibilityID: 'MNHTN',
    claimStatusID: '',
    payerName: 'MANHATTAN LIFE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1038',
    payerID: '55413',
    eligibilityID: 'MNUCAR',
    claimStatusID: '',
    payerName: 'UCARE PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1531',
    payerID: '52629',
    eligibilityID: 'MNUCAR',
    claimStatusID: '',
    payerName: 'UCARE OF MINNESOTA DOS before 1/1/2022',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4496',
    payerID: '52629',
    eligibilityID: 'MNUCAR',
    claimStatusID: '',
    payerName: 'UCARE OF MINNESOTA DOS before 1/1/2022',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7867',
    payerID: '55413',
    eligibilityID: 'MNUCAR',
    claimStatusID: '',
    payerName: 'UCARE PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1408',
    payerID: '241',
    eligibilityID: 'MOBCBS',
    claimStatusID: 'MOBCBS',
    payerName: 'MISSOURI BLUE SHIELD - ST LOUIS MO',
    claimType: 'Professional',
    State: 'MO',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2597',
    payerID: '241',
    eligibilityID: 'MOBCBS',
    claimStatusID: 'MOBCBS',
    payerName: 'MISSOURI BLUE CROSS - ST LOUIS MO',
    claimType: 'Institutional',
    State: 'MO',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1471',
    payerID: '431754897',
    eligibilityID: 'MOCAID',
    claimStatusID: 'MOCAID',
    payerName: 'MISSOURI MEDICAID',
    claimType: 'Professional',
    State: 'MO',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5571',
    payerID: '954897',
    eligibilityID: 'MOCAID',
    claimStatusID: 'MOCAID',
    payerName: 'MISSOURI MEDICAID',
    claimType: 'Institutional',
    State: 'MO',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2817',
    payerID: '13174',
    eligibilityID: 'MOCMO',
    claimStatusID: '',
    payerName: 'MONTEFIORE CMO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7541',
    payerID: '13174',
    eligibilityID: 'MOCMO',
    claimStatusID: '',
    payerName: 'MONTEFIORE CMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6806',
    payerID: '20934',
    eligibilityID: 'MOLIL',
    claimStatusID: 'MOLIL',
    payerName: 'MOLINA HEALTHCARE of ILLINOIS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6907',
    payerID: '20934',
    eligibilityID: 'MOLIL',
    claimStatusID: 'MOLIL',
    payerName: 'MOLINA HEALTHCARE of ILLINOIS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8648',
    payerID: '81794',
    eligibilityID: 'MOLPR',
    claimStatusID: 'MOLPR',
    payerName: 'MOLINA HEALTHCARE of PUERTO RICO (REFORM)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3039',
    payerID: '65465',
    eligibilityID: 'MORECA',
    claimStatusID: 'MORECA',
    payerName: 'MORECARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '8290',
    payerID: '65465',
    eligibilityID: 'MORECA',
    claimStatusID: 'MORECA',
    payerName: 'MORECARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1639',
    payerID: 'MPHC2',
    eligibilityID: 'MRTNP',
    claimStatusID: '',
    payerName: 'MARTINS POINT HEALTH CARE USFHP/GENERATIONS ADV',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5701',
    payerID: 'MPHC2',
    eligibilityID: 'MRTNP',
    claimStatusID: '',
    payerName: 'MARTINS POINT HEALTH CARE USFHP/GENERATIONS ADV',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3045',
    payerID: '83269',
    eligibilityID: 'MRYWSH',
    claimStatusID: '',
    payerName: 'MARY WASHINGTON HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8294',
    payerID: '83269',
    eligibilityID: 'MRYWSH',
    claimStatusID: '',
    payerName: 'MARY WASHINGTON HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2415',
    payerID: '230',
    eligibilityID: 'MSBCBS',
    claimStatusID: 'MSBCBS',
    payerName: 'MISSISSIPPI BLUE SHIELD',
    claimType: 'Professional',
    State: 'MS',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5555',
    payerID: '230',
    eligibilityID: 'MSBCBS',
    claimStatusID: 'MSBCBS',
    payerName: 'MISSISSIPPI BLUE CROSS',
    claimType: 'Institutional',
    State: 'MS',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1490',
    payerID: '77032',
    eligibilityID: 'MSCAID',
    claimStatusID: 'MSCAID',
    payerName: 'MISSISSIPPI MEDICAID',
    claimType: 'Professional',
    State: 'MS',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5557',
    payerID: '77032',
    eligibilityID: 'MSCAID',
    claimStatusID: 'MSCAID',
    payerName: 'MISSISSIPPI MEDICAID',
    claimType: 'Institutional',
    State: 'MS',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'MSCH',
    payerID: '',
    eligibilityID: 'MSCHIP',
    claimStatusID: '',
    payerName: 'MISSISSIPPI CHIP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3614',
    payerID: '39190',
    eligibilityID: 'MSFC',
    claimStatusID: '',
    payerName: 'MEDSTAR FAMILY CHOICE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4775',
    payerID: '39190',
    eligibilityID: 'MSFC',
    claimStatusID: '',
    payerName: 'MEDSTAR FAMILY CHOICE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5561',
    payerID: '230',
    eligibilityID: 'MSSEHP',
    claimStatusID: '',
    payerName: 'MISSISSIPPI BLUE CROSS-STATE EMPLOYEE',
    claimType: 'Institutional',
    State: 'MS',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5585',
    payerID: 'BCBS',
    eligibilityID: 'MTBCBS',
    claimStatusID: 'MTBCBS',
    payerName: 'MONTANA BLUE CROSS',
    claimType: 'Institutional',
    State: 'MT',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7450',
    payerID: 'BCBS',
    eligibilityID: 'MTBCBS',
    claimStatusID: 'MTBCBS',
    payerName: 'MONTANA BLUE SHIELD',
    claimType: 'Professional',
    State: 'MT',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '4427',
    payerID: '77039',
    eligibilityID: 'MTCAID',
    claimStatusID: '',
    payerName: 'MONTANA MEDICAID',
    claimType: 'Professional',
    State: 'MT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5586',
    payerID: '77039',
    eligibilityID: 'MTCAID',
    claimStatusID: '',
    payerName: 'MONTANA MEDICAID',
    claimType: 'Institutional',
    State: 'MT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2065',
    payerID: '82275',
    eligibilityID: 'MUTOMA',
    claimStatusID: '',
    payerName: 'MUTUAL of OMAHA MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: '',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8205',
    payerID: '82275',
    eligibilityID: 'MUTOMA',
    claimStatusID: '',
    payerName: 'MUTUAL of OMAHA MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: '',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2420',
    payerID: '71412',
    eligibilityID: 'MUTOMH',
    claimStatusID: 'MUTOMH',
    payerName: 'MUTUAL of OMAHA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '4530',
    payerID: '71412',
    eligibilityID: 'MUTOMH',
    claimStatusID: 'MUTOMH',
    payerName: 'MUTUAL OF OMAHA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7224',
    payerID: '53011',
    eligibilityID: 'NAOLC',
    claimStatusID: '',
    payerName: 'NATIONAL ASSOCIATION of LETTER CARRIERS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8640',
    payerID: '53011',
    eligibilityID: 'NAOLC',
    claimStatusID: '',
    payerName: 'NATIONAL ASSOCIATION of LETTER CARRIERS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5016',
    payerID: '55892',
    eligibilityID: 'NBOND',
    claimStatusID: '',
    payerName: 'NEXTBLUE OF NORTH DAKOTA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8864',
    payerID: '55892',
    eligibilityID: 'NBOND',
    claimStatusID: '',
    payerName: 'NEXTBLUE OF NORTH DAKOTA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1730',
    payerID: '75190',
    eligibilityID: 'NCAS',
    claimStatusID: '',
    payerName: 'CAREFIRST ADMINISTRATORS / NCAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2914',
    payerID: '75190',
    eligibilityID: 'NCAS',
    claimStatusID: '',
    payerName: 'CAREFIRST ADMINISTRATORS / NCAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1411',
    payerID: '560894904',
    eligibilityID: 'NCBCBS',
    claimStatusID: 'NCBCBS',
    payerName: 'NORTH CAROLINA BLUE SHIELD',
    claimType: 'Professional',
    State: 'NC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3562',
    payerID: '560894904',
    eligibilityID: 'NCBCBS',
    claimStatusID: 'NCBCBS',
    payerName: 'NORTH CAROLINA BLUE CROSS',
    claimType: 'Institutional',
    State: 'NC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1480',
    payerID: 'DNC00',
    eligibilityID: 'NCCAID',
    claimStatusID: '1480',
    payerName: 'NORTH CAROLINA MEDICAID',
    claimType: 'Professional',
    State: 'NC',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3564',
    payerID: 'DNC00',
    eligibilityID: 'NCCAID',
    claimStatusID: '3564',
    payerName: 'NORTH CAROLINA MEDICAID',
    claimType: 'Institutional',
    State: 'NC',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7472',
    payerID: '560894904',
    eligibilityID: 'NCPNHP',
    claimStatusID: '',
    payerName: 'BLUE MEDICARE PPO/HMO of NORTH CAROLINA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5593',
    payerID: '55891',
    eligibilityID: 'NDBCBI',
    claimStatusID: '5593',
    payerName: 'NORTH DAKOTA BLUE CROSS',
    claimType: 'Institutional',
    State: 'ND',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2411',
    payerID: '55891',
    eligibilityID: 'NDBCBP',
    claimStatusID: '2411',
    payerName: 'NORTH DAKOTA BLUE SHIELD',
    claimType: 'Professional',
    State: 'ND',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1417',
    payerID: '760',
    eligibilityID: 'NEBCBS',
    claimStatusID: 'NEBCBS',
    payerName: 'NEBRASKA BLUE SHIELD',
    claimType: 'Professional',
    State: 'NE',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1521',
    payerID: '260',
    eligibilityID: 'NEBCBS',
    claimStatusID: 'NEBCBS',
    payerName: 'NEBRASKA BLUE CROSS',
    claimType: 'Institutional',
    State: 'NE',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1476',
    payerID: 'NEMEDICAID',
    eligibilityID: 'NECAID',
    claimStatusID: '',
    payerName: 'NEBRASKA MEDICAID',
    claimType: 'Professional',
    State: 'NE',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1520',
    payerID: 'NEMEDICAID',
    eligibilityID: 'NECAID',
    claimStatusID: '',
    payerName: 'NEBRASKA MEDICAID',
    claimType: 'Institutional',
    State: 'NE',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'NECVMD',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'NECVMD',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5457',
    payerID: 'NHP',
    eligibilityID: 'NGHBHP',
    claimStatusID: '',
    payerName: 'ALLWAYS HEALTH PARTNERS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8588',
    payerID: 'NHP',
    eligibilityID: 'NGHBHP',
    claimStatusID: '',
    payerName: 'ALLWAYS HEALTH PARTNERS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1567',
    payerID: '95123',
    eligibilityID: 'NGHPRT',
    claimStatusID: '',
    payerName: 'NEIGHBORHOOD HEALTH PARTNERSHIP of FLORIDA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2721',
    payerID: '95123',
    eligibilityID: 'NGHPRT',
    claimStatusID: '',
    payerName: 'NEIGHBORHOOD HEALTH PARTNERSHIP of FLORIDA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1512',
    payerID: '270',
    eligibilityID: 'NHBCBS',
    claimStatusID: 'NHBCBS',
    payerName: 'NEW HAMPSHIRE BLUE CROSS',
    claimType: 'Institutional',
    State: 'NH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7422',
    payerID: '770',
    eligibilityID: 'NHBCBS',
    claimStatusID: 'NHBCBS',
    payerName: 'NEW HAMPSHIRE BLUE SHIELD',
    claimType: 'Professional',
    State: 'NH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2575',
    payerID: '26000618',
    eligibilityID: 'NHCAID',
    claimStatusID: '2575',
    payerName: 'NEW HAMPSHIRE MEDICAID',
    claimType: 'Institutional',
    State: 'NH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7423',
    payerID: '26000618',
    eligibilityID: 'NHCAID',
    claimStatusID: '7423',
    payerName: 'NEW HAMPSHIRE MEDICAID',
    claimType: 'Professional',
    State: 'NH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1615',
    payerID: '77076',
    eligibilityID: 'NHICMC',
    claimStatusID: '',
    payerName: 'NETWORK HEALTH INSURANCE CORP MEDICARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2102',
    payerID: '77076',
    eligibilityID: 'NHICMC',
    claimStatusID: '',
    payerName: 'NETWORK HEALTH INSURANCE CORP MEDICARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2414',
    payerID: '22099',
    eligibilityID: 'NJBCBS',
    claimStatusID: 'NJBCBS',
    payerName: 'HORIZON BLUE SHIELD OF NEW JERSEY',
    claimType: 'Professional',
    State: 'NJ',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5526',
    payerID: '22099',
    eligibilityID: 'NJBCBS',
    claimStatusID: 'NJBCBS',
    payerName: 'HORIZON BLUE CROSS OF NEW JERSEY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5526',
    payerID: '22099',
    eligibilityID: 'NJBCBS',
    claimStatusID: 'NJBCBS',
    payerName: 'HORIZON BLUE CROSS OF NEW JERSEY',
    claimType: 'Institutional',
    State: 'NJ',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5526',
    payerID: '22099',
    eligibilityID: 'NJBCBS',
    claimStatusID: 'NJBCBS',
    payerName: 'HORIZON BLUE CROSS OF NEW JERSEY',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1483',
    payerID: '610515',
    eligibilityID: 'NJCAID',
    claimStatusID: '',
    payerName: 'NEW JERSEY MEDICAID',
    claimType: 'Professional',
    State: 'NJ',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5505',
    payerID: '12',
    eligibilityID: 'NJCAID',
    claimStatusID: '',
    payerName: 'NEW JERSEY MEDICAID',
    claimType: 'Institutional',
    State: 'NJ',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6443',
    payerID: '81264',
    eligibilityID: 'NLICOA',
    claimStatusID: 'NLICOA',
    payerName: 'NIPPON LIFE INSURANCE COMPANY of AMERICA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6539',
    payerID: '81264',
    eligibilityID: 'NLICOA',
    claimStatusID: 'NLICOA',
    payerName: 'NIPPON LIFE INSURANCE COMPANY of AMERICA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5565',
    payerID: '790',
    eligibilityID: 'NMBCBS',
    claimStatusID: 'NMBCBS',
    payerName: 'NEW MEXICO BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5565',
    payerID: '790',
    eligibilityID: 'NMBCBS',
    claimStatusID: 'NMBCBS',
    payerName: 'NEW MEXICO BLUE CROSS',
    claimType: 'Institutional',
    State: 'NM',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7403',
    payerID: '790',
    eligibilityID: 'NMBCBS',
    claimStatusID: 'NMBCBS',
    payerName: 'NEW MEXICO BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7403',
    payerID: '790',
    eligibilityID: 'NMBCBS',
    claimStatusID: 'NMBCBS',
    payerName: 'NEW MEXICO BLUE SHIELD',
    claimType: 'Professional',
    State: 'NM',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7403',
    payerID: '790',
    eligibilityID: 'NMBCBS',
    claimStatusID: 'NMBCBS',
    payerName: 'NEW MEXICO BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1492',
    payerID: 'NMMAD',
    eligibilityID: 'NMCAID',
    claimStatusID: '',
    payerName: 'NEW MEXICO MEDICAID',
    claimType: 'Professional',
    State: 'NM',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5562',
    payerID: 'NMMAD',
    eligibilityID: 'NMCAID',
    claimStatusID: '',
    payerName: 'NEW MEXICO MEDICAID',
    claimType: 'Institutional',
    State: 'NM',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3848',
    payerID: '9824',
    eligibilityID: 'NMMLNA',
    claimStatusID: 'NMMLNA',
    payerName: 'MOLINA HEALTHCARE OF NEW MEXICO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5486',
    payerID: '16644',
    eligibilityID: 'NOVAHC',
    claimStatusID: '',
    payerName: 'NOVA HEALTHCARE ADMINISTRATORS, INC. (GRAND ISLAND',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7583',
    payerID: '16644',
    eligibilityID: 'NOVAHC',
    claimStatusID: '',
    payerName: 'NOVA HEALTHCARE ADMINISTRATORS, INC. (GRAND ISLAND',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'NRLCA',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'NRLCA',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2407',
    payerID: '52103',
    eligibilityID: 'NTCA',
    claimStatusID: '',
    payerName: 'NATIONAL TELECOMMUNICATIONS COOP ASSOC. (NTCA)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3987',
    payerID: '52103',
    eligibilityID: 'NTCA',
    claimStatusID: '',
    payerName: 'NATIONAL TELECOMMUNICATIONS COOP ASSOC. (NTCA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2523',
    payerID: '170558746',
    eligibilityID: 'NTHLMA',
    claimStatusID: '',
    payerName: 'TUFTS HEALTH PUBLIC PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5475',
    payerID: '170558746',
    eligibilityID: 'NTHLMA',
    claimStatusID: '',
    payerName: 'TUFTS HEALTH PUBLIC PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1783',
    payerID: '39144',
    eligibilityID: 'NTWHL',
    claimStatusID: '',
    payerName: 'NETWORK HEALTH PLAN of WISCONSIN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3574',
    payerID: '39144',
    eligibilityID: 'NTWHL',
    claimStatusID: '',
    payerName: 'NETWORK HEALTH PLAN OF WISCONSIN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2511',
    payerID: '71080',
    eligibilityID: 'NVASYS',
    claimStatusID: '',
    payerName: 'NOVASYS HEALTH NETWORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3733',
    payerID: '71080',
    eligibilityID: 'NVASYS',
    claimStatusID: '',
    payerName: 'NOVASYS HEALTH NETWORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2906',
    payerID: '265',
    eligibilityID: 'NVBCBS',
    claimStatusID: 'NVBCBS',
    payerName: 'NEVADA BLUE CROSS',
    claimType: 'Institutional',
    State: 'NV',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7496',
    payerID: '265',
    eligibilityID: 'NVBCBS',
    claimStatusID: 'NVBCBS',
    payerName: 'NEVADA BLUE SHIELD',
    claimType: 'Professional',
    State: 'NV',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5652',
    payerID: 'NWADM',
    eligibilityID: 'NWADM',
    claimStatusID: '',
    payerName: 'NORTHWEST ADMINISTRATORS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6293',
    payerID: 'NWADM',
    eligibilityID: 'NWADM',
    claimStatusID: '',
    payerName: 'NORTHWEST ADMINISTRATORS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1477',
    payerID: '98798',
    eligibilityID: 'NWERAL',
    claimStatusID: '',
    payerName: 'NEW ERA LIFE INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3973',
    payerID: '98798',
    eligibilityID: 'NWERAL',
    claimStatusID: '',
    payerName: 'NEW ERA LIFE INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3527',
    payerID: '801',
    eligibilityID: 'NYBCBS',
    claimStatusID: '',
    payerName: 'NEW YORK BLUE CROSS WESTERN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3527',
    payerID: '801',
    eligibilityID: 'NYBCBS',
    claimStatusID: '',
    payerName: 'NEW YORK BLUE CROSS WESTERN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4409',
    payerID: '801',
    eligibilityID: 'NYBCBS',
    claimStatusID: '',
    payerName: 'NEW YORK BLUE SHIELD WESTERN',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3525',
    payerID: '800',
    eligibilityID: 'NYBCNE',
    claimStatusID: '',
    payerName: 'NEW YORK BLUE CROSS of NORTHEASTERN NEW YORK',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7433',
    payerID: '800',
    eligibilityID: 'NYBCNE',
    claimStatusID: '',
    payerName: 'NEW YORK BLUE SHIELD of NORTHEASTERN NEW YORK',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1422',
    payerID: '141797357',
    eligibilityID: 'NYCAID',
    claimStatusID: '1422',
    payerName: 'NEW YORK MEDICAID - PHASE II',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1500',
    payerID: '141797357',
    eligibilityID: 'NYCAID',
    claimStatusID: '1500',
    payerName: 'NEW YORK MEDICAID TITLE XIX - PHASE II',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1501',
    payerID: '141797357',
    eligibilityID: 'NYCAID',
    claimStatusID: '',
    payerName: 'NEW YORK MEDICAID INPATIENT - PHASE II',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1502',
    payerID: '141797357',
    eligibilityID: 'NYCAID',
    claimStatusID: '',
    payerName: 'NEW YORK MEDICAID OUTPATIENT - PHASE II',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3449',
    payerID: '805',
    eligibilityID: 'NYEXCL',
    claimStatusID: 'NYEXCL',
    payerName: 'NEW YORK BLUE SHIELD  CENTRAL REGION',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3528',
    payerID: '304',
    eligibilityID: 'NYEXCL',
    claimStatusID: 'NYEXCL',
    payerName: 'NEW YORK BLUE CROSS OF  ROCHESTER',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3618',
    payerID: '305',
    eligibilityID: 'NYEXCL',
    claimStatusID: 'NYEXCL',
    payerName: 'NEW YORK BLUE CROSS CENTRAL REGION',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4408',
    payerID: '804',
    eligibilityID: 'NYEXCL',
    claimStatusID: 'NYEXCL',
    payerName: 'NEW YORK BLUE SHIELD OF ROCHESTER',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7432',
    payerID: '806',
    eligibilityID: 'NYEXCL',
    claimStatusID: 'NYEXCL',
    payerName: 'NEW YORK BLUE SHIELD UTICA/WATERTOWN',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1854',
    payerID: '80141',
    eligibilityID: 'NYHFST',
    claimStatusID: 'NYHFST',
    payerName: 'HEALTHFIRST of NEW YORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1973',
    payerID: '80141',
    eligibilityID: 'NYHFST',
    claimStatusID: 'NYHFST',
    payerName: 'HEALTHFIRST of NEW YORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3425',
    payerID: '95308',
    eligibilityID: 'NYIHAW',
    claimStatusID: '',
    payerName: 'NEW YORK INDEPENDENT HEALTH ASSOC (IHA) - WESTERN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3545',
    payerID: '95308',
    eligibilityID: 'NYIHAW',
    claimStatusID: '',
    payerName: 'NEW YORK INDEPENDENT HEALTH ASSOC (IHA) - WESTERN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1748',
    payerID: '14165',
    eligibilityID: 'NYMVP',
    claimStatusID: '',
    payerName: 'MVP HEALTH CARE',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1748',
    payerID: '14165',
    eligibilityID: 'NYMVP',
    claimStatusID: '',
    payerName: 'MVP HEALTH CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6538',
    payerID: '14165',
    eligibilityID: 'NYMVP',
    claimStatusID: '',
    payerName: 'MVP HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6538',
    payerID: '14165',
    eligibilityID: 'NYMVP',
    claimStatusID: '',
    payerName: 'MVP HEALTH CARE',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2418',
    payerID: '834',
    eligibilityID: 'OHBCBS',
    claimStatusID: 'OHBCBS',
    payerName: 'OHIO BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2418',
    payerID: '834',
    eligibilityID: 'OHBCBS',
    claimStatusID: 'OHBCBS',
    payerName: 'OHIO BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3505',
    payerID: '332',
    eligibilityID: 'OHBCBS',
    claimStatusID: 'OHBCBS',
    payerName: 'OHIO BLUE CROSS',
    claimType: 'Institutional',
    State: 'OH',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2481',
    payerID: 'MMISODJFS',
    eligibilityID: 'OHCAID',
    claimStatusID: '2481',
    payerName: 'OHIO MEDICAID',
    claimType: 'Professional',
    State: 'OH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3509',
    payerID: 'MMISODJFS',
    eligibilityID: 'OHCAID',
    claimStatusID: '3509',
    payerName: 'OHIO MEDICAID',
    claimType: 'Institutional',
    State: 'OH',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,E',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1908',
    payerID: '20149',
    eligibilityID: 'OHMLNA',
    claimStatusID: 'OHMLNA',
    payerName: 'MOLINA HEALTHCARE OF OHIO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2286',
    payerID: '20149',
    eligibilityID: 'OHMLNA',
    claimStatusID: 'OHMLNA',
    payerName: 'MOLINA HEALTHCARE OF OHIO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'OHPB',
    payerID: '',
    eligibilityID: 'OHPBA',
    claimStatusID: '',
    payerName: 'PROFESSIONAL BENEFIT ADMINISTRATORS (OH)',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1403',
    payerID: '840',
    eligibilityID: 'OKBCBS',
    claimStatusID: 'OKBCBS',
    payerName: 'OKLAHOMA BLUE SHIELD',
    claimType: 'Professional',
    State: 'OK',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1514',
    payerID: '840',
    eligibilityID: 'OKBCBS',
    claimStatusID: 'OKBCBS',
    payerName: 'OKLAHOMA BLUE CROSS',
    claimType: 'Institutional',
    State: 'OK',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2753',
    payerID: '731476619',
    eligibilityID: 'OKCAID',
    claimStatusID: '2753',
    payerName: 'OKLAHOMA MEDICAID',
    claimType: 'Professional',
    State: 'OK',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3594',
    payerID: '731476619',
    eligibilityID: 'OKCAID',
    claimStatusID: '3594',
    payerName: 'OKLAHOMA MEDICAID',
    claimType: 'Institutional',
    State: 'OK',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '1874',
    payerID: '73143',
    eligibilityID: 'OKCMCR',
    claimStatusID: '',
    payerName: 'COMMUNITY CARE MANAGED HEALTHCARE PLANS OF OK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8560',
    payerID: '73143',
    eligibilityID: 'OKCMCR',
    claimStatusID: '',
    payerName: 'COMMUNITY CARE MANAGED HEALTHCARE PLANS OF OK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'OKST',
    payerID: '',
    eligibilityID: 'OKSTE',
    claimStatusID: '',
    payerName: 'OKLAHOMA EMPLOYEES GROUP INS DIVISION HEALTHCHOICE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1837',
    payerID: '25133',
    eligibilityID: 'OMNCRE',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE ',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8538',
    payerID: '25133',
    eligibilityID: 'OMNCRE',
    claimStatusID: '',
    payerName: 'COVENTRY HEALTH CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7499',
    payerID: '54154',
    eligibilityID: 'OPTSNT',
    claimStatusID: '',
    payerName: 'SENTARA HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7567',
    payerID: '453',
    eligibilityID: 'OPTSNT',
    claimStatusID: '',
    payerName: 'SENTARA HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1481',
    payerID: 'ORDHS',
    eligibilityID: 'ORCAID',
    claimStatusID: '',
    payerName: 'OREGON MEDICAID',
    claimType: 'Professional',
    State: 'OR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5517',
    payerID: 'ORDHS',
    eligibilityID: 'ORCAID',
    claimStatusID: '',
    payerName: 'OREGON MEDICAID',
    claimType: 'Institutional',
    State: 'OR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2404',
    payerID: '851',
    eligibilityID: 'ORRBCS',
    claimStatusID: 'ORRBCS',
    payerName: 'OREGON BLUE SHIELD REGENCE',
    claimType: 'Professional',
    State: 'OR',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5516',
    payerID: '851',
    eligibilityID: 'ORRBCS',
    claimStatusID: 'ORRBCS',
    payerName: 'OREGON BLUE CROSS REGENCE',
    claimType: 'Institutional',
    State: 'OR',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7468',
    payerID: 'OSCAR',
    eligibilityID: 'OSCAR',
    claimStatusID: '',
    payerName: 'OSCAR HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9638',
    payerID: 'OSCAR',
    eligibilityID: 'OSCAR',
    claimStatusID: '',
    payerName: 'OSCAR HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3001',
    payerID: '29237',
    eligibilityID: 'OSLIC',
    claimStatusID: '',
    payerName: 'OLD SURETY LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8250',
    payerID: '29237',
    eligibilityID: 'OSLIC',
    claimStatusID: '',
    payerName: 'OLD SURETY LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1238',
    payerID: 'SX163',
    eligibilityID: 'OVRSEA',
    claimStatusID: '',
    payerName: 'TRICARE OVERSEAS PROGRAM',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6565',
    payerID: '12X46',
    eligibilityID: 'OVRSEA',
    claimStatusID: '',
    payerName: 'TRICARE OVERSEAS PROGRAM',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6485',
    payerID: '6111',
    eligibilityID: 'OXFORD',
    claimStatusID: 'OXFORD',
    payerName: 'OXFORD HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6549',
    payerID: '6111',
    eligibilityID: 'OXFORD',
    claimStatusID: 'OXFORD',
    payerName: 'OXFORD HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2413',
    payerID: '54771',
    eligibilityID: 'PABCBS',
    claimStatusID: 'PABCBS',
    payerName: 'PENNSYLVANIA BLUE SHIELD',
    claimType: 'Professional',
    State: 'PA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2534',
    payerID: '54771W',
    eligibilityID: 'PABCBS',
    claimStatusID: 'PABCBS',
    payerName: 'PENNSYLVANIA BLUE CROSS WESTERN',
    claimType: 'Institutional',
    State: 'PA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2718',
    payerID: '54771V',
    eligibilityID: 'PABCBS',
    claimStatusID: '',
    payerName: 'PENNSYLVANIA BLUE SHIELD CLARITY VISION',
    claimType: 'Professional',
    State: 'PA',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,E',
    Claims: 'Yes-Agreement',
    Remit: 'No',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3587',
    payerID: '54771C',
    eligibilityID: 'PABCBS',
    claimStatusID: 'PABCBS',
    payerName: 'PENNSYLVANIA BLUE CROSS CENTRAL',
    claimType: 'Institutional',
    State: 'PA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7130',
    payerID: '15460',
    eligibilityID: 'PABCBS',
    claimStatusID: '',
    payerName: 'PENNSYLVANIA FREEDOM BLUE MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7679',
    payerID: '15460',
    eligibilityID: 'PABCBS',
    claimStatusID: '',
    payerName: 'PENNSYLVANIA FREEDOM BLUE MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1491',
    payerID: '236003113',
    eligibilityID: 'PACAID',
    claimStatusID: '1491',
    payerName: 'PENNSYLVANIA MEDICAID',
    claimType: 'Professional',
    State: 'PA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5509',
    payerID: '236003113',
    eligibilityID: 'PACAID',
    claimStatusID: '5509',
    payerName: 'PENNSYLVANIA MEDICAID',
    claimType: 'Institutional',
    State: 'PA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2713',
    payerID: '361',
    eligibilityID: 'PACAPB',
    claimStatusID: '',
    payerName: 'CAPITAL BLUE CROSS - FACILITY CONTRACTED PROVIDERS',
    claimType: 'Professional',
    State: 'PA',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5599',
    payerID: '361',
    eligibilityID: 'PACAPB',
    claimStatusID: 'PACAPB',
    payerName: 'CAPITAL BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7705',
    payerID: '23045',
    eligibilityID: 'PACAPB',
    claimStatusID: 'PACAPB',
    payerName: 'CAPITAL BLUE CROSS - PHYSICIAN CONTRACTED PROVIDER',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'PAIB',
    payerID: '',
    eligibilityID: 'PAIB',
    claimStatusID: '',
    payerName: 'PAI BENEFITS (PLANNED ADMINISTRATORS INC.)',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1930',
    payerID: '95044',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'AMERIHEALTH NEW JERSEY/DELAWARE - HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2684',
    payerID: '93688',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'AMERIHEALTH DELAWARE PPO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,E',
    Claims: 'Yes-Agreement',
    Remit: 'No',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2781',
    payerID: '54704',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'PENNSYLVANIA INDEPENDENCE BLUE CROSS',
    claimType: 'Professional',
    State: 'PA',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2800',
    payerID: '95044',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'AMERIHEALTH NEW JERSEY/DELAWARE - HMO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3558',
    payerID: '60061',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'PENNSYLVANIA INDEPENDENCE AMERIHEALTH PPO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3559',
    payerID: '95056',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'KEYSTONE EAST HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3560',
    payerID: '54704',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'PENNSYLVANIA INDEPENDENCE BLUE CROSS',
    claimType: 'Institutional',
    State: 'PA',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4715',
    payerID: '93688',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'PENNSYLVANIA INDEPENDENCE AMERIHEALTH DE PPO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4716',
    payerID: '60061',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'PENNSYLVANIA INDEPENDENCE AMERIHEALTH NJ PPO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7417',
    payerID: '95056',
    eligibilityID: 'PAIBCE',
    claimStatusID: '',
    payerName: 'KEYSTONE EAST HMO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'PALH',
    payerID: '',
    eligibilityID: 'PALHMS ',
    claimStatusID: '',
    payerName: 'PROVIDENT AMERICAN LIFE and HEALTH INS MEDICARE SU',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2002',
    payerID: 'PASSE',
    eligibilityID: 'PASSE',
    claimStatusID: '',
    payerName: 'SUMMIT COMMUNITY CARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8134',
    payerID: 'PASSE',
    eligibilityID: 'PASSE',
    claimStatusID: '',
    payerName: 'SUMMIT COMMUNITY CARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6045',
    payerID: '55768',
    eligibilityID: 'PDMTHP',
    claimStatusID: 'PDMTHP',
    payerName: 'PIEDMONT COMMUNITY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9199',
    payerID: '55768',
    eligibilityID: 'PDMTHP',
    claimStatusID: 'PDMTHP',
    payerName: 'PIEDMONT COMMUNITY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: 'PDSA',
    payerID: '',
    eligibilityID: 'PDSAM',
    claimStatusID: '',
    payerName: 'PLAN de SOCIOS AUXILIA MUTUO',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7127',
    payerID: '660636242',
    eligibilityID: 'PDSHM',
    claimStatusID: '',
    payerName: 'PLAN de SALUD HOSPITAL MENONITA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7676',
    payerID: '660636242',
    eligibilityID: 'PDSHM',
    claimStatusID: '',
    payerName: 'PLAN de SALUD HOSPITAL MENONITA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4727',
    payerID: 'HT000158-001',
    eligibilityID: 'PEHP',
    claimStatusID: '',
    payerName: 'PUBLIC EMPLOYEE HEALTH PLAN (PEHP)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2923',
    payerID: '72126',
    eligibilityID: 'PEOPLE',
    claimStatusID: '',
    payerName: 'PEOPLES HEALTH NETWORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8455',
    payerID: '72126',
    eligibilityID: 'PEOPLE',
    claimStatusID: '',
    payerName: 'PEOPLES HEALTH NETWORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4657',
    payerID: '65391',
    eligibilityID: 'PFMCR',
    claimStatusID: 'PFMCR',
    payerName: 'PERFORMCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6183',
    payerID: '65391',
    eligibilityID: 'PFMCR',
    claimStatusID: 'PFMCR',
    payerName: 'PERFORMCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5006',
    payerID: '77350',
    eligibilityID: 'PHAMCD',
    claimStatusID: '',
    payerName: 'PROVIDENCE HEALTH ASSURANCE MEDICAID AFTER 123120',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8849',
    payerID: '77350',
    eligibilityID: 'PHAMCD',
    claimStatusID: '',
    payerName: 'PROVIDENCE HEALTH ASSURANCE MEDICAID AFTER 123120',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5029',
    payerID: '77500',
    eligibilityID: 'PHAMCR',
    claimStatusID: '',
    payerName: 'PROVIDENCE HEALTH ASSURANCE MEDICARE SUPPLEMENT',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '8877',
    payerID: '77500',
    eligibilityID: 'PHAMCR',
    claimStatusID: '',
    payerName: 'PROVIDENCE HEALTH ASSURANCE MEDICARE SUPPLEMENT',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '2106',
    payerID: '77003',
    eligibilityID: 'PHCMCO',
    claimStatusID: 'PHCMCO',
    payerName: 'AMERIHEALTH CARITAS FLORIDA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8963',
    payerID: '77003',
    eligibilityID: 'PHCMCO',
    claimStatusID: 'PHCMCO',
    payerName: 'AMERIHEALTH CARITAS FLORIDA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1936',
    payerID: '37330',
    eligibilityID: 'PHPMI',
    claimStatusID: 'PHPMI',
    payerName: 'PHYSICIANS HEALTH PLAN MID-MICHIGAN / PHP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2498',
    payerID: '37330',
    eligibilityID: 'PHPMI',
    claimStatusID: 'PHPMI',
    payerName: 'PHYSICIANS HEALTH PLAN MID-MICHIGAN / PHP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3044',
    payerID: '83276',
    eligibilityID: 'PHPMIM',
    claimStatusID: '',
    payerName: 'PHYSICIANS HEALTH PLAN of MICHIGAN MEDICARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8293',
    payerID: '83276',
    eligibilityID: 'PHPMIM',
    claimStatusID: '',
    payerName: 'PHYSICIANS HEALTH PLAN of MICHIGAN MEDICARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3596',
    payerID: '12399',
    eligibilityID: 'PHPNI',
    claimStatusID: 'PHPNI',
    payerName: 'PHYSICIAN HEALTH PLAN of NORTHERN INDIANA - PHP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3815',
    payerID: '12399',
    eligibilityID: 'PHPNI',
    claimStatusID: 'PHPNI',
    payerName: 'PHYSICIAN HEALTH PLAN of NORTHERN INDIANA - PHP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7218',
    payerID: '93082',
    eligibilityID: 'PHPNV',
    claimStatusID: 'PHPNV',
    payerName: 'PROMINENCE HEALTH PLAN of NEVADA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8635',
    payerID: '93082',
    eligibilityID: 'PHPNV',
    claimStatusID: 'PHPNV',
    payerName: 'PROMINENCE HEALTH PLAN of NEVADA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3889',
    payerID: '47027',
    eligibilityID: 'PHYSMT',
    claimStatusID: '',
    payerName: 'PHYSICIANS MUTUAL INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4508',
    payerID: '47027',
    eligibilityID: 'PHYSMT',
    claimStatusID: '',
    payerName: 'PHYSICIANS MUTUAL INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5855',
    payerID: '95397',
    eligibilityID: 'PIADMN',
    claimStatusID: '',
    payerName: 'PERSONAL INSURANCE ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8966',
    payerID: '95397',
    eligibilityID: 'PIADMN',
    claimStatusID: '',
    payerName: 'PERSONAL INSURANCE ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'PITT',
    payerID: '',
    eligibilityID: 'PITTMN',
    claimStatusID: '',
    payerName: 'PITTMAN and ASSOCIATES',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5692',
    payerID: '660592131',
    eligibilityID: 'PMC',
    claimStatusID: '',
    payerName: 'PREFERRED MEDICARE CHOICE (PMC)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6713',
    payerID: '660592131',
    eligibilityID: 'PMC',
    claimStatusID: '',
    payerName: 'PREFERRED MEDICARE CHOICE (PMC)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7232',
    payerID: '660592131GHP',
    eligibilityID: 'PMCHOR',
    claimStatusID: '',
    payerName: 'PREFERRED MEDICARE CHOICE (REFORM)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8649',
    payerID: '660592131GHP',
    eligibilityID: 'PMCHOR',
    claimStatusID: '',
    payerName: 'PREFERRED MEDICARE CHOICE (REFORM)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6861',
    payerID: '4218',
    eligibilityID: 'PNAIC',
    claimStatusID: '',
    payerName: 'PAN AMERICAN LIFE INSURANCE GROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7642',
    payerID: '4218',
    eligibilityID: 'PNAIC',
    claimStatusID: '',
    payerName: 'PAN AMERICAN LIFE INSURANCE GROUP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'PFRG',
    payerID: '61271',
    eligibilityID: 'PRFGRP',
    claimStatusID: '',
    payerName: 'PRINCIPAL FINANCIAL GROUP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'PRFG',
    payerID: '61271',
    eligibilityID: 'PRFGRP',
    claimStatusID: '',
    payerName: 'PRINCIPAL FINANCIAL GROUP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3410',
    payerID: '411479744',
    eligibilityID: 'PRFONE',
    claimStatusID: '',
    payerName: 'PREFERRED ONE of MINNESOTA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8578',
    payerID: '411479744',
    eligibilityID: 'PRFONE',
    claimStatusID: '',
    payerName: 'PREFERRED ONE of MINNESOTA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'P, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5030',
    payerID: '83352',
    eligibilityID: 'PRHFFL',
    claimStatusID: 'PRHFFL',
    payerName: 'PROMINENCE HEALTHFIRST OF FLORIDA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8878',
    payerID: '83352',
    eligibilityID: 'PRHFFL',
    claimStatusID: 'PRHFFL',
    payerName: 'PROMINENCE HEALTHFIRST OF FLORIDA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3591',
    payerID: 'PRIORITY HEALTH',
    eligibilityID: 'PRIRTY',
    claimStatusID: '3591',
    payerName: 'PRIORITY HEALTH of MICHIGAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7459',
    payerID: 'PRIORITY',
    eligibilityID: 'PRIRTY',
    claimStatusID: '7459',
    payerName: 'PRIORITY HEALTH of MICHIGAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1763',
    payerID: '66917',
    eligibilityID: 'PRKLND',
    claimStatusID: '',
    payerName: 'PARKLAND COMMUNITY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8967',
    payerID: 'U6917',
    eligibilityID: 'PRKLND',
    claimStatusID: '',
    payerName: 'PARKLAND COMMUNITY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3275',
    payerID: '4198872500',
    eligibilityID: 'PRMNT',
    claimStatusID: '3275',
    payerName: 'PARAMOUNT',
    claimType: 'Professional',
    State: 'OH',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5977',
    payerID: '4198872500',
    eligibilityID: 'PRMNT',
    claimStatusID: '5977',
    payerName: 'PARAMOUNT',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1565',
    payerID: '61604',
    eligibilityID: 'PRMWST',
    claimStatusID: 'PRMWST',
    payerName: 'PRIME WEST',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2786',
    payerID: '61604',
    eligibilityID: 'PRMWST',
    claimStatusID: 'PRMWST',
    payerName: 'PRIME WEST',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1540',
    payerID: 'SX133',
    eligibilityID: 'PROVHP',
    claimStatusID: '',
    payerName: 'PROVIDENCE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1540',
    payerID: 'SX133',
    eligibilityID: 'PROVHP',
    claimStatusID: '',
    payerName: 'PROVIDENCE HEALTH PLAN',
    claimType: 'Institutional',
    State: 'OR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4498',
    payerID: 'SX133',
    eligibilityID: 'PROVHP',
    claimStatusID: '',
    payerName: 'PROVIDENCE HEALTH PLAN',
    claimType: 'Professional',
    State: 'OR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4498',
    payerID: 'SX133',
    eligibilityID: 'PROVHP',
    claimStatusID: '',
    payerName: 'PROVIDENCE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6831',
    payerID: '973MA',
    eligibilityID: 'PRSSA',
    claimStatusID: '',
    payerName: 'TRIPLE-S ADVANVTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7611',
    payerID: '973MA',
    eligibilityID: 'PRSSA',
    claimStatusID: '',
    payerName: 'TRIPLE-S ADVANVTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2756',
    payerID: '973',
    eligibilityID: 'PRSSS',
    claimStatusID: '',
    payerName: 'TRIPLE-S SALUD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3543',
    payerID: '973',
    eligibilityID: 'PRSSS',
    claimStatusID: '',
    payerName: 'TRIPLE-S SALUD',
    claimType: 'Institutional',
    State: 'PR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3902',
    payerID: 'PHC100680301406',
    eligibilityID: 'PRTHP',
    claimStatusID: '',
    payerName: 'PARTNERSHIP HEALTHPLAN of CALIFORNIA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7497',
    payerID: 'PHC100680301406',
    eligibilityID: 'PRTHP',
    claimStatusID: '',
    payerName: 'PARTNERSHIP HEALTHPLAN of CALIFORNIA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4794',
    payerID: '20416',
    eligibilityID: 'PSCSOL',
    claimStatusID: '',
    payerName: 'PACIFICSOURCE COMMUNITY HEALTH SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4972',
    payerID: '20416',
    eligibilityID: 'PSCSOL',
    claimStatusID: '',
    payerName: 'PACIFICSOURCE COMMUNITY HEALTH SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3627',
    payerID: '93031',
    eligibilityID: 'PSHLTH',
    claimStatusID: 'PSHLTH',
    payerName: 'PACIFICSOURCE ADMINISTRATORS (PSA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5441',
    payerID: '93029',
    eligibilityID: 'PSHLTH',
    claimStatusID: 'PSHLTH',
    payerName: 'PACIFICSOURCE HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5805',
    payerID: '93031',
    eligibilityID: 'PSHLTH',
    claimStatusID: 'PSHLTH',
    payerName: 'PACIFICSOURCE ADMINISTRATORS (PSA)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6575',
    payerID: '93029',
    eligibilityID: 'PSHLTH',
    claimStatusID: 'PSHLTH',
    payerName: 'PACIFICSOURCE HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4627',
    payerID: '20377',
    eligibilityID: 'PSMED',
    claimStatusID: 'PSMED',
    payerName: 'PACIFICSOURCE MEDICARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6147',
    payerID: '20377',
    eligibilityID: 'PSMED',
    claimStatusID: 'PSMED',
    payerName: 'PACIFICSOURCE MEDICARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1004',
    payerID: '66008',
    eligibilityID: 'PSPRT',
    claimStatusID: '',
    payerName: 'PASSPORT ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7829',
    payerID: '66008',
    eligibilityID: 'PSPRT',
    claimStatusID: '',
    payerName: 'PASSPORT ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6875',
    payerID: '73067',
    eligibilityID: 'QTCORP',
    claimStatusID: '',
    payerName: 'QUIKTRIP CORPORATION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8937',
    payerID: '73067',
    eligibilityID: 'QTCORP',
    claimStatusID: '',
    payerName: 'QUIKTRIP CORPORATION',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7264',
    payerID: '39180',
    eligibilityID: 'QUARTZ',
    claimStatusID: 'QUARTZ',
    payerName: 'QUARTZ ASO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '8677',
    payerID: '39180',
    eligibilityID: 'QUARTZ',
    claimStatusID: 'QUARTZ',
    payerName: 'QUARTZ ASO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '2593',
    payerID: '35174',
    eligibilityID: 'QUCHO',
    claimStatusID: '',
    payerName: 'QUALCHOICE of ARKANSAS',
    claimType: 'Institutional',
    State: 'AR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5428',
    payerID: '35174',
    eligibilityID: 'QUCHO',
    claimStatusID: '',
    payerName: 'QUALCHOICE of ARKANSAS',
    claimType: 'Professional',
    State: 'AR',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'RELS',
    payerID: '',
    eligibilityID: 'RELSTD',
    claimStatusID: '',
    payerName: 'RELIANCE STANDARD LIFE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2682',
    payerID: '73066',
    eligibilityID: 'RESNI',
    claimStatusID: '',
    payerName: 'RESERVE NATIONAL INSURANCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5812',
    payerID: '73066',
    eligibilityID: 'RESNI',
    claimStatusID: '',
    payerName: 'RESERVE NATIONAL INSURANCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1209',
    payerID: 'RGA01',
    eligibilityID: 'RGADM',
    claimStatusID: '',
    payerName: 'REGENCE GROUP ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3577',
    payerID: 'RGA01',
    eligibilityID: 'RGADM',
    claimStatusID: '',
    payerName: 'REGENCE GROUP ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5639',
    payerID: 'RHA01',
    eligibilityID: 'RHA01',
    claimStatusID: '',
    payerName: 'RESOLVE HEALTH PLAN ADMINISTRATORS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6269',
    payerID: 'RHA01',
    eligibilityID: 'RHA01',
    claimStatusID: '',
    payerName: 'RESOLVE HEALTH PLAN ADMINISTRATORS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5579',
    payerID: '370',
    eligibilityID: 'RIBCBS',
    claimStatusID: '',
    payerName: 'RHODE ISLAND BLUE CROSS',
    claimType: 'Institutional',
    State: 'RI',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7420',
    payerID: '870',
    eligibilityID: 'RIBCBS',
    claimStatusID: '',
    payerName: 'RHODE ISLAND BLUE SHIELD',
    claimType: 'Professional',
    State: 'RI',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7420',
    payerID: '870',
    eligibilityID: 'RIBCBS',
    claimStatusID: '',
    payerName: 'RHODE ISLAND BLUE SHIELD',
    claimType: 'Professional',
    State: 'RI',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2487',
    payerID: '5047',
    eligibilityID: 'RINHP',
    claimStatusID: '',
    payerName: 'NEIGHBORHOOD HEALTH PLAN of RHODE ISLAND',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3645',
    payerID: '5047',
    eligibilityID: 'RINHP',
    claimStatusID: '',
    payerName: 'NEIGHBORHOOD HEALTH PLAN of RHODE ISLAND',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3556',
    payerID: '840614905',
    eligibilityID: 'RKYMHP',
    claimStatusID: '',
    payerName: 'ROCKY MOUNTAIN HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7470',
    payerID: '840614905',
    eligibilityID: 'RKYMHP',
    claimStatusID: '',
    payerName: 'ROCKY MOUNTAIN HMO',
    claimType: 'Professional',
    State: 'CO',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4678',
    payerID: '74205',
    eligibilityID: 'RTCRE',
    claimStatusID: '',
    payerName: 'RIGHTCARE SCOTT & WHITE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6182',
    payerID: '74205',
    eligibilityID: 'RTCRE',
    claimStatusID: '',
    payerName: 'RIGHTCARE SCOTT & WHITE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7262',
    payerID: 'RX001',
    eligibilityID: 'RXINF',
    claimStatusID: '',
    payerName: 'TRANSACTRX INFUSION and SPECIALTY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7241',
    payerID: 'PARTD',
    eligibilityID: 'RXPTD',
    claimStatusID: '',
    payerName: 'TRANSACTRX PART D',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'RYLN',
    payerID: '',
    eligibilityID: 'RYLNB',
    claimStatusID: '',
    payerName: 'ROYAL NEIGHBORS of AMERICA',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1116',
    payerID: '37259',
    eligibilityID: 'SAMBAN',
    claimStatusID: 'SAMBAN',
    payerName: 'SPECIAL AGENTS MUTUAL BENEFITS ASSOCIATION (SAMBA)',
    claimType: '',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5005',
    payerID: '37259',
    eligibilityID: 'SAMBAN',
    claimStatusID: 'SAMBAN',
    payerName: 'SPECIAL AGENTS MUTUAL BENEFITS ASSOCIATION (SAMBA)',
    claimType: '',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'SAMB',
    payerID: '',
    eligibilityID: 'SAMBAN',
    claimStatusID: '',
    payerName: 'SPECIAL AGENT MUTUAL BENEFIT',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2254',
    payerID: '88030',
    eligibilityID: 'SAWHP',
    claimStatusID: '',
    payerName: 'SCOTT and WHITE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7907',
    payerID: '88030',
    eligibilityID: 'SAWHP',
    claimStatusID: '',
    payerName: 'SCOTT and WHITE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1559',
    payerID: '401',
    eligibilityID: 'SCBCBS',
    claimStatusID: '1559',
    payerName: 'SOUTH CAROLINA BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1559',
    payerID: '401',
    eligibilityID: 'SCBCBS',
    claimStatusID: '1559',
    payerName: 'SOUTH CAROLINA BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2410',
    payerID: '401',
    eligibilityID: 'SCBCBS',
    claimStatusID: '2410',
    payerName: 'SOUTH CAROLINA BLUE SHIELD',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2410',
    payerID: '401',
    eligibilityID: 'SCBCBS',
    claimStatusID: '2410',
    payerName: 'SOUTH CAROLINA BLUE SHIELD',
    claimType: 'Professional',
    State: 'SC',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1484',
    payerID: 'SCXIX',
    eligibilityID: 'SCCAID',
    claimStatusID: '1484',
    payerName: 'SOUTH CAROLINA MEDICAID',
    claimType: 'Professional',
    State: 'SC',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5540',
    payerID: 'SCXIX',
    eligibilityID: 'SCCAID',
    claimStatusID: '5540',
    payerName: 'SOUTH CAROLINA MEDICAID',
    claimType: 'Institutional',
    State: 'SC',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3613',
    payerID: '24077',
    eligibilityID: 'SCFHP',
    claimStatusID: '',
    payerName: 'SANTA CLARA FAMILY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5181',
    payerID: '24077',
    eligibilityID: 'SCFHP',
    claimStatusID: '',
    payerName: 'SANTA CLARA FAMILY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6794',
    payerID: '46299',
    eligibilityID: 'SCMLNA',
    claimStatusID: 'SCMLNA',
    payerName: 'MOLINA HEALTHCARE of SOUTH CAROLINA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6998',
    payerID: '46299',
    eligibilityID: 'SCMLNA',
    claimStatusID: 'SCMLNA',
    payerName: 'MOLINA HEALTHCARE of SOUTH CAROLINA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2983',
    payerID: 'SCAN1',
    eligibilityID: 'SCNHMO',
    claimStatusID: '',
    payerName: 'SCAN HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4464',
    payerID: 'SCAN1',
    eligibilityID: 'SCNHMO',
    claimStatusID: '',
    payerName: 'SCAN HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2890',
    payerID: '23285',
    eligibilityID: 'SCSLHT',
    claimStatusID: 'SCSLHT',
    payerName: 'FIRST CHOICE BY SELECT HEALTH OF SOUTH CAROLINA',
    claimType: 'Professional',
    State: 'SC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7544',
    payerID: '23285',
    eligibilityID: 'SCSLHT',
    claimStatusID: 'SCSLHT',
    payerName: 'FIRST CHOICE BY SELECT HEALTH OF SOUTH CAROLINA',
    claimType: 'Institutional',
    State: 'SC',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4478',
    payerID: 'SD48MED',
    eligibilityID: 'SDCAID',
    claimStatusID: '',
    payerName: 'SOUTH DAKOTA MEDICAID',
    claimType: 'Professional',
    State: 'SD',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5591',
    payerID: 'SD48MED',
    eligibilityID: 'SDCAID',
    claimStatusID: '',
    payerName: 'SOUTH DAKOTA MEDICAID',
    claimType: 'Institutional',
    State: 'SD',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4588',
    payerID: '39045',
    eligibilityID: 'SECHP',
    claimStatusID: '',
    payerName: 'SECURITY HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5427',
    payerID: '39045',
    eligibilityID: 'SECHP',
    claimStatusID: '',
    payerName: 'SECURITY HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2028',
    payerID: '52214',
    eligibilityID: 'SELMN',
    claimStatusID: 'SELMN',
    payerName: 'SELMAN and COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: '',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8164',
    payerID: '52214',
    eligibilityID: 'SELMN',
    claimStatusID: 'SELMN',
    payerName: 'SELMAN and COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: '',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6633',
    payerID: '943226498',
    eligibilityID: 'SFHP',
    claimStatusID: '',
    payerName: 'SAN FRANCISCO HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6736',
    payerID: '943226498',
    eligibilityID: 'SFHP',
    claimStatusID: '',
    payerName: 'SAN FRANCISCO HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7226',
    payerID: 'SHP01',
    eligibilityID: 'SHARP',
    claimStatusID: '',
    payerName: 'SHARP HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8643',
    payerID: 'SHP01',
    eligibilityID: 'SHARP',
    claimStatusID: '',
    payerName: 'SHARP HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1994',
    payerID: '76342',
    eligibilityID: 'SIERA',
    claimStatusID: '',
    payerName: 'SIERRA HEALTH SERVICES, INC. (CLAIMS)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3880',
    payerID: '76342',
    eligibilityID: 'SIERA',
    claimStatusID: '',
    payerName: 'SIERRA HEALTH SERVICES, INC. (CLAIMS)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2542',
    payerID: '77153',
    eligibilityID: 'SIHO',
    claimStatusID: '',
    payerName: 'SIHO INSURANCE SERVICES',
    claimType: 'Institutional',
    State: 'IN',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4406',
    payerID: '77153',
    eligibilityID: 'SIHO',
    claimStatusID: '',
    payerName: 'SIHO INSURANCE SERVICES',
    claimType: 'Professional',
    State: 'IN',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1982',
    payerID: 'SISCO',
    eligibilityID: 'SISCO',
    claimStatusID: '',
    payerName: 'SISCO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2491',
    payerID: 'SISCO',
    eligibilityID: 'SISCO',
    claimStatusID: '',
    payerName: 'SISCO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3469',
    payerID: '68035',
    eligibilityID: 'SJHP',
    claimStatusID: '',
    payerName: 'HEALTH PLAN of SAN JOAQUIN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4682',
    payerID: '68035',
    eligibilityID: 'SJHP',
    claimStatusID: '',
    payerName: 'HEALTH PLAN of SAN JOAQUIN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5620',
    payerID: '73099',
    eligibilityID: 'SLAICO',
    claimStatusID: '',
    payerName: 'STANDARD LIFE and ACCIDENT INS CO-Secondary Claims',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6255',
    payerID: '73099',
    eligibilityID: 'SLAICO',
    claimStatusID: '',
    payerName: 'STANDARD LIFE and ACCIDENT INS CO-Secondary Claims',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: '',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1096',
    payerID: 'SMPLY',
    eligibilityID: 'SMPLH',
    claimStatusID: 'SMPLH',
    payerName: 'SIMPLY HEALTHCARE PLANS, INC.',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8124',
    payerID: 'SMPLY',
    eligibilityID: 'SMPLH',
    claimStatusID: 'SMPLH',
    payerName: 'SIMPLY HEALTHCARE PLANS, INC.',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2116',
    payerID: 'SHP',
    eligibilityID: 'SNFDHP',
    claimStatusID: '',
    payerName: 'SANFORD HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5961',
    payerID: 'SHP',
    eligibilityID: 'SNFDHP',
    claimStatusID: '',
    payerName: 'SANFORD HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3443',
    payerID: '83035',
    eligibilityID: 'SRWH',
    claimStatusID: '',
    payerName: 'SENIOR WHOLE HEALTH of NEW YORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3916',
    payerID: '83035',
    eligibilityID: 'SRWH',
    claimStatusID: '',
    payerName: 'SENIOR WHOLE HEALTH of NEW YORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3916',
    payerID: '83035',
    eligibilityID: 'SRWH',
    claimStatusID: '',
    payerName: 'SENIOR WHOLE HEALTH of NEW YORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1130',
    payerID: '87020',
    eligibilityID: 'SSLCO',
    claimStatusID: '',
    payerName: 'SENTINEL SECURITY LIFE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4055',
    payerID: '87020',
    eligibilityID: 'SSLCO',
    claimStatusID: '',
    payerName: 'SENTINEL SECURITY LIFE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'STDI',
    payerID: '',
    eligibilityID: 'STDINS',
    claimStatusID: '',
    payerName: 'STANDARD INSURANCE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'STDN',
    payerID: '',
    eligibilityID: 'STDINY',
    claimStatusID: '',
    payerName: 'STANDARD INSURANCE of NEW YORK',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4593',
    payerID: '74227',
    eligibilityID: 'STDNT',
    claimStatusID: 'STDNT',
    payerName: 'UNITEDHEALTHCARE STUDENT RESOURCES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6465',
    payerID: '74227',
    eligibilityID: 'STDNT',
    claimStatusID: 'STDNT',
    payerName: 'UNITEDHEALTHCARE STUDENT RESOURCES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5419',
    payerID: '31053',
    eligibilityID: 'STFARM',
    claimStatusID: '',
    payerName: 'STATE FARM INSURANCE COMPANIES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6573',
    payerID: '31053',
    eligibilityID: 'STFARM',
    claimStatusID: '',
    payerName: 'STATE FARM INSURANCE COMPANIES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2956',
    payerID: '88029',
    eligibilityID: 'STMRH',
    claimStatusID: '',
    payerName: 'PROMINENCE HEALTH PLAN PPO/HMO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4256',
    payerID: '88029',
    eligibilityID: 'STMRH',
    claimStatusID: '',
    payerName: 'PROMINENCE HEALTH PLAN PPO/HMO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7219',
    payerID: '80095',
    eligibilityID: 'STMRHT',
    claimStatusID: 'STMRHT',
    payerName: 'PROMINENCE HEALTH PLAN of TEXAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8636',
    payerID: '80095',
    eligibilityID: 'STMRHT',
    claimStatusID: 'STMRHT',
    payerName: 'PROMINENCE HEALTH PLAN of TEXAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1755',
    payerID: '95202',
    eligibilityID: 'SUMCHP',
    claimStatusID: '',
    payerName: 'SUMMACARE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2544',
    payerID: '95202',
    eligibilityID: 'SUMCHP',
    claimStatusID: '',
    payerName: 'SUMMACARE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7781',
    payerID: '60624',
    eligibilityID: 'SUTAET',
    claimStatusID: '',
    payerName: 'SUTTER and AETNA INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8509',
    payerID: '60624',
    eligibilityID: 'SUTAET',
    claimStatusID: '',
    payerName: 'SUTTER and AETNA INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2845',
    payerID: '13407',
    eligibilityID: 'SVMNY',
    claimStatusID: '',
    payerName: 'ST. VINCENT CATHOLIC MEDICAL CENTER NEW YORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7542',
    payerID: '13407',
    eligibilityID: 'SVMNY',
    claimStatusID: '',
    payerName: 'ST. VINCENT CATHOLIC MEDICAL CENTER NEW YORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6085',
    payerID: 'SWHMA',
    eligibilityID: 'SWHMA',
    claimStatusID: '',
    payerName: 'SENIOR WHOLE HEALTH of MASSACHUSETTS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '9246',
    payerID: 'SWHMA',
    eligibilityID: 'SWHMA',
    claimStatusID: '',
    payerName: 'SENIOR WHOLE HEALTH of MASSACHUSETTS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: 'No'
  },
  {
    cHCPayerID: '1082',
    payerID: 'TRCLF',
    eligibilityID: 'TALI',
    claimStatusID: '',
    payerName: 'TRANSAMERICA PREMIER LIFE INSURANCE COMPANY',
    claimType: 'Institutional',
    State: 'IA',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8109',
    payerID: 'TRCLF',
    eligibilityID: 'TALI',
    claimStatusID: '',
    payerName: 'TRANSAMERICA PREMIER LIFE INSURANCE COMPANY',
    claimType: 'Professional',
    State: 'IA',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'TRAN',
    payerID: '',
    eligibilityID: 'TALI',
    claimStatusID: '',
    payerName: 'TRANSAMERICA LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1190',
    payerID: 'THRIV',
    eligibilityID: 'THRVNT',
    claimStatusID: '',
    payerName: 'THRIVENT FINANCIAL',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1191',
    payerID: '30167',
    eligibilityID: 'THRVNT',
    claimStatusID: '',
    payerName: 'THRIVENT FINANCIAL AID ASSOC for LUTHERAN MEDICARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1192',
    payerID: '30166',
    eligibilityID: 'THRVNT',
    claimStatusID: '',
    payerName: 'THRIVENT FINANCIAL LUTHERAN BROTHERHOOD MEDICARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2564',
    payerID: 'THRIV',
    eligibilityID: 'THRVNT',
    claimStatusID: '',
    payerName: 'THRIVENT FINANCIAL',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2565',
    payerID: '30167',
    eligibilityID: 'THRVNT',
    claimStatusID: '',
    payerName: 'THRIVENT FINANCIAL AID ASSOC for LUTHERAN MEDICARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2566',
    payerID: '30166',
    eligibilityID: 'THRVNT',
    claimStatusID: '',
    payerName: 'THRIVENT FINANCIAL LUTHERAN BROTHERHOOD MEDICARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'TMGN',
    payerID: '',
    eligibilityID: 'TMGNHI',
    claimStatusID: '',
    payerName: 'TMG NETWORK HEALTH INSURANCE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2401',
    payerID: '890',
    eligibilityID: 'TNBCBS',
    claimStatusID: 'TNBCBS',
    payerName: 'TENNESSEE BLUE SHIELD',
    claimType: 'Professional',
    State: 'TN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5513',
    payerID: '390',
    eligibilityID: 'TNBCBS',
    claimStatusID: 'TNBCBS',
    payerName: 'TENNESSEE BLUE CROSS',
    claimType: 'Institutional',
    State: 'TN',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2495',
    payerID: '890',
    eligibilityID: 'TNCAID',
    claimStatusID: 'TNBCBS',
    payerName: 'TENNESSEE BLUECARE/TENNCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5514',
    payerID: '390',
    eligibilityID: 'TNCAID',
    claimStatusID: 'TNBCBS',
    payerName: 'TENNESSEE BLUECARE/TENNCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'TOUC',
    payerID: '',
    eligibilityID: 'TOUCHS',
    claimStatusID: '',
    payerName: 'TOUCHSTONE HEALTH PSO',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4749',
    payerID: 'TDFIC',
    eligibilityID: 'TRCRU',
    claimStatusID: '',
    payerName: 'TRICARE for LIFE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5913',
    payerID: 'TDFIC',
    eligibilityID: 'TRCRU',
    claimStatusID: '',
    payerName: 'TRICARE for LIFE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5648',
    payerID: '12C01',
    eligibilityID: 'TRICAR',
    claimStatusID: 'TRICAR',
    payerName: 'TRICARE - WEST REGION',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6286',
    payerID: 'SCWI0',
    eligibilityID: 'TRICAR',
    claimStatusID: 'TRICAR',
    payerName: 'TRICARE - WEST REGION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1253',
    payerID: 'TREST',
    eligibilityID: 'TRICRE',
    claimStatusID: '',
    payerName: 'TRICARE - EAST REGION',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4585',
    payerID: 'TREST',
    eligibilityID: 'TRICRE',
    claimStatusID: '',
    payerName: 'TRICARE - EAST REGION',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'TRNA',
    payerID: '',
    eligibilityID: 'TRNAF',
    claimStatusID: '',
    payerName: 'TRANSAMERICA FINANCIAL LIFE INSURANCE COMPANY',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4541',
    payerID: '61425',
    eligibilityID: 'TRSMRK',
    claimStatusID: '',
    payerName: 'TRUSTMARK SMALL BUSINESS BENEFITS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6401',
    payerID: '61425',
    eligibilityID: 'TRSMRK',
    claimStatusID: '',
    payerName: 'TRUSTMARK SMALL BUSINESS BENEFITS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7803',
    payerID: '82288',
    eligibilityID: 'TRUNM',
    claimStatusID: '',
    payerName: 'TRUE HEALTH NEW MEXICO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8572',
    payerID: '82288',
    eligibilityID: 'TRUNM',
    claimStatusID: '',
    payerName: 'TRUE HEALTH NEW MEXICO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2438',
    payerID: '170558746',
    eligibilityID: 'TUFTS',
    claimStatusID: '',
    payerName: 'TUFTS ASSOCIATED HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8504',
    payerID: '170558746',
    eligibilityID: 'TUFTS',
    claimStatusID: '',
    payerName: 'TUFTS ASSOCIATED HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1406',
    payerID: '84980',
    eligibilityID: 'TXBCBS',
    claimStatusID: 'TXBCBS',
    payerName: 'TEXAS BLUE SHIELD',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1406',
    payerID: '84980',
    eligibilityID: 'TXBCBS',
    claimStatusID: 'TXBCBS',
    payerName: 'TEXAS BLUE SHIELD',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5501',
    payerID: '84980',
    eligibilityID: 'TXBCBS',
    claimStatusID: 'TXBCBS',
    payerName: 'TEXAS BLUE CROSS',
    claimType: 'Institutional',
    State: 'TX',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1470',
    payerID: '617591011C21P',
    eligibilityID: 'TXCAID',
    claimStatusID: '1470',
    payerName: 'TEXAS MEDICAID',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R,CS',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5500',
    payerID: '617591011C21P',
    eligibilityID: 'TXCAID',
    claimStatusID: '5500',
    payerName: 'TEXAS MEDICAID',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R,CS',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5500',
    payerID: '617591011C21P',
    eligibilityID: 'TXCAID',
    claimStatusID: '5500',
    payerName: 'TEXAS MEDICAID',
    claimType: 'Institutional',
    State: 'TX',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R,CS',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1729',
    payerID: '76048',
    eligibilityID: 'TXCHIP',
    claimStatusID: '',
    payerName: 'TEXAS CHILDRENS HEALTH PLAN - CHIP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6583',
    payerID: '76048',
    eligibilityID: 'TXCHIP',
    claimStatusID: '',
    payerName: 'TEXAS CHILDRENS HEALTH PLAN - CHIP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4226',
    payerID: '20554',
    eligibilityID: 'TXMLNA',
    claimStatusID: 'TXMLNA',
    payerName: 'MOLINA HEALTHCARE of TEXAS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4957',
    payerID: '20554',
    eligibilityID: 'TXMLNA',
    claimStatusID: 'TXMLNA',
    payerName: 'MOLINA HEALTHCARE of TEXAS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1431',
    payerID: '94265',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'MEDICA HEALTH PLAN',
    claimType: 'Professional',
    State: 'MN',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1431',
    payerID: '94265',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'MEDICA HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2240',
    payerID: '65088',
    eligibilityID: 'UHC',
    claimStatusID: '',
    payerName: 'PREFERRED CARE PARTNERS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2809',
    payerID: '25175',
    eligibilityID: 'UHC',
    claimStatusID: 'TRHPLN',
    payerName: 'UNITEDHEALTHCARE COMMUNITY PLAN of OHIO',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'AR',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'WI',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'MO',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'TX',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'SC',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'KY',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'FL',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'OH',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'WA',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3429',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Professional',
    State: 'GA',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'MO',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'WA',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'TX',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'GA',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'AR',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'OH',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'FL',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'SC',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'WI',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3550',
    payerID: '87726',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'UNITED HEALTHCARE',
    claimType: 'Institutional',
    State: 'KY',
    connectivityAvailable: 'B, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3929',
    payerID: '65088',
    eligibilityID: 'UHC',
    claimStatusID: '',
    payerName: 'PREFERRED CARE PARTNERS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4243',
    payerID: '78857',
    eligibilityID: 'UHC',
    claimStatusID: '',
    payerName: 'MEDICA HEALTHCARE PLAN of FLORIDA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5468',
    payerID: '95958',
    eligibilityID: 'UHC',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE WEST - PACIFICARE of WASHINGTON',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7531',
    payerID: '25175',
    eligibilityID: 'UHC',
    claimStatusID: 'TRHPLN',
    payerName: 'UNITEDHEALTHCARE COMMUNITY PLAN of OHIO',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7641',
    payerID: '78857',
    eligibilityID: 'UHC',
    claimStatusID: '',
    payerName: 'MEDICA HEALTHCARE PLAN of FLORIDA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8598',
    payerID: '94265',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'MEDICA HEALTH PLAN',
    claimType: 'Institutional',
    State: 'MN',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8598',
    payerID: '94265',
    eligibilityID: 'UHC',
    claimStatusID: 'UHC',
    payerName: 'MEDICA HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8956',
    payerID: '95958',
    eligibilityID: 'UHC',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE WEST - PACIFICARE of OREGON',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1886',
    payerID: '95467',
    eligibilityID: 'UHCGLH',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE COMMUNITY PLAN of MICHIGAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8577',
    payerID: '95467',
    eligibilityID: 'UHCGLH',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE COMMUNITY PLAN of MICHIGAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1227',
    payerID: '86050',
    eligibilityID: 'UHCMO',
    claimStatusID: 'UHCMO',
    payerName: 'UNITEDHEALTHCARE COMMUNITY PLAN of MISSOURI',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4527',
    payerID: '86050',
    eligibilityID: 'UHCMO',
    claimStatusID: 'UHCMO',
    payerName: 'UNITEDHEALTHCARE COMMUNITY PLAN of MISSOURI',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'UHSE',
    payerID: '',
    eligibilityID: 'UHSEC',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE SECURE HORIZONS - MEDICARE SUPP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6868',
    payerID: '77022',
    eligibilityID: 'ULTHP',
    claimStatusID: 'ULTHP',
    payerName: 'ULTIMATE HEALTH PLANS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7646',
    payerID: '77022',
    eligibilityID: 'ULTHP',
    claimStatusID: 'ULTHP',
    payerName: 'ULTIMATE HEALTH PLANS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4590',
    payerID: '39026',
    eligibilityID: 'UMRWAU',
    claimStatusID: 'UMRWAU',
    payerName: 'UNITED MEDICAL RESOURCES',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5402',
    payerID: '39026',
    eligibilityID: 'UMRWAU',
    claimStatusID: 'UMRWAU',
    payerName: 'UNITED MEDICAL RESOURCES',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1758',
    payerID: '52180',
    eligibilityID: 'UMWA',
    claimStatusID: '',
    payerName: 'UMWA HEALTH and RETIREMENT FUNDS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2991',
    payerID: '52180',
    eligibilityID: 'UMWA',
    claimStatusID: '',
    payerName: 'UMWA HEALTH and RETIREMENT FUNDS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'UNHI',
    payerID: '',
    eligibilityID: 'UNHIS',
    claimStatusID: '',
    payerName: 'UNITEDHEALTHCARE SHARED SERVICES',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4589',
    payerID: '80314',
    eligibilityID: 'UNICAR',
    claimStatusID: 'UNICAR ',
    payerName: 'UNICARE (TX, MA, KS, WV, RI, IL)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8473',
    payerID: '80314',
    eligibilityID: 'UNICAR',
    claimStatusID: 'UNICAR ',
    payerName: 'UNICARE (TX, MA, KS, WV, RI, IL)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6871',
    payerID: '45437',
    eligibilityID: 'UNIVAZ',
    claimStatusID: '',
    payerName: 'UNIVERSITY HEALTHCARE MARKETPLACE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8605',
    payerID: '45437',
    eligibilityID: 'UNIVAZ',
    claimStatusID: '',
    payerName: 'UNIVERSITY HEALTHCARE MARKETPLACE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2779',
    payerID: 'UNINW',
    eligibilityID: 'UNIVNY',
    claimStatusID: '',
    payerName: 'UNIVERA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2779',
    payerID: 'UNINW',
    eligibilityID: 'UNIVNY',
    claimStatusID: '',
    payerName: 'UNIVERA',
    claimType: 'Professional',
    State: 'NY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3598',
    payerID: 'UNINW',
    eligibilityID: 'UNIVNY',
    claimStatusID: '',
    payerName: 'UNIVERA',
    claimType: 'Institutional',
    State: 'NY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3598',
    payerID: 'UNINW',
    eligibilityID: 'UNIVNY',
    claimStatusID: '',
    payerName: 'UNIVERA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5084',
    payerID: '92916',
    eligibilityID: 'UNTDA',
    claimStatusID: '',
    payerName: 'UNITED AMERICAN INSURANCE COMPANY',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8179',
    payerID: '92916',
    eligibilityID: 'UNTDA',
    claimStatusID: '',
    payerName: 'UNITED AMERICAN INSURANCE COMPANY',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3824',
    payerID: '41194',
    eligibilityID: 'UNTDRN',
    claimStatusID: '',
    payerName: 'OPTUMHEALTH CARE SOLUTIONS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9513',
    payerID: '41194',
    eligibilityID: 'UNTDRN',
    claimStatusID: '',
    payerName: 'OPTUMHEALTH CARE SOLUTIONS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2791',
    payerID: '1UNTY',
    eligibilityID: 'UNTYHP',
    claimStatusID: 'UNTYHP',
    payerName: 'UNITY HEALTH INSURANCE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2901',
    payerID: '1UNTY',
    eligibilityID: 'UNTYHP',
    claimStatusID: 'UNTYHP',
    payerName: 'UNITY HEALTH INSURANCE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4779',
    payerID: 'SX155',
    eligibilityID: 'UNUTHP',
    claimStatusID: '',
    payerName: 'UNIVERSITY of UTAH HEALTH PLAN',
    claimType: 'Professional',
    State: 'MT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4779',
    payerID: 'SX155',
    eligibilityID: 'UNUTHP',
    claimStatusID: '',
    payerName: 'UNIVERSITY of UTAH HEALTH PLAN',
    claimType: 'Professional',
    State: 'UT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5920',
    payerID: 'SX155',
    eligibilityID: 'UNUTHP',
    claimStatusID: '',
    payerName: 'UNIVERSITY of UTAH HEALTH PLAN',
    claimType: 'Institutional',
    State: 'MT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5920',
    payerID: 'SX155',
    eligibilityID: 'UNUTHP',
    claimStatusID: '',
    payerName: 'UNIVERSITY of UTAH HEALTH PLAN',
    claimType: 'Institutional',
    State: 'UT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3648',
    payerID: '9830',
    eligibilityID: 'UNVFC',
    claimStatusID: '',
    payerName: 'UNIVERSITY FAMILY CARE/PHYSICIAN CARE ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'UPCA',
    payerID: '',
    eligibilityID: 'UPCADV',
    claimStatusID: '',
    payerName: 'BANNER MEDICARE ADVANTAGE DUAL HMO D-SNP',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: '',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2803',
    payerID: '95216',
    eligibilityID: 'UPMC',
    claimStatusID: 'UPMC',
    payerName: 'UPMC HEALTH PLAN',
    claimType: 'Professional',
    State: 'PA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7543',
    payerID: '95216',
    eligibilityID: 'UPMC',
    claimStatusID: 'UPMC',
    payerName: 'UPMC HEALTH PLAN',
    claimType: 'Institutional',
    State: 'PA',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3724',
    payerID: '87042',
    eligibilityID: 'UPREHS',
    claimStatusID: '',
    payerName: 'IRON ROAD HEALTHCARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7587',
    payerID: '87042',
    eligibilityID: 'UPREHS',
    claimStatusID: '',
    payerName: 'IRON ROAD HEALTHCARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6477',
    payerID: '74095',
    eligibilityID: 'USALCO',
    claimStatusID: '',
    payerName: 'USAA (U.S. AUTOMOBILE ASSOCIATION)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6561',
    payerID: '74095',
    eligibilityID: 'USALCO',
    claimStatusID: '',
    payerName: 'USAA (U.S. AUTOMOBILE ASSOCIATION)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1486',
    payerID: 'SKUT0',
    eligibilityID: 'UTCAID',
    claimStatusID: 'UTCAID',
    payerName: 'UTAH MEDICAID',
    claimType: 'Professional',
    State: 'UT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1528',
    payerID: 'HT000004-001',
    eligibilityID: 'UTCAID',
    claimStatusID: 'UTCAID',
    payerName: 'UTAH MEDICAID',
    claimType: 'Institutional',
    State: 'UT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'C,R,E,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1530',
    payerID: '910',
    eligibilityID: 'UTFEPI',
    claimStatusID: '',
    payerName: 'UTAH BLUE CROSS - FEP',
    claimType: 'Institutional',
    State: 'UT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2788',
    payerID: '910',
    eligibilityID: 'UTFEPP',
    claimStatusID: '',
    payerName: 'UTAH BLUE SHIELD - FEP',
    claimType: 'Professional',
    State: 'UT',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3611',
    payerID: 'HT001363-001',
    eligibilityID: 'UTMLNA',
    claimStatusID: 'UTMLNA',
    payerName: 'MOLINA HEALTHCARE OF UTAH',
    claimType: 'Institutional',
    State: 'UT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4237',
    payerID: 'SX109',
    eligibilityID: 'UTMLNA',
    claimStatusID: 'UTMLNA',
    payerName: 'MOLINA HEALTHCARE of UTAH',
    claimType: 'Professional',
    State: 'UT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1529',
    payerID: '910',
    eligibilityID: 'UTREBC',
    claimStatusID: 'UTREBS',
    payerName: 'UTAH REGENCE BLUE CROSS BLUE SHIELD',
    claimType: 'Institutional',
    State: 'UT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2412',
    payerID: '910',
    eligibilityID: 'UTREBS',
    claimStatusID: 'UTREBS',
    payerName: 'UTAH REGENCE BLUE CROSS BLUE SHIELD',
    claimType: 'Professional',
    State: 'UT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1532',
    payerID: 'HT000015-001',
    eligibilityID: 'UTSLHT',
    claimStatusID: '',
    payerName: 'SELECTHEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4480',
    payerID: 'HT000015-001',
    eligibilityID: 'UTSLHT',
    claimStatusID: '',
    payerName: 'SELECTHEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1413',
    payerID: '423',
    eligibilityID: 'VABCBS',
    claimStatusID: 'VABCBS',
    payerName: 'VIRGINIA BLUE SHIELD',
    claimType: 'Professional',
    State: 'VA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5537',
    payerID: '423',
    eligibilityID: 'VABCBS',
    claimStatusID: 'VABCBS',
    payerName: 'VIRGINIA BLUE CROSS',
    claimType: 'Institutional',
    State: 'VA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1472',
    payerID: 'VMAP FHSC FA',
    eligibilityID: 'VACAID',
    claimStatusID: '1472',
    payerName: 'VIRGINIA MEDICAID',
    claimType: 'Professional',
    State: 'VA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,CS',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5538',
    payerID: 'VMAP FHSC FA',
    eligibilityID: 'VACAID',
    claimStatusID: '5538',
    payerName: 'VIRGINIA MEDICAID',
    claimType: 'Institutional',
    State: 'VA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,CS',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7447',
    payerID: 'VMAP FHSC FA',
    eligibilityID: 'VACAID',
    claimStatusID: '7447',
    payerName: 'VIRGINIA MEDICAID LAB',
    claimType: 'Professional',
    State: 'VA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R,CS',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3009',
    payerID: 'VACCN',
    eligibilityID: 'VAFEE',
    claimStatusID: '',
    payerName: 'VETERANS AFFAIRS COMMUNITY CARE NETWORK RGN 1 2 3',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8257',
    payerID: 'VACCN',
    eligibilityID: 'VAFEE',
    claimStatusID: '',
    payerName: 'VETERANS AFFAIRS COMMUNITY CARE NETWORK RGN 1 2 3',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'VAHA',
    payerID: '',
    eligibilityID: 'VAHAC',
    claimStatusID: '',
    payerName: 'VA HEALTH ADMINISTRATION CENTER',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1816',
    payerID: '72128',
    eligibilityID: 'VANTAG  ',
    claimStatusID: '',
    payerName: 'VANTAGE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3924',
    payerID: '72128',
    eligibilityID: 'VANTAG  ',
    claimStatusID: '',
    payerName: 'VANTAGE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1507',
    payerID: '12115',
    eligibilityID: 'VETFBP',
    claimStatusID: 'VETFBP',
    payerName: 'VETERANS ADMINISTRATION FEE BASIS PROGRAMS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3887',
    payerID: '12115',
    eligibilityID: 'VETFBP',
    claimStatusID: 'VETFBP',
    payerName: 'VETERANS ADMINISTRATION FEE BASIS PROGRAMS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1503',
    payerID: '84146',
    eligibilityID: 'VETHAC',
    claimStatusID: 'CHMPVA',
    payerName: 'CHAMPVA - HAC',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3807',
    payerID: '84146',
    eligibilityID: 'VETHAC',
    claimStatusID: 'CHMPVA',
    payerName: 'CHAMPVA - HAC',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1181',
    payerID: '15976',
    eligibilityID: 'VIBRA',
    claimStatusID: '',
    payerName: 'VIBRA HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2553',
    payerID: '15976',
    eligibilityID: 'VIBRA',
    claimStatusID: '',
    payerName: 'VIBRA HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4472',
    payerID: '63114',
    eligibilityID: 'VIVAHL',
    claimStatusID: 'VIVAHL',
    payerName: 'VIVA HEALTH CARE and VIVA MEDICARE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5949',
    payerID: '63114',
    eligibilityID: 'VIVAHL',
    claimStatusID: 'VIVAHL',
    payerName: 'VIVA HEALTH CARE and VIVA MEDICARE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'VNSC',
    payerID: '',
    eligibilityID: 'VNSCHC',
    claimStatusID: '',
    payerName: 'VNS CHOICE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4258',
    payerID: '77073',
    eligibilityID: 'VNSNY',
    claimStatusID: 'VNSNY',
    payerName: 'VISITING NURSE SERVICE of NEW YORK',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5959',
    payerID: '77073',
    eligibilityID: 'VNSNY',
    claimStatusID: 'VNSNY',
    payerName: 'VISITING NURSE SERVICE of NEW YORK',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4967',
    payerID: 'BCBSVT',
    eligibilityID: 'VTBCBS',
    claimStatusID: 'VTBCBS',
    payerName: 'VERMONT BLUE CROSS',
    claimType: 'Institutional',
    State: 'VT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7493',
    payerID: 'BCBSVT',
    eligibilityID: 'VTBCBS',
    claimStatusID: 'VTBCBS',
    payerName: 'VERMONT BLUE SHIELD',
    claimType: 'Professional',
    State: 'VT',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3569',
    payerID: '752548221',
    eligibilityID: 'VTCAID',
    claimStatusID: '3569',
    payerName: 'VERMONT MEDICAID INPATIENT',
    claimType: 'Institutional',
    State: 'VT',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3570',
    payerID: '752548221',
    eligibilityID: 'VTCAID',
    claimStatusID: '3570',
    payerName: 'VERMONT MEDICAID OUTPATIENT',
    claimType: 'Institutional',
    State: 'VT',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7492',
    payerID: '752548221',
    eligibilityID: 'VTCAID',
    claimStatusID: '7492',
    payerName: 'VERMONT MEDICAID',
    claimType: 'Professional',
    State: 'VT',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,CS',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes-Agreement',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1482',
    payerID: '77045',
    eligibilityID: 'WACAID',
    claimStatusID: 'WACADP',
    payerName: 'WASHINGTON MEDICAID',
    claimType: 'Professional',
    State: 'WA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5523',
    payerID: '77045',
    eligibilityID: 'WACAID',
    claimStatusID: 'WACADI',
    payerName: 'WASHINGTON MEDICAID',
    claimType: 'Institutional',
    State: 'WA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5523',
    payerID: '77045',
    eligibilityID: 'WACAID',
    claimStatusID: 'WACADI',
    payerName: 'WASHINGTON MEDICAID',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1927',
    payerID: 'CHPWA',
    eligibilityID: 'WACHP',
    claimStatusID: '',
    payerName: 'COMMUNITY HEALTH PLAN OF WASHINGTON (CHPWA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3445',
    payerID: 'CHPWA',
    eligibilityID: 'WACHP',
    claimStatusID: '',
    payerName: 'COMMUNITY HEALTH PLAN OF WASHINGTON (CHPWA)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1863',
    payerID: '38336',
    eligibilityID: 'WAMLNA',
    claimStatusID: 'WAMLNA',
    payerName: 'MOLINA HEALTHCARE OF WASHINGTON',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2561',
    payerID: '38336',
    eligibilityID: 'WAMLNA',
    claimStatusID: 'WAMLNA',
    payerName: 'MOLINA HEALTHCARE of WASHINGTON',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8185',
    payerID: '',
    eligibilityID: 'WANATL',
    claimStatusID: '',
    payerName: 'WASHINGTON NATIONAL',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'No',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2581',
    payerID: '93093',
    eligibilityID: 'WAPBC',
    claimStatusID: '',
    payerName: 'LIFEWISE HEALTHPLAN of OREGON',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2854',
    payerID: '93093',
    eligibilityID: 'WAPBC',
    claimStatusID: '',
    payerName: 'LIFEWISE HEALTHPLAN of OREGON',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5522',
    payerID: '430',
    eligibilityID: 'WAPBCS',
    claimStatusID: '5522',
    payerName: 'BLUE CROSS WA/AK PREMERA BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5522',
    payerID: '430',
    eligibilityID: 'WAPBCS',
    claimStatusID: '5522',
    payerName: 'BLUE CROSS WA/AK PREMERA BLUE CROSS',
    claimType: 'Institutional',
    State: 'WA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5522',
    payerID: '430',
    eligibilityID: 'WAPBCS',
    claimStatusID: '5522',
    payerName: 'BLUE CROSS WA/AK PREMERA BLUE CROSS',
    claimType: 'Institutional',
    State: 'AK',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7445',
    payerID: '430',
    eligibilityID: 'WAPBCS',
    claimStatusID: '7445',
    payerName: 'BLUE CROSS WA/AK PREMERA BLUE CROSS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7445',
    payerID: '430',
    eligibilityID: 'WAPBCS',
    claimStatusID: '7445',
    payerName: 'BLUE CROSS WA/AK PREMERA BLUE CROSS',
    claimType: 'Professional',
    State: 'AK',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7445',
    payerID: '430',
    eligibilityID: 'WAPBCS',
    claimStatusID: '7445',
    payerName: 'BLUE CROSS WA/AK PREMERA BLUE CROSS',
    claimType: 'Professional',
    State: 'WA',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1997',
    payerID: '932',
    eligibilityID: 'WARBCS',
    claimStatusID: 'WARBCS',
    payerName: 'WASHINGTON REGENCE BLUE SHIELD',
    claimType: 'Institutional',
    State: 'WA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7451',
    payerID: '932',
    eligibilityID: 'WARBCS',
    claimStatusID: 'WARBCS',
    payerName: 'WASHINGTON REGENCE BLUE SHIELD',
    claimType: 'Professional',
    State: 'WA',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4413',
    payerID: '39151',
    eligibilityID: 'WEATST',
    claimStatusID: '',
    payerName: 'WISCONSIN EDUCATORS ASSOCIATION (WEA)',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6564',
    payerID: '39151',
    eligibilityID: 'WEATST',
    claimStatusID: '',
    payerName: 'WISCONSIN EDUCATORS ASSOCIATION (WEA)',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1706',
    payerID: '75261',
    eligibilityID: 'WEBTPA',
    claimStatusID: '',
    payerName: 'WebTPA',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4559',
    payerID: '75261',
    eligibilityID: 'WEBTPA',
    claimStatusID: '',
    payerName: 'WebTPA',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1844',
    payerID: '14163',
    eligibilityID: 'WELLCR',
    claimStatusID: 'WELLCR',
    payerName: 'WELLCARE HEALTH PLAN',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8551',
    payerID: '14163',
    eligibilityID: 'WELLCR',
    claimStatusID: 'WELLCR',
    payerName: 'WELLCARE HEALTH PLAN',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'B, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: 'WELL',
    payerID: '45423',
    eligibilityID: 'WELLCR ',
    claimStatusID: '',
    payerName: 'ADVICARE',
    claimType: '',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'No',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1401',
    payerID: '950',
    eligibilityID: 'WIBCBS',
    claimStatusID: 'WIBCBS',
    payerName: 'WISCONSIN BLUE SHIELD',
    claimType: 'Professional',
    State: 'WI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1401',
    payerID: '950',
    eligibilityID: 'WIBCBS',
    claimStatusID: 'WIBCBS',
    payerName: 'WISCONSIN BLUE SHIELD',
    claimType: 'Professional',
    State: 'WI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5511',
    payerID: '450',
    eligibilityID: 'WIBCBS',
    claimStatusID: 'WIBCBS',
    payerName: 'WISCONSIN BLUE CROSS',
    claimType: 'Institutional',
    State: 'WI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5511',
    payerID: '450',
    eligibilityID: 'WIBCBS',
    claimStatusID: 'WIBCBS',
    payerName: 'WISCONSIN BLUE CROSS',
    claimType: 'Institutional',
    State: 'WI',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5511',
    payerID: '450',
    eligibilityID: 'WIBCBS',
    claimStatusID: 'WIBCBS',
    payerName: 'WISCONSIN BLUE CROSS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '1467',
    payerID: 'WISC_TXIX',
    eligibilityID: 'WICAID',
    claimStatusID: 'WICAID',
    payerName: 'WISCONSIN MEDICAID',
    claimType: 'Professional',
    State: 'WI',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5510',
    payerID: 'WISC_TXIX',
    eligibilityID: 'WICAID',
    claimStatusID: 'WICAID',
    payerName: 'WISCONSIN MEDICAID',
    claimType: 'Institutional',
    State: 'WI',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5901',
    payerID: 'WISC_WCDP',
    eligibilityID: 'WICDP',
    claimStatusID: '',
    payerName: 'WISCONSIN CHRONIC DISEASE PROGRAM',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6764',
    payerID: 'WISC_WCDP',
    eligibilityID: 'WICDP',
    claimStatusID: '',
    payerName: 'WISCONSIN CHRONIC DISEASE PROGRAM',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5671',
    payerID: 'WISC_WWWP',
    eligibilityID: 'WIWWP',
    claimStatusID: '',
    payerName: 'WISCONSIN WELL WOMENS PROGRAM',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6454',
    payerID: 'WISC_WWWP',
    eligibilityID: 'WIWWP',
    claimStatusID: '',
    payerName: 'WISCONSIN WELL WOMENS PROGRAM',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2900',
    payerID: 'WPS',
    eligibilityID: 'WPSCR',
    claimStatusID: '',
    payerName: 'WPS COMMERCIAL / REGULAR BUSINESS',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2900',
    payerID: 'WPS',
    eligibilityID: 'WPSCR',
    claimStatusID: '',
    payerName: 'WPS COMMERCIAL / REGULAR BUSINESS',
    claimType: 'Institutional',
    State: 'WI',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4457',
    payerID: 'WPS',
    eligibilityID: 'WPSCR',
    claimStatusID: '',
    payerName: 'WPS COMERCIAL / REGULAR BUSINESS',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '4457',
    payerID: 'WPS',
    eligibilityID: 'WPSCR',
    claimStatusID: '',
    payerName: 'WPS COMERCIAL / REGULAR BUSINESS',
    claimType: 'Professional',
    State: 'WI',
    connectivityAvailable: 'N/A, B',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5634',
    payerID: '68039',
    eligibilityID: 'WSTHA',
    claimStatusID: '',
    payerName: 'WESTERN HEALTH ADVANTAGE',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '6267',
    payerID: '68039',
    eligibilityID: 'WSTHA',
    claimStatusID: '',
    payerName: 'WESTERN HEALTH ADVANTAGE',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3979',
    payerID: '31048',
    eligibilityID: 'WSTSL',
    claimStatusID: '',
    payerName: 'WESTERN SOUTHERN LIFE GROUP',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '5415',
    payerID: '31048',
    eligibilityID: 'WSTSL',
    claimStatusID: '',
    payerName: 'WESTERN SOUTHERN LIFE GROUP',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '3589',
    payerID: '54828',
    eligibilityID: 'WVBCBS',
    claimStatusID: 'WVBCI',
    payerName: 'WEST VIRGINIA HIGHMARK BLUE CROSS BLUE SHIELD',
    claimType: 'Institutional',
    State: 'WV',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7131',
    payerID: '15459',
    eligibilityID: 'WVBCBS',
    claimStatusID: '',
    payerName: 'WEST VIRGINIA FREEDOM BLUE MEDICARE ADVANTAGE',
    claimType: 'Professional',
    State: 'WV',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7474',
    payerID: '54828',
    eligibilityID: 'WVBCBS',
    claimStatusID: 'WVBCP',
    payerName: 'WEST VIRGINIA HIGHMARK BLUE CROSS BLUE SHIELD',
    claimType: 'Professional',
    State: 'WV',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '7680',
    payerID: '15459',
    eligibilityID: 'WVBCBS',
    claimStatusID: '',
    payerName: 'WEST VIRGINIA FREEDOM BLUE MEDICARE ADVANTAGE',
    claimType: 'Institutional',
    State: 'WV',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R,E',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes-Agreement',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2477',
    payerID: 'WV_MMIS_4MOLINA',
    eligibilityID: 'WVCAID',
    claimStatusID: '2477',
    payerName: 'WEST VIRGINIA MEDICAID',
    claimType: 'Professional',
    State: 'WV',
    connectivityAvailable: 'E, B',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '5532',
    payerID: 'WV_MMIS_4MOLINA',
    eligibilityID: 'WVCAID',
    claimStatusID: '5532',
    payerName: 'WEST VIRGINIA MEDICAID',
    claimType: 'Institutional',
    State: 'WV',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: 'Yes'
  },
  {
    cHCPayerID: '3584',
    payerID: '460',
    eligibilityID: 'WYBCBI',
    claimStatusID: '3584',
    payerName: 'WYOMING BLUE CROSS',
    claimType: 'Institutional',
    State: 'WY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7489',
    payerID: '960',
    eligibilityID: 'WYBCBP',
    claimStatusID: '7489',
    payerName: 'WYOMING BLUE SHIELD',
    claimType: 'Professional',
    State: 'WY',
    connectivityAvailable: 'E, E',
    enrollmentRequired: 'R',
    Claims: 'Yes',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2577',
    payerID: '77046',
    eligibilityID: 'WYCAID',
    claimStatusID: '',
    payerName: 'WYOMING MEDICAID',
    claimType: 'Institutional',
    State: 'WY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '7458',
    payerID: '77046',
    eligibilityID: 'WYCAID',
    claimStatusID: '',
    payerName: 'WYOMING MEDICAID',
    claimType: 'Professional',
    State: 'WY',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: 'C,R',
    Claims: 'Yes-Agreement',
    Remit: 'Yes-Agreement',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '2097',
    payerID: '83248',
    eligibilityID: 'ZINGIL',
    claimStatusID: '',
    payerName: 'ZING HEALTH',
    claimType: 'Institutional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '8246',
    payerID: '83248',
    eligibilityID: 'ZINGIL',
    claimStatusID: '',
    payerName: 'ZING HEALTH',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'N/A, E',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'No',
    attachments: 'No',
    mustSubmitClaimstoCH: ''
  },
  {
    cHCPayerID: '9496',
    payerID: '',
    eligibilityID: 'ZZ5010',
    claimStatusID: 'ZZ5010',
    payerName: 'TEST CPID',
    claimType: 'Professional',
    State: '',
    connectivityAvailable: 'B, B',
    enrollmentRequired: ' ',
    Claims: 'Yes',
    Remit: 'No',
    Eligibility: 'Yes',
    claimStatus: 'Yes',
    attachments: 'Yes',
    mustSubmitClaimstoCH: ''
  }
];

export default payerDropdown;
