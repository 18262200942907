import styled from 'styled-components';

export const FileUploader = styled.div`
  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .files-header {
    margin-bottom: 0.25em;
  }

  .filename {
    margin-top: 0;
    margin-bottom: 0.25em;
  }

  .file-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25em;

    .filename {
      overflow-x: auto;
      white-space: nowrap;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .filesize {
      margin-left: 8px;
      font-size: 0.875em;
    }

    .delete-btn {
      appearance: none;
      padding: 0 0.25em;
      border: none;
      background: none;
      border-radius: 0.25em;
      color: #333;
      margin-left: 0.75em;
      font-weight: 900;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .drag-drop-container {
    .dropbox {
      background-color: var(--color-tertiary--50);
      height: 10em;
      width: 100%;
      max-width: 50em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      outline: 1px dashed var(--color-secondary--100);
      outline-offset: -10px;

      a {
        color: var(--color-secondary);

        &:active {
          color: var(--color-secondary--100);
        }
      }

      h4,
      p {
        margin: 0;
        text-align: center;
      }

      &.dragging {
        outline-width: 3px;
      }

      .error-message {
        margin: 0;
        font-size: 0.85em;
        font-weight: 600;
        color: var(--color-error) !important;
      }
    }
  }
`;
