import { createSlice } from '@reduxjs/toolkit';
import { determineActionCard } from '../../utils';
import CONFIG from '../../config';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const {
  ACTIONS: {
    ACTION_STATES: { APPEAL, QUESTION, VIEW }
  }
} = CONFIG;

const DEFAULT_STATE = {
  ...LOADING_STATES,
  actionsAvailable: false,
  currentAction: null,
  headline: null,
  instructions: null,
  phoneNumber: null,
  respondBy: null
};

const initialState = DEFAULT_STATE;

const actionCardSlice = createSlice({
  name: 'actionCard',
  initialState,
  reducers: {
    clearActionCardError: state => clearError(state),
    clearActionCardSuccess: clearSuccess,
    disableActionCardActions(state) {
      state.actionsAvailable = false;
      state.currentAction = null;
    },
    enableActionCardAppeal(state) {
      state.actionsAvailable = true;
      state.currentAction = APPEAL;
    },
    enableActionCardQuestion(state) {
      state.actionsAvailable = true;
      state.currentAction = QUESTION;
    },
    enableActionCardViewQuestions(state) {
      state.actionsAvailable = true;
      state.currentAction = VIEW;
    },
    resetActionCard: () => DEFAULT_STATE,
    setActionCardResponse(state, { payload }) {
      state.headline = payload.headline;
      state.instructions = payload.instructions;
      state.phoneNumber = payload.phoneNumber;
      state.respondBy = payload.respondBy;
    },
    setActionCardError: loadingFailed,
    setActionCardSuccess: loadingSuccess,
    startActionCardLoading: startLoading,
    stopActionCardLoading: stopLoading
  }
});

export const {
  clearActionCardError,
  clearActionCardSuccess,
  disableActionCardActions,
  enableActionCardAppeal,
  enableActionCardQuestion,
  enableActionCardViewQuestions,
  resetActionCard,
  setActionCardError,
  setActionCardResponse,
  setActionCardSuccess,
  startActionCardLoading,
  stopActionCardLoading
} = actionCardSlice.actions;
export default actionCardSlice.reducer;

/**
 * Sequence for loading and setting action card data.
 * @param {Object} data
 */
export const fetchActionCard = data => async dispatch => {
  try {
    dispatch(startActionCardLoading());
    dispatch(setActionCardResponse(determineActionCard(data)));
    dispatch(setActionCardSuccess());
  } catch (err) {
    dispatch(setActionCardError(String(err)));
  }
};
