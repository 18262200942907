import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  attachment: null,
  paAuthorizationDetails: [],
  authorizationPeriod: {
    authorizationEffectiveDateTime: '',
    authorizationExpirationDateTime: ''
  },
  authorizationNumber: '',
  eappealSupported: false,
  errorCode: '',
  expectedResponseDate: '',
  fileName: '',
  name: '',
  paNote: '',
  questionSet: {
    header: {
      openResponseDeadlineForReplyInDateTime: '',
      questionSetId: '',
      questionSetTitle: '',
      questionSetDescription: '',
      questionSetPrimaryContactNumber: '',
      attachmentRequired: false,
      attachmentNotes: ''
    },
    question: []
  },
  reasonCode: '',
  recipient: '',
  responseStatus: '',
  status: '',
  statusReason: '',
  timestamp: ''
};

const initialState = DEFAULT_STATE;

const latestTaskSlice = createSlice({
  name: 'latestTask',
  initialState,
  reducers: {
    clearLatestTaskError: state => clearError(state),
    clearLatestTaskSuccess: clearSuccess,
    resetLatestTask: () => DEFAULT_STATE,
    setLatestTask(state, { payload }) {
      const {
        attachment,
        paAuthorizationDetails,
        authorizationPeriod,
        authorizationNumber,
        eappealSupported,
        errorCode,
        expectedResponseDate,
        fileName,
        name,
        paNote,
        questionSet,
        reasonCode,
        recipient,
        responseStatus,
        status,
        statusReason,
        timestamp
      } = payload;

      state.attachment = attachment || DEFAULT_STATE.attachment;
      state.paAuthorizationDetails =
        paAuthorizationDetails || DEFAULT_STATE.paAuthorizationDetails;

      if (authorizationPeriod !== null) {
        state.authorizationPeriod.authorizationEffectiveDateTime = authorizationPeriod?.authorizationEffectiveDateTime
          ? formatDate(authorizationPeriod.authorizationEffectiveDateTime)
          : DEFAULT_STATE.authorizationPeriod.authorizationEffectiveDateTime;

        state.authorizationPeriod.authorizationExpirationDateTime = authorizationPeriod?.authorizationExpirationDateTime
          ? formatDate(authorizationPeriod.authorizationExpirationDateTime)
          : DEFAULT_STATE.authorizationPeriod.authorizationExpirationDateTime;
      }

      state.authorizationNumber =
        authorizationNumber || DEFAULT_STATE.authorizationNumber;

      state.eappealSupported =
        eappealSupported || DEFAULT_STATE.eappealSupported;

      state.errorCode = errorCode || DEFAULT_STATE.errorCode;

      state.expectedResponseDate = expectedResponseDate
        ? formatDate(expectedResponseDate)
        : DEFAULT_STATE.expectedResponseDate;

      state.fileName = fileName || DEFAULT_STATE.fileName;
      state.name = name || DEFAULT_STATE.name;
      state.paNote = paNote || DEFAULT_STATE.paNote;
      state.questionSet = questionSet || DEFAULT_STATE.questionSet;
      state.reasonCode = reasonCode || DEFAULT_STATE.reasonCode;
      state.recipient = recipient || DEFAULT_STATE.recipient;
      state.responseStatus = responseStatus || DEFAULT_STATE.responseStatus;
      state.status = status || DEFAULT_STATE.status;
      state.statusReason = statusReason || DEFAULT_STATE.statusReason;
      state.timestamp = timestamp || DEFAULT_STATE.timestamp;
    },
    setLatestTaskError: loadingFailed,
    setLatestTaskSuccess: loadingSuccess,
    startLatestTaskLoading: startLoading,
    stopLatestTaskLoading: stopLoading
  }
});

export const {
  clearLatestTaskError,
  clearLatestTaskSuccess,
  resetLatestTask,
  setLatestTask,
  setLatestTaskError,
  setLatestTaskSuccess,
  startLatestTaskLoading,
  stopLatestTaskLoading
} = latestTaskSlice.actions;
export default latestTaskSlice.reducer;

/**
 * Sequence for loading and setting latest task data.
 * @param {Object} data
 */
export const fetchLatestTask = data => async dispatch => {
  try {
    dispatch(startLatestTaskLoading());
    dispatch(setLatestTask(data));
    dispatch(setLatestTaskSuccess());
  } catch (err) {
    dispatch(setLatestTaskError(String(err)));
  }
};
