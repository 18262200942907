/* eslint-disable no-else-return */
import { IQuestionType } from '@pixie/api';
import CONFIG from '@pixie/config';

const {
  QUESTIONS: {
    QUESTION_TYPES: { CHECKBOX, DATE, END, FREE_TEXT, NUMERIC, RADIO }
  }
} = CONFIG;

/**
 * Determines which `Question.*`.tsx` component to render
 * @param questionType: IQuestionType
 * @requires questionSet.questionType
 */
const getQuestionType = (questionType: IQuestionType) => {
  if (!questionType) return null;
  else if (questionType.select) {
    if (questionType.select.selectMultiple === true) return CHECKBOX;
    else return RADIO;
  } else if (questionType.numeric) return NUMERIC;
  else if (questionType.freeText) return FREE_TEXT;
  else if (questionType.date) return DATE;
  else return END;
};

export default getQuestionType;
