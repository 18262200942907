import { IQuestion } from '@pixie/api';
import { useLayoutEffect, useState } from 'react';

/**
 * Handles the smooth transition of heights between questions.
 *
 * @bug Fix flicker in regards to the first sequence and currentQuestionObj
 *
 * @param currentQuestionId
 * @param currentQuestionObj
 *
 * @returns {Number|String} height
 */
const useAnimateQuestionHeight = (
  currentQuestionId: string,
  currentQuestionObj: IQuestion | {}
): number | string => {
  const [height, setHeight] = useState<number | string>(0);
  const target = 'div[data-is-active="true"]';

  // handles AnimateHeight
  useLayoutEffect(() => {
    const currentQuestionDOM = document.querySelector<HTMLElement>(target);
    const currentQuestionHeight = currentQuestionDOM?.offsetHeight;

    if (currentQuestionHeight && currentQuestionId) {
      setHeight(height === 0 ? 'auto' : currentQuestionHeight);
    } else {
      setHeight('auto');
    }
  }, [currentQuestionId, currentQuestionObj, height]);

  return height;
};

export default useAnimateQuestionHeight;
