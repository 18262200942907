import { ReactElement } from 'react';
import * as React from 'react';
import { Button } from '@pixie/components';
import CONFIG from '@pixie/config';

import styled from 'styled-components';

interface IRefreshButtonProps {
  isDisabled?: boolean;
  isActionAvailable?: string;
  onClick: () => void;
}

const {
  ACTIONS: { ACTION_STATES }
} = CONFIG;

const TimestampRefreshButton = (props: IRefreshButtonProps): ReactElement => {
  const { isDisabled, isActionAvailable, onClick } = props;
  return (
    <Button
      aria-label="Refresh"
      buttonStyle={(isActionAvailable === ACTION_STATES.QUESTION || isActionAvailable === ACTION_STATES.APPEAL) ? 'secondary' : 'refresh'}
      disabled={isDisabled}
      id="RefreshData"
      text="Check for Status Updates"
      onClick={onClick}
      type="button"
    />
  );
};

TimestampRefreshButton.defaultProps = {
  isDisabled: false,
  isActionAvailable: ''
};

export default TimestampRefreshButton;
