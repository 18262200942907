import styled from 'styled-components';

export const Div = styled.div`
  padding-top: 15px;
  margin: auto;

  @media (min-width: 960px) {
    padding-top: 75px;

    &.uk-container-small {
      max-width: 69%;
    }
  }

  .pixie-license-text {
    font-size: 10px;
    white-space: pre-wrap;
  }
  .header-row {
    text-align: left;
  }
`;
