import { createSlice } from '@reduxjs/toolkit';
import {
  answerDateQuestionAction,
  answerFreeTextQuestionAction,
  answerMultiSelectQuestionAction,
  answerNumericQuestionAction,
  answerSelectQuestionAction,
  answerSelectQuestionAdditionalFreeTextAction,
  closeQuestionSetAction,
  openQuestionSetAction,
  setQuestionSetResponseAction
} from './actions';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading,
  resetLoadingStates
} from '../_services';
import { sortQuestionsArray } from './utils';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  isLoading: false,
  header: {},
  question: [],
  questionSetOpen: false,
  submitIsDisabled: false,
  submitErrorMessage: null,
  submitIsError: false,
  submitIsLoading: false,
  submitIsSuccess: false,
  showSubmitModal: false,
  showBuyAndBill: false
};

const initialState = DEFAULT_STATE;

const questionSetSlice = createSlice({
  name: 'questionSet',
  initialState,
  reducers: {
    answerDateQuestion: answerDateQuestionAction,
    answerFreeTextQuestion: answerFreeTextQuestionAction,
    answerMultiSelectQuestion: answerMultiSelectQuestionAction,
    answerNumericQuestion: answerNumericQuestionAction,
    answerSelectQuestion: answerSelectQuestionAction,
    answerSelectQuestionAdditionalFreeText: answerSelectQuestionAdditionalFreeTextAction,
    clearQuestionSetError: clearError,
    clearQuestionSetSuccess: clearSuccess,
    closeQuestionSet: closeQuestionSetAction,
    openQuestionSet: openQuestionSetAction,
    resetQuestionSet: () => DEFAULT_STATE,
    resetDateQuestion(state, { payload }) {
      const curQuestionSlot = state.question.findIndex(
        q => q.questionId === payload
      );
      const answeredQuestion = {
        ...state.question[curQuestionSlot],
        questionType: {
          ...state.question[curQuestionSlot].questionType,
          date: {
            ...state.question[curQuestionSlot].questionType.date,
            answer: null
          }
        }
      };

      const newQuestionArray = [
        ...state.question.filter(q => q.questionId !== payload),
        answeredQuestion
      ];

      // sort the array set
      const sortedQuestionArray = sortQuestionsArray(newQuestionArray);

      // set the new question array as the sorted array
      state.question = sortedQuestionArray;
    },
    resetFreeTextQuestion(state, { payload }) {
      // clear the textarea value on the DOM
      document
        ?.querySelectorAll(
          `#QuestionSet div[data-question-id="${payload}"] textarea`
        )
        ?.forEach(el => {
          el.value = '';
        });

      const curQuestionSlot = state.question.findIndex(
        q => q.questionId === payload
      );

      const answeredQuestion = {
        ...state.question[curQuestionSlot],
        questionType: {
          ...state.question[curQuestionSlot].questionType,
          freeText: {
            ...state.question[curQuestionSlot].questionType.freeText,
            answer: null
          }
        }
      };

      const newQuestionArray = [
        ...state.question.filter(q => q.questionId !== payload),
        answeredQuestion
      ];

      // sort the array set
      const sortedQuestionArray = sortQuestionsArray(newQuestionArray);

      // set the new question array as the sorted array
      state.question = sortedQuestionArray;
    },
    resetNumericQuestion(state, { payload }) {
      const curQuestionSlot = state.question.findIndex(
        q => q.questionId === payload
      );

      const answeredQuestion = {
        ...state.question[curQuestionSlot],
        questionType: {
          ...state.question[curQuestionSlot].questionType,
          numeric: {
            ...state.question[curQuestionSlot].questionType.numeric,
            answer: null
          }
        }
      };

      const newQuestionArray = [
        ...state.question.filter(q => q.questionId !== payload),
        answeredQuestion
      ];

      // sort the array set
      const sortedQuestionArray = sortQuestionsArray(newQuestionArray);

      // set the new question array as the sorted array
      state.question = sortedQuestionArray;
    },
    resetSelectQuestion(state, { payload }) {
      // clear the checked answer on the DOM
      document
        ?.querySelectorAll(
          `#QuestionSet div[data-question-id="${payload}"] input[type="radio"]`
        )
        ?.forEach(el => {
          el.checked = false;
        });

      const curQuestionSlot = state.question.findIndex(
        q => q.questionId === payload
      );

      const answeredQuestion = {
        ...state.question[curQuestionSlot],
        questionType: {
          ...state.question[curQuestionSlot].questionType,
          select: {
            ...state.question[curQuestionSlot].questionType.select,
            answer: null
          }
        }
      };

      const newQuestionArray = [
        ...state.question.filter(q => q.questionId !== payload),
        answeredQuestion
      ];

      // sort the array set
      const sortedQuestionArray = sortQuestionsArray(newQuestionArray);

      // set the new question array as the sorted array
      state.question = sortedQuestionArray;
    },
    resetQuestionSetLoadingStates: resetLoadingStates,
    setQuestionSetError: loadingFailed,
    setQuestionSetResponse: setQuestionSetResponseAction,

    // question set submission
    setSubmitIsDisabled(state, { payload }) {
      state.submitIsDisabled = payload;
    },
    setSubmitSuccess(state) {
      state.submitIsSuccess = true;
    },
    clearSubmitSuccess(state) {
      state.submitIsSuccess = false;
    },
    startSubmitLoading(state) {
      state.submitIsLoading = true;
    },
    stopSubmitLoading(state) {
      state.submitIsLoading = false;
    },
    setSubmitError(state, { payload }) {
      state.submitIsError = true;
      state.submitErrorMessage = payload;
      state.submitIsLoading = false;
      state.submitIsSuccess = false;
    },
    clearSubmitError(state) {
      state.submitIsError = false;
      state.submitErrorMessage = null;
      state.submitIsLoading = false;
      state.submitIsSuccess = false;
    },
    resetSubmitLoadingStates(state) {
      state.submitIsError = false;
      state.submitIsLoading = false;
      state.submitIsSuccess = false;
    },

    setQuestionSetSuccess: loadingSuccess,
    startQuestionSetLoading: startLoading,
    stopQuestionSetLoading: stopLoading,
    setShowSubmitModalTrue(state) {
      state.showSubmitModal = true;
    },
    setShowSubmitModalFalse(state) {
      state.showSubmitModal = false;
    },
    setShowBuyAndBillTrue(state) {
      state.showBuyAndBill = true;
    },
    setShowBuyAndBillFalse(state) {
      state.showBuyAndBill = false;
    }
  }
});

export const {
  answerDateQuestion,
  answerFreeTextQuestion,
  answerMultiSelectQuestion,
  answerNumericQuestion,
  answerSelectQuestion,
  answerSelectQuestionAdditionalFreeText,
  clearQuestionSetError,
  clearQuestionSetSuccess,
  clearSubmitError,
  clearSubmitSuccess,
  closeQuestionSet,
  openQuestionSet,
  resetDateQuestion,
  resetFreeTextQuestion,
  resetNumericQuestion,
  resetQuestionSet,
  resetQuestionSetLoadingStates,
  resetSelectQuestion,
  resetSubmitLoadingStates,
  setCurrentQuestionId,
  setNextQuestionId,
  setQuestionProgress,
  setQuestionSetError,
  setQuestionSetResponse,
  setQuestionSetSuccess,
  setSubmitError,
  setSubmitIsDisabled,
  setSubmitSuccess,
  startQuestionSetLoading,
  startSubmitLoading,
  stopQuestionSetLoading,
  stopSubmitLoading,
  setShowSubmitModalTrue,
  setShowSubmitModalFalse,
  setShowBuyAndBillTrue,
  setShowBuyAndBillFalse
} = questionSetSlice.actions;

export default questionSetSlice.reducer;

/**
 * Sequence for setting questionSet redux data.
 * @param {Object} data
 */
export const fetchQuestionSet = data => async dispatch => {
  try {
    dispatch(startQuestionSetLoading());
    dispatch(setQuestionSetResponse(data));
    dispatch(setQuestionSetSuccess());
  } catch (err) {
    dispatch(setQuestionSetError(String(err)));
  }
};
