import { ReactElement, useState } from 'react';
import * as React from 'react';
import { getFileDownload, IPayerAttachment } from '@pixie/api';
import { Card } from '@pixie/components';
import * as Styled from './TheRequestHeader.styled';

interface IPayerAttachmentProps {
  dataTestId: string;
  fileName: string;
  fileType: string;
}

const PayerAttachment = (props: IPayerAttachmentProps): ReactElement => {
  const { dataTestId,
    fileName,
    fileType } = props;

  // localstate for any attachment errors
  const [attachmentError, setAttachmentError] = useState(null);

  /**
   * Creates, appends, clicks, and removes an `<a />` tag to trigger
   * the file download sequence for a user.
   *
   *  1. create the element
   *  2. determine the content type; based on `props.fileType`
   *  3. make sure element is hidden
   *  4. set the `a[download]` attribute value
   *  5. set the `a[href] attr value
   *  6. append element to the DOM
   *  7. click it to download
   *  8. remove element from the DOM
   */
  const downloadFileAttachment = () => {
    setAttachmentError(null); // reset attachment error

    getFileDownload(fileName, fileType)
      .then((data: IPayerAttachment) => {
        const link = document.createElement('a'); // 1

        // 2
        let contentType = `image/${fileType}`;
        if (fileType.toLowerCase() === 'pdf') {
          contentType = 'application/pdf';
        }

        link.style.display = 'none'; // 3
        link.download = fileName; // 4
        link.href = `data:${contentType};base64,${data}`; // 5
        document.body.appendChild(link); // 6
        link.click(); // 7
        document.body.removeChild(link); // 8
      })
      .catch(err => {
        if (err) {
          setAttachmentError(err);
        }
      });
  };

  return (
    <Styled.PayerAttachment>
      <div className="pixie__payer-attachment uk-animation-fade">
        <div className="uk-container uk-container-large">
          <section className="uk-section uk-section-xsmall">
            <div className="uk-margin uk-margin-xsmall uk-width-1-1">
              <h2 className="uk-h4">
                <span>Attachment</span>
              </h2>
              <Card dataTestId={dataTestId}>
                <p>
                  <span>
                    There is a {fileType} file available for downloading.&nbsp;
                  </span>
                  <button onClick={() => downloadFileAttachment()} type="button">
                    Click here to download the attachment.
                  </button>
                </p>

                {attachmentError && (
                <p className="attachment-error uk-text-meta">
                  {attachmentError?.message ?? 'Error'}
                </p>
                )}
              </Card>
            </div>
          </section>
        </div>
      </div>
    </Styled.PayerAttachment>
  );
};

export default PayerAttachment;
