import styled from 'styled-components';

export const RequestDetail = styled.div`
  .pixie__request-header-title-bar .uk-grid {
    min-height: 40px;
  }

  h3 {
    font-weight: bold;
  }

  .uk-grid > div {
    transition: width, margin 150ms ease-out;
    will-change: width, margin;
  }
  .toggle-button-positioning {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const EbvDetail = styled.div`
  .benefits-page {
    margin: 20px;
  }

  // the @m is a UIkit class modifier,
  // meant for 960px breakpoints :)
  @media (min-width: 960px) {
    .benefits-coverage {
      width: 41%;
      padding: 40px;
    }
  }

  .benefits-coverage-left {
    border-right: 1px solid black;
    width: 50%;
    padding: 10px;
  }

  .discrepancies-alert {
    border: 1px solid var(--color-danger);
    border-radius: 10px;
    color: var(--color-danger);
    background-color: var(--color-secondary--alt1);
    margin-top: 20px;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
  }

  .prior-authorization-button {
    margin-top: 70px;
    display: block;
    float: right;
    padding-left: 5px;
    padding-bottom: 40px;
  }

  .rerun-benefits-verification-button {
    margin-top: 70px;
    display: block;
    margin-right: 0px;
    padding-bottom: 40px;
  }

  .header-information {
    width: 80%;
  }
  .timestamp {
    padding-left: 20px;
    margin-bottom: 20px;
    white-space: nowrap;
  }

  .discrepancies-header {
    padding-left: 20px;
    padding-top: 40px;
  }

  .ebv-disclaimer {
    font-size: 13px;
  }
`;

export const MultiRequestDetail = styled(RequestDetail)`
  position: relative;

  .uk-tab {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 0;
    list-style: none;
    position: relative;
  }

  .uk-tab:before {
    content: none;
  }

  .uk-tab > li {
    flex: none;
    padding-left: 20px;
    position: relative;
  }

  .uk-tab > li > button {
    display: flex;
    align-items: center;
    column-gap: 0.25em;
    justify-content: center;
    padding: 9px 20px;
    color: var(--color-black--10);
    border-bottom: 2px solid transparent;
    font-size: 12px;
    text-transform: uppercase;
    transition: color 0.1s ease-in-out;
    line-height: 20px;
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    appearance: none;
    cursor: pointer;
  }

  .uk-tab > .uk-active > button {
    color: var(--color-secondary);
    border-color: var(--color-secondary);
  }

  .pixie__request-header-title-bar,
  .pixie__request-header-details,
  .request-data,
  .task-history {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pixie__multi-request-view {
    border: 1px solid var(--color-tertiary--50);
  }
`;
