import styled from 'styled-components';

export const Div = styled.div`
  background-color: #ffffff;

  .pixie-sign-in {
    border-radius: 5px;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    padding: 57px 58px 20px 58px;
    transition: border-color 200ms ease-out;
    width: 100%;
    will-change: border-color;
    z-index: 0;
    @media (max-width: 959px) {
      margin-bottom: 20px;
    }
  }

  .pixie__select {
    &__menu-list {
      padding-left: 10px;
      padding-right: 10px;
    }

    &__option {
      font-size: 12px;
      padding: 8px 10px;
    }
  }

  .date-picker {
    font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 48;
    color: black;
  }
  .pixie-sign-in-border-success {
    border-color: var(--color-tertiary--100);
  }

  .pixie-sign-in-border-error {
    border-color: var(--color-error);
  }

  .pixie-auth-code-highlight {
    color: black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 21px;
  }

  .legend-required {
    color: var(--color-black);
    padding-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 11.38px;
    line-height: 19px;
    font-weight: 700;
  }

  .pixie-patient-info {
    padding-top: 20px;
  }

  .pixie-patient {
    padding-bottom: 15px;
  }

  .MuiInputBase-adornedEnd {
    width: 100%;
    height: 44px;
    color: #666;

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-tertiary--50);
      border-width: 2px;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-tertiary--50);
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-primary);
      box-shadow: 0px 0px 6px 0px rgba(81, 149, 213, 0.65);
      outline: none;
    }
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .pixie-dob-pattern {
    padding-top: 5px;
    font-size: 12px;
    color: #666;
  }

  .pixie-auth-code-input {
    border: 2px solid var(--color-tertiary--50);
    border-radius: 3px;
    padding: 0 10px 0 10px;
    margin: 0px 10px 0px 0px;
    height: 44px;
    color: #666;
    width: 33%;

    &:focus-within,
    &:focus-visible {
      border-color: var(--color-primary);
      box-shadow: 0px 0px 6px 0px rgba(81, 149, 213, 0.65);
      outline: none;
    }
  }

  .authentication-code-input-field {
    margin-top: 4px;
    letter-spacing: 2px;
  }

  .pixie-auth-code {
    margin: 0px;
  }

  .pixie-auth-code-divider {
    padding: 10px 5px 0 5px;
    margin: 0px 10px 0px 0px;
  }

  .error-container-auth-code {
    margin-top: 4px;
  }
  .link {
    color: #005c78;
    text-decoration: underline;
  }

  .error-message-auth-code {
    font-family: 'Open Sans', sans-serif;
    font-size: 11.38px;
    font-weight: 700;
    color: #ee3a3b;
  }

  .has-error-auth {
    border: 2px solid var(--color-danger);
    border-radius: 3px;
    padding: 0 10px 0 10px;
    margin: 0px 10px 0px 0px;
    height: 44px;
    color: #666;
    width: 33%;

    &:focus-within,
    &:focus-visible {
      border-color: var(--color-primary);
      box-shadow: 0px 0px 6px 0px rgba(81, 149, 213, 0.65);
      outline: none;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .pixie-auth {
    padding-top: 10px;
    margin: auto;
    width: 50%;
  }

  .auth-button {
    font-size: 16px;
    width: 100%;
    &:not(.Mui-disabled) {
      background-color: var(--color-button);
    }
  }

  .visibility {
    font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 48;
    color: var(--color-newBrandingPrimary);
  }

  .pixie-sign-in-header {
    text-align: center;
    font-weight: 700;
    font-size: 26.25px;
    font-family: 'Open Sans', sans-serif;
  }
  .pixie-legal-info {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
  }
`;
