import { createSlice } from '@reduxjs/toolkit';
import { triggerGridResizer } from '../../utils';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  resetLoadingStates,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  isLoading: false, // to override LOADING_STATES.isLoading
  appealBarOpen: false,
  input: '',
  note: 'Submit appeal reasoning in the following field.'
};

const initialState = DEFAULT_STATE;

const appealInputSlice = createSlice({
  name: 'appeal',
  initialState,
  reducers: {
    answerAppealInput(state, { payload }) {
      const { textAnswer } = payload;
      state.input = textAnswer;
    },
    clearAppealError: state => clearError(state),
    clearAppealSuccess: clearSuccess,
    closeAppealBar(state) {
      triggerGridResizer();
      state.appealBarOpen = false;
    },
    openAppealBar(state) {
      triggerGridResizer();
      state.appealBarOpen = true;
    },
    resetAppealForm: () => DEFAULT_STATE,
    resetAppealLoadingStates: resetLoadingStates,
    setAppealError: loadingFailed,
    setAppealSuccess: loadingSuccess,
    startAppealLoading: startLoading,
    stopAppealLoading: stopLoading
  }
});

export const {
  answerAppealInput,
  clearAppealError,
  clearAppealSuccess,
  closeAppealBar,
  openAppealBar,
  resetAppealForm,
  resetAppealLoadingStates,
  setAppealError,
  setAppealSuccess,
  startAppealLoading,
  stopAppealLoading
} = appealInputSlice.actions;

export default appealInputSlice.reducer;
