import CONFIG from '@pixie/config';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  closeAppealBar,
  closeQuestionSet,
  disableActionCardActions,
  resetAppealForm,
  resetBenefitsInfo,
  resetCancelPaRequest,
  resetLatestTask,
  resetPatientInfo,
  resetPharmacyInfo,
  resetPrescriberInfo,
  resetPrescriptionInfo,
  resetQuestionSet,
  setCaseCodeForAuthCode,
  setAccessToken,
  setCaseCode,
  setCasePriority,
  setCaseStatus,
  setCaseStatusReason,
  resetTreatmentInfo
} from '@pixie/features';
import '../api/axios-config';
import axios, { AxiosResponse } from 'axios';

const { SESSION } = CONFIG;
const { API } = CONFIG;

const useSignOut = () => {
  const { ACCESS_TOKEN, CASE_CODE, APIGEE_TOKEN, NEXT_TOKEN_REFRESH_AT } = SESSION;
  const { LOGOUT_URL } = API;
  const dispatch = useDispatch();
  const history = useHistory();

  return (): Promise<boolean> => {
    dispatch(closeAppealBar());
    dispatch(closeQuestionSet('')); // Set the question bar closed
    dispatch(disableActionCardActions());
    dispatch(resetAppealForm());
    dispatch(resetBenefitsInfo());
    dispatch(resetCancelPaRequest());
    dispatch(resetLatestTask());
    dispatch(resetPatientInfo());
    dispatch(resetPharmacyInfo());
    dispatch(resetPrescriberInfo());
    dispatch(resetPrescriptionInfo());
    dispatch(resetQuestionSet(''));
    dispatch(resetTreatmentInfo());
    dispatch(setCaseCodeForAuthCode(null)); // Clear out the case code from Redux Store
    dispatch(setAccessToken(null)); // Clear out the access token from Redux Store
    dispatch(setCaseCode(null));
    dispatch(setCasePriority(null));
    dispatch(setCaseStatus(null));
    dispatch(setCaseStatusReason(null));
    return new Promise((resolve, reject) => {
      if (window.sessionStorage.getItem(ACCESS_TOKEN)) {
        const logoutRequest = {
          access_token: window.sessionStorage.getItem(ACCESS_TOKEN)
        };
        const json = JSON.stringify(logoutRequest);
        axios
          .post(`/${LOGOUT_URL}`, json, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response: AxiosResponse) => {
            if ((window as any).refreshTokenInterval !== undefined) {
              window.clearInterval((window as any).refreshTokenInterval);
            }
            const currentSessionSecret = window.sessionStorage.getItem(ACCESS_TOKEN);
            const currentSessionCaseCode = window.sessionStorage.getItem(CASE_CODE);
            const currentApigeeToken = window.sessionStorage.getItem(APIGEE_TOKEN);
            const currentNextTokenRefreshAt = window.sessionStorage.getItem(NEXT_TOKEN_REFRESH_AT);

            // Clear the session storage secret
            if (currentSessionSecret) window.sessionStorage.removeItem(ACCESS_TOKEN);
            if (currentSessionCaseCode) window.sessionStorage.removeItem(CASE_CODE);
            if (currentApigeeToken) window.sessionStorage.removeItem(APIGEE_TOKEN);
            if (currentNextTokenRefreshAt) window.sessionStorage.removeItem(NEXT_TOKEN_REFRESH_AT);
            // push back to login screen
            history.push('/Login');
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve(true);
      }
    });
  };
};

export default useSignOut;
