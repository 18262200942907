import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState as Root } from '@pixie/store';
import { Modal } from '@pixie/components';
import { setShowSubmitModalFalse } from '@pixie/features';
import InitiationAwaitingResponseModalForm from './InitiationAwaitingResponseModalForm';
import * as Styled from './InitiationAwaitingResponseModal.styled';


interface IInitiationAwaitingResponseModalProps {
  handleRefreshRequest: any;
}
const InitiationAwaitingResponseModal = (props: IInitiationAwaitingResponseModalProps): ReactElement => {

  const {
    handleRefreshRequest
  } = props;

  const questionSet = useSelector((state: Root) => state.questionSet);
  const [closedModal, setClosedModal ] = useState<boolean>(false);
  const dispatch = useDispatch();


  const onCloseModal = () => {
    setClosedModal(true);
  };

  if (!questionSet?.showSubmitModal) {
    return <></>;
  }

  return (
    <Styled.InitiationAwaitingResponseModal data-testid="test">
      <Modal
        id="InitiationAwaitingResponseModal"
        dataTestId='initiation-awaiting-response-modal'
        isOpen
        shouldCloseOnEscKey={false}
        shouldCloseOnOverlayClick={false}
        dialogClassName='pixie-modal-dialog-questionSet'
      >
        <button
          aria-label="Close"
          className="uk-modal-close-default uk-close-large close-modal-button"
          data-testId="initiation-awaiting-response-close-button"
          onClick={() => onCloseModal()}
          data-uk-close
          type="button"
        />
        <InitiationAwaitingResponseModalForm
          handleRefreshRequest={handleRefreshRequest}
          closeModal={onCloseModal}
          isClosed={closedModal}
        />

      </Modal>
    </Styled.InitiationAwaitingResponseModal>
  );
};
export default InitiationAwaitingResponseModal;
