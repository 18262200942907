import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UAParser from 'ua-parser-js';
import { EbvQueryPage, TheLicensePage } from '@pixie/pages';
import UIkit from 'uikit';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import CONFIG from './config';
import { DebugMenu, TheAppFooter, TheAppNavbar } from './components';
import { useAuthenticationCheck, useSignOut, usePollTokenRefresh } from './hooks';
import { setAppBodyWidth, setAppIsDevEnv, setAppUserAgent } from './features';
import { RootState } from './store';
import { RequestDetailPages, TheLoginPage } from './pages';

window.UIkit = UIkit;

declare global {
  interface Window {
    __env: any;
    UIkit: any;
  }
}

const {
  APP: { USE_AUTH_FLOW }
} = CONFIG;

const App = (): ReactElement => {
  // -- hooks
  const dispatch = useDispatch();
  const routerLocation = useLocation();
  const ref = useRef(null);
  const pollTokenRefresh = usePollTokenRefresh();

  // -- redux lookups
  const app = useSelector((state: RootState) => state.app);
  const appeal = useSelector((state: RootState) => state.appeal);
  const questionSet = useSelector((state: RootState) => state.questionSet);

  // custom vars
  const appealBarOpen = appeal?.appealBarOpen;
  const questionSetOpen = questionSet?.questionSetOpen;
  const isDevEnv = process.env.NODE_ENV === 'development';
  const parser = new UAParser();
  const parserResult = parser.getResult();
  const browserName = parserResult?.browser?.name;
  const osName = parserResult?.os?.name;
  const deviceType = parserResult?.device?.type;
  const requestPaths = ['/Request', '/Requests'];

  /**
   * handle public/env.js
   */
  // eslint-disable-next-line no-underscore-dangle
  const env = window?.__env;
  const [envIsLoading, setEnvIsLoading] = useState(true);
  useEffect(() => env && setEnvIsLoading(false), [env]);

  /**
   * handle body width resizing
   */
  useEffect(() => {
    const node = ref.current as any;
    setTimeout(() => dispatch(setAppBodyWidth(node.offsetWidth)), 300);
  }, [questionSetOpen, appealBarOpen, dispatch]);

  /**
   * handle user agent sniffing and modifying body attrs
   */
  useEffect(() => {
    parser && dispatch(setAppUserAgent(parserResult));

    browserName && document.body.setAttribute('data-browser', browserName);
    osName && document.body.setAttribute('data-os', osName);
    deviceType && document.body.setAttribute('data-device-type', deviceType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * handle token refresh polling
   */
  useEffect(() => {
    pollTokenRefresh();
  }, [pollTokenRefresh]);

  /**
   * handle if dev env or not
   */
  useEffect(() => {
    isDevEnv && dispatch(setAppIsDevEnv(isDevEnv));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPatientAccessUi = window.location.hostname.toLowerCase().startsWith('patientaccess');
  const instance = createInstance({
    urlBase: env.matomoUrl,
    siteId: env.matomoSiteId,
    trackerUrl:  `${env.matomoUrl}/matomo.php`,
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 15 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
  });

  return (
    <div className="App">
      <MatomoProvider value={instance}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <TheAppNavbar
          signOut={useSignOut()}
          isPatientAccessUi={isPatientAccessUi}
          location={routerLocation}
        />

        <main ref={ref}>
          <Switch>
            <Route exact path="/">
              <TheLoginPage
                isPatientAccessUi={isPatientAccessUi}
              />
            </Route>

            <Route path="/Login">
              <TheLoginPage
                isPatientAccessUi={isPatientAccessUi}
              />
            </Route>

            <Route path="/EbvQuery">
              <EbvQueryPage />
            </Route>

            <Route path="/Licensing">
              <TheLicensePage />
            </Route>
            {USE_AUTH_FLOW ? (
              <PrivateRoute path={requestPaths}>
                <RequestDetailPages envIsLoading={envIsLoading} />
              </PrivateRoute>
            ) : (
              <Route path={requestPaths}>
                <RequestDetailPages envIsLoading={envIsLoading} />
              </Route>
            )}

            {USE_AUTH_FLOW ? (
              <PrivateRoute path={requestPaths}>
                <RequestDetailPages envIsLoading={envIsLoading} />
              </PrivateRoute>
            ) : (
              <Route path={requestPaths}>
                <RequestDetailPages envIsLoading={envIsLoading} />
              </Route>
            )}
          </Switch>
        </main>
        <TheAppFooter
          location={routerLocation}
          logoAlt="Pixie"
          logoSrc="../../logo_white_red.svg"
          logoHeight="auto"
          logoWidth="148px"
        />

        {app?.isDevEnv && (
        <>
          <DebugMenu />
        </>
        )}
      </MatomoProvider>
    </div>
  );
};

export default App;

interface IPrivateRouteProps {
  children: ReactNode;
  path: string[];
}

function PrivateRoute({ children, ...rest }: IPrivateRouteProps) {
  const isAuthenticated = useAuthenticationCheck();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/Login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
