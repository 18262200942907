import { answerFreeTextQuestion } from '../questionSet.slice';

/**
 * Sends freeText response data to Redux store
 */
const handleFreeText = (questionId: string, textAnswer: string) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(
    answerFreeTextQuestion({
      questionId,
      textAnswer
    })
  );
};

export default handleFreeText;
