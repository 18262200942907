import { closeQuestionSet } from '@pixie/features';

/**
 * Dispatches opening or closing of the CancelModal.
 * @param event
 */
const handleCancelModal = (
  e: React.TouchEvent | React.MouseEvent,
  questionSetOpen: boolean
) => dispatch => {
  const target = e.target as HTMLButtonElement;
  e.preventDefault();
  target.blur();

  if (questionSetOpen) dispatch(closeQuestionSet(''));
};

export default handleCancelModal;
