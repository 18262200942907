import { createSlice } from '@reduxjs/toolkit';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  isLoading: false, // to override LOADING_STATES.isLoading
  canCancel: false,
  cancelButtonEnabled: false,
  cancelReasonCode: '',
  cancelReasonFreeTextAnswer: '',
  isCancelled: false
};

const initialState = DEFAULT_STATE;

const cancelPaRequestSlice = createSlice({
  name: 'cancelPaRequest',
  initialState,
  reducers: {
    clearCancelPaRequestError: state => clearError(state),
    clearCancelPaRequestSuccess: clearSuccess,
    disableCancelPaRequest(state) {
      state.canCancel = false;
    },
    enableCancelPaRequest(state) {
      state.canCancel = true;
    },
    resetCancelPaRequest: () => DEFAULT_STATE,
    resetCancelPaRequestIsCancelled(state) {
      state.isCancelled = false;
    },
    setCancelPaRequestButtonEnabled(state, { payload }) {
      state.cancelButtonEnabled = payload;
    },
    setCancelPaRequestError: loadingFailed,
    setCancelPaRequestIsCancelled(state) {
      state.isCancelled = true;
    },
    setCancelPaRequestSuccess: loadingSuccess,
    startCancelPaRequestLoading: startLoading,
    stopCancelPaRequestLoading: stopLoading
  }
});

export const {
  clearCancelPaRequestError,
  clearCancelPaRequestSuccess,
  disableCancelPaRequest,
  enableCancelPaRequest,
  resetCancelPaRequest,
  resetCancelPaRequestIsCancelled,
  setCancelPaRequestButtonEnabled,
  setCancelPaRequestError,
  setCancelPaRequestIsCancelled,
  setCancelPaRequestSuccess,
  startCancelPaRequestLoading,
  stopCancelPaRequestLoading
} = cancelPaRequestSlice.actions;
export default cancelPaRequestSlice.reducer;
