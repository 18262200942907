/* eslint-disable no-param-reassign */

const RE = /(\{([^\}]+)\})/;
const formatUserFriendlyMessages = (ufm, tokens ) => {
  // process token if defined
  if (tokens && ufm) {
  // loop until all tokens are removed
    for (let m; m = ufm.match(RE); ) {
      // get token replacement func or use default func for missing tokens
      const fn = tokens.replacementTokens[m[2]] || (() => `??${m[2]}??`);
      // replace token with func value
      ufm = ufm.replace(m[1], fn);
    }
  }
  return ufm;
};

export default formatUserFriendlyMessages;
