import './axios-config';
import axios, { AxiosResponse } from 'axios';
import CONFIG from '@pixie/config';
import { IPayerAttachment } from './request-details.model';
import { IServiceResponse } from './service-response';

const {
  API: { FILENAME_URL }
} = CONFIG;

const getFileDownload = (
  fileName: string,
  fileType: string
): Promise<IPayerAttachment> => {
  return new Promise((resolve, reject) => {
    let contentType = `image/${fileType}`;
    if (fileType.toLowerCase() === 'pdf') {
      contentType = 'application/pdf';
    }

    axios
      .post(`${FILENAME_URL}`, fileName, {
        headers: {
          'Content-Type': contentType
        }
      })
      .then((response: AxiosResponse<IServiceResponse<IPayerAttachment>>) => {
        resolve(response.data.result);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default getFileDownload;
