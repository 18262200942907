import styled from 'styled-components';

export const Footer = styled.div`
  background-color: var(--color-black);
  border: 2px solid var(--color-black);
  padding: 10px 0;
  position: relative;

  .link {
    color: var(--color-tertiary--100);
  }

  @media (min-width: 960px) and (min-height: 925px) {
    right: 0;
    bottom: 0;
    left: 0;

    &.login-screen {
      position: absolute;
    }
  }

  @media (max-width: 959px) {
    .uk-navbar {
      flex-flow: column nowrap;
    }

    .pixie-cah-logo {
      justify-content: center;
      padding: 10px 0;
    }

    .pixie-cah-legal {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .pixie-cah-logo-img {
    image-rendering: pixelated;
  }

  .pixie-cah-footer-list {
    padding: 0;
    list-style-type: none;
    text-align: center;

    @media (min-width: 960px) {
      margin: 0;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
    }
  }

  .pixie-cah-legal {
    font-size: 12px;
    color: var(--color-tertiary--100);

    /* @media (max-width: 959px) {
      display: block;
    } */
  }

  .pixie-cah-legal-pad.separator {
    padding: 0 5px;

    @media (max-width: 959px) {
      transform: rotate(90deg);
    }
  }
`;
