import styled from 'styled-components';

export const InitiationConfirmationForm = styled.div`
  flex-wrap: wrap;
  display: flex;
  page-break-before: auto;

  .pixie-form {
    width: 100%;
  }

  .pixie-modal-header {
    font-size: 17px;
    padding: 0px 0px 15px;
  }

  .pixie-header {
    flex-direction: row;
    padding: 35px 30px 30px 30px;
  }

  .pixie-body {
    flex-direction: row;
    display: block;
    width: 100%;
    padding: 15px 30px 30px 30px;
  }

  .pixie-patient-name {
    flex-direction: row;
    position: center;
  }

  .pixie-body-header {
    padding: 35px 50px 20px 20px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .pixie-body-inputs {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .pixie-body-margin {
    position: relative;
    width: 20%;
    margin-right: 30px;
    margin-top: 10px;
  }
  .pixie-modal-text {
    position: relative;
    padding-top: 15px;
    margin-right: 30px;
    padding-bottom: 20px;
    width: 40%;
  }

  .pixie-text-required {
    font-family: 'Open Sans';
    font-size: 13px;
  }

  .pixie-lower-inputs {
    position: relative;
    width: 33%;
    margin-right: 30px;
    padding-top: 15px;
  }

  .pixie-checkbox-background {
    background: var(--color-priority);
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .pixie-checkbox {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    align-items: baseline;
    padding-right: 50px;
  }

  .pixie-priority-text {
    position: relative;
    width: auto;
    margin-right: 50px;
  }

  .pixie-header-text {
    position: relative;
    display: flex;
  }

  .pixie-header-text-one {
    position: relative;
    display: flex;
    margin-right: 20px;
    font-size: 18px;
  }

  .pixie-modal-button-test {
    position: center;
    display: flex;
    justify-content: center;
    padding: 30px 30px 30px 30px;
    float: center;
  }
  .pixie-outline {
    margin-left: 7%;
    margin-right: 7%;
    border: 2px solid var(--color-tertiary);
  }
  .pixie-text-field {
    font-size: 14px;
  }
  .horizontal-line {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
