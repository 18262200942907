import styled from 'styled-components';

export const BuyAndBillForm = styled.div`
  width: 100%;
  flex-wrap: wrap;
  color: black;
  font-family: 'Open Sans', sans-serif;

  .buy-and-bill-background {
    background: #f9fafb;
  }

  .buy-and-bill-section {
    width: 65%;
    // float: center;
    margin: auto;
    padding-bottom: 50px;
  }
  .additional-info-required {
    margin: auto;
    width: 50%;
    padding-top: 50px;
    font-size: 1.875rem;
    font-weight: 700;
  }
  .buy-and-bill-info {
    background: #00000;
    margin: auto;
    width: 75%;
    font-size: 1.125rem;
    padding-bottom: 20px;
  }
  .error-start-date {
    border: 1px solid red;
  }
  .pixie-form-width {
    width: 100%;
  }
  .pixie-form {
    border: 2px solid var(--color-tertiary--50);
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
    background: #fff;
  }

  .diagnosis-and-medication-names {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 40px;
    padding-top: 20px;
  }

  .pixie-header {
    position: relative;
    display: table;
    font-weight: 700;
    font-size: 11.38px;
    width: 100%;
    padding-top: 20px;
  }
  .pixie-lower-inputs {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-top: 20px;
  }

  .pixie-ancillary-section {
    width: 100%;
    display: flex;
    padding-top: 20px;
  }

  .pixie-header-medication {
    font-weight: 400;
    font-size: 1.5rem;
  }
  .diagnosis-and-medication-info {
    width: 50%;
  }
  .medication-amounts {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 40px;
  }
  .diagnosis-and-medication-amount-info {
    width: 25%;
  }
  .diagnosis-and-medication-is-buy-bill {
    width: 50%;
  }
  .diagnosis-and-medication-header-text {
    font-size: 0.8125rem;
    font-weight: 700;
  }
  .diagnosis-and-medication-body-text {
    font-size: 1.125rem;
    font-weight: 400;
  }
  .buy-and-bill-small-text-input {
    width: 130px;
    margin-right: 10px;
  }
  .buy-and-bill-large-input {
    width: 443px;
    margin-right: 10px;
  }
  .buy-and-bill-billing-code {
    width: 20%;
    flex-wrap: nowrap;
  }

  .pixie-legend-required {
    font-size: 11.38px;
    font-weight: 700;
  }
  .pixie-legend-optional {
    font-size: 11.38px;
    font-weight: 700;
  }

  .pixie-legend-required:after {
    content: '*';
    color: black;
  }
  .buy-and-bill-button-section {
    padding-bottom: 20px;
  }
  .provider-first-name-section {
    width: 326px;
    padding-right: 10px;
  }
  .date-picker {
    height: 2em;
  }
  .provider-last-name-section {
    width: 326px;
  }
  .pixie-provider-address-section {
    width: 467px;
    padding-right: 10px;
  }
  .provider-unit-section {
    width: 174px;
    margin-left: 10px;
  }
  .pixie-provider-npi-section {
    width: 174px;
    margin-left: 40px;
  }
  .provider-city-section {
    width: 359px;
    padding-right: 10px;
  }
  .provider-state-section {
    width: 92px;
    padding-right: 10px;
    margin-left: 15px;
  }
  .provider-zip-section {
    width: 174px;
    margin-left: 12px;
  }
  .phone-symbol {
    margin-top: 25px;
    // margin-right: 10px;
    padding-right: 10px;
    // color: #0000;
    font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 0, 'opsz' 24;
  }
  .provider-facility-name {
    width: 650px;
  }
  .fax-symbol {
    margin-top: 25px;
    padding-left: 35px;
    padding-right: 10px;
    // color: #0000;
    font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 0, 'opsz' 24;
  }
  .submit-button {
    padding-right: 10px;
  }
  .back-bbuton {
    padding-right: 10px;
  }
  .cancel-button {
    float: right;
  }
  .ancillary-service-code {
    width: 55%;
    margin-right: 10px;
  }
  .ancillary-service-code-type {
    // width: 35%;
  }
  .ancillary-code-type-section {
    // width: 100%;
    display: flex;
    flex-wrap: nowrap;
  }

  .delete-symbol {
    font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 0, 'opsz' 24;
    color: var(--color-newBrandingPrimary);
    margin-left: 10px;
    margin-top: 25px;
  }
  .phone-input-section {
    padding-right: 10px;
    width: 166px;
  }
  .fax-number-input-section {
    padding-right: 10px;
    width: 166px;
  }
  .add-ancillary-button {
    margin-top: 10px;
  }
  .start-date-section {
    width: 150px;
    margin-right: 10px;
  }
  .pixie-buy-and-bill-radio {
    margin-right: 10px;
  }
  .pixie-buy-and-bill {
    padding-top: 20px;
  }

  .pixie-header-rendering-service {
    font-size: 21px;
    padding-bottom: 10px;
  }
  .error-symbol {
    vertical-align: middle;
    font-size: 16px;
    font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 20;
    color: var(--color-error);
  }
  .error-message {
    color: #ee3a3b;
    font-size: 11.38px;
  }
  .divider-line {
    border-color: var(--color-lineGrey);
  }
  .MuiInputBase-adornedEnd {
    width: 100%;
    height: 44px;
    font-family: 'Open Sans', sans-serif;
    // color: var(--color-newBrandingPrimary);

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-borderGrey);
      border-width: 1px;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-borderGrey);
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-primary);
      box-shadow: 0px 0px 6px 0px rgba(81, 149, 213, 0.65);
      outline: none;
    }
  }
`;
