import './axios-config';
import axios, { AxiosResponse } from 'axios';
import CONFIG from '@pixie/config';

const { API } = CONFIG;

/**
 * Sends a POST request to the API's appeal endpoint.
 *
 * @param caseCode
 * @param appealInput
 * @requires axios
 * @requires axios-config
 * @requires pixie/config
 */
const sourceProgramRequest = (
  programName: string | null
) => {
  const { SOURCE_PROGRAM_REQUEST_URL } = API;
  const urlEncodedProgramName = encodeURI(programName);

  return new Promise((resolve, reject) => {
    axios
      .get(`/${SOURCE_PROGRAM_REQUEST_URL}/${urlEncodedProgramName}`)
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default sourceProgramRequest;
