import CONFIG from '@pixie/config';
import axios from 'axios';

const { API } = CONFIG;

const { SESSION } = CONFIG;

const { ACCESS_TOKEN, CASE_CODE, APIGEE_TOKEN } = SESSION;

/**
 * This sets config options that are shared for all axios requests.
 * Needs to be imported into all api files so that each api call can use
 * these common configuration values.
 */
axios.interceptors.request.use(request => {
  const { BASE_URL } = API;
  const { AUTHN_BASE_URL } = API;
  let url = BASE_URL;

  if (request.url.includes('authn') || request.url.includes('token') || request.url.includes('logout')) {
    url = AUTHN_BASE_URL;
  } else {
    const accessToken = window?.sessionStorage.getItem(ACCESS_TOKEN);
    const caseCode = window?.sessionStorage.getItem(CASE_CODE);
    const apigeeAccessToken = window?.sessionStorage.getItem(APIGEE_TOKEN);

    // TODO remove if logic eventually and force API calls to specific BASE URL
    if (request.method === 'put' || request.url.includes('generator') || request.url.includes('initiate') || request.url.includes('eligibility')) {
      url = BASE_URL.replace(/(outbound)/g, 'inbound');
    }
    request.headers.Authorization = `Bearer ${apigeeAccessToken}`;
    request.headers['x-auth-token'] = `Bearer ${accessToken}`;
    request.headers['x-case-code'] = caseCode;
    request.headers['x-initiated-by'] = 'prescriber';
  }

  if (request.url !== API.FILENAME_URL) {
    request.responseType = 'json';
  }

  request.baseURL = url;
  request.validateStatus = status => {
    return status >= 200 && status < 400;
  };

  return request;
});
