import React from 'react';
import * as Styled from './Radio.styled';

interface IRadioProps {
  checked?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  id: string;
  label: string;
  name: string;
  onClick: any;
}

const Radio = (props: IRadioProps) => {
  const { checked,
    dataTestId,
    disabled,
    id,
    label,
    name,
    onClick } = props;

  return (
    <Styled.Label
      className={disabled ? 'uk-disabled' : ''}
      htmlFor={`${name}_${id}`}
    >
      <input
        className="uk-radio"
        data-testId={dataTestId}
        defaultChecked={checked}
        disabled={disabled}
        id={`${name}_${id}`}
        name={name}
        onChange={onClick}
        type="radio"
        value={label}
      />
      <span>{label}</span>
    </Styled.Label>
  );
};

Radio.defaultProps = {
  checked: false,
  dataTestId:null,
  disabled: false,
};

export default Radio;
