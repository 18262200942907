/* eslint-disable react/style-prop-object */
import { ReactElement, useCallback } from 'react';
import * as React from 'react';
import CONFIG from '@pixie/config';
import { ActionCard, Card, Label } from '@pixie/components';
import ErrorMessage from './ErrorMessage';
import Timestamp from './Timestamp';
import TimestampRefreshButton from './TimestampRefreshButton';
import * as Styled from './TheRequestHeader.styled';

const {
  ACTIONS: { ACTION_STATES },
  LABELS,
  STATUS_ICONS
} = CONFIG;

interface IStatusAndActionsProps {
  actionCard: {
    actionsAvailable: boolean;
    currentAction: string;
    headline: string;
    instructions: string[];
    isLoading: boolean;
    phoneNumber?: string;
    respondBy?: string;
  };
  appError: string;
  bodyWidth: number;
  caseStatus: string;
  children: any;
  expectedResponseDate: string;
  isLoading: boolean;
  isReviewerApp: boolean;
  refreshRequest: () => void;
  timestamp: string;
}

const StatusAndActions = (props: IStatusAndActionsProps): ReactElement => {
  const {
    actionCard,
    appError,
    bodyWidth,
    caseStatus,
    children,
    expectedResponseDate,
    isLoading,
    isReviewerApp,
    refreshRequest,
    timestamp
  } = props;

  const handleWidthClass = useCallback(
    (elseClass: string) => (bodyWidth <= 959 ? 'uk-width-1-1' : elseClass),
    [bodyWidth]
  );

  const getLabelText = () => {
    if (actionCard?.currentAction === ACTION_STATES.QUESTION) {
      return 'Response Required';
    }
    if (caseStatus) {
      return LABELS[caseStatus];
    }
    return 'Unknown';

  };
  return (
    <Styled.StatusAndActions id="StatusAndActions" className="uk-margin">
      <div className="uk-grid uk-grid-match uk-animation-fade" data-uk-grid>
        <div className={handleWidthClass('uk-width-1-3@m')}>
          <span className="pixie-h2">Request Status</span>
          <Card style="status">
            <div className="status-icon-error">
              {actionCard?.currentAction === ACTION_STATES.QUESTION ? (
                <span className="material-symbols-outlined status-icon-actions_alert">
                  error
                </span>
              ) : (
                <span className={`material-symbols-outlined status-icon-${STATUS_ICONS[caseStatus]}`}>
                  {STATUS_ICONS[caseStatus] ? `${STATUS_ICONS[caseStatus]}` : 'error'}
                </span>
              )}
            </div>
            <Label
              labelStyle="pixie-h2"
              loading={isLoading}
              text={getLabelText()}
            />

            <Timestamp
              dataTestId='status-and-actions-timestamp'
              text={timestamp}
            />

            {expectedResponseDate && (
              <>
                <div className="response-required uk-animation-slide-bottom-small">
                  <strong>Expected Response Date:&nbsp;</strong>
                  <span>{expectedResponseDate}</span>
                </div>
              </>
            )}

            {timestamp && (
              <>
                <TimestampRefreshButton
                  isDisabled={isLoading}
                  isActionAvailable={actionCard?.currentAction}
                  data-testid="status-and-actions-timestamp-refresh"
                  onClick={refreshRequest}
                />
              </>
            )}
          </Card>
        </div>

        <div className={handleWidthClass('uk-width-2-3@m')}>
          <span className="pixie-h2">Available Actions</span>
          <Card style={actionCard?.currentAction === ACTION_STATES.QUESTION ? 'questions' : ''}>
            {appError !== '' ? (
              <>
                <ErrorMessage
                  dataTestId='status-and-actions-error-message'
                  message={appError !== '' && appError}
                />
              </>
            ) : (
              <>
                <ActionCard
                  actionsAvailable={actionCard?.actionsAvailable}
                  bodyWidth={bodyWidth}
                  dataTestId='status-and-actions-action-card'
                  headline={actionCard?.headline}
                  instructions={actionCard?.instructions}
                  phoneNumber={actionCard?.phoneNumber}
                  respondBy={actionCard?.respondBy}
                  isReviewerApp={isReviewerApp}
                  loading={isLoading}
                >
                  {children}
                </ActionCard>
              </>
            )}
          </Card>
        </div>
      </div>
    </Styled.StatusAndActions>
  );
};

export default StatusAndActions;
