/**
 * Formats the provided `date` param into a UTC `MM/DD/YYYY` string format.
 *
 * @example
 * ```js
 *  formatDate('2020-07-07T00:00:00.000Z');         // '07/07/2020'
 *  formatDate('2021-01-07T00:00:00.000Z');         // '01/07/2020'
 *  formatDate('2021-08-04T15:12:41.709Z');         // '08/04/2021'
 *  formatDate('2021-08-04T15:12:41.709Z', true);   // '08/04/2021 3:12:41 PM'
 * ```
 *
 * @param date string | Date | undefined
 * @param useTimestamp boolean
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 */
const formatDate = (
  date: string | Date | undefined,
  useTimestamp: boolean = false
): string | undefined => {
  /**
   * Eject if the date param is not provided.
   */
  if (!date) return undefined;

  /**
   * Check if the data param is a string and if the timestamp is 000 etc.
   * If so, regex out the individual parts of the date and return that.
   * This gets around the timezone conversion bug where running
   * `2020-07-07T00:00:00.000Z` thru our function converts that from UTC to
   * any timezone prior to UTC (Central, for example) and returns a date that
   * is a day before the provided date.
   */
  if (typeof date === 'string' && date.includes('T00:00:00.000Z')) {
    const yearRegex = date.match(/(\d{4}|\d{2}^)/g);
    const monthRegex = date.match(/(-[0-9]?[0-9]-)/g);
    const dayRegex = date.match(/(0?[1-9]|[12]\d|30|31)T/g);

    // Make sure to eject if there's no match,
    // so as to not break patientInfo.slice
    if (!yearRegex || !monthRegex || !dayRegex) return null;

    const year = yearRegex[0];
    const month = monthRegex[0].replace(/(-)/g, '');
    const day = dayRegex[0].replace('T', '');

    switch (useTimestamp) {
      case true:
        return `${month}/${day}/${year}, 12:00:00 AM`;

      case false:
      default:
        return `${month}/${day}/${year}`;
    }
  }

  /**
   * Defines the locale for consistency.
   */
  const locale = 'en-us';

  /**
   * Defines the parameters of each option for consistency.
   */
  // prettier-ignore
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',    // YYYY
    month: '2-digit',   // MM
    day: '2-digit',     // DD
    hour: 'numeric',    // h
    minute: '2-digit',  // mm
    second: '2-digit'   // ss
  };

  /**
   * In the following switch statement, we check what the `useTimestamp`
   * param value is and then return either a formatted date or dateTime string.
   *
   * If the `date` param comes in as a string, we first need to construct a
   * `new Date()` out of it — otherwise, we should just be able to format it.
   */
  switch (useTimestamp) {
    case true:
      return typeof date === 'string'
        ? new Date(date).toLocaleString(locale, options)
        : date.toLocaleString(locale, options);

    case false:
    default:
      if (typeof date === 'string') {
        return new Date(date).toLocaleString(locale, {
          year: options.year,
          month: options.month,
          day: options.day
        });
      }

      return date.toLocaleString(locale, {
        year: options.year,
        month: options.month,
        day: options.day
      });
  }
};

export default formatDate;
