import styled from 'styled-components';

interface Props {
  textWrap: boolean;
}

export const ReadonlyFormText = styled.div`
  box-sizing: border-box;
  display: block;
  font-size: 12px;

  .label {
    color: #121312;
    display: block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1em;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  .benefits_label {
    color: #121312;
    display: block;
    font-family: "Helvetica Neue";
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 2px;
    white-space: nowrap;
  }

  .benefits_text {5
    color: #121312;
    display: block;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 2px;
    white-space: normal;
  }

  .rtbc_label {
    color: #121312;
    display: block;
    font-family: "Helvetica Neue";
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 2px;
    margin-top:15px;
  }

  .rtbc_text {
    color: #121312;
    display: block;
    font-family: "Helvetica Neue";
    font-size: 13px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 2px;
    white-space: normal;
  }

  .rtbc_pay_amount_label {
    color: #121312;
    display: block;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 2px;
    margin-top:15px;
  }

  .rtbc_pay_amount_text {
    opacity: 1;
    color: rgba(45,41,40,1);
    font-family: "Helvetica Neue";
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
  }

  .benefits_text {
    color: #121312;
    display: block;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 2px;
  }

  .rtbc_drug_description_label {
    opacity: 1;
    color: rgba(22,22,22,1);
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-align: center;
  }

  .rtbc_drug_description_text {
    opacity: 1;
    color: rgba(45,41,40,1);
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    text-align: center;
  }

  .rtbc__section_header_label {
    opacity: 1;
    color: rgba(39,37,31,1);
    font-family: "HelveticaNeue-Bold";
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
  }

  p {
    color: var(--color-secondary--100);
    margin: 0;
    white-space: ${(props: Props) => (props.textWrap ? 'pre-wrap' : 'pre')};
    font-size: 1.35em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    line-height: 1.25;
    min-height: 21px;
  }

  p.loading-animation-swipe {
    color: transparent;
    width: 100%;
    display: block;
    height: 21px;
  }
  .title-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 17px;
    padding-right: 40px;
  }
  .title-large-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: black;
    padding-right: 40px;
  }
  .medical-title-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 17px;
    padding-right: 20px;
    color: black;
  }
  .medical-title-large-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: black;
  }
  .medical-title-normal-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: black;
    padding-right: 20px;
  }
  .medical-title-medication-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    color: black;
    padding-right: 20px;
    padding-left: 70px;
  }

  .medical-title-medication-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 17px;
    padding-right: 20px;
    padding-left: 70px;
    color: black;
  }
  .pixie-h6 {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 17px;
    color: black;
  }
  .pixie-h4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    color: black;
    padding-top: 4px;
  }
  .pixie-h2-prior-auth-info {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 22px;
    color: black;
    margin-bottom: 30px;
  }
`;

export default ReadonlyFormText;
