import CONFIG from '../../../config';
import { createAnswerObject, sortQuestionsArray } from '../utils';

const {
  QUESTIONS: { QUESTION_TYPES }
} = CONFIG;

/**
 * Redux action for answering a multi-selection (select) question.
 */
const answerMultiSelectQuestionAction = (state, { payload }) => {
  const { CHECKBOX } = QUESTION_TYPES;

  // deconstruct the payload
  const { questionId, choiceArray } = payload;

  /**
   * Find the slot (index) of the current question object
   * @memberof answerSelectQuestionAction
   */
  const curQuestionSlot = state.question.findIndex(
    q => q.questionId === questionId
  );

  /**
   * Sets up the new question object with
   * `answer.prescriberProvidedAnswer` appended
   * @memberof answerSelectQuestionAction
   */
  const answeredQuestion = {
    ...state.question[curQuestionSlot],
    questionType: {
      ...state.question[curQuestionSlot].questionType,
      select: {
        ...state.question[curQuestionSlot].questionType.select,
        answer: createAnswerObject(CHECKBOX, choiceArray)
      }
    }
  };

  /**
   * Creates a new array with state spread in, and the payload's
   * `answeredQuestion` object appended at the end
   * @memberof answerSelectQuestionAction
   * @requires answeredQuestion
   */
  const newQuestionArray = [
    ...state.question.filter(q => q.questionId !== questionId),
    answeredQuestion
  ];

  // sort the array set
  const sortedQuestionArray = sortQuestionsArray(newQuestionArray);

  // set the new question array as the sorted array
  state.question = sortedQuestionArray;
};

export default answerMultiSelectQuestionAction;
