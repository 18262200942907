/* eslint-disable global-require */
import React, { ReactElement, useState, useRef } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { IconButton, Input, SvgIcon, TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { Button, Select, TextInput } from '@pixie/components';
import * as Styled from './SignIn.styled';

export interface ISignInProps {
  authnButtonState?: boolean;
  isDevEnv: boolean;
  authCodeRef?: any;
  firstNameRef?: any;
  onAuthCodeChange?: any;
  onChange?: any;
  onClick?: any;
  onDobChange?: any;
  onMouseDown?: any;
  onSubmit?: any;
  signInButtonText?: string;
  status?: string;
  values?: any;
}

const SignIn = (props: ISignInProps): ReactElement => {
  const {
    authnButtonState,
    authCodeRef,
    firstNameRef,
    isDevEnv,
    onAuthCodeChange,
    onChange,
    onClick,
    onDobChange,
    onMouseDown,
    onSubmit,
    signInButtonText,
    status,
    values
  } = props;

  const [lastNameError, setLastNameError] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [authCodeError, setAuthCodeError] = useState<string>('');

  const nameErrorMessage = 'Name must be at least one character in length, using a standard keyboard alphabet';
  const authCodeErrorMessage = 'The code must have 5 letters or numbers per field, no dashes or no special characters.';

  const textInput = (params) => {
    const dobParams = {
      ...params,
      inputProps:{ ...params.inputProps, 'data-testid': 'sign-in-patient-dob', placeholder: 'MM/DD/YYYY' }
    };
    return (

      <TextField
        {...dobParams}
        sx={{
          // Color is newBrandingPrimary color
          svg: { color: 'black' }
        }}
      />
    );
  };

  const validateName = (param: any) => {
    if (param === 'lastName') {
      if (values?.lastName === '') {
        setLastNameError(nameErrorMessage);
      } else {
        setLastNameError('');
      }
    } else if (values?.firstName === '') {
      setFirstNameError(nameErrorMessage);
    } else {
      setFirstNameError('');
    }
  };

  const validateAuthCode = () => {
    if ((values?.authCodeFirst?.length !== 5 || values?.authCodeFirst?.includes('_'))
      || (values?.authCodeSecond?.length !== 5 || values?.authCodeSecond?.includes('_'))) {
      setAuthCodeError(authCodeErrorMessage);
    } else {
      setAuthCodeError('');
    }
  };

  const DatePickerIcon = () => {
    return (
      <span className="material-symbols-outlined date-picker">
        date_range
      </span>
    );
  };
  return (
    <Styled.Div>
      <div
        className={`pixie-sign-in ${
          status !== ''
            ? 'pixie-sign-in-border-error'
            : 'pixie-sign-in-border-success'
        }`}
      >
        <form onSubmit={onSubmit} data-testid="sign-in-form">
          <h4 className='pixie-sign-in-header'>Enter Authentication & Patient Information</h4>
          <h4 className="pixie-auth-code-highlight">Authentication Code</h4>

          <div>
            <legend className="legend-required">Enter Authentication Code</legend>
            <div className='uk-grid pixie-auth-code'>
              <div className={authCodeError ? 'has-error-auth' : 'pixie-auth-code-input'}>
                <InputMask
                  mask="*****"
                  maskChar="_"
                  className="auth-code-input-mask"
                  disabled={false}
                  onChange={onAuthCodeChange('authCodeFirst')}
                  value={values?.authCodeFirst}
                >
                  {() =>
                    <Input
                      className='authentication-code-input-field'
                      data-testid='sign-in-enter-authentication-code-1'
                      inputProps={{ 'data-testid': 'sign-in-auth-code' }}
                      type={values?.showAuthCode ? 'text' : 'password'}
                      placeholder="_____"
                    />
                }
                </InputMask>
              </div>
              <div className="pixie-auth-code-divider">
                -
              </div>
              <div className={authCodeError ? 'has-error-auth' : 'pixie-auth-code-input'}>
                <InputMask
                  mask="*****"
                  className="auth-code-input-mask"
                  disabled={false}
                  maskChar="_"
                  onChange={onAuthCodeChange('authCodeSecond')}
                  onBlur={() => validateAuthCode()}
                  value={values?.authCodeSecond}
                  type={values?.showAuthCode ? 'text' : 'password'}
                >
                  {() =>
                    <Input
                      inputRef={authCodeRef}
                      className='authentication-code-input-field'
                      data-testid='sign-in-enter-authentication-code-2'
                      inputProps={{ 'data-testid': 'sign-in-auth-code' }}
                      type={values?.showAuthCode ? 'text' : 'password'}
                      placeholder="_____"
                    />
                }
                </InputMask>
              </div>
              <IconButton onClick={onClick} onMouseDown={onMouseDown}>
                {values?.showAuthCode ? (
                  <span className="material-symbols-outlined visibility">
                    visibility
                  </span>
                ) : (
                  <span className="material-symbols-outlined visibility">
                    visibility_off
                  </span>
                )}
              </IconButton>
            </div>
            { authCodeError && (
              <div className='error-container-auth-code'>
                <span className='error-message-auth-code'> {authCodeError} </span>
              </div>
            )}
          </div>
          <div>
            <h4 className="pixie-auth-code-highlight pixie-patient-info">
              Patient Information
            </h4>
            <div className="pixie-patient">
              <legend className="legend-required">Patient First Name</legend>
              <TextInput
                dataTestId="sign-in-patient-first-name"
                forwardRef={firstNameRef}
                errorMessage={firstNameError}
                value={values?.firstName}
                onChange={onChange('firstName')}
                onBlur={() => validateName('firstName')}
              />
            </div>
            <div className="pixie-patient">
              <legend className="legend-required">Patient Last Name</legend>
              <TextInput
                dataTestId="sign-in-patient-last-name"
                errorMessage={lastNameError}
                value={values?.lastName}
                onChange={onChange('lastName')}
                onBlur={() => validateName('lastName')}
              />
            </div>
          </div>

          <div className="pixie-patient">
            <legend className="legend-required">Patient Date of Birth</legend>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  data-testId='sign-in-patient-date-of-birth'
                  value={values?.dob}
                  maxDate={new Date()}
                  onChange={newValue => {
                    onDobChange('dob', newValue);
                  }}
                  components={{ OpenPickerIcon: DatePickerIcon }}
                  renderInput={textInput}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="pixie-auth">
            <Button
              dataTestId="sign-in-sign-in"
              buttonStyle='refresh'
              onClick={onSubmit}
              text={signInButtonText}
              disabled={authnButtonState}
              type="button"
            />
          </div>
          <div className='pixie-legal-info'>
            <span> By submitting, you agree to the</span>
            <a
              href='../../terms-of-use.pdf'
              target='_blank'
              rel='noopener noreferrer'
              className='link'
              data-testId="the-app-footer-terms-of-us"
            > Terms of Use
            </a>
            <span> and</span>
            <a
              href='../../privacy-policy.pdf'
              target='_blank'
              rel='noopener noreferrer'
              className='link'
              data-testId="the-app-footer-privacy-policy"
            > Privacy Policy
            </a>
            <span> of Cardinal Health in how your data will be used and stored.</span>
          </div>
        </form>
      </div>
    </Styled.Div>
  );
};

SignIn.defaultProps = {
  authnButtonState: '',
  onChange: '',
  onClick: '',
  onDobChange: '',
  onMouseDown: '',
  onSubmit: '',
  signInButtonText: '',
  status: '',
  values: ''
};

export default SignIn;
