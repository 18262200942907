/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable func-names */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-anonymous-default-export */
import { createServer, Model, Response } from 'miragejs';
import CONFIG from './config';

const {
  API,
  SESSION: { SESSION_STATUS }
} = CONFIG;

const makeServer = ({ environment = 'development' } = {}) => {
  const {
    AUTHN_REQUEST_URL,
    REGEN_AUTH_CODE_URL,
    APPEAL_INIT_REQUEST_URL,
    BASE_URL,
    CANCEL_REQUEST_URL,
    CASE_DETAIL_URL,
    CASE_DETAIL_LIST_URL,
    FILENAME_URL,
    PA_REQUEST_URL,
    AUTHN_BASE_URL,
    SOURCE_PROGRAM_REQUEST_URL,
    SUBMIT_INITIATE_URL,
    EBV_ELIGIBILITY_URL
  } = API;

  return createServer({
    environment,

    models: {
      cases: Model,
      epas: Model,
      files: Model,
      sourceProgramDetails: Model,
      ebvs: Model
    },

    seeds(server) {
      // prettier-ignore
      if (process.env.NODE_ENV === 'development') {
        // cert cases
        server.create('case', require('./json/Cert ePA 1-1 (PA Init Request).json'));
        server.create('case', require('./json/Cert ePA 1-3 (PA Init Response).json'));
        server.create('case', require('./json/Cert ePA 1-4 (PA Request).json'));
        server.create('case', require('./json/Cert ePA 1-6 (PA Response Partially Denied).json'));
        server.create('case', require('./json/Cert ePA 2-1 (Init Request).json'));
        server.create('case', require('./json/Cert ePA 2-3 (Init Response).json'));
        server.create('case', require('./json/Cert ePA 2-4 (PA Request Sent).json'));
        server.create('case', require('./json/Cert ePA 2-6 (In_Process Response).json'));
        server.create('case', require('./json/Cert ePA 2-7 (Denied Response).json'));
        server.create('case', require('./json/Cert ePA 2-8 (Appeal Request Sent).json'));
        server.create('case', require('./json/Cert ePA 2-10 (Appeal Response Approved).json'));
        server.create('case', require('./json/Cert ePA 3-1 (Init Request).json'));
        server.create('case', require('./json/Cert ePA 3-2 (Init Response #1 Closed CD Code).json'));
        server.create('case', require('./json/Cert ePA 3-3 (Init Response #2 Closed BY Code).json'));
        server.create('case', require('./json/Cert ePA 4-1 (Init Request).json'));
        server.create('case', require('./json/Cert ePA 4-3 (Init Response).json'));
        server.create('case', require('./json/Cert ePA 4-4 (Cancel Request Sent).json'));
        server.create('case', require('./json/Cert ePA 4-6 (Cancel Response Approved).json'));
        server.create('case', require('./json/Cert ePA 5-1 (Init PA).json'));
        server.create('case', require('./json/Cert ePA 5-3 (Init Response #1 (IN_PROCESS)).json'));
        server.create('case', require('./json/Cert ePA 5-4 (Init Response #2 (IN_PROCESS)).json'));
        server.create('case', require('./json/Cert ePA 5-5 (PA Request Sent).json'));
        server.create('case', require('./json/Cert ePA 5-7 (PA Response).json'));
        server.create('case', require('./json/Cert ePA 6-2 (Init Request)'));
        server.create('case', require('./json/Cert ePA 6-4 (Init Response).json'));
        server.create('case', require('./json/Cert ePA 6-5 (PA Request Sent).json'));
        server.create('case', require('./json/Cert ePA 6-7 (Open Response).json'));
        server.create('case', require('./json/Cert ePA 6-8 (PA Request #2).json'));
        server.create('case', require('./json/Cert ePA 6-10 (Approved Response).json'));
        server.create('case', require('./json/Cert ePA 7-1 (Init Request).json'));
        server.create('case', require('./json/Cert ePA 7-3 (Init Response).json'));
        server.create('case', require('./json/Cert ePA 7-4 (PA Request Sent).json'));
        server.create('case', require('./json/Cert ePA 7-6 (PA Response).json'));
        server.create('case', require('./json/Medical ePA 1-1.json'));
        server.create('case', require('./json/Medical ePA 1-3.json'));
        server.create('case', require('./json/Medical ePA test.json'));

        server.create('case', require('./json/eBV Open Modal.json'));
        server.create('case', require('./json/eBV Skip Modal.json'));

        // specific scenarios
        server.create('case', require('./json/Closed Case Status Messaging.json'));
        server.create('case', require('./json/QuestionSet With Answered Questions.json'));
        server.create('case', require('./json/QuestionSet With Appeal Available.json'));
        server.create('case', require('./json/QuestionSet With Branching Paths.json'));
        server.create('case', require('./json/QuestionSet With More Branching Paths.json'));
        server.create('case', require('./json/QuestionSet With Document Download.json'));
        server.create('case', require('./json/QuestionSet With Document Upload.json'));
        server.create('case', require('./json/QuestionSet With Multi-Select.json'));
        server.create('case', require('./json/QuestionSet With Single Question.json'));
        server.create('case', require('./json/Latest Question Set From Whitt.json'));

        // multiple requests scenarios
        server.create('case', require('./json/Multiple EPAs 1'));
        server.create('epa', {
          initialSystemTrackingId: '1232145324',
          data: require('./json/Multiple EPAs from Atul.json')
        });
        server.create('epa', {
          initialSystemTrackingId: '1232145325',
          data: require('./json/Multiple EPAs from Atul Links Added.json')
        });

        // misc items
        server.create('case', require('./json/30c3d35cb69b444d966c4477151b3856.json'));
        server.create('file', {
          fileName: '2ff0eee5045e4b13a9e77f07f1423db2-PA_REQUEST-1628089952727.PDF',
          data: require('./json/PA_RESPONSE-1628090192877.json')
        });

        // source program details
        server.create('sourceProgramDetail', require('./json/source-program-detail-pfizer-dermatology.json'));

        server.create('ebv', require('./json/eBVOpenModalResponse.json'));
        server.create('ebv', require('./json/eBVCloseModalResponse.json'));
        server.create('ebv', require('./json/eBvSubmitEligibilityResponse.json'));
        server.create('ebv', require('./json/eBVResponse.json'));
      }
    },

    routes() {
      // set base url
      const API_OUTBOUND = BASE_URL;
      const API_INBOUND = BASE_URL.replace(/(outbound)/g, 'inbound');

      const getMatchedResultFromMatch = match => {
        const matchedResult = JSON.parse(JSON.stringify(match));
        return matchedResult;
      };
      const getResultWrappedWithServiceResponse = result => {
        const body = {
          result: result,
          message: null,
          status: 'SUCCESS'
        };
        return JSON.stringify(body);
      };

      // ==============================================================
      // api => authn
      // ==============================================================
      this.urlPrefix = AUTHN_BASE_URL;
      this.post(`/${AUTHN_REQUEST_URL}`, (schema, request) => {
        const attrs = JSON.parse(request.requestBody);

        if (attrs.authCode === 'invalid') {
          return new Response(
            200,
            {},
            JSON.stringify({
              status: SESSION_STATUS.INVALID,
              caseCode: '',
              accessToken: ''
            })
          );
        }

        if (attrs.authCode === 'expired') {
          return new Response(
            200,
            {},
            JSON.stringify({
              status: SESSION_STATUS.EXPIRED,
              caseCode: attrs.caseCode,
              accessToken: 'localToken'
            })
          );
        }

        if (attrs.authCode === 'valid') {
          return new Response(
            200,
            {},
            JSON.stringify({
              status: SESSION_STATUS.SUCCESS,
              caseCode: attrs.caseCode,
              accessToken: 'localToken'
            })
          );
        }

        const { requestBody } = request;
        return new Response(200, {}, `Authn SUCCESS: ${requestBody}`);
      });

      // ==============================================================
      // api => regen auth code
      // ==============================================================
      this.urlPrefix = API_INBOUND;
      this.post(`/${REGEN_AUTH_CODE_URL}/:caseCode`, (schema, request) => {
        return new Response(
          200,
          {},
          JSON.stringify({
            status: SESSION_STATUS.SUCCESS
          })
        );
      });

      // ==============================================================
      // api => appeal
      // ==============================================================
      this.urlPrefix = API_INBOUND;
      this.put(`/${APPEAL_INIT_REQUEST_URL}/:caseCode`, (schema, request) => {
        const { requestBody } = request;

        if (!requestBody) {
          return new Response(
            500,
            {},
            'Submission failed due to invalid requestBody'
          );
        }

        return new Response(200, {}, `Appeal submitted: ${requestBody}`);
      });

      // ==============================================================
      // api => cancel
      // ==============================================================
      this.urlPrefix = API_INBOUND;
      this.put(
        `/${CANCEL_REQUEST_URL}/:caseCode`,
        () => new Response(200, {}, 'Case cancelled')
      );

      // ==============================================================
      // api => download-file
      // ==============================================================
      this.urlPrefix = API_OUTBOUND;
      this.post(`${FILENAME_URL}`, (schema, request) => {
        const fileName = request.sendArguments[0];
        const match = schema.files.findBy({ fileName });

        if (!match) {
          return new Response(404, {}, `Invalid fileName: ${fileName}`);
        }

        return getResultWrappedWithServiceResponse(match?.data);
      });

      // ==============================================================
      // api => get-case-detail
      // ==============================================================
      this.urlPrefix = API_OUTBOUND;
      this.get(`${CASE_DETAIL_URL}/:caseCode`, (schema, request) => {
        const { caseCode } = request.params;
        const match = schema.cases.findBy({ caseCode });

        if (!match) {
          return new Response(
            404,
            {},
            {
              timestamp: new Date(),
              status: 404,
              error: 'Internal Server Error',
              message: `Invalid caseCode: ${caseCode}`,
              path: `/pixie/v1/epa/pharma/get-case-detail/${caseCode}`
            }
          );
        }

        const matchedResult = getMatchedResultFromMatch(match);
        delete matchedResult.id; // <--- id does not belong in body
        return getResultWrappedWithServiceResponse(matchedResult);
      });

      // ==============================================================
      // api => get-case-detail-list
      // ==============================================================
      this.urlPrefix = API_OUTBOUND;
      this.get(
        `${CASE_DETAIL_LIST_URL}/:initialSystemTrackingId`,
        (schema, request) => {
          const { initialSystemTrackingId } = request.params;
          const match = schema.epas.findBy({ initialSystemTrackingId });

          if (!match) {
            return new Response(
              404,
              {},
              {
                timestamp: new Date(),
                status: 404,
                error: 'Internal Server Error',
                message: `Invalid initialSystemTrackingId: ${initialSystemTrackingId}`,
                path: `/pixie/v1/epa/pharma/get-case-detail-list/${initialSystemTrackingId}`
              }
            );
          }

          return getResultWrappedWithServiceResponse(match?.data);
        }
      );

      // ==============================================================
      // api => submit questionSet
      // ==============================================================
      this.urlPrefix = API_INBOUND;
      this.put(`/${PA_REQUEST_URL}/:caseCode`, (schema, request) => {
        const mock500Error = false;
        const { requestBody } = request;
        const { caseCode } = request.params;
        const hasQuestionSetProp = requestBody.includes('questionSet');
        const hasAttachmentProp = requestBody.includes('attachment');

        if (mock500Error) {
          return new Response(
            500,
            {},
            {
              error: 'Internal Server Error',
              message: '',
              path: `/pixie/v1/epa/pharma/pa-request/${caseCode}`,
              status: 500,
              timestamp: new Date()
            }
          );
        }

        if (!hasQuestionSetProp || !hasAttachmentProp) {
          return new Response(
            500,
            {},
            'Submission failed due to invalid requestBody'
          );
        }

        return new Response(200, {}, { statusText: 'Submission successful' });
      });
      // ==============================================================
      // api => source-program
      // ==============================================================
      this.urlPrefix = API_OUTBOUND;
      this.get(
        `${SOURCE_PROGRAM_REQUEST_URL}/:programName`,
        (schema, request) => {
          const { programName } = request.params;
          const match = schema.sourceProgramDetails.findBy({
            program: programName
          });

          if (!match) {
            return new Response(
              404,
              {},
              {
                timestamp: new Date(),
                status: 404,
                error: 'Internal Server Error',
                message: `Invalid programName: ${programName}`,
                path: `/pixie/v1/source-program/${programName}`
              }
            );
          }

          const matchedResult = getMatchedResultFromMatch(match);
          return getResultWrappedWithServiceResponse(matchedResult);
        }
      );

      // ==============================================================
      // api => submit initiate epa
      // ==============================================================
      this.urlPrefix = API_INBOUND;
      this.post(`/${SUBMIT_INITIATE_URL}/:caseCode`, (schema, request) => {
        return new Response(200, {}, { statusText: 'Submission successful' });
      });

      // ==============================================================
      // api => get elibility history
      // ==============================================================
      this.urlPrefix = API_INBOUND;
      // TODO: get this working with new ?list=true url endpoint, previous change broke debug cases
      // Can work around by changing ?list=true -> list in eligibility-history-api.ts
      this.get(`${EBV_ELIGIBILITY_URL}/:caseCode/list`, (schema, request) => {
        const { caseCode } = request.params;
        const match = schema.ebvs.findBy({
          caseCode: caseCode
        });
        if (!caseCode) {
          return new Response(
            500,
            {},
            'Submission failed due to invalid caseCode'
          );
        }
        const matchedResult = getMatchedResultFromMatch(match);
        // need to use .result as Debug Case array has to be wrapped with a property to be found by
        return getResultWrappedWithServiceResponse(matchedResult?.result);
      });

      // ==============================================================
      // api => get elibility history
      // ==============================================================
      this.urlPrefix = API_INBOUND;
      this.post(`${EBV_ELIGIBILITY_URL}/:caseCode/`, (schema, request) => {
        const { caseCode } = request.params;
        // Used to test returning a response to close the modal after submit button is selected
        const match = schema.ebvs.findBy({
          caseCode: 'submitEligibility'
        });
        if (!caseCode) {
          return new Response(
            500,
            {},
            'Submission failed due to invalid caseCode'
          );
        }
        const matchedResult = getMatchedResultFromMatch(match);
        return getResultWrappedWithServiceResponse(matchedResult?.result);
      });
    }
  });
};

export default makeServer;
