import styled from 'styled-components';

export const Select = styled.div`
  .pixie__select__control {
    height: 44px;
  }
  .pixie__select__menu {
    box-shadow: 0 28px 50px rgb(0 0 0 / 8%);
  }

  .pixie__select__menu-list {
    padding: 15px 30px;
  }

  .pixie__select__option {
    border-radius: 5px;
    color: var(--color-black--10);
    cursor: pointer;
    font-size: 14px;
    padding: 18px 20px;
  }

  .pixie__select__option--is-focused {
    background-color: var(--color-primary);
    color: var(--color-black--50);
  }

  .pixie__select__option--is-selected {
    background-color: transparent;
    color: var(--color-black);
    font-weight: bold;
  }

  .pixie__select__multi-value__label {
    background-color: var(--color-tertiary--50);
    color: var(--color-black--50);
  }

  .pixie__select__multi-value__remove {
    background-color: var(--color-tertiary--50);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: var(--color-black--50);
    cursor: pointer;

    &:hover {
      background-color: var(--color-danger);
      color: var(--color-white);
    }
  }

  .pixie__select__input > input {
    box-shadow: none;
  }

  .pixie__select__indicators {
    cursor: pointer;
  }
  .pixie__select__modal__control {
    border: 1px solid var(--color-borderGrey);
  }
  .pixie__select__modal__option--is-focused {
    background-color: var(--color-white);
  }

  .pixie__select__modal__option--is-selected {
    background-color: transparent;
    background-color: var(--color-button);
    color: var(--color-white);
  }
  .pixie__select__modal__option {
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    padding: 18px 20px;
    &:hover {
      background-color: var(--color-warm-gray);
      color: var(--color-black--50);
    }
  }
  .pixie__select__buy_and_bill__control {
    border: 1px solid var(--color-borderGrey);
  }
  .pixie__select__buy_and_bill {
    width: 50px;
  }
  .pixie__select__buy_and_bill__control {
  }
  .pixie__select__buy_and_bill_place_of_service__control {
    border: 1px solid var(--color-borderGrey);
  }
  .pixie__select__buy_and_bill_place_of_service_error__control {
    border-color: #ee3a3b;
    background-color: #fce3e5;
  }
  .pixie__select__buy_and_bill_code__control {
    border: 1px solid var(--color-borderGrey);
    width: 125px;
  }
  .dropdown-arrow {
    color: black;
  }
`;
