import styled from 'styled-components';

export const Label = styled.label`
  &:not(disabled) {
    cursor: pointer;
  }

  &.uk-disabled {
    cursor: not-allowed;
  }

  span {
    margin-left: 10px;
    pointer-events: none;
  }

  .uk-radio {
    border-color: var(--color-tertiary--100);
  }

  .uk-radio:checked,
  .uk-radio:checked:focus {
    background-color: var(--color-newBrandingPrimary);
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E');
    border-color: var(--color-newBrandingPrimary);
  }
`;
