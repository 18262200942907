import styled from 'styled-components';

export const QuestionSet = styled.div`
  .uk-h2,
  .uk-h3 {
    font-weight: bold;
  }

  .uk-text-meta {
    font-size: 0.75rem;
  }

  progress::-moz-progress-bar {
    background-color: var(--color-tertiary--50);
  }

  progress::-webkit-progress-bar {
    background-color: var(--color-tertiary--50);
  }

  progress::-webkit-progress-value {
    background-color: var(--color-secondary);
  }

  progress[value='100']::-webkit-progress-value {
    background-color: var(--color-success);
  }

  div[data-is-active] {
    position: relative;
  }

  div[data-is-active='true'] {
    display: block;
    z-index: 1;
  }

  div[data-is-active='false'] {
    display: none;
  }

  .question-set .uk-margin > label {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .question-set .uk-margin > label > input[type='radio'] {
    margin-top: 1px;
    min-width: 16px;
  }

  .question-set .uk-margin > label > input[type='radio'] + span {
    display: block;
    position: relative;
    top: -3px;
  }

  /* @todo figure out a textarea resizer that
   * works with the <AnimateHeight /> component
   */
  textarea {
    /* resize: vertical; */
    resize: none;
  }

  .buttons-container {
    padding: 40px 0;
    margin: 40px 0;
    border-top: 1px solid var(--color-tertiary--100);
  }

  .buttons-container button {
    width: 100%;
  }

  .rah-static {
    overflow: visible !important;
  }

  .pixie__alert {
    background: var(--color-tertiary--50);
    color: var(--color-black--50);
  }

  .pixie__alert-error {
    background: var(--color-error);
    & > p {
      color: var(--color-black) !important;
    }
  }

  .pixie__alert-success {
    background: var(--color-success);
  }

  .question-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 82px;
    pointer-events: none;
    opacity: 0;
    width: 100%;
    display: none;

    &[data-show='true'] {
      display: block;
      position: relative;
    }
  }
`;
