import CONFIG from '@pixie/config';
import { useRefreshTokens } from './use-refresh-tokens';

const { SESSION } = CONFIG;

const usePollTokenRefresh = () => {
  // hooks
  const refreshTokens = useRefreshTokens();
  
  return () => {
    (window as any).refreshTokenInterval = setInterval(() => {
      const timestamp = window?.sessionStorage.getItem(SESSION.NEXT_TOKEN_REFRESH_AT);
    
      if (timestamp == null) {
        // no token refresh stored, not logged in... bail
        return;
      }

      const nextTokenRefreshAt = new Date(parseInt(timestamp, 10));
      const now = new Date();
      if (nextTokenRefreshAt < now) {
        // token refresh elapsed... refresh the tokens!
        refreshTokens();
      }
    }, 15000); // 15 second polling interval
  };
};

export { usePollTokenRefresh };

export default usePollTokenRefresh;
