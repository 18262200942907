/**
 * Formats a phone number
 * 
 * * @example
 * ```js
 *  formatPhoneNumber('9809142333');    // (980) 914-2333
 *  formatPhoneNumber('+19801542335');  // +1 (980) 154-2335
 * ```
 *
 * @param phoneNumber number to format
 * @returns Formatted phone number
 *
 * @see https://learnersbucket.com/examples/javascript/how-to-format-phone-number-in-javascript
 */
export default function formatPhoneNumber(phoneNumber: string) {
  // Filter only numbers from the input
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');

  // Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}
