import styled from 'styled-components';

export const DatePicker = styled.div`
  .uk-icon {
    border-left: 1px solid var(--color-tertiary--100);
    z-index: 1;
  }

  &:not(.uk-disabled) .uk-icon {
    color: black;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    background-color: var(--color-white);
    background-color: var(--color-white);
    border-color: var(--color-white--10);
  }

  .react-datepicker__header {
    background-color: var(--color-tertiary--10);
    border-color: var(--color-white--10);
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: var(--color-tertiary--10);
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: var(--color-white--10);
  }

  .react-datepicker__day--today {
    border-radius: 0.3rem;
    background-color: var(--color-tertiary--50);
  }

  .react-datepicker__day--selected,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--color-secondary);
  }
`;
