import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from '@pixie/components';

interface IToggleButton {
  actionsAvailable: boolean;
  availableAction: string;
  onClick: (event: React.TouchEvent | React.MouseEvent) => void;
  open: boolean;
}

const QuestionSetToggleButton = (props: IToggleButton): ReactElement => {
  const { actionsAvailable, availableAction, onClick, open } = props;
  const [style, setStyle] = useState('secondary');
  const [text, setText] = useState('');

  useEffect(() => {
    if (open) {
      setStyle('buy-and-bill-outline');
      setText('Hide Questions View');
    } else if (availableAction === 'QUESTION') {
      setStyle('refresh');
      setText('Answer Questions');
    } else if (availableAction === 'VIEW') {
      setStyle('buy-and-bill-outline');
      setText('View Questions');
    } else {
      setStyle('disabled');
      setText('No Questions Available');
    }
  }, [availableAction, open]);

  return (
    <Button
      buttonStyle={style}
      dataTestId="question-set-toggle-button"
      disabled={!actionsAvailable}
      onClick={onClick}
      text={text}
      type="button"
      ukToggle="target: #QuestionSet;"
    />
  );
};

export default QuestionSetToggleButton;
