/* eslint-disable jsx-a11y/media-has-caption */
import { LoadingBar, Button } from '@pixie/components';
import { useLocation } from 'react-router-dom';
import React, { ReactElement, useEffect, useState } from 'react';
import { IRequestDetails, getRequestDetails } from '@pixie/api';
import { setShowSubmitModalFalse } from '@pixie/features';
import {  useSelector, useDispatch } from 'react-redux';
import { RootState as Root } from '@pixie/store';
import CONFIG from '@pixie/config';
import * as Styled from './InitiationAwaitingResponseModal.styled';


const {
  APP: { USE_AUTH_FLOW },
  CASE: { CASE_STATUS }
} = CONFIG;

interface IInitiationAwaitingResponseModalFormProps {
  closeModal: Function;
  isClosed: boolean;
  handleRefreshRequest: any;
}
const InitiationAwaitingResponseModalForm = (props: IInitiationAwaitingResponseModalFormProps): ReactElement => {

  const {
    closeModal,
    isClosed,
    handleRefreshRequest
  } = props;

  const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0);
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const [timeoutFailures, setTimeoutFailures] = useState<number>(0);
  const dispatch = useDispatch();
  const caseCode = useSelector((state: Root) => state.caseCode);
  const location = useLocation();


  useEffect(() => {
    // 353 = 1000 * 85 / 30 =>  milliseconds * seconds / (% of progress bar)
    // should fill to 85% in 30 seconds, then 1% per second until 100%
    // used .5 for smoother progress bar
    let timer;
    if ( !isTimeout) {
      if (loadingBarProgress < 85) {
        timer = setInterval(() => {
          setLoadingBarProgress(loadingBarProgress + .5);
        }, 176.5);
      } else if (loadingBarProgress < 100) {
        timer = setInterval(() => {
          setLoadingBarProgress(loadingBarProgress + .5);
        }, 500);
      }
    }
    if (loadingBarProgress === 100) {
      setIsTimeout(!isTimeout);
      setLoadingBarProgress(0);
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };

  }, [closeModal, isTimeout, loadingBarProgress, timeoutFailures]);

  useEffect(() => {
    const regex =  /(\/Request\/)/g;

    const endpoint = USE_AUTH_FLOW
      ? caseCode
      : location.pathname.replace(regex, '');

    // local test endpoint to test next case of 1-3
    // can use any caseCode to mock a response
    // const endpoint = 'be6e26ee5c3f47ed969b7d92a0280e8c-3';
    const intervalId = setInterval(() => {
      getRequestDetails(endpoint, false)
        .then((data: IRequestDetails) => {
          // check if the latests status is OPEN
          if (data?.tasks[data?.tasks?.length - 1].status === CASE_STATUS.OPEN || isClosed) {
            clearInterval(intervalId);
            dispatch(setShowSubmitModalFalse(''));
            handleRefreshRequest();
          }
        })
        .catch(err => {
          // handle error scenarios in next story
        });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [caseCode, closeModal, dispatch, handleRefreshRequest, isClosed, location.pathname]);

  const onTryAgainClick = () => {
    if (timeoutFailures > 0) {
      closeModal();
    } else {
      setIsTimeout(!isTimeout);
      setLoadingBarProgress(0);
      setTimeoutFailures(timeoutFailures + 1);
    }
  };

  return (
    <Styled.InitiationAwaitingResponseModalForm data-testid="test">
      <div className="pixie-form">
        {(isTimeout) ? (
          <h2 className="pixie-header">
            We are not able to get a response from the payer at this time
          </h2>
        )
          : (
            <h2 className="pixie-header">
              ...checking with the payer to see if a quick response is
              available or if additional information is required...
            </h2>
          )}

        <div className="pixie-body">
          {(isTimeout) ? (

            <div className="body-information-message">
              {(timeoutFailures > 0) ? (
                <div>
                  Sometimes a response takes longer than expected, or the payer doesn&apos;t have a way to
                  electronically respond to a prior authorization request. Check the status for updates at a later time.
                </div>
              ) : (
                <div>
                  Sometimes a response takes longer than expected, or the payer doesn&apos;t have a way to
                  electronically respond to a prior authorization request. You can either click &quot;Try Again&quot; below, or
                  you can close out of this window and check the status for updates at a later time.
                </div>
              )}
            </div>
          ) : (
            <div className="body-information-message">
              Some payers are able to return a quick response to a request, and some may require additional information
              to process a case. Our system is checking with the payer, which should take less than 30 seconds.
            </div>
          )}

          <div >
            {(isTimeout) ? (
              <div className="timeout-image">
                <img
                  className="pixie-cah-logo-img"
                  src="../../../Timeout.svg"
                  alt='Cardinal Health'
                  style={{ height: 'auto', width: '340' }}
                />
              </div>
            ) : (
              <video
                className="animation-video"
                width="340"
                height="auto"
                autoPlay
                loop
                preload="auto"
              >
                <source src='/Videos/Pixie_Animation.mp4' type="video/mp4" />
              </video>
            )}
          </div>
          <div>
            {(isTimeout) ? (
              <div className="timeout-button">
                <Button
                  text={timeoutFailures > 0 ? 'Close' : 'Try Again'}
                  dataTestId="initiation-awaiting-modal-try-again"
                  buttonStyle='modal'
                  onClick={onTryAgainClick}
                  type="button"
                />
              </div>

            ) : (
              <LoadingBar
                completedPercentage={loadingBarProgress}
                containerStyle="initiationAwaitingResponseModalContainer"
                dataTestId="initiationAwaitingResponseModal-loadingBar"
                fillerStyle="initiationAwaitingResponseModalFiller"
                labelStyle="initiationAwaitingResponseModalLabel"
              />
            )}
          </div>
        </div>
      </div>

    </Styled.InitiationAwaitingResponseModalForm>
  );
};
export default InitiationAwaitingResponseModalForm;
