import { IEbvQuery } from '@pixie/interfaces';

/**
 * Returns the `state.question` array sorted by `sequenceNumber`
 * @see https://stackoverflow.com/a/57051534/8296677
 */
const sortEbvBenefits = (arr: any) => {
  if (arr === undefined ||  arr === null || arr?.length === 0) {
    return [];
  }
  return [...arr].sort((a: IEbvQuery, b: IEbvQuery) => {
    return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
  });
};

export default sortEbvBenefits;
