import styled from 'styled-components';

export const Div = styled.div`
  /* width: 61.5%; */
  .pixie-marketing-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 5em;
    @media (max-width: 960px) {
      padding-top: 0;
    }
    /* padding-top: 20px; */
    /* padding-left: 15px; */
  }

  .pixie-prior-auth-text {
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    padding-bottom: 50px;
    color: #27251f;
  }

  .pixie-auth-flow-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 50px;
    padding-top: 10px;
    width: 55%;
  }

  .pixie-flow-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .pixie-instructions-area {
    display: flex;
  }

  .pixie-auth-steps-explained {
    padding-left: 15px;
    padding-bottom: 28px;
    color: black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
    counter-increment: list-number 1;
  }
  .pixie-auth-steps-explained::marker {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    content: counter(list-number) '   ';
  }
  .pixie-auth-steps-explained::before {
    content: '';
    display: inline-block;
    position: absolute;
    margin-left: -48px;
    margin-top: 1px;
    z-index: -1;
    border-radius: 50%;
    min-width: 26px;
    width: 26px;
    max-width: 26px;
    min-height: 26px;
    height: 26px;
    max-height: 26px;
    background: #fff;
    color: white;
    background-color: var(--color-newBrandingPrimary);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 1 em;
    display: table;
  }

  .pixie-auth-steps-additional-text {
    font-size: 11.38px;
    line-height: 17px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    padding-top: 10px;
    color: black;
  }
  .alert-content-line-break {
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .pixie-pad {
    padding-top: 10px;
  }

  .pixie-pad-large {
    padding-top: 30px;
  }

  .pixie-secure-auth-text {
    font-size: 25px;
    font-weight: 600;
    color: #000;
  }

  .pad-top-large {
    padding-top: 20px;
  }

  .pad-top-xlarge {
    padding-top: 40px;
  }

  .pixie-contact-info {
    color: var(--color-secondary);
    font-size: 18px;
  }

  .pixie-questions {
    font-weight: 700;
  }

  .pixie-contact {
    font-weight: 400;
  }

  .pixie-auth-code-expires {
    font-size: 13px;
    font-weight: 300;
    color: #666;
  }

  .pixie-auth-alert-icon {
    padding-left: 20px;
    margin-top: -8%;
    @media (min-width: 960px) {
      margin-left: -4em;
    }
  }

  .pixie-auth-alert {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .pixie-secure-auth {
    padding-left: 10px;
  }

  .pixie-auth-alert-content-header {
    padding-left: 15px;
    font-size: 21px;
    font-weight: 400;
    color: var(--color-shield);
    font-family: 'Open Sans', sans-serif;
  }

  .pixie-auth-alert-content {
    font-size: 11.38px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: var(--color-shield);
  }

  .pixie-auth-mis-match-text {
    padding-top: 5px;
    font-weight: 300;
  }

  .pixie-new-auth-code-text {
    font-family: 'Open Sans', sans-serif;
    padding-top: 30px;
    color: #1a1a1a;
    font-size: 13px;
  }

  .pixie-new-auth-code-button {
    padding-top: 20px;
    width: 50%;
    padding-bottom: 35px;
  }

  .pixie-prior-auth-process-text {
    margin-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    line-height: 27px;
  }

  .auth-button {
    font-size: 16px;
    width: 100%;
    &:not(.Mui-disabled) {
      background-color: var(--color-button);
    }
  }

  .pixie-highlight-small {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 700;
  }
`;
