/**
 * Returns the weight with lbs appended to the end if a weight is provided
 */
const formatWeight = (value: string): any => {
  const trimmedValue = value?.trim();
  if (trimmedValue != null) {
    return `${trimmedValue} lbs`;
  }
  return trimmedValue;
};

export default formatWeight;
