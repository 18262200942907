import React, { ReactElement } from 'react';
import { IRequestDetails } from '@pixie/api';
import { IEbvQuery } from 'interfaces/ebv-query';
import RequestDetailView from './RequestDetailView';
import * as Styled from './RequestDetailPages.styled';

interface ISingleRequestPageProps {
  appIsLoading: boolean;
  data: IRequestDetails;
  ebvData: IEbvQuery;
  handleRefreshRequest: any;
  responseTimestamp: string;
  appError: string;
}

const TheSingleRequestDetailPage = (
  props: ISingleRequestPageProps
): ReactElement => {
  const {
    appError,
    appIsLoading,
    data,
    ebvData,
    handleRefreshRequest,
    responseTimestamp
  } = props;

  return (
    <Styled.RequestDetail>
      <RequestDetailView
        appIsLoading={appIsLoading}
        appError={appError}
        data={data}
        ebvData={ebvData}
        handleRefreshRequest={handleRefreshRequest}
        responseTimestamp={responseTimestamp}
      />
    </Styled.RequestDetail>
  );
};

export default TheSingleRequestDetailPage;
