import React, { ReactElement } from 'react';
import { ReadonlyFormText, CoverageTable } from '@pixie/components';
import { IEbvPlanInformation } from '@pixie/interfaces';
import * as Styled from './EbvBenefitsInfo.styled';


interface IEbvBenefitsCardProps {
  eligibilityPlan: IEbvPlanInformation;
  bodyWidth: number;
  isLoading?: boolean;
}

const EbvBenefitsCard = (props: IEbvBenefitsCardProps): ReactElement => {
  const {
    eligibilityPlan,
    bodyWidth,
    isLoading
  } = props;

  const formatDate = (date) => {
    return date ? `${date?.substring(4, 6)}/${date?.substring(6, 8)}/${date.substring(0, 4)}` : '--';
  };

  return (
    <Styled.EbvBenefitsInfo>
      {isLoading ? (
        <>
          <div className="is-loading">
            <div className="uk-spinner" data-uk-spinner />
          </div>
        </>
      ) : (
        <div>
          <h3 className="benefits-header">Health Plan Details</h3>
          <div className={
            bodyWidth <= 959
              ? 'benefits-card-box'
              : 'benefits-card-box uk-grid uk-child-width-1-3 uk-flex-between@m'
            }
          >
            <div className={
              bodyWidth <= 959
                ? 'benefits-card-left-mobile-version'
                : 'benefits-card-left'
            }
            >
              <div className="benefits-plan-status">
                <p className="benefits-plan-status-text">{eligibilityPlan.planStatus || 'Unknown'}</p>
              </div>
              <div className='benefits-plan-information uk-width-1-1'>
                <ReadonlyFormText
                  dataTestId='ebv-benefits-card-payer-name'
                  labelClassName="benefits_label"
                  textClassName="benefits_text"
                  label=''
                  textContent={eligibilityPlan.payerName}
                />
                <ReadonlyFormText
                  dataTestId='ebv-benefits-card-plan-name'
                  labelClassName="benefits_label"
                  textClassName="benefits_text"
                  label=''
                  textContent={eligibilityPlan.planName}
                />
              </div>
              <div>
                <div className={
                  bodyWidth <= 959
                    ? 'uk-grid uk-child-width-1-1'
                    : 'uk-grid uk-child-width-1-2 uk-flex-between@m'
                  }
                >
                  <ReadonlyFormText
                    dataTestId='ebv-benefits-card-effective-start-date'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    label='Effective Start Date'
                    textContent={formatDate(eligibilityPlan.planEffectiveStartDate)}
                  />
                  <ReadonlyFormText
                    dataTestId='ebv-benefits-card-effective-end-date'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    label='Effective End Date'
                    textContent={formatDate(eligibilityPlan.planEffectiveEndDate)}
                  />
                </div>
                <div className={
                  bodyWidth <= 959
                    ? 'uk-grid uk-child-width-1-1'
                    : 'uk-grid uk-child-width-1-2 uk-flex-between@m'
                  }
                >
                  <ReadonlyFormText
                    dataTestId='ebv-benefits-card-cardholder-id'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    label='Cardholder Id'
                    textContent={eligibilityPlan.cardholderId || '--'}
                  />
                  <ReadonlyFormText
                    dataTestId='ebv-benefits-card-group-id'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    label='Group Id'
                    textContent={eligibilityPlan.groupId || '--'}
                  />
                </div>
              </div>
            </div>
            <div className="benefits-card-right" >
              {eligibilityPlan && eligibilityPlan.pharmacyTypeCoverages && !isLoading && (
              <div >
                <h4>Coverage</h4>
                <hr className="coverage-horiziontal-line" />
                <CoverageTable data={eligibilityPlan.pharmacyTypeCoverages} />
              </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Styled.EbvBenefitsInfo>
  );
};

EbvBenefitsCard.defaultProps = {
  isLoading: true
};

export default EbvBenefitsCard;
