import { ReactElement } from 'react';
import * as React from 'react';
import { ReadonlyFormText } from '@pixie/components';
import * as Styled from './TheRequestHeader.styled';

interface IPatientTitleBarProps {
  cancelAvailable: boolean;
  cancelButton?: any;
  dataTestId: string;
  patientName: string;
  patientGender: string;
  patientDOB: string;
}

const PatientTitleBar = (props: IPatientTitleBarProps): ReactElement => {
  const {
    cancelAvailable,
    cancelButton,
    dataTestId,
    patientName,
    patientGender,
    patientDOB
  } = props;

  return (
    <Styled.TitleBar>
      <div
        className="uk-grid uk-child-width-auto@m uk-flex-between@m"
        data-testId={dataTestId}
        data-uk-grid
      >
        <div className="uk-flex">
          <h1>
            <span className="material-symbols-outlined person-icon">
              person
            </span>
            <span className="patient-name">{patientName}</span>
          </h1>
        </div>
        <div className="title-patient-info">
          <ReadonlyFormText
            dataTestId='case-details-patient-gender'
            label="Gender"
            labelClassName='title-label'
            textClassName='title-large-text'
            loading={false}
            textContent={patientGender}
          />
          <ReadonlyFormText
            dataTestId='case-details-patient-dob'
            label="Date of Birth"
            labelClassName='title-label'
            textClassName='title-large-text'
            loading={false}
            textContent={patientDOB}
          />
          {cancelAvailable && (
          <div className="cancel-button">{cancelButton}</div>
          )}
        </div>
      </div>
      <ul className="uk-tab tab-style" data-uk-tab>
        <li>
          <button
            type="button"
            data-testid="multi-request-tab-click"
          >
            <img
              className="pixie-cah-logo-img"
              src="../../../PA.svg"
              alt='Cardinal Health'
              style={{ height: 'auto', width: '340' }}
            />
            <span className='tab-style'>Prior Auth Request</span>
          </button>
        </li>
      </ul>
    </Styled.TitleBar>
  );
};

PatientTitleBar.defaultProps = {
  cancelButton: null
};
export default PatientTitleBar;
