import './axios-config';
import axios, { AxiosResponse } from 'axios';
import { IInitiationConfirmationModel } from '@pixie/api';
import CONFIG from '@pixie/config';

const { API } = CONFIG;

const submitInitiatePaRequest = (caseCode: string | null,   initiationConfirmationModel: IInitiationConfirmationModel) => {
  const { SUBMIT_INITIATE_URL } = API;

  return new Promise((resolve, reject) => {
    axios
      .post(`/${SUBMIT_INITIATE_URL}/${caseCode}`,
        initiationConfirmationModel, {
          headers: {
            'Content-Type': 'application/json'
          } } )
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default submitInitiatePaRequest;
