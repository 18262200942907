import { answerDateQuestion } from '../questionSet.slice';

/**
 * Sends date selection data to Redux store
 */
const handleDateChange = (
  questionId: string,
  dateChoice: Date,
  timeRequired: boolean = false
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  if (timeRequired) {
    return dispatch(
      answerDateQuestion({
        questionId,
        dateChoice: dateChoice.toISOString()
      })
    );
  }

  return dispatch(
    answerDateQuestion({
      questionId,
      dateChoice: new Date(dateChoice.setHours(0, 0, 0, 0)).toISOString()
    })
  );
};

export default handleDateChange;
