const debugMultipleCases = [
  {
    label: 'Cert ePA 1 - All',
    value: '2ff0eee5045e4b13a9e77f07f1423db2-all'
  },
  {
    label: 'Cert ePA 2 - All',
    value: '808be3f49bad4a56b76bdc7739cb3396-all'
  },
  {
    label: 'Multiple Random EPAs',
    value: '749184081281'
  },
  {
    label: 'Multiple EPAs from Atul',
    value: '1232145324'
  },
  {
    label: 'Multiple EPAs from Atul Links Added',
    value: '1232145325'
  }
];

export default debugMultipleCases;
