import React, { ReactElement } from 'react';
import { IRequestDetails } from '@pixie/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState as Root } from '@pixie/store';
import { classesArray, handlePatientNameGrid, formatICD10, formatTasksWithLinks } from '@pixie/utils';
import CONFIG from '@pixie/config';
import {
  Alert,
  InfoCard,
  TaskHistoryTable,
  TheRequestHeader,
  ExternalLinkModal,
  ReadonlyFormText
} from '@pixie/components';
import {
  AppealToggleButton,
  QuestionSetToggleButton,
  TheAppealBar,
  TheCancelPaRequestModal,
  TheCancelPaRequestModalButton,
  TheQuestionSetBar,
} from '@pixie/features';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  closeAlert,
  handleAppealBar,
  handleCancelModal,
  handleExternalLinkClick,
  handleQuestionSetBar
} from './methods';


const {
  ACTIONS: { ACTION_STATES }
} = CONFIG;

interface IPharmaDetailViewProps {
  appIsLoading: boolean;
  data: IRequestDetails;
  handleRefreshRequest: any;
  responseTimestamp: string;
  appError: string;
}

const PharmaDetailView = (props: IPharmaDetailViewProps): ReactElement => {
  const {
    appIsLoading,
    data,
    handleRefreshRequest,
    responseTimestamp,
    appError
  } = props;

  const dispatch = useDispatch();
  const actionCard = useSelector((state: Root) => state.actionCard);
  const alerts = useSelector((state: Root) => state.alerts);
  const appeal = useSelector((state: Root) => state.appeal);
  const app = useSelector((state: Root) => state.app);
  const benefitsInfo = useSelector((state: Root) => state.benefitsInfo);
  const cancelReq = useSelector((state: Root) => state.cancelPaRequest);
  const caseInfo = useSelector((state: Root) => state.caseInfo);
  const latestTask = useSelector((state: Root) => state.latestTask);
  const patientInfo = useSelector((state: Root) => state.patientInfo);
  const pharmacyInfo = useSelector((state: Root) => state.pharmacyInfo);
  const prescriberInfo = useSelector((state: Root) => state.prescriberInfo);
  const prescriptionInfo = useSelector((state: Root) => state.prescriptionInfo);
  const questionSet = useSelector((state: Root) => state.questionSet);
  const externalLink = useSelector((state: Root) => state.externalLink);
  const { trackEvent } = useMatomo();

  const handleOpenExternalSite = () => {
    window.open(externalLink.externalURL);
  };


  return (
    <>
      <TheRequestHeader
        actionCard={actionCard}
        appError={appError}
        authorizationNumber={latestTask?.authorizationNumber}
        authorizationPeriod={latestTask?.authorizationPeriod}
        bodyWidth={app.bodyWidth}
        cancelAvailable={cancelReq?.canCancel}
        cancelButton={
          <TheCancelPaRequestModalButton
            cancelAvailable={cancelReq?.canCancel}
            isCancelled={cancelReq?.isCancelled}
            onClick={e =>
              dispatch(handleCancelModal(e, questionSet?.questionSetOpen))
            }
          />
        }
        caseNumber={caseInfo.caseNumber}
        caseStatus={caseInfo.caseStatus}
        expectedResponseDate={latestTask?.expectedResponseDate}
        isLoading={appIsLoading}
        isReviewerApp={false}
        notes={latestTask?.paNote}
        externalLinkClick={(urlStr) => {
          dispatch(handleExternalLinkClick(urlStr));
        }}
        paAuthorizationDetails={latestTask?.paAuthorizationDetails}
        patientName={patientInfo.patientFormattedName}
        patientGender={patientInfo?.patientGender}
        patientDOB={patientInfo?.patientDateOfBirth}
        payerAttachmentFileName={data?.integrationSubmittedFileName}
        payerName={benefitsInfo?.payerName}
        payerCaseId={caseInfo?.payerCaseId}
        prescription={prescriptionInfo?.drugDescription}
        refreshRequest={handleRefreshRequest}
        timestamp={responseTimestamp}
      >
        <div className="toggle-button-positioning">
          {actionCard.currentAction === ACTION_STATES.APPEAL ? (
            <AppealToggleButton
              actionsAvailable={actionCard.actionsAvailable}
              availableAction={actionCard.currentAction}
              onClick={e => dispatch(handleAppealBar(e, appeal.appealBarOpen))}
              open={appeal?.appealBarOpen}
            />
          ) : (
            <QuestionSetToggleButton
              actionsAvailable={actionCard.actionsAvailable}
              availableAction={actionCard.currentAction}
              onClick={e =>
                dispatch(handleQuestionSetBar(e, questionSet?.questionSetOpen))
            }
              open={questionSet?.questionSetOpen}
            />
          )}
        </div>
      </TheRequestHeader>

      {!appError && (
        <div
          className={classesArray([
            'request-data',
            'uk-container',
            'uk-container-large',
            'uk-section',
            'uk-section-small'
          ])}
        >
          <ReadonlyFormText textClassName="pixie-h2-prior-auth-info" textContent='Prior Authorization Information' />
          <div
            className={
              app.bodyWidth <= 959
                ? 'uk-grid uk-child-width-1-2@l uk-grid-match'
                : 'uk-grid uk-child-width-1-2@m uk-grid-match'
            }
            data-uk-grid
          >
            <div>
              <InfoCard
                cardTitle="Patient Information"
                cardStyle="Gray--10"
                isLoading={patientInfo?.isLoading}
                gridClasses={classesArray([
                  'uk-child-width-1-2@s',
                  'uk-child-width-1-3@m',
                  'uk-grid-small'
                ])}
                data={[
                  {
                    dataTestId:'request-detail-view-patient-first-name',
                    label: 'First Name',
                    value: patientInfo.patientFirstName,
                    gridClass: handlePatientNameGrid(
                      patientInfo.patientFirstName
                    )
                  },
                  {
                    dataTestId:'request-detail-view-patient-middle-name',
                    label: 'Middle Name',
                    value: patientInfo.patientMiddleName,
                    gridClass: handlePatientNameGrid(
                      patientInfo.patientMiddleName
                    )
                  },
                  {
                    dataTestId:'request-detail-view-patient-last-name',
                    label: 'Last Name',
                    value: patientInfo.patientLastName,
                    gridClass: handlePatientNameGrid(
                      patientInfo.patientLastName
                    )
                  },
                  {
                    dataTestId:'request-detail-view-patient-date-of-birth',
                    label: 'DOB',
                    value: patientInfo.patientDateOfBirth
                  },
                  {
                    dataTestId:'request-detail-view-patient-gender',
                    label: 'Gender',
                    value: patientInfo.patientGender
                  },
                  {
                    dataTestId:'request-detail-view-patient-contact-number',
                    label: 'Contact Number',
                    value: patientInfo.patientPrimaryContactNo
                  },
                  {
                    dataTestId:'request-detail-view-patient-address',
                    label: 'Address',
                    value: patientInfo.patientFormattedAddress,
                    gridClass: 'uk-width-1-1'
                  }
                ]}
              />
            </div>
            <div>
              <InfoCard
                cardTitle="Pharmacy Information"
                cardStyle="Gray--10"
                isLoading={pharmacyInfo?.isLoading}
                gridClasses="uk-child-width-1-2@s uk-grid-small"
                data={[
                  {
                    dataTestId:'request-detail-view-pharmacy-name',
                    label: 'Pharmacy Name',
                    value: pharmacyInfo.pharmacyName
                  },
                  {
                    dataTestId:'request-detail-view-pharmacy-contact-number',
                    label: 'Contact Number',
                    value: pharmacyInfo.pharmacyContactNumber
                  },
                  {
                    dataTestId:'request-detail-view-pharmacy-address',
                    label: 'Address',
                    value: pharmacyInfo.pharmacyFormattedAddress,
                    gridClass: 'uk-width-1-1'
                  }
                ]}
              />
            </div>
            <div>
              <InfoCard
                cardTitle="Prescription Information"
                cardStyle="Gray--10"
                isLoading={prescriptionInfo?.isLoading}
                gridClasses="uk-child-width-1-2@m uk-grid-small"
                data={[
                  {
                    dataTestId:'request-detail-view-prescription-drug-description',
                    label: 'Drug Description',
                    value: prescriptionInfo.drugDescription
                  },
                  {
                    dataTestId:'request-detail-view-prescription-view-direction',
                    label: 'Direction',
                    value: prescriptionInfo.sigText,
                    gridClass: 'uk-width-1-1'
                  },
                  {
                    dataTestId:'request-detail-view-prescription-quantity',
                    label: 'Quantity',
                    value: prescriptionInfo.formattedQuantityRequestValue
                  },
                  {
                    dataTestId:'request-detail-view-prescription-days-supply',
                    label: 'Days Supply',
                    value: prescriptionInfo.daysSupply && Math.round(prescriptionInfo.daysSupply)
                  },
                  {
                    dataTestId:'request-detail-view-prescription-primary-diagnosis',
                    label: 'Diagnosis',
                    value: prescriptionInfo.primaryDiagnosisDesc
                  },
                  {
                    dataTestId:'request-detail-view-prescription-secondary-diagnosis',
                    label: 'Secondary Diagnosis',
                    value: prescriptionInfo.secondaryDiagnosisDesc
                  },
                  {
                    dataTestId:'request-detail-view-prescription-primary-diagnosis-code',
                    label: 'Diagnosis Code',
                    value: prescriptionInfo.primaryDiagnosisCode
                      ? formatICD10(prescriptionInfo.primaryDiagnosisCode)
                      : null
                  },
                  {
                    dataTestId:'request-detail-view-prescription-secondary-diagnosis-code',
                    label: 'Secondary Diagnosis Code',
                    value: prescriptionInfo.secondaryDiagnosisCode
                      ? formatICD10(prescriptionInfo.secondaryDiagnosisCode)
                      : null
                  }
                ]}
              />
            </div>
            <div>
              <InfoCard
                cardTitle="Prescriber Information"
                cardStyle="Gray--10"
                isLoading={prescriberInfo?.isLoading}
                gridClasses={classesArray([
                  'uk-child-width-1-2@s',
                  'uk-child-width-1-3@m',
                  'uk-grid-small'
                ])}
                data={[
                  {
                    dataTestId:'request-detail-view-prescriber-prescribed-by',
                    label: 'Prescribed By',
                    value: prescriberInfo.prescriberFormattedName
                  },
                  {
                    dataTestId:'request-detail-view-prescriber-contact-number',
                    label: 'Contact Number',
                    value: prescriberInfo.prescriberPrimaryTelephoneNumber
                  },
                  {
                    dataTestId:'request-detail-view-prescriber-fax-number',
                    label: 'Fax Number',
                    value: prescriberInfo.prescriberFaxNumber
                  },
                  {
                    dataTestId:'request-detail-view-prescriber-address',
                    label: 'Address',
                    value: prescriberInfo.prescriberFormattedAddress,
                    gridClass: 'uk-width-1-1'
                  }
                ]}
              />
            </div>
          </div>
        </div>
      )}

      {data && data.tasks && !appIsLoading && (
        <div
          className={classesArray([
            'task-history',
            'uk-container',
            'uk-container-large',
            'uk-section',
            'uk-section-small'
          ])}
        >
          <ReadonlyFormText textClassName="pixie-h2-prior-auth-info" textContent='Request History' />
          <TaskHistoryTable
            data={formatTasksWithLinks( data.tasks, (urlStr) => {
              dispatch(handleExternalLinkClick(urlStr));
            })}
          />
        </div>
      )}

      {alerts.type !== '' && alerts.message !== '' && (
        <>
          <Alert
            onClose={(e: any) => dispatch(closeAlert(e))}
            type={alerts.type}
            text={alerts.message}
          />
        </>
      )}

      <TheQuestionSetBar
        onClose={e =>{
          trackEvent({
            category: 'PA Question Submission',
            action: 'Question Set',
            name: 'Answer Questions'
          });
          dispatch(handleQuestionSetBar(e, questionSet?.questionSetOpen));
        }
      }
        handleRefreshRequest={handleRefreshRequest}
        renderQuestions={!!(data && latestTask && latestTask.questionSet)}
      />

      <TheAppealBar
        onClose={e => dispatch(handleAppealBar(e, appeal.appealBarOpen))}
        handleRefreshRequest={handleRefreshRequest}
        renderAppeal={!!(data && latestTask && latestTask.eappealSupported)}
      />

      <TheCancelPaRequestModal
        handleRefreshRequest={handleRefreshRequest}
      />

      <ExternalLinkModal
        title='You Are Being Redirected'
        id="externalRedirectModal"
        onClick={handleOpenExternalSite}
      > You are being redirected to a third party website {externalLink.externalURL}.
        This site will open in a new tab. Click OK to continue.
      </ExternalLinkModal>
    </>
  );
};

export default PharmaDetailView;
