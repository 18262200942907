import { triggerGridResizer } from '../../../utils';

export const closeQuestionSetAction = state => {
  triggerGridResizer();
  state.questionSetOpen = false;
};

export const openQuestionSetAction = state => {
  triggerGridResizer();
  state.questionSetOpen = true;
};
