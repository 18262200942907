import './axios-config';
import axios, { AxiosResponse } from 'axios';
import CONFIG from '@pixie/config';
import { IRequestDetails } from './request-details.model';
import { IServiceResponse } from './service-response';

const {
  API: { CASE_DETAIL_URL, CASE_DETAIL_LIST_URL }
} = CONFIG;

const getRequestDetails = (
  caseCode: string,
  isMultiRequest: boolean
): Promise<IRequestDetails> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${
          isMultiRequest ? CASE_DETAIL_LIST_URL : CASE_DETAIL_URL
        }/${caseCode}`
      )
      .then((response: AxiosResponse<IServiceResponse<IRequestDetails>>) => {
        resolve(response.data.result);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default getRequestDetails;
