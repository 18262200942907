import { answerSelectQuestionAdditionalFreeText } from '../questionSet.slice';

/**
 * Sends freeText value to redux store,
 * spread into related radio choice answer.
 */
const handleRadioFreeText = (questionId: string, freeText: string) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(
    answerSelectQuestionAdditionalFreeText({
      questionId,
      freeText
    })
  );
};

export default handleRadioFreeText;
