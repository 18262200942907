import styled from 'styled-components';

export const Div = styled.div`
  .wrapper {
    position: relative;
    margin-bottom: -0.35%;
  }

  .background {
    background-color: var(--color-tertiary--10);
    z-index: -7;
    height: 105%;
    position: absolute;
    width: 100%;
  }

  .pixie-container {
    display: flow-root;
    box-sizing: content-box;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .pixie-prescriber-right-img {
    float: right;
    display: fixed;
    margin-right: -31%;
    margin-top: -30%;
    z-index: 1;
  }
  .pixie-prescriber-left-img {
    float: left;
    z-index: 1;
    margin-left: -35%;
    margin-top: -30%;
  }
  .content {
    position: relative;
  }

  .horizontal-line {
    background-color: var(--color-tertiary--10);
    height: 22px;
    margin-top: 30px;
    z-index: -7;
  }

  &.dev-mode {
    overflow: visible;
    height: 100%;
  }

  .pixie_login_flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  // the @m is a UIkit class modifier,
  // meant for 960px breakpoints :)
  @media (min-width: 960px) {
    .pixie-fixed-width {
      width: 38%;
    }
  }
`;
