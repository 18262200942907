import styled from 'styled-components';

export const Modal = styled.div`
  .pixie-modal {
  }
  .pixie-modal-dialog {
    width: 65%;
  }

  .pixie-modal-dialog-questionSet {
    width: 50%;
  }
`;
