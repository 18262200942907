import styled from 'styled-components';

export const InfoCard = styled.div`
  text-transform: capitalize;
  h3 {
    font-weight: bold;
  }

  h3 > .uk-spinner {
    margin-left: 10px;
    position: relative;
    top: -1px;
  }

  .pixie-card p {
    white-space: pre-wrap;
  }
  .pixie-h3 {
    font-family: 'Open Sans', sans-serif;
    color: black;
    font-weight: 300;
    font-size: 1.5rem;
    // padding-bottom: 14px;
  }
  .card-title {
    font-family: 'Open Sans', sans-serif;
    color: black;
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 14px;
  }
  .pixie-h6 {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 17px;
    color: black;
  }
  .pixie-h4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    color: black;
    padding-top: 4px;
  }
  .phone-symbol {
    // margin-top: 25px;
    // margin-right: 10px;
    padding-right: 10px;
    // color: #0000;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 16;
  }
  .fax-symbol {
    // margin-top: 25px;
    // padding-top: 5px;
    // padding-left: 35px;
    padding-right: 10px;
    // color: #0000;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 16;
  }
  .fax-and-phone {
    // position: absolute;
    vertical-align: top;
  }
`;
