/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { formatBytes } from '@pixie/utils';
import { usePrevious } from '@pixie/hooks';
import * as Styled from './FileUploader.styled';

interface IFileUploaderProps {
  sizeMbLimit?: number;
  supportedFileTypes?: string[];
  acceptMultipleFiles?: boolean;
  onFileChange: (files?: File[]) => any[] | void;
}

const FileUploader = (props: IFileUploaderProps) => {
  const {
    sizeMbLimit,
    supportedFileTypes,
    acceptMultipleFiles,
    onFileChange
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const dropBoxRef = React.useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<Array<File>>([]);
  const previousFiles = usePrevious(files);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  let dragElementCounter = 0;

  const initializeDropEventListeners = useCallback(() => {
    const dropBox = dropBoxRef.current;
    dropBox?.addEventListener('dragenter', handleDragEnter);
    dropBox?.addEventListener('dragleave', handleDragOut);
    dropBox?.addEventListener('dragover', handleDragOver);
    dropBox?.addEventListener('drop', handleDrop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanupDropEventListeners = useCallback(() => {
    const dropBox = dropBoxRef.current;
    dropBox?.removeEventListener('dragenter', handleDragEnter);
    dropBox?.removeEventListener('dragleave', handleDragOut);
    dropBox?.removeEventListener('dragover', handleDragOver);
    dropBox?.removeEventListener('drop', handleDrop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set up the drag & drop event listeners
  useEffect(() => {
    initializeDropEventListeners();
    return cleanupDropEventListeners;
  }, [cleanupDropEventListeners, initializeDropEventListeners]);

  useEffect(() => {
    if (files !== previousFiles) {
      // Notify the parent component that the files have changed
      onFileChange(files);

      // Any time the files variable changes, we need to re-bind the event listeners so that they have the latest version of the files
      cleanupDropEventListeners();
      initializeDropEventListeners();
    }
  }, [
    cleanupDropEventListeners,
    files,
    initializeDropEventListeners,
    onFileChange,
    previousFiles
  ]);

  const handleMessaging = () => {
    if (files.length < 1) return 'Choose a File';
    if (acceptMultipleFiles) return 'Choose Another File';
    return 'Choose Different File';
  };

  // Handle the drag & Drop events
  const handleDragEnter = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    dragElementCounter += 1;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };
  const handleDragOut = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    dragElementCounter -= 1;
    if (dragElementCounter === 0) {
      setIsDragging(false);
    }
  };
  const handleDragOver = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const handleDrop = (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDragging(false);

    const newFile = event.dataTransfer?.files[0];
    if (newFile) {
      updateSelectedFiles(newFile);
    }
  };

  // Trigger the file uploader view
  const handleButtonClick = (event: any) => {
    event.preventDefault();
    inputRef.current!.click();
  };

  // Update the files in state if a file is selected using the input element
  const handleInputFileChange = (event: any) => {
    // Get the file the user just selected
    const newFile = inputRef!.current!.files![0];
    updateSelectedFiles(newFile);

    // Clean out the input element to make sure any new files that are selected trigger the "onChange" event
    inputRef.current!.value = '';
  };

  const handleFileDelete = (fileIndex: number) => {
    const updatedFileList = [...files];
    updatedFileList.splice(fileIndex, 1);
    setFiles(updatedFileList);
  };

  const updateSelectedFiles = (newFile: File) => {
    // Check if file is correct size
    const filesize = Number((newFile.size / 1000000).toFixed(4)); // Convert Bytes to MB
    if (sizeMbLimit && filesize > sizeMbLimit) {
      // File is too big
      showError(`File Size Too Large: Must be less than ${sizeMbLimit} MB`);
      if (!acceptMultipleFiles) {
        // Empty out the files array
        setFiles([]);
      }
      return;
    }

    // check if filetype is supported
    const fileTypeRegex = /\.(tiff|png|jpg|jpeg|pdf|cda|ccr)$/i;
    const fileTypeMatch = newFile.name.match(fileTypeRegex);
    const fileType = fileTypeMatch && fileTypeMatch[0];

    // Add the new file to the list of files in state
    if (acceptMultipleFiles) {
      setFiles([...files, newFile]);
    } else {
      setFiles([newFile]);
    }

    // Reset the error message every time a new file is uploaded
    setErrorVisible(false);
  };

  const showError = (message: string) => {
    setErrorMessage(message);
    setErrorVisible(true);
  };

  return (
    <Styled.FileUploader>
      <div className="upload-button-container">
        <input
          data-testid="file-uploader-file-to-upload"
          className="visually-hidden"
          type="file"
          ref={inputRef}
          name="fileToUpload"
          id="fileToUpload"
          accept={supportedFileTypes?.join(',')}
          onChange={handleInputFileChange}
        />
        <div>
          <h5 className="files-header">File:</h5>
          {files.length > 0 ? (
            files.map((file, index) => (
              <div key={file.name} className="file-row">
                <p className="filename">
                  {file.name}
                  <em className="filesize">({formatBytes(file.size)})</em>
                </p>
                <button
                  data-testid={`file-uploader-delete-file-${index}`}
                  className="delete-btn"
                  onClick={() => handleFileDelete(index)}
                  type="button"
                >
                  &#x2715;
                </button>
              </div>
            ))
          ) : (
            <p className="filename">No file choosen</p>
          )}
        </div>
      </div>
      <div className="drag-drop-container">
        <div
          data-testid="file-uploader-drag-drop-container" 
          className={`dropbox ${isDragging ? 'dragging' : ''}`}
          ref={dropBoxRef}
        >
          <div className="dropbox-content">
            <h4>
              <a 
                data-testid="file-uploader-choose-file"
                onClick={handleButtonClick}
              >
                {/* Set the display text based on if a file has already been uploaded and if the system accepts multiple files */}
                {handleMessaging()}
              </a>
            </h4>
            <p>or drag it here.</p>
            {errorVisible && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      </div>
    </Styled.FileUploader>
  );
};

FileUploader.defaultProps = {
  sizeMbLimit: 1,
  supportedFileTypes: [],
  acceptMultipleFiles: false
};

export default FileUploader;
