/**
 * Sets the entire `state` object as the `payload` param.
 */
const setQuestionSetResponseAction = (state, { payload }) => {
  const { header, question } = payload;

  state.header = header;


  // sort the questions by `sequenceNumber` to determine first one to display
  const sortFunc = (a, b) => {
    if (parseInt(a.sequenceNumber, 10) > parseInt(b.sequenceNumber, 10)) {
      return 1;
    } 
    return -1;
  };
  question.sort(sortFunc);
  state.question = question;
};

export default setQuestionSetResponseAction;
