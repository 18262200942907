import React, { ReactElement } from 'react';
import * as Styled from './Card.styled';

interface ICardProps {
  dataTestId?: string;
  style?: string;
  children: JSX.Element | JSX.Element[];
}

const Card = ({ children, style, dataTestId }: ICardProps): ReactElement => {
  return (
    <Styled.Card className={`pixie-card pixie-card-${style}`}>
      <div className="uk-card uk-card-xsmall uk-card-body" data-testId={dataTestId}>{children}</div>
    </Styled.Card>
  );
};

Card.defaultProps = {
  dataTestId: null,
  style: 'default'
};

export default Card;
