import { createSlice } from '@reduxjs/toolkit';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  ebvBenefits: [
    {
      id: null,
      createdDate: null,
      updatedDate: null,
      externalId: null,
      vendorId: null,
      response: {
        patientFound: false,
        userFriendlyErrors: [],
        eligibilityPatient: {
          lastName: null,
          firstName: null,
          middleName: null,
          gender: null,
          addressLine1: null,
          addressCity: null,
          addressState: null,
          addressPostalCode: null,
          dateOfBirth: null,
          payerName: null,
          planName: null,
          planStatus: null,
          memberID: null,
          groupNumber: null,
          policyNumber: null,
          bin: null,
          pcn: null,
          cardholderId: null,
          payerId: null,
          eligibility: null,
          daw: null,
          isLoading: false
        },
        eligibilityPrescriber: {
          npi: null,
          lastName: null,
          firstName: null,
          addressLine1: null,
          addressCity: null,
          addressState: null,
          addressPostalCode: null,
          phoneNumber: null,
          faxNumber: null,
          prescriberEmail: null,
          practiceTaxId: null,
          practiceAddressLine: null,
          practiceCity: null,
          practiceState: null,
          practiceZipCode: null
        },
        eligibilityPlans: [
          {
            payerName: null,
            coverageLevel: null,
            planName: null,
            planStatus: null,
            planEffectiveStartDate: null,
            planEffectiveEndDate: null,
            pharmacyType: null,
            drugCoverageStatus: null,
            drugStatus: null,
            inPlanNetwork: null,
            deductibleMet: null,
            deductible: null,
            deductibleRemaining: null,
            outOfPocket: null,
            outOfPocketRemaining: null,
            copayAmount: null,
            coinsurancePercentage: null,
            priorAuthDetails: null,
            message: null,
            outOfNetwork: null,
            quantityPriced: null,
            daysPriced: null,
            responseStatus: null,
            priority: null,
            paCaseID: null,
            deadlineForReply: null,
            authorizationDetailsFromPayer: null,
            authorizationNumber: null,
            authorizationPeriod: null,
            maxOutOfPocket: null,
            cardholderId: null,
            groupId: null,
            hasPatientChanged: false,
            pixieMessageId: null,
            userFriendlyErrors: [],
            pharmacyTypeCoverages: [
              {
                type: null,
                coverage: null
              }
            ],
            patientDiscrepancies: [
              {
                fieldName: null,
                before: null,
                after: null
              }
            ],
            benefitsDetails: [
              {
                pharmacyType: null,
                estimatedPatientPayAmount: null,
                pharmacyName: null,
                daysSupplyApplied: null,
                quantityPriced: null,
                formularyStatus: null,
                drugStatusCode: null,
                isPriorAuthorizationRequired: null,
                planPaidAmount: null,
                deductibleApplied: null,
                outOfPocketApplied: null,
                deductibleRemaining: null,
                outOfPocketRemaining: null,
                coverageAlertList: []
              }
            ]
          }
        ],
        eligibilityEncounter: {
          daysSupply: null,
          quantityRequested: null,
          ndc: null,
          quantityUnitOfMeasureCode: null,
          quantityUnitOfMeasureDescription: null,
          drugDescription: null,
          diagnosisCode: null,
          diagnosisDescription: null,
          jcode: null,
          qcode: null,
          cptCode: null,
          pharmacyName: null,
          contactForPharmacy: null,
          datesOfService: null,
          placeOfService: null,
          ncpdpID: null,
          npiForPharmacy: null,
          height: null,
          weight: null,
          numberOfDosages: null,
          numberOfVisits: null,
          priority: null,
          patientDirections: null
        }
      }
    }
  ]
};

const initialState = DEFAULT_STATE;

const EbvBenefitsSlice = createSlice({
  name: 'EbvBenefits',
  initialState,
  reducers: {
    clearEbvBenefitsError: state => clearError(state),
    clearEbvBenefitsSuccess: clearSuccess,
    resetEbvBenefits: () => DEFAULT_STATE,
    setEbvBenefits(state, { payload }) {
      state.ebvBenefits = payload || DEFAULT_STATE.ebvBenefits;
    },
    setEbvBenefitsError: loadingFailed,
    setEbvBenefitsSuccess: loadingSuccess,
    startEbvBenefitsLoading: startLoading,
    stopEbvBenefitsLoading: stopLoading
  }
});

export const {
  clearEbvBenefitsError,
  clearEbvBenefitsSuccess,
  resetEbvBenefits,
  setEbvBenefits,
  setEbvBenefitsError,
  setEbvBenefitsSuccess,
  startEbvBenefitsLoading,
  stopEbvBenefitsLoading
} = EbvBenefitsSlice.actions;
export default EbvBenefitsSlice.reducer;

export const fetchEbvBenefits = data => async dispatch => {
  try {
    dispatch(startEbvBenefitsLoading());
    dispatch(setEbvBenefits(data));
    dispatch(setEbvBenefitsSuccess());
  } catch (err) {
    dispatch(setEbvBenefitsError(String(err)));
  }
};
