import React, { ReactElement } from 'react';
import { classesArray } from '@pixie/utils';
import * as Styled from './Alert.styled';

export interface IAlertProps {
  dataTestId?: string;
  onClose: any;
  text: string;
  type: string;
}

const Alert = (props: IAlertProps): ReactElement => {
  const { dataTestId,
    onClose,
    text,
    type
  } = props;

  return (
    <Styled.Alert
      data-uk-alert
      data-testId={dataTestId}
      className={classesArray([
        'uk-alert',
        'uk-animation-slide-top-small',
        'pixie__alert',
        `pixie__alert--${type.toLowerCase()}`
      ])}
    >
      <button
        aria-label="Close"
        className="uk-close uk-alert-close"
        data-uk-close
        onClick={onClose}
        type="button"
      />

      <p className="pixie__alert-type">
        <strong>{type.toUpperCase()}</strong>
      </p>

      <p className="pixie__alert-text">{text}</p>
    </Styled.Alert>
  );
};

export default Alert;
