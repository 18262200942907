import React, { ReactElement } from 'react';
import { classesArray, uiKitOptions } from '@pixie/utils';
import TheCancelPaRequestForm from './TheCancelPaRequestForm';

interface ITheCancelPaRequestModal {
  handleRefreshRequest: any;
}
const TheCancelPaRequestModal = (props: ITheCancelPaRequestModal): ReactElement => {
  const {
    handleRefreshRequest
  } = props;
  return (
    <div
      id="CancelPaRequestModal"
      className="uk-modal"
      data-uk-modal={uiKitOptions({
        'bg-close': true,
        'esc-close': true,
        container: false
      })}
    >
      <div
        className={classesArray([
          'pixie__cancel-modal-dialog',
          'uk-modal-dialog'
        ])}
      >
        <button
          aria-label="Close"
          className="uk-modal-close-default"
          data-uk-close
          type="button"
        />

        <TheCancelPaRequestForm
          handleRefreshRequest={handleRefreshRequest}
        />
      </div>
    </div>
  );
};

export default TheCancelPaRequestModal;
