/**
 * Takes in an UIkit component object & returns it
 * formatted properly for the DOM to read and parse.
 *
 * ```js
 * // INPUT
 * uiKitOptions({
 *  autoplay: false,
 *  center: false,
 *  draggable: true,
 * });
 *
 * // RETURNS
 * 'autoplay: false; center: false; draggable: true;'
 * ```
 *
 * @name uiKitOptions
 * @param {Object} object The object to DOM'ify.
 */
export default function uiKitOptions(object: any) {
  return JSON.stringify(object)
    .replace('{', '')
    .replace('}', '')
    .replace(/(")/g, '')
    .replace(/(:)/g, ': ')
    .replace(/(,)/g, '; ')
    .concat('', ';');
}
