import CONFIG from '@pixie/config';
import { useDispatch } from 'react-redux';
import { setAccessToken } from '@pixie/features';
import { IRefreshTokenResponse } from '@pixie/api';
import '../api/axios-config';
import axios, { AxiosResponse } from 'axios';

const { SESSION } = CONFIG;

const { API } = CONFIG;
const { ACCESS_TOKEN } = SESSION;

const usePixieRefreshToken = () => {
  const dispatch = useDispatch();
  const { TOKEN_REFRESH_REQUEST_URL } = API;

  return (): Promise<IRefreshTokenResponse> => {
    return new Promise((resolve, reject) => {
      const currentAccessToken = window?.sessionStorage.getItem(ACCESS_TOKEN);
      const refreshTokenRequest = {
        access_token: currentAccessToken
      };
      const json = JSON.stringify(refreshTokenRequest);
      axios
        .post(`/${TOKEN_REFRESH_REQUEST_URL}`, json, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response: AxiosResponse) => {
          dispatch(setAccessToken(response.data.access_token));
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export { usePixieRefreshToken };

export default usePixieRefreshToken;
