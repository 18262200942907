const debugCases = [
  {
    label: 'QuestionSet With Branching Paths',
    value: 'a339386f27f54e92a6a97429fc08292d'
  },
  {
    label: 'QuestionSet With More Branching Paths',
    value: '68304ba4e9314281bb5210bd415bc6e8'
  },
  {
    label: 'QuestionSet With Document Upload',
    value: 'kasldjn23iehu1h98daiosdiua123n1l'
  },
  {
    label: 'QuestionSet With Document Download',
    value: 'sdmnkajsbkfhui2h3ioda'
  },
  {
    label: 'QuestionSet With Answered Questions',
    value: 'aksdjfi2oij12901912ue90udcajs'
  },
  {
    label: 'QuestionSet With Multi-Select',
    value: 'mmsbdjkb1jalls9uu21nknlkas'
  },
  {
    label: 'QuestionSet With Single Question',
    value: 'aksndjkn23iurhuina'
  },
  {
    label: 'QuestionSet With Appeal Available',
    value: 'lkhfkjbt23j4kbjabkjsa'
  }
];

export default debugCases;
