import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import * as Styled from './DatePicker.styled';

interface IDatePickerProps {
  dataTestId?: string;
  disabled?: boolean;
  defaultDate?: Date;
  handleDateChange: (date: Date) => void;
  placeholderText?: string;
  showTimeSelect?: boolean;
}

/**
 * @example ```
 *  const [startDate, setStartDate] = useState(new Date());
 * ```
 */
const DatePicker = (props: IDatePickerProps) => {
  const {
    dataTestId,
    defaultDate,
    disabled,
    handleDateChange,
    placeholderText,
    showTimeSelect
  } = props;

  const [localDefaultDate, setLocalDefaultDate] = useState(
    defaultDate || new Date()
  );

  const updateDatePicker = date => {
    setLocalDefaultDate(date);
    handleDateChange(date);
  };

  return (
    <Styled.DatePicker
      className={`uk-inline uk-width-1-1 ${disabled ? 'uk-disabled' : ''}`}
    >
      <span
        className="uk-form-icon uk-form-icon-flip uk-icon"
        data-uk-icon="icon: calendar"
      />
      <ReactDatePicker
        data-testId={dataTestId}
        dateFormat={showTimeSelect ? 'MM/dd/yyyy h:mm:ss aa' : 'MM/dd/yyyy'}
        disabled={disabled}
        onChange={date => updateDatePicker(date)}
        placeholderText={placeholderText}
        popperPlacement="bottom"
        selected={localDefaultDate || defaultDate}
        showTimeSelect={showTimeSelect}
      />
    </Styled.DatePicker>
  );
};

DatePicker.defaultProps = {
  dataTestId: null,
  disabled: false,
  defaultDate: new Date(),
  placeholderText: null,
  showTimeSelect: false
};

export default DatePicker;
