import { Select, TextInput,  Button, Radio, TitleBar  } from '@pixie/components';
import React, { ReactElement, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { IRequestDetails, IInitiationConfirmationModel, IAncillaryServicesModel,
  IBuyAndBillError, sourceProgramRequest, submitInitiatePaRequest, IPlaceOfService } from '@pixie/api';
import {   setShowSubmitModalTrue  } from '@pixie/features';
import { formatDate, formatPhoneNumber } from '@pixie/utils';
import {  useSelector,  useDispatch } from 'react-redux';
import { RootState } from '@pixie/store';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import * as Styled from './buyAndBillForm.styled';
import States from '../../assets/states.json';
import PlacesOfService from '../../assets/placeOfService.json';
import AncillaryCodes from '../../assets/ancillaryCodes.json';


interface BuyAndBillFormProps {
  data: IRequestDetails;
  handleRefreshRequest: any;
  setShowBuyAndBill: any;
  setInitiationConfirmationModal: any;
  initiationConfirmationModel: IInitiationConfirmationModel;
  useSignOut: any;
}
const placeOfServiceData = [];
for ( const k in PlacesOfService){
  if (PlacesOfService[k])
    placeOfServiceData.push({ value : k, label: PlacesOfService[k] });
}
const stateData = [];
for ( const k in States) {
  if (States[k])
    stateData.push(States[k]);
}
const ancillaryCodesData = [];
for ( const k in AncillaryCodes) {
  if (AncillaryCodes[k])
    ancillaryCodesData.push(AncillaryCodes[k]);
}
const BuyAndBillForm = (props: BuyAndBillFormProps): ReactElement => {

  const {
    data,
    handleRefreshRequest,
    setShowBuyAndBill,
    setInitiationConfirmationModal,
    initiationConfirmationModel,
    useSignOut
  } = props;

  const dispatch = useDispatch();
  const selectedEligiblityPlan = useSelector((state: RootState) => state.selectedEligiblityPlan);

  const [stateOptions, setStateOptions] = useState<string[]>([]);
  const [placeOfServiceOptions, setPlaceOfServiceOptions] = useState<IPlaceOfService[]>(placeOfServiceData);
  const [placeOfServiceSelected, setPlaceOfServiceSelected] = useState<string>('');
  const [stateSelected, setStateSelected] = useState<any>('');
  const [medicationUnit, setMedicationUnit] = useState<string>('');
  // Unfortunately setState are async, so must do error methods seperately
  const [errorPatientWeight, setErrorPatientWeight] = useState<string>();
  const [errorPatientHeight, setErrorPatientHeight] = useState<string>();
  const [errorPlaceOfService, setErrorPlaceOfService] = useState<string>();
  const [errorBillingCode, setErrorBillingCode] = useState<string>();
  const [errorNumberOfVisits, setErrorNumberOfVisits] = useState<string>();
  const [errorUnitsBilled, setErrorUnitsBilled] = useState<string>();
  const [errorNumberOfDosages, setErrorNumberOfDosages] = useState<string>();
  const [errorStartDate, setErrorStartDate] = useState<string>();
  const [errorEndDate, setErrorEndDate] = useState<string>();
  const [errorFacilityName, setErrorFacilityName] = useState<string>();
  const [errorProviderFirstName, setErrorProviderFirstName] = useState<string>();
  const [errorProviderLastName, setErrorProviderLastName] = useState<string>();
  const [errorProviderAddress, setErrorProviderAddress] = useState<string>();
  const [errorProviderCity, setErrorProviderCity] = useState<string>();
  const [errorProviderState, setErrorProviderState] = useState<string>();
  const [errorProviderZip, setErrorProviderZip] = useState<string>();
  const [errorProviderPhoneNumber, setErrorProviderPhoneNumber] = useState<string>();
  const [errorProviderFaxNumber, setErrorProviderFaxNumber] = useState<string>();
  const [errorProviderNpi, setErrorProviderNpi] = useState<string>();
  const app = useSelector((state: RootState) => state.app);
  const patientInfo = useSelector((state: RootState) => state.patientInfo);
  const caseInfo = useSelector((state: RootState) => state.caseInfo);
  const prescriberInfo = useSelector((state: RootState) => state.prescriberInfo);
  const benefitsInfo = useSelector((state: RootState) => state.benefitsInfo);
  const prescriptionInfo = useSelector((state: RootState) => state.prescriptionInfo);
  const [ancillaryServicesList, setAncillaryServicesList] = useState<IAncillaryServicesModel[]>(initiationConfirmationModel?.ancillaryServices);

  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState<boolean>(false);
  // Think I have to sepearte these out

  const requiredErrorMessage = 'This field is required';
  const zipErrorMessage = 'Zip must be 5 or 8 digits';

  useEffect(() => {
    setStateOptions(stateData);
    // setDefaultState();
    // setStateSelected(data?.prescriber?.prescriberStateProvince);
    setPlaceOfServiceOptions(placeOfServiceData);
    setStateSelected({ label: data?.prescriber?.prescriberStateProvince,
      value: data?.prescriber?.prescriberStateProvince });
    // set defaults in initial render
    sourceProgramRequest(data.faxTemplateCode)
      .then((res: AxiosResponse) => handleResponse('success', res))
      .catch((err: AxiosError) => handleResponse('alert', err));
  }, [data.faxTemplateCode, data.prescriber.prescriberStateProvince]);

  const handleResponse = (type: string, obj: any) => {
    switch (type) {
      case 'success':
        if (obj.result !== null && obj.result.detailJson !== null) {
          setPlaceOfServiceOptions(placeOfServiceData);
          setStateOptions(stateData);
        }
        break;
      case 'alert':
      default:

    }
  };

  const getPlaceOfServiceDropdownOptions = () =>{
    return placeOfServiceOptions.map((item) => {
      return {
        value: item.value,
        label: item.label,
      };
    });
  };

  const onChangePriorAuthTiming = prop => event => {
    if (event?.target?.value === 'Post-Service') {
      setInitiationConfirmationModal({ ...initiationConfirmationModel, [prop]: 'POST_SERVICE' });
    } else {
      setInitiationConfirmationModal({ ...initiationConfirmationModel, [prop]: 'PRE_SERVICE' });
    }
  };

  const getStateDropdownOptions = () =>{
    const stateOption =  stateOptions.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    return stateOption;
  };

  const getAncillaryDropdownOptions = () =>{
    const diagnosisOptions =  ancillaryCodesData.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    return diagnosisOptions;
  };

  // TODO: Add check for if Buy and Bill is selected then travel to medical UI instead
  const onClickSubmit = () => {
    if (validateInputFields()) {
      setIsLoadingSubmitButton(true);
      setInitiationConfirmationModal({ ...initiationConfirmationModel, ebvQueryId: selectedEligiblityPlan?.ebvQueryId, eligibilityPlanPixieMessageId: selectedEligiblityPlan?.eligibilityPlanPixieMessageId });
      submitInitiatePaRequest(data.caseCode, initiationConfirmationModel
      ).then(() => {
        setIsLoadingSubmitButton(false);
        setShowBuyAndBill(false);
        dispatch(setShowSubmitModalTrue(''));
      }).catch((err) => {
        setIsLoadingSubmitButton(false);
      });
    }

  };

  const validateInputFields = () => {
    let isSuccess = true;
    resetErrorFields();
    if (initiationConfirmationModel.patientWeight == null || initiationConfirmationModel.patientWeight === '0') {
      isSuccess = false;
      setErrorPatientWeight(requiredErrorMessage);
    }
    if (initiationConfirmationModel.patientHeight == null || initiationConfirmationModel.patientHeight === '0'){
      isSuccess = false;
      setErrorPatientHeight(requiredErrorMessage);
    }
    if (initiationConfirmationModel.placeOfService == null) {
      isSuccess = false;
      setErrorPlaceOfService(requiredErrorMessage);
    }
    if (initiationConfirmationModel.billingCode == null){
      isSuccess = false;
      setErrorBillingCode(requiredErrorMessage);
    }
    if (initiationConfirmationModel.numberOfVisits == null || initiationConfirmationModel.numberOfVisits === '0'){
      isSuccess = false;
      setErrorNumberOfVisits(requiredErrorMessage);
    }
    if (initiationConfirmationModel.numberOfDosages == null || initiationConfirmationModel.numberOfDosages === '0'){
      isSuccess = false;
      setErrorNumberOfDosages(requiredErrorMessage);
    }
    if (initiationConfirmationModel.unitsBilled == null || initiationConfirmationModel.unitsBilled === '0'){
      isSuccess = false;
      setErrorUnitsBilled(requiredErrorMessage);
    }
    if (initiationConfirmationModel.numberOfDosages == null || initiationConfirmationModel.numberOfDosages === '0'){
      isSuccess = false;
      setErrorNumberOfDosages(requiredErrorMessage);
    }
    if (initiationConfirmationModel.startDate == null){
      isSuccess = false;
      setErrorStartDate(requiredErrorMessage);
    }
    if (initiationConfirmationModel.endDate == null || Date.parse(initiationConfirmationModel.endDate) < Date.parse(initiationConfirmationModel.startDate)){
      isSuccess = false;
      setErrorEndDate(requiredErrorMessage);
    }
    if (initiationConfirmationModel.facilityName == null){
      isSuccess = false;
      setErrorFacilityName(requiredErrorMessage);
    }
    if (initiationConfirmationModel.providerFirstName == null){
      isSuccess = false;
      setErrorProviderFirstName(requiredErrorMessage);
    }
    if (initiationConfirmationModel.providerLastName == null){
      isSuccess = false;
      setErrorProviderLastName(requiredErrorMessage);
    }
    if (initiationConfirmationModel.providerAddress == null){
      isSuccess = false;
      setErrorProviderAddress(requiredErrorMessage);
    }
    if (initiationConfirmationModel.providerCity == null){
      isSuccess = false;
      setErrorProviderCity(requiredErrorMessage);
    }
    if (initiationConfirmationModel.providerState == null){
      isSuccess = false;
      setErrorProviderState(requiredErrorMessage);
    }
    if (initiationConfirmationModel.providerZip == null) {
      isSuccess = false;
      setErrorProviderZip(requiredErrorMessage);
    } else if (initiationConfirmationModel.providerZip.length !== 5 && initiationConfirmationModel.providerZip.length !== 8) {
      isSuccess = false;
      setErrorProviderZip(zipErrorMessage);
    }
    if (initiationConfirmationModel.providerPhoneNumber == null){
      isSuccess = false;
      setErrorProviderPhoneNumber(requiredErrorMessage);
    }
    if (initiationConfirmationModel.providerFaxNumber == null){
      isSuccess = false;
      setErrorProviderFaxNumber(requiredErrorMessage);
    }
    if (initiationConfirmationModel.providerNpi == null){
      isSuccess = false;
      setErrorProviderNpi(requiredErrorMessage);
    }
    return isSuccess;
  };

  const resetErrorFields = () => {
    setErrorPatientWeight(null);
    setErrorPatientHeight(null);
    setErrorBillingCode(null);
    setErrorPlaceOfService(null);
    setErrorNumberOfVisits(null);
    setErrorNumberOfDosages(null);
    setErrorUnitsBilled(null);
    setErrorStartDate(null);
    setErrorEndDate(null);
    setErrorFacilityName(null);
    setErrorProviderFirstName(null);
    setErrorProviderLastName(null);
    setErrorProviderAddress(null);
    setErrorProviderCity(null);
    setErrorProviderZip(null);
    setErrorProviderState(null);
    setErrorProviderPhoneNumber(null);
    setErrorProviderFaxNumber(null);
    setErrorProviderNpi(null);
  };

  const addAdditionalAcnillaryService = () => {
    ancillaryServicesList.push(        {
      serviceCode: null,
      codeType: null
    });
    setAncillaryServicesList(ancillaryServicesList);
    setInitiationConfirmationModal({ ...initiationConfirmationModel,
      ancillaryServices: ancillaryServicesList });
  };

  const onChangeFormField = prop => event => {
    setInitiationConfirmationModal({ ...initiationConfirmationModel, [prop]: event.target.value || event.value });
  };

  const onChangeAncillaryServiceCode = (event: any, index: number) => {
    ancillaryServicesList[index].serviceCode = event?.target?.value;
    setAncillaryServicesList(ancillaryServicesList);
    setInitiationConfirmationModal({ ...initiationConfirmationModel,
      ancillaryServices: ancillaryServicesList });
  };


  const onChangeAncillaryCodeType = (event: any, index: number) => {
    ancillaryServicesList[index].codeType = event?.value;
    setAncillaryServicesList(ancillaryServicesList);
    setInitiationConfirmationModal({ ...initiationConfirmationModel,
      ancillaryServices: ancillaryServicesList });
  };

  const onChangePlaceOfService = (args) =>{
    setPlaceOfServiceSelected(args);
    setInitiationConfirmationModal({ ...initiationConfirmationModel, placeOfService : args.value });
  };

  const onChangeState = (args) => {
    setStateSelected(args);
    setInitiationConfirmationModal({ ...initiationConfirmationModel, providerState : args.label });
  };


  const onQuantityKeyUp = (event: any) =>{
    return (event < 0 ? event * -1 : event);
  };

  if (data == null || data.patient == null || data.prescriber == null || data.medicationPrescribed == null) {
    return null;
  }

  const onEndDateChange = (prop, value) => {
    setInitiationConfirmationModal({
      ...initiationConfirmationModel,
      endDate: value !== null ? formatDate(value) : value
    });
  };

  const onStartDateChange = (prop, value) => {
    setInitiationConfirmationModal({
      ...initiationConfirmationModel,
      startDate: value !== null ? formatDate(value) : value
    });
  };
  const textInputStartDate = (params) => {
    const dobParams = {
      ...params,
      inputProps:{ ...params.inputProps, 'data-testid': 'buy-and-bill-start-date', placeholder: 'MM/DD/YYYY' },
    };
    const isError = (errorStartDate && errorStartDate !== null);
    return (

      <TextField
        {...dobParams}
        sx={{
          // Color is newBrandingPrimary color
          svg: { color: '#3F7D98' },
        }}
        error={isError}
      />
    );
  };

  const textInputEndDate = (params) => {
    const dobParams = {
      ...params,
      inputProps:{ ...params.inputProps, 'data-testid': 'buy-and-bill-end-date', placeholder: 'MM/DD/YYYY' },
    };
    const isError = (errorEndDate && errorEndDate !== null);
    return (

      <TextField
        {...dobParams}
        sx={{
          // Color is newBrandingPrimary color
          svg: { color: '#3F7D98' }
        }}
        error={isError}
      />
    );
  };

  const deleteAncillary = (index: any) => {
    ancillaryServicesList.splice(index, 1);
    setAncillaryServicesList(ancillaryServicesList);
    setInitiationConfirmationModal({ ...initiationConfirmationModel,
      ancillaryServices: ancillaryServicesList });
  };

  const onClickBack = () => {
    setShowBuyAndBill(false);
  };

  return (
    <Styled.BuyAndBillForm data-testid="test">
      <TitleBar
        dataTestId='the-request-header-title-bar'
        patientName={patientInfo?.patientFormattedName}
        patientGender={patientInfo?.patientGender}
        patientDOB={patientInfo?.patientDateOfBirth}
        isLoading={false}
        isMedicalView
        appError=""
        caseNumber={caseInfo?.caseNumber}
        payerCaseId={caseInfo?.payerCaseId}
        prescription={prescriptionInfo?.drugDescription}
        prescriberName={prescriberInfo?.prescriberFormattedName}
        cancelAvailable={false}
        payerName={benefitsInfo?.payerName}
      />
      <div className='buy-and-bill-background'>
        <div className='buy-and-bill-section'>
          <div className='additional-info-required'>
            Additional Information Required
          </div>
          <div className='buy-and-bill-info'>
            Buy and bill prior authorization requests require the following patient and provider information.
          </div>
          <form className="pixie-form">
            <div className='pixie-form-width'>
              <div className="pixie-header">
                <div className="pixie-header-medication">
                  Patient Medication & Diagnosis
                </div>
                <div className="pixie-header-code-diagnosis">
                  <div className="diagnosis-and-medication-names">
                    <div className="diagnosis-and-medication-info">
                      <span className="diagnosis-and-medication-header-text">
                        Diagnosis Code
                      </span>
                      <br />
                      <span className="diagnosis-and-medication-body-text">
                        {initiationConfirmationModel?.diagnosisCode}
                      </span>
                    </div>
                    <div className="diagnosis-and-medication-info">
                      <span className="diagnosis-and-medication-header-text">
                        Medication Name
                      </span>
                      <br />
                      <span className="diagnosis-and-medication-body-text">
                        {initiationConfirmationModel?.drugPrescribed}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="medication-amounts">
                  <div className="diagnosis-and-medication-amount-info">
                    <span className="diagnosis-and-medication-header-text">
                      Days Supply
                    </span>
                    <br />
                    <span className="diagnosis-and-medication-body-text">
                      {initiationConfirmationModel?.daysSupply}
                    </span>
                  </div>

                  <div className="diagnosis-and-medication-amount-info">
                    <span className="diagnosis-and-medication-header-text">
                      Quantity
                    </span>
                    <br />
                    <span className="diagnosis-and-medication-body-text">
                      {initiationConfirmationModel?.quantityRequestValue}
                    </span>
                  </div>
                  <div className="diagnosis-and-medication-is-buy-bill">
                    <span className="diagnosis-and-medication-header-text">
                      Will your practice buy and bill this medication?
                    </span>
                    <br />
                    <span className="diagnosis-and-medication-body-text">
                      Yes
                    </span>
                  </div>
                </div>
              </div>
              <hr className="divider-line" />
              <div className="pixie-treament-information-section">
                <div>
                  <div className="pixie-header-medication">
                    Treatment Information
                  </div>
                  <div className="pixie-lower-inputs">
                    <div className="buy-and-bill-small-text-input">
                      <div className="pixie-body-quantity-legend">
                        <legend className="pixie-legend-required"> Patient Weight  </legend>
                      </div>
                      <TextInput
                        errorMessage={errorPatientWeight}
                        isErrorSymbol
                        type="number"
                        min='0'
                        unit='lbs'
                        unitLabel='weight-unit'
                        onKeyUp={(event: any) => onQuantityKeyUp(event.target.value)}
                        dataTestId='buy-and-bill-patient-weight'
                        value={initiationConfirmationModel?.patientWeight}
                        className="confirmation-modal"
                        onChange={onChangeFormField('patientWeight')}
                      />
                    </div>
                    <div className="buy-and-bill-small-text-input">
                      <div className="pixie-body-quantity-legend">
                        <legend className="pixie-legend-required"> Patient Height  </legend>
                      </div>
                      <TextInput
                        errorMessage={errorPatientHeight}
                        isErrorSymbol
                        type="number"
                        min='0'
                        unit='inches'
                        unitLabel='height-unit'
                        onKeyUp={(event: any) => onQuantityKeyUp(event.target.value)}
                        dataTestId='buy-and-bill-patient-height'
                        value={initiationConfirmationModel?.patientHeight}
                        className="confirmation-modal"
                        onChange={onChangeFormField('patientHeight')}
                      />
                    </div>
                  </div>
                  <div className="pixie-lower-inputs">
                    <div className="buy-and-bill-large-input">
                      <div className="pixie-medication" data-testid='buy-and-bill-place-of-service-name'>
                        <legend className="pixie-legend-required"> Place of Service  </legend>
                        <Select
                          classNamePrefix={errorPlaceOfService ?
                            'pixie__select__buy_and_bill_place_of_service_error'
                            : 'pixie__select__buy_and_bill_place_of_service'}
                          dataTestId='buy-and-bill-place-of-service-dropdown'
                          placeholder=''
                          value={placeOfServiceSelected}
                          options={getPlaceOfServiceDropdownOptions()}
                          onChange={onChangePlaceOfService}
                        />
                        {errorPlaceOfService ? (
                          <>
                            <span className="material-symbols-outlined error-symbol">
                              error
                            </span>
                            <span className="error-message">{errorPlaceOfService}</span>
                          </>
                        ) : null }
                      </div>
                    </div>
                    <div className="buy-and-bill-billing-code">
                      <div className="pixie-body-quantity-legend">
                        <legend className="pixie-legend-required">Billing Code (HCPCS) </legend>
                      </div>
                      <TextInput
                        errorMessage={errorBillingCode}
                        isErrorSymbol
                        type="text"
                        onKeyUp={(event: any) => onQuantityKeyUp(event.target.value)}
                        dataTestId='buy-and-bill-billing-code'
                        value={initiationConfirmationModel?.billingCode}
                        className="confirmation-modal"
                        onChange={onChangeFormField('billingCode')}
                      />
                    </div>
                  </div>
                  <div className="pixie-lower-inputs">
                    <div className="buy-and-bill-small-text-input">
                      <div className="pixie-body-quantity-legend">
                        <legend className="pixie-legend-required"> Number of Visits  </legend>
                        <legend className="pixie-legend-unit-of-measure"> {medicationUnit?.toString()}</legend>
                      </div>
                      <TextInput
                        errorMessage={errorNumberOfVisits}
                        isErrorSymbol
                        type="number"
                        min='0'
                        onKeyUp={(event: any) => onQuantityKeyUp(event.target.value)}
                        dataTestId='buy-and-bill-number-of-visits'
                        value={initiationConfirmationModel?.numberOfVisits}
                        className="confirmation-modal"
                        onChange={onChangeFormField('numberOfVisits')}
                      />
                    </div>
                    <div className="buy-and-bill-small-text-input">
                      <div className="pixie-body-quantity-legend">
                        <legend className="pixie-legend-required"> Units Billed  </legend>
                      </div>
                      <TextInput
                        errorMessage={errorUnitsBilled}
                        isErrorSymbol
                        type="number"
                        min='0'
                        onKeyUp={(event: any) => onQuantityKeyUp(event.target.value)}
                        dataTestId='buy-and-bill-units-billed'
                        value={initiationConfirmationModel?.unitsBilled}
                        className="confirmation-modal"
                        onChange={onChangeFormField('unitsBilled')}
                      />
                    </div>
                    <div className="buy-and-bill-small-text-input">
                      <div className="pixie-body-quantity-legend">
                        <legend className="pixie-legend-required"> Number of Dosages  </legend>
                      </div>
                      <TextInput
                        errorMessage={errorNumberOfDosages}
                        isErrorSymbol
                        type="number"
                        min='0'
                        onKeyUp={(event: any) => onQuantityKeyUp(event.target.value)}
                        dataTestId='buy-and-bill-number-of-dosages'
                        value={initiationConfirmationModel?.numberOfDosages}
                        className="confirmation-modal"
                        onChange={onChangeFormField('numberOfDosages')}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="pixie-buy-and-bill">
                      <span className="pixie-buy-and-bill-text">Prior Auth Request Timing<br /></span>
                      <span className='pixie-buy-and-bill-radio'>
                        <Radio
                          dataTestId='pixie-buy-and-bill-service-timing-pre'
                          id='pixie-buy-and-bill-service-timing'
                          name='pixie-buy-and-bill-service-timing'
                          checked
                          onClick={onChangePriorAuthTiming('priorAuthRequestTiming')}
                          label='Pre-Service'
                        />
                      </span>
                      <span className='pixie-buy-and-bill-radio'>
                        <Radio
                          dataTestId='pixie-buy-and-bill-service-timing-post'
                          id='pixie-buy-and-bill-service-timing'
                          name='pixie-buy-and-bill-service-timing'
                          onClick={onChangePriorAuthTiming('priorAuthRequestTiming')}
                          label='Post-Service'
                        />
                      </span>

                    </div>
                    <div className="pixie-lower-inputs">

                      <div className="start-date-section">
                        <legend className="legend-required">Start Date of Service</legend>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              data-testId='buy-and-bill-start-date'
                              value={initiationConfirmationModel?.startDate}
                              onChange={newValue => {
                                onStartDateChange('startDate', newValue);
                              }}
                              className='date-picker'
                            // onError={errorStartDate}
                              renderInput={textInputStartDate}
                            />
                          </LocalizationProvider>
                        </div>
                        {errorStartDate ? (
                          <>
                            <span className="material-symbols-outlined error-symbol">
                              error
                            </span>
                            <span className="error-message">{errorStartDate}</span>
                          </>
                        ) : null }
                      </div>

                      <div className="start-date-section">
                        <legend className="legend-required">End Date of Service</legend>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              data-testId='buy-and-bill-end-date'
                              value={initiationConfirmationModel?.endDate}
                              onChange={newValue => {
                                onEndDateChange('endDate', newValue);
                              }}
                              className='date-picker'
                              renderInput={textInputEndDate}
                            />
                          </LocalizationProvider>
                        </div>
                        {errorEndDate ? (
                          <>
                            <span className="material-symbols-outlined error-symbol">
                              error
                            </span>
                            <span className="error-message">{errorEndDate}</span>
                          </>
                        ) : null }
                      </div>
                    </div>
                  </div>
                  <hr className="divider-line" />
                  <div>
                    <div className="pixie-header-rendering-service">
                      Rendering Service Provider
                    </div>
                    <div className="buy-and-bill-large-input">
                      <div className="provider-facility-name">
                        <legend className="pixie-legend-required"> Facility Name  </legend>
                        <TextInput
                          isErrorSymbol
                          className="confirmation-modal"
                          dataTestId='buy-and-bill-faculty-name'
                          value={initiationConfirmationModel?.facilityName}
                          errorMessage={errorFacilityName}
                          onChange={onChangeFormField('facilityName')}
                        />
                      </div>
                    </div>
                    <div className="pixie-lower-inputs">
                      <div className="provider-first-name-section">
                        <legend className="pixie-legend-required"> Provider First Name  </legend>
                        <TextInput
                          isErrorSymbol
                          className="confirmation-modal"
                          errorMessage={errorProviderFirstName}
                          dataTestId='buy-and-bill-provider-first-name'
                          value={initiationConfirmationModel?.providerFirstName}
                          onChange={onChangeFormField('providerFirstName')}
                        />
                      </div>
                      <div className="provider-last-name-section">
                        <legend className="pixie-legend-required"> Provider Last Name  </legend>
                        <TextInput
                          isErrorSymbol
                          className="confirmation-modal"
                          errorMessage={errorProviderLastName}
                          dataTestId='buy-and-bill-provider-last-name'
                          value={initiationConfirmationModel?.providerLastName}
                          onChange={onChangeFormField('providerLastName')}
                        />
                      </div>
                    </div>
                    <div className="pixie-lower-inputs">
                      <div className="pixie-provider-address-section">
                        <legend className="pixie-legend-required"> Address  </legend>
                        <TextInput
                          isErrorSymbol
                          className="confirmation-modal"
                          errorMessage={errorProviderAddress}
                          dataTestId='buy-and-bill-provider-address'
                          value={initiationConfirmationModel?.providerAddress}
                          onChange={onChangeFormField('providerAddress')}
                        />
                      </div>
                      <div className="provider-unit-section">
                        <legend className="pixie-legend-optional"> Unit, Suite, etc. (Optional)  </legend>
                        <TextInput
                          className="confirmation-modal"
                          dataTestId='buy-and-bill-provider-unit'
                          value={initiationConfirmationModel?.providerUnit}
                          onChange={onChangeFormField('providerUnit')}
                        />
                      </div>
                    </div>
                    <div className="pixie-lower-inputs">
                      <div className="provider-city-section">
                        <legend className="pixie-legend-required">City</legend>
                        <TextInput
                          isErrorSymbol
                          className="confirmation-modal"
                          errorMessage={errorProviderCity}
                          dataTestId='buy-and-bill-provider-city'
                          value={initiationConfirmationModel?.providerCity}
                          onChange={onChangeFormField('providerCity')}
                        />
                      </div>
                      <div className="provider-state-section">
                        <legend className="pixie-legend-required"> State  </legend>
                        <Select
                          classNamePrefix="pixie__select__buy_and_bill"
                          placeholder=''
                          dataTestId='buy-and-bill-state'
                          value={stateSelected}
                          options={getStateDropdownOptions()}
                          onChange={onChangeState}
                        />
                      </div>
                      <div className="provider-zip-section">
                        <legend className="pixie-legend-required"> Zip Code  </legend>
                        <TextInput
                          type="number"
                          min='0'
                          isErrorSymbol
                          className="confirmation-modal"
                          errorMessage={errorProviderZip}
                          dataTestId='buy-and-bill-zip'
                          value={initiationConfirmationModel?.providerZip}
                          onChange={onChangeFormField('providerZip')}
                        />
                      </div>
                    </div>

                    <div className="pixie-lower-inputs">
                      <img
                        className="phone-symbol"
                        src="../../../phone.png"
                        alt='Cardinal Health'
                        style={{ height: '25px', width: 'auto' }}
                      />
                      <div className="phone-input-section">
                        <legend className="pixie-legend-required"> Provider Phone Number  </legend>

                        <TextInput
                          isErrorSymbol
                          className="confirmation-modal"
                          errorMessage={errorProviderPhoneNumber}
                          dataTestId='buy-and-bill-provider-phone-number'
                          value={formatPhoneNumber(initiationConfirmationModel?.providerPhoneNumber)}
                          onChange={onChangeFormField('providerPhoneNumber')}
                        />
                      </div>
                      <img
                        className="fax-symbol"
                        src="../../../fax.png"
                        alt='Cardinal Health'
                        style={{ height: '25px', width: 'auto' }}
                      />
                      <div className="fax-number-input-section">
                        <legend className="pixie-legend-required"> Fax Number </legend>
                        <TextInput
                          isErrorSymbol
                          className="confirmation-modal"
                          errorMessage={errorProviderFaxNumber}
                          dataTestId='buy-and-bill-provider-fax-number'
                          value={formatPhoneNumber(initiationConfirmationModel?.providerFaxNumber)}
                          onChange={onChangeFormField('providerFaxNumber')}
                        />
                      </div>
                      <div className="pixie-provider-npi-section">
                        <legend className="pixie-legend-required"> Provider NPI  </legend>
                        <TextInput
                          isErrorSymbol
                          className="confirmation-modal"
                          errorMessage={errorProviderNpi}
                          dataTestId='buy-and-bill-provider-npi'
                          value={initiationConfirmationModel?.providerNpi}
                          onChange={onChangeFormField('providerNpi')}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className='divider-line' />
                  <div>
                    <div className="pixie-header-medication">
                      Ancillary Services
                    </div>
                    <div>
                      The ancillary service(s) selected must be related to the medication for prior authorization.
                    </div>
                    <div>
                      {ancillaryServicesList?.map((as, index) => (
                        <div className="pixie-lower-inputs">
                          <div className="ancillary-service-code">
                            <legend className="pixie-legend-optional"> Ancillary Service Code (Optional)  </legend>
                            <TextInput
                              isErrorSymbol
                              className="confirmation-modal"
                              errorMessage={errorProviderNpi}
                              dataTestId={`ancillary-service-code-${index}`}
                              value={as?.serviceCode}
                              onChange={(event: any) => onChangeAncillaryServiceCode(event, index)}
                            />
                          </div>
                          <div className="ancillary-service-code-type">
                            <legend className="pixie-legend-optional"> Code Type  </legend>
                            <div className='ancillary-code-type-section'>
                              <Select
                                classNamePrefix="pixie__select__buy_and_bill_code"
                                placeholder=''
                                dataTestId={`ancillary-drop-down-${index}`}
                                value={{ value : as?.codeType, label: as?.codeType }}
                                options={getAncillaryDropdownOptions()}
                                onChange={(event: any) => onChangeAncillaryCodeType(event, index)}
                              />
                            </div>
                          </div>
                          {index > 0 && (
                          <a
                            aria-hidden="true"
                            data-testId={`ancillary-delete-${index}`}
                            className="material-symbols-outlined delete-symbol"
                            onClick={() => deleteAncillary(index)}
                            onKeyDown={() => deleteAncillary(index)}
                          >
                            delete
                          </a>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="add-ancillary-button">
                      <Button
                        buttonStyle="buy-and-bill-outline"
                        dataTestId='add-ancillary-service-button'
                        isLoading={isLoadingSubmitButton}
                        onClick={addAdditionalAcnillaryService}
                        text="Add another ancillary service"
                        type="button"
                      />
                    </div>
                  </div>
                </div>
                <hr className='divider-line' />
                <div className="buy-and-bill-button-section">
                  <span className="submit-button">
                    <Button
                      buttonStyle="epa-modal"
                      dataTestId='buy-and-bill-submit-button'
                      isLoading={isLoadingSubmitButton}
                      onClick={onClickSubmit}
                      text="Submit Prior Authorization"
                      type="button"
                    />
                  </span>
                  <span className="back-button">
                    <Button
                      buttonStyle="buy-and-bill-outline"
                      dataTestId='initiation-confirmation-modal-button'
                      isLoading={isLoadingSubmitButton}
                      onClick={onClickBack}
                      text="Back"
                      type="button"
                    />
                  </span>
                  <span className="cancel-button">
                    <Button
                      buttonStyle="buy-and-bill-outline"
                      dataTestId='initiation-confirmation-modal-button'
                      isLoading={isLoadingSubmitButton}
                      onClick={useSignOut}
                      text="Cancel"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Styled.BuyAndBillForm>
  );
};
export default BuyAndBillForm;
