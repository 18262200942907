import { ReactElement } from 'react';
import * as React from 'react';
import * as Styled from './TheRequestHeader.styled';

interface IErrorMessageProps {
  dataTestId?:string;
  instructions?: string;
  message?: string;
}

const ErrorMessage = (props: IErrorMessageProps): ReactElement => {
  const { dataTestId,
    instructions,
    message } = props;

  return (
    <Styled.ErrorMessage>
      <div className="uk-animation-slide-bottom-small" data-testId={dataTestId}>
        <h2 className="uk-h5">{instructions}</h2>
        <p>{message}</p>
      </div>
    </Styled.ErrorMessage>
  );
};

ErrorMessage.defaultProps = {
  dataTestId:'',
  message: '',
  instructions: 'Sorry, there has been an error. Please retry your request.'
};

export default ErrorMessage;
