import CONFIG from '@pixie/config';
import { useDispatch } from 'react-redux';
import { setCaseCodeForAuthCode, setAccessToken } from '@pixie/features';
import { IAuthnResponse } from '@pixie/api';
import '../api/axios-config';
import axios, { AxiosResponse } from 'axios';

const { API } = CONFIG;

const useSignIn = () => {
  const dispatch = useDispatch();
  const { AUTHN_REQUEST_URL, APIGEE_TOKEN_REFRESH_REQUEST_URL } = API;

  return (authRequest: any): Promise<IAuthnResponse> => {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(authRequest);
      axios
        .post(`/${AUTHN_REQUEST_URL}`, json, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response: AxiosResponse) => {
          dispatch(setCaseCodeForAuthCode(response.data.caseCode));
          dispatch(setAccessToken(response.data.accessToken));
          const refreshTokenRequest = {
            access_token: response.data.accessToken
          };

          if (response.data.status !== 'SUCCESS' && response.data.status !== 'EXPIRED') {
            // unsuccessful auth... bail
            resolve(response.data);
            return;
          }

          axios
            .post(APIGEE_TOKEN_REFRESH_REQUEST_URL, refreshTokenRequest, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then((apigeeResponse: AxiosResponse) => {
              window?.sessionStorage.setItem('apigee_access_token', apigeeResponse.data.apigee_access_token);
              resolve(response.data);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export default useSignIn;
