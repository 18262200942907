import React, { ReactElement } from 'react';
import InputNumber from 'react-input-number';
import * as Styled from './NumericInput.styled';

export interface INumericInputProps {
  dataTestId: string;
  numberMin: Number;
  numberMax: Number;
  placeholder?: String;
  onBlur?: any;
  onChange?: any;
  numberStep?: Number;
  value?: Number;
}

/**
 * @see https://github.com/swiftcarrot/react-input-number
 */
const NumericInput = (props: INumericInputProps): ReactElement => {
  const {
    dataTestId,
    numberMin,
    numberMax,
    numberStep,
    onBlur,
    onChange,
    placeholder,
    value
  } = props;

  return (
    <div className="uk-inline">
      <Styled.Label>
        <InputNumber
          dataTestId={dataTestId}
          enableMobileNumericKeyboard
          min={numberMin}
          max={numberMax}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          step={numberStep}
          value={value}
        />
      </Styled.Label>
    </div>
  );
};

NumericInput.defaultProps = {
  numberMin: 0,
  numberMax: 1000,
  numberStep: 1,
  onBlur: () => {},
  onChange: () => {}
};

export default NumericInput;
