/**
 * Formats an address.
 *
 * @example
 * ```js
 *  formatAddress('2550 South Clark St', null, 'Arlington', 'VA', 22202);
 *  returns => '2550 South Clark St, \nArlington, VA 22202'
 * ```
 *
 * @param line1
 * @param line2
 * @param city
 * @param state
 * @param zip
 * @returns Formatted address string
 */
export default function formatAddress(
  line1: string = null,
  line2: string = null,
  city: string = null,
  state: string = null,
  zip: number | string = null
) {
  if (line1 && line2 && city && state && zip) {
    if (line2 === null) return `${line1}, \n${city}, ${state} ${zip}`;
    if (line2 === '') return `${line1}, \n${city}, ${state} ${zip}`;
    return `${line1}, ${line2} \n${city}, ${state} ${zip}`;
  }

  if (line1 && city && state && zip) {
    return `${line1}, \n${city}, ${state} ${zip}`;
  }

  return '';
}
