import { createSlice } from '@reduxjs/toolkit';
import getInitialState from './get-initial-state';

import CONFIG from '../../config';

const {
  SESSION: { ACCESS_TOKEN }
} = CONFIG;

const initialState = getInitialState();

const authSecretSlice = createSlice({
  name: 'authSecret',
  initialState,
  reducers: {
    setAccessToken(state, action) {
      if (action?.payload !== null) {
        window?.sessionStorage.setItem(ACCESS_TOKEN, action.payload);
      }
      return action.payload;
    }
  }
});

export const { setAccessToken } = authSecretSlice.actions;

export default authSecretSlice.reducer;
