import getQuantityObject from './get-quantity-object';

/**
 * Formats an quantityOfMeasure code into string.
 *
 * @example
 * ```js
 *  formatQuantityOfMeasure(1, 'C28254');   // '1 Milliliter'
 *  formatQuantityOfMeasure(2, 'C64933');   // '2 Each'
 *  formatQuantityOfMeasure(1, 'C48540');   // '1 Syringe'
 * ```
 *
 * @param quantityRequestValue
 * @param quantityUnitOfMeasureCode
 * @requires utils/getQuantityObject
 * @returns Formatted value string.
 */
export default function formatQuantityOfMeasure(
  quantityRequestValue: number = 0,
  quantityUnitOfMeasureCode: string = ''
) {
  const reqValue = String(quantityRequestValue);
  const { preferredTermNCPDP } = getQuantityObject(quantityUnitOfMeasureCode);

  return `${reqValue} ${preferredTermNCPDP}`;
}
