import React, { ReactElement } from 'react';
import { ReadonlyFormText } from '@pixie/components';
import { formatPhoneNumber } from '@pixie/utils';
import { IEbvEligibilityResponse } from '@pixie/interfaces';
import * as Styled from './EbvBenefitsInfo.styled';



interface IEbvPatientCardProps {
  ebvEligibilityResponse: IEbvEligibilityResponse;
  isLoading?: boolean;
}

const EbvPatientCard = (props: IEbvPatientCardProps): ReactElement => {
  const {
    ebvEligibilityResponse,
    isLoading
  } = props;

  const {
    eligibilityPatient,
    eligibilityPrescriber
  } = ebvEligibilityResponse;

  const formatAddressForCard = (value) => {
    if (value === 'patient' && eligibilityPatient.addressLine1 && eligibilityPatient.addressCity
     && eligibilityPatient.addressState && eligibilityPatient.addressPostalCode) {
      return `${eligibilityPatient.addressLine1} ${eligibilityPatient.addressCity} ${eligibilityPatient.addressState} ${eligibilityPatient.addressPostalCode}`;
    }
    if (eligibilityPrescriber.addressLine1 && eligibilityPrescriber.addressCity && eligibilityPrescriber.addressState && eligibilityPrescriber.addressPostalCode) {
      return `${eligibilityPrescriber.addressLine1} ${eligibilityPrescriber.addressCity} ${eligibilityPrescriber.addressState} ${eligibilityPrescriber.addressPostalCode}`;
    }
    return '';
  };

  const formatDate = (date) => {
    return date ? `${date?.substring(4, 6)}/${date?.substring(6, 8)}/${date.substring(0, 4)}` : '--';
  };

  return (
    <Styled.EbvBenefitsInfo>
      {isLoading ? (
        <>
          <div className="is-loading">
            <div className="uk-spinner" data-uk-spinner />
          </div>
        </>
      ) : (
        <div>
          <div className="card-box uk-grid">
            <div className='uk-width-1-1' data-testId="ebv-patient-name">
              <h2> {eligibilityPatient.lastName}, {eligibilityPatient.firstName} {eligibilityPatient.middleName?.substring(0, 1)}{eligibilityPatient?.middleName ? '.' : ''}</h2>
            </div>
            <div className="card-left uk-card uk-card-xsmall uk-card-body">
              <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small" data-uk-grid>
                <div>
                  <ReadonlyFormText
                    dataTestId="ebv-patient-card-gender"
                    label="Gender"
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    textContent={eligibilityPatient.gender || '--'}
                  />
                </div>
                <div>
                  <ReadonlyFormText
                    dataTestId="ebv-patient-card-date-of-birth"
                    label="Date Of Birth"
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    textContent={formatDate(eligibilityPatient.dateOfBirth)}
                  />
                </div>
                <div className="patient-address uk-width-1-1">
                  <ReadonlyFormText
                    dataTestId="ebv-patient-card-address"
                    label="Address"
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    textContent={formatAddressForCard('patient') || '--'}
                  />
                </div>
              </div>
            </div>
            <div className="card-right uk-card uk-card-xsmall uk-card-body">
              <div className="uk-child-width-1-4@m uk-grid-small" data-uk-grid>
                <div>
                  <ReadonlyFormText
                    dataTestId="ebv-patient-card-prescriber-name"
                    label='Prescriber Name'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    textContent={`${eligibilityPrescriber.firstName} ${eligibilityPrescriber.lastName}` || '--'}
                  />
                </div>
                <div>
                  <ReadonlyFormText
                    dataTestId="ebv-patient-card-prescriber-npi"
                    label='NPI'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    textContent={eligibilityPrescriber.npi || '--'}
                  />
                </div>
                <div>
                  <ReadonlyFormText
                    dataTestId="ebv-patient-card-prescriber-phone-number"
                    label='Phone Number'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    textContent={formatPhoneNumber(eligibilityPrescriber.phoneNumber) || '--'}
                  />
                </div>
                <div>
                  <ReadonlyFormText
                    dataTestId="ebv-patient-card-prescriber-fax-number"
                    label='Fax Number'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    textContent={formatPhoneNumber(eligibilityPrescriber.faxNumber) || '--'}
                  />
                </div>
                <div className="uk-width-1-1">
                  <ReadonlyFormText
                    dataTestId="ebv-patient-card-prescriber-address"
                    label='Address'
                    labelClassName="benefits_label"
                    textClassName="benefits_text"
                    textContent={formatAddressForCard('prescriber') || '--'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Styled.EbvBenefitsInfo>
  );
};


EbvPatientCard.defaultProps = {
  isLoading: true
};

export default EbvPatientCard;
