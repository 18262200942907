import { ReactElement } from 'react';
import * as React from 'react';
import { ReadonlyFormText } from '@pixie/components';
import * as Styled from './TheMedicalRequestHeader.styled';

interface ICaseDetailsProps {
  caseNumber: string;
  isLoading: boolean;
  payerCaseId: string;
  prescription: string;
  prescriberName: string;
  payerName: string;
}

const MedicalCaseDetails = (props: ICaseDetailsProps): ReactElement => {
  const { caseNumber, isLoading, prescription, prescriberName, payerCaseId, payerName } = props;

  return (
    <Styled.CaseDetails>
      <div className="uk-grid uk-child-width-auto@m uk-flex-between@m" data-uk-grid>
        <div className="title-prescriber-info-section">
          <div className="pixie__request-header-case-number uk-child-width-auto">
            <ReadonlyFormText
              dataTestId='case-details-drug-description'
              label="Case No."
              labelClassName='medical-title-label'
              textClassName='medical-title-normal-text'
              loading={isLoading}
              textContent={caseNumber}
              textWrap
            />
          </div>
          <div className="pixie__request-header-payer-medical uk-child-width-auto">
            <ReadonlyFormText
              dataTestId='case-details-payer-medical'
              label="Payer Name"
              labelClassName='medical-title-label'
              textClassName='medical-title-normal-text'
              loading={isLoading}
              textContent={payerName}
            />
          </div>
          <div className="pixie__request-header-prescriber uk-child-width-auto">
            <ReadonlyFormText
              dataTestId='case-details-prescriber'
              label="Prescribing Physician"
              labelClassName='medical-title-label'
              textClassName='medical-title-normal-text'
              loading={isLoading}
              textContent={prescriberName}
            />
          </div>
          <div className="pixie__request-header-patient-name uk-child-width-auto">
            <ReadonlyFormText
              dataTestId='case-details-patient-name'
              label="Medication for Prior Authorization"
              labelClassName='medical-title-medication-label'
              textClassName='medical-title-medication-text'
              loading={isLoading}
              textContent={prescription}
            />
          </div>
          <div className="pixie__request-header-payer-case-id uk-child-width-auto">
            <ReadonlyFormText
              dataTestId='case-details-payer-case-id'
              label="PA Case ID"
              labelClassName='medical-title-medication-label'
              textClassName='medical-title-medication-text'
              loading={isLoading}
              textContent={payerCaseId}
              textWrap
            />
          </div>
        </div>
      </div>
    </Styled.CaseDetails>
  );
};

export default MedicalCaseDetails;
