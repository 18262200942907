/* eslint-disable no-use-before-define */
// @ts-nocheck
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTable, useExpanded } from 'react-table';
import { formatDate } from '../../utils';
import CONFIG from '../../config';
import * as Styled from './Table.styled';

const { LABELS } = CONFIG;

const TaskHistoryTable = props => {
  const { data } = props;

  const taskHistoryColumns = useMemo(
    () => [
      {
        Header: 'Task',
        accessor: 'name', // accessor is the "key" in the data
        headerClassName: 'uk-table-expand',
        Cell: ({ row }) => <span>{LABELS[row.values.name]}</span>
        // ------------------ use the accessor here ^^^^
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'uk-text-bold',
        Cell: ({ row }) => <span>{LABELS[row.values.status]}</span>
      },
      {
        Header: 'Date/Time',
        accessor: 'timestamp',
        Cell: ({ row }) =>
          row.values.timestamp ? (
            <>
              <time dateTime={row.values.timestamp}>
                {formatDate(row.values.timestamp, true)}
              </time>
            </>
          ) : (
            <>
              <span>-</span>
            </>
          )
      },
      {
        Header: 'Notes',
        accessor: 'paNote',
        headerClassName: 'uk-table-shrink',
        className:'row--notes',
        Cell: ({ row }) => <div>{row.values.paNote}</div>
      }
    ],
    []
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row }) => <div>{row.values.expander}</div>,
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns
  } = useTable(
    {
      columns: taskHistoryColumns,
      data
    },
    useExpanded // We can useExpanded to track the expanded state
    // for sub components too!
  );

  return (
    <Styled.Table>
      <table {...getTableProps()} className="style--task-history uk-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps({
                    className: column.headerClassName
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              // Use a React.Fragment here so the table markup is still valid
              <Fragment key={idx}>
                <tr
                  {...row.getRowProps()}
                  className={idx % 2 === 0 ? 'row-even' : 'row-odd'}
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        data-testId={`case-history-${cell.column.id}-${cell.row.id}`}
                        {...cell.getCellProps({
                          className: cell.column.className
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>

                {/*
                  If the row is in an expanded state, render a row with a
                  column that fills the entire length of the table.
                */}
                {row.values.expander && (
                  <>
                    <tr
                      className={
                        row.isExpanded
                          ? 'row--isExpanded uk-animation-slide-top-small uk-animation-fast'
                          : 'uk-hidden'
                      }
                    >
                      <td colSpan={visibleColumns.length}>
                        {/*
                      Inside it, call our renderRowSubComponent function.
                      In reality, you could pass whatever you want as props
                      to a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row
                    */}
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  </>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </Styled.Table>
  );
};

export default TaskHistoryTable;
