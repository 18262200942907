import os from 'os';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import * as serviceWorker from './serviceWorker';
import store from './store';
import App from './App';
import makeServer from './server';
// eslint-disable-next-line import/extensions
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/css/uikit.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toggle/style.css';
import './styles/index.scss';

// @ts-ignore
UIkit.use(Icons);

// if on local mac (not AWS windows on client network),
// use mirageJS api to load static api from src/json dir
const isNodeDevEnv = process.env.NODE_ENV === 'development';
const isMacOs = os?.release()?.includes('Mac OS');
// comment out this following line to allow workflow using dev instead of canned responses in server.js
if (isNodeDevEnv && isMacOs) makeServer();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
