/* eslint-disable @typescript-eslint/indent */
import React, { ReactElement } from 'react';
import { classesArray } from '@pixie/utils';
import CachedIcon from '@mui/icons-material/Cached';
import * as Styled from './Button.styled';

export interface IButtonProps {
  dataTestId?: string;
  disabled: boolean;
  id?: string;
  isLoading: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  buttonStyle:
    | string
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'outline'
    | 'error'
    | 'danger'
    | 'warning'
    | 'alert'
    | 'success'
    | 'link'
    | 'modal'
    | 'refresh';
  // buttonStyle: string;
  type?: 'submit' | 'button' | 'reset';
  text: string;
  onClick?: (...args: any[]) => any;
  ukModalClose?: boolean;
  ukToggle?: string;
}

const Button = (props: IButtonProps): ReactElement => {
  const {
    dataTestId,
    disabled,
    id,
    isLoading,
    isSuccess,
    isError,
    buttonStyle,
    type,
    text,
    onClick,
    ukModalClose,
    ukToggle
  } = props;

  const isDisabled = !!(disabled || isError || isLoading || isSuccess);

  return (
    <Styled.Button
      id={id}
      data-testid={dataTestId}
      disabled={isDisabled}
      className={classesArray([
        buttonStyle,
        isLoading ? 'loading' : '',
        isError ? 'error uk-animation-shake' : '',
        isSuccess ? 'success' : '',
        ukModalClose ? 'uk-modal-close' : ''
      ])}
      type={type}
      onClick={onClick}
      // eslint-disable-next-line no-unneeded-ternary
      data-uk-toggle={ukToggle ? ukToggle : null}
    >
      {isLoading && !isError && !isSuccess && (
        <div
          className="uk-animation-fade uk-icon uk-spinner"
          data-uk-spinner="ratio: 0.5;"
        />
      )}

      {!isLoading && !isError && isSuccess && (
        <div
          className="uk-animation-fade uk-icon"
          data-uk-icon="icon: check; ratio: 1;"
        />
      )}

      {!isLoading && isError && !isSuccess && (
        <div
          className="uk-animation-fade uk-icon"
          data-uk-icon="icon: close; ratio: 1;"
        />
      )}

      <span
        className={
          isError || isLoading || isSuccess
            ? 'uk-invisible'
            : 'uk-animation-fade uk-animation-fast'
        }
      >
        {text === 'Refresh Verification' && (
          <span className="icon-refresh">
            <CachedIcon  />
          </span>
        )}

        {text}
      </span>
    </Styled.Button>
  );
};

Button.defaultProps = {
  dataTestId: '',
  disabled: false,
  id: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  onClick: () => {},
  buttonStyle: 'primary',
  type: 'submit',
  text: 'Button',
  ukModalClose: false
};

export default Button;
