import { createSlice } from '@reduxjs/toolkit';
import {
  concatPatientName,
  formatAddress,
  formatDate,
  formatPhoneNumber
} from '../../utils';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  patientAddressLine1: '',
  patientAddressLine2: '',
  patientCity: '',
  patientCountryCode: '',
  patientDateOfBirth: '',
  patientFirstName: '',
  patientFormattedAddress: '',
  patientFormattedName: '',
  patientGender: '',
  patientLastName: '',
  patientMiddleName: '',
  patientPostalCode: '',
  patientPrimaryContactNo: '',
  patientStateProvince: '',
  patientId: ''
};

const initialState = DEFAULT_STATE;

const patientInfoSlice = createSlice({
  name: 'patientInfo',
  initialState,
  reducers: {
    clearPatientInfoError: state => clearError(state),
    clearPatientInfoSuccess: clearSuccess,
    resetPatientInfo: () => DEFAULT_STATE,
    setPatientInfo(state, { payload }) {
      const {
        patientAddressLine1,
        patientAddressLine2,
        patientCity,
        patientCountryCode,
        patientDateOfBirth,
        patientFirstName,
        patientGender,
        patientLastName,
        patientMiddleName,
        patientPostalCode,
        patientPrimaryContactNo,
        patientStateProvince,
        patientId
      } = payload;

      state.patientAddressLine1 = patientAddressLine1 || '';
      state.patientAddressLine2 = patientAddressLine2 || '';
      state.patientCity = patientCity || '';
      state.patientCountryCode = patientCountryCode || '';

      state.patientDateOfBirth = patientDateOfBirth
        ? formatDate(patientDateOfBirth)
        : '';

      state.patientFirstName = patientFirstName || '';

      if (
        patientAddressLine1 !== null &&
        patientCity !== null &&
        patientStateProvince !== null &&
        patientPostalCode !== null
      ) {
        state.patientFormattedAddress = formatAddress(
          patientAddressLine1,
          patientAddressLine2 || '',
          patientCity,
          patientStateProvince,
          patientPostalCode
        );
      }

      state.patientFormattedName = concatPatientName(
        patientFirstName,
        patientLastName,
        patientMiddleName || '-'
      );
      state.patientGender = patientGender || '';
      state.patientLastName = patientLastName || '';
      state.patientMiddleName = patientMiddleName || '-';
      state.patientPostalCode = patientPostalCode || '';
      state.patientPrimaryContactNo = formatPhoneNumber(
        patientPrimaryContactNo
      );
      state.patientStateProvince = patientStateProvince || '';
      state.patientId = patientId || '';
    },
    setPatientInfoError: loadingFailed,
    setPatientInfoSuccess: loadingSuccess,
    startPatientInfoLoading: startLoading,
    stopPatientInfoLoading: stopLoading
  }
});

export const {
  clearPatientInfoError,
  clearPatientInfoSuccess,
  resetPatientInfo,
  setPatientInfo,
  setPatientInfoError,
  setPatientInfoSuccess,
  startPatientInfoLoading,
  stopPatientInfoLoading
} = patientInfoSlice.actions;
export default patientInfoSlice.reducer;

/**
 * Sequence for loading and setting patient information.
 * @param {Object} data
 */
export const fetchPatientInfo = data => async dispatch => {
  try {
    dispatch(startPatientInfoLoading());
    dispatch(setPatientInfo(data));
    dispatch(setPatientInfoSuccess());
  } catch (err) {
    dispatch(setPatientInfoError(String(err)));
  }
};
