import CONFIG from '@pixie/config';
import { IRefreshTokenResponse } from '@pixie/api';
import '../api/axios-config';
import axios, { AxiosResponse } from 'axios';

const { API, SESSION } = CONFIG;
const { ACCESS_TOKEN } = SESSION;

const useApigeeRefreshToken = () => {
  const { APIGEE_TOKEN_REFRESH_REQUEST_URL } = API;

  return (): Promise<IRefreshTokenResponse> => {
    return new Promise((resolve, reject) => {
      const currentAccessToken = window?.sessionStorage.getItem(ACCESS_TOKEN);
      const refreshTokenRequest = {
        access_token: currentAccessToken
      };
      
      axios
        .post(APIGEE_TOKEN_REFRESH_REQUEST_URL, refreshTokenRequest, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response: AxiosResponse) => {
          window?.sessionStorage.setItem('apigee_access_token', response.data.apigee_access_token);
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export { useApigeeRefreshToken };

export default useApigeeRefreshToken;
