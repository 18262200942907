import userFriendlyMessages from './user-friendly-messages.json';

export type IUserFriendlyMessage = {
  scenario: string;
  caseStatus: string;
  taskStatus: string;
  sureScriptsReq: boolean;
  reasonCode: string;
  messageType: string;
  service: string;
  instructions: string;
  notes: string;
};

// prettier-ignore
const messagesConfig = {
  APPEAL_AVAILABLE_HEADLINE: 'Appeal now available',
  APPEAL_AVAILABLE_INSTRUCTIONS: 'The request has been denied. However, you may submit an appeal to try and reinstate the process.',
  FILE_UPLOAD_QUESTION_TEXT: 'Please upload any documents requested in previous question set.',
  NO_ACTIONS_AVAILABLE_HEADLINE: 'No actions currently available.',
  NO_ACTIONS_AVAILABLE_INSTRUCTIONS: 'There are no actions needed at this time.',
  QUESTIONS_AVAILABLE_HEADLINE: 'Question set now available.',
  QUESTIONS_AVAILABLE_INSTRUCTIONS: 'You have question set that need to be answered to proceed with this Prior Authorization request. After completion, please submit the question set to be reviewed.',
  REQUEST_APPROVED_HEADLINE: 'Request Approved',
  REQUEST_CANCELLED_INSTRUCTIONS: 'This request has been cancelled.',
  REQUEST_CANCELLED_PA_INSTRUCTIONS: 'This request has been cancelled by the payer.',
  REQUEST_CLOSED_HEADLINE: 'Request Closed',
  REQUEST_COMPLETE_HEADLINE: 'Request Complete',
  REQUEST_COMPLETE_INSTRUCTIONS: 'This request has been closed and there are no further actions to take.',
  REQUEST_ERROR_HEADLINE: 'An error has occured.',
  REQUEST_INITIATED_INSTRUCTIONS: 'Your prior authorization request has been initiated and we are waiting on a response from the payer. There are no actions for you to take at this time. Please check back later to see if the status has changed.',
  REQUEST_SUBMITTED_PA_INSTRUCTIONS: 'We are waiting on a response from the payer. There are no actions for you to take at this time. Please check back later to see if the status has changed.',
};

const userFriendlyMessagesConfig = {
  ALL_MESSAGES: userFriendlyMessages,
  APPROVED_MESSAGES: userFriendlyMessages.filter(
    (obj: IUserFriendlyMessage) => {
      return (
        obj?.taskStatus !== null &&
        obj?.taskStatus === 'Approved' &&
        obj?.caseStatus !== null &&
        obj?.caseStatus === 'Approved'
      );
    }
  ),
  CANCELLED_MESSAGES: userFriendlyMessages.filter(
    (obj: IUserFriendlyMessage) => {
      return (
        (obj?.caseStatus !== null && obj?.caseStatus === 'Cancelled') ||
        (obj?.taskStatus !== null && obj?.taskStatus === 'Cancelled')
      );
    }
  ),
  CLOSED_MESSAGES: userFriendlyMessages.filter((obj: IUserFriendlyMessage) => {
    return (
      (obj?.caseStatus !== null && obj?.caseStatus === 'Closed') ||
      obj?.reasonCode !== null
    );
  }),
  DENIED_MESSAGES: userFriendlyMessages.filter((obj: IUserFriendlyMessage) => {
    return (
      obj?.taskStatus !== null &&
      obj?.taskStatus === 'Denied' &&
      obj?.caseStatus !== null &&
      obj?.caseStatus === 'Denied'
    );
  }),
  ERROR_MESSAGES: userFriendlyMessages.filter((obj: IUserFriendlyMessage) => {
    return obj?.caseStatus !== null && obj?.caseStatus === 'Error';
  }),
  IN_PROGRESS_MESSAGES: userFriendlyMessages.filter(
    (obj: IUserFriendlyMessage) => {
      return (
        obj?.taskStatus !== null &&
        obj?.taskStatus === 'In-Progress' &&
        obj?.caseStatus !== null &&
        obj?.caseStatus === 'In-Progress'
      );
    }
  ),
  PARTIALLY_DENIED_MESSAGES: userFriendlyMessages.filter(
    (obj: IUserFriendlyMessage) => {
      return (
        obj?.taskStatus !== null &&
        obj?.taskStatus === 'Partially Denied' &&
        obj?.caseStatus !== null &&
        obj?.caseStatus === 'Partially Denied'
      );
    }
  )
};

export { messagesConfig, userFriendlyMessagesConfig };
