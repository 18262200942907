import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatDate } from '@pixie/utils';
import { useSignIn, useSignOut, useRefreshTokens } from '@pixie/hooks';
import { postRegenAuthCodeRequest } from '@pixie/api';
import { AlertContent, SignIn, SnackBar } from '@pixie/components';
import { RootState } from '@pixie/store';
import * as config from '@pixie/config';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Styled from './TheLoginPage.styled';

export interface ITheLoginPageProps {
  isPatientAccessUi: boolean;
}

const authnInitialState = {
  authCodeFirst: '',
  authCodeSecond: '',
  firstName: '',
  lastName: '',
  dob: null,
  showAuthCode: true,
  caseCode: ''
};

const errorInitialState = {
  status: '',
  caseCode: ''
};

const TheLoginPage = (props: ITheLoginPageProps): ReactElement => {
  const { isPatientAccessUi } = props;
  const [open, setOpen] = useState(false);
  const [authnButtonState, setAuthnButtonState] = useState(false);
  const [regenAuthCode, setRegenAuthCode] = useState(false);
  const [values, setValues] = useState(authnInitialState);
  const [error, setError] = useState(errorInitialState);
  const app = useSelector((state: RootState) => state.app);

  const authCodeRef = useRef(null);
  const firstNameRef = useRef(null);

  const history = useHistory();
  const signIn = useSignIn();
  const signOut = useSignOut();
  const refreshTokens = useRefreshTokens();
  // Track page view
  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({});
    // Disabling exhaustive-deps here to have this run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    signOut().then();
    // Disabling exhaustive-deps here to have this run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickShowAuthCode = () => {
    setValues({ ...values, showAuthCode: !values.showAuthCode });
  };

  const handleMouseDownAuthCode = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const handleFormChange = prop => event => {
    setValues({ ...values, [prop]: event.value || event.target.value });
  };

  const handlePatientDOBChange = (prop, value) => {
    setValues({
      ...values,
      [prop]: value !== null ? formatDate(value) : value
    });
  };

  const handlePatientAuthChange = prop => event => {
    setValues({ ...values, [prop]: event.value || event.target.value.toUpperCase() });
  };

  useEffect(() => {
    if (values.authCodeFirst.length === 5 && !values.authCodeFirst.includes('_')) {
      authCodeRef.current.focus();
    }
  }, [values.authCodeFirst]);

  useEffect(() => {
    if (values.authCodeSecond.length === 5 && !values.authCodeSecond.includes('_')) {
      firstNameRef.current.focus();
    }
  }, [values.authCodeSecond]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const authenticate = (event: any) => {
    event.preventDefault();
    setAuthnButtonState(true);
    setError({ ...error, status: '' });
    trackEvent({
      category: 'Login page',
      action: 'Click Submit Button',
      name: 'Continue to Prior Authorization'
    });

    const request = {
      authCode: `${values.authCodeFirst  }-${  values.authCodeSecond}`,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      dob: values.dob
    };

    const devRequest = {
      authCode:  `${values.authCodeFirst  }-${  values.authCodeSecond}`,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      dob: values.dob,
      caseCode: values.caseCode
    };
    if (config.getConfig().APP.USE_AUTH_FLOW) {
      signIn(app?.isDevEnv ? devRequest : request)
        .then((data: any) => {
          if (data.status === 'SUCCESS') {
            refreshTokens().then(() => {
              history.push(`/Request/${data.caseCode}`);
            });
          } else {
            setError({
              status: data.status,
              caseCode: data.caseCode
            });
            setAuthnButtonState(false);
          }
        })
        .catch(err => {
          if (typeof err?.message === 'function') {
            setError({ ...error, status: err.message() });
          } else if (err?.request?.response) {
            setError({ ...error, status: err?.request?.response });
          } else if (err?.request?.responseText) {
            setError({ ...error, status: err?.request?.responseText });
          } else {
            setError({ ...error, status: 'An error has occurred.' });
          }
          setAuthnButtonState(false);
        });
    }
  };

  const regenerateAuthCode = () => {
    setRegenAuthCode(true);
    postRegenAuthCodeRequest(error.caseCode)
      .then((data: any) => {
        if (data.status === 'SUCCESS') {
          setRegenAuthCode(false);
          setError({ ...error, status: '' });
          setValues(authnInitialState);
          handleClick();
        }
      })
      .catch(err => {
        setRegenAuthCode(false);
      });
  };

  return (
    <Styled.Div>
      <div className="horizontal-line" />
      <div className="wrapper">
        <div className="background">&nbsp;</div>
        <div className="uk-container-expand pixie-container">
          <SnackBar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            autoHideDuration={5000}
            dataTestId="the-login-page-auth-code-regen-successful"
            displayText="Authentication Code successfully regenerated!"
            onClose={handleClose}
            open={open}
          />
          <div className="uk-grid content" data-uk-grid>
            <div className="uk-width-expand@m">
              <AlertContent
                dataTestId="the-login-page-alert-content"
                onClick={regenerateAuthCode}
                regenAuthCode={regenAuthCode}
                regenButtonText="Request New Authentication Code"
                status={error.status}
                isPatientAccessUi={isPatientAccessUi}
              />
            </div>

            <div className="pixie-fixed-width uk-width-auto@m">
              <SignIn
                authnButtonState={authnButtonState}
                authCodeRef={authCodeRef}
                firstNameRef={firstNameRef}
                isDevEnv={app?.isDevEnv}
                onChange={handleFormChange}
                onClick={handleClickShowAuthCode}
                onAuthCodeChange={handlePatientAuthChange}
                onDobChange={handlePatientDOBChange}
                onMouseDown={handleMouseDownAuthCode}
                onSubmit={authenticate}
                signInButtonText="Submit"
                status={error.status}
                values={values}
              />
              <div className='prescriber-image-section'>
                <img
                  className="pixie-prescriber-left-img"
                  src="../../../PrescriberLeft.svg"
                  alt='Cardinal Health'
                  style={{ height: 'auto', width: '340' }}
                />
                <img
                  className="pixie-prescriber-right-img"
                  src="../../../PrescriberRight.svg"
                  alt='Cardinal Health'
                  style={{ height: 'auto', width: '340' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled.Div>
  );
};

export default TheLoginPage;
