/* eslint-disable @typescript-eslint/indent */
import { createSlice } from '@reduxjs/toolkit';
import { formatQuantityOfMeasure } from '../../utils';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  primaryDiagnosisCode: '',
  primaryDiagnosisDesc: '',
  secondaryDiagnosisCode: '',
  secondaryDiagnosisDesc: '',
  diagnosisQualifier: '',
  medicationNdc: '',
  drugDescription: '',
  sigText: '',
  quantityRequestValue: null,
  quantityUnitOfMeasureCode: '',
  daysSupply: null,
  strengthValue: '',
  strengthFormCode: '',
  strengthUnitOfMeasureCode: '',
  dispenseAsWritten: false,
  drugDbCode: '',
  medicationQualifier: '',
  quantityCodeListQualifier: '',
  formattedQuantityRequestValue: '',
  productCodeValue: ''
};

const initialState = DEFAULT_STATE;

const prescriptionInfoSlice = createSlice({
  name: 'prescriptionInfo',
  initialState,
  reducers: {
    clearPrescriptionInfoError: state => clearError(state),
    clearPrescriptionInfoSuccess: clearSuccess,
    resetPrescriptionInfo: () => DEFAULT_STATE,
    setPrescriptionInfo(state, { payload }) {
      const {
        diagnosis: {
          primaryDiagnosisCode,
          primaryDiagnosisDesc,
          secondaryDiagnosisCode,
          secondaryDiagnosisDesc,
          diagnosisQualifier
        },
        medicationPrescribed: [
          {
            medicationNdc,
            drugDescription,
            sigText,
            quantityRequestValue,
            quantityUnitOfMeasureCode,
            daysSupply,
            strengthValue,
            strengthFormCode,
            strengthUnitOfMeasureCode,
            dispenseAsWritten,
            drugDbCode,
            medicationQualifier,
            quantityCodeListQualifier,
            productCodeValue
          }
        ]
      } = payload;

      state.primaryDiagnosisCode = primaryDiagnosisCode || '';
      state.primaryDiagnosisDesc = primaryDiagnosisDesc || '';
      state.secondaryDiagnosisCode = secondaryDiagnosisCode || '';
      state.secondaryDiagnosisDesc = secondaryDiagnosisDesc || '';
      state.diagnosisQualifier = diagnosisQualifier || '';
      state.medicationNdc = medicationNdc || '';
      state.drugDescription = drugDescription || '';
      state.sigText = sigText || '';
      state.quantityRequestValue = quantityRequestValue || null;
      state.quantityUnitOfMeasureCode = quantityUnitOfMeasureCode || '';
      state.daysSupply = daysSupply || null;
      state.strengthValue = strengthValue || '';
      state.strengthFormCode = strengthFormCode || '';
      state.strengthUnitOfMeasureCode = strengthUnitOfMeasureCode || '';
      state.dispenseAsWritten = dispenseAsWritten || false;
      state.drugDbCode = drugDbCode || '';
      state.medicationQualifier = medicationQualifier || '';
      state.quantityCodeListQualifier = quantityCodeListQualifier || '';

      state.formattedQuantityRequestValue =
        quantityRequestValue && quantityUnitOfMeasureCode
          ? formatQuantityOfMeasure(
              quantityRequestValue,
              quantityUnitOfMeasureCode
            )
          : '';

      state.productCodeValue = productCodeValue || '';
    },
    setPrescriptionInfoError: loadingFailed,
    setPrescriptionInfoSuccess: loadingSuccess,
    startPrescriptionInfoLoading: startLoading,
    stopPrescriptionInfoLoading: stopLoading
  }
});

export const {
  clearPrescriptionInfoError,
  clearPrescriptionInfoSuccess,
  resetPrescriptionInfo,
  setPrescriptionInfo,
  setPrescriptionInfoError,
  setPrescriptionInfoSuccess,
  startPrescriptionInfoLoading,
  stopPrescriptionInfoLoading
} = prescriptionInfoSlice.actions;
export default prescriptionInfoSlice.reducer;

/**
 * Sequence for loading and setting latest task data.
 * @param {Object} data
 */
export const fetchPrescriptionInfo = data => async dispatch => {
  try {
    dispatch(startPrescriptionInfoLoading());
    dispatch(setPrescriptionInfo(data));
    dispatch(setPrescriptionInfoSuccess());
  } catch (err) {
    dispatch(setPrescriptionInfoError(String(err)));
  }
};
