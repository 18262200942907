import React, { ReactElement } from 'react';
import { Button } from '@pixie/components';
import * as Styled from './TheAppNavbar.styled';

export interface ITheAppNavbarProps {
  isPatientAccessUi: boolean;
  isReviewerApp?: boolean;
  location?: any;
  signOut?: any;
}

const TheAppNavbar = (props: ITheAppNavbarProps): ReactElement => {
  const {
    isReviewerApp,
    location,
    signOut,
    isPatientAccessUi
  } = props;

  const { pathname } = location;
  const isLoginScreen = pathname === '/Login' || pathname === '/';

  let headingText = 'Cardinal Health™ Prior Authorization';
  if (isPatientAccessUi) {
    headingText = 'Cardinal Health™ Benefits Verification & Prior Authorization';
  }

  return (
    <Styled.Navbar id="Navbar">
      <div className="uk-container uk-container-expand">
        <nav className="uk-navbar">
          <div className="uk-navbar-left">
            <div className="uk-navbar-app uk-navbar-item">
              <h3 className="remove-whitespace" data-testid="the-app-navbar-heading">{headingText}</h3>
            </div>
          </div>

          {!isReviewerApp && !isLoginScreen && (
            <div className="uk-navbar-right">
              <div className="uk-navbar-item uk-visible@m">
                <div className="request-bar">
                  <Button
                    dataTestId="the-app-navbar-logout-button"
                    text="Logout"
                    onClick={signOut}
                    buttonStyle="outline"
                  />
                </div>
              </div>
            </div>
          )}
        </nav>
      </div>
    </Styled.Navbar>
  );
};

TheAppNavbar.defaultPropos = {
  isReviewerApp: false
};

export default TheAppNavbar;
