import { answerSelectQuestion } from '../questionSet.slice';

/**
 * Sends radio choice data object to Redux store
 */
const handleRadioClick = (
  questionId: string,
  choiceId: string,
  additionalFreeTextIndicator: string
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(
    answerSelectQuestion({
      questionId,
      choiceId,
      additionalFreeTextIndicator,
      additionalFreeText: ''
    })
  );
};

export default handleRadioClick;
