import { ReactElement, useEffect, useState } from 'react';
import * as React from 'react';
import { IAuthorizationDetails, IAuthorizationDetailsDates } from '@pixie/api';
import { Card, Select, ReadonlyFormText } from '@pixie/components';
import { classesArray, getQuantityObject } from '@pixie/utils';
import * as Styled from './TheRequestHeader.styled';

interface IAuthDetailsProps {
  authorizationNumber?: string;
  authorizationPeriod?: IAuthorizationDetailsDates;
  isLoading: boolean;
  paAuthorizationDetails?: IAuthorizationDetails[];
}

const AuthDetails = (props: IAuthDetailsProps): ReactElement => {
  const {
    authorizationPeriod: {
      authorizationEffectiveDateTime,
      authorizationExpirationDateTime
    },
    authorizationNumber,
    isLoading,
    paAuthorizationDetails
  } = props;

  const [activeDetail, setActiveDetail] = useState<IAuthorizationDetails>(
    paAuthorizationDetails ? paAuthorizationDetails[0] : undefined
  );

  useEffect(() => {
    if (paAuthorizationDetails) setActiveDetail(paAuthorizationDetails[0]);
  }, [paAuthorizationDetails]);

  const concatQuantityString = (
    quantityValue: number,
    quantityCode: string
  ) => {
    if (!quantityValue || !quantityCode) return '-';

    const codeObj = getQuantityObject(quantityCode);
    const codeTerm = codeObj?.preferredTermNCPDP;

    return `${quantityValue} ${codeTerm}`;
  };

  const renderComponent =
    (paAuthorizationDetails.length !== 0 && activeDetail !== undefined) ||
    (authorizationEffectiveDateTime && authorizationExpirationDateTime) ||
    authorizationNumber;

  const renderSecondGrid =
    authorizationEffectiveDateTime ||
    authorizationExpirationDateTime ||
    authorizationNumber;

  return (
    !isLoading &&
    renderComponent && (
      <>
        <Styled.AuthDetails
          className={classesArray([
            'uk-container',
            'uk-container-large',
            'uk-section',
            'uk-section-xsmall',
            'uk-animation-fade'
          ])}
          id="AuthDetails"
        >
          <hr />
          <h2 className="uk-h4 ">
            <span className='auth-details'>Authorization Details</span>
          </h2>

          {renderSecondGrid && (
            <div
              className="uk-grid uk-grid-column-medium uk-margin"
              data-uk-grid
            >
              {authorizationNumber && (
                <>
                  <div>
                    <ReadonlyFormText
                      labelClassName='medical-title-label'
                      textClassName='medical-title-normal-text'
                      label="Authorization Number"
                      textContent={authorizationNumber}
                      textWrap
                    />
                  </div>
                </>
              )}

              {authorizationEffectiveDateTime && (
                <>
                  <div>
                    <ReadonlyFormText
                      labelClassName='medical-title-label'
                      textClassName='medical-title-normal-text'
                      label="Authorization Effective Date"
                      textContent={authorizationEffectiveDateTime}
                      textWrap
                    />
                  </div>
                </>
              )}

              {authorizationExpirationDateTime && (
                <>
                  <div>
                    <ReadonlyFormText
                      labelClassName='medical-title-label'
                      textClassName='medical-title-normal-text'
                      label="Authorization Expiration Date"
                      textContent={authorizationExpirationDateTime}
                      textWrap
                    />
                  </div>
                </>
              )}
            </div>
          )}

          <div
            className={classesArray([
              'uk-grid',
              'uk-child-width-1-4@m',
              'uk-child-width-1-5@l',
              'uk-child-width-1-6@xl',
              'uk-grid-match',
              'uk-grid-small'
            ])}
            data-uk-grid
          >
            {activeDetail?.pharmacyType && (
              <>
                <div className="uk-animation-slide-bottom-small select-wrap">
                  {paAuthorizationDetails.length > 1 ? (
                    <Select
                      id="paAuthorizationDetails__pharmacyType"
                      name="paAuthorizationDetails__pharmacyType"
                      options={paAuthorizationDetails.map(details => {
                        return {
                          value: details.pharmacyType,
                          label: details.pharmacyType,
                          details
                        };
                      })}
                      value={{
                        value: activeDetail.pharmacyType,
                        label: activeDetail.pharmacyType,
                        details: activeDetail
                      }}
                      onChange={option => {
                        setActiveDetail(option.details);
                      }}
                    />
                  ) : (
                    activeDetail?.pharmacyType && (
                      <Card dataTestId='auth-details-pharmacy'>
                        <ReadonlyFormText
                          labelClassName='medical-title-label'
                          textClassName='medical-title-normal-text'
                          label="Pharmacy"
                          textContent={activeDetail.pharmacyType}
                          textWrap
                        />
                      </Card>
                    )
                  )}
                </div>
              </>
            )}

            {activeDetail?.quantityValue && (
              <>
                <div className="uk-animation-slide-bottom-small">
                  <Card dataTestId='auth-details-quantity'>
                    <ReadonlyFormText
                      labelClassName='medical-title-label'
                      textClassName='medical-title-normal-text'
                      label="Quantity"
                      textContent={concatQuantityString(
                        activeDetail.quantityValue,
                        activeDetail.quantityUnitOfMeasureCode
                      )}
                      textWrap
                    />
                  </Card>
                </div>
              </>
            )}

            {activeDetail?.numberOfCycles && (
              <>
                <div className="uk-animation-slide-bottom-small">
                  <Card dataTestId='auth-details-number-of-cycles'>
                    <ReadonlyFormText
                      labelClassName='medical-title-label'
                      textClassName='medical-title-normal-text'
                      label="No. Cycles"
                      textContent={activeDetail.numberOfCycles}
                      textWrap
                    />
                  </Card>
                </div>
              </>
            )}

            {activeDetail?.numberOfRefills && (
              <>
                <div className="uk-animation-slide-bottom-small">
                  <Card dataTestId='auth-details-number-of-refills'>
                    <ReadonlyFormText
                      labelClassName='medical-title-label'
                      textClassName='medical-title-normal-text'
                      label="No. Refills"
                      textContent={activeDetail.numberOfRefills}
                      textWrap
                    />
                  </Card>
                </div>
              </>
            )}

            {activeDetail?.paNote && (
              <>
                <div className="notes uk-width-auto">
                  <Card dataTestId='auth-details-authorization-notes'>
                    <ReadonlyFormText
                      labelClassName='medical-title-label'
                      textClassName='medical-title-normal-text'
                      label="Authorization Notes"
                      textContent={activeDetail.paNote}
                      textWrap
                    />
                  </Card>
                </div>
              </>
            )}
          </div>
        </Styled.AuthDetails>
      </>
    )
  );
};

AuthDetails.defaultProps = {
  paAuthorizationDetails: [],
  authorizationPeriod: {
    authorizationEffectiveDateTime: '',
    authorizationExpirationDateTime: ''
  },
  authorizationNumber: ''
};

export default AuthDetails;
