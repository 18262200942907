import { createSlice } from '@reduxjs/toolkit';
import getInitialState from './get-initial-state';

import CONFIG from '../../config';

const {
  SESSION: { CASE_CODE }
} = CONFIG;

const initialState = getInitialState();

const caseCodeSlice = createSlice({
  name: 'caseCode',
  initialState,
  reducers: {
    setCaseCodeForAuthCode(state, action) {
      if (action?.payload !== null) {
        window?.sessionStorage.setItem(CASE_CODE, action.payload);
      }
      return action.payload;
    }
  }
});

export const { setCaseCodeForAuthCode } = caseCodeSlice.actions;

export default caseCodeSlice.reducer;
