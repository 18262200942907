import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
  externalURL: null
};

const initialState = DEFAULT_STATE;

const externalLinkSlice = createSlice({
  name: 'externalLink',
  initialState,
  reducers: {
    setExternalUrl(state, action) {
      state.externalURL = action.payload;
    },
    clearExternalUrl(state) {
      state.externalURL = null;
    }
  }
});

export const { clearExternalUrl, setExternalUrl } = externalLinkSlice.actions;
export default externalLinkSlice.reducer;
