import './axios-config';
import axios, { AxiosResponse } from 'axios';
import CONFIG from '@pixie/config';
import { IEbvQuery } from 'interfaces/ebv-query';

const { API } = CONFIG;

/**
 * Sends a GET request to the API's get eligibility history endpoint.
 *
 * @param caseCode
 * @requires axios
 * @requires axios-config
 * @requires pixie/config
 */
const eligibilityHistoryRequest = (
  caseCode: string | null
): Promise<IEbvQuery> => {
  const { EBV_ELIGIBILITY_URL } = API;
  return new Promise((resolve, reject) => {
    axios
      .get(`/${EBV_ELIGIBILITY_URL}/${caseCode}/list`)
      .then((response: AxiosResponse) => {
        resolve(response?.data?.result);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default eligibilityHistoryRequest;
