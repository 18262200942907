import './axios-config';
import axios, { AxiosResponse } from 'axios';
import CONFIG from '@pixie/config';

const { API } = CONFIG;

const postRegenAuthCodeRequest = (caseCode: string) => {
  const { REGEN_AUTH_CODE_URL } = API;

  return new Promise((resolve, reject) => {
    axios
      .post(`/${REGEN_AUTH_CODE_URL}/${caseCode}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default postRegenAuthCodeRequest;
