import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from '@pixie/components';

interface IAppealToggleButton {
  actionsAvailable: boolean;
  availableAction: string;
  onClick: (event: React.TouchEvent | React.MouseEvent) => void;
  open: boolean;
}

const AppealToggleButton = (props: IAppealToggleButton): ReactElement => {
  const { actionsAvailable, availableAction, onClick, open } = props;
  const [style, setStyle] = useState<any>('secondary');
  const [text, setText] = useState('');

  useEffect(() => {
    if (open) {
      setStyle('tertiary');
      setText('Hide Appeal View');
    } else if (availableAction === 'APPEAL') {
      setStyle('alert');
      setText('Submit an Appeal');
    } else {
      setStyle('secondary');
      setText('No Appeal Available');
    }
  }, [availableAction, open]);

  return (
    <Button
      buttonStyle={style}
      disabled={!actionsAvailable}
      dataTestId="data-test-id-appeal-toggle-button"
      onClick={onClick}
      text={text}
      type="button"
      ukToggle="target: #AppealInput;"
    />
  );
};

export default AppealToggleButton;
