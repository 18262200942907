import React, { ReactElement } from 'react';
import { classesArray } from '@pixie/utils';
import * as Styled from './ReadonlyFormText.styled';

export interface IReadonlyFormTextProps {
  dataTestId?: string;
  label?: string;
  labelClassName?: string;
  textClassName?: string;
  loading?: boolean;
  textContent: string;
  textWrap: boolean;
}

const ReadonlyFormText = (props: IReadonlyFormTextProps): ReactElement => {
  const { dataTestId,
    label,
    labelClassName,
    loading,
    textContent,
    textClassName,
    textWrap } = props;

  return (
    <Styled.ReadonlyFormText
      className="pixie__readonly-form-text"
      data-testId={dataTestId}
      textWrap={textWrap}
    >
      {label !== null ? <span className={labelClassName}>{label}</span> : null}
      {loading ? (
        <p className="loading-animation-swipe">{textContent}</p>
      ) : (
        <p className={classesArray([
          textClassName,
          'uk-animation-fade uk-animation-fast'
        ])}
        >{textContent}
        </p>
      )}
    </Styled.ReadonlyFormText>
  );
};

ReadonlyFormText.defaultProps = {
  label: null,
  labelClassName:'label',
  loading: false,
  textClassName:'',
  textContent: '',
  textWrap: false
};

export default ReadonlyFormText;
