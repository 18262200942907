import React from 'react';
import { Question, Select } from '@pixie/components';
import { IQuestionType, ISelectChoice } from '@pixie/api';

interface IQuestionProps {
  dataTestId:string;
  isSelectMultiple?: boolean;
  onChange: any;
  questionText: string;
  questionTitle?: string;
  questionType: IQuestionType;
}

const QuestionSelect = (props: IQuestionProps) => {
  const {
    dataTestId,
    isSelectMultiple,
    questionTitle,
    questionText,
    questionType,
    onChange
  } = props;

  const options = questionType.select.choice.map((item: ISelectChoice) => {
    const { choiceId, choiceText } = item;
    return { value: choiceId, label: choiceText };
  });

  return (
    <Question questionText={questionText} questionTitle={questionTitle}>
      <Select
        dataTestId={dataTestId}
        options={options}
        isMulti={isSelectMultiple}
        onChange={selectedOption => onChange(selectedOption)}
      />
    </Question>
  );
};

QuestionSelect.defaultProps = {
  isSelectMultiple: false,
  questionTitle: ''
};

export default QuestionSelect;
