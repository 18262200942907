/**
 * Returns the height with inches appended to the end if a weight is provided
 */
const formatHeight = (value: string): any => {
  const trimmedValue = value?.trim();
  if (trimmedValue != null) {
    return `${trimmedValue} inches`;
  }
  return trimmedValue;
};

export default formatHeight;
