import styled from 'styled-components';

export const EbvRTBCCard = styled.div`
  .uk-grid > div {
    transition: width, margin 150ms ease-out;
    will-change: width, margin;
  }

  .rtbc-card-box {
    justify-content: center;
    border-radius: 0;
    margin-top: 20px;
    margin-left: 5px;
    padding: 30px 10px 30px 10px;
    background-color: var(--color-ebvBackground);
    border: 1px solid var(--color-ebvBorder);
    border-radius: 10px;
    align-items: stretch;
  }

  .RTBC-pharmacy {
    padding: 10px 0px 10px 0px;
    margin-left: 10px;
    background-color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 10px;
    align-items: stretch;
    height: 100%;
    resize: none;
  }

  .rtbc-card-body {
    padding: 10px 50px 10px 50px;
  }

  .rtbc-card-header {
    padding: 10px 50px 10px 50px;
    align-items: stretch;
    font-size: 24px;
  }

  .horizontal-line {
    padding: 0px;
  }

  .section-seperator {
    padding-left: -50px;
    padding-right: -50px;
  }

  .coverage-alert {
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
    color: var(--color-shield);
  }

  .coverage-alert-header {
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: bold;
    color: var(--color-shield);
  }
  .rtbc-disclaimer {
    font-size: 13px;
  }

  .rtbc-header {
    padding-top: 40px;
    padding-left: 20px;
  }

  .uk-width-1-1 {
    width: 25%;
  }

  .uk-width-1-2 {
    width: 25%;
  }

  .uk-width-1-3 {
    width: 25%;
  }

  .rtbc-cards {
    display: flex;
    justify-content: center;
  }

  .rtbc-drugDescription {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    text-align: center;
  }

  .rtbc-coverage-alert-link {
    color: var(--color-shield);
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
    text-decoration: underline;
  }

  .rtbc-card-pharmacy-type {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .rtbc-card-logos {
    font-size: 60px;
    color: var(--color-black);
    padding-top: 10px;
  }

  .rtbc-pharmacy-type-header {
    padding-left: 20px;
  }

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
    font-size: 60px;
  }
`;
