import { combineReducers } from '@reduxjs/toolkit';

import {
  actionCardSlice,
  alertsSlice,
  appealSlice,
  appSlice,
  authSecretSlice,
  benefitsInfoSlice,
  cancelPaRequestSlice,
  caseCodeSlice,
  caseInfoSlice,
  externalLinkSlice,
  latestTaskSlice,
  patientInfoSlice,
  pharmacyInfoSlice,
  prescriberInfoSlice,
  prescriptionInfoSlice,
  questionSetSlice,
  EBVBenefitsSlice,
  selectedEligiblityPlanSlice,
  routingTypeSlice,
  treatmentInfoSlice
} from '@pixie/features';

const rootReducer = combineReducers({
  actionCard: actionCardSlice,
  alerts: alertsSlice,
  app: appSlice,
  appeal: appealSlice,
  caseCode: caseCodeSlice,
  authSecret: authSecretSlice,
  benefitsInfo: benefitsInfoSlice,
  cancelPaRequest: cancelPaRequestSlice,
  caseInfo: caseInfoSlice,
  externalLink: externalLinkSlice,
  latestTask: latestTaskSlice,
  patientInfo: patientInfoSlice,
  pharmacyInfo: pharmacyInfoSlice,
  prescriberInfo: prescriberInfoSlice,
  prescriptionInfo: prescriptionInfoSlice,
  questionSet: questionSetSlice,
  ebvBenefits: EBVBenefitsSlice,
  selectedEligiblityPlan: selectedEligiblityPlanSlice,
  routingType: routingTypeSlice,
  treatmentInfo: treatmentInfoSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
