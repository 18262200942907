import styled from 'styled-components';

export const Label = styled.span`
  border-radius: 2px;
  color: #162427;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding: 0 6px;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;

  &.pixie__label--pixie-h2 {
    font-weight: 700;
    font-size: 1.875rem;
    color: black; !important;
  }
  &.pixie__label--secondary {
    background: var(--color-primary);
    color: #ffffff;
    background-color: var(--color-secondary);
  }

  &.pixie__label--error,
  &.pixie__label--danger {
    background: var(--color-primary);
    color: #2b1514;
    background-color: var(--color-error);
  }

  &.pixie__label--warning,
  &.pixie__label--alert {
    background: var(--color-primary);
    color: #443c34;
    background-color: var(--color-warning);
  }

  &.pixie__label--success {
    background: var(--color-primary);
    color: #23482e;
    background-color: var(--color-success);
  }

  .pixie__label--status {
    background: #ffffff;
    background-color: #ffffff;
  }

  &.ellipsis-loader {
    background: var(--color-primary);
    min-width: 27px;
  }
`;
