import { createSlice } from '@reduxjs/toolkit';
import { formatAddress, formatPhoneNumber } from '../../utils';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  pharmacyAddressLine1: '',
  pharmacyAddressLine2: '',
  pharmacyCity: '',
  pharmacyContactNumber: '',
  pharmacyFormattedAddress: '',
  pharmacyName: '',
  pharmacyPostalCode: '',
  pharmacyStateProvince: ''
};

const initialState = DEFAULT_STATE;

const pharmacyInfoSlice = createSlice({
  name: 'pharmacyInfo',
  initialState,
  reducers: {
    clearPharmacyInfoError: state => clearError(state),
    clearPharmacyInfoSuccess: clearSuccess,
    resetPharmacyInfo: () => DEFAULT_STATE,
    setPharmacyInfo(state, { payload }) {
      const {
        pharmacyAddressLine1,
        pharmacyAddressLine2,
        pharmacyCity,
        pharmacyContactNumber,
        pharmacyName,
        pharmacyPostalCode,
        pharmacyStateProvince
      } = payload;

      state.pharmacyAddressLine1 = pharmacyAddressLine1 || '';
      state.pharmacyAddressLine2 = pharmacyAddressLine2 || '';
      state.pharmacyCity = pharmacyCity || '';
      state.pharmacyContactNumber = formatPhoneNumber(pharmacyContactNumber);

      if (
        pharmacyAddressLine1 !== null &&
        pharmacyCity !== null &&
        pharmacyStateProvince !== null &&
        pharmacyPostalCode !== null
      ) {
        state.pharmacyFormattedAddress = formatAddress(
          pharmacyAddressLine1,
          pharmacyAddressLine2 || '',
          pharmacyCity,
          pharmacyStateProvince,
          pharmacyPostalCode
        );
      }

      state.pharmacyName = pharmacyName || '';
      state.pharmacyPostalCode = pharmacyPostalCode || '';
      state.pharmacyStateProvince = pharmacyStateProvince || '';
    },
    setPharmacyInfoError: loadingFailed,
    setPharmacyInfoSuccess: loadingSuccess,
    startPharmacyInfoLoading: startLoading,
    stopPharmacyInfoLoading: stopLoading
  }
});

export const {
  clearPharmacyInfoError,
  clearPharmacyInfoSuccess,
  resetPharmacyInfo,
  setPharmacyInfo,
  setPharmacyInfoError,
  setPharmacyInfoSuccess,
  startPharmacyInfoLoading,
  stopPharmacyInfoLoading
} = pharmacyInfoSlice.actions;
export default pharmacyInfoSlice.reducer;

/**
 * Sequence for loading and setting latest task data.
 * @param {Object} data
 */
export const fetchPharmacyInfo = data => async dispatch => {
  try {
    dispatch(startPharmacyInfoLoading());
    dispatch(setPharmacyInfo(data));
    dispatch(setPharmacyInfoSuccess());
  } catch (err) {
    dispatch(setPharmacyInfoError(String(err)));
  }
};
