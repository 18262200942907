const debugCertCases = [
  // cert 1
  {
    label: 'Cert ePA 1-1 (PA Init Request)',
    value: 'be6e26ee5c3f47ed969b7d92a0280e8c-1'
  },
  {
    label: 'Cert ePA 1-3 (PA Init Response)',
    value: 'be6e26ee5c3f47ed969b7d92a0280e8c-3'
  },
  {
    label: 'Cert ePA 1-4 (PA Request)',
    value: 'be6e26ee5c3f47ed969b7d92a0280e8c-4'
  },
  {
    label: 'Cert ePA 1-6 (PA Response Partially Denied)',
    value: 'be6e26ee5c3f47ed969b7d92a0280e8c-6'
  },
  // cert 2
  {
    label: 'Cert ePA 2-3 (Init Response)',
    value: '73cad4fcdb584357be97cc14efe8a7a2-1'
  },
  {
    label: 'Cert ePA 2-4 (PA Request Sent)',
    value: '73cad4fcdb584357be97cc14efe8a7a2-4'
  },
  {
    label: 'Cert ePA 2-6 (In_Process Response)',
    value: '73cad4fcdb584357be97cc14efe8a7a2-6'
  },
  {
    label: 'Cert ePA 2-7 (Denied Response)',
    value: '73cad4fcdb584357be97cc14efe8a7a2-7'
  },
  {
    label: 'Cert ePA 2-8 (Appeal Request Sent)',
    value: '73cad4fcdb584357be97cc14efe8a7a2-8'
  },
  {
    label: 'Cert ePA 2-10 (Appeal Response Approved)',
    value: '73cad4fcdb584357be97cc14efe8a7a2-10'
  },
  // cert 3
  {
    label: 'Cert ePA 3-1 (Init Request)',
    value: '654ada67b2e44efb8d79a7067e64a627-1'
  },
  {
    label: 'Cert ePA 3-2 (Init Response #1 Closed CD Code)',
    value: '654ada67b2e44efb8d79a7067e64a627-2'
  },
  {
    label: 'Cert ePA 3-3 (Init Response #2 Closed BY Code)',
    value: '654ada67b2e44efb8d79a7067e64a627-3'
  },
  // cert 4
  {
    label: 'Cert ePA 4-1 (Init Request)',
    value: '9f8f3ef6cfcb44aa9b8f6e9420af1bbd-1'
  },
  {
    label: 'Cert ePA 4-3 (Init Response)',
    value: '9f8f3ef6cfcb44aa9b8f6e9420af1bbd-3'
  },
  {
    label: 'Cert ePA 4-4 (Cancel Request Sent)',
    value: '9f8f3ef6cfcb44aa9b8f6e9420af1bbd-4'
  },
  {
    label: 'Cert ePA 4-6 (Cancel Response Approved)',
    value: '9f8f3ef6cfcb44aa9b8f6e9420af1bbd-6'
  },
  // cert 5
  {
    label: 'Cert ePA 5-1 (Init PA)',
    value: 'c20994c5010f4176ace88166a858373d-1'
  },
  {
    label: 'Cert ePA 5-3 (Init Response #1 (IN_PROCESS))',
    value: 'c20994c5010f4176ace88166a858373d-3'
  },
  {
    label: 'Cert ePA 5-4 (Init Response #2 (IN_PROCESS))',
    value: 'c20994c5010f4176ace88166a858373d-4'
  },
  {
    label: 'Cert ePA 5-5 (PA Request Sent)',
    value: 'c20994c5010f4176ace88166a858373d-5'
  },
  {
    label: 'Cert ePA 5-7 (PA Response)',
    value: 'c20994c5010f4176ace88166a858373d-7'
  },
  // cert 6
  {
    label: 'Cert ePA 6-2 (Init Request)',
    value: '2246c0dea0be45fa8e8eb5e30f91f18a-2'
  },
  {
    label: 'Cert ePA 6-4 (Init Response)',
    value: '2246c0dea0be45fa8e8eb5e30f91f18a-4'
  },
  {
    label: 'Cert ePA 6-5 (PA Request Sent)',
    value: '2246c0dea0be45fa8e8eb5e30f91f18a-5'
  },
  {
    label: 'Cert ePA 6-7 (Open Response)',
    value: '2246c0dea0be45fa8e8eb5e30f91f18a-7'
  },
  {
    label: 'Cert ePA 6-8 (PA Request #2)',
    value: '2246c0dea0be45fa8e8eb5e30f91f18a-8'
  },
  {
    label: 'Cert ePA 6-10 (Approved Response)',
    value: '2246c0dea0be45fa8e8eb5e30f91f18a-10'
  },
  // cert 7
  {
    label: 'Cert ePA 7-1 (Init Request)',
    value: '47e2824e441b40aa8ff802ebb998ed65-1'
  },
  {
    label: 'Cert ePA 7-3 (Init Response)',
    value: '47e2824e441b40aa8ff802ebb998ed65-3'
  },
  {
    label: 'Cert ePA 7-4 (PA Request Sent)',
    value: '47e2824e441b40aa8ff802ebb998ed65-4'
  },
  {
    label: 'Cert ePA 7-6 (PA Response)',
    value: '47e2824e441b40aa8ff802ebb998ed65-6'
  },
  {
    label: 'eBV Open Modal',
    value: 'be6e26ee5c3f47ed969b7d92a0280e8c-2'
  },
  {
    label: 'eBV Skip Modal',
    value: '73cad4fcdb584357be97cc14efe8a7a2-2'
  },
  {
    label: 'Medical ePA 1-1',
    value: 'be6e26ee5c3f47ed969b7d92a0280e8c-medical'
  },
  {
    label: 'Medical ePA 1-3',
    value: 'be6e26ee5c3f47ed969b7d92a0280e8c-3medical'
  },
  {
    label: 'Medical ePA test',
    value: 'be6e26ee5c3f47ed969b7d92a0280e8c-test'
  }
];

export default debugCertCases;
