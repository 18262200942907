import styled from 'styled-components';

export const Article = styled.article`
  label {
    cursor: pointer;
  }

  label > input + span {
    margin-left: 6px;
    pointer-events: none;
    user-select: none;
    cursor: pointer;
  }

  .uk-modal-body {
    position: relative;
  }

  .has-not-been-cancelled {
    transition: filter 200ms ease-out;
    will-change: filter;
  }

  .has-been-cancelled {
    padding: 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .has-been-cancelled:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.865);
  }

  .has-been-cancelled p {
    position: relative;
    z-index: 2;
    display: block;
    width: 60%;
    text-align: center;
    margin: auto;
  }
`;
