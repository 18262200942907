/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { Fragment,  useMemo } from 'react';
import { useTable, useExpanded } from 'react-table';
import * as Styled from './CoverageTable.styled';

const CoverageTable = props => {
  const { data } = props;
  const coverageTableColumns = useMemo(
    () => [
      {
        Header: 'Pharmacy',
        accessor: 'type', // accessor is the "key" in the data
        className: 'pharmacy-width',
        headerClassName: 'uk-table-expand pharmacy-width',
        Cell: ({ row }) => <span>{row.values.type}</span>
        // ------------------ use the accessor here ^^^^
      },
      {
        Header: 'Covered',
        accessor: 'coverage',
        className: 'uk-text-bold uk-text-center',
        headerClassName: 'uk-text-center',
        Cell: ({ row }) => <span>{row.values.coverage }</span>
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns: coverageTableColumns,
      data
    },
    useExpanded // We can useExpanded to track the expanded state
    // for sub components too!
  );

  return (
    <Styled.CoverageTable>
      <table {...getTableProps()} className="style--coverage-table uk-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps({
                    className: column.headerClassName
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              // Use a React.Fragment here so the table markup is still valid
              <Fragment key={idx}>
                <tr
                  {...row.getRowProps()}
                  className='row-background'
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        data-testId={`ebv-coverage-table-${cell.column.id}-${cell.row.id}`}
                        {...cell.getCellProps({
                          className: cell.column.className
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </Styled.CoverageTable>
  );
};

export default CoverageTable;

