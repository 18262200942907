import React, { ReactElement } from 'react';
import { classesArray, formatDate, formatPhoneNumber } from '@pixie/utils';
import * as Styled from './ActionCard.styled';

interface IActionCardProps {
  actionsAvailable: boolean;
  bodyWidth: number;
  children: any;
  dataTestId?: string;
  headline: string;
  instructions: string[];
  isReviewerApp?: boolean;
  loading?: boolean;
  phoneNumber: string;
  respondBy: string;
}

const ActionCard = (props: IActionCardProps): ReactElement => {
  const {
    actionsAvailable,
    bodyWidth,
    children,
    dataTestId,
    headline,
    instructions,
    isReviewerApp,
    loading,
    phoneNumber,
    respondBy
  } = props;

  return (
    <Styled.ActionCard className="uk-grid uk-grid-small" data-uk-grid data-testId={dataTestId}>
      {loading ? (
        <>
          <div className="is-loading">
            <div className="uk-spinner" data-uk-spinner />
          </div>
        </>
      ) : (
        <>
          <div
            className={
              `${!actionsAvailable ? 'uk-width-1-1@m ' : ''}` +
              `${actionsAvailable && bodyWidth <= 959 ? 'uk-width-1-1' : ''}` +
              `${actionsAvailable && bodyWidth > 959 ? 'uk-width-4-5@m' : ''}`
            }
          >
            {headline && (
              <>
                <span>
                  <span className='pixie-h3'>{headline}</span>
                </span>
              </>
            )}

            {instructions && (
              <>
                <ul className='pixie-body'>
                  {instructions.map(instruction => {
                    return (
                      <li className='instructions-text'>{instruction}</li>
                    );
                  })}
                </ul>
              </>
            )}

            {phoneNumber && (
              <>
                <p>
                  <strong>Phone Number:</strong>
                  <span>&nbsp;</span>
                  <span data-testId={`${dataTestId}__phone-number`}>{formatPhoneNumber(phoneNumber)}</span>
                </p>
              </>
            )}
          </div>

          {actionsAvailable && !isReviewerApp && (
            <>
              <div
                className={classesArray([
                  'actions-available uk-animation-slide-bottom-small',
                  bodyWidth <= 959 ? 'flex-start' : 'uk-text-center@m',
                  bodyWidth <= 959
                    ? 'uk-width-1-1 uk-grid-margin'
                    : 'uk-width-1-5@m'
                ])}
              >
                {children}
              </div>
            </>
          )}
        </>
      )}
    </Styled.ActionCard>
  );
};

ActionCard.defaultProps = {
  dataTestId: null,
  isReviewerApp: false,
  loading: false
};

export default ActionCard;
