/**
 * Returns string formatted to display file size.
 *
 * @example
 * ```js
 *  formatBytes(1024);       // 1 KB
 *  formatBytes('1024');     // 1 KB
 *  formatBytes(1234);       // 1.21 KB
 *  formatBytes(1234, 3);    // 1.205 KB
 * ```
 *
 * @param bytes Number value to format
 * @param decimals Amount of decimal points (default=`2`)
 * @see https://stackoverflow.com/a/18650828/8296677
 */
const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default formatBytes;
