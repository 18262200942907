import React, { ReactElement } from 'react';
import { uiKitOptions } from '@pixie/utils';
import TheAppealForm from './TheAppealForm';

interface ITheAppealBar {
  onClose: (event: React.TouchEvent | React.MouseEvent) => void;
  handleRefreshRequest: any;
  renderAppeal: boolean;
}

const TheAppealBar = (props: ITheAppealBar): ReactElement => {
  const {
    onClose,
    handleRefreshRequest,
    renderAppeal
  } = props;

  return (
    <div
      id="AppealInput"
      data-uk-offcanvas={uiKitOptions({
        'bg-close': false,
        'esc-close': false,
        flip: true,
        mode: 'push',
        overlay: false
      })}
    >
      <div className="uk-offcanvas-bar uk-box-shadow-xlarge">
        <button
          aria-label="Close"
          className="uk-offcanvas-close"
          data-uk-close
          onClick={onClose}
          type="button"
        />

        {renderAppeal &&
        <TheAppealForm
          handleRefreshRequest={handleRefreshRequest}
        />}
      </div>
    </div>
  );
};

export default TheAppealBar;
