enum statusIconsConfig {
  'APPROVED' = 'check_circle',
  'CANCELLED' = 'door_back',
  'CLOSED' = 'door_back',
  'DENIED' = 'hide_source',
  'ERROR' = 'error',
  'IN_PROCESS' = 'pending_actions',
  'IN_PROGRESS' = 'pending_actions',
  'OPEN' = 'pending_actions',
  'PARTIALLY_DENIED' = 'check_circle',
  'PROCESS_QUESTIONS' = 'pending_actions',
  'UNKNOWN' = 'error'
}

export default statusIconsConfig;
