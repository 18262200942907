const tasksConfig = {
  TASK_NAMES: {
    ANSWER_QUESTIONS: 'ANSWER_QUESTIONS',
    ANSWER_APPEAL_QUESTIONS: 'ANSWER_APPEAL_QUESTIONS',
    APPEAL_PA: 'APPEAL_PA',
    CANCEL_PA: 'CANCEL_PA',
    INITIATE_PA: 'INITIATE_PA',
    PROCESS_QUESTIONS: 'PROCESS_QUESTIONS'
  },
  TASK_REASON_CODE: {
    BX: 'BX',
    BY: 'BY',
    CC: 'CC',
    CD: 'CD',
    CE: 'CE',
    CF: 'CF',
    CG: 'CG',
    CY: 'CY',
    FL: 'FL',
    FM: 'FM',
    FN: 'FN',
    FO: 'FO',
    FP: 'FP',
    FQ: 'FQ',
    FR: 'FR'
  },
  TASK_RESPONSE_STATUS: {
    APPROVED: 'APPROVED',
    CLOSED: 'CLOSED',
    DENIED: 'DENIED',
    OPEN: 'OPEN',
    PARTIALLY_DENIED: 'PARTIALLY_DENIED'
  },
  TASK_STATUS: {
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED',
    CLOSED: 'CLOSED',
    DENIED: 'DENIED',
    ERROR: 'ERROR',
    IN_PROCESS: 'IN_PROCESS',
    IN_PROGRESS: 'IN_PROGRESS',
    OPEN: 'OPEN',
    PARTIALLY_DENIED: 'PARTIALLY_DENIED',
    SUBMITTED: 'SUBMITTED'
  }
};

export default tasksConfig;
