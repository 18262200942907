import React, { ReactElement, useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Licenses from '../../assets/licenses.json';
import * as Styled from './TheLicensePage.styled';

const licenseData = [];
for ( const k in Licenses){
  if (Licenses[k])
    licenseData.push(Licenses[k]);
}

const TheLicensePage = (): ReactElement => {
  const [showManifest, setManifest] = useState(false);

  const onClickToggleShow = () => setManifest(!showManifest);
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({});
    // Disabling exhaustive-deps here to have this run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.Div className="uk-container uk-container-small">
      <h1>Third Party Licensing</h1>
      <button
        onClick={onClickToggleShow}
        data-testid="the-license-page-toggle-button"
        type="button"
      >
        {showManifest ? 'Show Licenses' : 'Show Manifest' }
      </button>
      { !showManifest &&
        <table>
          <caption>The License Manifest for our project</caption>
          <tbody>
            <tr className="header-row">
              <th>OSS</th>
              <th>Rev</th>
              <th>Source</th>
              <th>License Type</th>
            </tr>
            {licenseData.map((license) => (
              <tr key={`${license.name} - ${license.version}-license`}>
                <td>{license.name}</td>
                <td>{license.version}</td>
                <td>{license.repository}</td>
                <td>{license.licenses}</td>
              </tr>
            ))}
          </tbody>
        </table>
      }
      {showManifest &&
      <>
        {licenseData.map((license) => (
          <div key={`${license.name} - ${license.version}-manifest`}>
            <span className="pixie-license-name">{license.name}</span> -- <span className="pixie-license-copyright">{license.copyright}</span>
            <span className="pixie-license-text">{license.licenseText}</span>
            <hr />
          </div>
        ))}
      </>
      }
    </Styled.Div>
  );
};
export default TheLicensePage;
