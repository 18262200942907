import React, { ReactElement } from 'react';
import { Modal } from '@pixie/components';
import { IRequestDetails } from '@pixie/api';
import { IEbvEligibilityResponse } from '@pixie/interfaces';
import EbvConfirmationForm from './ebvConfirmationForm';

interface IIntiationConfirmationModalProps {
  data: IRequestDetails;
  ebvEligibilityResponse: IEbvEligibilityResponse;
}
const EbvConfirmationModal = (props: IIntiationConfirmationModalProps): ReactElement => {

  const {
    data,
    ebvEligibilityResponse
  } = props;

  if (ebvEligibilityResponse != null) {
    return <></>;
  }

  return (
    <Modal
      id="InitiationConfirmationModal"
      dataTestId='ebv-confirmation-modal'
      isOpen
      shouldCloseOnEscKey={false}
      shouldCloseOnOverlayClick={false}
    >
      <button
        aria-label="Close"
        className="uk-modal-close-default"
        data-uk-close
        type="button"
      />
      <EbvConfirmationForm
        data={data}
      />
    </Modal>
  );
};
export default EbvConfirmationModal;
