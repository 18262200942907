/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
import React, { ReactElement, useState } from 'react';
import { Button, ReadonlyFormText, Select } from '@pixie/components';
import {
  EbvPatientCard,
  EbvMedicationCard,
  EbvBenefitsCard
} from '@pixie/features';
import { IEbvQuery } from '@pixie/interfaces';
import * as Styled from './EbvQueryPage.styled';
import testData from '../../json/eBVCloseModalResponse.json';

const EbvQueryPage = (): ReactElement => {

  const [isBenefitsRequested, setIsBenefitsRequested] = useState<boolean>(false);
  const [ebvQueryData, setEbvQueryData] = useState<IEbvQuery>();
  const [payerId, setPayerId] = useState<String>();

  const onClickSubmit = () => {
    setIsBenefitsRequested(true);
    // Placeholder page - grab the first item in array
    setEbvQueryData(testData.result[0]);
  };

  const handleSelectPayerChange = prop => event => {
    setPayerId(event.value);
  };

  const isSubmitButtonDisabled = () => {
    return payerId != null;
  };

  return (
    <Styled.Div>
      {!isBenefitsRequested && (
        <div className="wrapper">
          <div className="submit-ebv uk-container">
            <div className="payer-dropdown uk-margin-top">
              <h4 className="pixie-highlight">Payer ID</h4>
              <Select
                dataTestId="ebv-query-payer-select"
                classNamePrefix="pixie__select__modal"
                options={[
                  ...require('../../json/payer-dropdown')?.default
                ].map((item: { payerName: string; cHCPayerID: string }) => {
                  return { value: item.cHCPayerID,
                    label: `${item.payerName} - ${item.cHCPayerID}` };
                })}
                onChange={handleSelectPayerChange('caseCode')}
              />
            </div>
            <Button
              dataTestId='ebv-query-submit-button'
              isLoading={false}
              disabled={!isSubmitButtonDisabled()}
              onClick={onClickSubmit}
              text="Submit eBV Query"
              type="button"
            />
          </div>
        </div>
      )}
      {isBenefitsRequested && (

        <div>
          <h2 className="header-information">Medical Benefits Investigation Report</h2>
          <div className="header-case-id">
            <ReadonlyFormText dataTestId="ebv-patient-gender" label="Case No." textContent={ebvQueryData.response.eligibilityPlans[0].paCaseID} />
          </div>
          <EbvPatientCard
            ebvEligibilityResponse={ebvQueryData.response}
            isLoading={false}
          />
          <h3 className="header-information">Benefits Information</h3>
          {ebvQueryData.response.eligibilityPlans.map((eligibilityPlan, index) =>{
            return (
              <div key={`ebv-benefits-card-${index}`}>
                <EbvBenefitsCard
                  eligibilityPlan={eligibilityPlan}
                  bodyWidth={1280}
                  isLoading={false}
                />
              </div>
            );
          })}
          <h3 className="header-information">Medication and Encounter Information</h3>
          <EbvMedicationCard
            ebvEncounterInformation={ebvQueryData.response.eligibilityEncounter}
            isLoading={false}
          />
        </div>
      )}
    </Styled.Div>
  );
};
export default EbvQueryPage;
