import React, { ReactElement } from 'react';
import * as Styled from './Headline.styled';

interface IHeadlineProps {
  style?:string;
  text:string;
}

const Headline = (props:IHeadlineProps): ReactElement => {
  const { style, text } = props;

  switch (style) {
    case 'h6':
      return <Styled.H6>{text}</Styled.H6>;
    case 'h5':
      return <Styled.H5>{text}</Styled.H5>;
    case 'h4':
      return <Styled.H4>{text}</Styled.H4>;
    case 'h3':
      return <Styled.H3>{text}</Styled.H3>;
    case 'h2':
      return <Styled.H2>{text}</Styled.H2>;
    case 'h1':
    default:
      return <Styled.H1>{text}</Styled.H1>;
  }
};

Headline.defaultProps = {
  style: 'h1'
};

export default Headline;
