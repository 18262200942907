import styled from 'styled-components';

export const Label = styled.label`
  box-sizing: border-box;
  display: block;

  .label-container {
    margin-bottom: 8px;
  }

  .label {
    color: var(--color-black--50);
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .hint {
    color: var(--color-black--10);
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-left: 8px;
  }
  .quantity-unit {
    position: relative;
    float: right;
    margin-top: -32px;
    margin-right: 6px;
  }
  .weight-unit {
    position: absolute;
    margin-left: -30px;
    margin-top: 7px;
  }

  .height-unit {
    position: absolute;
    margin-left: -50px;
    margin-top: 7px;
  }

  .error-container {
    margin-top: 4px;
  }

  .confirmation-modal {
    background: #fff;
    border-radius: 3px;
    border: 1px solid var(--color-borderGrey);
    color: black;
    display: inline-block;
    height: 40px;
    max-width: 100%;
    padding: 0 10px;
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, border;
    vertical-align: middle;
    width: 100%;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;

    &:focus-within,
    &:focus-visible {
      border-color: var(--color-primary);
      box-shadow: 0px 0px 6px 0px rgba(81, 149, 213, 0.65);
      outline: none;
    }

    &:disabled {
      background-color: #f9f9f9;
      color: #999;
      border-color: #a8a8a8;
    }
  }

  &.is-required .label {
    padding-left: 10px;
    position: relative;

    &:before {
      background-color: #ef3938;
      border-radius: 50%;
      content: '';
      height: 6px;
      width: 6px;
      position: absolute;
      top: 4px;
      right: auto;
      left: 0;
      bottom: 0;
    }
  }

  &.has-error {
    .label,
    .error-message {
      color: #ee3a3b;
      font-family: 'Open Sans', sans-serif;
      font-size: 11.38px;
      font-weight: 700;
    }

    input[type='text'] {
      border-color: #ee3a3b;
      background-color: #fce3e5;
    }
    input[type='number'] {
      border-color: #ee3a3b;
      background-color: #fce3e5;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .error-symbol {
    vertical-align: middle;
    font-size: 16px;
    font-variation-settings: 'FILL' 1, 'wght' 600, 'GRAD' 0, 'opsz' 20;
    color: var(--color-error);
  }
`;
