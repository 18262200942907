/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, ReadonlyFormText, Select, DiscrepanciesTable, EbvRTBCCard } from '@pixie/components';
import {
  EbvConfirmationModal,
  EbvPatientCard,
  EbvBenefitsCard,
  fetchEbvBenefits,
  startEbvBenefitsLoading,
  stopEbvBenefitsLoading,
  setEligibilityPlanPixieMessageId,
  setEbvQueryId
} from '@pixie/features';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@pixie/store';
import { formatDate } from '@pixie/utils';
import { IEbvQuery, IEbvPlanInformation } from '@pixie/interfaces';
import { IRequestDetails, getEligibilityRequest } from '@pixie/api';
import CONFIG from '@pixie/config';
import * as Styled from './RequestDetailPages.styled';

const {
  TASKS: { TASK_NAMES }
} = CONFIG;

interface IEbvDetailView {
  ebvData: IEbvQuery,
  modalData: IRequestDetails,
  onClickPriorAuthButton: any;
  isLoading: boolean;
}

const EbvDetailView = (props: IEbvDetailView): ReactElement => {

  const {
    ebvData,
    modalData,
    onClickPriorAuthButton,
    isLoading,
  } = props;

  const caseInfo = useSelector((state: RootState) => state.caseInfo);
  const app = useSelector((state: RootState) => state.app);
  const [coverageSelected, setCoverageSelected] = useState<IEbvPlanInformation>();
  const [coveragePlan, setCoveragePlan] = useState<IEbvPlanInformation>();
  const [coverageOptions, setCoverageOptions] = useState<IEbvPlanInformation[]>([]);
  const [isLoadingRefreshButton, setIsLoadingRefreshButton] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onChangeCoverageOptions = ((args) => {
    setCoverageSelected(args);
    setCoveragePlan(args.value);
  });

  const getCoverageOptions = () => {
    return coverageOptions?.map((item: { payerName: string }) => {
      return {
        value: item,
        label: item.payerName,
      };
    });
  };

  const checkIf72HoursElapsed = () => {
    const millisecondsIn1Second = 1000;
    const secondsIn1Minute = 60;
    const minutesIn1Hour = 60;
    const hoursIn1Day = 24;

    const daysEligible = 3; // or 72 hours

    const millisecondsEligible =
      millisecondsIn1Second * secondsIn1Minute * minutesIn1Hour * hoursIn1Day * daysEligible;
    return Date.now() > (Date.parse(displayDateInLocalTimezone(ebvData?.createdDate)) + millisecondsEligible);
  };

  useEffect(() => {
    setCoverageOptions(ebvData?.response?.eligibilityPlans);
    // Needed for setting a default value of the first coverage
    onChangeCoverageOptions({
      value: ebvData?.response?.eligibilityPlans ? ebvData?.response?.eligibilityPlans[0] : '--',
      label: ebvData?.response?.eligibilityPlans ? ebvData?.response?.eligibilityPlans[0]?.payerName : '--'
    });
  }, [ebvData]);

  useEffect(() => {
    dispatch(setEbvQueryId(ebvData?.id));
  }, [dispatch, ebvData]);

  useEffect(() => {
    dispatch(setEligibilityPlanPixieMessageId(coveragePlan?.pixieMessageId));
  }, [dispatch, coveragePlan]);

  const onClickRefresh = () => {
    // TODO: in future story will add email as value to include in passing back to eligibilityHistory
    setIsLoadingRefreshButton(true);
    getEligibilityRequest(modalData.caseCode).then((res: IEbvQuery) =>{
      dispatch(startEbvBenefitsLoading());
      dispatch(fetchEbvBenefits([res]));
      dispatch(stopEbvBenefitsLoading());
      setIsLoadingRefreshButton(false);
    }).catch(err =>{
      const errData = err?.response?.data;
      let errorMsg = ' ';

      if (typeof errData === 'object') {
        if (errData?.status && errData?.message) {
          errorMsg = `[${errData?.status}] ${errData?.message}`;
        } else if (errData?.status && errData?.error) {
          errorMsg = `[${errData?.status}] ${errData?.error}`;
        }
      }

      if (errorMsg.includes('undefined')) errorMsg = ' ';

      setIsLoadingRefreshButton(false);
    });
  };

  const displayDateInLocalTimezone = (dateString : string) => {
    const date = new Date(formatDate(dateString, true));
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset).toLocaleString('en-us');
  };

  return (
    <Styled.EbvDetail>
      <EbvConfirmationModal
        data={modalData}
        ebvEligibilityResponse={ebvData?.response}
      />
      <div className="benefits-page uk-card uk-card-xsmall uk-card-body">
        <section className="uk-section uk-section-xsmall">
          <div className="uk-grid uk-child-width-auto@m uk-flex-between@m" data-uk-grid>
            <div className="uk-flex uk-flex-middle">
              <h2 className="header-information">Pharma Benefits Verification Report</h2>
              <span className="timestamp"> {displayDateInLocalTimezone(ebvData?.createdDate)}</span>
            </div>
            <div>
              <div className="uk-grid uk-child-width-auto@m uk-flex-between@m">
                <ReadonlyFormText
                  dataTestId="ebv-detail-view-case-number"
                  label="Case No."
                  labelClassName="benefits_label"
                  textClassName="benefits_text"
                  textContent={caseInfo.caseNumber}
                />
              </div>
            </div>
          </div>
        </section>
        {ebvData && ebvData?.response && (
        <EbvPatientCard
          ebvEligibilityResponse={ebvData?.response}
          isLoading={isLoading}
        />
        )}
        <hr />
        <div className="uk-grid uk-child-width-auto@m uk-flex-between@m">
          <div className="benefits-coverage uk-width-auto@m">
            <legend className="pixie-legend-required"><b> Coverage</b></legend>
            <Select
              classNamePrefix="pixie__select__modal"
              dataTestId='ebv-detail-view-coverage-select'
              isDisabled={!ebvData?.response?.patientFound}
              value={coverageSelected}
              options={getCoverageOptions()}
              onChange={onChangeCoverageOptions}
            />
          </div>
          <div className="uk-flex uk-flex-middle">
            {checkIf72HoursElapsed() &&
            <div className="rerun-benefits-verification-button">
              <Button
                buttonStyle="ebvSubmitButton"
                dataTestId="ebv-detail-view-refresh-benefits-button"
                isLoading={isLoadingRefreshButton}
                onClick={onClickRefresh}
                text="Refresh Verification"
                type="button"
              />
            </div>}
            <div className="prior-authorization-button">
              <Button
                buttonStyle="ebvSubmitButton"
                dataTestId="ebv-detail-view-prior-auth-button"
                onClick={onClickPriorAuthButton}
                text={(modalData == null ||
              modalData.tasks == null ||
              !(modalData.tasks.length === 1 && modalData.tasks[0].name === TASK_NAMES.INITIATE_PA)) ? 'Create Prior Authorization' : 'Prior Authorization'}
                type="button"
              />
            </div>
          </div>
        </div>

        {((coveragePlan && coveragePlan?.userFriendlyErrors?.length > 0)
          || ebvData?.response?.userFriendlyErrors?.length > 0) && (
          <div className="discrepancies-alert" >
            {coveragePlan?.userFriendlyErrors?.map(message =>
              <div key={message}><b>Alert</b>&emsp;{`${message}`}<br /></div>
            )}
            {ebvData?.response?.userFriendlyErrors?.map(message =>
              <div key={message}><b>Alert</b>&emsp;{`${message}`}<br /></div>
            )}
          </div>
        )}

        {!isLoading &&  coveragePlan && coveragePlan?.payerName && (
        <EbvBenefitsCard
          eligibilityPlan={coveragePlan}
          bodyWidth={app.bodyWidth}
          isLoading={false}
        />
        )}

        {!isLoading && coveragePlan && coveragePlan?.benefitsDetails?.length > 0 && (
        <EbvRTBCCard
          isLoading={false}
          bodyWidth={app.bodyWidth}
          eligibilityPlan={coveragePlan}
          eligibilityEncounter={ebvData?.response?.eligibilityEncounter}
        />)}

        {coveragePlan && coveragePlan.hasPatientChanged && (
        <div className="discrepancies-header">
          <h3 >Discrepancies</h3>
          <div>
            <DiscrepanciesTable data={coveragePlan.patientDiscrepancies} />
          </div>
        </div>
        )}
        <p className="ebv-disclaimer">
          Actual pricing may vary depending on plan structure, deductibles,
          previous payments, future claims and prior authorizations. Information
          provided does not include possible drug interactions or other safety alerts.
          The doses returned for the alternatives do not consider a patient’s health
          status, age, concomitant medications, or other medical considerations.
          They are for informational purposes only and are not intended to replace
          the clinical judgment or medical expertise of the prescriber. Adult doses
          are displayed and the medications presented are for comparison purposes and
          may differ in labeled indication, effectiveness, dosing, side effects and/or
          drug interaction profiles. Clinicians should reference the prescribing information
          for the most appropriate medication and dose for their patients.
        </p>
      </div>
    </Styled.EbvDetail>
  );
};
export default EbvDetailView;
