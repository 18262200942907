import actionCardConfig from './action-card.config';
import apiConfig from './api.config';
import appConfig from './app.config';
import caseConfig from './case.config';
import labelsConfig from './labels.config';
import statusIconsConfig from './status-icons.config';
import { messagesConfig } from './messages.config';
import questionsConfig from './questions.config';
import sessionConfig from './session.config';
import tasksConfig from './tasks.config';
import safeLinkConfig from './safe-links.config';

const CONFIG = Object.freeze({
  ACTIONS: actionCardConfig,
  API: apiConfig,
  APP: appConfig,
  CASE: caseConfig,
  LABELS: labelsConfig,
  MESSAGES: messagesConfig,
  QUESTIONS: questionsConfig,
  SESSION: sessionConfig,
  TASKS: tasksConfig,
  SAFE_LINKS: safeLinkConfig,
  STATUS_ICONS: statusIconsConfig
});

export default CONFIG;

export const getConfig = () => CONFIG;
