import './axios-config';
import axios, { AxiosResponse } from 'axios';
import CONFIG from '@pixie/config';

const { API } = CONFIG;

/**
 * Sends a POST request to the API's appeal endpoint.
 *
 * @param caseCode
 * @param appealInput
 * @requires axios
 * @requires axios-config
 * @requires pixie/config
 */
const submitAppealRequest = (
  caseCode: string | null,
  appealInput: string | null
) => {
  const { APPEAL_INIT_REQUEST_URL } = API;

  return new Promise((resolve, reject) => {
    axios
      .put(`/${APPEAL_INIT_REQUEST_URL}/${caseCode}`, {
        paNote: appealInput
      })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default submitAppealRequest;
