import CONFIG from '@pixie/config';

const {
  MESSAGES: { NO_ACTIONS_AVAILABLE_HEADLINE, NO_ACTIONS_AVAILABLE_INSTRUCTIONS }
} = CONFIG;

export type IActionCard = {
  headline: string;
  instructions: string[];
  phoneNumber?: string;
  respondBy?: string;
};

export type INoActionCard = {
  headline: string;
  instructions: string[];
};

/**
 * Returns an actionCard state object with an actionable response.
 */
const generateActionCard = (
  headline: string,
  instructions: string[],
  phoneNumber?: string,
  respondBy?: string
): IActionCard => {
  return {
    headline,
    instructions,
    phoneNumber: phoneNumber || null,
    respondBy: respondBy || null
  };
};

/**
 * Returns an actionCard state object with no actionable response.
 */
const generateNoActionCard = (
  headline: string = NO_ACTIONS_AVAILABLE_HEADLINE,
  instructions: string[] = [NO_ACTIONS_AVAILABLE_INSTRUCTIONS]
): INoActionCard => {
  return {
    headline,
    instructions
  };
};

export { generateActionCard, generateNoActionCard };
