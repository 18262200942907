/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';
// import { Button } from '@pixie/components';




const formatLinksFromText = (textStr : string, whiteList : string[], clickHandler) => {

  // Turn the white list into RegEx expressions
  const exprs = whiteList.map(safeDomain => {
    return (new RegExp(`${safeDomain.trim()}*`, 'gi'));
  });
  const urlPositions = findURLIndecies(textStr, exprs);
  if (urlPositions.length < 1) return textStr;
  const jsxSections = strToJSXSections(textStr, urlPositions, clickHandler);

  return (jsxSections);
};

const findURLIndecies = (textStr : string, exprs : RegExp[]) => {

  const urlPositions = [];
  if (!textStr) return (urlPositions);

  // Find links in text that are on the whitelist (expected domains)
  for (let i = 0; i < exprs.length; i++){
    let result;
    const indecies = [];  // textStr.search(exprs[i]);
    while ((result = exprs[i].exec(textStr))){
      indecies.push(result.index);
    }

    // Find the ending index of the URL
    for (let j = 0; j < indecies.length; j++){
      const ends = findEndIndex(textStr, indecies[j]);
      urlPositions.push({ startIndex: indecies[j], endIndex: ends });
    }
  }
  urlPositions.sort((a, b)=>{return (a.startIndex - b.startIndex);});
  return (urlPositions);
};

const findEndIndex = (str : string, startIndex : number) => {
  let endIndex = -1;
  if (startIndex > -1) {
    endIndex = str.slice(startIndex).search(/[\s\r\n]/);
    if (endIndex > -1) {
      endIndex += startIndex;
      endIndex = removeFinalPunctuation(str, startIndex, endIndex);
    } else {
      endIndex = removeFinalPunctuation(str, startIndex, str.length);
    }
  }
  return (endIndex);
};

const strToJSXSections = (textStr, urlPositions, clickHandler): ReactElement[] => {

  // slice strings
  let jsxSections = [];
  let setFirstSlice = false;
  let lastLinkInd = -1;

  for (let i = 0; i < urlPositions.length; i++){
    if (urlPositions[i].startIndex > -1 ) {

      if (!setFirstSlice) {
        setFirstSlice = true;
        jsxSections.push(textStr.slice(0, urlPositions[0].startIndex));
      } else if (urlPositions[i - 1].endIndex !== urlPositions[i].startIndex) {
        jsxSections.push(textStr.slice(urlPositions[i - 1].endIndex, urlPositions[i].startIndex));
      }
      jsxSections.push(
        convertToJSXLink(
          textStr.slice(urlPositions[i].startIndex, urlPositions[i].endIndex),
          i.toString(),
          clickHandler
        )
      );
      lastLinkInd = i;
    }
  }
  if ( (lastLinkInd >= 0) && urlPositions[lastLinkInd].endIndex <= textStr.length - 1) {
    jsxSections.push(textStr.slice(urlPositions[lastLinkInd].endIndex, textStr.length));
  } else if (lastLinkInd === -1) jsxSections = [textStr];
  return (jsxSections);
};

const convertToJSXLink = (textStr : string, key, clickHandler) : ReactElement => {
  // eslint-disable-next-line
  return (<a key={key} onClick={() => clickHandler(textStr)}>{textStr}</a>);
  // return (<Button key={key} buttonStyle='link' ukToggle="target: #externalRedirectModal" text={textStr} onClick={() => clickHandler(textStr)} />);
};

const removeFinalPunctuation = (str : string, startInd : number, endInd: number): number => {
  const expr = /[.)?!\]}]/;
  let endIndex = endInd;
  for (let i = endInd - 1; i > startInd; i--) {
    if (expr.test(str[i])) endIndex -= 1;
    else return (endIndex);
  }
  return (endIndex);
};


export default formatLinksFromText;
export { removeFinalPunctuation, convertToJSXLink, strToJSXSections, findEndIndex, findURLIndecies };
