/**
 * Redux action for adding value to select.answer.additionalFreeText
 * @param {Object} state
 * @param {Object} payload
 */
const answerSelectQuestionAdditionalFreeTextAction = (state, { payload }) => {
  // deconstruct the payload
  const { questionId, freeText } = payload;

  /**
   * Find the slot (index) of the current question object
   * @memberof answerSelectQuestionAdditionalFreeTextAction
   */
  const curQuestionSlot = state.question.findIndex(
    q => q.questionId === questionId
  );

  // set the new question array as the sorted array
  state.question[
    curQuestionSlot
  ].questionType.select.answer.prescriberProvidedSelectAnswer[0].additionalFreeText = freeText;
};

export default answerSelectQuestionAdditionalFreeTextAction;
