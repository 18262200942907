import { createSlice } from '@reduxjs/toolkit';
import { triggerGridResizer } from '../../utils';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  bodyWidth: window?.innerWidth,
  isDevEnv: false,
  ua: '',
  browser: {
    name: '',
    version: '',
    major: '' // @deprecated
  },
  engine: {
    name: '',
    version: ''
  },
  os: {
    name: '',
    version: ''
  },
  device: {
    model: '',
    type: '',
    vendor: ''
  },
  cpu: {
    architecture: ''
  }
};

const initialState = DEFAULT_STATE;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clearAppError: state => clearError(state),
    clearAppSuccess: clearSuccess,
    resetApp: () => DEFAULT_STATE,
    setAppBodyWidth(state, { payload }) {
      triggerGridResizer();
      state.bodyWidth = payload;
    },
    setAppError: loadingFailed,
    setAppIsDevEnv(state, { payload }) {
      state.isDevEnv = payload;
    },
    setAppUserAgent(state, { payload }) {
      const { ua, browser, engine, os, device, cpu } = payload;

      state.isLoading = false;
      state.isSuccess = true;

      state.ua = ua || '';

      state.browser = {
        name: browser?.name || '',
        version: browser?.version || ''
      };

      state.engine = {
        name: engine?.name || '',
        version: engine?.version || ''
      };

      state.os = {
        name: os?.name || '',
        version: os?.version || ''
      };

      state.device = {
        model: device.model || '',
        type: device.type || '',
        vendor: device.vendor || ''
      };

      state.cpu = {
        architecture: cpu.architecture || ''
      };
    },
    setAppSuccess: loadingSuccess,
    startAppLoading: startLoading,
    stopAppLoading: stopLoading
  }
});

export const {
  clearAppError,
  clearAppSuccess,
  resetApp,
  setAppBodyWidth,
  setAppError,
  setAppIsDevEnv,
  setAppSuccess,
  setAppUserAgent,
  startAppLoading,
  stopAppLoading
} = appSlice.actions;
export default appSlice.reducer;
