import React, { ReactElement } from 'react';
import IdleTimer from 'react-idle-timer';
import * as Styled from './IdleTimeout.styled';

export interface IIdleTimeoutProps {
  dataTestId: any;
  idleTimer: any;
  handleOnIdle: any;
}

const IdleTimeout = (props: IIdleTimeoutProps): ReactElement => {
  const {
    dataTestId,
    idleTimer,
    handleOnIdle } = props;

  return (
    <Styled.Div className="pixie__alert" data-testId={dataTestId}>
      <div>
        <IdleTimer
          ref={idleTimer}
          timeout={1000 * 60 * 15}
          onIdle={handleOnIdle}
          debounce={250}
        />
      </div>
    </Styled.Div>
  );
};

export default IdleTimeout;
