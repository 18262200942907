import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import * as Styled from './MuiButton.styled';

export interface Props {
  buttonText?: string;
  dataTestId?: string;
  disabled?: boolean;
  onClick?: any;
}

const AlertContent = ({
  buttonText,
  disabled,
  dataTestId,
  onClick
}: Props): ReactElement => {
  return (
    <Styled.Label>
      <Button
        className="auth-button"
        data-testid={dataTestId}
        disabled={disabled}
        sx={{
          whiteSpace: 'normal',
          color: '#ffffff',
          textTransform: 'none',
          padding: '10px',
          fontWeight: 600,
          fontSize: '16px'
        }}
        variant="contained"
        size="medium"
        type="submit"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Styled.Label>
  );
};

export default AlertContent;
