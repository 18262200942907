import { IRequestTask } from '@pixie/api';
import  CONFIG  from '@pixie/config';
import { formatLinksFromText } from '@pixie/utils';

const formatTasksWithLinks = (tasks: IRequestTask[], onLinkClick ) => {

  const  data = tasks.map( taskItem => {
    let notesJSX = null;
    if ( taskItem.paNote ) notesJSX = formatLinksFromText(taskItem.paNote, CONFIG.SAFE_LINKS.SAFE_LINKS_ARRAY, onLinkClick);
    return (
      {
        name: taskItem.name,
        status: taskItem.status,
        timestamp: taskItem.timestamp,
        paNote: notesJSX
      }
    );
  });
  return (data);
};

export default formatTasksWithLinks;
