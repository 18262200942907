import { createSlice } from '@reduxjs/toolkit';
import {
  concatPatientName,
  formatAddress,
  formatPhoneNumber
} from '../../utils';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  prescriberAddressLine1: '',
  prescriberAddressLine2: '',
  prescriberCity: '',
  prescriberClinicName: '',
  prescriberEmail: '',
  prescriberFaxNumber: '',
  prescriberFirstName: '',
  prescriberFormattedAddress: '',
  prescriberFormattedName: '',
  prescriberLastName: '',
  prescriberMiddleName: '',
  prescriberNpi: '',
  prescriberPostalCode: '',
  prescriberCountryCode: '',
  prescriberPrimaryTelephoneNumber: '',
  prescriberStateProvince: ''
};

const initialState = DEFAULT_STATE;

const prescriberInfoSlice = createSlice({
  name: 'prescriberInfo',
  initialState,
  reducers: {
    clearPrescriberInfoError: state => clearError(state),
    clearPrescriberInfoSuccess: clearSuccess,
    resetPrescriberInfo: () => DEFAULT_STATE,
    setPrescriberInfo(state, { payload }) {
      const {
        prescriberAddressLine1,
        prescriberAddressLine2,
        prescriberCity,
        prescriberClinicName,
        prescriberCountryCode,
        prescriberEmail,
        prescriberFaxNumber,
        prescriberFirstName,
        prescriberLastName,
        prescriberMiddleName,
        prescriberNpi,
        prescriberPostalCode,
        prescriberPrimaryTelephoneNumber,
        prescriberStateProvince
      } = payload;

      state.prescriberAddressLine1 = prescriberAddressLine1 || '';
      state.prescriberAddressLine2 = prescriberAddressLine2 || '';
      state.prescriberCity = prescriberCity || '';
      state.prescriberClinicName = prescriberClinicName || '';
      state.prescriberEmail = prescriberEmail || '';
      state.prescriberFaxNumber = formatPhoneNumber(prescriberFaxNumber);
      state.prescriberFirstName = prescriberFirstName || '';

      if (
        prescriberAddressLine1 !== null &&
        prescriberCity !== null &&
        prescriberStateProvince !== null &&
        prescriberPostalCode !== null
      ) {
        state.prescriberFormattedAddress = formatAddress(
          prescriberAddressLine1,
          prescriberAddressLine2 || '',
          prescriberCity,
          prescriberStateProvince,
          prescriberPostalCode
        );
      }

      state.prescriberFormattedName = concatPatientName(
        prescriberFirstName,
        prescriberLastName,
        prescriberMiddleName
      );

      state.prescriberLastName = prescriberLastName || '';
      state.prescriberMiddleName = prescriberMiddleName || '';
      state.prescriberNpi = prescriberNpi || '';
      state.prescriberPostalCode = prescriberPostalCode || '';
      state.prescriberCountryCode = prescriberCountryCode || '';

      if (prescriberPrimaryTelephoneNumber !== null) {
        state.prescriberPrimaryTelephoneNumber = formatPhoneNumber(
          prescriberPrimaryTelephoneNumber
        );
      }

      state.prescriberStateProvince = prescriberStateProvince || '';
    },
    setPrescriberInfoError: loadingFailed,
    setPrescriberInfoSuccess: loadingSuccess,
    startPrescriberInfoLoading: startLoading,
    stopPrescriberInfoLoading: stopLoading
  }
});

export const {
  clearPrescriberInfoError,
  clearPrescriberInfoSuccess,
  resetPrescriberInfo,
  setPrescriberInfo,
  setPrescriberInfoError,
  setPrescriberInfoSuccess,
  startPrescriberInfoLoading,
  stopPrescriberInfoLoading
} = prescriberInfoSlice.actions;
export default prescriberInfoSlice.reducer;

/**
 * Sequence for loading and setting latest task data.
 * @param {Object} data
 */
export const fetchPrescriberInfo = data => async dispatch => {
  try {
    dispatch(startPrescriberInfoLoading());
    dispatch(setPrescriberInfo(data));
    dispatch(setPrescriberInfoSuccess());
  } catch (err) {
    dispatch(setPrescriberInfoError(String(err)));
  }
};
