import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { IRequestDetails } from '@pixie/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@pixie/store';
import {
  closeAppealBar,
  closeQuestionSet,
  disableActionCardActions,
  disableCancelPaRequest,
  enableActionCardAppeal,
  enableActionCardQuestion,
  enableActionCardViewQuestions,
  enableCancelPaRequest,
  fetchActionCard,
  fetchBenefitsInfo,
  fetchCaseInfo,
  fetchLatestTask,
  fetchPatientInfo,
  fetchPharmacyInfo,
  fetchPrescriberInfo,
  fetchPrescriptionInfo,
  fetchQuestionSet,
  resetLatestTask,
  resetQuestionSet
} from '@pixie/features';
import CONFIG from '@pixie/config';
import { IEbvQuery } from 'interfaces/ebv-query';
import * as Styled from './RequestDetailPages.styled';
import RequestDetailView from './RequestDetailView';

const {
  CASE: { CASE_STATUS }
} = CONFIG;

interface IMultiRequestPageProps {
  appIsLoading: boolean;
  data: IRequestDetails[];
  ebvData:IEbvQuery;
  handleRefreshRequest: any;
  responseTimestamp: string;
  appError: string;
}

const TheMultiRequestDetailPage = (
  props: IMultiRequestPageProps
): ReactElement => {
  const {
    appError,
    appIsLoading,
    data,
    ebvData,
    handleRefreshRequest,
    responseTimestamp
  } = props;

  const dispatch = useDispatch();
  const appeal = useSelector((state: RootState) => state.appeal);
  const questionSet = useSelector((state: RootState) => state.questionSet);
  const [activeRequestDataId, setActiveRequestDataId] = useState(
    data && data[0]?.caseCode ? data[0]?.caseCode : ''
  );

  const handleActiveRequestData = useCallback(
    dataId => {
      const dataMatch =
        data &&
        data?.length !== 0 &&
        data.find((obj: IRequestDetails) => obj.caseCode === dataId);
      if (dataMatch) {
        const tasks = dataMatch?.tasks;
        setActiveRequestDataId(dataMatch?.caseCode);
        dispatch(fetchActionCard(dataMatch));
        dispatch(fetchCaseInfo(dataMatch));
        dispatch(fetchBenefitsInfo(dataMatch?.benefitsCoordination[0]));
        dispatch(fetchPatientInfo(dataMatch?.patient));
        dispatch(fetchPharmacyInfo(dataMatch?.pharmacy));
        dispatch(fetchPrescriberInfo(dataMatch?.prescriber));
        dispatch(fetchPrescriptionInfo(dataMatch));
        if (dataMatch?.payerCaseId !== null) {
          if (
            dataMatch.caseStatus !== CASE_STATUS.APPROVED &&
            dataMatch.caseStatus !== CASE_STATUS.CANCELLED &&
            dataMatch.caseStatus !== CASE_STATUS.CLOSED &&
            dataMatch.caseStatus !== CASE_STATUS.DENIED &&
            dataMatch.caseStatus !== CASE_STATUS.PARTIALLY_DENIED
          ) {
            if (tasks && tasks[tasks?.length - 1]?.name !== 'CANCEL_PA') {
              dispatch(enableCancelPaRequest());
            }
          }
        } else {
          dispatch(disableCancelPaRequest());
        }
        if (tasks) {
          const latestTaskObj = tasks[tasks?.length - 1];
          const latestTaskQuestionSet = latestTaskObj?.questionSet;
          const latestTaskStatus = latestTaskObj.status;
          const isAppealSupported = latestTaskObj.eappealSupported;

          if (latestTaskQuestionSet) {
            dispatch(fetchQuestionSet(latestTaskQuestionSet));
          } else {
            dispatch(resetQuestionSet(''));
          }

          if (latestTaskObj) {
            dispatch(fetchLatestTask(latestTaskObj));
          } else {
            dispatch(resetLatestTask());
          }

          if (latestTaskQuestionSet && latestTaskStatus === 'SUBMITTED') {
            dispatch(enableActionCardViewQuestions());
          } else if (latestTaskQuestionSet && latestTaskStatus === 'OPEN') {
            // MAYBE CHANGE TO OPEN - DENIED - PARTIALLYDENIED
            dispatch(enableActionCardQuestion());
          } else if (
            latestTaskQuestionSet &&
            isAppealSupported &&
            (latestTaskStatus === 'PARTIALLY_DENIED' ||
              latestTaskStatus === 'DENIED')
          ) {
            dispatch(enableActionCardQuestion());
          } else if (
            isAppealSupported &&
            (latestTaskStatus === 'PARTIALLY_DENIED' ||
              latestTaskStatus === 'DENIED' ||
              latestTaskStatus === 'APPROVED')
          ) {
            dispatch(enableActionCardAppeal());
          } else {
            dispatch(disableActionCardActions());
          }
        }
      }
    },
    [data, dispatch]
  );

  useEffect(() => {
    if (data && data?.length !== 0) handleActiveRequestData(data[0]?.caseCode);
  }, [data, handleActiveRequestData]);

  useEffect(() => {
    handleActiveRequestData(activeRequestDataId);
  }, [activeRequestDataId, handleActiveRequestData]);

  useEffect(() => {
    setTimeout(() => {
      document
        ?.querySelectorAll<HTMLElement>('.pixie__multi-tab > li')
        .forEach(node => {
          node?.removeAttribute('class');
          if (node?.getAttribute('data-value') === activeRequestDataId) {
            node?.setAttribute('class', 'uk-active');
          }
        });
    }, 1);
  }, [activeRequestDataId, data]);

  const handleTabClick = (value: string) => {
    if (questionSet?.questionSetOpen) {
      dispatch(closeQuestionSet(''));
      const targetString = 'button[data-uk-toggle="target: #QuestionSet;"]';
      document?.querySelector<HTMLButtonElement>(targetString)?.click();
    }

    if (appeal?.appealBarOpen) {
      dispatch(closeAppealBar());
      const targetString = 'button[data-uk-toggle="target: #AppealInput;"]';
      document?.querySelector<HTMLButtonElement>(targetString)?.click();
    }

    return setActiveRequestDataId(value);
  };

  return (
    <Styled.MultiRequestDetail className="uk-container uk-container-large">
      {!appError && !appIsLoading && data ? (
        <>
          <ul className="pixie__multi-tab uk-tab" data-uk-tab>
            {data.map((obj: IRequestDetails) => {
              const { caseCode } = obj;
              return (
                <li key={caseCode} data-value={caseCode}>
                  <button
                    onClick={() => handleTabClick(caseCode)}
                    type="button"
                    data-testid="multi-request-tab-click"
                  >
                    {caseCode}
                  </button>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <>
          <ul className="uk-tab" data-uk-tab>
            <li className="uk-disabled">
              <button onClick={() => {}} type="button">
                {appIsLoading ? 'Loading' : ' '}
              </button>
            </li>
          </ul>
        </>
      )}
      <div className="pixie__multi-request-view">
        <RequestDetailView
          appIsLoading={appIsLoading}
          appError={appError}
          data={
            data && !appError && !appIsLoading
              ? data.find(obj => obj.caseCode === activeRequestDataId)
              : undefined
          }
          ebvData={ebvData}
          handleRefreshRequest={handleRefreshRequest}
          responseTimestamp={responseTimestamp}
        />
      </div>
    </Styled.MultiRequestDetail>
  );
};

export default TheMultiRequestDetailPage;
