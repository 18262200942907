import React, { ReactElement } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { classesArray } from '@pixie/utils';
import * as Styled from './TheAppFooter.styled';

interface ITheAppFooterProps {
  companyName?: string;
  location: any;
  logoAlt: string;
  logoHeight: string;
  logoSrc: string;
  logoWidth: string;
}

const TheAppFooter = (props: ITheAppFooterProps): ReactElement => {
  const {
    companyName,
    location,
    logoAlt,
    logoHeight,
    logoSrc,
    logoWidth
  } = props;

  const date = new Date().toLocaleString('en-us', {
    year: 'numeric'
  });

  const { pathname } = location;
  const isLoginScreen = pathname === '/Login' || pathname === '/';
  return (
    <Styled.Footer
      id="TheAppFooter"
      className={isLoginScreen ? 'login-screen' : ''}
    >
      <div className="uk-container uk-container-expand">
        <div className="uk-navbar">
          <div
            className={classesArray([
              'pixie-cah-logo',
              'uk-navbar-left',
              'uk-flex',
              'uk-flex-middle',
              'uk-width-1-1',
              'uk-width-auto@m'
            ])}
          >
            <img
              className="pixie-cah-logo-img"
              src={logoSrc}
              alt={companyName || logoAlt}
              style={{ height: logoHeight, width: logoWidth }}
            />
          </div>
          <div
            className={classesArray([
              'pixie-cah-legal',
              'uk-navbar-right',
              'uk-flex',
              'uk-flex-center',
              'uk-flex-right@m'
            ])}
          >
            <Router>
              <Switch>
                <Route exact path="/Licensing" />
                <ul className="pixie-cah-footer-list">
                  <li className="pixie-cah-legal-pad">
                    Copyright © <time dateTime={date}>{date}</time>
                  </li>
                  <li className="pixie-cah-legal-pad separator">|</li>
                  <li className="pixie-cah-legal-pad link" >
                    <a
                      href='../../privacy-policy.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='link'
                      data-testId="the-app-footer-privacy-policy"
                    >Privacy Policy
                    </a>
                  </li>
                  <li className="pixie-cah-legal-pad separator">|</li>
                  <li className="pixie-cah-legal-pad">
                    <a
                      href='../../terms-of-use.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='link'
                      data-testId="the-app-footer-terms-of-us"
                    >Terms of Use
                    </a>
                  </li>
                  <li className="pixie-cah-legal-pad separator">|</li>
                  <li
                    className="pixie-cah-legal-pad"
                    data-testID="the-app-footer-licensing"
                  >
                    <Link
                      to="/Licensing"
                      target="_blank"
                      className='link'
                    >Licensing
                    </Link>
                  </li>
                </ul>
              </Switch>
            </Router>
          </div>
        </div>
      </div>
    </Styled.Footer>
  );
};

TheAppFooter.defaultProps = {
  companyName: 'Cardinal Health'
};

export default TheAppFooter;
