import { setExternalUrl } from '@pixie/features';
import UIkit from 'uikit';

/**
 * Opens modal to confirm user wants to open external website link
 * @param externalUrl
 */
const handleExternalLinkClick = (externalUrl: string) => dispatch => {
  dispatch(setExternalUrl(externalUrl));
  UIkit.modal('#externalRedirectModal').show();
};

export default handleExternalLinkClick;
