import CONFIG from '@pixie/config';

const {
  QUESTIONS: {
    QUESTION_TYPES: { CHECKBOX, DATE, FREE_TEXT, NUMERIC, RADIO }
  }
} = CONFIG;

/**
 * Returns an answer object in the desired backend format.
 */
const createAnswerObject = (key: string, data: any) => {
  /**
   * A default object to spread into each return value
   */
  const defaultObject = {
    prescriberProvidedNumericAnswer: null,
    prescriberProvidedSelectAnswer: null,
    prescriberProvidedDateAnswer: null,
    prescriberProvidedFreeTextAnswer: null,
    prescriberProvidedFreeTextAttachmentData: null
  };

  switch (key) {
    case CHECKBOX:
      return {
        ...defaultObject,
        prescriberProvidedSelectAnswer: data.map(
          (choice: { label: string; value: string }) => {
            return {
              choiceId: choice.value,
              additionalFreeTextIndicator: 'NA' // @todo add to choice data
            };
          }
        )
      };

    case DATE:
      return {
        ...defaultObject,
        prescriberProvidedDateAnswer: data
      };

    case FREE_TEXT:
      return {
        ...defaultObject,
        prescriberProvidedFreeTextAnswer: data
      };

    case NUMERIC:
      return {
        ...defaultObject,
        prescriberProvidedNumericAnswer: String(data)
      };

    case RADIO:
      return {
        ...defaultObject,
        prescriberProvidedSelectAnswer: [
          {
            choiceId: data.choiceId,
            additionalFreeText: data.additionalFreeText, // @todo add to radio data
            additionalFreeTextIndicator: data.additionalFreeTextIndicator
          }
        ]
      };

    default:
      return defaultObject;
  }
};

export default createAnswerObject;
