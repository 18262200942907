import styled from 'styled-components';

export const Table = styled.div`
  overflow: auto;

  table {
    border-spacing: 0;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 20px;
  }

  caption {
    font-size: 14px;
    text-align: left;
    color: #999;
  }

  thead th {
    padding: 16px 40px;
    text-align: left;
    vertical-align: bottom;
    border-bottom: 1px solid var(--color-tertiary--100);
    color: black;
    font-size: 20px;
    font-weight: bold;
    text-transform: none;
    white-space: nowrap;
  }

  tfoot {
    font-size: 14px;
  }

  td {
    font-size: 18px;
    padding: 16px 40px;
    vertical-align: top;
  }

  /* ============================ */
  /* STYLE => default */
  /* ============================ */
  table.style--default {
    tbody tr {
      transition: background-color 0.1s linear;
    }

    td {
      color: #131313;
    }

    tbody > tr > td:first-child {
      color: #0057b8;
      white-space: nowrap;
    }

    table > tr:hover,
    tbody tr:hover {
      background-color: #eaf4f7;
    }
  }

  /* ============================ */
  /* STYLE => simple */
  /* ============================ */
  table.style--simple {
    border-collapse: collapse;
    border: none;

    thead th {
      border-bottom: 1px solid #d5d0ce;
      font-size: 19px;
      padding: 16px 18px;
    }

    td {
      padding: 40px 14px;
      vertical-align: middle;
    }

    & > tr:not(:first-child),
    & > :not(:first-child) > tr,
    & > :first-child > tr:not(:first-child) {
      border-top: 1px solid #d5d0ce;
    }

    tbody > tr > td:first-child {
      color: black;
    }

    tbody > tr > td:nth-child(n + 2) {
      font-size: 12px;
      max-width: 120px;
    }

    & > tr:hover,
    tbody tr:hover {
      background-color: inherit;
    }
  }

  /* ============================ */
  /* STYLE => task history */
  /* ============================ */
  table.style--task-history {
    border-collapse: collapse;
    border: none;
    margin-bottom: 0;
    margin-top: 30px;
    font-family: 'Open Sans', sans-serif;
    thead th {
      border-bottom: 1px solid var(--color-white--10);
      // --color-Gray-30
      background-color: var(--color-Gray-30);
      font-size: 11.38px;
      font-weight: 700;
      padding: 6px 13px;
    }

    tbody tr {
      background-color: var(--color-white);
    }

    /* & > tr:nth-of-type(odd):not(.row--isExpanded):not(.uk-hidden), */
    tbody tr.row-even {
      background-color: var(--color-white);
    }

    tbody tr.row-odd {
      background-color: var(--color-Gray-10);
    }

    td {
      color: black;
      font-size: 14px;
      padding: 9px 14px;
      vertical-align: middle;
    }

    tr.row--isExpanded {
      background-color: var(--color-Gray-30) !important;
    }

    tr.row--isExpanded td {
      padding: 9px 14px;
      white-space: pre-wrap;
    }

    & > tr:not(:first-child),
    & > :not(:first-child) > tr,
    & > :first-child > tr:not(:first-child) {
      border-top: 1px solid var(--color-tertiary--50);
    }
  }

  .uk-table-justify {
    th:first-child,
    td:first-child {
      padding-left: 0;
    }

    th:last-child,
    td:last-child {
      padding-right: 0;
    }
  }

  table td.link span {
    color: var(--color-secondary);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
  }
  table td.row--notes {
    width: 50%;
  }
`;
