import styled from 'styled-components';

export const Div = styled.div`
  .ebv-body {
    padding: 100px;
  }
  .header-information {
    padding-left: 40px;
  }
  .header-case-id {
    float: right;
    padding-right: 20px;
  }
  .submit-ebv {
    height: 100%;
    margin-top: 20px;
    padding: 20px 20px 20px 20px;
    border-radius: 5px;
    border: 2px solid var(--color-tertiary--50);
    display: flex;
    flex-direction: column;
    padding: 20px 35px;
    transition: border-color 200ms ease-out;
    width: 30%;
    will-change: border-color;
  }

  .pixie-highlight {
    color: var(--color-secondary);
    font-family: Myriad Pro Bold, Helvetica, Arial, sans-serif;
  }

  .payer-dropdown {
    padding-bottom: 250px;
  }

  .wrapper {
    position: relative;
  }
`;
