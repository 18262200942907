/**
 * UIkit's grid dynamically resizes on window resize events, but it won't
 * do so when we're opening and closing our `#QuestionSet` sidebar. Therefore
 * we'll dispatch a resize event after the `300ms` transition animation to
 * make sure the grid items of our layout set accordingly.
 * @prop {Number} delay
 */
export default function triggerGridResizer(delay: number = 300) {
  setTimeout(() => window.dispatchEvent(new Event('resize')), delay);
}
