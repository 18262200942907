import React from 'react';
import { Question, NumericInput } from '@pixie/components';

interface IQuestionNumericProps {
  dataTestId:string;
  questionText?: string;
  questionTitle?: string;
  onChange?: any;
  onBlur?: any;
  numberMax?: number;
  numberMin?: number;
  numberStep?: number;
  placeholder?: string;
}

const QuestionNumeric = (props: IQuestionNumericProps) => {
  const {
    dataTestId,
    questionText,
    questionTitle,
    onChange,
    onBlur,
    numberMax,
    numberMin,
    numberStep,
    placeholder
  } = props;

  return (
    <Question questionText={questionText} questionTitle={questionTitle}>
      <NumericInput
        dataTestId={dataTestId}
        numberMax={numberMax}
        numberMin={numberMin}
        numberStep={numberStep}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Question>
  );
};

QuestionNumeric.defaultProps = {
  placeholder: 0,
  questionText: '',
  questionTitle: '',
  onChange: () => {},
  onBlur: () => {},
  numberMax: 10000,
  numberMin: 0,
  numberStep: 1
};

export default QuestionNumeric;
