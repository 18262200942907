import CONFIG from '../../../config';
import { createAnswerObject, sortQuestionsArray } from '../utils';

const {
  QUESTIONS: { QUESTION_TYPES }
} = CONFIG;

/**
 * Redux action for answering a Numeric amount question.
 * @param {Object} state
 * @param {Object} payload
 * @requires utils/createAnswerObject
 * @requires utils/sortQuestionsArray
 */
const answerNumericQuestionAction = (state, { payload }) => {
  const { NUMERIC } = QUESTION_TYPES;

  // deconstruct the payload
  const { numericChoice, questionId } = payload;

  /**
   * Find the slot (index) of the current question object
   * @memberof answerNumericQuestionAction
   */
  const curQuestionSlot = state.question.findIndex(
    q => q.questionId === questionId
  );

  /**
   * New question object with `prescriberProvidedNumericAnswer` appended
   * @memberof answerNumericQuestionAction
   */
  const answeredQuestion = {
    ...state.question[curQuestionSlot],
    questionType: {
      ...state.question[curQuestionSlot].questionType,
      numeric: {
        ...state.question[curQuestionSlot].questionType.numeric,
        answer: createAnswerObject(NUMERIC, String(numericChoice))
      }
    }
  };

  /**
   * Creates a new array with state spread in, and the payload's
   * `answeredQuestion` object appended at the end
   * @memberof answerNumericQuestionAction
   * @requires answeredQuestion
   */
  const newQuestionArray = [
    ...state.question.filter(q => q.questionId !== questionId),
    answeredQuestion
  ];

  // sort the array set
  const sortedQuestionArray = sortQuestionsArray(newQuestionArray);

  // set the new question array as the sorted array
  state.question = sortedQuestionArray;
};

export default answerNumericQuestionAction;
