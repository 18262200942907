import { clearAlertMessage } from '@pixie/features';

/**
 * Dispatches clearing an alert message from the UI.
 * @param event
 */
const closeAlert = (e: React.TouchEvent | React.MouseEvent) => dispatch => {
  const target = e.target as HTMLButtonElement;
  e.preventDefault();
  target.blur();
  setTimeout(() => {
    dispatch(clearAlertMessage());
  }, 100);
};

export default closeAlert;
