import { ReactElement } from 'react';
import * as React from 'react';
import { Card } from '@pixie/components';
import { formatLinksFromText } from '@pixie/utils';
import  CONFIG from '@pixie/config';
import * as Styled from './TheRequestHeader.styled';

interface INotesProps {
  dataTestId:string;
  notes: string;
  onLinkClick: any;
}

const Notes = (props: INotesProps): ReactElement => {
  const { dataTestId,
    notes,
    onLinkClick } = props;
  const formatedNotes = formatLinksFromText(notes, CONFIG.SAFE_LINKS.SAFE_LINKS_ARRAY, onLinkClick );

  return (
    <Styled.Notes className="pixie__request-header-notes uk-animation-fade">
      <div className="uk-container uk-container-large">
        <section className="uk-section uk-section-xsmall">
          <div className="uk-margin uk-margin-small uk-width-1-1">
            <h2 className="uk-h4">Notes:</h2>
            <Card dataTestId={dataTestId}>
              <p>{formatedNotes}</p>
            </Card>
          </div>
        </section>
      </div>
    </Styled.Notes>
  );
};

export default Notes;
