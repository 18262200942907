import styled from 'styled-components';

export const InitiationConfirmationForm = styled.div`
  flex-wrap:wrap;
  display:flex;
  page-break-before: auto;
  color: black;
  font-family: 'Open Sans', sans-serif;

  .pixie-form {
    width:100%;
  }

  .pixie-modal-header{
    font-size:16px;
    padding: 0px 0px 15px;
  }

  .pixie-header {
    flex-direction: row;
    padding: 15px 30px;
  }

  .pixie-body {
    flex-direction: row;
    width:100%;
    padding: 15px 30px 30px 30px;
  }

  .pixie-patient-name {
    flex-direction: row;
    position: center;
  }

  .pixie-body-header {
    padding: 30px 30px;
    position: relative;
    display: flex;
    justify-content: center
  }
  .confirmation-header {
    padding-left: 20px;
    margin-top: 15px;
    font-size: 2rem;
    font-weight: 300;
  }
  .pixie-cah-medical-cross-img{
    margin-top: -11px;
  }
  .pixie-body-inputs {
    position: relative;
    width: 443px;
    margin-right: 30px;
    margin-bottom: 20px;
    padding-top:15px;
  }
  .pixie-body-inputs-email {
    position: relative;
    width: 443px;
    margin-right: 30px;
    margin-bottom: 20px;
    padding-top:15px;
  }
  .pixie-body-margin {
    position: relative;
    width:120px;
    margin-left: 30px;
  }
  .pixie-medication {
    position: relative;
    margin-top: 10px;
  }

  .pixie-icd-code {
    width: 443px;
  }

  .pixie-icd-other-section {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 10px;
  }

  .pixie-icd-other-code {
    position: relative;
    width:20%;
  }

  .patient-information-header {
    font-size: 16px;
    margin-left: 20px;
    margin-top: 6px;
  }

  .pixie-patient-name {
    font-weight: 700;
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pixie-buy-and-bill-radio {
    margin-right: 20px;
    font-size:14px;
  }

  .pixie-legend-required {
    font-size: 11.38px;
    font-weight: 700;
    display: inline-block;
  }

  .pixie-legend-optional {
    font-size: 11.38px;
    font-weight: 700;
    display: inline-block;
  }

  .pixie-legend-unit-of-measure {
    color: var(--color-unit-gray);
    position: absolute;
    right: 0;
  }

  .pixie-body-quantity-legend {
    display: flex;
  }

  .pixie-legend-required:after {
    content: '*';
    color: black;
  }

  .pixie-lower-inputs {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .pixie-icd-other-description {
    width: 44%;
    margin-left: 20px;
\  }

  .pixie-checkbox-background {
    background: var(--color-skyblue);
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: 23px;
  }

  .pixie-checkbox {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    align-items: baseline;
    padding-right: 50px;
  }

  .pixie-priority-text {
    position: relative;
    width: auto;
    margin-right: 50px;

  }

  .pixie-header-text {
    position: relative;
    display: table;
    // font-weight: 700;
    font-size: 11.38px;
    width: 100%;
  }

  .pixie-header-text-one {
    position: relative;
    float:left;
    // font-weight: 700px;
    font-size: 11.38px;
    width: 23%;
  }
  .pixie-patient-info-text {
    font-size: 1.125rem;
    font-weight: 400px !important;
  }
  .header-font-text {
    font-size: 0.8125rem;
    font-weight: 700px !important;
  }
  .pixie-header-text-two {
    position: relative;
    float:left;
    font-size: 11.38px;
    padding-left: 40px;
    // font-weight: 700px;
    width: 52%;
  }

  .pixie-buy-and-bill-text {
    font-size: 11.38px;
    font-weight: 700;
  }

  .pixie-modal-button-test{
    position:center;
    display:flex;
    justify-content: center;
    padding: 0px 30px 30px 30px;
    float: center;
  }
  .pixie-outline{
    padding:20px 20px 0px 20px;
    margin-left: 7%;
    margin-right:7%;
  }
  .prescription-symbol {
    font-variation-settings:'FILL' 1,'wght' 400,'GRAD' 0,'opsz' 24;
    padding-right: 5px;
    margin-top: -5px;
  }
  .pixie-modal-medication-header {
    font-size: 1.125rem;
    font-weight: 400;
    position: absolute;
    margin-top: -5px;
  }
  .divider-line {
    border-color: var(--color-lineGrey)
  }

`;
export const InitiationConfirmationModal = styled.div`
  .close-modal-button {
    margin-right: 20px;
    margin-top: 20px;
  }
`;
