import { createSlice } from '@reduxjs/toolkit';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  pbmMemberId: null,
  planNumber: null,
  iin: null,
  pcn: null,
  cardHolderId: null,
  cardHolderFirstName: null,
  cardHolderLastName: null,
  groupId: null,
  groupName: null,
  payerName: null,
  payerId: null
};

const initialState = DEFAULT_STATE;

const benefitsInfoSlice = createSlice({
  name: 'benefitsInfo',
  initialState,
  reducers: {
    clearBenefitsInfoError: state => clearError(state),
    clearBenefitsInfoSuccess: clearSuccess,
    resetBenefitsInfo: () => DEFAULT_STATE,
    setBenefitsInfo(state, { payload }) {
      const {
        pbmMemberId,
        planNumber,
        iin,
        pcn,
        cardHolderId,
        cardHolderFirstName,
        cardHolderLastName,
        groupId,
        groupName,
        payerName,
        payerId
      } = payload;

      state.pbmMemberId = pbmMemberId;
      state.planNumber = planNumber;
      state.iin = iin;
      state.pcn = pcn;
      state.cardHolderId = cardHolderId;
      state.cardHolderFirstName = cardHolderFirstName;
      state.cardHolderLastName = cardHolderLastName;
      state.groupId = groupId;
      state.groupName = groupName;
      state.payerName = payerName;
      state.payerId = payerId;
    },
    setBenefitsInfoError: loadingFailed,
    setBenefitsInfoSuccess: loadingSuccess,
    startBenefitsInfoLoading: startLoading,
    stopBenefitsInfoLoading: stopLoading
  }
});

export const {
  clearBenefitsInfoError,
  clearBenefitsInfoSuccess,
  resetBenefitsInfo,
  setBenefitsInfo,
  setBenefitsInfoError,
  setBenefitsInfoSuccess,
  startBenefitsInfoLoading,
  stopBenefitsInfoLoading
} = benefitsInfoSlice.actions;
export default benefitsInfoSlice.reducer;

/**
 * Sequence for loading and setting benefits information.
 * @param {Object} data
 */
export const fetchBenefitsInfo = data => async dispatch => {
  try {
    dispatch(startBenefitsInfoLoading());
    dispatch(setBenefitsInfo(data));
    dispatch(setBenefitsInfoSuccess());
  } catch (err) {
    dispatch(setBenefitsInfoError(String(err)));
  }
};
