import React from 'react';
import { Question, DatePicker } from '@pixie/components';

interface IQuestionDateProps {
  dataTestId:string;
  defaultDate?: Date;
  onClick?: any;
  questionText: string;
  questionTitle?: string;
  showTimeSelect?: boolean;
}

const QuestionDate = (props: IQuestionDateProps) => {
  const {
    dataTestId,
    defaultDate,
    onClick,
    questionText,
    questionTitle,
    showTimeSelect
  } = props;

  return (
    <Question questionText={questionText} questionTitle={questionTitle}>
      <DatePicker
        dataTestId={dataTestId}
        defaultDate={defaultDate}
        handleDateChange={date => onClick(date)}
        showTimeSelect={showTimeSelect}
      />
    </Question>
  );
};

QuestionDate.defaultProps = {
  defaultDate: new Date(),
  onClick: () => {},
  questionTitle: '',
  showTimeSelect: false
};

export default QuestionDate;
