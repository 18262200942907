import styled from 'styled-components';

export const Question = styled.div`
  .summary {
    background-color: white;
    border: 1px solid var(--color-tertiary--50);
    border-radius: 0;
    padding: 15px;
  }

  .summary .pixie__readonly-form-text {
    font-size: 14px;

    & > .label {
      line-height: 1.45;
      text-transform: none;
    }
  }

  .summary.file-upload .pixie__readonly-form-text > p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
