export const clearError = (state, key = 'errorMessage') => {
  state[key] = null;
  state.isError = false;
};

export const clearSuccess = state => {
  state.isSuccess = false;
};

export const loadingFailed = (
  state,
  { payload } = null,
  key = 'errorMessage'
) => {
  state[key] = payload;
  state.isError = true;
  state.isLoading = false;
  state.isSuccess = false;
};

export const loadingSuccess = state => {
  state.isError = false;
  state.isLoading = false;
  state.isSuccess = true;
};

export const resetLoadingStates = state => {
  state.isError = false;
  state.isLoading = false;
  state.isSuccess = false;
};

export const startLoading = state => {
  state.isLoading = true;
};

export const stopLoading = state => {
  state.isLoading = false;
};
