import React, { ReactElement, useState, useEffect } from 'react';
import { IRequestDetails } from '@pixie/api';
import {
  InitiationConfirmationModal,
  InitiationAwaitingResponseModal
} from '@pixie/features';
import { isBuyAndBillPayer } from '@pixie/utils';
import { useSignOut } from '@pixie/hooks';
import PharmaDetailView from './PharmaDetailView';
import MedicalDetailView from './MedicalDetailView';


interface IEpaDetailViewProps {
  appIsLoading: boolean;
  data: IRequestDetails;
  handleRefreshRequest: any;
  responseTimestamp: string;
  appError: string;
}

const EpaDetailView = (props: IEpaDetailViewProps): ReactElement => {
  const {
    appIsLoading,
    data,
    handleRefreshRequest,
    responseTimestamp,
    appError
  } = props;

  const [isBuyAndBillView, setIsBuyAndBillView] = useState<boolean>(false);
  const [showBuyAndBill, setShowBuyAndBill] = useState<boolean>(false);

  useEffect(() => {
    setIsBuyAndBillView(isBuyAndBillPayer(data, false));
  }, [data]);


  return (
    <>
      <InitiationConfirmationModal
        data={data}
        handleRefreshRequest={handleRefreshRequest}
        useSignOut={useSignOut()}
        showBuyAndBill={showBuyAndBill}
        setShowBuyAndBill={setShowBuyAndBill}
      />
      <InitiationAwaitingResponseModal
        handleRefreshRequest={handleRefreshRequest}
      />
      {!isBuyAndBillView && !showBuyAndBill ?
        (
          <PharmaDetailView
            appIsLoading={appIsLoading}
            appError={appError}
            data={data}
            handleRefreshRequest={handleRefreshRequest}
            responseTimestamp={responseTimestamp}
          />
        ) :
        (
          <MedicalDetailView
            appIsLoading={appIsLoading}
            appError={appError}
            data={data}
            handleRefreshRequest={handleRefreshRequest}
            responseTimestamp={responseTimestamp}
            showBuyAndBill={showBuyAndBill}
          />
        )}
    </>
  );
};

export default EpaDetailView;
