/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { Fragment,  useMemo } from 'react';
import { useTable, useExpanded } from 'react-table';
import * as Styled from './DiscrepanciesTable.styled';


const DiscrepanciesTable = props => {
  const { data } = props;
  const discrepanciesTableColumns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'fieldName', // accessor is the "key" in the data
        headerClassName: 'discrepancies-column uk-table-expand',
        Cell: ({ row }) => <span>{row.values.fieldName}</span>
        // ------------------ use the accessor here ^^^^
      },
      {
        Header: 'Sent on Request',
        accessor: 'before',
        className: 'discrepancies-column uk-text-bold uk-text-uppercase',
        Cell: ({ row }) => <span>{row.values.before}</span>
      },
      {
        Header: 'Returned by Payer',
        accessor: 'after',
        className: 'uk-text-bold uk-text-uppercase',
        Cell: ({ row }) => <span>{row.values.after}</span>
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns: discrepanciesTableColumns,
      data
    },
    useExpanded // We can useExpanded to track the expanded state
    // for sub components too!
  );

  return (
    <Styled.DiscrepanciesTable>
      <table {...getTableProps()} className="style--discrepancies uk-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps({
                    className: column.headerClassName
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              // Use a React.Fragment here so the table markup is still valid
              <Fragment key={idx}>
                <tr
                  {...row.getRowProps()}
                  className='row-background'
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        data-testId={`ebv-discrepancies-table-${cell.column.id}-${cell.row.id}`}
                        {...cell.getCellProps({
                          className: cell.column.className
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </Styled.DiscrepanciesTable>
  );
};

export default DiscrepanciesTable;

