import { ReactElement } from 'react';
import * as React from 'react';

import styled from 'styled-components';

interface ITimestampProps {
  dataTestId: string;
  text: string;
}

const Timestamp = (props: ITimestampProps): ReactElement => {
  const {
    dataTestId,
    text } = props;
  return (
    <StyledTimestamp className="uk-animation-fade">
      <span>(as of&nbsp;</span>
      {text ? (
        <>
          <time data-testId={dataTestId} dateTime={text}>{text}</time>
        </>
      ) : (
        <>
          <span>...</span>
        </>
      )}
      <span>)</span>
    </StyledTimestamp>
  );
};

const StyledTimestamp = styled.div`
  color: var(--color-black);
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
`;

export default Timestamp;
