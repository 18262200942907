import React from 'react';
import { Question, Select } from '@pixie/components';

interface IQuestionCheckboxProps {
  dataTestId: string;
  questionId: string;
  questionText: string;
  questionTitle?: string;
  defaultNextQuestionId?: string;
  questionType: any;
  onClick: any;
}

export interface IQuestionCheckboxQuestionType {
  select: {
    selectMultiple: true | false;
    choice: IQuestionCheckboxChoice[];
    answer?: {
      prescriberProvidedSelectAnswer: Array<IQuestionCheckboxAnswer>
    };
  };
}

export interface IQuestionCheckboxChoice {
  choiceId: string;
  choiceText: string;
  additionalFreeTextIndicator: string;
  nextQuestionId?: string;
}

export interface IQuestionCheckboxAnswer {
  choiceId : string;
}


const QuestionCheckbox = (props: IQuestionCheckboxProps) => {
  const {
    dataTestId,
    questionId,
    questionText,
    questionTitle,
    defaultNextQuestionId,
    questionType,
    onClick
  } = props;

  const options = questionType.select.choice.map((item: IQuestionCheckboxChoice) => {
    const { choiceId, choiceText } = item;
    return (
      { value: choiceId, label: choiceText }
    );
  });

  return (
    <Question questionText={questionText} questionTitle={questionTitle}>
      <Select
        dataTestId={dataTestId}
        options={options}
        isMulti
        onChange={(selectedOption)=> {onClick( questionId, selectedOption, defaultNextQuestionId );}}
      />
    </Question>
  );
};

QuestionCheckbox.defaultProps = {
  questionTitle: '',
  defaultNextQuestionId: undefined
};

export default QuestionCheckbox;
