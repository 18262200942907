import styled from 'styled-components';

export const RequestHeader = styled.div`
  position: relative;
  z-index: 1;

  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  h2,
  h3 {
    margin-bottom: 0.45rem;
  }

  h1 > span + [class*='uk-animation-'],
  h2 > span + [class*='uk-animation-'] {
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 10px;
    margin-bottom: 0;
  }

  .row {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 -0.5rem;
    align-items: stretch;

    & > * {
      margin: 0 0.5rem;
    }
  }

  .pixie__label {
    margin-bottom: 10px;
  }

  .pixie__readonly-form-text {
    font-size: 13px;
  }

  .pixie__readonly-form-text p.loading-animation-swipe {
    background-color: var(--color-tertiary--10);
    background-image: linear-gradient(
      to right,
      #f3f3f3 0%,
      #f0f0f0 20%,
      #eeeeee 40%,
      #f8f8f8 100%
    );
  }

  .pixie__request-header-details {
    width: 100%;
    background-color: var(--color-tertiary--10);

    .uk-grid {
      & > div:nth-of-type(2) p.loading-animation-swipe {
        animation-delay: 100ms;
      }

      & > div:nth-of-type(3) p.loading-animation-swipe {
        animation-delay: 200ms;
      }

      & > div:nth-of-type(4) p.loading-animation-swipe {
        animation-delay: 300ms;
      }
    }
  }

  .action-card {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--color-tertiary--50);
  }

  .pixie__payer-attachment p {
    margin: 0;
  }

  .pixie__payer-attachment .attachment-error {
    color: var(--color-error);
    margin-top: 6px;
  }

  .pixie__payer-attachment button {
    background: none;
    border: none;
    color: var(--color-secondary);
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const CaseDetails = styled.div`
  .pixie__request-header-patient-name {
    @media (max-width: 959px) {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .request-header-payer-name {
    display: flex;
  }
`;

/**
 * TitleBar.tsx
 * @memberof Styled.RequestHeader
 */
export const TitleBar = styled.div`
font-family: 'Open Sans', sans-serif;
  & > .uk-grid {
    min-height: 40px;
  }

  h1 {
    color: var(--color-secondary--100);
  }

  h1 .pixie__label {
    margin-bottom: 0;
  }
  .material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
  }
  position: relative;

  .tab-style {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    text-transform: none;
    color:black;
  }
  .uk-tab {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-bottom: 20px;
    margin-top: 0px;
    padding: 0;
    list-style: none;
    position: relative;
  }

  .uk-tab:before {
    content: black;
  }
  // .uk-tab:after {
    //   content: black;
    // }

  .uk-tab > li {
    flex: none;
    padding-left: 20px;
    position: relative;
  }

  .uk-tab > li > button {
    display: flex;
    align-items: center;
    column-gap: 0.25em;
    justify-content: center;
    padding: 9px 20px;
    color: var(--color-black--10);
    font-size: 12px;
    text-transform: uppercase;
    transition: color 0.1s ease-in-out;
    line-height: 20px;
    background: none;
    border-top: 1px solid var(--color-tertiary);
    border-left: 1px solid var(--color-tertiary);
    border-right: 1px solid var(--color-tertiary);
    border-bottom: 1px solid white;
    border-radius: 3px;
    appearance: none;
    cursor: pointer;

  }

  .uk-tab > .uk-active > button {
    color: black;
  }

  .pixie__request-header-title-bar,
  .pixie__request-header-details,
  .request-data,
  .task-history {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pixie__multi-request-view {
    border: 1px solid var(--color-tertiary--50);
  }

  .patient-name {
    font-size: 28px;
    font-weight: 300;
    color: black;
  }
  .title-patient-info {
    display: flex;
  }
`;

/**
 * StatusAndActions.tsx
 * @memberof Styled.RequestHeader
 */
export const StatusAndActions = styled.div`
  .uk-grid-match .pixie-card {
    height: calc(100% - 35px);
  }
  .pixie-h2 {
    font-family: 'Open Sans',sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: black;
  }
  .uk-grid-match .pixie-card > .uk-card,
  .uk-grid-match .pixie-card > .uk-card > .uk-grid {
    height: 100%;
  }

  & > .uk-animation-fade {
    animation-delay: 300ms;
  }

  .response-required {
    line-height: 1.15;
  }

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
    font-size: 60px;
  }

  .status-icon-actions_alert {
    color: #E09A00;
    font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 0, 'opsz' 24;
  }

  .status-icon-pending_actions {
    color: #3F7D98;
  }

  .status-icon-door_back {
    color: var(--color-black);
  }

  .status-icon-check_circle {
    color: #498530;
    font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 0, 'opsz' 24;
  }

  .status-icon-hide_source {
    color: #C61C2D;
  }

  .status-icon-error {
    color: #C61C2D;
  }
`;

/**
 * ErrorMessage.tsx
 * @memberof Styled.RequestHeader
 */
export const ErrorMessage = styled.div`
  h2 {
    color: var(--color-black--50);
    margin: 0;
  }

  p {
    color: var(--color-error);
    margin: 0;
  }
`;

/**
 * AuthDetails.tsx
 * @memberof Styled.RequestHeader
 */
export const AuthDetails = styled.div`
  .auth-details {
    font-family: "Open Sans", sans-serif;
  }
  .pixie__select {
    margin: auto;
  }

  .pixie__select__control,
  .pixie__select__value-container,
  .pixie__select__indicators {
    cursor: context-menu;
  }

  .auth-details-dates {
    margin-top: 0.5em;
  }

  .auth-details-dates strong + span {
    margin-left: 4px;
  }

  .select-wrap {
    position: relative;
    z-index: 1;
  }

  // prettier-ignore
  div[class*='uk-animation-slide'] {
    &:nth-of-type(1) { animation-delay: 0ms; }
    &:nth-of-type(2) { animation-delay: 100ms; }
    &:nth-of-type(3) { animation-delay: 200ms; }
    &:nth-of-type(4) { animation-delay: 300ms; }
  }
`;

/**
 * Notes.tsx
 * @memberof Styled.RequestHeader
 */
export const Notes = styled.div`
  p {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: black;
  }
`;

export const PayerAttachment = styled.div`
p {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: black;
}
`;
