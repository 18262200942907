/* eslint-disable no-else-return */
import { ISelectChoice, IQuestion } from '@pixie/api';
import CONFIG from '@pixie/config';
import { handleNumericNextQuestionId } from './index';

const {
  QUESTIONS: { QUESTION_TYPES }
} = CONFIG;

/**
 * Determines which questionId comes after the currently active one.
 */
const handleNextQuestionId = (currentObj: IQuestion, currentId: string) => {
  /**
   * if currentObj?.defaultNextQuestionId
   */
  if (currentObj?.defaultNextQuestionId && !currentObj?.questionType?.numeric) {
    if (
      currentObj?.questionType?.select?.answer
        ?.prescriberProvidedSelectAnswer[0]?.choiceId
    ) {
      const { select } = currentObj.questionType;
      const choices = select.choice;
      const { choiceId } = select.answer.prescriberProvidedSelectAnswer[0];
      const nextChoiceObj = choices.find(
        (c: ISelectChoice) => c.choiceId === choiceId
      );
      const nextChoiceId = nextChoiceObj?.nextQuestionId;

      /**
       * if answer.choiceId !== null
       * else answer.choiceId === null
       */
      if (nextChoiceId !== null) return nextChoiceId;
      return currentObj.defaultNextQuestionId;
    }

    /**
     * else
     */
    return currentObj.defaultNextQuestionId;

    /**
     * if currentObj?.defaultNextQuestionId === null
     */
  } else if (
    currentObj?.defaultNextQuestionId === null &&
    currentObj?.questionType?.select?.answer?.prescriberProvidedSelectAnswer[0]
      ?.choiceId
  ) {
    const { select } = currentObj.questionType;
    const choices = select.choice;
    const { choiceId } = select.answer.prescriberProvidedSelectAnswer[0];
    const nextChoiceObj = choices.find(
      (c: ISelectChoice) => c.choiceId === choiceId
    );
    const nextChoiceId = nextChoiceObj?.nextQuestionId;

    /**
     * if nextChoiceId !== null
     */
    if (nextChoiceId !== null) return nextChoiceId;

    /**
     * QUESTION_TYPES === FILE_UPLOAD
     */
  } else if (currentId === QUESTION_TYPES.FILE_UPLOAD) {
    return QUESTION_TYPES.END;
  } else if (currentObj?.questionType?.numeric?.answer?.prescriberProvidedNumericAnswer) {
    return handleNumericNextQuestionId(currentObj?.questionType?.numeric?.answer?.prescriberProvidedNumericAnswer, currentObj);
  }

  /**
   * return null by default
   */
  return null;
};

export default handleNextQuestionId;
