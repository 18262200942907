const caseConfig = {
  CASE_STATUS: {
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED',
    CLOSED: 'CLOSED',
    DENIED: 'DENIED',
    ERROR: 'ERROR',
    IN_PROCESS: 'IN_PROCESS',
    IN_PROGRESS: 'IN_PROGRESS',
    OPEN: 'OPEN',
    PARTIALLY_DENIED: 'PARTIALLY_DENIED',
    SUBMITTED: 'SUBMITTED'
  }
};

export default caseConfig;
