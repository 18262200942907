import styled from 'styled-components';

export const Label = styled.label`
  input[type='number'] {
    background: #fff;
    border-radius: 3px;
    border: 1px solid var(--color-tertiary--100);
    color: #666;
    display: inline-block;
    height: 40px;
    max-width: 100%;
    padding: 0 10px;
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, border;
    vertical-align: middle;
    width: 100%;
    min-width: 167px;
  }

  input[type='number']:focus-within,
  input[type='number']:focus-visible {
    border-color: var(--color-primary);
    box-shadow: 0px 0px 6px 0px rgb(81 149 213 / 65%);
    outline: none;
  }
`;
