import styled from 'styled-components';

export const Card = styled.div`
  background-color: white;
  border: 1px solid var(--color-tertiary--50);
  border-radius: 0;

  &.pixie-card-tertiary--50 {
    background-color: var(--color-tertiary--50);
    border-color: var(--color-tertiary);
  }

  &.pixie-card-tertiary--10 {
    background-color: var(--color-tertiary--10);
    border-color: var(--color-tertiary--50);
    padding-bottom: 30px;
  }

  &.pixie-card-Gray--10 {
    background-color: var(--color-Gray-10);
    border-radius: 10px !important;
    padding-bottom: 30px;
  }

  &.pixie-card-status {
    align-items: center;
    justify-content: center;
    float: center;
    text-align: center;
  }

  .uk-card {
    box-shadow: none;
  }

  .uk-card-xsmall {
    padding: 15px;
  }

  &.pixie-card-questions {
    border: 2px solid var(--color-alert);
  }
`;
