import React, { ReactElement } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@pixie/store';
import { Button } from '@pixie/components';
import { submitAppealRequest } from '@pixie/api';
import { useAnimateQuestionHeight } from '@pixie/hooks';
import {
  answerAppealInput,
  clearAlertMessage,
  resetAppealLoadingStates,
  setAlertMessage,
  setAppealError,
  setAppealSuccess,
  startAppealLoading
} from '@pixie/features';
import { AxiosError, AxiosResponse } from 'axios';
import * as Styled from './TheAppealForm.styled';

interface ITheAppealForm {
  handleRefreshRequest :any;
}
const TheAppealForm = (props : ITheAppealForm): ReactElement => {
  const {
    handleRefreshRequest
  } = props;
  const dispatch = useDispatch();
  const appeal = useSelector((state: RootState) => state.appeal);
  const caseInfo = useSelector((state: RootState) => state.caseInfo);

  /**
   * Determines which affix className to pass to `buttonStyle`
   */
  const handleAppealButtonStyle = () => {
    if (appeal.isError) return 'error';
    if (appeal.isSuccess) return 'success';
    return 'alert';
  };

  /**
   * Sends freeText response data to Redux store
   */
  const handleTextAreaChange = (textAnswer: string) => {
    dispatch(answerAppealInput({ textAnswer }));
  };

  /**
   * Handles submission of the appeal.input to the API.
   */
  const submitAppealResponse = () => {
    /**
     * Switches between `success` and `error` for response handling.
     */
    const handleResponse = (type: string, obj: any) => {
      const defaultErrMsg = 'An error has occured, please retry your request.';
      const defaultSucMsg = 'Appeal submitted successfully!';

      switch (type) {
        case 'success':
          return setTimeout(() => {
            dispatch(setAppealSuccess());
            dispatch(
              setAlertMessage({
                type: 'success',
                message: obj?.statusText || obj?.responseText || defaultSucMsg
              })
            );

            setTimeout(() => {
              // -- stop loading
              dispatch(resetAppealLoadingStates());

              // -- refresh data
              handleRefreshRequest();
            }, 2000);
          }, 2000);

        case 'alert':
        default:
          return setTimeout(() => {
            dispatch(
              setAppealError(
                obj?.responseText || obj?.statusText || defaultErrMsg
              )
            );

            dispatch(
              setAlertMessage({
                type: 'error',
                message: obj?.responseText || obj?.statusText || defaultErrMsg
              })
            );
            setTimeout(() => dispatch(resetAppealLoadingStates()), 2000);
            setTimeout(() => dispatch(clearAlertMessage()), 3000);
          }, 2000);
      }
    };

    dispatch(clearAlertMessage()); // -- clear any active failed alerts
    dispatch(startAppealLoading()); // -- start appeal loading

    submitAppealRequest(caseInfo.caseCode, appeal?.input)
      .then((res: AxiosResponse) => handleResponse('success', res))
      .catch((err: AxiosError) => handleResponse('alert', err));
  };

  return (
    <Styled.AppealInput>
      <section className="appeal">
        <div className="uk-margin">
          <h1 className="uk-h2 uk-margin-remove">Submit Appeal</h1>
          {appeal?.note && (
            <p className="uk-text-meta uk-margin-remove">
              <span>{appeal?.note}</span>
            </p>
          )}
        </div>
        <form>
          <AnimateHeight
            animateOpacity
            delay={0}
            duration={300}
            height={useAnimateQuestionHeight('', {})}
          >
            <div className="uk-animation-slide-right-small">
              <textarea
                className="uk-textarea"
                name="appeal"
                data-testid="data-test-id-the-appeal-form-textarea"
                id="appeal-textarea"
                rows={5}
                onChangeCapture={(event: any) =>
                  handleTextAreaChange(String(event.target.value))
                }
              />
            </div>
          </AnimateHeight>

          <div className="buttons-container">
            <Button
              dataTestId="data-test-id-the-appeal-form-submit"
              buttonStyle={handleAppealButtonStyle()}
              disabled={appeal.input === ''}
              isError={appeal.isError}
              isLoading={appeal.isLoading}
              isSuccess={appeal.isSuccess}
              onClick={submitAppealResponse}
              text="Submit"
              type="button"
            />
          </div>
        </form>
      </section>
    </Styled.AppealInput>
  );
};

export default TheAppealForm;
