import React, { ReactElement } from 'react';
import * as Styled from './Label.styled';


interface ILabelProps {
  loading?: boolean;
  labelStyle?: string;
  text: string;
}

const Label = (props: ILabelProps): ReactElement => {
  const { loading, labelStyle, text } = props;

  return (
    <Styled.Label
      className={`pixie__label pixie__label--${labelStyle} ${
        loading ? 'ellipsis-loader' : ''
      }`}
    >
      {loading ? ' ' : text}
    </Styled.Label>
  );
};

Label.defaultProps = {
  loading: false,
  labelStyle: 'default'
};

export default Label;
