const sessionConfig = {
  ACCESS_TOKEN: 'access_token',
  CASE_CODE: 'case_code',
  APIGEE_TOKEN: 'apigee_access_token',
  NEXT_TOKEN_REFRESH_AT: 'next_token_refresh_at',
  SESSION_STATUS: {
    EXPIRED: 'EXPIRED',
    INVALID: 'INVALID',
    SUCCESS: 'SUCCESS'
  },
  QUESTIONSET_KEY: 'qs'
};

export default sessionConfig;
