import React, { ReactElement } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as Styled from './SnackBar.styled';

export interface Props {
  open?: boolean;
  autoHideDuration?: number;
  onClose?: any;
  anchorOrigin?: any;
  displayText?: string;
  dataTestId?:string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = ({
  open,
  autoHideDuration,
  onClose,
  anchorOrigin,
  displayText,
  dataTestId
}: Props): ReactElement => {
  return (
    <Styled.Label>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        data-testid={dataTestId}
      >
        <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
          {displayText}
        </Alert>
      </Snackbar>
    </Styled.Label>
  );
};

export default SnackBar;
