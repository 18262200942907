import { ReactElement } from 'react';
import * as React from 'react';
import { ReadonlyFormText } from '@pixie/components';
import * as Styled from './TheRequestHeader.styled';

interface ICaseDetailsProps {
  caseNumber: string;
  isLoading: boolean;
  payerName: string;
  prescription: string;
  payerCaseId: string;
}

const CaseDetails = (props: ICaseDetailsProps): ReactElement => {
  const { caseNumber, isLoading, payerName, prescription, payerCaseId } = props;

  return (
    <Styled.CaseDetails>
      <div className="uk-grid uk-child-width-auto@m uk-flex-between@m" data-uk-grid>
        <div className="request-header-payer-name">
          <ReadonlyFormText
            dataTestId='case-details-payer-name'
            label="Payer Name"
            labelClassName='medical-title-label'
            textClassName='medical-title-normal-text'
            loading={isLoading}
            textContent={payerName}
          />
          <ReadonlyFormText
            dataTestId='case-details-drug-description'
            label="Drug Description"
            labelClassName='medical-title-label'
            textClassName='medical-title-normal-text'
            loading={isLoading}
            textContent={prescription}
            textWrap
          />
          {payerCaseId && (
          <ReadonlyFormText
            dataTestId='case-details-payer-case-id'
            label="PA Case ID"
            labelClassName='medical-title-medication-label'
            textClassName='medical-title-medication-text'
            loading={isLoading}
            textContent={payerCaseId}
            textWrap
          />
          )}
        </div>
        <div className="pixie__request-header-case-number uk-child-width-auto">
          <ReadonlyFormText
            dataTestId='case-details-case-number'
            label="Case No."
            labelClassName='medical-title-label'
            textClassName='medical-title-normal-text'
            loading={isLoading}
            textContent={caseNumber}
          />
        </div>
      </div>
    </Styled.CaseDetails>
  );
};

export default CaseDetails;
