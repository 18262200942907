import './axios-config';
import axios, { AxiosResponse } from 'axios';
import { IAttachment, IQuestionSet } from '@pixie/api';
import CONFIG from '@pixie/config';

const { API } = CONFIG;

/**
 * Sends a PUT request to the API, the dataObject param should look like:
 * ```
 *  {
 *    "questionSet": {...},
 *    "attachment": {
 *      "messageSource": MessageType.PA_REQUEST,
 *      "attachmentInBase64": "String",
 *      "fileType": FileType.PDF
 *    }
 *  }
 * ```
 *
 * @param caseCode
 * @param dataObject
 * @requires axios
 * @requires axios-config
 * @requires pixie/config
 * @requires IAttachment
 * @requires IQuestionSet
 */
const submitPaRequest = (
  caseCode: string | null,
  dataObject: {
    questionSet: IQuestionSet;
    attachment: IAttachment;
  },
  isAppealSubmission: boolean | null
) => {
  let REQUEST_URL;
  if (isAppealSubmission){
    REQUEST_URL = API.APPEAL_REQUEST_URL;
  } else {
    REQUEST_URL = API.PA_REQUEST_URL;
  }

  return new Promise((resolve, reject) => {
    axios
      .put(`/${REQUEST_URL}/${caseCode}`, dataObject)
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default submitPaRequest;
