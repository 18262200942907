/**
 * Concatenates the provided params to
 * return a single string of a patient's name.
 *
 * @param first
 * @param last
 * @param middle
 *
 * @returns `first ?middle last`
 */
const concatPatientName = (
  first: string,
  last: string,
  middle: string = '' || '-'
): string => {
  if (first && last && (middle && middle !== '-' && middle !== null)) {
    if (middle.length >= 20) return `${first} ${middle.charAt(0)}. ${last}`;
    return `${first} ${middle} ${last}`;
  }

  if (first && last && (!middle || middle === '-')) {
    return `${first} ${last}`;
  }

  if (first && !last) {
    return first;
  }

  return '-';
};

export default concatPatientName;
