import { IRequestDetails } from 'api/request-details.model';

/**
 * Returns true if the metaData field holds
 */
const isBuyAndBillPayer = (data: IRequestDetails, isInitiate: boolean): any => {
  let isBnB = false;
  if (data === undefined || data === null || data.metadata === undefined || data.metadata === null ) {
    return isBnB;
  }

  Object.entries(data.metadata).forEach(value => {
    if (value[0] === 'isBuyAndBillPayer' && value[1] === true) {
      // Check if buyAndBill is true, if this is the initiate request then buyAndBill is always false
      // controls if we display the buyAndBill UI on subsequent visits
      if (isInitiate || data == null || data?.buyAndBill == null || data?.buyAndBill?.buyAndBill) {
        isBnB = true;
      }
    }
  });

  return isBnB;
};

export default isBuyAndBillPayer;
