import { IQuestion } from '@pixie/api';

/**
 * Returns the `state.question` array sorted by `sequenceNumber`
 * @see https://stackoverflow.com/a/57051534/8296677
 */
const sortQuestionsArray = (arr: IQuestion[]) => {
  const sortedArray = [...arr].sort((a: IQuestion, b: IQuestion) => {
    return Number(a.sequenceNumber) - Number(b.sequenceNumber);
  });

  return sortedArray;
};

export default sortQuestionsArray;
