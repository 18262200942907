/* eslint-disable @typescript-eslint/indent */
import { createSlice } from '@reduxjs/toolkit';
import {
  LOADING_STATES,
  clearError,
  clearSuccess,
  loadingFailed,
  loadingSuccess,
  startLoading,
  stopLoading
} from '../_services';

const DEFAULT_STATE = {
  ...LOADING_STATES,
  patientWeight: '',
  patientHeight: '',
  placeOfService: '',
  billingCode: '',
  numberOfVisits: '',
  unitsBilled: '',
  numberOfDosages: '',
  priorAuthRequestTiming: '',
  startDate: null,
  endDate: null,
  ancillaryServices: [],
  facilityName: '',
  providerFirstName: '',
  providerLastName: '',
  providerAddress: '',
  providerUnit: '',
  providerCity: '',
  providerState: '',
  providerZip: '',
  providerPhoneNumber: '',
  providerFaxNumber: '',
  providerNpi: ''
};

const initialState = DEFAULT_STATE;

const treatmentInfoSlice = createSlice({
  name: 'treatmentInfo',
  initialState,
  reducers: {
    clearTreatmentInfoError: state => clearError(state),
    clearTreatmentInfoSuccess: clearSuccess,
    resetTreatmentInfo: () => DEFAULT_STATE,
    setTreatmentInfo(state, { payload }) {
      const {
        patientWeight,
        patientHeight,
        placeOfService,
        billingCode,
        numberOfVisits,
        unitsBilled,
        numberOfDosages,
        priorAuthRequestTiming,
        startDate,
        endDate,
        ancillaryServices,
        facilityName,
        providerFirstName,
        providerLastName,
        providerAddress,
        providerUnit,
        providerCity,
        providerState,
        providerZip,
        providerPhoneNumber,
        providerFaxNumber,
        providerNpi
      } = payload;

      state.patientWeight = patientWeight || '';
      state.patientHeight = patientHeight || '';
      state.placeOfService = placeOfService || '';
      state.billingCode = billingCode || '';
      state.numberOfVisits = numberOfVisits || '';
      state.unitsBilled = unitsBilled || '';
      state.numberOfDosages = numberOfDosages || '';
      state.priorAuthRequestTiming = priorAuthRequestTiming || '';
      state.startDate = startDate || null;
      state.endDate = endDate || '';
      state.ancillaryServices = ancillaryServices || null;
      state.facilityName = facilityName || '';
      state.providerFirstName = providerFirstName || '';
      state.providerLastName = providerLastName || '';
      state.providerAddress = providerAddress || '';
      state.providerUnit = providerUnit || '';
      state.providerCity = providerCity || '';
      state.providerState = providerState || '';
      state.providerZip = providerZip || '';
      state.providerPhoneNumber = providerPhoneNumber || '';
      state.providerFaxNumber = providerFaxNumber || '';
      state.providerNpi = providerNpi || '';
    },
    setTreatmentInfoError: loadingFailed,
    setTreatmentInfoSuccess: loadingSuccess,
    startTreatmentInfoLoading: startLoading,
    stopTreatmentInfoLoading: stopLoading
  }
});

export const {
  clearTreatmentInfoError,
  clearTreatmentInfoSuccess,
  resetTreatmentInfo,
  setTreatmentInfo,
  setTreatmentInfoError,
  setTreatmentInfoSuccess,
  startTreatmentInfoLoading,
  stopTreatmentInfoLoading
} = treatmentInfoSlice.actions;
export default treatmentInfoSlice.reducer;

/**
 * Sequence for loading and setting latest task data.
 * @param {Object} data
 */
export const fetchTreatmentInfo = data => async dispatch => {
  try {
    dispatch(startTreatmentInfoLoading());
    dispatch(setTreatmentInfo(data));
    dispatch(setTreatmentInfoSuccess());
  } catch (err) {
    dispatch(setTreatmentInfoError(String(err)));
  }
};
