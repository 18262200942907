import data from './data/NCPDP-QUOM-LIST.json';

interface INCPDPQUOMLIST {
  'NCIt Subset Code': string;
  'NCPDP Subset Preferred Term': string;
  'NCIt Code': string;
  'NCPDP Preferred Term': string;
  'NCIt Preferred Term': string;
  'NCIt Definition': string;
}

export interface IQuantityObject {
  code: string;
  preferredTermNCPDP?: string;
  preferredTermNCIt?: string;
  synonym?: string;
  definition?: string;
}

/**
 * Returns the relative object of the provided `NCItCode` param code string.
 *
 * ***NCPDP Terminology***
 * - `code` A unique and permanent identifier attached to every concept.
 * - `preferredTermNCPDP` The term chosen by NCPDP for use as its Preferred Term.
 * - `preferredTermNCIt` term chosen as most widely used in the biomedical community.
 * - `synonym` Additional term that has an equivalent meaning to the Preferred Term.
 * - `definition` Definition of the term created by an NCI EVS subject matter expert.
 *
 * @param NCItCode
 * @requires NCPDP-QUOM-LIST.json
 * @see https://evs.nci.nih.gov/ftp1/NCPDP/About.html
 */
const emptyResponse: IQuantityObject = {
  code: '',
  preferredTermNCPDP: ''
};

const getQuantityObject = (NCItCode: string): IQuantityObject => {
  if (!NCItCode) {
    return emptyResponse;
  }

  const match = data.find((obj: INCPDPQUOMLIST) => {
    return obj['NCIt Code'] === NCItCode;
  });

  if (!match) {
    return emptyResponse;
  }

  // prettier-ignore
  return {
    code: match['NCIt Subset Code'],
    preferredTermNCPDP: match['NCPDP Preferred Term']
      ? match['NCPDP Preferred Term']
      : undefined,
    preferredTermNCIt: match['NCIt Preferred Term']
      ? match['NCIt Preferred Term']
      : undefined,
    synonym: match['NCPDP Synonym']
      ? match['NCPDP Synonym']
      : undefined,
    definition: match['NCIt Definition']
      ? match['NCIt Definition']
      : undefined
  };
};

export default getQuantityObject;
