import React from 'react';
import CONFIG from '@pixie/config';
import { formatBytes, formatDate } from '@pixie/utils';
import { IQuestion } from '@pixie/api';
import { Question, ReadonlyFormText } from '@pixie/components';
import { getQuestionType } from '../../features/questionSet/logic';

const {
  QUESTIONS: { QUESTION_TYPES }
} = CONFIG;

interface IQuestionSummaryProps {
  dataTestId:string;
  fileUploads?: File[];
  questionHistory?: string[];
  questionSetArray?: IQuestion[];
  questionTitle?: string;
}

const QuestionSummary = (props: IQuestionSummaryProps) => {
  const {
    dataTestId,
    fileUploads,
    questionSetArray,
    questionHistory,
    questionTitle
  } = props;

  /**
   * Returns the date with or without time, base on showTime param.
   */
  const handleDateDisplay = (value: any, showTime: boolean = false) => {
    if (showTime) return formatDate(value, true);
    return formatDate(value);
  };

  return questionSetArray ? (
    <Question questionTitle={questionTitle} data-testId={dataTestId}>
      {questionSetArray
        .filter(obj => questionHistory?.includes(obj.questionId))
        .map((q: IQuestion) => {
          const { questionType } = q;
          switch (getQuestionType(questionType)) {
            case QUESTION_TYPES.DATE:
              return (
                questionType.date?.answer?.prescriberProvidedDateAnswer && (
                  <div key={q.questionId} className="summary uk-margin">
                    <ReadonlyFormText
                      label={q.questionText}
                      textContent={handleDateDisplay(
                        questionType.date?.answer?.prescriberProvidedDateAnswer,
                        questionType?.date?.dateTimeRequired
                      )}
                      textWrap
                    />
                  </div>
                )
              );

            case QUESTION_TYPES.FREE_TEXT:
              return (
                questionType.freeText?.answer
                  ?.prescriberProvidedFreeTextAnswer && (
                  <div key={q.questionId} className="summary uk-margin">
                    <ReadonlyFormText
                      label={q.questionText}
                      textContent={
                        questionType.freeText?.answer
                          ?.prescriberProvidedFreeTextAnswer
                      }
                      textWrap
                    />
                  </div>
                )
              );

            case QUESTION_TYPES.NUMERIC:
              return (
                questionType.numeric?.answer
                  ?.prescriberProvidedNumericAnswer && (
                  <div key={q.questionId} className="summary uk-margin">
                    <ReadonlyFormText
                      label={q.questionText}
                      textContent={
                        questionType.numeric?.answer
                          ?.prescriberProvidedNumericAnswer
                      }
                      textWrap
                    />
                  </div>
                )
              );

            case QUESTION_TYPES.RADIO:
              return (
                questionType.select?.answer?.prescriberProvidedSelectAnswer && (
                  <div key={q.questionId} className="summary uk-margin">
                    <ReadonlyFormText
                      label={q.questionText}
                      textContent={
                        questionType.select.choice.find(
                          obj =>
                            obj.choiceId ===
                            questionType.select.answer
                              .prescriberProvidedSelectAnswer[0].choiceId
                        ).choiceText
                      }
                      textWrap
                    />
                    {q.questionType.select?.answer
                      ?.prescriberProvidedSelectAnswer[0]
                      ?.additionalFreeText && (
                      <p className="uk-margin-remove">
                        {
                          q.questionType.select?.answer
                            ?.prescriberProvidedSelectAnswer[0]
                            ?.additionalFreeText
                        }
                      </p>
                    )}
                  </div>
                )
              );

            case QUESTION_TYPES.CHECKBOX:
              return (
                questionType.select?.answer?.prescriberProvidedSelectAnswer && (
                  <div key={q.questionId} className="summary uk-margin">
                    <ReadonlyFormText
                      label={q.questionText}
                      textContent={questionType.select?.answer?.prescriberProvidedSelectAnswer
                        .map(obj => obj.choiceId)
                        ?.map(id =>
                          questionType.select?.choice.find(
                            obj => obj.choiceId === id
                          )
                        )
                        ?.map(choice => choice?.choiceText)
                        .join(', ')}
                      textWrap
                    />
                  </div>
                )
              );

            case QUESTION_TYPES.END:
            case null:
            default:
              return null;
          }
        })}

      {fileUploads?.length !== 0 && (
        <div className="summary file-upload uk-margin">
          <ReadonlyFormText
            label={`File Attachment (${formatBytes(fileUploads[0].size)})`}
            textContent={fileUploads[0].name}
            textWrap
          />
        </div>
      )}
    </Question>
  ) : null;
};

QuestionSummary.defaultProps = {
  fileUploads: [],
  questionHistory: [],
  questionSetArray: [],
  questionTitle: 'Summary'
};

export default QuestionSummary;
