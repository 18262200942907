import React, { ReactElement } from 'react';
import { postCancelPaRequest } from '@pixie/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@pixie/store';
import { classesArray } from '@pixie/utils';
import { Button } from '@pixie/components';
import {
  clearAlertMessage,
  clearCancelPaRequestError,
  clearCancelPaRequestSuccess,
  setAlertMessage,
  setCancelPaRequestButtonEnabled,
  setCancelPaRequestError,
  setCancelPaRequestIsCancelled,
  setCancelPaRequestSuccess,
  startCancelPaRequestLoading
} from '@pixie/features';
import * as Styled from './TheCancelPaRequestForm.styled';

interface ITheCancelPaRequestForm {
  handleRefreshRequest: any;
}
const TheCancelPaRequestForm = (props: ITheCancelPaRequestForm): ReactElement => {
  const {
    handleRefreshRequest
  } = props;
  const dispatch = useDispatch();
  const caseInfo = useSelector((state: RootState) => state.caseInfo);
  const cancelReq = useSelector((state: RootState) => state.cancelPaRequest);

  /**
   * Determines which affix className to pass to `buttonStyle`
   */
  const handleCancelButtonStyle = () => {
    if (cancelReq.isError) return 'error';
    if (cancelReq.isSuccess) return 'success';
    return 'alert';
  };

  /**
   * Sends checkbox value to Redux store
   */
  const handleCheckboxChange = (e: React.TouchEvent | React.MouseEvent) => {
    const target = e.target as HTMLInputElement;
    target?.blur();
    dispatch(setCancelPaRequestButtonEnabled(target.checked));
  };

  /**
   * Submits questionSet + attachments to the API
   * @requires submitPaRequest
   */
  const submitCancelRequest = () => {
    dispatch(startCancelPaRequestLoading());
    postCancelPaRequest(caseInfo.caseCode)
      .then(() => {
        setTimeout(() => {
          dispatch(setCancelPaRequestSuccess());
          dispatch(setCancelPaRequestIsCancelled());
          setTimeout(() => {
            // clear success
            dispatch(clearCancelPaRequestSuccess());

            // close modal
            document
              ?.querySelector<HTMLButtonElement>('#CloseCancelModal')
              ?.click();

            // refresh data
            setTimeout(() => {
              handleRefreshRequest();
            }, 1000);
          }, 2000);
        }, 2000);
      })
      .catch(err => {
        setTimeout(() => {
          dispatch(setCancelPaRequestError(err.message));
          dispatch(setAlertMessage({ type: 'error', message: err.message }));
          setTimeout(() => dispatch(clearCancelPaRequestError()), 2000);
          setTimeout(() => dispatch(clearAlertMessage()), 4000);
        }, 2000);
      });
  };

  return (
    <Styled.Article>
      <div className="uk-modal-header">
        <h1 className="uk-h3">Cancel Prior Auth Request</h1>
      </div>

      <div className="uk-modal-body">
        {cancelReq.isCancelled && (
          <div className="has-been-cancelled uk-animation-fade">
            <p>
              Cancellation request for case <strong>{caseInfo.caseCode}</strong>{' '}
              has been submitted.
            </p>
          </div>
        )}
        <div
          className="has-not-been-cancelled"
          style={{
            filter: cancelReq.isCancelled ? 'blur(2px)' : 'blur(0)'
          }}
        >
          <p>This Prior Authorization request will be cancelled. Once cancelled you have to start a new Prior Authorization request. </p>
          <p>
            To confirm cancellation of this request, please check the box below
          </p>
          <label htmlFor="confirm">
            <input
              className="uk-checkbox"
              data-testId="the-cancel-pa-request-form-checkbox"
              id="confirm"
              name="confirm"
              onChangeCapture={(e: any) => handleCheckboxChange(e)}
              type="checkbox"
            />
            <span>I want to cancel this case.</span>
          </label>
        </div>
      </div>

      <div className="uk-modal-footer uk-text-right">
        <div
          className={classesArray([
            'uk-grid',
            'uk-grid-small',
            'uk-child-width-auto',
            'uk-flex-right'
          ])}
          data-uk-grid
        >
          <div>
            <Button
              disabled={false}
              buttonStyle="outline"
              dataTestId="the-cancel-pa-request-form-close-button"
              id="CloseCancelModal"
              text="Close"
              type="button"
              ukModalClose
            />
          </div>
          <div>
            <Button
              buttonStyle={handleCancelButtonStyle()}
              disabled={!cancelReq.cancelButtonEnabled || cancelReq.isCancelled}
              dataTestId="the-cancel-pa-request-form-submit-button"
              isError={cancelReq.isError}
              isLoading={cancelReq.isLoading}
              isSuccess={cancelReq.isSuccess}
              onClick={() => submitCancelRequest()}
              text="Submit Cancellation Request"
              type="button"
            />
          </div>
        </div>
      </div>
    </Styled.Article>
  );
};

export default TheCancelPaRequestForm;
