import { ReactElement } from 'react';
import * as React from 'react';
import * as Styled from './TheRequestHeader.styled';
import CaseDetails from './CaseDetails';
import PatientTitleBar from './PatientTitleBar';
import MedicalCaseDetails from '../TheMedicalRequestHeader/MedicalCaseDetails';

interface ITitleBarProps {
  appError?: string;
  cancelAvailable: boolean;
  cancelButton?: any;
  isLoading: boolean;
  isMedicalView: boolean;
  dataTestId: string;
  patientName: string;
  patientGender: string;
  patientDOB: string;
  payerCaseId: string;
  prescriberName: string;
  caseNumber: string;
  prescription: string;
  payerName: string;
}

const TitleBar = (props: ITitleBarProps): ReactElement => {
  const {
    appError,
    cancelAvailable,
    cancelButton,
    isLoading,
    isMedicalView,
    dataTestId,
    patientName,
    patientGender,
    patientDOB,
    payerCaseId,
    prescriberName,
    caseNumber,
    prescription,
    payerName
  } = props;

  return (
    <Styled.TitleBar className="pixie__request-header-title-bar">
      <div className="uk-container uk-container-large">
        <section className="uk-section uk-section-xsmall">
          <PatientTitleBar
            cancelAvailable={cancelAvailable}
            cancelButton={cancelButton}
            dataTestId={dataTestId}
            patientName={patientName}
            patientGender={patientGender}
            patientDOB={patientDOB}
          />
          {isMedicalView ? (
            <MedicalCaseDetails
              caseNumber={appError ? '—' : caseNumber}
              isLoading={isLoading}
              payerCaseId={appError ? '—' : payerCaseId}
              prescription={appError ? '—' : prescription}
              prescriberName={appError ? '—' : prescriberName}
              payerName={appError ? '—' : payerName}
            />
          ) : (
            <CaseDetails
              caseNumber={appError ? '—' : caseNumber}
              isLoading={isLoading}
              prescription={appError ? '—' : prescription}
              payerName={appError ? '—' : payerName}
              payerCaseId={appError ? '—' : payerCaseId}
            />
          )}
        </section>
      </div>
    </Styled.TitleBar>
  );
};

TitleBar.defaultProps = {
  appError: '',
  cancelButton: null
};
export default TitleBar;
