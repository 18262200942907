import { IQuestion } from '@pixie/api';

/**
 * Returns a `questionSet.question[]` array, modified to revert
 * any answered question objects not applicable to the submission.
 *
 * This is determined by checking the provided `editedQuestionArray` param
 * against the provided `questionHistory` param; using the
 * `originalQuestionArray` to replace any necessary objects.
 */
const formatQuestionSubmission = (
  originalQuestionArray: IQuestion[],
  editedQuestionArray: IQuestion[],
  questionHistory: string[]
): IQuestion[] => {
  const formattedQuestionArray = editedQuestionArray.map(obj => {
    if (!questionHistory.includes(obj.questionId)) {
      const matchingObject = originalQuestionArray.find(
        (match: IQuestion) => match.questionId === obj.questionId
      );

      return matchingObject;
    }

    return obj;
  });

  return formattedQuestionArray;
};

export default formatQuestionSubmission;
