import { answerNumericQuestion } from '../questionSet.slice';

/**
 * Sends numeric input data to Redux store
 */
const handleNumericChange = (questionId: string, numericChoice: number) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(
    answerNumericQuestion({
      questionId,
      numericChoice: String(numericChoice)
    })
  );
};

export default handleNumericChange;
