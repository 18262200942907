import React, { useRef, ReactElement, useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { getRequestDetails, IRequestDetails, eligibilityHistoryRequest } from '@pixie/api';
import axios, { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState as Root } from '@pixie/store';
import { formatDate, sortEbvBenefits } from '@pixie/utils';
import {
  clearAlertMessage,
  closeAppealBar,
  closeQuestionSet,
  disableCancelPaRequest,
  enableActionCardAppeal,
  enableActionCardQuestion,
  enableActionCardViewQuestions,
  enableCancelPaRequest,
  fetchActionCard,
  fetchBenefitsInfo,
  fetchCaseInfo,
  fetchLatestTask,
  fetchPatientInfo,
  fetchPharmacyInfo,
  fetchPrescriberInfo,
  fetchPrescriptionInfo,
  fetchQuestionSet,
  resetActionCard,
  resetCaseInfo,
  resetEbvBenefits,
  resetLatestTask,
  resetQuestionSet,
  startActionCardLoading,
  startBenefitsInfoLoading,
  startPatientInfoLoading,
  startPharmacyInfoLoading,
  startPrescriberInfoLoading,
  startPrescriptionInfoLoading,
  fetchEbvBenefits,
  startEbvBenefitsLoading,
  stopEbvBenefitsLoading,
  fetchTreatmentInfo
} from '@pixie/features';
import CONFIG from '@pixie/config';
import { IdleTimeout } from '@pixie/components';
import { useSignOut } from '@pixie/hooks';
import { IEbvQuery } from 'interfaces/ebv-query';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import TheSingleRequestDetailPage from './TheSingleRequestDetailPage';
import TheMultiRequestDetailPage from './TheMultiRequestDetailPage';

const {
  APP: { USE_AUTH_FLOW },
  CASE: { CASE_STATUS }
} = CONFIG;

interface IRequestDetailPagesProps {
  envIsLoading: boolean;
}

const RequestDetailPages = (props: IRequestDetailPagesProps): ReactElement => {
  const { envIsLoading } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [appError, setAppError] = useState<string>('');
  const [requestData, setRequestData] = useState(undefined);
  const [responseTimestamp, setResponseTimestamp] = useState('');
  const [requestIsLoading, setRequestIsLoading] = useState<boolean>(true);
  const [appIsLoading, setAppIsLoading] = useState<boolean>(true);
  const isMultiRequest = location.pathname.includes('/Requests/');
  const appeal = useSelector((state: Root) => state.appeal);
  const caseCode = useSelector((state: Root) => state.caseCode);
  const questionSet = useSelector((state: Root) => state.questionSet);
  const ebvData = useSelector((state: Root) => state.ebvBenefits);

  const idleTimer = useRef(null);
  const signOut = useSignOut();
  // Track page view
  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({});
    // Disabling exhaustive-deps here to have this run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Gets and sets local variable of `requestData` via API
   * @requires getRequestDetails
   */
  const getRequestData = useCallback(() => {
    const regex = isMultiRequest ? /(\/Request?s\/)/g : /(\/Request\/)/g;

    const endpoint = USE_AUTH_FLOW
      ? caseCode
      : location.pathname.replace(regex, '');

    getRequestDetails(endpoint, isMultiRequest)
      .then((data: IRequestDetails) => {
        const currentTime = new Date();

        // Pushes history to correct caseCode if it doesn't match location.
        // @see https://cardinalhealth.atlassian.net/browse/PIX-433
        if (
          USE_AUTH_FLOW &&
          !history.location.pathname.includes(data.caseCode)
        ) {
          isMultiRequest
            ? history.push(`/Requests/${data.caseCode}`)
            : history.push(`/Request/${data.caseCode}`);
        }
        setRequestData(data);
        setResponseTimestamp(formatDate(currentTime, true));

        if (!isMultiRequest) {
          const tasks = data?.tasks;
          dispatch(fetchActionCard(data));
          dispatch(fetchCaseInfo(data));
          dispatch(fetchBenefitsInfo(data.benefitsCoordination[0]));
          dispatch(fetchPatientInfo(data.patient));
          dispatch(fetchPharmacyInfo(data.pharmacy));
          dispatch(fetchPrescriberInfo(data.prescriber));
          dispatch(fetchPrescriptionInfo(data));
          dispatch(fetchTreatmentInfo(data.buyAndBill));

          if (data?.payerCaseId !== null) {
            if (
              data.caseStatus !== CASE_STATUS.APPROVED &&
              data.caseStatus !== CASE_STATUS.CANCELLED &&
              data.caseStatus !== CASE_STATUS.CLOSED &&
              data.caseStatus !== CASE_STATUS.DENIED &&
              data.caseStatus !== CASE_STATUS.PARTIALLY_DENIED
            ) {
              if (tasks && tasks[tasks?.length - 1]?.name !== 'CANCEL_PA') {
                dispatch(enableCancelPaRequest());
              }
            }
          }

          if (tasks) {
            const latestTaskObj = tasks[tasks?.length - 1];
            const latestTaskQuestionSet = latestTaskObj?.questionSet;
            const latestTaskStatus = latestTaskObj.status;
            const isAppealSupported = latestTaskObj.eappealSupported;

            if (latestTaskQuestionSet) {
              dispatch(fetchQuestionSet(latestTaskQuestionSet));
              if (latestTaskStatus === 'OPEN')
                setTimeout(() => {
                  const targetString = 'button[data-uk-toggle="target: #QuestionSet;"]';
                  document?.querySelector<HTMLButtonElement>(targetString)?.click();
                }, 300);
            }

            if (latestTaskObj) {
              dispatch(fetchLatestTask(latestTaskObj));
            }

            if (latestTaskQuestionSet && latestTaskStatus === 'SUBMITTED') {
              dispatch(enableActionCardViewQuestions());
            } else if (latestTaskQuestionSet && latestTaskStatus === 'OPEN') {
              // MAYBE CHANGE TO OPEN - DENIED - PARTIALLYDENIED
              dispatch(enableActionCardQuestion());
            } else if (
              latestTaskQuestionSet &&
              isAppealSupported &&
              (latestTaskStatus === 'PARTIALLY_DENIED' ||
                latestTaskStatus === 'DENIED')
            ) {
              dispatch(enableActionCardQuestion());
            } else if (
              isAppealSupported &&
              (latestTaskStatus === 'PARTIALLY_DENIED' ||
                latestTaskStatus === 'DENIED' ||
                latestTaskStatus === 'APPROVED')
            ) {
              dispatch(enableActionCardAppeal());
            }
          }
        }

        setRequestIsLoading(false);
      })
      .catch(err => {
        const errData = err?.response?.data;
        let errorMsg = ' ';

        if (typeof errData === 'object') {
          if (errData?.status && errData?.message) {
            errorMsg = `[${errData?.status}] ${errData?.message}`;
          } else if (errData?.status && errData?.error) {
            errorMsg = `[${errData?.status}] ${errData?.error}`;
          }
        }

        if (errorMsg.includes('undefined')) errorMsg = ' ';

        setAppError(errorMsg);

        setRequestIsLoading(false);
      });
  }, [caseCode, dispatch, history, isMultiRequest, location.pathname]);

  useEffect(() => {
    getRequestData();
    return () => {};
  }, [caseCode, dispatch, getRequestData]);

  useEffect(() => {
    if (requestIsLoading) setAppIsLoading(true);
    else setAppIsLoading(false);
    return () => {};
  }, [requestIsLoading]);

  /**
   * Sends an additional API request.
   * @requires getRequestData()
   */
  const handleRefreshRequest = () => {
    setRequestIsLoading(true);
    dispatch(clearAlertMessage());
    dispatch(disableCancelPaRequest());
    dispatch(resetCaseInfo());
    dispatch(resetQuestionSet(''));
    dispatch(resetLatestTask());
    dispatch(resetActionCard());
    dispatch(resetEbvBenefits());
    dispatch(startBenefitsInfoLoading());
    dispatch(startActionCardLoading());
    dispatch(startPatientInfoLoading());
    dispatch(startPharmacyInfoLoading());
    dispatch(startPrescriptionInfoLoading());
    dispatch(startPrescriberInfoLoading());

    if (questionSet?.questionSetOpen) {
      dispatch(closeQuestionSet(''));
      const targetString = 'button[data-uk-toggle="target: #QuestionSet;"]';
      document?.querySelector<HTMLButtonElement>(targetString)?.click();
    }

    if (appeal?.appealBarOpen) {
      dispatch(closeAppealBar());
      const targetString = 'button[data-uk-toggle="target: #AppealInput;"]';
      document?.querySelector<HTMLButtonElement>(targetString)?.click();
    }
    trackEvent({
      category: 'Status Check',
      action: 'Check for Status Updates',
      name: 'Check for Status Updates'
    });
    setTimeout(() => getRequestData(), 2000);
    setTimeout(() => getEligibilityHistory(), 2000);
  };

  const handleOnIdle = () => {
    signOut().then();
  };

  const getEligibilityHistory = useCallback(() => {
    // temporary local fix
    const regex = isMultiRequest ? /(\/Request?s\/)/g : /(\/Request\/)/g;

    const endpoint = USE_AUTH_FLOW
      ? caseCode
      : location.pathname.replace(regex, '');
    eligibilityHistoryRequest(endpoint)
      .then((res: IEbvQuery) => {
        dispatch(startEbvBenefitsLoading());
        dispatch(fetchEbvBenefits(sortEbvBenefits(res)));
        dispatch(stopEbvBenefitsLoading());
      })
      .catch((err: AxiosError) => {
        const errData = err?.response?.data;
        let errorMsg = ' ';

        if (axios.isAxiosError(errData)) {
          if (errData?.status && errData?.message) {
            errorMsg = `[${errData?.status}] ${errData?.message}`;
          } else if (errData?.status && errData?.cause) {
            errorMsg = `[${errData?.status}] ${errData?.cause}`;
          }
        }

        if (errorMsg.includes('undefined')) errorMsg = ' ';

        setAppError(errorMsg);

        setRequestIsLoading(false);
      });
  }, [caseCode, dispatch, isMultiRequest, location.pathname]);

  useEffect(() => {
    getEligibilityHistory();
    return () => {};
  }, [dispatch, caseCode, getEligibilityHistory]);

  return (
    <Switch>
      <Route path="/Request">
        <div>
          <IdleTimeout
            dataTestId="request-detail-pages-idle-timeout"
            idleTimer={idleTimer}
            handleOnIdle={handleOnIdle}
          />
        </div>
        {envIsLoading ? (
          <div className="uk-flex uk-flex-center uk-section-xsmall">
            <div className="uk-spinner" data-uk-spinner />
          </div>
        ) : (
          <TheSingleRequestDetailPage
            appError={appError}
            appIsLoading={appIsLoading}
            data-testid="handleRefreshRequest"
            data={requestData}
            ebvData={
              ebvData?.ebvBenefits?.length > 0 ?
                ebvData.ebvBenefits[ebvData.ebvBenefits.length - 1]
                : null
              }
            handleRefreshRequest={handleRefreshRequest}
            responseTimestamp={responseTimestamp}
          />
        )}
      </Route>

      <Route path="/Requests">
        <div>
          <IdleTimeout
            dataTestId="request-detail-pages-idle-timer"
            idleTimer={idleTimer}
            handleOnIdle={handleOnIdle}
          />
        </div>
        {envIsLoading ? (
          <div className="uk-flex uk-flex-center uk-section-xsmall">
            <div className="uk-spinner" data-uk-spinner />
          </div>
        ) : (
          <TheMultiRequestDetailPage
            appError={appError}
            appIsLoading={appIsLoading}
            data-testid="handleRefreshRequest"
            data={requestData}
            ebvData={
              ebvData?.ebvBenefits?.length > 0 ?
                ebvData.ebvBenefits[ebvData.ebvBenefits.length - 1]
                : null
              }
            handleRefreshRequest={handleRefreshRequest}
            responseTimestamp={responseTimestamp}
          />
        )}
      </Route>
    </Switch>
  );
};

export default RequestDetailPages;
