import { answerMultiSelectQuestion } from '../questionSet.slice';

/**
 * Sends freeText response data to Redux store
 */
const handleSelectMultiple = (
  questionId: string,
  choiceArray: Array<String>
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(
    answerMultiSelectQuestion({
      questionId,
      choiceArray
    })
  );
};

export default handleSelectMultiple;
