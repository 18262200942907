import styled from 'styled-components';

export const EbvBenefitsInfo = styled.div`
  .ebv-benefit-left {
    width: 40%;
  }

  .ebv-benefit-right {
    float: right;
    width: 40%;
  }

  .card-left {
    border-right: 1px solid black;
    width: 40%;
    padding-top: 10px;
    display: flex;
  }

  .benefits-card-left {
    border-right: 1px solid black;
    width: 38%;
  }

  .benefits-card-left-mobile-version {
    border-right: 1px solid black;
  }

  .benefits-card-box {
    display: flex;
    border-radius: 0;
    margin-top: 20px;
    margin-left: 5px;
    padding: 30px 10px 10px 10px;
    background-color: var(--color-ebvBackground);
    border: 1px solid var(--color-ebvBorder);
    border-radius: 10px;
  }

  .benefits-plan-information {
    padding: 10px 0px 0px 0px;
  }
  .benefits-card-right {
    width: 50%;
    padding: 50px 50px 50px 0px;
  }
  .card-right {
    display: flex;
    width: 50%;
    padding: 10px 10px 40px 30px;
  }
  .card-right-in-network {
    display: flex;
    width: 50%;
    padding: 10px;
  }
  .card-right-out-of-network {
    display: flex;
    width: 50%;
    padding: 10px;
  }
  .card-box {
    display: flex;
    border-radius: 0;
  }
  .medication-box {
    background-color: var(--color-tertiary--10);
    border-color: var(--color-tertiary);
    border: 1px solid var(--color-tertiary--50);
    border-radius: 0;
    padding: 20px;
  }

  .benefits-header {
    padding-left: 20px;
  }

  .benefits-plan-information {
    margin-bottom: 30px;
  }

  .benefits-plan-status {
    padding: 10px;
    background-color: var(--color-warm-gray);
    width: 20%;
    float: right;
    margin-top: -30px;
    margin-right: 5px;
  }

  .benefits-plan-status-text {
    color: #121312;
    font-family: 'Helvetica Neue';
    font-size: 17px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 2px;
    white-space: normal;
    text-align: center;
  }
`;
