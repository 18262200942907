import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
  routingType: null
};

const initialState = DEFAULT_STATE;

const routingTypeSlice = createSlice({
  name: 'routingType',
  initialState,
  reducers: {
    setRoutingType(state, { payload }) {
      state.routingType = payload;
    }
  }
});

export const { setRoutingType } = routingTypeSlice.actions;

export default routingTypeSlice.reducer;
