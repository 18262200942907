import React, { ReactElement } from 'react';
import ReactSelect, { components } from 'react-select';
// import { DropdownIndicator } from 'react-select/src/components/indicators';
import * as Styled from './Select.styled';

export interface Props {
  dataTestId?: string;
  autoFocus: boolean;
  classNamePrefix?: string;
  closeMenuOnScroll: boolean;
  defaultValue: any;
  hideSelectedOptions: boolean;
  id: string;
  isClearable: boolean;
  isDisabled: boolean;
  isMulti: boolean;
  isRtl: boolean;
  name: string;
  onChange?: (...args: any[]) => any;
  options: Array<Object>;
  value?: Object;
  placeholder?: string;
}

/**
 *
 * @prop `autoFocus` Focus the control when it is mounted
 * @prop `closeMenuOnScroll` If true, close the select menu when the user scrolls the document
 * @prop `hideSelectedOptions` Hide the selected option from the menu
 * @prop `isClearable` Is the select value clearable
 * @prop `id` The id to set on the SelectContainer component.
 * @prop `isDisabled` Is the select disabled
 * @prop `isMulti` Support multiple selected options
 * @prop `isRtl` Is the select direction right-to-left
 */
const Select = (props: Props): ReactElement => {
  const {
    autoFocus,
    classNamePrefix,
    closeMenuOnScroll,
    dataTestId,
    defaultValue,
    hideSelectedOptions,
    id,
    isClearable,
    isDisabled,
    isMulti,
    isRtl,
    name,
    options,
    value,
    onChange,
    placeholder
  } = props;

  // Attempted to use the following to plumb through dataTestId prop - https://gist.github.com/MikaelCarpenter/1fe226967b00eea82e6a4760b244ada1

  /**
   * Changing this variable to `true` will force-open the dropdown menu.
   * This will allow you to debug/target the CSS styles more easily.
   *
   * - `true` menu is open, regardless of activity
   * - `undefined` menu behavior default behavior
   */
  const DEBUG_MENU_OPEN = undefined;

  // const dropdownIndicatorStyles = (base, state) => {
  //   const changes = {
  //     // all your override styles
  //     color: 'black',
  //   };
  //   return Object.assign(base, changes);
  // };

  const DropdownIndicator = propsSelect => {
    return (
      <components.DropdownIndicator {...propsSelect}>
        <span className="material-symbols-outlined dropdown-arrow">
          arrow_drop_down
        </span>
      </components.DropdownIndicator>
    );
  };

  return (
    <Styled.Select
      className="pixie__select"
      data-testid={dataTestId}
    >
      <ReactSelect
        autoFocus={autoFocus}
        blurInputOnSelect={!isMulti}
        classNamePrefix={classNamePrefix}
        closeMenuOnScroll={closeMenuOnScroll}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={hideSelectedOptions}
        defaultValue={defaultValue}
        id={id}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isRtl={isRtl}
        menuIsOpen={DEBUG_MENU_OPEN}
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        // styles={{ dropdownIndicator: dropdownIndicatorStyles }}
      />
    </Styled.Select>
  );
};

Select.defaultProps = {
  autoFocus: false,
  blurInputOnSelect: true,
  closeMenuOnScroll: false,
  classNamePrefix: 'pixie__select',
  defaultValue: null,
  hideSelectedOptions: false,
  id: '',
  isClearable: false,
  isDisabled: false,
  isMulti: false,
  isRtl: false,
  name: 'select',
  placeholder: 'Select...',
  options: []
};

export default Select;
