import React from 'react';
import { Question, Radio, TextInput } from '@pixie/components';
import { IQuestionType, ISelectChoice } from '@pixie/api';

interface IQuestionRadioProps {
  dataTestId:string;
  questionId: string;
  questionText: string;
  questionTitle?: string;
  defaultNextQuestionId?: string;
  questionType: IQuestionType;
  onAdditionalTextChange: any;
  onClick: any;
  maxLength: number;
}

const QuestionRadio = (props: IQuestionRadioProps) => {
  const {
    dataTestId,
    questionId,
    questionText,
    questionTitle,
    defaultNextQuestionId,
    questionType,
    onAdditionalTextChange,
    onClick,
    maxLength
  } = props;

  const renderAdditionalFreeText =
    questionType?.select?.answer &&
    questionType?.select?.answer !== null &&
    questionType?.select?.answer?.prescriberProvidedSelectAnswer[0]
      ?.additionalFreeTextIndicator !== 'NA';

  return (
    <Question questionText={questionText} questionTitle={questionTitle}>
      <div className="uk-child-width-1-1">
        {questionType.select.choice.map((item: ISelectChoice) => {
          const {
            additionalFreeTextIndicator,
            choiceId,
            choiceText,
            nextQuestionId
          } = item;

          return (
            <div key={choiceId} className="uk-margin">
              <Radio
                dataTestId={`${dataTestId}__${choiceId}`}
                id={choiceId}
                label={choiceText}
                name={questionId}
                onClick={() =>
                  onClick(
                    questionId,
                    choiceId,
                    additionalFreeTextIndicator,
                    nextQuestionId || defaultNextQuestionId
                  )
                }
              />
            </div>
          );
        })}

        {renderAdditionalFreeText && (
          <>
            <div className="uk-animation-slide-top-small">
              <TextInput maxLength={maxLength} dataTestId={`${dataTestId}__text-input`} onChange={onAdditionalTextChange} />
            </div>
          </>
        )}
      </div>
    </Question>
  );
};

QuestionRadio.defaultProps = {
  defaultNextQuestionId: null,
  questionTitle: ''
};

export default QuestionRadio;
