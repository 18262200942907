import React, { ReactElement } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { MuiButton } from '@pixie/components';
import CONFIG from '@pixie/config';
import * as Styled from './AlertContent.styled';

const {
  SESSION: {
    SESSION_STATUS: { EXPIRED }
  }
} = CONFIG;

export interface IAlertContentProps {
  dataTestId?: string;
  status: string;
  regenAuthCode: boolean;
  onClick: any;
  regenButtonText: string;
  isPatientAccessUi: boolean;
}

const AlertContent = (props: IAlertContentProps): ReactElement => {
  const { dataTestId, status, regenAuthCode, onClick, regenButtonText, isPatientAccessUi } = props;

  let pixiePriorAuthCodeExpires = 'Authentication codes will expire after 15 days from the time it was received via secure fax.';
  if (isPatientAccessUi) {
    pixiePriorAuthCodeExpires = 'Authentication codes will expire after 15 days from the time it was received via secure fax. Once expired, enter expired code and patient information to regenerate new authentication codes.';
  }

  return (
    <Styled.Div className="pixie__alert" data-testid={dataTestId}>
      <div className="pixie-marketing-content">
        <h1 className="pixie-prior-auth-text" data-testid="alert-content-auth-text">
          { !isPatientAccessUi &&
            <>
              Expedite access with a quick and secure prior authorization process
            </>
          }
          {
            isPatientAccessUi &&
            <>Expedite access with a quick and secure benefits verification and prior authorization</>
          }
        </h1>
        <div className='pixie-instructions-area'>
          <img
            className="pixie-cah-logo-img"
            src="../../../FaxTemplate.svg"
            alt='Cardinal Health'
            style={{ height: 'auto', width: '310px' }}
          />
          <div className="pixie-auth-flow-content">
            {status === '' && (
            <>
              <div className="pixie-flow-content" data-testid={`${dataTestId}__status-is-blank`}>
                <ol className='list'>
                  <li className='pixie-auth-steps-explained'>
                    <span data-testid="alert-content-auth-steps-explained-1-span">
                      Locate the secure fax with the Authentication Code that was sent to your office
                    </span>
                    <br />
                    <div className='pixie-auth-steps-additional-text'>Authentication codes expire after 15 days from the time it was recieved via secure fax.</div>
                  </li>
                  <li className='pixie-auth-steps-explained'>
                    <span data-testid="alert-content-auth-steps-explained-2-span">Enter the secure Authentication Code and patient information</span>
                  </li>
                  <li className='pixie-auth-steps-explained'>
                    <span data-testid="alert-content-auth-steps-explained-3-span">Confirm patient details and submit your prior authorization</span>
                  </li>
                </ol>
              </div>
            </>
            )}
            {status !== '' && (
            <>
              <div className="pad-top-large " data-testid={`${dataTestId}__status-is-incorrect`}>
                <div className="pixie-auth-alert">
                  <div className="pixie-auth-alert-icon">
                    <SvgIcon
                      sx={{
                        height: '2em',
                        width: '2em',
                        color: 'var(--color-shield)'
                      }}
                    >
                      <path
                        d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12c5.16-1.26 9-6.45 9-12V5l-9-4z"
                        fill="currentColor"
                      />
                      <svg height="0.75em" width="0.6em">
                        <text x="9.25" y="23" fill="var(--color-white)">
                          i
                        </text>
                      </svg>
                    </SvgIcon>
                  </div>
                  <div className="pixie-auth-alert-content-header">
                    <span>
                      The Authentication Code or Patient information are not correct.
                    </span>
                  </div>
                </div>

                {status === EXPIRED && (
                  <>
                    <div className="pixie-auth-alert-content" data-testid={`${dataTestId}__status-is-expired`}>
                      <div className="pixie-auth-mis-match-text">
                        Verify that the Authentication Code matches the code
                        from your secure fax, and that the Patient Information
                        has been entered correctly.
                      </div>
                      <div className="pixie-new-auth-code-text">
                        <span className="pixie-highlight-small">
                          Authentication Codes expire after 15 days from the time of receipt.&#160;
                        </span>
                        If you believe your code has expired you can request a
                        new Authentication Code by clicking the button below.
                      </div>
                    </div>
                    <div className="pixie-new-auth-code-button">
                      <MuiButton
                        buttonText={regenButtonText}
                        dataTestId={`${dataTestId}__regen-auth-code-button`}
                        disabled={regenAuthCode}
                        onClick={onClick}
                      />
                    </div>
                    <div>
                      <span className="pixie-auth-code-expires">
                        A new Authentication Code will be sent to the secure fax
                        number we have on record for your organization.
                      </span>
                    </div>
                  </>
                )}

                {status !== EXPIRED && (
                  <>
                    <div className="pixie-auth-alert-content" data-testid={`${dataTestId}__status-is-mis-match`}>
                      <div className="pixie-auth-mis-match-text">
                        Verify that the Authentication Code matches the code
                        from your secure fax, and that the Patient Information
                        has been entered correctly. Authentication Codes expire
                        after 15 days from the time of receipt.
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
            )}
          </div>
        </div>
      </div>
    </Styled.Div>
  );
};

export default AlertContent;
