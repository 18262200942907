import styled from 'styled-components';

export const Navbar = styled.div`
  height: 112px;
  border-top: 5px solid var(--color-shield);
  box-shadow: 0px 2px 54px 0px #0000001f;

  .logo {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .uk-navbar-left {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    /* margin-left: 2.3rem; */
    margin-right: -2rem;
  }

  .uk-navbar-right {
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: -2rem;
  }

  .uk-navbar-item {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 120px;
  }

  .uk-navbar-item > img {
    box-sizing: border-box;
    height: auto;
    width: 100%;
  }

  .request-bar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    input {
      min-width: 200px;
    }

    button {
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  @media (min-width: 960px) {
    .request-bar input {
      min-width: 320px;
    }
  }

  .uk-navbar-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .uk-navbar-app {
    display: flex;
    align-items: center;
    padding-top: 1%;
  }

  h3.remove-whitespace {
    padding-left: 2em;
    font-size: 26px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    line-height: 35px;
    color: #27251f;
    @media (max-width: 374px) {
      padding-left: 0;
    }
  }
`;
