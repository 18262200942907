enum labelsConfig {
  'ANSWER_QUESTIONS' = 'Answer Questions',
  'ANSWER_APPEAL_QUESTIONS' = 'Answer Appeal Questions',
  'APPEAL_PA' = 'Appeal PA',
  'APPROVED' = 'Approved',
  'CANCEL_PA' = 'Cancel PA',
  'CANCELLED' = 'Cancelled',
  'CLOSED' = 'Closed',
  'DENIED' = 'Denied',
  'ERROR' = 'Error',
  'IN_PROCESS' = 'In-Process',
  'IN_PROGRESS' = 'In-Progress',
  'INITIATE_PA' = 'Initiate PA',
  'OPEN' = 'Open',
  'PARTIALLY_DENIED' = 'Partially-Denied',
  'PROCESS_QUESTIONS' = 'Process Questions',
  'SUBMITTED' = 'Submitted',
  'UNKNOWN' = 'Unknown'
}

export default labelsConfig;
