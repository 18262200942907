import styled from 'styled-components';

export const InitiationAwaitingResponseModalForm = styled.div`
  flex-wrap: wrap;
  display: flex;
  page-break-before: auto;

  .pixie-form {
    width: 100%;
  }

  .pixie-header {
    flex-direction: row;
    text-align: center;
    font-size: 24px;
    padding-top: 50px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .pixie-body {
    flex-direction: row;
    padding-bottom: 50px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .body-information-message {
    text-align: center;
  }

  .progress-bar {
    color: var(--color-primary);
    margin: 10px;
  }

  .animation-video {
    padding-top: 20px;
    padding-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .timeout-image {
    justify-content: center;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .timeout-button {
    position: center;
    display: flex;
    justify-content: center;
    padding: 10px 0px 10px 0px;
    float: center;
  }
`;

export const InitiationAwaitingResponseModal = styled.div`
  .close-modal-button {
    margin-right: 20px;
    margin-top: 20px;
  }
`;
